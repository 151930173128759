<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
        <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.cancel"
        v-model="dialog.cancel"
        width="400">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="title text-second text--darken-2 font-weight-bold">
              Hapus {{ deletes.title }}
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.cancel"
                class="text-capitalize px-0"
                @click="dialog.cancel = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah anda yakin ingin menghapus kelas {{ deletes.name }} ini?
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="red"
                  block
                  :disabled="process.cancel"
                  :loading="process.cancel"
                  @click="toDeletes()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, Hapus
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.cancel"
                  block
                  @click="dialog.cancel = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              @click="dialog.cancel = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Ya, Hapus
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              @click="dialog.cancel = false"
              class="text-capitalize white--text ml-2">
              Tidak
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.status"
        v-model="dialog.status"
        width="400">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="title text-second text--darken-2 font-weight-bold">
              <span v-if="!deletes.active_bool">Publish</span> <span v-if="deletes.active_bool">Unpublish</span>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.cancel"
                class="text-capitalize px-0"
                @click="dialog.status = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah anda yakin ingin <span v-if="!deletes.active_bool">Publish</span> <span v-if="deletes.active_bool">Unpublish</span> kelas {{ deletes.name }} ini?
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  :color="deletes.active_bool ? 'warning' : '#4ab762'"
                  block
                  :disabled="process.cancel"
                  :loading="process.cancel"
                  @click="toUpdateStatus()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, <span v-if="!deletes.active_bool">Publish</span> <span v-if="deletes.active_bool">Unpublish</span>
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.cancel"
                  block
                  @click="dialog.status = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              @click="dialog.cancel = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Ya, Hapus
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              @click="dialog.cancel = false"
              class="text-capitalize white--text ml-2">
              Tidak
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-2">
          <v-col :cols="9" class="pt-0">
            <div class="d-flex align-center" style="height: 100%;">
              <div>
                <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
                  <span>List Elearning</span>
                </div>
                <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
                  <span>Kelola elearning yang ingin Anda jual</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="43" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
      </section>

      <section>
        <v-card-text class="pa-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'mb_20 mt_25'">
          <v-row>
            <v-col cols="12" md="4" class="pt-0">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.search"
                v-on:keyup.enter="fetch()"
                @click:clear="filter.search = ''; fetch();"
                :placeholder="`Cari produk…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <v-select
                :items="category"
                solo
                color="#4ab762"
                style="width: 100%;"
                item-color="green"
                hide-details
                item-text="name"
                item-value="id"
                v-on:change="fetch()"
                v-model="filter.category"
                placeholder="Kategori">
              </v-select>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <v-select
                :items="etalase"
                solo
                color="#4ab762"
                style="width: 100%;"
                item-color="green"
                hide-details
                item-text="name"
                item-value="id"
                v-on:change="fetch()"
                v-model="filter.etalase"
                placeholder="Etalase">
              </v-select>
            </v-col>
            <v-col cols="12" md="2" class="pt-0">
              <v-btn
                color="#3498db"
                elevation="0"
                height="45"
                block
                to="/account/product/form"
                class="text-capitalize white--text">
                Tambah
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-second mt_20" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : ''" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data kelas
          </div>
        </v-card-text>

        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 5" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0">
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      class="mx-auto"
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.detail"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <div class="text-right ml-auto mt-5">
                        <v-skeleton-loader
                          :loading="process.run"
                          type="paragraph">
                        </v-skeleton-loader>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="chip">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="chip">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="chip">
                </v-skeleton-loader>
              </div>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  style="width: 100px; height: 40px;"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
                <v-skeleton-loader
                  style="width: 100px; height: 40px;"
                  class="ml-5"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-card-text class="py-3 px-0">
            <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : 'mb-7 px-3'">
              <v-row  class="border_radius_7 hover_select" style="padding: 17px 20px 23px 20px;" :style="'background: #f9f9f9; border: 1px solid #ebebeb;'">
                <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'px-0 py-0' : 'pt-0'">
                  <v-list three-line style="background: transparent;" class="py-0">
                    <v-list-item class="py-0 px-0">
                      <v-list-item-avatar
                        size="105"
                        class="my-0"
                        tile>
                        <v-img 
                          :src="l.img_url" style="border-radius: 15px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                          {{ l.name }}
                        </v-list-item-subtitle>
                        <div class="d-flex align-center body-2 text-second">
                          <div class="text-first font-weight-bold">
                            {{ l.review_average }}
                          </div>
                          <div class="mx-2">
                            <v-rating
                              :value="l.review_average*1"
                              background-color="grey"
                              color="#e67e22"
                              dense
                              half-increments
                              hover
                              size="20">
                            </v-rating>
                          </div>
                        </div>
                        <div class="text-first font-weight-bold text-capitalize mt-0 mb-0 body-1">
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(l.discount_percent) > 0">
                            {{ l.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span v-if="parseInt(l.discount_percent) > 0">
                              (-{{ parseInt(l.discount_percent) }}%)
                            </span>
                            <span class="text-first" v-if="parseInt(l.price) > 0">
                              {{ l.price_discount | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pr-0 py-0' : 'pt-0'">
                  <div class="text-first body-1 font-weight-bold mt-0 d-flex justify-end">
                    <v-list style="background: transparent; width: 60px;">
                      <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                        <v-list-item-content class="py-0">
                          <div class="font-weight-bold body-1 text-right text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                            <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              Bab
                            </div>
                            {{ l.content_count }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list style="background: transparent; width: 100px;">
                      <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-8">
                        <v-list-item-content class="py-0">
                          <div class="font-weight-bold body-1 text-right text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                            <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              Materi 
                            </div>
                            {{ l.content_sub_count }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list style="background: transparent; width: 120px;">
                      <v-list-item class="py-0">
                        <v-list-item-content class="py-0">
                          <div class="font-weight-bold body-1 text-right text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                            <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              Terjual
                            </div>
                            {{ l.subscribed_count }}x
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div class="text-right ml-auto mt-0 d-flex align-center justify-end" style="margin-top: 0.5px;">
                    <v-icon @click="deletes = l; dialog.cancel = true;" size="20" color="red">mdi-block-helper</v-icon>
                    <v-btn
                      outlined
                      elevation="0"
                      height="40"
                      :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      style="background: #fff;"
                      color="#9e9e9e"
                      @click.stop="fetchDetail(l); drawer = !drawer"
                      class="text-capitalize ml-3">
                      Lihat Detail
                    </v-btn>
                    <v-btn
                      outlined
                      elevation="0"
                      height="40"
                      @click="deletes = l; dialog.status = true"
                      v-if="l.active_bool"
                      :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      style="background: #fff;"
                      color="warning"
                      class="text-capitalize ml-3">
                      Unpublish
                    </v-btn>
                    <v-btn
                      outlined
                      elevation="0"
                      height="40"
                      @click="deletes = l; dialog.status = true"
                      v-if="!l.active_bool"
                      :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      style="background: #fff;"
                      color="green"
                      class="text-capitalize ml-3">
                      Publish
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>


          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>

      <v-navigation-drawer
        v-model="drawer"
        fixed
        right
        width="680"
        temporary>
        <v-card flat>
          <div class="d-flex align-center" style="padding: 25px 30px 0px 30px !important;">
            <div class="body-1 text-second">
              Detail Kelas
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="drawer = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text class="pa-0 mt-3" style="height: calc(100vh - 138px); overflow-y: auto;">
            <div style="padding: 10px 30px 0px 30px !important;" v-if="process.detail">
              <v-skeleton-loader
                :loading="process.detail"
                class=""
                type="text">
              </v-skeleton-loader>
              <div class="d-flex mt-3">
                <v-skeleton-loader
                  style="width: 100px; height: 30px;"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
                <v-skeleton-loader
                  style="width: 100px; height: 30px;"
                  class="ml-5"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
                <v-skeleton-loader
                  style="width: 100px; height: 30px;"
                  class="ml-5"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
              </div>
              <div class="mt_15 mb-5">
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  style="width: 150px;"
                  type="text">
                </v-skeleton-loader>
              </div>
              <v-row class="mb-1 px-4">
                <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
                  <v-list three-line style="background: transparent;" class="py-0">
                    <v-list-item class="py-0 pl-0">
                      <v-list-item-avatar
                        size="100"
                        tile>
                        <v-skeleton-loader
                          class="mx-auto"
                          style="width: 100px; height: 100px;"
                          type="image"
                          :loading="process.detail"
                        ></v-skeleton-loader>
                      </v-list-item-avatar>
                      <v-list-item-content class="pb-0 pt-1">
                        <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                          <div class="text-right ml-auto mt-5">
                            <v-skeleton-loader
                              :loading="process.run"
                              type="paragraph">
                            </v-skeleton-loader>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="5" class="mt-2 pr-0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
                  <div class="text-right ml-auto mt-2 d-flex justify-end">
                    <v-skeleton-loader
                      :loading="process.run"
                      type="chip">
                    </v-skeleton-loader>
                    <v-skeleton-loader
                      class="ml-5"
                      :loading="process.run"
                      type="chip">
                    </v-skeleton-loader>
                    <v-skeleton-loader
                      :loading="process.run"
                      class="ml-5"
                      type="chip">
                    </v-skeleton-loader>
                  </div>
                  <div class="text-right ml-auto mt-2 d-flex justify-end">
                    <v-skeleton-loader
                      style="width: 100px; height: 40px;"
                      :loading="process.detail"
                      type="image">
                    </v-skeleton-loader>
                    <v-skeleton-loader
                      style="width: 100px; height: 40px;"
                      class="ml-5"
                      :loading="process.detail"
                      type="image">
                    </v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
              <div class="mb-2">
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  style="width: 150px;"
                  type="text">
                </v-skeleton-loader>
              </div>
              <div>
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  type="paragraph">
                </v-skeleton-loader>
              </div>
              <div class="mt_20 mb-2">
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  style="width: 150px;"
                  type="text">
                </v-skeleton-loader>
              </div>
              <div>
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  type="paragraph">
                </v-skeleton-loader>
              </div>
              <div class="mt_20 mb-2">
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  style="width: 150px;"
                  type="text">
                </v-skeleton-loader>
              </div>
              <div>
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  type="paragraph">
                </v-skeleton-loader>
              </div>
              <div class="mt_23">
                <v-skeleton-loader
                  :loading="process.detail"
                  class=""
                  type="text">
                </v-skeleton-loader>
              </div>
              <div class="mt-4 mb-5">
                <v-skeleton-loader
                  style="height: 55px;"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
              </div>
              <div class="mb-5">
                <v-skeleton-loader
                  style="height: 55px;"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
              </div>
            </div>
            <div style="padding: 0px 30px 0px 30px !important;" v-if="!process.detail">
              <div class="fs-20 text-third font-weight-bold mb-3">
                {{ detail.name }}
              </div>
              <v-btn
                elevation="0"
                color="#27ae6038"
                height="30"
                width="90"
                v-if="detail.active_bool"
                class="text-capitalize color-first body-2">
                Published
              </v-btn>
              <v-btn
                elevation="0"
                color="#fb8c0052"
                height="30"
                width="90"
                v-if="!detail.active_bool"
                class="text-capitalize warning--text body-2">
                Draft
              </v-btn>
              <v-btn
                elevation="0"
                disabled
                height="30"
                class="text-capitalize body-2 ml_15">
                {{ detail.category_name }}
              </v-btn>
              <v-btn
                elevation="0"
                disabled
                height="30"
                v-if="detail.group_name !== ''"
                class="text-capitalize body-2 ml_15">
                {{ detail.group_name }}
              </v-btn>

              <div class="text-second body-2 mt_15 mb-5">
                Terakhir diubah pada {{ detail.created_datetime | datetimemonth }}
              </div>

              <v-row  class="border_radius_7" style="padding: 17px 20px 23px 20px;">
                <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'px-0 py-0' : 'pt-0'">
                  <v-list three-line style="background: transparent;" class="py-0">
                    <v-list-item class="py-0 px-0">
                      <v-list-item-avatar
                        size="105"
                        class="my-0"
                        tile>
                        <v-img 
                          :src="detail.img_url" style="border-radius: 15px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="pb-0 pt-0">
                        <div class="d-flex align-center body-2 text-second">
                          <div class="text-first font-weight-bold">
                            5.0
                          </div>
                          <div class="mx-2">
                            <v-rating
                              :value="5"
                              background-color="white"
                              color="#e67e22"
                              dense
                              half-increments
                              hover
                              size="20">
                            </v-rating>
                          </div>
                        </div>
                        <div class="text-first font-weight-bold text-capitalize mt-0 mb-0 body-1">
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(detail.discount_percent) > 0">
                            {{ detail.price | price }}
                          </span>
                          <div class="font-weight-bold red--text">
                            <span v-if="parseInt(detail.discount_percent) > 0">
                              (-{{ parseInt(detail.discount_percent) }}%)
                            </span>
                            <span class="text-first" v-if="parseInt(detail.price) > 0">
                              {{ detail.price_discount | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pr-0 py-0' : 'pt-0'">
                  <div class="text-first body-1 font-weight-bold mt-0 d-flex justify-end">
                    <v-list style="background: transparent; width: 60px;">
                      <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                        <v-list-item-content class="py-0">
                          <div class="font-weight-bold body-1 text-right text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                            <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              Bab
                            </div>
                            {{ detail.content_count }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list style="background: transparent; width: 100px;">
                      <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-8">
                        <v-list-item-content class="py-0">
                          <div class="font-weight-bold body-1 text-right text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                            <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              Materi 
                            </div>
                            {{ detail.content_sub_count }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list style="background: transparent; width: 120px;">
                      <v-list-item class="py-0">
                        <v-list-item-content class="py-0">
                          <div class="font-weight-bold body-1 text-right text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                            <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              Terjual
                            </div>
                            {{ detail.subscribed_count }}x
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-col>
              </v-row>

              <div class="mt_21 mb-2 text-third fs-16 font-weight-bold">
                Deskripsi Singkat
              </div>
              <div class="text-second fs-16 line-text-first">
                {{ detail.description }}
              </div>
              <div class="mt_20 mb-2 text-third fs-16 font-weight-bold">
                Deskripsi Kelas
              </div>
              <div class="text-second fs-16 line-text-first">
                {{ detail.description_long }}
              </div>
              <div class="mt_20 mb-2 text-third fs-16 font-weight-bold">
                Yang Dipelajari
              </div>
              <div class="text-second fs-16 line-text-first">
                {{ detail.subject }}
              </div>

              <!-- <div class="d-flex align-center mt_23">
                <div class="text-first fs-16 font-weight-bold">
                  {{ detail.content_count }} Materi • {{ detail.content_sub_count }} Sub materi • Total durasi 38h 28m
                </div>
                <div class="ml-auto">
                  <v-btn
                    text
                    color="#505050"
                    @click="all()"
                    class="text-capitalize">
                    <u>Expand all</u>
                  </v-btn>
                </div>
              </div> -->
              <div class="d-flex align-center mt_23">
                <div class="text-first fs-16 font-weight-bold">
                  {{ detail.content_count }} Bab • {{ detail.content_sub_count }} Materi 
                  <!-- • Total durasi 38h 28m -->
                </div>
                <div class="ml-auto">
                  <v-btn
                    text
                    color="#505050"
                    @click="!openAll ? all() : panel = []; !openAll ? openAll = true :  openAll = false;"
                    class="text-capitalize">
                    <u v-if="!openAll">Expand all</u>
                    <u v-if="openAll">Close all</u>
                  </v-btn>
                </div>
              </div>

              <div class="mt-4">
                <div>
                  <v-expansion-panels flat class="mb-3" multiple v-model="panel">
                    <v-expansion-panel class="mb-3" v-for="(materi, i) in detail.content" :key="i">
                      <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: 55px;">
                        <template v-slot:actions>
                          <div>
                            <v-icon class="px-0" style="order: 0;" color="#7B7B7B">
                              $expand
                            </v-icon>
                          </div>
                        </template>
                        <v-list dense style="order: 1;" class="py-0 pl-6" color="transparent">
                          <v-list-item class="py-0 px-0">
                            <div class="d-flex align-center" style="width: 100%;">
                              <div class="body-1 ml-0 font-weight-bold text-capitalize">
                                {{ materi.title }}
                              </div>
                              <div class="body-2 text-first ml-auto">
                                <!-- 11 lectures - 26min -->
                              </div>
                            </div>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="non-padding-expansion">
                        <div style="padding: 17px 20px;">
                          <div class="d-flex">
                            <div v-if="materi.file_url !== ''">
                              <div
                                style="min-width: 45px; width: 45px; padding-top: 23.5px">
                                <v-icon 
                                  size="25"
                                  :color="'grey'">
                                  {{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                </v-icon>
                              </div>
                            </div>
                            <div>
                              <div class="d-flex">
                                <div class="mr-4">
                                  <v-img
                                    width="75"
                                    height="75"
                                    class="d-flex align-center justify-center"
                                    gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                    :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                                    style="border-radius: 5px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#FF3177">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <div class="body-1 font-weight-light text-second"
                                  style="line-height: 1.75;">
                                  <div v-if="materi.description !== ''">
                                    <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="materi.description.length > 150">
                                      <div v-if="materi.preview_img_url === ''">
                                        <span class="body-2" style="white-space: pre-line;" v-html="materi.description.substring(0, 150) + '...'">
                                        </span>
                                        <span class="text-second cursor-pointer mt-5" @click="materi.preview_img_url = 'oke'"><u>Selengkapnya</u></span>
                                      </div>
                                      <div v-if="materi.preview_img_url !== ''">
                                        <span class="body-2 mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                        <span class="text-second cursor-pointer mt-0" @click="materi.preview_img_url = ''"><u>Tutup</u></span>
                                      </div>
                                    </div>
                                    <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="materi.description.length <= 150">
                                      <div class="body-2" style="white-space: pre-line;" v-html="materi.description"></div>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="ml-auto pl-6" v-if="materi.preview_bool" style="color: #4ab762; padding-top: 23.5px;">
                                  Preview
                                </div> -->
                              </div>
                              <div v-for="(sub_materi, index) in materi.sub" :key="index">
                                <div style="padding: 17px 0 17px 20px;">
                                  <div class="d-flex">
                                    <div>
                                      <div
                                        style="min-width: 75px; width: 75px; padding-top: 23.5px">
                                        <v-icon 
                                          size="25"
                                          :color="'grey'">
                                          {{ sub_materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                        </v-icon>
                                      </div>
                                    </div>
                                    <div class="mr-4">
                                      <v-img
                                        width="75"
                                        height="75"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="sub_materi.img_url === '' ? detail.img_url : sub_materi.img_url"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </div>

                                    <div class="body-1 font-weight-light text-second">
                                      <span
                                        class="text-second">
                                        <div class="body-2 font-weight-bold mb-1">
                                          {{ sub_materi.title }}
                                        </div>
                                        <div v-if="sub_materi.description !== ''">
                                          <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="sub_materi.description.length > 90">
                                            <div v-if="sub_materi.preview_img_url === ''">
                                              <span class="body-2" style="white-space: pre-line;" v-html="sub_materi.description.substring(0, 90) + '...'">
                                              </span>
                                              <span class="text-second cursor-pointer mt-5" @click="sub_materi.preview_img_url = 'oke'"><u>Selengkapnya</u></span>
                                            </div>
                                            <div v-if="sub_materi.preview_img_url !== ''">
                                              <span class="body-2 mr-3" style="white-space: pre-line;" v-html="sub_materi.description"></span>
                                              <span class="text-second cursor-pointer mt-0" @click="sub_materi.preview_img_url = ''"><u>Tutup</u></span>
                                            </div>
                                          </div>
                                          <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="sub_materi.description.length <= 90">
                                            <div class="body-2" style="white-space: pre-line;" v-html="sub_materi.description"></div>
                                          </div>
                                        </div>
                                      </span>
                                    </div>

                                    <!-- <div class="ml-auto pl-6" v-if="sub_materi.preview_bool" style="color: #4ab762; padding-top: 23.5px;">
                                      Preview
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- <v-expansion-panel>
                      <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: 55px;">
                        <template v-slot:actions>
                          <div>
                            <v-icon class="px-0" style="order: 0;" color="#7B7B7B">
                              $expand
                            </v-icon>
                          </div>
                        </template>
                        <v-list dense style="order: 1;" class="py-0 pl-6" color="transparent">
                          <v-list-item class="py-0 px-0">
                            <div class="d-flex align-center" style="width: 100%;">
                              <div class="body-1 ml-0 font-weight-bold text-capitalize">
                                Working with Contents
                              </div>
                              <div class="body-2 text-first ml-auto">
                                11 lectures - 26min
                              </div>
                            </div>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="non-padding-expansion">
                        <div style="padding: 17px 20px;">
                          <div class="d-flex align-center mb-3">
                            
                            <div class="">
                              <v-img
                                width="75"
                                height="75"
                                class="d-flex align-center justify-center"
                                gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                :src="require('@/assets/publichome/content_image_6.png')"
                                style="border-radius: 5px;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div class="body-1 ml_18 font-weight-light text-second"
                              style="line-height: 1.75;">
                              <span
                                class="three-line text-second">
                                React Native is an excellent solution for developing apps on mobile in a fraction of the time it
                                takes to make an equivalent iOS or Swift app.
                                You'll love seeing your changes instantly
                                appear on your own device, rather than waiting for Swift/Java code to
                              </span>
                            </div>
                          </div>

                          <div>
                            <div class="d-flex align-center mb-3">
                              <div
                                class="text-center"
                                style="min-width: 80px; width: 80px;">
                                <v-icon 
                                  size="25"
                                  :color="'grey'">
                                  mdi-television-play
                                </v-icon>
                              </div>
                              
                              <div class="ml_18">
                                <v-img
                                  width="75"
                                  height="75"
                                  class="d-flex align-center justify-center"
                                  gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                  :src="require('@/assets/publichome/content_image_6.png')"
                                  style="border-radius: 5px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#FF3177">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>

                              <div class="body-1 ml_18 font-weight-light text-second"
                                style="line-height: 1.75;">
                                <span
                                  class="three-line text-second">
                                  React Native is an excellent solution for developing apps on mobile in a fraction of the time it
                                  takes to make an equivalent iOS or Swift app.
                                  You'll love seeing your changes instantly
                                  appear on your own device, rather than waiting for Swift/Java code to
                                </span>
                              </div>

                              <div class="ml-auto pl-6" style="color: #4ab762;">
                                Preview
                              </div>
                            </div>
                            <div class="d-flex align-center">
                              <div
                                class="text-center"
                                style="min-width: 80px; width: 80px;">
                                <v-icon 
                                  size="25"
                                  :color="'grey'">
                                  mdi-television-play
                                </v-icon>
                              </div>
                              
                              <div class="ml_18">
                                <v-img
                                  width="75"
                                  height="75"
                                  class="d-flex align-center justify-center"
                                  :src="require('@/assets/publichome/content_image_6.png')"
                                  style="border-radius: 5px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#FF3177">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>

                              <div class="body-1 ml_18 font-weight-light text-second"
                                style="line-height: 1.75;">
                                <span
                                  class="three-line text-second">
                                  React Native is an excellent solution for developing apps on mobile in a fraction of the time it
                                  takes to make an equivalent iOS or Swift app.
                                  You'll love seeing your changes instantly
                                  appear on your own device, rather than waiting for Swift/Java code to
                                </span>
                              </div>
                              <div class="ml-auto pl-6" style="color: #4ab762;">
                                Preview
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel> -->
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions style="padding: 18px 30px 17px 30px !important;">
            <v-row>
              <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 2 : 4">
                <v-btn
                  elevation="0"
                  color="#4ab762"
                  height="40"
                  :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 150"
                  @click="toPreview(detail)"
                  :disabled="process.detail"
                  class="text-capitalize white--text px-4">
                  <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                    Preview
                  </span>
                  <span v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                    <v-icon color="white">mdi-eye</v-icon>
                  </span>
                </v-btn>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 10 : 8" class="text-right">
                <v-btn
                  elevation="0"
                  color="primary"
                  outlined
                  :disabled="process.detail"
                  :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 110"
                  :to="`/account/product/form?data=${detail.id}`"
                  height="40"
                  class="text-capitalize">
                  <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                    Ubah
                  </span>
                  <span v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                    <v-icon color="primary">mdi-pencil-outline</v-icon>
                  </span>
                </v-btn>
                <v-btn
                  elevation="0"
                  color="warning"
                  outlined
                  v-if="detail.active_bool"
                  @click="deletes = detail; dialog.status = true"
                  :disabled="process.detail"
                  :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 110"
                  height="40"
                  class="text-capitalize ml_20">
                  <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                    Unpublish
                  </span>
                  <span v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                    <v-icon color="warning">mdi-upload-off</v-icon>
                  </span>
                </v-btn>
                <v-btn
                  elevation="0"
                  color="green"
                  outlined
                  v-if="!detail.active_bool"
                  :disabled="process.detail"
                  @click="deletes = detail; dialog.status = true"
                  :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 110"
                  height="40"
                  class="text-capitalize ml_20">
                  <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                    Publish
                  </span>
                  <span v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                    <v-icon color="green">mdi-upload</v-icon>
                  </span>
                  <!-- Publish
                  <span>
                    <v-icon>mdi-upload-off</v-icon>
                  </span> -->
                </v-btn>
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  @click="deletes = detail; dialog.cancel = true"
                  :disabled="process.detail"
                  :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 110"
                  height="40"
                  class="text-capitalize ml_20">
                  <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                    Hapus
                  </span>
                  <span v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                    <v-icon color="red">mdi-delete</v-icon>
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-navigation-drawer>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        page: 1,
        dialog: {
          cancel: false,
          form: false,
          product: false,
          status: false
        },
        drawer: false,
        allEtalase: false,
        avatar: null,
        panel: 0,
        allEtalaseLength: 0,
        detail_items: [],
        selected: {
          etalase: '',
          product: [],
          page: 'list',
          detail: '',
          item: ""
        },
        openAll: false,
        category: [],
        etalase: [],
        filter: {
          etalase: "",
          category: "",
          search: ""
        },
        deletes: {},
        form: {
          id: "",
          name: "",
          product: [],
          img_url: ""
        },
        snackbar: false,
        timeout: 5000,
        contentEmpty: "",
        contentEmptyProduct: "",
        detail: {},
        list: [],
        pagination: {},
        kelas: [],
        process: {
          form: false,
          run: true,
          kelas: false,
          detail: false,
          avatar: false,
          product: false,
          cancel: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        // SEO
        content: {
          url: '/account/product',
          title: 'Produk Elearning',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.img_url = ''
          }
        },
        deep: true
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    mounted () {
      this.fetch()
      this.fetchCategory()
      this.fetchEtalase()
    },
    methods: {
      async fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search,
          category_id: this.filter.category,
          group_id: this.filter.etalase
        }
        this.$axios.$get(`api/publisher/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            if (this.filter.search !== '' || this.filter.search !== null || this.filter.category !== '' || this.filter.etalase !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail (item) {
        this.process.detail = true
        let params = {
          id: item.id
        }
        this.$axios.$get(`api/publisher/course/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      fetchCategory () {
        this.process.run = true
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/course_category`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.category = [
              {
                id: '',
                img_url: '',
                name: 'Disemua kategori'
              }
            ]
            for (let i in response.results.data) {
              this.category.push(
                {
                  id: response.results.data[i].id,
                  img_url: response.results.data[i].img_url,
                  name: response.results.data[i].name
                }
              ) 
              
            }
          }else{
            this.process.run = false
          }
        })
      },
      fetchEtalase () {
        this.process.run = true
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/publisher/course_group`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.etalase = [
              {
                id: '',
                name: 'Disemua etalase'
              }
            ]
            for (let i in response.results.data) {
              this.etalase.push(
                {
                  id: response.results.data[i].id,
                  name: response.results.data[i].name
                }
              ) 
              
            }
          }else{
            this.process.run = false
          }
        })
      },
      all () {
        this.panel = [...Array(this.detail.content).keys()].map((k, i) => i)
      },
      toPreview(detail) {
        // storeToken(token){
            localStorage.setItem("setContent", JSON.stringify(detail));
          // }
          window.open(`/preview`)
      },
      async toDeletes() {
        this.process.cancel = true
        await this.$axios.$post(`api/publisher/course/delete`, 
        {
          id: this.deletes.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cancel = false
            this.dialog.cancel = false
            this.fetch()
            this.success.message = "Berhasil menghapus Kelas"
            this.snackbar = true
          } else {
            this.process.cancel = false
            this.error.message = response.message
          }
        })
      },
      async toUpdateStatus() {
        this.process.cancel = true
        await this.$axios.$post(`api/publisher/course/publish`, 
        {
          id: this.deletes.id,
          active_bool: this.deletes.active_bool ? false : true
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cancel = false
            this.dialog.status = false
            this.fetch()
            this.success.message = `Berhasil ${this.deletes.active_bool ? 'diunpublish' : 'dipublish'}`
            this.snackbar = true
          } else {
            this.process.cancel = false
            this.error.message = response.message
          }
        })
      }
    },
  };
</script>