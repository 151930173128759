<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
      </v-snackbar>
      <v-dialog
        persistent
        scrollable
        v-if="dialog.cancel"
        v-model="dialog.cancel"
        width="420">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-h5 text-second text--darken-2 font-weight-bold">
              Hapus Wishlist
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                :disabled="process.form"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.cancel = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="fs-14 title font-weight-reguler pt-8 pb-3 text-center">
              Apakah anda yakin ingin menghapus kelas <b>{{ detail.name }}</b> ini dari daftar wishlist Anda?
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="#4ab762"
                  block
                  :disabled="process.form"
                  :loading="process.form"
                  @click="removeWishlist()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, Hapus
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.form"
                  block
                  @click="dialog.cancel = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              :loading="process.form"
              :disabled="process.form"
              @click="removeWishlist()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Ya, Hapus
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              :disabled="process.form"
              @click="dialog.cancel = false"
              class="text-capitalize white--text ml-2">
              Tidak
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <v-dialog
        persistent
        scrollable
        v-if="dialog.cart"
        v-model="dialog.cart"
        width="420">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-h5 text-second text--darken-2 font-weight-bold">
              Beli Kelas
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.cart = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-first title font-weight-reguler pt-8 pb-3 text-center">
              Apakah anda yakin ingin membeli kelas ini, atau tambahkan ke keranjang??
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              to="/cart"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Beli Langsung
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="green"
              outlined
              width="120"
              to="/cart"
              class="text-capitalize white--text ml-2">
              <v-icon>mdi-plus</v-icon>
              Keranjang
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <v-dialog v-model="dialog.success"
      width="430"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      persistent>
      <v-card 
        height="530"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

          <v-col cols="12" class="pa-0">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  icon
                  @click="dialog.success = false; "
                  elevation="0"
                  class="color-green-second">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; " color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                Tambah Keranjang
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="250"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second title text-center mb-5">
                {{ success.message }}
              </div>
              <div class="text-center d-flex alogn-center justify-center">
                <v-btn
                  width="175"
                  color="#4ab762"
                  elevation="0"
                  to="/cart"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Lihat Keranjang
                </v-btn>
                <v-btn
                  width="100"
                  color="red"
                  outlined
                  elevation="0"
                  @click="dialog.success = false"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Tutup
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.cart"
      v-model="dialog.cart"
      width="400">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="title text-second text--darken-2 font-weight-bold">
            Beli Kelas
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.cart"
              class="text-capitalize px-0"
              @click="dialog.cart = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah anda yakin ingin menambahkan kelas <b>{{ detail.name }}</b> ini ke keranjang?
          </div>
          <v-alert 
            type="error" 
            text
            dense
            class="mb-0"
            prominent
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-row>
            <v-col cols="7">
              <v-btn
                elevation="0"
                color="#4ab762"
                block
                :disabled="process.cart"
                @click="addCart()"
                class="text-capitalize white--text px-4">
                <div class="d-flex align-center" style="width: 100%;">
                  <v-icon>mdi-check-circle-outline</v-icon>
                  <div class="ml-auto">
                    Beli Langsung
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                elevation="0"
                color="green"
                outlined
                width="120"
                block
                :disabled="process.cart"
                @click="addCart()"
                class="text-capitalize white--text ml-2">
                <v-icon>mdi-plus</v-icon>
                Keranjang
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#4ab762"
            width="220"
            @click="dialog.cart = false"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                Ya, Hapus
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            @click="dialog.cart = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn>
          <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

      <section>
        <v-row class="mb-7">
          <v-col cols="9" class="pt-0">
            <!-- <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div> -->
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb-12'">
          <div class="text-second" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 50%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari kelas…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 50%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data kelas
          </div>
        </div>
        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0">
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      class="mx-auto"
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        type="list-item">
                      </v-skeleton-loader>
                      <v-skeleton-loader
                        :loading="process.run"
                        type="paragraph">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <v-skeleton-loader
                class="mx-auto"
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="button">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list class="py-0">
              <v-list-item style="padding: 16px 20px; background: #f9f9f9; border-radius: 7px; border: 1px solid #f9f9f9;" class="hover_select mb-4">
                <v-list-item-content class="py-0">
                  <div class="d-flex align-center" style="flex-wrap: inherit;">
                    <div class="d-flex align-center">
                      <v-list-item class="px-0 pb-2" style="min-height: 20px;">
                        <v-list-item-avatar :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 25 : 18" class="mt-1 mb-0 mr-2">
                          <!-- <v-img v-if="l.class_type_id === '2'" :src="require('@/assets/icon/icon_official_merchant.svg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :size="13"
                                  :width="2"
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img> -->
                          <v-icon v-if="l.organization_img_url === ''">mdi-office-building</v-icon>
                          <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :size="13"
                                  :width="2"
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>
            
                        <v-list-item-content class="pt-1 pb-0">
                          <v-list-item-title class="text-first font-weight-bold" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'">{{ l.organization_name }} <span class="text-second" v-if="l.class_is_collection === '1'">[SUDAH ANDA BELI]</span></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <div class="text-second text--darken-2 ml-auto text-right" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1 ml-auto' : 'caption'">
                      <div>
                        Ditambahkan pada <span class="font-weight-bold">{{ l.created_datetime | datetimemonth }}</span>
                      </div>
                    </div>
                  </div>
                  <v-row class="pt-2 pb-2">
                    <v-col cols="2" class="pr-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'display-none' : ''">
                      <v-list style="background: transparent;">
                        <v-list-item class="py-0 px-0">
                        <v-list-item-avatar
                            size="105"
                            class="mt-0"
                            tile>
                            <v-img 
                              :src="l.img_url" style="border-radius: 8px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 12 : 10">
                      <v-row>
                        <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0 pb-0' : 'pt-0 mt-n2'">
                          <v-list style="background: transparent;">
                            <v-list-item class="py-0 pl-0">
                              <v-list-item-avatar :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 105 : 105" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'display-none' : 'mr-2'" tile>
                                <v-img 
                                  :src="l.img_url" style="border-radius: 8px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>
                              <v-list-item-content class="py-0">
                                <div class="font-weight-bold body-1 text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                  {{ l.name }}
                                  <div class="text-second text--darken-2 mt-0 line-text-first" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                    {{ l.description }}
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <div class="d-flex align-center text-second" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                            <div class="text-first font-weight-bold body-2">
                              {{ l.review_average }}
                            </div>
                            <div class="ml-2">
                              <v-rating
                                :value="l.review_average*1"
                                readonly
                                background-color="grey"
                                color="#e67e22"
                                dense
                                half-increments
                                hover
                                :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 25 : 18">
                              </v-rating>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" md="5" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pb-0' : 'pt-0'">
                          <div class="text-first body-1 font-weight-bold">
                            <v-list style="background: transparent;">
                              <v-list-item class="py-0 px-0">
                                <v-list-item-content class="py-0">
                                  <div class="body-2 font-weight-bold body-1 text-second text--darken-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'text-right'">
                                    <div class="body-2 text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'display-none' : ''">
                                      Harga
                                    </div>
                                    <div>
                                      <!-- <span class="text-second mr-2" v-if="parseInt(l.class_diskon_value) > 0">
                                        <v-chip
                                          class="font-weight-bold pa-2 mr-1"
                                          x-small
                                          color="red"
                                          label
                                          text-color="#FFFFFF">
                                          <span>
                                            {{ l.class_diskon_value }} %
                                          </span>
                                        </v-chip>
                                      </span> -->
                                      <!-- <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(l.class_diskon_value) > 0">
                                        {{ l.price }}
                                      </span> -->
                                      <!-- <span class="red--text mr-2" v-if="parseInt(l.class_diskon_value) > 0">
                                        ({{ l.class_diskon_value }}%)
                                      </span> -->
                                      <span class="font-weight-bold text-first">
                                        {{ l.price | price }}
                                      </span>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-col>
                        <v-col cols="4" class="pt-0 pl-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'display-none' : ''">
                          <div class="d-flex align-center text-second">
                            <div class="text-first font-weight-bold body-2">
                              {{ l.review_average }}
                            </div>
                            <div class="ml-2">
                              <v-rating
                                :value="l.review_average*1"
                                readonly
                                background-color="grey"
                                color="#e67e22"
                                dense
                                half-increments
                                hover
                                size="20">
                              </v-rating>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" md="8" class="pt-0">
                        <div :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-right ml-auto' : 'text-right ml-auto pt-0 mt-n3'">
                          <v-icon size="20" @click="detail = l; dialog.cancel = true;" color="red">mdi-block-helper</v-icon>
                          <v-btn
                            outlined
                            elevation="0"
                            :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                            :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                            color="#9e9e9e"
                            :to="`/elearning/${l.course_id}`"
                            class="text-capitalize ml-3">
                            Lihat Detail
                          </v-btn>
                          <v-btn
                            outlined
                            elevation="0"
                            :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                            :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                            color="#4ab762"
                            @click="detail= l; dialog.cart = true"
                            class="text-capitalize ml-3">
                            Beli Kelas
                          </v-btn>
                          <v-btn
                            elevation="0"
                            :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                            :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                            color="#4ab762"
                            v-if="l.class_is_collection === '1'"
                            class="text-capitalize white--text ml-4">
                            Lanjutkan Belajar
                          </v-btn>
                        </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- <div>
                    <div class="text-right">
                      <v-icon size="20" color="red" @click="dialog.cancel = true">mdi-block-helper</v-icon>
                      <v-btn
                        outlined
                        elevation="0"
                        width="180"
                        color="#9e9e9e"
                        class="text-capitalize ml-3">
                        Lihat Detail
                      </v-btn>
                    </div>
                  </div> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          sort: 'Paling baru'
        },
        items: ['Paling baru'],
        format: 'row', 
        page: 1,
        dialog: {
          cart: false,
          cancel: false,
          success: false
        },
        detail: {},
        snackbar: false,
        timeout: 2000,
        pagination: {},
        contentEmpty: "",
        list: [],
        success: {
          message: ""
        },
        error: {
          message: ""
        },
        process: {
          run: false,
          form: false,
          cart: false
        },
        // SEO
        content: {
          url: '/account/wishlist/elearning',
          title: 'Favorit Kelas',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search
        }
        this.$axios.$get(`api/wishlist`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            if (this.filter.search !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      async removeWishlist() {
        this.process.form = true
        await this.$axios.$post(`api/wishlist/delete`, 
        {
          id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.success.message = "Berhasil dihapus dari wishlist"
            this.snackbar = true
            this.fetch()
            this.dialog.cancel = false
            this.process.form = false
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async addCart () {
        this.process.cart = true
        await this.$axios.$post(`api/cart`, 
        {
          course_id: this.detail.course_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.dialog.cart = false
            this.success.message = "Berhasil menambahkab kelas ini ke keranjang"
            this.dialog.success = true
            this.process.cart = false
            this.$store.commit('header/setCart', this.headers.cart + 1)
          } else {
            this.process.cart = false
            this.error.message = response.message
          }
        })
      }
    },
  };
</script>