<template>
  <div>
    <v-container>
      <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
        <section class="py-10">
          <v-row>
            <v-col cols="9">
              <div class="text-second body-1">
                Publisher |  Detail
              </div>
              <div v-if="process.run" class="mb-10">
                <div>
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="list-item"
                  ></v-skeleton-loader>
                </div>
                <div class="mb-6">
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <v-row>
                  <v-col cols="4" v-for="k in 3" :key="k">
                    <div>
                      <v-skeleton-loader
                        class="mx-auto"
                        max-width="100%"
                        height="100"
                        :loading="process.run"
                        type="image"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                </v-row>
                <div class="mt-6">
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="list-item"
                  ></v-skeleton-loader>
                </div>
                <div class="mb-6">
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div v-if="!process.run">
                <div class="d-flex align-center mt-5 mb-0">
                  <div class="text-first text-h5 font-weight-bold">
                    {{ detail.publisher_name }}
                  </div>
                  <!-- <div class="ml-auto">
                    <v-list-item class="px-0">
                      <v-list-item-avatar size="25" class="mr-2">
                        <v-img :src="require('@/assets/icon/icon_official_merchant.svg')"></v-img>
                      </v-list-item-avatar>
          
                      <v-list-item-content>
                        <v-list-item-title class="text-first font-weight-bold">Official Partner</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div> -->
                </div>
                <div class="mb-5" style="font-size: 21px; line-height: 36px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                  {{ detail.description === '' ? "-" : detail.description  }}
                </div>
                <v-row>
                  <v-col cols="4">
                    <div style="background-color: #f5f5f5;" class="pa-6">
                      <div class="text-first font-weight-bold">
                        Class Sold
                      </div>
                      <div class="text-first text-h5 font-weight-bold">
                        {{ detail.total_course_sold}}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div style="background-color: #f5f5f5;" class="pa-6">
                      <div class="text-first font-weight-bold">
                        Total students
                      </div>
                      <div class="text-first text-h5 font-weight-bold">
                        {{ detail.total_student }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div style="background-color: #f5f5f5;" class="pa-6">
                      <div class="text-first font-weight-bold">
                        Rating Averages
                      </div>
                      <div class="d-flex align-center body-2 text-second">
                        <div class="text-second text-h6 text--darken-3 font-weight-bold">
                          {{ detail.review_average }}
                        </div>
                        <div class="mx-2">
                          <v-rating
                            :value="detail.review_average*1"
                            readonly
                            background-color="grey"
                            color="#e67e22"
                            dense
                            half-increments
                            hover
                            size="30">
                          </v-rating>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div class="mt-7">
                  <div class="text-first text-h6 font-weight-bold">
                    Tentang Publisher
                  </div>
                  <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
                </div>
                <div class="mt-2 mb-7" style="font-size: 16px; line-height: 26px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                  {{ detail.about === '' ? '-' : detail.about }}
                </div>
      
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="grey darken-3"
                  class="mt-6 mb-8"
                  v-on:change="fetchClass()"
                  dense>
                  <v-tabs-slider color="#4ab762"></v-tabs-slider>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-5"
                    style="min-width: 55px;">
                    <div>
                      <div class="body-1 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                        <span v-if="item.id !== ''">
                          {{ item.name }} ({{ item.count }})
                        </span>
                        <span v-if="item.id === ''">
                          {{ item.name }} ({{ allCourse }})
                        </span>
                      </div>
                    </div>
                  </v-tab>
                </v-tabs>
              </div>
    
              <div v-if="process.kelas">
                <v-row>
                  <v-col cols="12" md="4" v-for="loader in 9" :key="loader">
                    <v-skeleton-loader
                      class="mx-auto"
                      max-width="300"
                      :loading="process.kelas"
                      type="card"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </div>
              <div v-if="!process.kelas">
                <v-row>
                  <v-col cols="12" md="4" v-for="(l, index) in list" :key="index" style="margin-bottom: 45px;">
                    <v-hover v-slot="{ hover }">
                      <v-card flat>
                        <v-img :src="l.img_url" style="border-radius: 15px;" height="320">
                          <div class="badge-overlay-circle" v-if="l.discount_bool">
                            <span class="top-right-circle badge-circle">
                              <div
                                class="font-weight-bold "
                                style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                                <span v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</span> 
                                <span v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</span>
                                <span v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</span>
                              </div>
                            </span>
                          </div>
                        </v-img> 
                        <div class="text-first fs-18 font-weight-bold mt-4 two-line" style="height: 48px;">
                          {{ l.name }}
                        </div> 
                        <v-list-item class="px-0">
                          <v-list-item-avatar size="25" color="#ebebeb">
                            <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                            <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                          </v-list-item-avatar>
              
                          <v-list-item-content>
                            <v-list-item-title class="text-second fs-14">{{ l.organization_name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <div class="d-flex align-center body-2 text-second">
                          <div class="text-first font-weight-bold">
                            {{ l.review_average }}
                          </div>
                          <div class="mx-2">
                            <v-rating
                              :value="l.review_average*1"
                              background-color="grey"
                              color="#e67e22"
                              dense
                              readonly
                              half-increments
                              hover
                              size="20">
                            </v-rating>
                          </div>
                          <div class="">
                            ({{ l.review_count }})
                          </div>
                        </div>
                        <div class="text-first fs-16 font-weight-bold pt-2">
                          <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                            {{ l.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span class="text-first" v-if="parseInt(l.price) > 0">
                              <span v-if="!l.discount_bool">
                                {{ l.price | price }}
                              </span>
                              <span v-if="l.discount_bool">
                                {{ l.price_discount | price }}
                              </span>
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div> 
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                            style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                            <v-img :src="l.img_url" style="height:200px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                            <div class="px-3">
                              <div class="white--text fs-18 font-weight-bold mb-2 two-line mt-3" style="height: 48px; line-height: 1.5;">
                                {{ l.name }}
                              </div> 
                              <div class="body-2 mb-2 white--text mt-4 three-line" style="height: 60px;">
                                {{ l.description }}
                              </div>
                              <div class="white--text text--darken-3 fs-16 font-weight-bold py-1">
                                <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                                  {{ l.price | price }}
                                </span>
                                <span class="font-weight-bold">
                                  <span class="white--text" v-if="parseInt(l.price) > 0">
                                    <span v-if="!l.discount_bool">
                                      {{ l.price | price }}
                                    </span>
                                    <span v-if="l.discount_bool">
                                      {{ l.price_discount | price }}
                                    </span>
                                  </span>

                                  <span class="white--text" v-else>
                                    Gratis
                                  </span>
                                </span>
                              </div> 
                              <div class="d-flex align-center">
                                <div>
                                  <v-btn
                                    color="white"
                                    width="159"
                                    height="40"
                                    elevation="0"
                                    :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                                    class="text-capitalize">
                                    Lihat Kelas
                                  </v-btn>
                                </div>
                                <div class="ml-auto">
                                  <v-icon color="white" size="25" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" class="ml-3" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                                  <span v-if="token">
                                    <v-icon size="25" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                                    <v-icon size="25" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                                  </span>
                                  <span v-if="!token">
                                    <v-icon size="25" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-expand-transition>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </div>
              <div class="text-right mt-16" v-if="!process.kelas && list.length < 1">
                <Empty v-model="contentEmpty"/>
              </div>
              <Pagination 
                @changePage="fetchClass($event)" 
                :model="pagination"
                class="pt-6"
                v-show="pagination.total_page > 1"/>
            </v-col>
            <v-col cols="3">
              <!-- <v-carousel v-model="model" hide-delimiters :show-arrows="false" height="auto">
                <v-carousel-item
                  v-for="(elearning, i) in 5"
                  :key="i">
                  <v-img :src="require('@/assets/publichome/content_image_5.png')"></v-img>
                </v-carousel-item>
              </v-carousel> -->
              <div v-if="process.run">
                <div class="mb-5">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    :loading="process.run"
                    type="image"
                  ></v-skeleton-loader>
                </div>
                <div v-for="m in 7" :key="m" class="mb-2">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    height="70"
                    :loading="process.run"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div v-if="!process.run">
                <div>
                  <v-img
                    :src="detail.publisher_img_url"
                    :lazy-src="detail.publisher_img_url"
                    v-if="detail.publisher_img_url !== ''"
                    height="280"
                    style="border-radius: 15px;"
                    class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <div v-if="detail.publisher_img_url === undefined || detail.publisher_img_url === ''" style="background-color: #4ab762; color: #fff; width: 100%; height: 280px;" class="rounded-lg">
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    style="font-size: 9rem"
                    justify="center"
                  >
                    {{ getKarekter(detail.publisher_name) }}
                  </v-row>

                </div>
                <!-- <div class="text-center mt-2"> 
                  <v-btn 
                    v-for="(item, i) in 5"
                    :key="i"
                    width="15"
                    height="15"
                    elevation="0"
                    x-small
                    fab
                    :color="model === i ? '#4ab762' : '#5baf6036'"
                    class="ma-2"
                    @click="model = i">
                  </v-btn>
                </div> -->
                <div class="mt-4">
                  <!-- <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #d35400; border-radius: 6px;">
                    <v-icon color="#d35400">mdi-heart-outline</v-icon>
                    <div class="ml-auto" style="color: #d35400;">
                      Favoritkan
                    </div>
                  </div>
      
                  <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #757575; border-radius: 6px;">
                    <v-icon color="#757575">mdi-share-variant</v-icon>
                    <div class="ml-auto" style="color: #757575;">
                      Bagikan
                    </div>
                  </div> -->
      
                  <a target="_blank" :href="detail.url_web" v-if="detail.url_web !== ''">
                    <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #757575; border-radius: 6px;">
                      <v-icon color="#757575">mdi-link</v-icon>
                      <div class="ml-auto" style="color: #757575;">
                        Website
                      </div>
                    </div>
                  </a>
      
                  <a target="_blank" :href="detail.url_youtube" v-if="detail.url_youtube !== ''">
                    <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #757575; border-radius: 6px;">
                      <v-icon color="#757575">mdi-youtube</v-icon>
                      <div class="ml-auto" style="color: #757575;">
                        Youtube
                      </div>
                    </div>
                  </a>
      
                  <a target="_blank" :href="detail.url_instagram" v-if="detail.url_instagram !== ''">
                    <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #757575; border-radius: 6px;">
                      <v-icon color="#757575">mdi-instagram</v-icon>
                      <div class="ml-auto" style="color: #757575;">
                        Instagram
                      </div>
                    </div>
                  </a>
      
                  <a target="_blank" :href="detail.url_facebook" v-if="detail.url_facebook !== ''">
                    <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #757575; border-radius: 6px;">
                      <v-icon color="#757575">mdi-facebook</v-icon>
                      <div class="ml-auto" style="color: #757575;">
                        Facebook
                      </div>
                    </div>
                  </a>
      
                  <a target="_blank" :href="detail.url_twitter" v-if="detail.url_twitter !== ''">
                    <div class="d-flex align-center pa-3 mb-4 cursor-pointer" style="border: 1px solid #757575; border-radius: 6px;">
                      <v-icon color="#757575">mdi-twitter</v-icon>
                      <div class="ml-auto" style="color: #757575;">
                        Twitter
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </section>
      </div>

      <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
        <section class="mt-n5">
          <div v-if="process.run">
            <v-row>
              <v-col cols="12" md="3">
                <div class="mb-5">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="200"
                    :loading="process.run"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <div>
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="list-item"
                  ></v-skeleton-loader>
                </div>
                <div class="mb-6">
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <v-row>
                  <v-col cols="6" v-for="k in 2" :key="k">
                    <div>
                      <v-skeleton-loader
                        class="mx-auto"
                        max-width="100%"
                        height="100"
                        :loading="process.run"
                        type="image"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-skeleton-loader
                        class="mx-auto"
                        max-width="100%"
                        height="100"
                        :loading="process.run"
                        type="image"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                </v-row>
                <div class="mt-6">
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="list-item"
                  ></v-skeleton-loader>
                </div>
                <div class="mb-6">
                  <v-skeleton-loader
                    max-width="100%"
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="!process.run">
            <v-row>
              <v-col cols="12" md="3">
                <!-- <v-carousel v-model="model" hide-delimiters :show-arrows="false" height="auto">
                  <v-carousel-item
                    v-for="(elearning, i) in 5"
                    :key="i">
                    <v-img :src="require('@/assets/publichome/content_image_5.png')" style="width: 200px; margin: auto;"></v-img>
                  </v-carousel-item>
                </v-carousel> -->
                <div>
                  <v-img
                    :src="detail.publisher_img_url"
                    :lazy-src="detail.publisher_img_url"
                    v-if="detail.publisher_img_url !== ''"
                    height="200"
                    width="200"
                    style="border-radius: 15px;"
                    class="grey lighten-2 mx-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <div v-if="detail.publisher_img_url === undefined || detail.publisher_img_url === ''" style="background-color: #4ab762; color: #fff; width: 200px; height: 200px;" class="rounded-lg mx-auto">
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    style="font-size: 9rem"
                    justify="center"
                  >
                    {{ getKarekter(detail.publisher_name) }}
                  </v-row>

                </div>
                <!-- <div class="text-center mt-2"> 
                  <v-btn 
                    v-for="(item, i) in 5"
                    :key="i"
                    width="10"
                    height="10"
                    elevation="0"
                    x-small
                    fab
                    :color="model === i ? '#4ab762' : '#5baf6036'"
                    class="ma-2"
                    @click="model = i">
                  </v-btn>
                </div> -->
                <div class="mt-3 px-3 text-center">
                  <!-- <v-btn
                    fab
                    width="40"
                    @click="!token ? authDialog = 'login' : !detail.favorite_bool ? addWishlist() : removeWishlist()"
                    height="40"
                    elevation="0"
                    class="mx-1">
                    <v-icon :color="'#d35400'" v-if="token && !detail.favorite_bool">mdi-heart-plus-outline</v-icon>
                    <v-icon :color="'#d35400'" v-if="token && detail.favorite_bool">mdi-heart</v-icon>
                    <v-icon :color="'#d35400'" v-if="!token">mdi-heart-plus-outline</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    width="40"
                    height="40"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="grey">
                      mdi-share-variant
                    </v-icon>
                  </v-btn> -->
                  <v-btn
                    fab
                    width="40"
                    height="40"
                    target="_blank" :href="detail.url_web" v-if="detail.url_web !== ''"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="primary">
                      mdi-link
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    width="40"
                    target="_blank" :href="detail.url_youtube" v-if="detail.url_youtube !== ''"
                    height="40"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="red">
                      mdi-youtube
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    width="40"
                    height="40"
                    target="_blank" :href="detail.url_instagram" v-if="detail.url_instagram !== ''"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="#bc2a8d">
                      mdi-instagram
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    width="40"
                    height="40"
                    target="_blank" :href="detail.url_twitter" v-if="detail.url_twitter !== ''"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="#1DA1F2">
                      mdi-twitter
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    width="40"
                    height="40"
                    target="_blank" :href="detail.url_facebook" v-if="detail.url_facebook !== ''"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="#0077b5">
                      mdi-facebook
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <div class="text-second body-2">
                  Publisher |  Detail
                </div>
                <div class="d-flex align-center mt-5 mb-0">
                  <div class="text-first body-1 font-weight-bold">
                    {{ detail.publisher_name }}
                  </div>
                </div>
                <div class="text-second body-1 line-text-first font-weight-medium mb-5">
                  {{ detail.description === '' ? "-" : detail.description  }}
                </div>
                <v-row>
                  <v-col cols="6" md="4">
                    <div style="background-color: #f5f5f5;" class="pa-6">
                      <div class="text-first body-2 font-weight-bold">
                        Class Sold
                      </div>
                      <div class="text-first body-1 font-weight-bold">
                        {{ detail.total_course_sold}}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" md="4">
                    <div style="background-color: #f5f5f5;" class="pa-6">
                      <div class="text-first body-2 font-weight-bold">
                        Total students
                      </div>
                      <div class="text-first body-1 font-weight-bold">
                        {{ detail.total_student }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div style="background-color: #f5f5f5;" class="pa-6">
                      <div class="text-first body-2 font-weight-bold">
                        Rating Averages
                      </div>
                      <div class="d-flex align-center body-2 text-second">
                        <div class="text-first font-weight-bold">
                          {{ detail.review_average }}
                        </div>
                        <div class="mx-2">
                          <v-rating
                            :value="detail.review_averag*1"
                            background-color="grey"
                            color="#e67e22"
                            dense
                            half-increments
                            hover
                            size="20">
                          </v-rating>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div class="mt-7">
                  <div class="text-first body-1 font-weight-bold">
                    Tentang Publisher
                  </div>
                  <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
                </div>
                <div class="line-text-first text-second py-2 body-2 mt-2 mb-7">
                  {{ detail.about === "" ? "-" : detail.about }}
                </div>
      
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="grey darken-3"
                  class="mt-6 mb-8"
                  v-on:change="fetchClass()"
                  dense>
                  <v-tabs-slider color="#4ab762"></v-tabs-slider>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-5"
                    style="min-width: 55px;">
                    <div>
                      <div class="body-1 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                        <span v-if="item.id !== ''">
                          {{ item.name }} ({{ item.count }})
                        </span>
                        <span v-if="item.id === ''">
                          {{ item.name }} ({{ allCourse }})
                        </span>
                      </div>
                    </div>
                  </v-tab>
                </v-tabs>
      
                <div v-if="process.kelas">
                  <v-row>
                    <v-col cols="6" md="6" v-for="loader in 12" :key="loader">
                      <v-skeleton-loader
                        class="mx-auto"
                        max-width="300"
                        :loading="process.kelas"
                        type="card"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="!process.kelas">
                  <v-row>
                    <v-col cols="6" v-for="(l, index) in list" :key="index">
                      <v-hover v-slot="{ hover }">
                        <v-card flat style="background: #f5f5f5b5;" class="pa-0" height="345">
                          <v-img :src="l.img_url" style="height: 160px; border-radius: 15px;" >
                            <div class="badge-overlay-circle" v-if="l.discount_bool">
                              <span class="top-right-circle-small badge-circle-small">
                                <div
                                  class="font-weight-bold"
                                  style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                                  <div v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</div> 
                                  <div v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</div>
                                  <div v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</div>
                                </div>
                              </span>
                            </div>
                          </v-img> 
                          <div class="text-first body-1 font-weight-bold mt-4 two-line px-2" style="height: 50px;">
                            {{ l.name }}
                          </div> 
                          <v-list-item class="px-2" style="min-height: 25px;">
                            <v-list-item-avatar size="25" color="#ebebeb">
                              <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                              <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                            </v-list-item-avatar>
                
                            <v-list-item-content class="py-1">
                              <v-list-item-title class="text-second caption">{{ l.organization_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <div class="d-flex align-center body-2 px-2 text-second">
                            <div class="mr-2">
                              <v-rating
                                :value="l.review_average*1"
                                background-color="grey"
                                color="#e67e22"
                                dense
                                half-increments
                                hover
                                size="15">
                              </v-rating>
                            </div>
                            <div class="caption">
                              ({{ l.review_average }})
                            </div>
                          </div>
                          <div class="text-first fs-14 px-2 font-weight-bold py-2">
                            <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                              {{ l.price | price }}
                            </span>
                            <span class="font-weight-bold red--text">
                              <span class="text-first" v-if="parseInt(l.price) > 0">
                                <span v-if="!l.discount_bool">
                                  {{ l.price | price }}
                                </span>
                                <span v-if="l.discount_bool">
                                  {{ l.price_discount | price }}
                                </span>
                              </span>

                              <span class="green--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </div> 
                          <!-- <div class="text-first body-1 font-weight-bold py-1" v-if="l.is_diskon">
                            <div class="text-decoration-line-through text-second caption mr-2">
                              Rp. 150.000
                            </div>
                            <div>
                              Rp 75.000
                            </div>
                          </div>  -->
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="transition-fast-in-fast-out v-card--reveal white--text align-start"
                              style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                              <v-img :src="l.img_url" style="height:130px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                              <div class="px-3">
                                <div class="white--text body-1 font-weight-bold mb-2 two-line mt-3" style="height: 50px;">
                                  {{ l.name }}
                                </div> 
                                <div class="body-1 mb-2 caption white--text two-line">
                                  {{ l.description }}
                                </div>
                                <div class="white--text text--darken-3 fs-14 font-weight-bold py-2">
                                  <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                                    {{ l.price | price }}
                                  </span>
                                  <span class="font-weight-bold">
                                    <span class="white--text" v-if="parseInt(l.price) > 0">
                                      <span v-if="!l.discount_bool">
                                        {{ l.price | price }}
                                      </span>
                                      <span v-if="l.discount_bool">
                                        {{ l.price_discount | price }}
                                      </span>
                                    </span>

                                    <span class="white--text" v-else>
                                      Gratis
                                    </span>
                                  </span>
                                </div> 
                                <!-- <div class="white--text text--darken-3 body-1 font-weight-bold py-1" v-if="l.is_diskon">
                                  <div class="text-decoration-line-through white--text caption mr-2">
                                    Rp. 150.000
                                  </div>
                                  <div>
                                    Rp 75.000
                                  </div>
                                </div>  -->
                                <div class="d-flex align-center mt-2">
                                  <div>
                                    <v-btn
                                      color="white"
                                      width="90"
                                      elevation="0"
                                      small
                                      :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                                      class="text-capitalize">
                                      Lihat Kelas
                                    </v-btn>
                                  </div>
                                  <div class="ml-auto">
                                    <v-icon color="white" size="18" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" class="ml-3" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                                    <span v-if="token">
                                      <v-icon size="18" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                                      <v-icon size="18" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                                    </span>
                                    <span v-if="!token">
                                      <v-icon size="18" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                                    </span>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-expand-transition>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </div>
                <div class="text-right mt-16" v-if="!process.kelas && list.length < 1">
                  <Empty v-model="contentEmpty"/>
                </div>
                <!-- <Pagination 
                  @changePage="fetchClass($event)" 
                  :model="pagination"
                  class="pt-6"
                  v-show="pagination.total_page > 1"/> -->
              </v-col>
            </v-row>
          </div>
        </section>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        menu: [],
        selected: {
          menu: 0
        },
        snackbar_fiture: false,
        allCourse: 0,
        detail: {},
        page: 1,
        model: 0,
        contentEmpty: "",
        pagination: {},
        list: [],
        process: {
          run: true,
          kelas: false
        },
        // SEO
        content: {
          url: '/publisher',
          title: 'Publisher',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return th/course/detailis.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      DialogAuth: () => import('@/components/dialog/auth'),
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.process.run = true
        this.process.kelas = true
        let params = {
          id: this.$route.params.slug
        }
        this.$axios.$get(`api/publishers/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.detail = response.results.data
            this.fetchCategory()
          }else{
            this.process.run = false
          }
        })
      },
      fetchCategory () {
        let params = {
          user_id: this.$route.params.slug
        }
        this.$axios.$get(`api/publishers/course_category`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.menu = [{id : "", name: "All Courses"}, ...response.results.data]
            this.fetchClass()
          }else{
            this.process.run = false
          }
        })
      },
      fetchClass (p) {
        this.process.kelas = true
        let params = {
          limit: 6,
          page: p === undefined ? 1 : p,
          publisher_user_id: this.$route.params.slug
        }
        this.$axios.$get(`api/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.contentEmpty = "Data belum tersedia"
            this.list = response.results.data
            this.pagination = response.results.pagination
            if (this.selected.menu === 0) {
              this.allCourse = this.pagination.total_data
            }
            this.process.run = false
            this.process.kelas = false
          }else{
            this.process.run = false
          }
        })
      },
      getKarekter(item) {
        if (item !== undefined) {
          let name = item
          let splitName = name.split(" ")
          let initial = name.charAt(0);
          // let second = "";
          if(splitName[1] !== undefined) {
            initial += splitName[1].charAt(0)
          }
  
          // let result = first+second
          // this.nama_image = initial.toUpperCase()
          // console.log(initial.toUpperCase())
          return initial.toUpperCase()
        }
      },
    },
  };
</script>