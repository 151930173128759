<template>
  <div>
    <div>
      <FormMateri :dialogopen="dialog.materi" :detailCOntenDialog="detailCOntenDialog" :formInput="input" @addData="addMateri($event)" @close="dialog.materi = false"/>
      <FormSubMateri :detailMateri="materiDetail" :detailCOntenDialog="detailCOntenDialog" :dialogopen="dialog.sub_materi" :formInput="input" @addData="addSubMateri($event)" @close="dialog.sub_materi = false"/>
      <section>
        <div>
          <div class="text-first font-weight-bold fs-14">
            Bab ({{ form.length }})
          </div>
        </div>
        <v-card-text class="pa-0" :style="form.length > 0 ? 'margin-top: 24px;' : ''">
          <div v-for="(materi, index) in form" :key="index">
            <div class="d-flex">
              <div class="d-flex">
                <div style="width: 25px; height: 25px; background: #3498DB; color: #FFF; border-radius: 7px;" class="text-center d-flex align-center justify-center">
                  {{ index + 1}}
                </div>
              </div>
              <div class="ml_15" style="width: 100%;">
                <div class="d-flex">
                  <div class="fs-18">
                    {{ materi.title }}
                  </div>
                  <div class="ml-auto text-right" style="width: 125px;">
                    <v-btn
                      text
                      small
                      @click="detailCOntenDialog = {title: 'Ubah Bab', type: 'edit'}; type = 'edit'; openDialogMateri(materi)"
                      color="#3498db"
                      class="text-capitalize pr-0">
                      Ubah
                    </v-btn>
                    <v-btn
                      text
                      v-if="index !== 0"
                      small
                      @click="deleteMateri(index)"
                      class="text-capitalize pr-0"
                      color="red">
                      Hapus
                    </v-btn>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex align-center mt-4" :class="materi.sub.length > 0 ? 'mb_15' : ''">
                  <div class="text-first font-weight-bold fs-14">
                    Materi ({{ materi.sub.length }})
                  </div>
                </div>
                <v-alert
                  text
                  v-if="materi.sub.length < 1"
                  class="fs-14"
                  style="margin-top: 17px;"
                  color="warning">
                  Tidak ada sub materi pada kelas ini, tambahkan jika ada dengan menekan tambah sub materi.
                </v-alert>
                <div v-for="(sub_materi, i) in materi.sub" :key="i" :class="(i+1) !== materi.sub.length > 0 ? 'mb_15' : ''">
                  <div class="d-flex align-center" style="width: 100%; padding: 17px 15px; border: 1px solid #F2F2F2; box-sizing: border-box; border-radius: 8px;">
                    <div>
                      <v-icon v-if="sub_materi.type === 'video'">mdi-television-play</v-icon>
                      <v-icon v-if="sub_materi.type === 'docs'">mdi-text</v-icon>
                    </div>
                    <div class="ml_17">
                      <v-img
                        width="67"
                        height="67"
                        class="d-flex align-center justify-center"
                        v-if="sub_materi.img_url !== ''"
                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                        :src="sub_materi.img_url"
                        style="border-radius: 5px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <div v-if="sub_materi.img_url === ''" style="background-color: #ededed; color: #fff; width: 67px; height: 67px;" class="rounded-lg">
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-icon large>mdi-palette-swatch</v-icon>
                        </v-row>
                      </div>
                    </div>
                    <div class="ml_18">
                      <div class="fs-16 text-first font-weight-bold">
                        {{ sub_materi.title }}
                      </div>
                      <div class="text-first fs-14 two-line">
                        {{ sub_materi.description }}
                      </div>
                    </div>
                    <div class="ml-auto" style="min-width: 112px;">
                      <v-btn
                        text
                        small
                        @click="detailCOntenDialog = {title: 'Ubah Materi', type: 'edit'}; type = 'edit'; id_selected = index; id_sub_selected = i; openDialogSubMateri(sub_materi, materi);"
                        color="#3498db"
                        class="text-capitalize">
                        Ubah
                      </v-btn>
                      <v-btn
                        text
                        small
                        @click="deleteSubMateri(index, i)"
                        class="text-capitalize px-0"
                        color="red">
                        Hapus
                      </v-btn>
                    </div>
                  </div>
                </div>

                <v-btn
                  elevation="0"
                  color="#3498DB"
                  height="30"
                  width="120"
                  outlined
                  :disabled="process.form"
                  :loading="process.form"
                  @click="detailCOntenDialog = {title: 'Tambah Materi', type: 'add'}; type = 'add'; id_selected = index; openDialogSubMateri(undefined, materi);"
                  class="text-capitalize fs-14">
                  <div class="d-flex align-center justify-center" style="width: 100%;">
                    <v-icon size="12" style="margin-top: 1px;">mdi-plus</v-icon>
                    <div class="fs-14 ml-1">
                      Materi
                    </div>
                  </div>
                </v-btn>
              </div>
            </div>
            <v-divider style="border-width: 4px; border-color: #D8D8D8;" :class="(index+1) !== form.length ? 'mt_25 mb_25' : 'mt_25'"></v-divider>
          </div>
          <div>
            <v-btn
              elevation="0"
              color="#3498DB"
              height="30"
              width="120"
              :disabled="process.form"
              :loading="process.form"
              @click="detailCOntenDialog = {title: 'Tambah Bab', type: 'add'}; type = 'add'; openDialogMateri()"
              class="text-capitalize white--text mt_15">
              <div class="d-flex align-center justify-center" style="width: 100%;">
                <v-icon size="12" style="margin-top: 1px;">mdi-plus</v-icon>
                <div class="ml-1 fs-14">
                  Bab
                </div>
              </div>
            </v-btn>
          </div>
        </v-card-text>
      </section>

    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          materi: false,
          sub_materi: false
        },
        detailCOntenDialog: {
          title: "",
          type: ""
        },
        type: "add",
        materiDetail: {},
        input: {},
        id_selected: 0,
        id_sub_selected: 0,
        form:[],
        process: {
          run: false,
          form: false
        }
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      FormMateri: () => import('@/components/elearning/materi/form/materi'),
      FormSubMateri: () => import('@/components/elearning/materi/form/submateri')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      detailClass: {
        type: Array,
        default: () => []
      },
    },
    watch: {
      'detailClass': function(val) {
        this.form = val
      }
    },
    mounted () {
      this.form = this.$props.detailClass
    },
    methods: {
      openDialogMateri(item) {
        if (item === undefined) {
          this.input = {
            "title":"",
            "img_url":"",
            "file_url":"",
            "type":"",
            "id": "",
            "description":"",
            "active_bool":true,
            "process_img": false,
            "process_file": false,
            "preview": false,
            "sub":[]
          }
        } else (
          this.input = {
            "title":item.title,
            "img_url":item.img_url,
            "file_url":item.file_url,
            "type":item.type,
            "id": item.id,
            "description":item.description,
            "active_bool":true,
            "process_img": false,
            "process_file": false,
            "preview": item.preview_bool,
            "sub": item.sub
          }
        )

        this.dialog.materi = true
      },

      openDialogSubMateri(item, detail) {
        this.materiDetail = detail
        if (item === undefined) {
          this.input = {
            "title":"",
            "img_url":"",
            "file_url":"",
            "type":"",
            "id": "",
            "description":"",
            "active_bool":true,
            "process_img": false,
            "process_file": false,
            "preview": false
          }
        } else (
          this.input = {
            "title":item.title,
            "img_url":item.img_url,
            "file_url":item.file_url,
            "type":item.type,
            "id": item.id,
            "description":item.description,
            "active_bool":true,
            "process_img": false,
            "process_file": false,
            "preview": item.preview_bool
          }
        )

        this.dialog.sub_materi = true
      },

      addMateri(item) {
        if (this.type === 'edit') {
          this.form[this.id_selected] = item
        } else if (this.type === 'add') {
          this.form.push(item)
        }
      },

      addSubMateri(item) {
        if (this.type === 'edit') {
          this.form[this.id_selected].sub[this.id_sub_selected] = item
        } else if (this.type === 'add') {
          this.form[this.id_selected].sub.push(item)
        }
      },

      deleteMateri(index) {
        this.form.splice(index, 1)
      },

      deleteSubMateri(parent, index) {
        this.form[parent].sub.splice(index, 1)
      },
    },
  };
</script>