<template>
  <div>
    <v-container>
      <Loading :detailMessage="loading_message" v-if="process.form"/>
      <Discussion v-if="Object.keys(detail).length > 0" :detailMateri="playlis_materi" :dialogopen="dialog.discus" :detailCollection="detail" @close="dialog.discus = false" @add="fetch()" @deletes="fetch()"/>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -20px;"
        :color="color">
        <div class="body-1 font-weight-bold">{{ color === 'warning' ? warning.message : success.message }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Tutup
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
        v-if="dialog.detail"
        v-model="dialog.detail"
        width="570">
        <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-7 pb-3 pt-5'">
            <div style="font-weight: 900; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'fs-18'">
              Diskusi
            </div>
            <div class="ml-auto">
              <v-btn
                :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
                :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="$emit('close'); dialog.detail = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pt-2 pb-5'">
            <div class="text-second" style="width: 100%">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.comment"
                v-on:keyup.enter="fetchComment()"
                @click:clear="filter.comment = ''; fetchComment();"
                :placeholder="`Cari nama diskusi…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </div>
            <!-- <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
              <v-select
                  :items="[
                    'Semua Materi'
                  ]"
                  solo
                  color="#4ab762"
                  item-color="green"
                  hide-details
                  class=""
                  placeholder="Materi"
                ></v-select>
            </div> -->
          </div>
          <v-divider></v-divider>
          <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pb-16'" style="min-height: 400px">
            <div>
              <div>
                <div>
                  <div class="d-flex">
                    <div class="pr-0">
                      <v-img
                        :src="detail.user_img_url"
                        :lazy-src="detail.user_img_url"
                        v-if="detail.user_img_url !== ''"
                        width="40"
                        height="40"
                        style="border-radius: 6px;"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <div v-if="detail.user_img_url === undefined || detail.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                        <v-row
                          class="fill-height ma-0 fs-28"
                          align="center"
                          justify="center"
                        >
                          {{ getKarekter(detail.user_name) }}
                        </v-row>

                      </div>
                    </div>
                    <div style="width: 100%; margin-left: 15px;">
                      <div class="d-flex">
                        <div class="text-second text--darken-4" style="width: 100%;">
                          <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;">
                            {{ detail.user_name }}
                          </div>
                        </div>
                      </div>
                      <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #3498DB;">
                        <span v-if="detail.course_content_title_parent !== ''">
                          {{ detail.course_content_title_parent }} - {{ detail.course_content_title }}
                        </span>
                        <span v-if="detail.course_content_title_parent === ''">
                          Semua Bab
                        </span>
                      </div>
                      <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.5;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                        Posted· {{ detail.last_update_datetime | datetimemonth }}
                      </div>
                      <div class="fs-12mt-0 mb-3" style="text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                        {{ detail.text }}
                      </div>

                      <div>
                        <div class="d-flex">
                          <div>
                            <v-img
                              :src="user.publisher_img_url"
                              :lazy-src="user.publisher_img_url"
                              width="38"
                              height="38"
                              style="border-radius: 6px;"
                              class="grey lighten-2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>
                          <div style="width: 100%; margin-left: 15px;">
                            <ValidationObserver ref="observer">
                              <div>
                                <ValidationProvider :name="`tanggapan`" rules="max:1000" v-slot="{ errors }">
                                  <v-text-field
                                    color="#4ab762"
                                    rows="2"
                                    class="mt-0 pt-0"
                                    :counter="1000"
                                    :autofocus="false"
                                    v-on:keyup.enter="save()"
                                    :messages="'Optional'"
                                    v-model="form.comment"
                                    placeholder="Tuliskan tanggapan anda disini"
                                    :error-messages="errors">
                                    <template v-slot:message="{ message }">
                                      <span :class="error.message !== '' ? 'red--text' : message !== 'Optional' ? 'red--text' : 'color-first'" v-html="error.message !== '' ? error.message : message"></span>
                                    </template>
                                    <template v-slot:counter="{ props }">
                                      <span style="font-size: 9px;" class="text-second">
                                        {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                                      </span>
                                    </template>
                                  </v-text-field>
                                </ValidationProvider>
                              </div>
                            </ValidationObserver>
                          </div>
                        </div>
                      </div>
                      <div v-if="!detail.child_open_bool && !process.detail && comment.length > 0" class="mt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                        <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline; color: #505050;" @click="error.message = ''; detail.child_open_bool = true">
                          Tampilkan komentar ({{ detail.child_count }})
                        </span>
                      </div>
                      <div v-if="process.detail && detail.id === detail.id" class="my-10 text-center">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </div>
                      <div v-if="detail.child_open_bool && !process.detail" class="mt-2">
                        <div v-for="(l, i) in comment" :key="i" class="d-flex mb-3">
                          <div>
                            <v-img
                              :src="l.user_img_url"
                              :lazy-src="l.user_img_url"
                              v-if="l.user_img_url !== ''"
                              width="40"
                              height="40"
                              style="border-radius: 6px;"
                              class="grey lighten-2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <div v-if="l.user_img_url === undefined || l.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                              <v-row
                                class="fill-height ma-0 fs-20"
                                align="center"
                                justify="center"
                              >
                                {{ getKarekter(l.user_name) }}
                              </v-row>

                            </div>
                          </div>
                          <div style="width: 100%; margin-left: 15px;">
                            <div class="d-flex align-center">
                              <div class="text-second text--darken-4" :style="l.is_user ? 'width: 55%;' : 'width: 100%;'">
                                <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                                  {{ l.user_name }} <span style="font-weight: normal; font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;">- {{ l.last_update_datetime | datetimemonth }}</span>
                                </div>
                              </div>
                              <div class="ml-auto text-right" v-if="l.user_id === user.id" style="width: 45%;">
                                <v-btn
                                  @click="reset(l)"
                                  text
                                  x-small
                                  :disabled="process.delete"
                                  class="text-capitalize fs-12 primary--text pl-0 pr-1">
                                  Ubah
                                </v-btn>
                                <v-btn
                                  class="text-capitalize fs-12 red--text pr-0 pl-1"
                                  text
                                  x-small
                                  :disabled="process.delete"
                                  :loading="process.delete && index_child === i"
                                  @click="index_child = i; form.id = l.id; deleteComment(i);">
                                  Hapus
                                </v-btn>
                              </div>
                            </div>
                            <div class="fs-13 mt-0 mb-0" style="font-weight: 900; text-align: justify; color: #4ab762; mix-blend-mode: normal; opacity: 0.7;">
                              {{ l.title }}
                            </div>
                            <div class="mt-1" style="font-size: 12px; line-height: 18px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                              {{ l.text }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="!process.detail && detail.child_open_bool && !process.detail && comment.length > 0" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                        <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline; color: #505050;" @click="error.message = ''; detail.child_open_bool = false">
                          Tutup komentar
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mt-16" v-if="!process.run && list.length < 1">
              <Empty v-model="contentEmpty"/>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-2">
          <v-col :cols="9" class="pt-0">
            <div class="d-flex align-center" style="height: 100%;">
              <div>
                <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
                  <span>Diskusi Produk</span>
                </div>
                <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
                  <span>Lihat diskusi produk / kelas elaearning yang Anda jual.</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="43" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
      </section>

      <section>
        <v-card-text class="pa-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'mb_20 mt_25'">
          <v-row>
            <v-col cols="12" md="9" class="pt-0">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.search"
                v-on:keyup.enter="fetch()"
                @click:clear="filter.search = ''; fetch();"
                :placeholder="`Cari nama kelas…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <v-select
                :items="[
                  {
                    name:'Hari Ini',
                    id:'today'
                  },
                  {
                    name:'Kemarin',
                    id:'yesterday'
                  },
                  {
                    name:'Minggu Ini',
                    id:'last_week'
                  },
                  {
                    name:'Bulan Ini',
                    id:'last_month'
                  },
                  {
                    name:'Pilih Tanggal',
                    id:'date'
                  }
                ]"
                solo
                v-on:change="fetch()"
                color="#4ab762"
                placeholder="Pilih kategori disini"
                item-text="name"
                item-value="id"
                item-color="green"
                return-object
                v-model="filter.periode">
                <template v-slot:selection="data">
                  <div v-if="filter.periode.id !== 'date'">
                    {{ data.item.name }}
                  </div>
                  <div v-if="filter.periode.id === 'date'">
                    Tanggal
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div v-if="item.id === 'date'" style="display: contents;">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="filter.date"
                      persistent
                      width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs"
                          v-on="on" class="py-0" style="width: 100%;">
                          <div class="d-flex align-center" style="width: 100%;" v-if="filter.periode.id !== 'date'">
                            Pilih Tanggal
                          </div>
                          <div class="d-flex align-center" style="width: 100%;" v-if="filter.periode.id === 'date'">
                            Ubah Tanggal
                          </div>
                        </v-list-item-content>
                      </template>
                      <v-date-picker
                        v-model="filter.date"
                        range
                        color="#4ab762"
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="#4ab762"
                          @click="modal = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="#4ab762"
                          @click="$refs.dialog.save(filter.date); fetch(); filter.periode = {
                            name:'Pilih Tanggal',
                            id:'date'
                          }">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <div v-if="item.id !== 'date'" @click="filter.date = [$callbackdate(today)]">
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- <div class="text-second mt_20" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : ''" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data kelas
          </div> -->
          <div style="font-weight: 900; font-size: 16px; line-height: 19px; color: #505050; mix-blend-mode: normal; opacity: 0.8;" class="mb-2">
            Diskusi
          </div>
          <div class="d-flex align-center mb-5">
            <div class="">
              <!-- <div style="font-size: 14px; line-height: 19px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
                {{ pagination.total_data }} Ulasan
              </div> -->
              <div style="font-size: 14px; line-height: 19px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
                <span v-if="filter.periode.id === 'today'">
                  Hari Ini ({{ new Date() | dateTanggalMonth }})
                </span>
                <span v-if="filter.periode.id === 'yesterday'">
                  <!-- 7 Hari Terakhir (Periode 3 Agu 2021 - Hari ini) -->
                  Kemarin ({{ yesterday | dateTanggalMonth }})
                </span>
                <span v-if="filter.periode.id === 'last_week'">
                  Minggu ini ({{ week_start | dateTanggalMonth }} - {{ week_last | dateTanggalMonth }})
                </span>
                <span v-if="filter.periode.id === 'last_month'">
                  <!-- 7 Hari Terakhir (Periode 3 Agu 2021 - Hari ini) -->
                  Bulan ini ({{ mounth_start | dateTanggalMonth }} - {{ mounth_last | dateTanggalMonth }})
                </span>
                <span v-if="filter.periode.id === 'date'">
                  <span v-if="filter.date[1] !== undefined">
                    {{ filter.date[0] < filter.date[1] ? filter.date[0] : filter.date[1] | dateTanggalMonth }} - {{ filter.date[0] > filter.date[1] ? filter.date[0] : filter.date[1] | dateTanggalMonth }}
                  </span>
                  <span v-if="filter.date[1] === undefined">
                    {{ filter.date[0] | dateTanggalMonth }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-card-text>

        <div v-if="!loader_tab" class="mb_20">
          <v-card-text class="py-3 px-0">

            <v-tabs
              background-color="transparent"
              color="grey darken-3"
              v-model="filter.menu"
              dense
              v-on:change="fetch()">
              <v-tabs-slider color="#4ab762"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in menu"
                :key="index"
                class="px-0 mr-5">
                <div>
                  <div class="body-1 text-capitalize" :class="filter.menu === index ? 'font-weight-bold' : 'text-third'">
                    {{ item === 'Semua' ? `Semua (${(all_data)})` :  item === 'Perlu Ditanggapi' ? `Perlu Ditanggapi (${(data_review.not_replied_count === '' ? 0 : data_review.not_replied_count)})` :  item === 'Sudah Ditanggapi' ? `Sudah Ditanggapi (${(data_review.replied_count === '' ? 0 : data_review.replied_count)})` : item }}
                  </div>
                </div>
              </v-tab>
            </v-tabs>

          </v-card-text>
        </div>

        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 5" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0">
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      class="mx-auto"
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.detail"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <div class="text-right ml-auto mt-5">
                        <v-skeleton-loader
                          :loading="process.run"
                          type="paragraph">
                        </v-skeleton-loader>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="chip">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="chip">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="chip">
                </v-skeleton-loader>
              </div>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  style="width: 100px; height: 40px;"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
                <v-skeleton-loader
                  style="width: 100px; height: 40px;"
                  class="ml-5"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-card-text class="py-3 px-0">

            <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : 'mb-7 px-3'">
              <v-row  class="border_radius_7 hover_select" style="padding: 17px 8px 23px 8px;" :style="'background: #f9f9f9; border: 1px solid #ebebeb;'">
                <v-col cols="12" class="pt-0 pb-2">
                  <div class="text-right fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                    {{ l.discussion_last_update_datetime | datetimemonth }}
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-list three-line style="background: transparent;" class="py-0">
                    <v-list-item class="py-0 px-0">
                      <v-list-item-avatar
                        size="80"
                        class="my-0"
                        tile>
                        <v-img 
                          :src="l.img_url" style="border-radius: 15px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="pb-0 pt-0">
                        <div class="text-first text--darken-2 font-weight-bold " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'caption'">
                          {{ l.name }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4" class="py-0" style="border-left: 1px solid #ebebeb;">
                  <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-bottom: 7px;">
                    {{ l.discussion_user_name }}
                  </div>
                  <div>
                    <div>
                      <span v-if="l.discussion_text !== ''" style="color: #505050;" class="two-line">
                        {{ l.discussion_text }}
                      </span>
                    </div>
                    <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-top: 5px;">
                      {{ l.last_update_datetime_readable }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-right py-0" style="border-left: 1px solid #ebebeb;">
                  <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-bottom: 7px;">
                    Tanggapan Anda
                  </div>
                  <div>
                    <div class="fs-14">
                      <span v-if="l.discussion_publisher_replied_text !== ''" style="color: #505050;" class="two-line">
                        {{ l.discussion_publisher_replied_text }}
                      </span>
                      <span v-if="l.discussion_publisher_replied_text === ''" style="text-align: right; color: #F39C12;">
                        Anda belum menggapi diskusi ini
                      </span>
                    </div>
                    <div class="fs-12" v-if="l.discussion_publisher_replied_text !== ''" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-top: 5px;">
                      {{ l.publisher_replied_datetime | datetimemonth }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="pb-0 text-right">
                  <v-btn
                    outlined
                    elevation="0"
                    height="40"
                    v-if="parseInt(l.discussion_count) > 1"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    style="background: #fff;"
                    @click="toDetail(l)"
                    color="#9e9e9e"
                    class="text-capitalize ml-3">
                    Diskusi ({{ l.discussion_count }})
                  </v-btn>
                  <!-- <v-btn
                    outlined
                    elevation="0"
                    height="40"
                    v-if="parseInt(l.discussion_count) > 1"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    style="background: #fff;"
                    @click="detail = l; dialog.detail = true; fetchComment(); detail.child_open_bool = true; reset();"
                    color="#9e9e9e"
                    class="text-capitalize ml-3">
                    Diskusi ({{ l.discussion_count }})
                  </v-btn> -->
                  <v-btn
                    outlined
                    elevation="0"
                    height="40"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    style="background: #fff;"
                    v-if="l.discussion_publisher_replied_text !== ''"
                    :to="`/account/discussion/${l.id}`"
                    color="#3498DB"
                    class="text-capitalize ml-3">
                    Lihat Detail
                  </v-btn>
                  <v-btn
                    outlined
                    elevation="0"
                    height="40"
                    v-if="l.discussion_publisher_replied_text === ''"
                    @click="toDetail(l)"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    style="background: #fff;"
                    color="#3498DB"
                    class="text-capitalize ml-3">
                    Balas
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>


          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        page: 1,
        dialog: {
          detail: false,
          replay: false,
          discus: false
        },
        loading_message: "Loading",
        menu: ['Semua', 'Perlu Ditanggapi', 'Sudah Ditanggapi'],
        panel_class: 0,
        panel_balas: 0,
        panel_ulasan: 0,
        filter: {
          menu: 0,
          date: "",
          comment: "",
          search: "",
          periode: {
            name:'Bulan Ini',
            id:'last_month'
          },
          date:[]
        },
        form: {
          comment: ""
        },
        all_data: 0,
        index_child: "",
        color: "",
        comment: [],
        loader: false,
        loader_tab: true,
        data_review:{},
        snackbar: false,
        timeout: 5000,
        contentEmpty: "",
        detail: {},
        list: [],
        playlis_materi:[],
        pagination: {},
        process: {
          form: false,
          run: true,
          detail: false
        },
        success: {
          message: ""
        },
        warning: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        // SEO
        content: {
          url: '/account/discussion',
          title: 'Diskusi',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      today () {
        var now = new Date()
        this.filter.date = [this.$callbackdate(now)]
        return now
      },
      yesterday () {
        var now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate()-1)
        return next.toISOString().substr(0, 10)
      },
      week_start () {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        return new Date(curr.setDate(first));
      },
      week_last () {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        return new Date(curr.setDate(last));
      },
      mounth_start () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
      },
      mounth_last () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      Loading: () => import('@/components/Loading'),
      Discussion: () => import('@/components/publisher/discussion'),
    },
    mounted () {
      var now = new Date()
      this.filter.date = [this.$callbackdate(now)]
      this.fetch()
    },
    methods: {
      async fetch (p) {
        let params = {}
        this.process.run = this.loader ? false : true
        if (this.filter.menu === 0) {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            sort: "-id",
            periode: this.filter.periode.id !== 'date' ? this.filter.periode.id : '',
            'created_datetime[gte]': this.filter.date[1] !== undefined ? this.filter.date[0] < this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'created_datetime[lte]': this.filter.date[1] !== undefined ? this.filter.date[0] > this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'discussion_count[neq]': "0"
          }
        } else if (this.filter.menu === 1) {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            discussion_publisher_replied_bool: false,
            sort: "-id",
            periode: this.filter.periode.id !== 'date' ? this.filter.periode.id : '',
            'created_datetime[gte]': this.filter.date[1] !== undefined ? this.filter.date[0] < this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'created_datetime[lte]': this.filter.date[1] !== undefined ? this.filter.date[0] > this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'discussion_count[neq]': "0"
          }
        } else if (this.filter.menu === 2) {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            discussion_publisher_replied_bool: true,
            sort: "-id",
            periode: this.filter.periode.id !== 'date' ? this.filter.periode.id : '',
            'created_datetime[gte]': this.filter.date[1] !== undefined ? this.filter.date[0] < this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'created_datetime[lte]': this.filter.date[1] !== undefined ? this.filter.date[0] > this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'discussion_count[neq]': "0"
          }
        }
        this.$axios.$get(`api/publisher/course_discussion/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            if (this.filter.search !== '' || this.filter.search !== null || this.filter.category !== '' || this.filter.etalase !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            if (this.filter.menu === 0) {
              this.all_data = response.results.pagination.total_data
              this.data_review = response.results
            }
            this.loader = false
            this.loader_tab = false
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      async fetchComment (p) {
        this.process.detail = this.loader ? false : true
        let params = {
          limit: 10000,
          page: p === undefined ? 1 : p,
          parent_id: this.detail.id,
          // rate: this.filter.rating,
          search: this.filter.comment
        }
        this.$axios.$get(`api/publisher/course_discussion_comment`, { params })
        .then((response)=>{
          if(response.status === 200) {
            // this.$refs.observerComment[0].reset()
            if (this.loader) {
              this.fetch()
              this.detail.child_open_bool = true
            }
            if (response.results.data.length < 1) {
              this.detail.child_open_bool = false
            }
            this.detail.child_count = response.results.pagination.total_data
            this.comment = response.results.data
            // this.pagination = response.results.pagination
            // this.contentEmpty = "Data belum tersedia"
            this.process.detail = false
          }else{
            this.process.detail = false
          }
        })
      },
      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
      reset(item) {
        this.error.message = ''
        this.warning.message = ""
        this.success.message = ""
        this.form={
          id: item !== undefined ? item.id : "",
          comment: item !== undefined ? item.text : ""
        }
      },
      toDetail (detail) {
        this.detail = detail
        let array = []
        for (let i in detail.content) {
          array.push (
            {
              content_id: detail.content[i].content_id,
              description: detail.content[i].description,
              file_url: detail.content[i].file_url,
              id: detail.content[i].id,
              img_url: detail.content[i].img_url,
              last_watch_time: detail.content[i].last_watch_time,
              parent_id: detail.content[i].parent_id,
              read_bool: detail.content[i].read_bool,
              sub: detail.content[i].sub,
              sub_count: detail.content[i].sub_count,
              sub_proggress: detail.content[i].sub_proggress,
              sub_proggress_count: detail.content[i].sub_proggress_count,
              title: detail.content[i].title,
              total_time: detail.content[i].total_time,
              type: detail.content[i].type,
              read_more: false
            }
          )
          if (detail.content[i].sub.length > 0) {
            array[i].sub = []
            for (let j in detail.content[i].sub) {
              array[i].sub.push (
                {
                  content_id: detail.content[i].sub[j].content_id,
                  description: detail.content[i].sub[j].description,
                  file_url: detail.content[i].sub[j].file_url,
                  id: detail.content[i].sub[j].id,
                  img_url: detail.content[i].sub[j].img_url,
                  last_watch_time: detail.content[i].sub[j].last_watch_time,
                  parent_id: detail.content[i].sub[j].parent_id,
                  read_bool: detail.content[i].sub[j].read_bool,
                  sub_count: detail.content[i].sub[j].sub_count,
                  title: detail.content[i].sub[j].title,
                  total_time: detail.content[i].sub[j].total_time,
                  type: detail.content[i].sub[j].type,
                  read_more: false
                }
              )
            }
          }
        }
        this.detail.content = array
        this.playlis_materi = []
        for (let i in this.detail.content) {
          if (this.detail.content[i].file_url !== '') {
            this.playlis_materi.push(this.detail.content[i])
          }
          for (let j in this.detail.content[i].sub) {
            if (this.detail.content[i].sub[j].file_url !== '') {
              this.playlis_materi.push(this.detail.content[i].sub[j])
            }
          }
        }
        this.dialog.discus = true
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.loader = true
          if (this.form.id === '') {
            this.createsComment()
          } else {
            this.updatesComment()
          }
        } else {
          this.warning.message = "Harap periksa kembali inputan Anda"
          this.color="warning"
          this.snackbar = true
        }
      },
      async createsComment() {
        this.loading_message = "Membuat diskusi Anda..."
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_discussion_comment`, 
        {
          id: this.detail.id,
          text: this.form.comment
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetchComment()
            this.form.comment = ""
            this.color="#27ae60"
            this.success.message="Berhasil membuat diskusi"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updatesComment() {
        this.loading_message = "Mengubah diskusi Anda..."
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_discussion_comment/update`, 
        {
          id: this.form.id,
          text: this.form.comment
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetchComment()
            this.color="#27ae60"
            this.form.comment = ""
            this.success.message="Berhasil mengubah diskusi"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      async deleteComment(index) {
        this.loading_message = "Menghapus diskusi Anda..."
        this.error.message = ''
        this.warning.message = ""
        this.success.message = ""
        this.process.form = true
        this.loader = true
        await this.$axios.$post(`api/publisher/course_discussion_comment/delete`, 
        {
          id: this.form.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetchComment()
            this.color="#27ae60"
            this.success.message="Berhasil menghapus diskusi"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>