<template>
  <div>
    <v-dialog v-model="dialog.login"
      v-if="dialog.login"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 400 : fps === 'md' ? 900 : 990"
      persistent>
      <v-card 
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">

          <v-col cols="12" md="7" class="pa-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-3' : 'px-16 mt-3'">
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 20px; left: -20px;" v-if="fps !== 'lg' && fps !== 'md' && fps !== 'xl'">
              <v-btn
                fab
                small
                icon
                @click="dialog.login = false; $emit('close');"
                elevation="0"
                :disabled="process.form || process.register || process.login_sso">
                <v-icon size="30" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-card-text> -->
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3' : ''">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  text
                  @click="dialog.login = false; $emit('close');"
                  elevation="0"
                  class="color-green-second"
                  :disabled="process.form || process.register || process.login_sso">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-2 mt-9' : 'px-10'" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'padding-top: 80px !important; padding-bottom: 90px !important;'">
              <div class="text-center text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1 font-weight-bold text-first' : 'title text-first'">
                Login ke Akun Anda
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-2' : 'mt-5'"></v-divider>
              <div class="text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'my-5' : 'my-6'">
                <!-- <v-btn
                  solo
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  fab
                  color="grey darken-2"
                  elevation="0"
                  class="px-3 mx-2">
                  <v-icon color="grey darken-4" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 29 : 37">mdi-facebook</v-icon>
                </v-btn> -->
                <v-btn
                  outlined
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  fab
                  :disabled="process.form || process.register || process.login_sso"
                  :loading="process.login_sso"
                  color="grey darken-2"
                  elevation="0"
                  @click="google('login')"
                  class="px-3 mx-2">
                  <v-icon color="grey darken-4" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 24 : ''">mdi-google</v-icon>
                </v-btn>
                <!-- <v-btn
                  solo
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  fab
                  color="grey darken-2"
                  elevation="0"
                  class="px-3 mx-2">
                  <v-icon color="grey darken-4" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 24 : ''">mdi-linkedin</v-icon>
                </v-btn> -->
              </div>
              <div class="text-second text--darken-2 mb-5 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                Atau gunakan akun email Anda
              </div>
              <v-form
                class="mb-1">
                <ValidationObserver ref="observer">
                  <v-form
                    class="mb-5">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        placeholder="Email"
                        :disabled="process.form || process.register || process.login_sso"
                        prepend-inner-icon="mdi-email-outline"
                        v-model="form.email"
                        v-on:keyup.enter="save('login')"
                        @keypress="form.username = form.email"
                        @blur="form.username = form.email"
                        @change="form.username = form.email"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Password" rules="required|min:5|max:20" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        placeholder="Password"
                        v-on:keyup.enter="save('login')"
                        :disabled="process.form || process.register || process.login_sso"
                        prepend-inner-icon="mdi-form-textbox-password"
                        v-model="form.password"
                        :type="show.password ? 'text' : 'password'"
                        :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password = !show.password"
                        :counter="20"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </v-form>
              <div class="d-flex align-center">
                <div>
                  <v-checkbox class="mt-0 pt-0" :dense="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false" hide-details color="#4ab762">
                    <template v-slot:label>
                      <div class="font-weight-bold text-first text--darken-4" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                        Ingat Saya
                      </div>
                    </template>
                  </v-checkbox>

                </div>
                <div class="font-weight-bold text-first text--darken-4 ml-auto" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                  <span class="cursor-pointer" @click="dialog.login = false; dialog.reset = true; reset(); $refs.observer.reset();">Lupa Password?</span>
                </div>
              </div>
              <div class="text-center mt-7">
                <v-alert 
                  type="error" 
                  text
                  dense
                  prominent
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
                  v-show="error.message.length > 0"
                  v-html="error.message">
                </v-alert>
                <v-btn
                  width="200"
                  color="#4ab762"
                  elevation="0"
                  :disabled="process.form || process.register || process.login_sso"
                  :loading="process.form"
                  @click="save('login')"
                  class="caption font-weight-bold white--text">
                  Login
                </v-btn>
                <div v-if="fps !== 'lg' && fps !== 'md' && fps !== 'xl'">
                  <div class="d-flex align-center mt-3 mb-4">
                    <v-divider/>
                    <span class="caption font-weight-bold mx-3">
                      Atau
                    </span>
                    <v-divider/>
                  </div>

                  <v-btn
                    width="250"
                    color="#4ab762"
                    outlined
                    elevation="0"
                    @click="google('register')"
                    :disabled="process.form || process.register || process.login_sso"
                    :loading="process.register"
                    class="caption font-weight-bold">
                    <v-avatar size="18">
                      <v-img
                        :src="require('@/assets/icon/login_google.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height mt-0"
                            align="end"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :size="20"
                              :width="2"
                              color="#4ab762">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <span class="primary--text ml-3">Buat Akun Dengan google</span>
                  </v-btn>
                </div>

              </div>
            </div>
          </v-col>

          <v-col :cols="5" v-if="fps !== 'xs' && fps !== 'sm'" class="pa-0" style="background: #4ab762; border-radius: 0px 12px 12px 0px !important;">
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 20px; left: -20px;">
              <v-btn
                fab
                small
                icon
                @click="dialog.login = false; $emit('close');"
                elevation="0"
                :disabled="process.form || process.register || process.login_sso">
                <v-icon size="30" class="cursop-pointer" color="white">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-card-text>
            <div class="px-7 d-flex align-center justify-center" style="height: 100%;">
              <div>
                <div class="text-center title white--text">
                  Hallo, kawan
                </div>
                <v-divider style="border: 2px solid #FFF; width: 67px; border-radius: 6px;" class="mx-auto my-5"></v-divider>
                <div class="white--text body-1 text-center mb-5">
                  Mau belajar tapi belum punya Akun atau bingung mau daftar, yuk daftar sekarang disini
                </div>
                <div class="text-center">
                  <v-btn
                    width="250"
                    color="#FFF"
                    elevation="0"
                    @click="google('register')"
                    :disabled="process.form || process.register || process.login_sso"
                    :loading="process.register"
                    class="caption font-weight-bold">
                    <v-avatar size="20">
                      <v-img
                        :src="require('@/assets/icon/login_google.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height mt-0"
                            align="end"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :size="20"
                              :width="2"
                              color="#4ab762">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <span class="primary--text ml-3">Buat Akun Dengan google</span>
                  </v-btn>
                  <!-- <v-btn
                    width="250"
                    color="#4ab762"
                    elevation="0"
                    @click="google()"
                    class="caption font-weight-bold white--text">
                    <v-icon color="white" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 20 : '22'" class="mr-2">mdi-google</v-icon>
                    Buat Akun Dengan google
                  </v-btn> -->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.register"
      :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 400 : 990"
      v-if="dialog.register"
      persistent>
      <v-card 
        :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 540 : 660"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0 align-center" justify="center" style="height: 100%;">
          <v-col cols="12" md="7" class="pa-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-3' : 'px-13 mt-5'">
            <v-img
              class="cursor-pointer"
              :max-width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-2 mt-9' : 'px-10'">
              <div class="text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1 font-weight-bold text-first' : 'title text-first'">
                Buat Akun
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-2' : 'my-5'"></v-divider>
              <div class="text-second text-center font-weight-bold mb-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                Registrasi dengan akun email Anda
              </div>
              <div class="text-second body-1 text-center mb-5">
                <v-alert 
                  type="error" 
                  text
                  dense
                  prominent
                  v-show="error.message.length > 0"
                  v-html="error.message">
                </v-alert>
                <v-btn
                  width="250"
                  color="#4ab762"
                  elevation="0"
                  @click="google()"
                  class="caption font-weight-bold white--text">
                  <v-icon color="white" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 20 : '22'" class="mr-2">mdi-google</v-icon>
                  Buat Akun Dengan google
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="7" class="pa-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-3' : 'px-16'">
            <v-img
              class="cursor-pointer"
              :max-width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps !== 'lg' && fps !== 'md' && fps !== 'xl'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.register = false;"  $emit('close');color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-2 mt-9' : 'px-10'">
              <div class="text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1 font-weight-bold text-first' : 'title text-first'">
                Buat Akun
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-2' : 'mt-5'"></v-divider>
              <div class="text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'my-5' : 'my-10'">
                <v-btn
                  solo
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  fab
                  color="grey darken-2"
                  elevation="0"
                  class="px-3 mx-2">
                  <v-icon color="grey darken-4" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 29 : 37">mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                  solo
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  fab
                  color="grey darken-2"
                  elevation="0"
                  class="px-3 mx-2">
                  <v-icon color="grey darken-4" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 24 : ''">mdi-google</v-icon>
                </v-btn>
                <v-btn
                  solo
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 40 : 55"
                  fab
                  color="grey darken-2"
                  elevation="0"
                  class="px-3 mx-2">
                  <v-icon color="grey darken-4" :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 24 : ''">mdi-linkedin</v-icon>
                </v-btn>
              </div>
              <div class="text-second text--darken-2 mb-5 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                Atau gunakan akun email Anda
              </div>
              <v-form
                class="mb-1">
                  <v-text-field
                    solo
                    dense 
                    color="#4ab762"
                    placeholder="Nama Lengkap"
                    prepend-inner-icon="mdi-account-circle"
                    v-model="form.name">
                  </v-text-field>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <v-text-field
                    solo
                    dense 
                    color="#4ab762"
                    placeholder="Email"
                    prepend-inner-icon="mdi-email-outline"
                    v-model="form.email"
                    @keypress="form.username = form.email"
                    @blur="form.username = form.email"
                    @change="form.username = form.email">
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider name="Password" rules="required|min:5|max:20" v-slot="{ errors }">
                  <v-text-field
                    solo
                    dense 
                    color="#4ab762"
                    placeholder="Password"
                    prepend-inner-icon="mdi-form-textbox-password"
                    v-model="form.password"
                    :type="show.password ? 'text' : 'password'"
                    :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show.password = !show.password"
                    :counter="20">
                  </v-text-field>
                </ValidationProvider>
              </v-form>
              <div class="text-center mt-7">
                <v-btn
                  width="200"
                  color="#4ab762"
                  elevation="0"
                  @click="dialog.register = false; dialog.success = true;"
                  class="caption font-weight-bold white--text">
                  BUAT AKUN
                </v-btn>
              </div>
            </div>
          </v-col> -->

          <v-col :cols="5" v-if="fps !== 'xs' && fps !== 'sm'" class="pa-0 d-flex align-center justify-center" style="background: #4ab762; height: 100%; border-radius: 0px 12px 12px 0px !important;">
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 20px; left: -20px;">
              <v-icon size="30" class="cursop-pointer" @click="dialog.register = false; $emit('close');" color="white">mdi-close-circle-outline</v-icon>
            </v-card-text>
            <div class="px-7">
              <div class="text-center title white--text">
                Hallo, kawan
              </div>
              <v-divider style="border: 2px solid #FFF; width: 67px; border-radius: 6px;" class="mx-auto my-5"></v-divider>
              <div class="white--text body-1 text-center mb-5">
                Yuk belajar sekarang biar semakin banyak ilmu dan pengetahuan buatmu
              </div>
              <div class="text-center">
                <v-btn
                  width="200"
                  outlined
                  color="#FFF"
                  elevation="0"
                  @click="dialog.register = false; dialog.login = true;  reset();"
                  class="caption font-weight-bold">
                  LOGIN
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialog.success"
      width="990"
      persistent>
      <v-card 
        height="660"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0 align-center" justify="center" style="height: 100%;">

          <v-col cols="7" class="pa-0 px-14">
            <v-img
              class="cursor-pointer"
              max-width="130px"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <div class="px-10">
              <div class="text-center title">
                Buat Akun
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="300"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second text-center text-h4 font-weight-bold mb-3">
                Reset Password Sukses
              </div>
              <div class="text-second body-1 text-center mb-5">
                Silahkan cek email Anda
              </div>
            </div>
          </v-col>

          <v-col :cols="5" class="pa-0 d-flex align-center justify-center" style="background: #4ab762; height: 100%; border-radius: 0px 12px 12px 0px !important;">
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 20px; left: -20px;">
              <v-icon size="30" class="cursop-pointer" @click="dialog.success = false; $emit('close');" color="white">mdi-close-circle-outline</v-icon>
            </v-card-text>
            <div class="px-7">
              <div class="text-center title white--text">
                Hallo, kawan
              </div>
              <v-divider style="border: 2px solid #FFF; width: 67px; border-radius: 6px;" class="mx-auto my-5"></v-divider>
              <div class="white--text body-1 text-center mb-5">
                Yuk belajar sekarang biar semakin banyak ilmu dan pengetahuan buatmu
              </div>
              <div class="text-center">
                <v-btn
                  width="200"
                  solo
                  color="#FFF"
                  elevation="0"
                  @click="dialog.success = false; dialog.login = true; reset();"
                  class="caption font-weight-bold">
                  LOGIN
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialog.success"
      width="500"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      persistent>
      <v-card 
        height="650"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

          <v-col cols="12" class="pa-0">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  icon
                  @click="dialog.success = false; $emit('close')"
                  elevation="0"
                  class="color-green-second">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; $emit('close')" color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                Reset Password
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="300"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second text-center \ font-weight-bold mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'title' : 'text-h4'">
                Reset Password Sukses
              </div>
              <div class="text-second body-1 text-center mb-5">
                Silahkan cek email Anda
              </div>
              <div class="text-center">
                <v-btn
                  width="250"
                  color="#4ab762"
                  elevation="0"
                  @click="$emit('close'); dialog.success = false"
                  to="/home"
                  class="caption font-weight-bold white--text">
                  Tutup
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.reset"
      v-if="dialog.reset"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 400 : 990"
      persistent>
      <v-card 
        :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 540 : 660"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">
          <v-col cols="12" md="7" class="pa-0" :class="fps === 'xs' || fps === 'sm' ? 'px-3' : 'px-16'">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3' : ''">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  text
                  @click="dialog.reset = false; $emit('close')"
                  elevation="0"
                  class="color-green-second"
                  :disabled="process.form || process.register || process.login_sso">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; $emit('close')" color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-2 mt-14' : 'px-10'" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'padding-top: 80px !important; padding-bottom: 90px !important;'">
              <div>
                <div class="text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1 font-weight-bold text-first' : 'title text-first'">
                  RESET PASSWORD
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mb-5 mt-3' : 'my-5'"></v-divider>
                <div class="text-second text--darken-2 mb-5 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                  Silahkan masukkan email anda, kami akan mengirim link reset password ke email anda.
                </div>
                <div
                  class="mb-1">
                  <ValidationObserver ref="observer">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        v-on:keyup.enter="save('reset')"
                        placeholder="Email"
                        prepend-inner-icon="mdi-email-outline"
                        v-model="form.email"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </ValidationObserver>
                </div>
                <div class="text-center mt-5">
                  <v-alert 
                    type="error" 
                    text
                    dense
                    prominent
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>
                  <v-btn
                    width="200"
                    color="#4ab762"
                    elevation="0"
                    :disabled="process.form"
                    :loading="process.form"
                    @click="save('reset')"
                    class="caption font-weight-bold white--text">
                    Reset Password
                  </v-btn>
                  <div v-if="fps !== 'lg' && fps !== 'md' && fps !== 'xl'">
                    <div class="d-flex align-center mt-3 mb-4">
                      <v-divider/>
                      <span class="caption font-weight-bold mx-3">
                        Atau
                      </span>
                      <v-divider/>
                    </div>

                    <v-btn
                      width="200"
                      outlined
                      color="#4ab762"
                      elevation="0"
                      :disabled="process.form"
                      @click="dialog.reset = false; dialog.register = false; dialog.login = true; reset(); $refs.observer.reset();"
                      class="caption font-weight-bold">
                      LOGIN
                    </v-btn>

                    <!-- <v-btn
                      width="250"
                      color="#4ab762"
                      elevation="0"
                      @click="google('register')"
                      :disabled="process.form || process.register || process.login_sso"
                      :loading="process.register"
                      class="caption font-weight-bold white--text">
                      <v-avatar size="20">
                        <v-img
                          :src="require('@/assets/icon/login_google.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="end"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :size="20"
                                :width="2"
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      Buat Akun Dengan google
                    </v-btn> -->
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col :cols="5" v-if="fps !== 'xs' && fps !== 'sm'" class="pa-0 d-flex align-center justify-center" style="background: #4ab762; height: 100%; border-radius: 0px 12px 12px 0px !important;">
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 20px; left: -20px;">
              <v-icon size="30" class="cursop-pointer" @click="dialog.reset = false; $emit('close');" color="white">mdi-close-circle-outline</v-icon>
            </v-card-text>
            <div class="px-7">
              <div class="text-center title white--text">
                Hallo, kawan
              </div>
              <v-divider style="border: 2px solid #FFF; width: 67px; border-radius: 6px;" class="mx-auto my-5"></v-divider>
              <div class="white--text body-1 text-center mb-5">
                Yuk belajar sekarang biar semakin banyak ilmu dan pengetahuan buatmu
              </div>
              <div class="text-center" v-if="$route.path !== '/login'">
                <v-btn
                  width="200"
                  outlined
                  color="#FFF"
                  elevation="0"
                  :disabled="process.form"
                  @click="dialog.reset = false; dialog.register = false; dialog.login = true; reset(); $refs.observer.reset();"
                  class="caption font-weight-bold">
                  LOGIN
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.logout" max-width="350">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1 px-4">
          Sign Out
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close'); dialog.logout = false;">
            <v-icon color="#e74c3c">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center" style="height: 100px;">
          <div class="subtitle-2 fill-height d-flex justify-center align-center">Apakah Anda yakin ingin keluar dari aplikasi <br> Otodidak?</div>
        </v-card-text>
        <v-card-actions class="d-flex px-5 pb-5">
          <v-btn depressed :width="fps !== 'xs' && fps !== 'sm' ? 200 : 160" :disabled="process.form" :loading="process.form" class="text-none white--text text--lighten-3" color="#4ab762" @click="logout()">
            <v-icon left>mdi-check-circle-outline</v-icon>Ya, yakin
          </v-btn>
          <v-btn depressed width="100" class="text-none" :disabled="process.form" outlined color="#e74c3c" @click="$emit('close'); dialog.logout = false;">Tidak</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import sha256 from 'crypto-js/sha256';
  import hmacSHA256 from 'crypto-js/hmac-sha256';
  export default {
    props: {
      dialogauth: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        dialog: {
          logout: false,
          register: false,
          login: false,
          verification: false,
          reset: false,
          success: false
        },
        show: {
          password: false,
          password_confirm: false
        },
        form: {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          token_email: '',
          role: 'user',
          image: ''
        },
        form_google: {
          name: '',
          email: '',
          device: 'web',
          image: ''
        },
        process: {
          run: false,
          form: false,
          register: false,
          login_sso: false
        },
        error: {
          message: ''
        }
      }
    },
    watch: { 
      'dialogauth': function(val) {
        this.reset()
        this.process.form = false
        if (val === 'register') {
          this.form.email = this.email !== '' ? this.email : ''
          this.dialog.register = true
        } else if (val === 'login') {
          this.dialog.login = true
        } else if (val === 'verification') {
          this.dialog.verification = true
        } else if (val === 'success') {
          this.success.message = this.message !== '' ? this.message : ''
          this.dialog.success = true
        } else if (val === 'logout') {
          this.dialog.logout = true
        } else if (val === 'reset') {
          this.dialog.reset = true
        }
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
    },
    methods: {
      reset() {
        this.form = {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          token_email: '',
          role: 'user',
          image: ''
        }
        
        this.form_google = {
          name: '',
          email: '',
          device: 'web',
          image: ''
        }

        this.process.run = false

        this.process.form = false

        this.error.message = ''
      },
      async google(type) {
        // this.process.register = type === 'register' ? true : false
        // this.process.login_sso = type === 'login' ? true : false
        this.error.message = ''
        let provider = new this.$firebase.auth.GoogleAuthProvider()
        await this.$firebase.auth().signInWithPopup(provider)
        .then(response => {
          // let data = response.additionalUserInfo.profile
          // this.form_google.name = data.name
          // this.form_google.email = data.email
          // this.form_google.image = data.picture
          // this.form_google.device = 'web'

          // let datas = {
          //   email: this.form_google.email,
          //   name: this.form_google.name,
          // }

          let data = response
          this.form.name = data.additionalUserInfo.profile.name
          this.form.email = data.additionalUserInfo.profile.email
          this.form.image = data.additionalUserInfo.profile.picture
          this.form.token_email = data.credential.idToken
          this.form.device = 'web'

          let datas = {
            email: this.form.email,
            name: this.form.name,
          }


          console.log(data)

          // let hashData = sha256(JSON.stringify(datas)).toString().toLowerCase()
          // let signatureData = `L06INS0O:WEB:${hashData}:${process.env.API_SIGNATURE}`
          // this.form_google.signature = hmacSHA256(signatureData, 'K0l0niaW3bPl4tform_google').toString()
          
          this.process.register = false
          this.process.login_sso = false
          this.process.form = false
          this.loginSSO(type)
        }).catch(error => {
          this.$snotify.error(error.message)
          console.log(error)
        })
      },
      async loginSSO(type) {
        this.process.register = type === 'register' ? true : false
        this.process.login_sso = type === 'login' ? true : false
        this.process.run = true

        await this.$axios.$post(`api/login/sso`, 
        {
          email: this.form.email,
          name: this.form.name,
          img_url: this.form.image,
          token: this.form.token_email,
          device : "web"
        }).then((response) => {
          if (response.status === 200) {
            this.process.register = false
            this.process.login_sso = false
            this.process.form = false
            console.log(response.results.data)
            Cookie.set('user', response.results.data)
            Cookie.set('token', response.results.token)

            window.location = this.$route.path
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      async save(type) {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          if (type === 'register') {
            this.register()
          } else if (type === 'verification') {
            this.verification()
          } else if (type === 'login') {
            this.login()
          } else if (type === 'reset') {
            this.forgotPassword()
          }
        }
      },
      async login(){
        this.process.form = true

        // let data = {
        //   username: this.form.username,
        //   password: this.form.password
        // }
        
        // let hashData = sha256(JSON.stringify(data)).toString().toLowerCase()
        // let signatureData = `L0G1N:WEB:${hashData}:${process.env.API_SIGNATURE}`
        // this.form.signature = hmacSHA256(signatureData, 'K0l0niaW3bPl4tform').toString()

        await this.$axios.$post(`api/login`,{
          credential: this.form.username,
          password: this.form.password,
          device : "web"
        }).then((response)=>{
          console.log('ini data respon',response)
          if (response.status === 200) {
            Cookie.set('user', response.results.data)
            Cookie.set('token', response.results.token)
            window.location = this.$route.path
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      async forgotPassword(){
        this.process.form = true

        // let data = {
        //   username: this.form.username,
        //   password: this.form.password
        // }
        
        // let hashData = sha256(JSON.stringify(data)).toString().toLowerCase()
        // let signatureData = `L0G1N:WEB:${hashData}:${process.env.API_SIGNATURE}`
        // this.form.signature = hmacSHA256(signatureData, 'K0l0niaW3bPl4tform').toString()

        await this.$axios.$post(`api/forget_password`,{
          email: this.form.email
        }).then((response)=>{
          console.log('ini data respon',response)
          if (response.status === 200) {
            this.dialog.success = true
            this.dialog.reset = false
            this.process.form = false
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      logout() {
        this.process.form = true
        Cookie.remove('token')
        Cookie.remove('user')
        Cookie.remove('profile')
        Cookie.remove('fcm')
        Cookie.remove('notif')

        this.logoutGoogle()
      },
      async logoutGoogle() {
        await this.$firebase.auth().signOut().then(() => {
          this.$emit('close')
          window.location = '/home'
        }).catch((error) => {
          console.log(error)
        })
      }
    },
  }
</script>