<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -20px;"
      color="#27ae60">
      <div class="body-1 font-weight-bold">{{ success.message }}</div>
    </v-snackbar>
    <v-dialog
      persistent
      scrollable
      v-if="dialog.form"
      v-model="dialog.form"
      width="450">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            <span v-if="form.id !== ''">Ubah</span>
            <span v-if="form.id === ''">Buat</span>
             Ulasan
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.form = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-card-text class="pb-5 pt-0" style="min-height: 200px;">
          <div class="mt-0">
            <div class="fs-16 text-center mb-1">
              <span v-if="form.id !== ''">Ubah</span>
              <span v-if="form.id === ''">Buat</span>
              Ulasan Anda untuk kelas
            </div>

            <div class="fs-16 text-center font-weight-bold mb-16">
              {{ detail.name }}
            </div>
            <ValidationObserver ref="observer">
              <div class="text-center mb-4">
                <v-rating
                  background-color="#F39C12"
                  color="#e67e22"
                  dense
                  hover
                  @input="error.message = ''"
                  length="5"
                  :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 40 : 45"
                  v-model="form.rating">
                </v-rating>
              </div>
              

              <ValidationProvider name="Komentar" rules="max:1000" v-slot="{ errors }">
                <v-textarea
                  rows="2"
                  :class="'pt-0 mt-0'"
                  color="#4ab762"
                  :counter="1000"
                  :messages="'Optional'"
                  :error-messages="errors"
                  v-model="form.desc"
                  placeholder="Tuliskan komentar anda disini">
                  <!-- <template v-slot:append>
                    <v-btn
                      icon
                      fab
                      :disabled="process.form"
                      @click="save()"
                      small>
                      <v-icon color="#4ab762">mdi-send</v-icon>
                    </v-btn>
                  </template> -->
                  <template v-slot:message="{ message }">
                    <span :class="error.message !== '' ? 'red--text' : message !== 'Optional' ? 'red--text' : 'color-first'" v-html="error.message !== '' ? error.message : message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                    </span>
                  </template>
                </v-textarea>
              </ValidationProvider>
              <!-- <div class="d-flex align-center mt-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                <div class="color-green-second">
                  Optional
                </div>
                <div class="text-second text-draken-2 ml-auto">
                  0 dari 1.000 karakter
                </div>
              </div> -->
            </ValidationObserver>
            <v-alert 
              type="error" 
              text
              dense
              class="mb-0 mr-2 px-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="pa-0">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            elevation="0"
            color="#4ab762"
            block
            height="50"
            @click="save()"
            :loading="process.form"
            :disabled="process.form"
            class="text-capitalize white--text">
            <div class="d-flex align-center justify-center" style="width: 100%;">
              <v-icon>mdi-check</v-icon>
              <div class="ml-2 fs-18">
                <span v-if="form.id !== ''">Simpan</span>
                <span v-if="form.id === ''">Tambahkan</span>
              </div>
            </div>
          </v-btn>

          <!-- <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            :disabled="process.form"
            @click="$emit('close'); dialog.form = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.detail"
      v-model="dialog.detail"
      width="570">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Rating dan Ulasan ({{ pagination.total_data }})
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.detail = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pt-2 pb-7'">
          <div class="text-second" style="width: 60%">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari Ulasan…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
            <v-select
              :items="rate"
              solo
              v-on:change="fetch()"
              color="#4ab762"
              item-value="value"
              item-text="text"
              item-color="green"
              hide-details
              v-model="filter.rate"
              :placeholder="'Rating'"
            ></v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pb-12'" style="min-height: 500px">
          <div v-if="show_form"  class="my-5">
            <v-row>
              <v-col cols="2">
                <v-img
                  :src="user.img_url"
                  :lazy-src="user.img_url"
                  v-if="user.img_url !== ''"
                  aspect-ratio="1"
                  width="55"
                  height="55"
                  style="border-radius: 6px;"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div v-if="user.img_url === ''" style="background-color: #4ab762; color: #fff; width: 55px; height: 55px;" class="rounded-lg">
                  <v-row
                    class="fill-height ma-0 fs-28"
                    align="center"
                    justify="center"
                  >
                    {{ getKarekter(user.name) }}
                  </v-row>

                </div>
              </v-col>
              <v-col cols="10" class="pl-0">
                <ValidationObserver ref="observer">
                  <v-rating
                    background-color="#F39C12"
                    color="#e67e22"
                    dense
                    hover
                    @input="error.message = ''"
                    length="5"
                    :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
                    v-model="form.rating">
                  </v-rating>
                  <!-- <v-rating
                    hover
                    length="5"
                    :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
                    :value="form.rating"
                  ></v-rating> -->

                  <ValidationProvider name="Komentar" rules="max:1000" v-slot="{ errors }">
                    <v-textarea
                      rows="2"
                      :class="'pt-0 mt-0'"
                      color="#4ab762"
                      :counter="1000"
                      :messages="'Optional'"
                      :error-messages="errors"
                      v-model="form.desc"
                      placeholder="Tuliskan komentar anda disini">
                      <template v-slot:append>
                        <v-btn
                          icon
                          fab
                          :disabled="process.form"
                          @click="save()"
                          small>
                          <v-icon color="#4ab762">mdi-send</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:message="{ message }">
                        <span :class="error.message !== '' ? 'red--text' : message !== 'Optional' ? 'red--text' : 'color-first'" v-html="error.message !== '' ? error.message : message"></span>
                      </template>
                      <template v-slot:counter="{ props }">
                        <span style="font-size: 9px;" class="text-second">
                          {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                        </span>
                      </template>
                    </v-textarea>
                  </ValidationProvider>
                  <!-- <div class="d-flex align-center mt-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                    <div class="color-green-second">
                      Optional
                    </div>
                    <div class="text-second text-draken-2 ml-auto">
                      0 dari 1.000 karakter
                    </div>
                  </div> -->
                </ValidationObserver>
              </v-col>
            </v-row>
          </div>
          <div>
            <div>
              <div v-if="process.run">
                <v-skeleton-loader
                  class="ma-auto"
                  v-for="r in 4"
                  :key="r"
                  :loading="process.run"
                  type="list-item-avatar-three-line">
                </v-skeleton-loader>
              </div>
              <div v-if="!process.run" class="mt-5">
                <v-row v-for="(item, index) in review" :key="index">
                  <v-col cols="2">
                    <v-img
                      :src="item.user_img_url"
                      :lazy-src="item.user_img_url"
                      v-if="item.user_img_url !== ''"
                      aspect-ratio="1"
                      width="55"
                      height="55"
                      style="border-radius: 6px;"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <div v-if="item.user_img_url === undefined || item.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 55px; height: 55px;" class="rounded-lg">
                      <v-row
                        class="fill-height ma-0 fs-28"
                        align="center"
                        justify="center"
                      >
                        {{ getKarekter(item.user_name) }}
                      </v-row>

                    </div>
                  </v-col>
                  <v-col cols="10" class="pl-0">
                    <div class="d-flex align-center">
                      <div class="text-second text--darken-4" :style="item.is_user ? 'width: 80%;' : 'width: 100%;'">
                        <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;">
                          {{ item.user_name }}
                        </div>
                      </div>
                      <div class="ml-auto text-right" v-if="item.user_id === user.id" style="width: 20%;">
                        <v-btn
                          text
                          x-small
                          @click="dialog.form = true; reset(item)"
                          class="text-capitalize primary--text">
                          Ubah
                        </v-btn>
                      </div>
                    </div>
                    <v-rating
                      :value="item.rate*1"
                      background-color="grey"
                      color="#e67e22"
                      dense
                      half-increments
                      hover
                      :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 15 : 17">
                    </v-rating>
                    <div class="text-second text--draken-2 mt-1 fs-12">
                      {{ item.text }}
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="index !== (review.length - 1)">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <!-- <v-list dense class="my-3" color="transparent">
                  <v-list-item
                    v-for="(l, index) in review"
                    :key="index">
                    <v-list-item-avatar size="60" tile class="align-self-start">
                      <v-img
                        :src="l.user_img_url"
                        v-if="l.user_img_url !== ''"
                        style="border-radius: 6px;">
                      </v-img>
                      <div v-if="l.user_img_url === undefined || l.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 60px; height: 60px;" class="rounded-lg">
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          {{ getKarekter(l.user_name) }}
                        </v-row>

                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content class="cursor-pointer">
                      <div class="d-flex align-start">
                        <div>
                          <v-list-item-title class="body-1 font-weight-bold">
                            {{ l.user_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="mt-2">
                            <v-rating dense
                              :value="l.rate*1"
                              readonly
                              color="#F0932B"
                              background-color="grey darken-1">
                            </v-rating>
                          </v-list-item-subtitle>
                        </div>
                        <div class="text-second body-2 ml-auto">
                          {{ l.last_update_datetime | datetimemonth }}
                        </div>
                      </div>
                      <v-list-item-content class="text-second line-text-second body-2">
                        {{ l.text }}
                      </v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
              </div>
            </div>
            <div class="text-right mt-16" v-if="!process.run && review.length < 1">
              <Empty v-model="contentEmpty"/>
            </div>
            <div class="px-0 mb-2 mt-8 text-center color-blue-first" v-if="!process.run && pagination.current < pagination.total_page">
              <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline;" @click="loadet_list = true; page = page+1; fetch(page)">
                <div v-if="loadet_list" class="my-10 text-center">
                  <v-skeleton-loader
                    class="ma-auto"
                    v-for="r in 4"
                    :key="r"
                    :loading="process.run"
                    type="list-item-avatar-three-line">
                  </v-skeleton-loader>
                </div>
                <div v-if="!loadet_list" class="text-center">
                  Tampilkan lebih banyak
                </div>
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    data () {
      return {
        detail: {},
        dialog: {
          detail: false,
          form: false
        },
        form:{
          id: "",
          rating: 0,
          desc: ""
        },
        filter: {
          search: "",
          rate: ""
        },
        rate: [
          {
            text: "Semua Rating",
            value: ""
          },
          {
            text: "5 Bintang",
            value: "5"
          },
          {
            text: "4 Bintang",
            value: "4"
          },
          {
            text: "3 Bintang",
            value: "3"
          },
          {
            text: "2 Bintang",
            value: "2"
          },
          {
            text: "1 Bintang",
            value: "1"
          },
        ],
        page : 1,
        show_form: true,
        contentEmpty: "",
        review: [
          {
            date: 'Kemarin, pukul 12:00',
            name: 'Anne Marie',
            image: "https://picsum.photos/500/300?image=35",
            rate: 5,
            is_user: false,
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
          }
        ],
        loadet_list: false,
        pagination: {},
        snackbar: false,
        timeout: 2000,
        nama_image: "",
        review_is_user: false,
        loader: false,
        playlis_materi: [],
        process: {
          detail: true,
          read: false,
          form: false
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        }
      }
    },
    props: {
      dialogopen: {
        type: Boolean,
        default: false
      },
      detailCollection: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'dialogopen': function(val) {
        this.dialog.detail = val
      },
      'detailCollection': function(val) {
        this.detail = val
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.detail = this.$props.detailCollection
      this.fetch()
    },
    methods: {
      fetch (page) {
        this.process.run = this.loadet_list ? false : true
        let params = {
          limit: page === undefined ? 10 : page * 10,
          course_id: this.detail.course_id,
          rate: this.filter.rate,
          search: this.filter.search
        }
        this.$axios.$get(`api/course_review`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.review = response.results.data
            this.pagination = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.run = false
            this.loadet_list = false
            for (let index = 0; index < this.review.length; index++) {
              if (this.review[index].user_id === this.user.id) {
                this.show_form = false
              }
            }
          }else{
            this.process.run = false
          }
        })
      },
      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
      toSaveReview() {
        this.review.unshift(
          {
            date: 'Kemarin, pukul 12:00',
            name: 'Anda',
            is_user: true,
            image: "https://picsum.photos/500/300?image=35",
            rate: 5,
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
          }
        )

        this.review_is_user = true
      },
      reset(item) {
        this.error.message=""
        this.form = {
          id: item !== undefined ? item.id : "",
          rating: item !== undefined ? item.rate : 0,
          desc: item !== undefined ? item.text : ""
        }
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (parseInt(this.form.rating) !== 0 && isValid) {
          if (this.form.id === '') {
            this.creates()
          } else {
            this.updates()
          }
        } else {
          this.error.message = "Harap pilih bintang terlebih dahulu"
        }
      },
      async creates() {
        this.process.form = true
        await this.$axios.$post(`api/course_review`, 
        {
          course_id: this.detail.course_id,
          rate: this.form.rating,
          text: this.form.desc,
          img_url_array: ""
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch(this.page)
            this.$emit('add')
            this.success.message="Berhasil membuat rating"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        this.process.form = true
        await this.$axios.$post(`api/course_review/update`, 
        {
          id: this.form.id,
          course_id: this.detail.course_id,
          rate: this.form.rating,
          text: this.form.desc,
          img_url_array: ""
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.fetch(this.page)
            this.dialog.form = false
            this.reset()
            // this.$emit('deletes')
            this.success.message="Berhasil mengubah rating"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>