<template>
  <v-layout column class="py-12">
    <div class="text-center">
      <section
        :class="$vuetify.breakpoint.name === 'xl' ? 'container' : ''">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" md="12">
              <v-card
                class="border-radius box-shadow pa-12">
                <v-row justify="center" align="center">
                  <v-col cols="12" md="10">
                    <div class="py-9">
                      <v-img 
                        alt="otodidak" 
                        :src="require('@/assets/icon/logo.png')"
                        max-height="250"
                        max-width="250"
                        class="ma-auto">
                      </v-img>
                    </div>
                    <div class="pb-12">
                      <p class="text-h5 text-first font-weight-bold mb-1 pt-12">
                        Internal server error
                      </p>
                      <p class="title font-weight-light text-second pb-12">
                        {{ language === 'en' ? 'The link you visited may be broken or no longer exists.' : 'Tautan yang anda kunjungi mungkin rusak atau tidak ada lagi.' }}
                      </p>
                      <v-btn
                        light
                        rounded
                        color="#099A55"
                        large
                        elevation="3"
                        class="white--text text-capitalize px-6"
                        to="/">
                        <v-icon class="mr-1">mdi-chevron-left-circle-outline</v-icon>
                        {{ language === 'en' ? 'Back To Home' : 'Kembali Ke Beranda' }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
  </v-layout>
</template>

<script> 
  import { createSEOMeta } from '@/utils/seo'
  export default {
    name: 'error-500',
    data () {
      return {
        // SEO
        content: {
          url: '',
          title: '500',
          description: 'Internal server error',
          image: require('@/assets/icon/logo.png'),
        }
      }
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      language() {
        return this.$store.state.content.lang.lang
      }
    }
  }
</script>