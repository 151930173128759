<template>
  <div>
    <v-dialog
      persistent
      scrollable
      v-if="dialog.user"
      v-model="dialog.user"
      width="450">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Detail Pembeli
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.user = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text class="pb-5 pt-3" style="min-height: 200px;">
          <div class="mb-2 text-first text--darken-2">
            <div class="fs-12">
              Tanggal
            </div>
            <div>
              2 Januari 2022
            </div>
          </div>
          <div class="mb-5 text-first text--darken-2">
            <div class="fs-12">
              Kelas
            </div>
            <div>
              React Native - The Practical Guide [2021 Edition]
            </div>
          </div>
          <div class="d-flex align-center" style="padding: 10px 5px; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
              Nama Pembeli
            </div>
            <div class="ml-auto green--text" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
              Nominal
            </div>
          </div>
          <v-divider class="pb-1"></v-divider>
          <div class="d-flex align-center" style="padding: 15px 5px; border-bottom: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
              Asri Kurniasih
            </div>
            <div class="green--text ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
              Rp 500.000
            </div>
          </div>
          <div class="d-flex align-center" style="padding: 15px 5px; border-bottom: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
              Asri Kurniasih
            </div>
            <div class="green--text ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
              Rp 500.000
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pa-0">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            elevation="0"
            color="#4ab762"
            block
            height="50"
            @click="dialog.detail = true"
            class="text-capitalize white--text">
            <div class="d-flex align-center justify-center" style="width: 100%;">
              Lihat Kelas Lainnya
            </div>
          </v-btn>

          <!-- <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            :disabled="process.form"
            @click="$emit('close'); dialog.form = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.detail"
      v-model="dialog.detail"
      width="1200">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-5 pb-3 pt-5'">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Detail Penjualan 
            <span>
              <span v-if="filterTanggal.sort_filter !== 'Tahun Ini' && filterTanggal.sort_filter !== 'Tahun Kemarin'">
                {{ detail.date_raw | dateTanggalMonth }}
              </span>
              <span v-if="filterTanggal.sort_filter === 'Tahun Ini' || filterTanggal.sort_filter === 'Tahun Kemarin'">
                {{ detail.date_raw | monthyear }}
              </span>
            </span>
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.detail = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pb-10'" style="min-height: 400px; height: 100vh;">
          <div style="height: 100%;">
            <v-row style="height: 100%;">
              <v-col cols="12" md="5" class="pr-0" style="border-right: 0.5px solid rgba(151, 151, 151, 0.10); height: 100%;">
                <div class="mb-3 font-weight-bold">
                  Data Kelas
                </div>
                <div class="pr-3 mb-5" style="height: calc(100% * 0.75); overflow-y: auto; border-bottom: 0.5px solid rgba(151, 151, 151, 0.10)">
                  <div v-if="process.run">
                    <v-skeleton-loader
                      class="ma-auto"
                      v-for="r in 4"
                      :key="r"
                      :loading="process.run"
                      type="list-item-three-line">
                    </v-skeleton-loader>
                  </div>
                  <div v-if="!process.run">
                    <div class="mb-6" v-for="(item, index) in list" :key="index" style="background: #f6f6f6; min-height: 55px; padding: 10px 11px; border-radius: 6px;" :style="filter.data === index ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected = item; filter.data = index; fetchUser();">
                      <div class="d-flex align-center" style="width: 100%;">
                        <div class="body-1 ml-0 font-weight-bold text-capitalize">
                          {{ item.course_name }}
                          <div class="caption text-second d-flex align-center">
                            <div class="pr-2" style="border-right: 1px solid">
                              {{ item.count_transaction }} Transaksi
                            </div>
                            <div class="pl-2 green--text font-weight-bold" >
                              {{ item.sum_transaction_total_paid_nett | price }}
                            </div>
                          </div>
                        </div>
                        <div class="ml-auto pl-4 text-right" style="color: #4ab762;">
                          <v-icon size="20" :color="filter.data !== index ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-right mt-16" v-if="!process.run && list.length < 1">
                    <Empty v-model="contentEmpty"/>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="7" style="height: calc(100vh - 190px); overflow-y: auto;">
                <div>
                  <div v-if="process.user">
                    <v-skeleton-loader
                      class="ma-auto"
                      v-for="r in 4"
                      :key="r"
                      :loading="process.user"
                      type="list-item-avatar-three-line">
                    </v-skeleton-loader>
                  </div>
                  <div v-if="!process.user">
                    <div class="mb-3 font-weight-bold">
                      Data Pembeli
                    </div>
                    <!-- <div class="d-flex align-center" style="padding: 10px 5px; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
                        Nama Pembeli
                      </div>
                      <div class="ml-auto green--text" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
                        Nominal
                      </div>
                    </div>
                    <v-divider class="pb-1"></v-divider> -->
                    <div class="d-flex align-center" v-for="(l, k) in users" :key="k" style="padding: 15px 5px; border-bottom: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
                        <v-list-item class="py-0 px-0">
                          <v-list-item-avatar
                            size="40"
                            class="my-0"
                            tile>
                            <v-img 
                              :src="l.user_img_url" style="border-radius: 6px;"
                              v-if="l.user_img_url !== ''">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <div v-if="l.user_img_url === undefined || l.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 38px; height: 38px;" class="rounded-lg">
                                <v-row
                                  class="fill-height ma-0 fs-28"
                                  align="center"
                                  justify="center"
                                >
                                  {{ getKarekter(l.user_name) }}
                                </v-row>

                              </div>
                          </v-list-item-avatar>
                          <v-list-item-content class="pb-0 pt-0">
                            {{ l.user_name }}
                            <div style="margin-top: 5px;">
                              {{ l.user_email }}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div class="ml-auto" style="font-weight: normal; font-size: 14px; line-height: 16px; text-align: right;">
                        <div class="fs-12">
                          Nominal
                        </div>
                        <div class="green--text font-weight-bold" style="margin-top: 3px;">
                          {{ l.sum_transaction_total_paid_nett | price }}
                        </div>
                      </div>
                    </div>

                    <div class="text-right mt-16" v-if="!process.user && users.length < 1">
                      <Empty v-model="contentEmpty"/>
                    </div>

                    <div class="py-3 text-center">
                      <v-chip
                        color="white"
                        text-color="green"
                        v-show="loader"
                      >
                        <v-progress-circular
                          indeterminate
                          size="16"
                          width="2"
                          class="mr-4"
                          color="green"
                        ></v-progress-circular>
                        Memuat data...
                      </v-chip>
                      <v-btn
                        v-show="
                          !loader &&
                          pagination.current < pagination.total_page
                        "
                        @click="loader = true; page = page+1; fetchUser(page)"
                        depressed
                        small
                        class="text-none white--text"
                        color="green"
                        >Tampilkan lagi
                        <v-icon right small>mdi-arrow-down</v-icon></v-btn
                      >
                    </div>

                    <!-- <div class="d-flex align-center" style="padding: 15px 5px; border-bottom: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
                        <v-list-item class="py-0 px-0">
                          <v-list-item-avatar
                            size="40"
                            class="my-0"
                            tile>
                            <v-img 
                              src="https://picsum.photos/id/11/500/300" style="border-radius: 6px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                          <v-list-item-content class="pb-0 pt-0">
                            Asri Kurniasih
                            <div style="margin-top: 5px;">
                              asri.kurniasih@gmail.com
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div class="ml-auto" style="font-weight: normal; font-size: 14px; line-height: 16px; text-align: right;">
                        <div class="fs-12">
                          Nominal
                        </div>
                        <div class="green--text font-weight-bold" style="margin-top: 3px;">
                          Rp 500.000
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <!-- <v-card-text class="pt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-5 pb-12'" style="min-height: 500px">
          <div class="d-flex align-center" style="padding: 10px 18px; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
              Nama Kelas
            </div>
            <div class="ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
              Jumalah Transaksi
            </div>
          </div>
          <v-divider class="pb-1"></v-divider>
          <div class="d-flex align-center" style="padding: 15px 18px; border-bottom: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
              React Native - The Practical Guide [2021 Edition]
            </div>
            <div class="primary--text ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
              <u class="cursor-pointer" @click="dialog.user = true; dialog.detail = false;">5 Transaksi</u>
            </div>
          </div>
          <div class="d-flex align-center" style="padding: 15px 18px; border-bottom: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
              React Native - The Practical Guide [2021 Edition]
            </div>
            <div class="primary--text ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
              <u class="cursor-pointer" @click="dialog.user = true; dialog.detail = false;">5 Transaksi</u>
            </div>
          </div>
        </v-card-text> -->
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    data () {
      return {
        detail: {},
        dialog: {
          detail: false,
          user: false
        },
        filter: {
          data: 0
        },
        contentEmpty: "",
        selected: {},
        list: [],
        users: [],
        pagination: {},
        paginationUser: {},
        filterTanggal: {
          tanggal_mulai: "",
          tanggal_selesai: "",
          sort_filter: "Tahun Ini"
        },
        loader: false,
        page: 1,
        process: {
          detail: false,
          user: false,
          run: false
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        }
      }
    },
    props: {
      dialogopen: {
        type: Boolean,
        default: false
      },
      detailData: {
        type: Object,
        default: () => {}
      },
      filterData: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'dialogopen': function(val) {
        this.dialog.detail = val
      },
      'detailData': function(val) {
        this.detail = val
        this.fetch()
      },
      'filterData': function(val) {
        this.filterTanggal = val
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.detail = {}
      this.detail = this.$props.detailData
      if (Object.keys(this.detail).length > 0) {
        this.fetch()
      }
    },
    methods: {
      async fetch () {
        this.process.run = true
        this.process.user = true
        let params = {
          limit: 100000000,
          date: this.detail.date_raw     
          }
        this.$axios.$get(`api/publisher/report/summary/list_detail_by_course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            this.selected = response.results.data[0]
            if (response.results.data.length > 0) {
              this.fetchUser()
            } else {
              this.users = []
              this.contentEmpty = "Data belum tersedia"
              this.process.user = false
            }
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      async fetchUser (page) {
        this.process.user =  this.loader ? false :  true
        let params = {
          date: this.detail.date_raw,
          limit: page === undefined ? 10 : page * 10,
          course_id: this.selected.course_id
          }
        this.$axios.$get(`api/publisher/report/summary/list_detail_by_transaction`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.loader = this.loader ? false : this.loader
            this.users = response.results.data
            this.pagination = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.user = false
          }else{
            this.process.user = false
          }
        })
      },
      
      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
    }
  };
</script>