<template>
  <div>
    <v-container>
      <section class="py-14">
        <v-row class="align-center">
          <v-col cols="12" md="6">
            <v-img :src="require('@/assets/publichome/header_image.png')" ></v-img>  
          </v-col>
          <v-col cols="12" md="6">
            <v-carousel v-model="model" hide-delimiters :show-arrows="false" height="auto">
              <v-carousel-item
                v-for="(elearning, i) in 5"
                :key="i">
                <div class="text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                  Elearning |  Programming
                </div>
                <div class="text-first font-weight-bold my-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
                  The Complete React Native + Hooks Course
                </div>
                <div class="text-second line-text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                  Use React Native and your React knowledge to build native iOS and Android Apps - incl. Push Notifications, Hooks, Redux.
                </div>
                <div class="d-flex align-center text-second my-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                  <div class="text-first font-weight-bold">
                    5.0
                  </div>
                  <div class="mx-2">
                    <v-rating
                      :value="5"
                      readonly
                      background-color="white"
                      color="#e67e22"
                      dense
                      half-increments
                      hover
                      size="20">
                    </v-rating>
                  </div>
                  <div class="">
                    (1.024)
                  </div>
                </div>
                <div class="mb-5">
                  <v-btn 
                    elevation="0"
                    :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                    color="#4ab762"
                    class="mr-3 mt-2 white--text text-capitalize font-weight-bold">
                    Beli Sekarang
                  </v-btn>
                  <v-btn 
                    elevation="0"
                    outlined
                    :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                    color="#000000"
                    class="mr-3 mt-2 text-capitalize">
                    <v-icon size="15" class="mr-2">mdi-share-variant</v-icon>
                    Bagikan
                  </v-btn>
                  <v-btn 
                    elevation="0"
                    outlined
                    :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                    color="#d35400"
                    class="mr-3 mt-2 text-capitalize">
                    <v-icon size="15" class="mr-2">mdi-heart-outline</v-icon>
                    Wishlist
                  </v-btn>
                </div>
              </v-carousel-item>
            </v-carousel>
            <div class="text-left ml-n2"> 
              <v-btn 
                v-for="(item, i) in 5"
                :key="i"
                :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                elevation="0"
                x-small
                fab
                :color="model === i ? '#4ab762' : '#5baf6036'"
                class="ma-2"
                @click="model = i">
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="py-5">
        <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
          <div>
            <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
              Mau Belajar Apa Hari ini?
            </div>
            <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
          </div>
          <div class="text-second ml-auto">
            <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
            <v-btn 
              class="px-0" 
              min-width="36" 
              elevation="0"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
              color="#ececec78">
              <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <v-col cols="12" md="3">
            <v-img :src="require('@/assets/publichome/content_image_1.png')" ></v-img> 
            <div class="text-first text-h5 text-center font-weight-bold mt-4 mb-1">
              Programming
            </div> 
            <div class="text-center text-second">
              (1.024)
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <v-img :src="require('@/assets/publichome/content_image_2.png')" ></v-img> 
            <div class="text-first text-h5 text-center font-weight-bold mt-4 mb-1">
              Research & Ideas
            </div> 
            <div class="text-center text-second">
              (512)
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <v-img :src="require('@/assets/publichome/content_image_3.png')" ></v-img> 
            <div class="text-first text-h5 text-center font-weight-bold mt-4 mb-1">
              Art & Designs
            </div> 
            <div class="text-center text-second">
              (256)
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <v-img :src="require('@/assets/publichome/content_image_4.png')" ></v-img> 
            <div class="text-first text-h5 text-center font-weight-bold mt-4 mb-1">
              Science
            </div> 
            <div class="text-center text-second">
              (128)
            </div>
          </v-col>
        </v-row>

        <v-sheet
          class="mx-auto"
          elevation="0"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
          max-width="800">
          <v-slide-group
            v-model="model1"
            class="pa-0"
            :show-arrows="false">
            <v-slide-item
              v-for="(c, k) in category"
              :key="k">
              <v-card
                class="ma-2"
                height="210"
                width="150"
                flat>
              <v-img :src="c.image" style="width: 150px;" class="mx-auto"></v-img> 
                <div class="text-first body-2 text-center font-weight-bold mt-4 mb-1">
                  {{ c.title }}
                </div> 
                <div class="text-center text-second caption">
                  ({{ c.count }})
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </section>
    
      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-8' : 'py-14'">
        <v-row class="align-center">
          <v-col cols="12" md="6">
            <v-carousel v-model="model2" hide-delimiters :show-arrows="false" height="auto">
              <v-carousel-item
                v-for="(elearning, i) in 5"
                :key="i">
                <div class="text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'body-2'">
                  Official Partner
                </div>
                <div class="text-first font-weight-bold my-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
                  Start Learning Online Courses with ESODA
                </div>
                <div class="text-second line-text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <div class="my-5">
                  <v-btn 
                    elevation="0"
                    outlined
                    color="#4ab762"
                    class="mr-3 text-capitalize font-weight-bold">
                    View All Course
                  </v-btn>
                </div>
              </v-carousel-item>
            </v-carousel>
            <div class="text-left ml-n2"> 
              <v-btn 
                v-for="(item, i) in 5"
                :key="i"
                :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                elevation="0"
                x-small
                fab
                :color="model2 === i ? '#4ab762' : '#5baf6036'"
                class="ma-2"
                @click="model2 = i">
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-card flat>
              <v-img :src="require('@/assets/publichome/content_image_2.png')" style="width: 70%;" class="ml-auto"></v-img>  
              <div style="position: absolute; bottom: -70px; left: 70px;">
                <v-img :src="require('@/assets/publichome/content_image_5.png')" style="width: 55%;"></v-img>  
              </div>
            </v-card>
          </v-col>
        </v-row>
      </section>
    
      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-5 pt-10'">
        <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
          <div>
            <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
              Kelas Populer & Terlaris
            </div>
            <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
          </div>
          <div class="text-second ml-auto">
            <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
              <span class="cursor-pointer">
                <router-link to="/elearning" class="text-second">
                  Lihat Semua
                </router-link>
              </span>
            </span>
            <v-btn 
              class="px-0" 
              min-width="36" 
              elevation="0"
              to="/elearning"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
              color="#ececec78">
              <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_6.png')" ></v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                Socket.IO Chat App in React Native for iOS/Android
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_power_merchant.svg')"></v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                Rp. 150.000
              </div> 
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_7.png')" >
                <div class="badge-overlay-circle">
                  <span class="top-right-circle badge-circle">
                    <div
                      class="font-weight-bold"
                      style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                      -50%
                    </div>
                  </span>
                </div>
              </v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                React Native: Advanced Concepts
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_official_merchant.svg')">
                  </v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                <span class="text-decoration-line-through text-second mr-2">
                  Rp. 150.000
                </span>
                <span>
                  Rp 75.000
                </span>
              </div> 
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_8.png')" ></v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                Socket.IO Chat App in React Native for iOS/Android
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_power_merchant.svg')"></v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                Rp. 150.000
              </div> 
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_4.png')" ></v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                Socket.IO Chat App in React Native for iOS/Android
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_official_merchant.svg')"></v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                Rp. 150.000
              </div> 
            </v-card>
          </v-col>
        </v-row>

        <v-sheet
          class="mx-auto"
          elevation="0"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
          max-width="800">
          <v-slide-group
            v-model="model3"
            class="pa-0"
            :show-arrows="false">
            <v-slide-item
              v-for="(p, r) in popular"
              :key="r">
              <v-card
                class="ma-2"
                height="290"
                to="/elearning/detail"
                width="180"
                flat>
              <v-img :src="p.image" style="width: 180px;" class="mx-auto">
                <div class="badge-overlay-circle" v-if="p.is_diskon">
                  <span class="top-right-circle badge-circle">
                    <div
                      class="font-weight-bold"
                      style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                      -50%
                    </div>
                  </span>
                </div>
              </v-img> 
                <div class="text-first body-2 font-weight-bold mt-4 mb-1 two-line">
                  {{ p.title }}
                </div> 
                <div class="text-first body-2 font-weight-bold py-2" v-if="p.is_diskon">
                  <span class="text-decoration-line-through text-second mr-2">
                    Rp. 150.000
                  </span>
                  <span>
                    Rp 75.000
                  </span>
                </div>  
                <div class="text-first body-2 font-weight-bold py-2 color-green-second" v-if="!p.is_diskon">
                  Rp. 150.000
                </div> 
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </section>
    
      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
        <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
          <div>
            <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
              Berdasarkan Pencarianmu
            </div>
            <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
          </div>
          <div class="text-second ml-auto">
            <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
              <span class="cursor-pointer">
                <router-link to="/elearning" class="text-second">
                  Lihat Semua
                </router-link>
              </span>
            </span>
            <v-btn 
              class="px-0" 
              min-width="36" 
              elevation="0"
              to="/elearning"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
              color="#ececec78">
              <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_6.png')" ></v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                Socket.IO Chat App in React Native for iOS/Android
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_power_merchant.svg')"></v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                Rp. 150.000
              </div> 
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_7.png')" >
                <div class="badge-overlay-circle">
                  <span class="top-right-circle badge-circle">
                    <div
                      class="font-weight-bold"
                      style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                      -50%
                    </div>
                  </span>
                </div>
              </v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                React Native: Advanced Concepts
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_official_merchant.svg')">
                  </v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                <span class="text-decoration-line-through text-second mr-2">
                  Rp. 150.000
                </span>
                <span>
                  Rp 75.000
                </span>
              </div> 
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_8.png')" ></v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                Socket.IO Chat App in React Native for iOS/Android
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_power_merchant.svg')"></v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                Rp. 150.000
              </div> 
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card to="/elearning/detail" flat>
              <v-img :src="require('@/assets/publichome/content_image_4.png')" ></v-img> 
              <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                Socket.IO Chat App in React Native for iOS/Android
              </div> 
              <v-list-item class="px-0">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/icon/icon_official_merchant.svg')"></v-img>
                </v-list-item-avatar>
    
                <v-list-item-content>
                  <v-list-item-title class="text-second">Era Solusi Data</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div class="d-flex align-center body-2 text-second">
                <div class="text-first font-weight-bold">
                  5.0
                </div>
                <div class="mx-2">
                  <v-rating
                    :value="5"
                    readonly
                    background-color="white"
                    color="#e67e22"
                    dense
                    half-increments
                    hover
                    size="20">
                  </v-rating>
                </div>
                <div class="">
                  (1.024)
                </div>
              </div>
              <div class="text-first text-h6 font-weight-bold py-2">
                Rp. 150.000
              </div> 
            </v-card>
          </v-col>
        </v-row>

        <v-sheet
          class="mx-auto"
          elevation="0"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
          max-width="800">
          <v-slide-group
            v-model="model4"
            class="pa-0"
            :show-arrows="false">
            <v-slide-item
              v-for="(p, r) in popular"
              :key="r">
              <v-card
                class="ma-2"
                height="290"
                to="/elearning/detail"
                width="180"
                flat>
                  <v-img :src="p.image" style="width: 180px;" class="mx-auto">
                    <div class="badge-overlay-circle" v-if="p.is_diskon">
                      <span class="top-right-circle badge-circle">
                        <div
                          class="font-weight-bold"
                          style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                          -50%
                        </div>
                      </span>
                    </div>
                  </v-img> 
                <div class="text-first body-2 font-weight-bold mt-4 mb-1 two-line">
                  {{ p.title }}
                </div> 
                <div class="text-first body-2 font-weight-bold py-2" v-if="p.is_diskon">
                  <span class="text-decoration-line-through text-second mr-2">
                    Rp. 150.000
                  </span>
                  <span>
                    Rp 75.000
                  </span>
                </div>  
                <div class="text-first body-2 font-weight-bold py-2 color-green-second" v-if="!p.is_diskon">
                  Rp. 150.000
                </div> 
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

      </section>
    
      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-7' : 'py-16 my-10'">
        <div class="text-first text-center font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
          Belajar Bisa Dapat Poin Lo, Mau?
        </div>
        <div class="text-second text-center mt-4 mb-10" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h5'">
          Dapatkan 10% Diskon untuk temanmu + 1 Kelas Gratis & Akun Premium untukmu
        </div>
        <v-row>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_9.png')" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 35%;' : 'width:75%;'" class="mx-auto"></v-img> 
            <div class="text-first text-center font-weight-bold mt-11 mb-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
              Ajak Temanmu untuk Belajar
            </div> 
            <div class="text-center text-second body-1" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : ''">
              Salin dan bagikan link referensimu di media sosial atau kirim ajakan ke temanmu melalui email
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_10.png')" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 35%;' : 'width:75%;'" class="mx-auto"></v-img> 
            <div class="text-first text-center font-weight-bold mt-4 mb-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
              Ajak Temanmu untuk Belajar
            </div> 
            <div class="text-center text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
              Salin dan bagikan link referensimu di media sosial atau kirim ajakan ke temanmu melalui email
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_11.png')" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 35%;' : 'width:75%;'" class="mx-auto"></v-img> 
            <div class="text-first text-center font-weight-bold mt-4 mb-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
              Ajak Temanmu untuk Belajar
            </div> 
            <div class="text-center text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
              Salin dan bagikan link referensimu di media sosial atau kirim ajakan ke temanmu melalui email
            </div>
          </v-col>
        </v-row>
        <div class="my-10 text-center">
          <v-btn 
            elevation="0"
            outlined
            :large="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? false : true"
            :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
            color="grey darken-1"
            class="text-capitalize font-weight-bold px-6">
            Baca Sarat & Ketentuan
          </v-btn>
        </div>
      </section>
    
      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
        <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
          <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 5%;' : 'width: 2%'">
            <v-img :src="require('@/assets/icon/icon_power_merchant.svg')" ></v-img> 
          </div>
          <div class="d-flex align-center ml-2" style="width: 98%">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h6'">
                Official Partner
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_12.png')" ></v-img> 
            <div class="d-flex align-center mt-5 mb-1">
              <div>
                <div class="text-first text-h6 font-weight-bold">
                  ESODA
                </div>
              </div>
              <div class="text-second ml-auto">
                (1.024)
              </div>
            </div>
            <div class="text-second body-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_13.png')" ></v-img> 
            <div class="d-flex align-center mt-5 mb-1">
              <div>
                <div class="text-first text-h6 font-weight-bold">
                  Era Solusi Data
                </div>
              </div>
              <div class="text-second ml-auto">
                (150)
              </div>
            </div>
            <div class="text-second body-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_14.png')" ></v-img> 
            <div class="d-flex align-center mt-5 mb-1">
              <div>
                <div class="text-first text-h6 font-weight-bold">
                  Amila Community
                </div>
              </div>
              <div class="text-second ml-auto">
                (4)
              </div>
            </div>
            <div class="text-second body-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
          </v-col>
        </v-row>

        <v-sheet
          class="mx-auto"
          elevation="0"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
          max-width="800">
          <v-slide-group
            v-model="model5"
            class="pa-0"
            :show-arrows="false">
            <v-slide-item
              v-for="(p, r) in partner"
              :key="r">
              <v-card
                class="ma-2"
                height="200"
                width="180"
                flat>
                <v-img :src="p.image" ></v-img> 
                <div class="d-flex align-center mt-3 mb-1">
                  <div>
                    <div class="text-first body-2 font-weight-bold">
                      {{ p.title }}
                    </div>
                  </div>
                  <div class="text-second ml-auto caption">
                    ({{ p.count }})
                  </div>
                </div>
                <div class="text-second caption">
                  {{ p.desc }}
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </section>

      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
        <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
          <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 5%;' : 'width: 2%'">
            <v-img :src="require('@/assets/icon/icon_official_merchant.svg')" ></v-img> 
          </div>
          <div class="d-flex align-center ml-2" style="width: 98%">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h6'">
                Power Merchant
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_12.png')" ></v-img> 
            <div class="d-flex align-center mt-5 mb-1">
              <div>
                <div class="text-first text-h6 font-weight-bold">
                  ESODA
                </div>
              </div>
              <div class="text-second ml-auto">
                (1.024)
              </div>
            </div>
            <div class="text-second body-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_13.png')" ></v-img> 
            <div class="d-flex align-center mt-5 mb-1">
              <div>
                <div class="text-first text-h6 font-weight-bold">
                  Era Solusi Data
                </div>
              </div>
              <div class="text-second ml-auto">
                (150)
              </div>
            </div>
            <div class="text-second body-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/content_image_14.png')" ></v-img> 
            <div class="d-flex align-center mt-5 mb-1">
              <div>
                <div class="text-first text-h6 font-weight-bold">
                  Amila Community
                </div>
              </div>
              <div class="text-second ml-auto">
                (4)
              </div>
            </div>
            <div class="text-second body-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
          </v-col>
        </v-row>

        <v-sheet
          class="mx-auto"
          elevation="0"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
          max-width="800">
          <v-slide-group
            v-model="model6"
            class="pa-0"
            :show-arrows="false">
            <v-slide-item
              v-for="(p, r) in partner"
              :key="r">
              <v-card
                class="ma-2"
                height="200"
                width="180"
                flat>
                <v-img :src="p.image" ></v-img> 
                <div class="d-flex align-center mt-3 mb-1">
                  <div>
                    <div class="text-first body-2 font-weight-bold">
                      {{ p.title }}
                    </div>
                  </div>
                  <div class="text-second ml-auto caption">
                    ({{ p.count }})
                  </div>
                </div>
                <div class="text-second caption">
                  {{ p.desc }}
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </section>

      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
        <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
          <div>
            <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
              Apa Kata Mereka
            </div>
            <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
          </div>
          <div class="text-second ml-auto">
            <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
            <v-btn 
              class="px-0" 
              min-width="36" 
              elevation="0"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
              color="#ececec78">
              <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <v-col cols="12" md="4">
            <v-card
              flat
              height="390"
              class="border-radius box-shadow">
              <v-card-title>
                <v-avatar
                  tile
                  size="40">
                  <v-img
                    contain
                    :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                  </v-img>
                </v-avatar>
              </v-card-title>
              <v-card-text class="body-2 line-text-second text-second">
                I believe that everything happens for a reason.
                People change so that you can learn to let go,
                things go wrong so that you appreciate them
                when they're right, you believe lies so you
                eventually learn to trust no one but yourself,
                and sometimes good things fall apart so better
                things can fall together.
              </v-card-text>
              <div class="list-bottom">
                <v-card-title class="pt-0">
                  <v-avatar
                    size="50">
                    <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-title class="subtitle-2 text-second pt-0 pb-1">
                  Marilyn Monroe
                </v-card-title>
                <v-card-title class="subtitle-2 pt-0 pb-5 text-second">
                  Los Angeles
                </v-card-title>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              flat
              height="390"
              class="border-radius box-shadow">
              <v-card-title>
                <v-avatar
                  tile
                  size="40">
                  <v-img
                    contain
                    :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                  </v-img>
                </v-avatar>
              </v-card-title>
              <v-card-text class="body-2 line-text-second text-second">
                I believe that everything happens for a reason.
                People change so that you can learn to let go,
                things go wrong so that you appreciate them
                when they're right, you believe lies so you
                eventually learn to trust no one but yourself,
                and sometimes good things fall apart so better
                things can fall together.
              </v-card-text>
              <div class="list-bottom">
                <v-card-title class="pt-0">
                  <v-avatar
                    size="50">
                    <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-title class="subtitle-2 text-second pt-0 pb-1">
                  Marilyn Monroe
                </v-card-title>
                <v-card-title class="subtitle-2 pt-0 pb-5 text-second">
                  Los Angeles
                </v-card-title>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              flat
              height="390"
              class="border-radius box-shadow">
              <v-card-title>
                <v-avatar
                  tile
                  size="40">
                  <v-img
                    contain
                    :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                  </v-img>
                </v-avatar>
              </v-card-title>
              <v-card-text class="body-2 line-text-second text-second">
                I believe that everything happens for a reason.
                People change so that you can learn to let go,
                things go wrong so that you appreciate them
                when they're right, you believe lies so you
                eventually learn to trust no one but yourself,
                and sometimes good things fall apart so better
                things can fall together.
              </v-card-text>
              <div class="list-bottom">
                <v-card-title class="pt-0">
                  <v-avatar
                    size="50">
                    <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-title class="subtitle-2 text-second pt-0 pb-1">
                  Marilyn Monroe
                </v-card-title>
                <v-card-title class="subtitle-2 pt-0 pb-5 text-second">
                  Los Angeles
                </v-card-title>
              </div>
            </v-card>
          </v-col>
  
        </v-row>

        <v-sheet
          class="mx-auto"
          elevation="0"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
          max-width="800">
          <v-slide-group
            v-model="model7"
            class="pa-0"
            :show-arrows="false">
            <v-slide-item
              v-for="(r, a) in review"
              :key="a">
              <v-card
                height="325"
                class="border-radius box-shadow ma-2"
                :style="$vuetify.breakpoint.name === 'sm' ? 'width: 350px;' : 'width: calc(100vw - 40px);'"
                flat>
                <v-card-title>
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                    </v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-text class="caption line-text-second text-second">
                  {{ r.desc }}
                </v-card-text>
                <div class="list-bottom">
                  <v-card-title class="pt-0">
                    <v-avatar
                      size="50">
                      <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                    </v-avatar>
                  </v-card-title>
                  <v-card-title class="caption text-second pt-0 pb-1">
                    {{ r.name }}
                  </v-card-title>
                  <v-card-title class="caption pt-0 pb-5 text-second">
                    {{ r.address }}
                  </v-card-title>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

      </section>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        year: {
          selected: "2021",
          items: ["2021", "2020", "2019", "2018"]
        },
        model: 0,
        model1: 0,
        model2: 0,
        model3: 0,
        model4: 0,
        model5: 0,
        model6: 0,
        model7: 0,
        category: [
          {
            title: "Programming",
            image: require('@/assets/publichome/content_image_1.png'),
            count: "1.024"
          },
          {
            title: "Research & Ideas",
            image: require('@/assets/publichome/content_image_2.png'),
            count: "1.024"
          },
          {
            title: "Art & Designs",
            image: require('@/assets/publichome/content_image_3.png'),
            count: "1.024"
          },
          {
            title: "Science",
            image: require('@/assets/publichome/content_image_4.png'),
            count: "1.024"
          }
        ],
        popular: [
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "React Native: Advanced Concepts",
            image: require('@/assets/publichome/content_image_7.png'),
            price: "Rp. 150.000",
            is_diskon: true,
            diskon_value: 50,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_7.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          }
        ],
        searching: [
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "React Native: Advanced Concepts",
            image: require('@/assets/publichome/content_image_7.png'),
            price: "Rp. 150.000",
            is_diskon: true,
            diskon_value: 50,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_4.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          }
        ],
        partner: [
          {
            title: "ESODA",
            image: require('@/assets/publichome/content_image_12.png'),
            count: "1.024",
            desc: "Lorem Ipsum is simply dummy text of the printing."
          },
          {
            title: "Era Solusi Data",
            image: require('@/assets/publichome/content_image_13.png'),
            count: "150",
            desc: "Lorem Ipsum is simply dummy text of the printing."
          },
          {
            title: "Amila Community",
            image: require('@/assets/publichome/content_image_14.png'),
            count: "4",
            desc: "Lorem Ipsum is simply dummy text of the printing."
          }
        ],
        review: [
          {
            desc: "I believe that everything happens for a reason. People change so that you can learn to let go, things go wrong so that you appreciate them when they're right, you believe lies so you eventually learn to trust no one but yourself, and sometimes good things fall apart so better things can fall together.",
            name: "Marilyn Monroe",
            image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            address: "Los Angeles"
          },
          {
            desc: "I believe that everything happens for a reason. People change so that you can learn to let go, things go wrong so that you appreciate them when they're right, you believe lies so you eventually learn to trust no one but yourself, and sometimes good things fall apart so better things can fall together.",
            name: "Marilyn Monroe",
            image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            address: "Los Angeles"
          },
          {
            desc: "I believe that everything happens for a reason. People change so that you can learn to let go, things go wrong so that you appreciate them when they're right, you believe lies so you eventually learn to trust no one but yourself, and sometimes good things fall apart so better things can fall together.",
            name: "Marilyn Monroe",
            image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            address: "Los Angeles"
          }
        ],
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      // this.fetch()
      window.location="/home"
    },
    methods: {
      fetch () {
        // this.process.run = true
        // let params = {
        //   content_type: 'donasi',
        //   sort: 'donation_end_datetime',
        //   dir: 'ASC'
        // }
        this.$axios.$get(`api/class/lists/banner`)
        .then((response)=>{
          console.log('data response', response)
          // if(response.status === 200) {
          //   this.process.run = false
          //   this.list = response.results.data
          //   this.getLength = this.list.length
          //   this.contentEmpty = 'Data tidak tersedia'
          // }else{
          //   this.process.run = false
          // }
        })
      },
    }
  }
</script>
