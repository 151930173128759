<template>
  <div>
    <div>

      <section>
        <v-row>
          <v-col cols="12" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <div class="d-flex align-center text-second">
              <div>
                Nama Kelas
              </div>
            </div>
            <ValidationProvider name="Nama" rules="required|max:50" v-slot="{ errors }">
              <v-text-field
                color="#4ab762"
                class="pt-0"
                :counter="50"
                placeholder="Tuliskan disini"
                
                v-model="form.name"
                :messages="'Wajib'"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span class="red--text" v-html="message"></span>
                </template>
                <template v-slot:counter="{ props }">
                  <span style="font-size: 9px;" class="text-second">
                    {{ props.value }} dari {{ props.max }} karakter
                  </span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <div class="d-flex align-center text-second">
              <div>
                Kategori
              </div>
            </div>
            <ValidationProvider name="Kategori" rules="" v-slot="{ errors }">
              <!-- <v-select
                color="#4ab762"
                class="pt-0"
                :messages="'Optional'"
                placeholder="Pilih kategori disini"
                
                v-model="form.category_id"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                </template>
              </v-select> -->
              <v-autocomplete
                :items="category"
                color="#4ab762"
                :messages="'Optional'"
                placeholder="Pilih kategori disini"
                item-text="name"
                item-value="id"
                item-color="green"
                return-object
                v-model="form.category_id"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                </template>
                <template v-slot:no-data>
                  
                </template>
                <!-- <template v-slot:selection="data">
                  {{ data.item }}
                </template> -->
                <!-- <template v-slot:item="data">
                  <div>
                    {{ data.item.name }}
                  </div>
                  <div>
                    <v-divider></v-divider>
                    Pilih Lainnya
                  </div>
                </template> -->
                <template v-slot:item="{ index, item }">
                  <div v-if="item.id === null" style="display: contents;">
                    <v-list-item-content @click.stop class="py-0" style="width: 100%;" v-if="editing === item || item.name === 'Tulis Lainnya'">
                      <div class="d-flex align-center" style="width: 100%;">
                        <v-text-field
                          v-if="editing === item"
                          v-model="editing.name"
                          autofocus
                          placeholder="Tulis Disini"
                          color="#4ab762"
                          flat
                          class="pt-0"
                          background-color="transparent"
                          hide-details
                          @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <div v-else>
                          {{ item.name }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop v-if="editing !== item">
                          <v-btn
                            icon
                            @click.stop.prevent="edit(index, item); form.category_id.name == item.name ? form.category_id = {} : ''; item.name === 'Tulis Lainnya' ? item.name = '' : item.name;">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action v-if="editing === item">
                          <v-btn
                            icon
                            @click="edit(index, item); item.name === '' ? item.name = 'Tulis Lainnya' : item.name; item.name === '' || item.name === 'Tulis Lainnya' ? form.category_id = {} : form.category_id = item  ">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content class="py-0" style="width: 100%;" v-if="editing !== item && item.name !== 'Tulis Lainnya'">
                      <div class="d-flex align-center" style="width: 100%;">
                        <v-text-field
                          v-if="editing === item"
                          v-model="editing.name"
                          autofocus
                          placeholder="Tulis Disini"
                          color="#4ab762"
                          flat
                          class="pt-0"
                          background-color="transparent"
                          hide-details
                          @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <div v-else>
                          {{ item.name }}
                        </div>
                        <v-spacer></v-spacer>
                         <v-list-item-action @click.stop v-if="editing !== item">
                          <v-btn
                            icon
                            @click.stop.prevent="edit(index, item); form.category_id.name == item.name ? form.category_id = {} : ''; item.name === 'Tulis Lainnya' ? item.name = '' : item.name;">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action v-if="editing === item">
                          <v-btn
                            icon
                            @click="edit(index, item); item.name === '' ? item.name = 'Tulis Lainnya' : item.name; item.name === '' || item.name === 'Tulis Lainnya' ? form.category_id = {} : form.category_id = item  ">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </div>
                    </v-list-item-content>
                  </div>
                  <div v-if="item.id !== null">
                    {{ item.name }}
                  </div>
                </template>
              </v-autocomplete>
            </ValidationProvider>
            <div class="mt_30 mb-2">
              <div class="d-flex">
                <div style="width: 165px;">
                  <v-img
                    :src="form.img_url"
                    :lazy-src="form.img_url"
                    v-if="form.img_url !== ''"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 165 : 140"
                    :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 165 : 140"
                    aspect-ratio="1"
                    style="border-radius: 15px"
                    class="grey lighten-2">
                    <!-- <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template> -->
                    <v-progress-linear
                      color="#4ab762"
                      rounded
                      indeterminate
                      height="6"
                      v-if="process.avatar"
                      class="mx-auto"
                      style="width: 60%; margin-top: 50%;">
                    </v-progress-linear>
                  </v-img>
                  <div v-if="form.img_url === ''">
                    <div style="background-color: #ededed; color: #fff; width: 160px; height: 160px;" class="rounded-lg ml-2" :style="process.avatar ? 'padding-top: 90px' : ''">
                      <v-row
                        class="fill-height ma-0"
                        v-if="!process.avatar"
                        :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h3' : ''"
                        align="center"
                        justify="center"
                      >
                        <v-icon large>mdi-camera</v-icon>
                      </v-row>
                      <v-progress-linear
                        color="#4ab762"
                        rounded
                        indeterminate
                        height="6"
                        v-if="process.avatar"
                        class="mx-auto"
                        style="width: 60%;">
                      </v-progress-linear>
                    </div>
                  </div>
                </div>
                <div class="ml-auto pl-5">
                  <image-input v-model="avatar" style="height: 48px; width: 100%;">
                    <div slot="activator">
                      <v-btn
                        outlined
                        :disabled="process.avatar"
                        block
                        :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 40 : 40"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="#5050505e"
                        class="text-capitalize">
                        Pilih Foto
                      </v-btn>
                    </div>
                  </image-input>


                  <div class="mt-1 text-second text-darken-1" style="font-size: 11px;">
                    Besar file: maksimum 10.000.000 bytes
                    (10 Megabytes). Ekstensi file yang
                    diperbolehkan: .JPG .JPEG .PNG.
                    Dengan dimensi terbaik square (contoh:
                    256px x 256px)
                  </div>
                  <p
                    style="font-size: 9px;"
                    class="mt-0 mb-0 red--text pr-2 mt-1"
                    v-show="error.img_url !== ''">
                    {{ error.img_url }}
                  </p>
                  <p
                    style="font-size: 9px;"
                    class="mt-0 mb-0 red--text pr-2 mt-1"
                    v-show="error.img_url === ''">
                    Wajib
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <div class="d-flex align-center text-second">
              <div>
                Etalase
              </div>
            </div>
            <ValidationProvider name="Etalase" rules="" v-slot="{ errors }">
              <v-autocomplete
                :items="etalase"
                color="#4ab762"
                :messages="'Optional'"
                placeholder="Pilih kategori disini"
                item-text="name"
                item-value="id"
                item-color="green"
                return-object
                v-model="form.group_id"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                </template>
                <template v-slot:no-data>
                  
                </template>
                <!-- <template v-slot:selection="data">
                  {{ data.item }}
                </template> -->
                <!-- <template v-slot:item="data">
                  <div>
                    {{ data.item.name }}
                  </div>
                  <div>
                    <v-divider></v-divider>
                    Pilih Lainnya
                  </div>
                </template> -->
                <template v-slot:item="{ index, item }">
                  <div v-if="item.id === null" style="display: contents;">
                    <v-list-item-content @click.stop class="py-0" style="width: 100%;" v-if="editingEtalase === item || item.name === 'Tulis Lainnya'">
                      <div class="d-flex align-center" style="width: 100%;">
                        <v-text-field
                          v-if="editingEtalase === item"
                          v-model="editingEtalase.name"
                          autofocus
                          placeholder="Tulis Disini"
                          color="#4ab762"
                          flat
                          class="pt-0"
                          background-color="transparent"
                          hide-details
                          @keyup.enter="editEtalase(index, item)"
                        ></v-text-field>
                        <div v-else>
                          {{ item.name }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop v-if="editingEtalase !== item">
                          <v-btn
                            icon
                            @click.stop.prevent="editEtalase(index, item); form.group_id.name == item.name ? form.group_id = {} : ''; item.name === 'Tulis Lainnya' ? item.name = '' : item.name;">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action v-if="editingEtalase === item">
                          <v-btn
                            icon
                            @click="editEtalase(index, item); item.name === '' ? item.name = 'Tulis Lainnya' : item.name; item.name === '' || item.name === 'Tulis Lainnya' ? form.group_id = {} : form.group_id = item  ">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content class="py-0" style="width: 100%;" v-if="editingEtalase !== item && item.name !== 'Tulis Lainnya'">
                      <div class="d-flex align-center" style="width: 100%;">
                        <v-text-field
                          v-if="editingEtalase === item"
                          v-model="editingEtalase.name"
                          autofocus
                          placeholder="Tulis Disini"
                          color="#4ab762"
                          flat
                          class="pt-0"
                          background-color="transparent"
                          hide-details
                          @keyup.enter="editEtalase(index, item)"
                        ></v-text-field>
                        <div v-else>
                          {{ item.name }}
                        </div>
                        <v-spacer></v-spacer>
                         <v-list-item-action @click.stop v-if="editingEtalase !== item">
                          <v-btn
                            icon
                            @click.stop.prevent="editEtalase(index, item); form.group_id.name == item.name ? form.group_id = {} : ''; item.name === 'Tulis Lainnya' ? item.name = '' : item.name;">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action v-if="editingEtalase === item">
                          <v-btn
                            icon
                            @click="editEtalase(index, item); item.name === '' ? item.name = 'Tulis Lainnya' : item.name; item.name === '' || item.name === 'Tulis Lainnya' ? form.group_id = {} : form.group_id = item  ">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </div>
                    </v-list-item-content>
                  </div>
                  <div v-if="item.id !== null">
                    {{ item.name }}
                  </div>
                </template>
              </v-autocomplete>
            </ValidationProvider>
            <div class="d-flex align-center text-second mt_30">
              <div>
                Level
              </div>
            </div>
            <ValidationProvider name="level" rules="" v-slot="{ errors }">
              <v-select
                color="#4ab762"
                class="pt-0"
                :items="level"
                :messages="'Optional'"
                placeholder="Pilih level disini"
                
                v-model="form.level"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                </template>
              </v-select>
            </ValidationProvider>
            <div class="d-flex align-center text-second mt_30">
              <div>
                Organisasi
              </div>
            </div>
            <ValidationProvider name="organisasi" rules="" v-slot="{ errors }">
              <v-autocomplete
                :items="organisasi"
                color="#4ab762"
                :messages="'Optional'"
                placeholder="Pilih organisasi disini"
                item-text="name"
                item-value="id"
                item-color="green"
                v-model="form.organization_id"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <div class="d-flex align-center text-second">
              <div>
                Deskripsi Singkat
              </div>
            </div>
            <ValidationProvider name="Deskripsi Singkat" rules="required|max:500|min:50" v-slot="{ errors }">
              <v-text-field
                color="#4ab762"
                class="pt-0"
                :counter="500"
                :messages="'Wajib'"
                v-model="form.description"
                
                :error-messages="errors"
                require
                placeholder="Tuliskan disini">
                <template v-slot:message="{ message }">
                  <span class="red--text" v-html="message"></span>
                </template>
                <template v-slot:counter="{ props }">
                  <span style="font-size: 9px;" class="text-second">
                    {{ props.value }} dari {{ props.max }} karakter
                  </span>
                </template>
              </v-text-field>
            </ValidationProvider>
            <div class="d-flex align-center text-second mt_30">
              <div>
                Deskripsi Kelas
              </div>
            </div>
            <ValidationProvider name="Deskripsi Kelas" rules="required|max:1000|min:50" v-slot="{ errors }">
              <v-text-field
                color="#4ab762"
                class="pt-0"
                :messages="'Wajib'"
                :counter="1000"
                v-model="form.description_long"
                
                :error-messages="errors"
                require
                placeholder="Tuliskan disini">
                <template v-slot:message="{ message }">
                  <span class="red--text" v-html="message"></span>
                </template>
                <template v-slot:counter="{ props }">
                  <span style="font-size: 9px;" class="text-second">
                    {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                  </span>
                </template>
              </v-text-field>
            </ValidationProvider>
            <div class="d-flex align-center text-second mt_30">
              <div>
                Yang Akan Dipelajari
              </div>
            </div>
            <ValidationProvider name="Yang Akan Dipelajari" rules="required|max:1000" v-slot="{ errors }">
              <v-text-field
                color="#4ab762"
                class="pt-0"
                :messages="'Wajib'"
                :counter="1000"
                v-model="form.subject"
                
                :error-messages="errors"
                require
                placeholder="Tuliskan disini">
                <template v-slot:message="{ message }">
                  <span class="red--text" v-html="message"></span>
                </template>
                <template v-slot:counter="{ props }">
                  <span style="font-size: 9px;" class="text-second">
                    {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                  </span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </section>

    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        avatar: null,
        editing: null,
        editingEtalase: null,
        editingIndex: -1,
        editingEtalaseIndex: -1,
        category: [],
        etalase: [],
        level: [],
        form: {},
        organisasi:[],
        empty_field: false,
        process: {
          run: false,
          avatar: false,
          form: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        content: {
          url: '/account/setting',
          title: 'Pengaturan',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      ImageInput: () => import('@/components/ImageInput')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      detailClass: {
        type: Object,
        default: () => {}
      },
      detailCategory: {
        type: Array,
        default: () => []
      },
      detailEtalase: {
        type: Array,
        default: () => []
      },
      detailLevel: {
        type: Array,
        default: () => []
      },
      detailOrganisasi: {
        type: Array,
        default: () => []
      },
    },
    watch: {
      'detailClass': function(val) {
        this.form = val
      },
      'detailCategory': function(val) {
        this.category = val
      },
      'detailEtalase': function(val) {
        this.etalase = val
      },
      'detailLevel': function(val) {
        this.level = val
      },
      'detailOrganisasi': function(val) {
        this.organisasi = val
      },

      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.img_url = ''
          }
        },
        deep: true
      }
    },
    mounted () {
      this.form = this.$props.detailClass
      this.category = this.$props.detailCategory
      this.etalase = this.$props.detailEtalase
      this.level = this.$props.detailLevel
      this.organisasi = this.$props.detailOrganisasi
    },
    methods: {
      edit (index, item) {
        if (!this.editing) {
          this.editing = item
          this.editingIndex = index
        } else {
          this.editing = null
          this.editingIndex = -1
        }
      },
      editEtalase (index, item) {
        if (!this.editingEtalase) {
          this.editingEtalase = item
          this.editingEtalaseIndex = index
        } else {
          this.editingEtalase = null
          this.editingEtalaseIndex = -1
        }
      },
      validation() {
        var error = this.error,
          valid = true;

        if (this.form.img_url.length < 1) {
          error.img_url = "Gambar tidak boleh kosong";
          valid = false;
        } else {
          error.img_url = "";
        }

        return valid;
      }
    },
  };
</script>