<template>
  <div>
    <DialogAuth :dialogauth="authDialog" @close="authDialog = ''" />
    <v-dialog
      persistent
      scrollable
      v-if="dialog.filter"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      v-model="dialog.filter"
      width="650">
      <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="body-1 text-second text--darken-2 font-weight-bold">
            <div>
              Filter Kelas
            </div>
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.form"
              class="text-capitalize px-0"
              @click="fetch(); dialog.filter = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 235px);">
          <div class="text-first fs-18 mt-4 font-weight-bold">
            Filter Harga
          </div>
          <div class="mt-1 mb-5 mt-2 pb-0">
            <v-range-slider
              v-model="filter.price"
              hide-details="true"
              min="0"
              color="#3498DB"
              class="slider_price"
              :track-color="'grey lighten-2'"
              v-on:change="fetch();"
              max="1000000">
              <!-- <template v-slot:thumb-label>
                <v-icon color="white" small>mdi-currency-usd</v-icon>
              </template> -->
            </v-range-slider>
            <p class="text-third text-left fs-14 mt-2 text-second">
              Harga: 
              <span class="font-weight-bold">
                {{ filter.price[0] | price }}
              </span>
              -
              <span class="font-weight-bold">
                {{ filter.price[1] | price }}
              </span>
            </p>
          </div>
          <div class="text-first fs-18 mt-5 mb-5 font-weight-bold">
            Kategori
          </div>
          <div class="d-flex align-center mt-4" style="flex-wrap: wrap;">
            <div  v-for="(c, n) in category" :key="n">
              <v-btn
                :color="filter.category === c.id ? '#4ab762' : '#ededed'"
                elevation="0"
                :class="filter.category === c.id ? 'white--text' : 'text-second'"
                @click="filter.category = c.id; fetch()"
                class="text-capitalize mr-2 mb-2 fs-14">
                {{c.name }} <span v-if="c.id !== ''">({{ c.count }})</span>
              </v-btn>
              <!-- <div class="fs-14 mb_10 d-flex align-center" :class="filter.category === c.id ? 'color-first font-weight-bold' : 'text-second'" v-if="!show.category ? n <= 4 : true">
                <div>
                  {{c.name }}
                </div>
                <div class="ml-auto" v-if="c.id !== ''">
                  ({{ c.count }})
                </div>
              </div> -->
            </div>
          </div>
          <div class="text-first fs-18 mt-5 mb-5 font-weight-bold">
            Level
          </div>
          <div class="d-flex align-center mt-4" style="flex-wrap: wrap;">
            <div v-for="(e, v) in level" :key="v">
              <v-btn
                :color="filter.level === e ? '#4ab762' : '#ededed'"
                elevation="0"
                :class="filter.level === e ? 'white--text' : 'text-second'"
                @click="filter.level = e; fetch()"
                class="text-capitalize mr-2 mb-2 fs-14">
                {{ e }}
              </v-btn>
              <!-- <div class="fs-14 mb_10 d-flex align-center" :class="filter.level === e ? 'color-first font-weight-bold' : 'text-second'" v-for="(e, v) in level" :key="v" @click="filter.level = e; fetch()">
                <div>
                  {{ e }}
                </div>
                <div class="ml-auto">
                </div>
              </div> -->
            </div>
          </div>
           <div class="text-first fs-18 mt-5 mb-5 font-weight-bold">
            Penyedia
          </div>
          <div class="d-flex align-center mt-4" style="flex-wrap: wrap;">
            <div v-for="(o, n) in organisasi" :key="n">
              <v-btn
                :color="filter.organisasi === o.id ? '#4ab762' : '#ededed'"
                elevation="0"
                :class="filter.organisasi === o.id ? 'white--text' : 'text-second'"
                @click="filter.organisasi = o.id; fetch();"
                class="text-capitalize mr-2 mb-2 fs-14">
                {{ o.name }}
              </v-btn>
              <!-- <div  v-for="(o, n) in organisasi" :key="n" @click="filter.organisasi = o.id; fetch()">
                <div class="fs-14 mb_10 d-flex align-center" :class="filter.organisasi === o.id ? 'color-first font-weight-bold' : 'text-second'" v-if="!show.organisasi ? n <= 4 : true">
                  <div>
                    {{ o.name }}
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>

          <v-btn
            elevation="0"
            color="#4ab762"
            :disabled="process.form"
            width="120"
            @click="fetch(); dialog.filter = false"
            class="text-capitalize white--text mr-2">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -80px;"
      color="#27ae60">
      <div class="body-1 font-weight-bold">{{ success.message }}</div>
    </v-snackbar>

    <v-dialog v-model="dialog.success"
      width="430"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      persistent>
      <v-card 
        height="530"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

          <v-col cols="12" class="pa-0">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  icon
                  @click="dialog.success = false; "
                  elevation="0"
                  class="color-green-second">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; " color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                Tambah Keranjang
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="250"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second title text-center mb-5">
                {{ success.message }}
              </div>
              <div class="text-center d-flex alogn-center justify-center">
                <v-btn
                  width="175"
                  color="#4ab762"
                  elevation="0"
                  to="/cart"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Lihat Keranjang
                </v-btn>
                <v-btn
                  width="100"
                  color="red"
                  outlined
                  elevation="0"
                  @click="dialog.success = false"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Tutup
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.cart"
      v-model="dialog.cart"
      width="400">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="title text-second text--darken-2 font-weight-bold">
            Tambah ke keranjang
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.cart"
              class="text-capitalize px-0"
              @click="dialog.cart = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah anda yakin ingin menambahkan kelas <b>{{ detail.name }}</b> ini ke keranjang?
          </div>
          <v-alert 
            type="error" 
            text
            dense
            class="mb-0"
            prominent
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-row>
            <v-col cols="7">
              <v-btn
                elevation="0"
                color="#4ab762"
                block
                :disabled="process.cart"
                :loading="process.cart"
                @click="addCart()"
                class="text-capitalize white--text px-4">
                <div class="d-flex align-center" style="width: 100%;">
                  <v-icon>mdi-check-circle-outline</v-icon>
                  <div class="ml-auto">
                    Ya, Tambahkan
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                elevation="0"
                color="red"
                outlined
                :disabled="process.cart"
                block
                @click="dialog.cart = false"
                class="text-capitalize white--text">
                Tidak
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#4ab762"
            width="220"
            @click="dialog.cart = false"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                Ya, Hapus
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            @click="dialog.cart = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn>
          <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
        <section class="py-10">
          <v-row>
            <v-col cols="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
              <div class="text-first fs-18 font-weight-bold">
                Filter Harga
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
              <div class="mt-1 mb-5 mt-5 pb-0">
                <v-range-slider
                  v-model="filter.price"
                  hide-details="true"
                  min="0"
                  color="#3498DB"
                  class="slider_price"
                  :track-color="'grey lighten-2'"
                  v-on:change="fetch();"
                  max="1000000">
                  <!-- <template v-slot:thumb-label>
                    <v-icon color="white" small>mdi-currency-usd</v-icon>
                  </template> -->
                </v-range-slider>
                <p class="text-third text-left fs-14 mt-2 text-second">
                  Harga: 
                  <span class="font-weight-bold">
                    {{ filter.price[0] | price }}
                  </span>
                  -
                  <span class="font-weight-bold">
                    {{ filter.price[1] | price }}
                  </span>
                </p>
              </div>
              <v-expansion-panels flat v-model="category_panel">
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first fs-18 font-weight-bold">
                      Kategori
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <!-- <div class="fs-14 mb_10 d-flex align-center" :class="filter.category === c.id ? 'color-first font-weight-bold' : 'text-second'" v-for="(c, k) in category" :key="k" v-show="!show.category ? k <= 5 : ''" @click="filter.category = c.id; fetch()">
                      <div>
                        {{ c.name }}
                      </div>
                      <div class="ml-auto">
                        ({{ c.count }})
                      </div>
                    </div>
                    <div class="text-decoration-underline pt-3" @click="show.category = true" v-if="category.length > 5" style="color: #C0C0C0;">
                      Kategori Lainnya
                    </div> -->
                    <div  v-for="(c, n) in category" :key="n" @click="filter.category = c.id; fetch()">
                      <div class="fs-14 mb_10 d-flex align-center" :class="filter.category === c.id ? 'color-first font-weight-bold' : 'text-second'" v-if="!show.category ? n <= 4 : true">
                        <div>
                          {{c.name }}
                        </div>
                        <div class="ml-auto" v-if="c.id !== ''">
                          ({{ c.count }})
                        </div>
                      </div>
                    </div>
                    <div class="text-decoration-underline pt-0 fs-14" @click="show.category = true" v-if="!show.category && category.length > 5" style="color: #C0C0C0;">
                      Kategori Lainnya
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first fs-18 font-weight-bold">
                      Level
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <div class="fs-14 mb_10 d-flex align-center" :class="filter.level === e ? 'color-first font-weight-bold' : 'text-second'" v-for="(e, v) in level" :key="v" @click="filter.level = e; fetch()">
                      <div>
                        {{ e }}
                      </div>
                      <div class="ml-auto">
                        <!-- ({{ l.count }}) -->
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first fs-18 font-weight-bold">
                      Penyedia
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <div  v-for="(o, n) in organisasi" :key="n" @click="filter.organisasi = o.id; fetch()">
                      <div class="fs-14 mb_10 d-flex align-center" :class="filter.organisasi === o.id ? 'color-first font-weight-bold' : 'text-second'" v-if="!show.organisasi ? n <= 4 : true">
                        <div>
                          {{ o.name }}
                        </div>
                        <div class="ml-auto">
                          <!-- ({{ l.count }}) -->
                        </div>
                      </div>
                    </div>
                    <div class="text-decoration-underline pt-0 fs-14" @click="show.organisasi = true" v-if="!show.organisasi && organisasi.length > 5" style="color: #C0C0C0;">
                      Kategori Lainnya
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first fs-18 font-weight-bold">
                      Kelas Gratis
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <v-list three-line class="py-0">
                      <!-- <v-list-item class="px-0">
                        <v-list-item-avatar tile width="100" height="100">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="require('@/assets/publichome/content_image_6.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#3D4178">
                                </v-progress-circular>
                              </v-row>
                            </template>
                            <div class="badge-overlay-circle">
                              <span class="top-right-circle badge-circle-small">
                                <div
                                  class="font-weight-bold"
                                  style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                                  -50%
                                </div>
                              </span>
                            </div>
                          </v-img>
                        </v-list-item-avatar>
    
                        <v-list-item-content class="align-self-start">
                          <div class="body-1 font-weight-bold">
                            <p class="text-capitalize my-1 three-line" style="height: 72px;">
                              Socket.IO Chat App in React Native for iOS/ Android
                            </p>
                          </div>
                          <v-list-item-subtitle>
                          <div class="text-first caption font-weight-bold py-0">
                            <span class="text-decoration-line-through text-second mr-2">
                              Rp. 150.000
                            </span>
                            <span>
                              Rp 75.000
                            </span>
                          </div> 
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->
                      <v-list-item class="px-0" v-for="(g, s) in list" :key="s" v-show="g.price === 0" :to="`/elearning/${g.id}`">
                        <v-list-item-avatar tile width="100" height="100">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="g.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#3D4178">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>
    
                        <v-list-item-content class="align-self-start">
                          <div class="body-1 font-weight-bold">
                            <p class="text-capitalize my-1 three-line fs-14" style="height: 72px;">
                              {{ g.name }} 
                            </p>
                          </div>
                          <v-list-item-subtitle>
                          <div class="green--text caption font-weight-bold py-0">
                            <span>
                              Gratis
                            </span>
                          </div> 
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item class="px-0">
                        <v-list-item-avatar tile width="100" height="100">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="require('@/assets/publichome/content_image_4.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#3D4178">
                                </v-progress-circular>
                              </v-row>
                            </template>
                            <div class="badge-overlay-circle">
                              <span class="top-right-circle badge-circle-small">
                                <div
                                  class="font-weight-bold"
                                  style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                                  -50%
                                </div>
                              </span>
                            </div>
                          </v-img>
                        </v-list-item-avatar>
    
                        <v-list-item-content class="align-self-start">
                          <div class="body-1 font-weight-bold">
                            <p class="text-capitalize my-1 three-line" style="height: 72px;">
                              ES6 Javascript: The Complete Developer's Guide
                            </p>
                          </div>
                          <v-list-item-subtitle>
                          <div class="text-first caption font-weight-bold py-0">
                            <span class="text-decoration-line-through text-second mr-2">
                              Rp. 150.000
                            </span>
                            <span>
                              Rp 75.000
                            </span>
                          </div> 
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first text-h6 font-weight-bold">
                      More Option Here
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Semua
                      </div>
                      <div class="ml-auto">
                        (55)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Official Partner
                      </div>
                      <div class="ml-auto">
                        (30)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Power Merchant
                      </div>
                      <div class="ml-auto">
                        (15)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Personal (Komunitas)
                      </div>
                      <div class="ml-auto">
                        (15)
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first text-h6 font-weight-bold">
                      More Option Here
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Semua
                      </div>
                      <div class="ml-auto">
                        (55)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Official Partner
                      </div>
                      <div class="ml-auto">
                        (30)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Power Merchant
                      </div>
                      <div class="ml-auto">
                        (15)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Personal (Komunitas)
                      </div>
                      <div class="ml-auto">
                        (15)
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    <div class="text-first text-h6 font-weight-bold">
                      More Option Here
                      <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Semua
                      </div>
                      <div class="ml-auto">
                        (55)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Official Partner
                      </div>
                      <div class="ml-auto">
                        (30)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Power Merchant
                      </div>
                      <div class="ml-auto">
                        (15)
                      </div>
                    </div>
                    <div class="text-second mb-2 d-flex align-center">
                      <div>
                        Personal (Komunitas)
                      </div>
                      <div class="ml-auto">
                        (15)
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
            </v-col>
            <v-col cols="12" md="9">
              <div class="d-flex align-center mb-5">
                <div class="text-second">
                  Menampilkan <span class="font-weight-bold mx-1 fs-14">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> kelas di rentang harga “
                  <span class="fs-14">
                    {{ filter.price[0] | price }}
                  </span>
                  -
                  <span class="fs-14">
                    {{ filter.price[1] | price }}
                  </span>”
                </div>
                <div class="ml-auto d-flex align-center">
                  <div class="text-second mr-2">
                    Urutkan: 
                  </div>
                  <v-select
                    :items="items"
                    solo
                    hide-details
                    v-on:change="fetch()"
                    class="mr-3"
                    color="#4ab762"
                    item-color="green"
                    style="width: 150px;"
                    v-model="filter.sort"
                    placeholder="Urutkan"
                  ></v-select>
                  <v-icon size="30" v-if="format === 'row'" @click="format = 'list'">mdi-format-list-text</v-icon>
                  <v-icon size="30" v-if="format === 'list'" @click="format = 'row'">mdi-view-grid</v-icon>
                </div>
              </div>
              <div v-if="process.run">
                <v-row>
                  <v-col cols="12" md="4" v-for="loader in 9" :key="loader">
                    <v-skeleton-loader
                      class="mx-auto"
                      max-width="300"
                      :loading="process.run"
                      type="card"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </div>
              <div v-if="!process.run">
                <v-row v-if="format === 'row'">
                  <v-col cols="12" md="4" v-for="(l, index) in list" :key="index" style="margin-bottom: 45px;">
                    <v-hover v-slot="{ hover }">
                      <v-card flat>
                        <v-img :src="l.img_url" style="border-radius: 15px;" height="320">
                          <div class="badge-overlay-circle" v-if="l.discount_bool">
                            <span class="top-right-circle badge-circle">
                              <div
                                class="font-weight-bold "
                                style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                                <span v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</span> 
                                <span v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</span>
                                <span v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</span>
                              </div>
                            </span>
                          </div>
                        </v-img> 
                        <div class="text-first fs-18 font-weight-bold mt-4 two-line" style="height: 48px;">
                          {{ l.name }}
                        </div> 
                        <v-list-item class="px-0">
                          <v-list-item-avatar size="25" color="#ebebeb">
                            <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                            <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                          </v-list-item-avatar>
              
                          <v-list-item-content>
                            <v-list-item-title class="text-second fs-14">{{ l.organization_name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <div class="d-flex align-center body-2 text-second">
                          <div class="text-first font-weight-bold">
                            {{ l.review_average }}
                          </div>
                          <div class="mx-2">
                            <v-rating
                              :value="l.review_average*1"
                              background-color="grey"
                              color="#e67e22"
                              dense
                              readonly
                              half-increments
                              hover
                              size="20">
                            </v-rating>
                          </div>
                          <div class="">
                            ({{ l.review_count }})
                          </div>
                        </div>
                        <div class="text-first fs-16 font-weight-bold pt-2">
                          <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                            {{ l.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span class="text-first" v-if="parseInt(l.price) > 0">
                              <span v-if="!l.discount_bool">
                                {{ l.price | price }}
                              </span>
                              <span v-if="l.discount_bool">
                                {{ l.price_discount | price }}
                              </span>
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div> 
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                            style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                            <v-img :src="l.img_url" style="height:200px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                            <div class="px-3">
                              <div class="white--text fs-18 font-weight-bold mb-2 two-line mt-3" style="height: 48px; line-height: 1.5;">
                                {{ l.name }}
                              </div> 
                              <div class="body-2 mb-2 white--text mt-4 three-line" style="height: 60px;">
                                {{ l.description }}
                              </div>
                              <div class="white--text text--darken-3 fs-16 font-weight-bold py-1">
                                <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                                  {{ l.price | price }}
                                </span>
                                <span class="font-weight-bold">
                                  <span class="white--text" v-if="parseInt(l.price) > 0">
                                    <span v-if="!l.discount_bool">
                                      {{ l.price | price }}
                                    </span>
                                    <span v-if="l.discount_bool">
                                      {{ l.price_discount | price }}
                                    </span>
                                  </span>

                                  <span class="white--text" v-else>
                                    Gratis
                                  </span>
                                </span>
                              </div> 
                              <div class="d-flex align-center">
                                <div>
                                  <v-btn
                                    color="white"
                                    width="159"
                                    height="40"
                                    elevation="0"
                                    :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                                    class="text-capitalize">
                                    Lihat Kelas
                                  </v-btn>
                                </div>
                                <div class="ml-auto">
                                  <v-icon color="white" size="25" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" class="ml-3" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                                  <span v-if="token">
                                    <v-icon size="25" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                                    <v-icon size="25" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                                  </span>
                                  <span v-if="!token">
                                    <v-icon size="25" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-expand-transition>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
                <div v-if="format === 'list'">
                  <div v-for="(l, index) in list" :key="index">
                    <v-hover v-slot="{ hover }">
                      <v-card flat class="mb-4">
                        <v-row>
                          <v-col cols="5">
                            <v-img :src="l.img_url" style="height: 230px; border-radius: 15px;" >
                              <div class="badge-overlay-circle" v-if="l.discount_bool">
                                <span class="top-right-circle-big badge-circle-big">
                                  <div
                                    class="font-weight-bold"
                                    style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 85px; padding-right: 35px;">
                                    <span v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</span> 
                                    <span v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</span>
                                    <span v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</span>
                                  </div>
                                </span>
                              </div>
                            </v-img> 
                          </v-col>
                          <v-col cols="7">
                            <div class="text-first text-h6 font-weight-bold mb-2 one-line" style="height: 32px;">
                              {{ l.name }}
                            </div> 
                            <div class="body-1 mb-2 text-second">
                              {{ l.description }}
                            </div>
                            <div class="text-first body-2">
                              <b>{{ l.content_count }}</b> Materi
                            </div>
                            <v-list-item class="px-0">
                              <v-list-item-avatar size="25">
                                <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                                <v-icon v-if="l.organization_img_url === ''">mdi-office-building</v-icon>
                              </v-list-item-avatar>
                  
                              <v-list-item-content>
                                <v-list-item-title class="text-second">{{ l.organization_name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <div class="d-flex align-center body-2 text-second">
                              <div class="text-first font-weight-bold">
                                {{ l.review_average }}
                              </div>
                              <div class="mx-2">
                                <v-rating
                                  :value="l.review_average*1"
                                  background-color="grey"
                                  color="#e67e22"
                                  dense
                                  readonly
                                  half-increments
                                  hover
                                  size="20">
                                </v-rating>
                              </div>
                              <div class="">
                                ({{ l.review_count }})
                              </div>
                            </div>
                            <div class="text-first text-h6 font-weight-bold py-2">
                              <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                                {{ l.price | price }}
                              </span>
                              <span class="font-weight-bold red--text">
                                <span class="text-first" v-if="parseInt(l.price) > 0">
                                  <span v-if="!l.discount_bool">
                                    {{ l.price | price }}
                                  </span>
                                  <span v-if="l.discount_bool">
                                    {{ l.price_discount | price }}
                                  </span>
                                </span>

                                <span class="green--text" v-else>
                                  Gratis
                                </span>
                              </span>
                            </div> 
                          </v-col>
                        </v-row>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                            style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                            <v-row>
                              <v-col cols="5">
                                <v-img :src="l.img_url" style="height: 253px; border-radius: 15px;" ></v-img> 
                              </v-col>
                              <v-col cols="7" class="py-5 pr-5" style="display: grid;">
                                <div>
                                  <div class="ehite--text text-h6 font-weight-bold mb-2 one-line" style="height: 32px;">
                                    {{ l.name }}
                                  </div> 
                                  <div class="body-1 mb-2 white--text mt-3">
                                    {{ l.description }}
                                  </div>
                                </div>
                                <div class="white--text text-h6 font-weight-bold py-2 d-flex align-end pr-3">
                                  <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                                    {{ l.price | price }}
                                  </span>
                                  <span class="font-weight-bold">
                                    <span class="white--text" v-if="parseInt(l.price) > 0">
                                      <span v-if="!l.discount_bool">
                                        {{ l.price | price }}
                                      </span>
                                      <span v-if="l.discount_bool">
                                        {{ l.price_discount | price }}
                                      </span>
                                    </span>

                                    <span class="white--text" v-else>
                                      Gratis
                                    </span>
                                  </span>
                                  <div class="ml-auto">
                                    <v-btn
                                      color="white"
                                      width="150"
                                      elevation="0"
                                      :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                                      class="text-capitalize">
                                      Lihat Kelas
                                    </v-btn>
                                    <!-- <v-icon color="white" class="mx-2">mdi-cart-plus</v-icon>
                                    <v-icon color="white">mdi-heart-outline</v-icon> -->
                                    <v-icon color="white" class="ml-2" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                                    <span v-if="token">
                                      <v-icon class="ml-2" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                                      <v-icon class="ml-2" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                                    </span>
                                    <span v-if="!token">
                                      <v-icon class="ml-2" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                                    </span>

                                  </div>
                                </div> 
                              </v-col>
                            </v-row>
                          </div>
                        </v-expand-transition>
                      </v-card>
                    </v-hover>
                  </div>
                </div>
              </div>
              <div class="text-right mt-16" v-if="!process.run && list.length < 1">
                <Empty v-model="contentEmpty"/>
              </div>
              <Pagination 
                @changePage="fetch($event)" 
                :model="pagination"
                class="pt-6"
                v-show="pagination.total_page > 1"/>
            </v-col>
          </v-row>

        </section>
      </div>

      <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
        <div class="d-flex align-center mb-3">
          <div class="text-second mr-2" style="width: 70%;">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari kelas ...`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto">
            <v-icon size="25" class="mr-1" @click="dialog.filter = true;">mdi-filter-variant</v-icon>
            <v-icon size="25" class="mr-1" @click="filter.sort = 'Paling baru'; fetch()" v-if="filter.sort === 'Paling lama'">mdi-sort-bool-ascending-variant</v-icon>
            <v-icon size="25" class="mr-1" @click="filter.sort = 'Paling lama'; fetch()" v-if="filter.sort === 'Paling baru'">mdi-sort-bool-descending-variant</v-icon>
            <v-icon size="25" v-if="format === 'row'" @click="format = 'list'">mdi-format-list-text</v-icon>
            <v-icon size="25" v-if="format === 'list'" @click="format = 'row'">mdi-view-grid</v-icon>
          </div>
        </div>
        <div class="text-second caption mb-4">
          Menampilkan <span class="font-weight-bold mx-1 fs-14">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> kelas di rentang harga “
          <span class="fs-14">
            {{ filter.price[0] | price }}
          </span>
          -
          <span class="fs-14">
            {{ filter.price[1] | price }}
          </span>”
        </div>

        <div v-if="process.run">
          <v-row>
            <v-col cols="6" md="6" v-for="loader in 10" :key="loader">
              <v-skeleton-loader
                  class="mx-auto"
                  max-width="300"
                  :loading="process.run"
                  type="card"
                ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-row v-if="format === 'row'" class="mb-3">
            <v-col cols="6" v-for="(l, index) in list" :key="index">
              <v-hover v-slot="{ hover }">
                <v-card flat style="background: #f5f5f5b5;" class="pa-0" height="345">
                  <v-img :src="l.img_url" style="height: 160px; border-radius: 15px;" >
                    <div class="badge-overlay-circle" v-if="l.discount_bool">
                      <span class="top-right-circle-small badge-circle-small">
                        <div
                          class="font-weight-bold"
                          style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                          <div v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</div> 
                          <div v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</div>
                          <div v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</div>
                        </div>
                      </span>
                    </div>
                  </v-img> 
                  <div class="text-first body-1 font-weight-bold mt-4 two-line px-2" style="height: 50px;">
                    {{ l.name }}
                  </div> 
                  <v-list-item class="px-2" style="min-height: 25px;">
                    <v-list-item-avatar size="25" color="#ebebeb">
                      <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                      <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                    </v-list-item-avatar>
        
                    <v-list-item-content class="py-1">
                      <v-list-item-title class="text-second caption">{{ l.organization_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="d-flex align-center body-2 px-2 text-second">
                    <div class="mr-2">
                      <v-rating
                        :value="l.review_average*1"
                        background-color="grey"
                        color="#e67e22"
                        dense
                        half-increments
                        hover
                        size="15">
                      </v-rating>
                    </div>
                    <div class="caption">
                      ({{ l.review_average }})
                    </div>
                  </div>
                  <div class="text-first fs-14 px-2 font-weight-bold py-2">
                    <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                      {{ l.price | price }}
                    </span>
                    <span class="font-weight-bold red--text">
                      <span class="text-first" v-if="parseInt(l.price) > 0">
                        <span v-if="!l.discount_bool">
                          {{ l.price | price }}
                        </span>
                        <span v-if="l.discount_bool">
                          {{ l.price_discount | price }}
                        </span>
                      </span>

                      <span class="green--text" v-else>
                        Gratis
                      </span>
                    </span>
                  </div> 
                  <!-- <div class="text-first body-1 font-weight-bold py-1" v-if="l.is_diskon">
                    <div class="text-decoration-line-through text-second caption mr-2">
                      Rp. 150.000
                    </div>
                    <div>
                      Rp 75.000
                    </div>
                  </div>  -->
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="transition-fast-in-fast-out v-card--reveal white--text align-start"
                      style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                      <v-img :src="l.img_url" style="height:130px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                      <div class="px-3">
                        <div class="white--text body-1 font-weight-bold mb-2 two-line mt-3" style="height: 50px;">
                          {{ l.name }}
                        </div> 
                        <div class="body-1 mb-2 caption white--text two-line">
                          {{ l.description }}
                        </div>
                        <div class="white--text text--darken-3 fs-14 font-weight-bold py-2">
                          <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                            {{ l.price | price }}
                          </span>
                          <span class="font-weight-bold">
                            <span class="white--text" v-if="parseInt(l.price) > 0">
                              <span v-if="!l.discount_bool">
                                {{ l.price | price }}
                              </span>
                              <span v-if="l.discount_bool">
                                {{ l.price_discount | price }}
                              </span>
                            </span>

                            <span class="white--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div> 
                        <!-- <div class="white--text text--darken-3 body-1 font-weight-bold py-1" v-if="l.is_diskon">
                          <div class="text-decoration-line-through white--text caption mr-2">
                            Rp. 150.000
                          </div>
                          <div>
                            Rp 75.000
                          </div>
                        </div>  -->
                        <div class="d-flex align-center mt-2">
                          <div>
                            <v-btn
                              color="white"
                              width="90"
                              elevation="0"
                              small
                              :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                              class="text-capitalize">
                              Lihat Kelas
                            </v-btn>
                          </div>
                          <div class="ml-auto">
                            <v-icon color="white" size="18" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" class="ml-3" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                            <span v-if="token">
                              <v-icon size="18" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                              <v-icon size="18" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                            </span>
                            <span v-if="!token">
                              <v-icon size="18" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                            </span>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <div v-if="format === 'list'">
            <v-hover v-slot="{ hover }" v-for="(l, index) in list" :key="index">
              <v-card flat class="mb-4">
                <v-row>
                  <v-col cols="5">
                    <v-img :src="l.img_url" style="height: 160px; border-radius: 15px;" >
                      <div class="badge-overlay-circle"  v-if="l.discount_bool">
                        <span class="top-right-circle-small badge-circle-small">
                          <div
                            class="font-weight-bold"
                            style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                            <div v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</div> 
                            <div v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</div>
                            <div v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</div>
                          </div>
                        </span>
                      </div>
                    </v-img> 
                  </v-col>
                  <v-col cols="7" class="pl-0">
                    <div class="text-first body-1 font-weight-bold mb-2 one-line" style="height: 26px;">
                      {{ l.name }}
                    </div> 
                    <div class="caption mb-0 text-second two-line">
                      {{ l.description }}
                    </div>
                    <!-- <div class="text-first caption">
                      <b>50</b> Materi
                    </div> -->
                    <v-list-item class="px-0" style="min-height: 25px;">
                      <v-list-item-avatar size="25" color="#ebebeb">
                        <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                        <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                      </v-list-item-avatar>
          
                      <v-list-item-content class="py-1">
                        <v-list-item-title class="text-second caption">{{ l.organization_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <div class="d-flex align-center caption text-second">
                      <div class="mr-2">
                        <v-rating
                          :value="l.review_average*1"
                          background-color="grey"
                          color="#e67e22"
                          dense
                          half-increments
                          hover
                          size="15">
                        </v-rating>
                      </div>
                      <div class="caption">
                        ({{ l.review_average }})
                      </div>
                    </div>
                    <div class="text-first fs-14 font-weight-bold py-2">
                      <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                        {{ l.price | price }}
                      </span>
                      <span class="font-weight-bold red--text">
                        <span class="text-first" v-if="parseInt(l.price) > 0">
                          <span v-if="!l.discount_bool">
                            {{ l.price | price }}
                          </span>
                          <span v-if="l.discount_bool">
                            {{ l.price_discount | price }}
                          </span>
                        </span>

                        <span class="green--text" v-else>
                          Gratis
                        </span>
                      </span>
                    </div> 
                    <!-- <div class="text-first body-1 font-weight-bold py-1" v-if="l.is_diskon">
                      <div class="text-decoration-line-through text-second caption mr-2">
                        Rp. 150.000
                      </div>
                      <div>
                        Rp 75.000
                      </div>
                    </div>  -->
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                    style="height: 195px; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                    <v-row>
                      <v-col cols="5">
                        <v-img :src="l.img_url" style="height: 194px; border-radius: 15px;" > </v-img> 
                      </v-col>
                      <v-col cols="7" class="py-5 pr-5 pl-0" style="display: grid;">
                        <div>
                          <div class="ehite--text body-1 font-weight-bold mb-2 one-line" style="height: 28px;">
                            {{ l.name }}
                          </div> 
                          <div class="caption mb-2 white--text mt-1 two-line">
                            {{ l.description }}
                          </div>
                        </div>
                        <div class="white--text body-1 font-weight-bold py-2 mt-n2">
                          <div class="white--text text--darken-3 fs-14 font-weight-bold py-1">
                            <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                              {{ l.price | price }}
                            </span>
                            <span class="font-weight-bold">
                              <span class="white--text" v-if="parseInt(l.price) > 0">
                                <span v-if="!l.discount_bool">
                                  {{ l.price | price }}
                                </span>
                                <span v-if="l.discount_bool">
                                  {{ l.price_discount | price }}
                                </span>
                              </span>

                              <span class="white--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </div> 
                          <!-- <div class="white--text text--darken-3 body-1 font-weight-bold py-1" v-if="l.is_diskon">
                            <div class="text-decoration-line-through white--text caption mr-2">
                              Rp. 150.000
                            </div>
                            <div>
                              Rp 75.000
                            </div>
                          </div>  -->
                          <div :class="'mt-2'">
                            <v-btn
                              color="white"
                              width="90"
                              elevation="0"
                              :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                              small
                              class="text-capitalize">
                              Lihat Kelas
                            </v-btn>
                            <!-- <v-icon color="white" small class="mx-1">mdi-cart-plus</v-icon>
                            <v-icon color="white" small>mdi-heart-outline</v-icon> -->
                            <v-icon color="white" class="ml-1" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>
                            <span v-if="token">
                              <v-icon color="white" class="ml-1" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                              <v-icon :color="'pink'" class="ml-1" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                            </span>
                            <span v-if="!token">
                              <v-icon color="white" class="ml-1" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                            </span>
                          </div>
                        </div> 
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-hover>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>


      </div>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          sort: 'Paling baru',
          price: [0, 1000000],
          category: '',
          level: 'Semua Level',
          organisasi: ''
        },
        authDialog: '',
        dialog: {
          filter: false,
          auth: '',
          success: false,
          cart: false
        },
        detail: {},
        items: ['Paling baru', 'Paling lama'],
        format: 'row', 
        show: {
          category: false,
          organisasi: false
        },
        category_panel: 0,
        snackbar: false,
        timeout: 2000,
        contentEmpty: "",
        level: [],
        category: [],
        organisasi: [],
        page: 1,
        list: [],
        pagination: {},
        process: {
          run: true,
          cart: false
        },
        success: {
          message: ""
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/elearning',
          title: 'Kelas',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      DialogAuth: () => import('@/components/dialog/auth'),
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.category !== undefined) {
        this.filter.category = this.$route.query.category
      } else {
        this.filter.category = ''
      }
      if (this.$route.query.search !== undefined) {
        this.filter.search = this.$route.query.search
      } else {
        this.filter.search = ''
      }
      this.fetch()
    },
    watch: {
      // call again the method if the route changes
      '$route': function(val) {
        if (this.$route.query.category !== undefined) {
          this.filter.category = this.$route.query.category
        } else {
          this.filter.category = ''
        }
        if (this.$route.query.search !== undefined) {
          this.filter.search = this.$route.query.search
        } else {
          this.filter.search = ''
        }
        this.fetch()
      },
    },
    mounted () {
      this.fetchCategory()
      // this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 12,
          page: p === undefined ? 1 : p,
          search: this.filter.search,
          category_id: this.filter.category,
          level: this.filter.level === 'Semua Level' ? '' : this.filter.level,
          organization_id: this.filter.organisasi,
          sort: this.filter.sort === 'Paling baru' ? '-created_datetime' : 'created_datetime',
          'price[gte]': this.filter.price[0],
          'price[lte]': this.filter.price[1],
          'publisher_user_id[neq]': parseInt(this.user.id)
        }
        this.$axios.$get(`api/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            if (this.filter.search !== '' || this.filter.search !== null || this.filter.category !== '' || this.filter.level !== 'Semua Level' || this.filter.organisasi !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      fetchCategory () {
        this.process.run = true
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/course_category`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.category = []
            this.category.push(
              {
                id: "",
                name: 'Semua Kategori',
                count: ''
              }
            ) 
            for (let i in response.results.data) {
              this.category.push(
                {
                  id: response.results.data[i].id,
                  count: response.results.data[i].count,
                  name: response.results.data[i].name
                }
              ) 
              
            }
            this.fetchLevel()
          }else{
            this.process.run = false
          }
        })
      },
      fetchLevel () {
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/course_level`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.fetchOraganisasi()
            this.level = []
            this.level.push('Semua Level') 
            for (let i in response.results.data) {
              this.level.push(response.results.data[i]) 
              
            }
          }else{
            this.process.run = false
          }
        })
      },
      fetchOraganisasi () {
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/organization`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.organisasi = []
            this.organisasi.push(
              {
                id: "",
                name: 'Semua penyedia',
              }
            ) 
            for (let i in response.results.data) {
              this.organisasi.push(
                {
                  id: response.results.data[i].id,
                  name: response.results.data[i].name
                }
              ) 
              
            }
          }else{
            this.process.run = false
          }
        })
      },
      changeNameRouter() {
        this.$router.replace({ path: '/elearning' })
      },
      async addWishlist(item, index) {
        await this.$axios.$post(`api/wishlist`, 
        {
          course_id: item.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.list[index].course_wish_id = response.results.data.id
            this.success.message = "Berhasil ditambahkan ke wishlist"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async removeWishlist(item, index) {
        await this.$axios.$post(`api/wishlist/delete`, 
        {
          id: item.course_wish_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.list[index].course_wish_id = ""
            this.success.message = "Berhasil dihapus dari wishlist"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async addCart () {
        this.process.cart = true
        await this.$axios.$post(`api/cart`, 
        {
          course_id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.dialog.cart = false
            this.success.message = "Berhasil menambahkab kelas ini ke keranjang"
            this.dialog.success = true
            this.process.cart = false
            this.$store.commit('header/setCart', this.headers.cart + 1)
          } else {
            this.process.cart = false
            this.error.message = response.message
          }
        })
      }
    }
  }
</script>


<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
