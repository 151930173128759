<template>
  <v-app>
    <div v-if="$route.path.split('/')[1] !== 'collection' && $route.path.split('/')[1] !== 'preview' && $route.path !== '/login' && $route.path !== '/reset-password'">
      <Headers/>
    </div>
    <v-sheet light 
      class="mx-auto"
      :class="
      $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? $route.path === '/login' || $route.path === '/reset-password' ? 'pt-0' : 
      'lebar_layar pt-16 mt-2' : 
      $route.path.split('/')[1] === 'collection' || $route.path.split('/')[1] === 'preview' || $route.path === '/login' || $route.path === '/reset-password' ? 'my-0' : 
      'lebar_layar my-16'" 
      :style="
      $route.path.split('/')[1] === 'collection' || $route.path.split('/')[1] === 'preview' || $route.path === '/login' || $route.path === '/reset-password' ? 'width: 100%;' : 
      $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? $route.path === '/cart' ? '' : 
      'padding-bottom: 56px;' : 
      'padding-top: 100px'">
      <nuxt/>
    </v-sheet>
    <Footers v-if="$route.path === '/elearning' || $route.path === '/home' || $route.path === '/organitation' || $route.path === '/account/setting' || $route.path === '/login'"/>
  </v-app>
</template>

<script>
  import Headers from "~/components/Header.vue"
  import Footers from "~/components/Footer.vue"
  const Cookie = process.client ? require('js-cookie') : undefined
  import firebase from "firebase/app"
  import "firebase/messaging"
  export default {
    data () {
      return {
        fab: false,
      }
    },
    
    components: {
      Headers,
      Footers
    },
    created() {
      if (process.browser) {
        // this.setStore()

        if ('Notification' in window && navigator.serviceWorker) {
          this.checkTokenFirebase()
        }
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    mounted () {
      this.load_snap()
    },
    methods: {
      load_snap(){
          //change this to the script source you want to load, for example this is snap.js sandbox env
        const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js'; 
        //change this according to your client-key
        const myMidtransClientKey = 'SB-Mid-client-j8Vpfxl457O6mCxO'; 
      
        let scriptTag = document.createElement('script');
        scriptTag.src = midtransScriptUrl;
        // optional if you want to set script attribute
        // for example snap.js have data-client-key attribute
        scriptTag.setAttribute('data-client-key', myMidtransClientKey);
      
        document.body.appendChild(scriptTag);
        return () => {
          document.body.removeChild(scriptTag);
        }
      },
      checkTokenFirebase () {
        if (firebase.messaging.isSupported()) {
          if (!this.token) {
            navigator.permissions.query({ name: 'notifications' })
            .then(function(result) {
              if (result.state === 'granted') {
                firebase.messaging().getToken()
                .then(oldToken => {
                  if (oldToken) {
                    firebase.messaging().deleteToken(oldToken)
                  }
                })
              }
            })
          } else {
            navigator.permissions.query({ name: 'notifications' })
            .then(function(result) {
              if (result.state !== 'granted') {
                firebase.messaging().requestPermission()
                .then(function() {
                  window.location.reload()
                })
                .catch(function(err) {
                  console.log('Unable to get permission to notify', err)
                })
              }
            })

            // console.log(firebase.messaging().getToken({vapidKey: 'BKWfHMCqp3DSHM6XSD2DvW7m8emOAbmINVELnpksMoyQ0RbG0QrTKOz22FQrBoX7GW9mjwsdzaINqI_4y6N9dYQ'}))

            firebase.messaging().getToken({vapidKey: 'BKWfHMCqp3DSHM6XSD2DvW7m8emOAbmINVELnpksMoyQ0RbG0QrTKOz22FQrBoX7GW9mjwsdzaINqI_4y6N9dYQ'})
            .then(currentToken => {
              this.updateTokenFirebase(currentToken)
            })
            .catch(function(err) {
              console.log('Unable to retrieve token', err)
            })
          }
        }
      },
      async updateTokenFirebase(currentToken) {
        if (currentToken !== this.fcm)
        await this.$axios.$post(`api/fcm/update`, {
          fcm_token: currentToken
        })
        .then((response) => {
          this.$store.commit('member/auth/setFcm', currentToken)
           Cookie.set('fcm', currentToken)
        })
      }
    },
    getCategory(data){
      console.log('ini isi dat',data)
    },
    // created() {
    //   if (process.browser) {
    //     if ('Notification' in window && navigator.serviceWorker) {
    //       this.checkTokenFirebase()
    //     }
    //   }
    // },
    // onScroll (e) {
    //   if (typeof window === 'undefined') return
    //   const top = window.pageYOffset || e.target.scrollTop || 0
    //   this.fab = top > window.innerHeight-100
    // }
  }
</script>
