<template>
  <div>
    <Loading :detailMessage="loading_message" v-if="process.form || process.delete"/>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -20px;"
      :color="color">
      <div class="body-1 font-weight-bold">{{ color === 'warning' ? warning.message : success.message }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.form"
      v-model="dialog.form"
      width="500">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            <span v-if="form.id !== ''">Ubah</span>
            <span v-if="form.id === ''">Buat</span>
             Catatan <span v-if="form.time !== ''">({{ form.time }})</span>
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.form = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-card-text class="pb-5 pt-0" style="min-height: 200px;">
          <div class="mt-0">
            <ValidationObserver ref="observer">
             <div class="body-1 text-first">
                Catatan
              </div>
              <ValidationProvider name="Catatan" rules="required|max:1000" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="mt-0 pt-3"
                  :counter="1000"
                  v-on:keyup.enter="save()"
                  :messages="'Wajib'"
                  v-model="form.text"
                  placeholder="Tuliskan disini"
                  :error-messages="errors"
                  require>
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </ValidationObserver>
            <v-alert 
              type="error" 
              text
              dense
              class="mb-0 mr-2 px-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#4ab762"
            width="190"
            @click="save()"
            :loading="process.form"
            :disabled="process.form"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                <span v-if="form.id !== ''">Simpan</span>
                <span v-if="form.id === ''">Tambahkan</span>
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            :disabled="process.form"
            @click="dialog.form = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.materi"
      v-model="dialog.materi"
      width="600">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-7 pb-3 pt-5'">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Catatan 
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              small
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.materi = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pt-2 pb-7'">
          <div class="text-second" style="width: 60%">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetchDetail()"
              @click:clear="filter.search = ''; fetchDetail();"
              :placeholder="`Cari catatan…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
            <v-select
              :items="[
                'Urutkan Terbaru',
                'Urutkan Terlama'
              ]"
              solo
              v-on:change="fetchDetail()"
              color="#4ab762"
              hide-details
              placeholder="Urutkan disini"
              item-color="green"
              return-object
              v-model="filter.sort">
            </v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-4" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pb-12'" style="min-height:600px;">
          <div v-if="process.run">
            <v-skeleton-loader
              class="ma-auto"
              v-for="r in 4"
              :key="r"
              :loading="process.run"
              type="list-item-three-line">
            </v-skeleton-loader>
          </div>
          <div v-if="!process.run">
            <v-row v-for="(item, index) in list_materi" :key="index">
              <v-col cols="2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'pr-0'">
                <div class="color-green-second pa-2 text-center font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'caption' : 'fs-14'" style="background-color: #4ab76230;">
                  {{ item.time === '' ? '-' : item.time }}
                </div>
              </v-col>
              <v-col cols="10" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'pl-4'">
                <div class="d-flex">
                  <div style="font-weight: 900; font-size: 14px; line-height: 16px; olor: #505050; width: 68%;">
                    {{ item.content_title }}
                    <div style="font-weight: normal; font-size: 12px; line-height: 14px; color: #42A558; margin-top: 4px; margin-bottom: 6px;">
                      {{ item.content_parent_title }}
                    </div>
                    <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.5;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                      {{ item.update_datetime | datetimemonth }}
                    </div>
                  </div>
                  <div class="ml-auto text-right justify-end" style="width: 32%;">
                    <v-btn
                      :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                      :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                      text
                      @click="reset(item); dialog.form = true;"
                      style="font-weight: 400;"
                      class="text-capitalize primary--text pl-0 fs-12">
                      Ubah
                    </v-btn>
                    <v-btn
                      :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                      :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                      text
                      @click="reset(item); deletes()"
                      style="font-weight: 400;"
                      class="text-capitalize red--text pr-0 fs-12">
                      Hapus
                    </v-btn>
                  </div>
                </div>
                <div style="font-size: 12px; line-height: 18px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                  {{ item.text }}
                </div>
              </v-col>
              <v-col cols="12" v-if="index !== (list_materi.length - 1)">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list_materi.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
          <div class="px-0 mb-2 mt-8 text-center color-blue-first" v-if="!process.run && pagination_materi.current < pagination_materi.total_page">
            <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline;" @click="loader = true; page = page+1; fetchDetail(page)">
              <div v-if="loader" class="my-10 text-center">
                <v-skeleton-loader
                  class="ma-auto"
                  v-for="r in 4"
                  :key="r"
                  :loading="process.run"
                  type="list-item-three-line">
                </v-skeleton-loader>
              </div>
              <div v-if="!loader" class="text-center">
                Tampilkan lebih banyak
              </div>
            </span>
          </div>
        </v-card-text>
        <v-fab-transition v-if="!process.run">
          <v-btn
            elevation="3"
            fab
            fixed
            bottom
            right
            style="position: absolute;"
            class="white--text"
            @click="error.message = ''; dialog.form = true"
            color="#3498db">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.detail"
      v-model="dialog.detail"
      width="600">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-7 pb-3 pt-5'">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Catatan Saya ({{ pagination.total_data }})
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              small
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.detail = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pt-2 pb-7'">
          <div class="text-second" style="width: 40%">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari catatan…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 30%">
            <v-select
              :items="[
                'Urutkan Terbaru',
                'Urutkan Terlama'
              ]"
              solo
              v-on:change="fetch()"
              color="#4ab762"
              hide-details
              placeholder="Urutkan disini"
              item-color="green"
              return-object
              v-model="filter.sort">
            </v-select>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 30%">
            <v-select
              :items="materi_filter"
              solo
              v-on:change="fetch()"
              color="#4ab762"
              item-value="content_id"
              item-text="title"
              item-color="green"
              hide-details
              v-model="filter.materi"
              :placeholder="'Materi'"
            ></v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-4" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pb-12'" style="min-height:600px;">
          <div v-if="process.run">
            <v-skeleton-loader
              class="ma-auto"
              v-for="r in 4"
              :key="r"
              :loading="process.run"
              type="list-item-three-line">
            </v-skeleton-loader>
          </div>
          <div v-if="!process.run">
            <v-row v-for="(item, index) in list" :key="index">
              <v-col cols="2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'pr-0'">
                <div class="color-green-second pa-2 text-center font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'caption' : 'fs-14'" style="background-color: #4ab76230;">
                  {{ item.time === '' ? '-' : item.time }}
                </div>
              </v-col>
              <v-col cols="10" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'pl-4'">
                <div class="d-flex">
                  <div style="font-weight: 900; font-size: 14px; line-height: 16px; olor: #505050; width: 68%;">
                    {{ item.content_title }}
                    <div style="font-weight: normal; font-size: 12px; line-height: 14px; color: #42A558; margin-top: 4px; margin-bottom: 4px;">
                      {{ item.content_parent_title }}
                    </div>
                    <div class="fs-12" style="font-weight: normal; color: #505050; mix-blend-mode: normal; opacity: 0.5;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                      {{ item.update_datetime | datetimemonth }}
                    </div>
                  </div>
                  <div class="ml-auto text-right justify-end" style="width: 32%;">
                    <v-btn
                      :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                      :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                      text
                      @click="reset(item); dialog.form = true;"
                      style="font-weight: 400;"
                      class="text-capitalize primary--text pl-0 fs-12">
                      Ubah
                    </v-btn>
                    <v-btn
                      :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                      :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                      text
                      @click="reset(item); deletes()"
                      style="font-weight: 400;"
                      class="text-capitalize red--text pr-0 fs-12">
                      Hapus
                    </v-btn>
                  </div>
                </div>
                <div style="font-size: 12px; line-height: 18px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                  {{ item.text }}
                </div>
              </v-col>
              <v-col cols="12" v-if="index !== (list.length - 1)">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
          <div class="px-0 mb-2 mt-8 text-center color-blue-first" v-if="!process.run && pagination.current < pagination.total_page">
            <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline;" @click="loader = true; page = page+1; fetch(page)">
              <div v-if="loader" class="my-10 text-center">
                <v-skeleton-loader
                  class="ma-auto"
                  v-for="r in 4"
                  :key="r"
                  :loading="process.run"
                  type="list-item-three-line">
                </v-skeleton-loader>
              </div>
              <div v-if="!loader" class="text-center">
                Tampilkan lebih banyak
              </div>
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    data () {
      return {
        dialog: {
          detail: false,
          form: false,
          materi: false
        },
        form:{
          id: "",
          text: "",
          time: ""
        },
        filter: {
          search: "",
          materi :"",
          sort: "Urutkan Terbaru"
        },
        materi_filter: [
          {
            title: "Semua Materi",
            content_id: ""
          }
        ],
        page: 1,
        loading_message: "Loading...",
        detail: {},
        contentEmpty: "",
        list: [],
        pagination: {},
        list_materi: [],
        pagination_materi: {},
        color: "",
        snackbar: false,
        timeout: 2000,
        nama_image: "",
        loader: false,
        catatan: [
          {
            time: '1:15',
            title: 'Animation with React Native',
            materi: 'The Animation Module',
            catatan: 'React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.'
          },
          {
            time: '0:36',
            title: 'Animation with React Native',
            materi: 'The Animation Module',
            catatan: 'React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.'
          },
          {
            time: '0:36',
            title: 'Animation with React Native',
            materi: 'The Animation Module',
            catatan: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
          }
        ],
        playlist: {},
        process: {
          detail: false,
          run: false,
          form: false,
          delete: false
        },
        warning: {
          message: ""
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        }
      }
    },
    props: {
      dialogopen: {
        type: Boolean,
        default: false
      },
      dialogopenform: {
        type: Boolean,
        default: false
      },
      detailCollection: {
        type: Object,
        default: () => {}
      },
      detailPlaylist: {
        type: Object,
        default: () => {}
      },
      timeDialog: {
        type: String,
        default: ""
      },
    },
    watch: {
      'dialogopen': function(val) {
        this.dialog.detail = val
        this.page = 1
      },
      'dialogopenform': function(val) {
        this.dialog.materi = val
        this.reset()
        this.loader = false
        this.page = 1
        this.filter.search = ""
        this.fetch()
        this.fetchDetail()
      },
      'detailCollection': function(val) {
        this.detail = val
      },
      'detailPlaylist': function(val) {
        this.playlist = val
        this.fetchDetail()
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      Loading: () => import('@/components/Loading')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.detail = this.$props.detailCollection
      this.playlist = this.$props.detailPlaylist
      for (let i in this.detail.content) {
        if (this.detail.content[i].file_url !== '') {
          this.materi_filter.push(this.detail.content[i])
        }
        for (let j in this.detail.content[i].sub) {
          if (this.detail.content[i].sub[j].file_url !== '') {
            this.materi_filter.push(this.detail.content[i])
          }
        }
      }
      this.page = 1
      this.loader = false
      this.filter.search = ""
      this.fetch()
      this.fetchDetail()
    },
    methods: {
      fetch (page) {
        this.process.run = this.loader ? false : true
        let params = {
          limit: page === undefined ? 10 : page * 10,
          course_id: this.detail.course_id,
          content_id: this.filter.materi,
          search: this.filter.search,
          sort: this.filter.sort === "Urutkan Terbaru" ? "-id" : "id", 
        }
        this.$axios.$get(`api/my_course/note`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.run = false
            this.loader = false
          }else{
            this.process.run = false
          }
        })
      },

      fetchDetail (page) {
        this.process.run = this.loader ? false : true
        let params = {
          limit: page === undefined ? 10 : page * 10,
          course_id: this.detail.course_id,
          content_id: this.playlist.data.id,
          search: this.filter.search,
          sort: this.filter.sort === "Urutkan Terbaru" ? "-id" : "id", 
        }
        this.$axios.$get(`api/my_course/note`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list_materi = response.results.data
            this.pagination_materi = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.run = false
            this.loader = false
          }else{
            this.process.run = false
          }
        })
      },

      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
      reset(item) {
        this.error.message = ''
        this.warning.message = ""
        this.success.message = ""
        this.form = {
          id: item !== undefined ? item.id : "",
          text: item !== undefined ? item.text : "",
          time: item !== undefined ? item.time : this.$props.timeDialog,
        }
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          if (this.form.id === '') {
            this.creates()
          } else {
            this.updates()
          }
        } else {
          this.warning.message = "Harap periksa kembali inputan Anda"
          this.color="warning"
          this.snackbar = true
        }
      },
      async creates() {
        this.loading_message = "Membuat catatan Anda..."
        this.process.form = true
        await this.$axios.$post(`api/my_course/note`, 
        {
          content_id: this.playlist.data.id,
          time: this.form.time,
          text: this.form.text
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.$emit('add')
            this.fetch(this.page)
            this.fetchDetail(this.page)
            this.dialog.detail = this.dialog.materi ? false : true
            this.dialog.form = false
            this.color="#27ae60"
            this.success.message="Berhasil membuat catatan"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        this.loading_message = "Mengubah catatan Anda..."
        this.process.form = true
        await this.$axios.$post(`api/my_course/note_update`, 
        {
          id: this.form.id,
          time: this.form.time,
          text: this.form.text
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.fetch()
            this.dialog.detail = this.dialog.materi ? false : true
            this.dialog.form = false
            this.reset(this.page)
            this.fetchDetail(this.page)
            this.color="#27ae60"
            this.success.message="Berhasil mengubah catatan"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      async deletes() {
        this.loading_message = "Menghapus catatan Anda..."
        this.error.message = ''
        this.warning.message = ""
        this.success.message = ""
        this.process.form = true
        await this.$axios.$post(`api/my_course/note_delete`, 
        {
          id: this.form.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch()
            this.$emit('deletes')
            this.fetchDetail(this.page)
            this.color="#27ae60"
            this.success.message="Berhasil menghapus catatan"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>