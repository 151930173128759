<template>
  <div>
    <v-container>

      <section>
        <v-row class="mb-2">
          <v-col cols="9" class="pt-0">
            <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Laporan dan Statistik Penjualan
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Data laporan dan statistik toko, produk dan penjualan Anda.
            </div>
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>

        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb-5'">
          <div class="text-second d-flex align-center" :style="'width: 100%'">
            <div style="width: 100%;">
              <v-btn
                color="#f9f9f9"
                elevation="0"
                :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '100%' : 450"
                :disabled="process.export"
                height="45"
                @click="dialog.sort_filter = true;"
                class="text-capitalize"
                style="border-radius: 6px 0px 0px 6px !important;">
                <div class="d-flex align-center" style="width: 100%;">
                  <div>
                    Periode Data Berdasarkan
                    <span v-if="filter.sort_filter === 'date'">
                      {{ filter.tanggal_mulai | dateTanggalMonth }} - {{ filter.tanggal_selesai | dateTanggalMonth }}
                    </span>
                    <span v-if="filter.sort_filter !== 'date'">
                      {{ filter.sort_filter }}
                    </span>
                  </div>
                  <div class="ml-auto">
                    <v-icon>mdi-chevron-down</v-icon>
                  </div>
                </div>
              </v-btn>
              <v-btn
                color="#3498DB"
                elevation="0"
                @click="dialog.filter = true;"
                height="45"
                v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
                :disabled="process.export"
                :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'border-radius: 0px 6px 6px 0px !important;' : ''"
                class="text-capitalize white--text">
                <v-icon :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'mr-4' : ''">mdi-calendar</v-icon>
                <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  Filter Data
                </span>
              </v-btn>
            </div>
            <div class="text-right ml-auto" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
              <v-btn
                color="#3498DB"
                elevation="0"
                outlined
                @click="exportExel()"
                :disabled="process.export"
                :loading="process.export"
                height="45"
                class="text-capitalize">
                <v-icon class="mr-4">mdi-download</v-icon>
                Download
              </v-btn>
            </div>
          </div>
          <div v-if="$vuetify.breakpoint.name !== 'lg' && $vuetify.breakpoint.name !== 'xl'" class="d-flex align-center mt-3">
            <div>
              <v-btn
                color="#3498DB"
                elevation="0"
                @click="dialog.filter = true;"
                height="45"
                :disabled="process.export"
                :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'border-radius: 0px 6px 6px 0px !important;' : ''"
                class="text-capitalize white--text">
                <v-icon :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'mr-4' : ''">mdi-calendar</v-icon>
                <span v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  Filter Data
                </span>
              </v-btn>
            </div>
            <div class="text-right ml-auto">
              <v-btn
                color="#3498DB"
                elevation="0"
                outlined
                @click="exportExel()"
                :disabled="process.export"
                :loading="process.export"
                height="45"
                class="text-capitalize">
                <v-icon class="mr-4">mdi-download</v-icon>
                Download
              </v-btn>
            </div>
          </div>
        </div>

        <div>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="#4ab762"
            slider-size="4">
            <v-tabs-slider color="#4ab762"></v-tabs-slider>
            <v-tab
              v-for="(item, index) in menu"
              :key="index"
              class="text-capitalize">
              <span class="body-2" :class="tab === index ? 'font-weight-bold color-default' : 'text-third'">
                {{ item.label }}
              </span>
            </v-tab>
          </v-tabs>
          <div class="mt-3">
            <!-- <v-divider/> -->
          <div v-if="tab === 0 && process.run">
            <Overview :filterData="filter"/>
          </div>

          <div v-if="tab === 1">
            <Transaction :filterData="filter"/>
          </div>
          </div>
        </div>
      </section>
    </v-container>

    <v-dialog
      v-model="dialog.filter"
      v-if="dialog.filter"
      persistent
      max-width="350"
      scrollable>
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Filter Data <br />
          <div class="caption grey--text text--darken-1">
            Isi form dibawah ini untuk memfilter
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-4 pt-2 px-4">
          <v-form
            ref="form-report"
            lazy-validation
            @keyup.native.enter="form_filter.sort_filter = 'date'; toFilter()">
            <div class="subtitle-2 mb-6">
              Tanggal Transaksi
            </div>
            <ValidationObserver ref="observer">
              <v-row>
                <v-col :cols="12" class="py-0">
                  <v-menu
                    v-model="filter_start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="Tanggal Mulai" rules="required" v-slot="{ errors }">
                        <v-text-field
                          :value="$dateInput(form_filter.tanggal_mulai)"
                          :label="'Mulai Dari'"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-on:change="form_filter.tanggal_selesai = '';"
                          dense
                          solo
                          color="#4ab762"
                          type="text"
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                          :messages="'Wajib'"
                          require>
                          <template v-slot:append>
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                          <template v-slot:message="{ message }">
                            <span class="red--text" v-html="message"></span>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form_filter.tanggal_mulai"
                      color="#4ab762"
                      :max="reminder"
                      @input="filter_start_date = false; changeDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-menu
                    v-model="filter_end_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="Tanggal Akhir" rules="required" v-slot="{ errors }">
                        <v-text-field
                          :value="$dateInput(form_filter.tanggal_selesai)"
                          :label="'Kurang Dari'"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          :disabled="form_filter.tanggal_mulai === ''"
                          dense
                          solo
                          color="#4ab762"
                          type="text"
                          v-bind="attrs"
                          v-on="on"
                          placeholder="Pilih disini"
                          :error-messages="errors"
                          :messages="'Wajib'"
                          require>
                          <template v-slot:append>
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                          <template v-slot:message="{ message }">
                            <span class="red--text" v-html="message"></span>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form_filter.tanggal_selesai"
                      color="#4ab762"
                      :min="form_filter.tanggal_mulai"
                      :max="max_tanggal"
                      @input="filter_end_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="form_filter.sort_filter = 'date'; toFilter()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >Simpan Filter
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="dialog.filter = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialog.sort_filter"
      v-if="dialog.sort_filter"
      persistent
      max-width="300"
      scrollable>
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Filter Data <br />
          <div class="caption grey--text text--darken-1">
            Pilih tombol dibawah ini untuk memfilter
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-5 pt-4 px-5">
          <v-row>
            <v-col cols="6" v-for="(i, k) in sort_filter" :key="k" class="text-center">
              <v-btn
                elevation="0"
                :outlined="filter.sort_filter === i.name ? true : false"
                width="80"
                height="80"
                class="text-capitalize"
                style="white-space: normal !important; letter-spacing: normal; display: unset;"
                @click="form_filter.sort_filter = i.name; toFilter('tombol');"
                :color="filter.sort_filter === i.name ? '#4ab762' : 'rgb(247, 247, 247)'">
                <div>
                  <div>
                    <v-icon>{{ i.icon }}</v-icon>
                  </div>
                  {{ i.name }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            block
            @click="dialog.sort_filter = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import moment from "moment"
  import 'moment/locale/id'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          offline: false,
          filter: false,
          sort_filter: false
        },
        sort_filter: [
          {
            name: "Minggu Ini",
            icon: "mdi-calendar-week"
          },
          {
            name: "Bulan Ini",
            icon: "mdi-calendar-week"
          },
          {
            name: "Tahun Ini",
            icon: "mdi-calendar-week"
          },
          {
            name: "Tahun Kemarin",
            icon: "mdi-calendar-week"
          }
        ],
        filter_start_date: false,
        filter_end_date: false,
        filter: {
          tanggal_mulai: "",
          tanggal_selesai: "",
          sort_filter: "Tahun Ini"
        },
        form_filter: {
          tanggal_mulai: "",
          tanggal_selesai: "",
          sort_filter: "Tahun Ini"
        },
        max_tanggal: "",
        tab: 0,
        menu: [
          {
            label: "Ringkasan Penjualan"
          },
          {
            label: "Riwayat Penjualan"
          }
        ],
        process: {
          run: false,
          export: false
        }
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      Overview: () => import('@/components/report/Overview.vue'),
      Transaction: () => import('@/components/report/Transaction.vue'),
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      reminder () {
        let now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate())
        return next.toISOString().substr(0, 10)
      },
    },
    mounted () {
        setTimeout(() => {
          this.process.run = true
        }, 100);
    },

    methods: {
      changeDate() {
        let now = new Date(this.form_filter.tanggal_mulai),
            next = new Date(now)
        next.setDate(now.getDate()+90)
        this.max_tanggal = next.toISOString().substr(0, 10)
      },

      async toFilter(item) {
        const isValid = item === undefined ? await this.$refs.observer.validate() : true

        if (isValid) {
          this.filter = {
            tanggal_mulai: this.form_filter.tanggal_mulai,
            tanggal_selesai: this.form_filter.tanggal_selesai,
            sort_filter: this.form_filter.sort_filter
          },
          // this.fetchData()
          this.dialog.filter = false
          this.dialog.sort_filter = false
          // this.validationReset();
        }
      },

      inputFilter(item) {
        this.form_filter = {
          tanggal_mulai: this.filter.tanggal_mulai,
          tanggal_selesai: this.filter.tanggal_selesai,
          sort_filter: this.filter.sort_filter
        },
        this.dialog.filter = true
      },




      async exportExel () {
        this.process.export = true
        let firstday = '';
        let lastday = '';
        if (this.filter.sort_filter === 'Minggu Ini') {
          let curr = new Date; // get current date
          let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          let last = first + 6; // last day is the first day + 6

          firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
          lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);
        }
        let params = {
          filter_type: this.filter.sort_filter === 'Tahun Ini' || this.filter.sort_filter === 'Tahun Kemarin' ? 'year' : this.filter.sort_filter === 'Bulan Ini' ? 'month' : 'date_range',
          filter_value: this.filter.sort_filter === 'Tahun Ini' ? new Date().getFullYear() : this.filter.sort_filter === 'Tahun Kemarin' ? new Date().getFullYear() - 1 : this.filter.sort_filter === 'Bulan Ini' ? `${new Date().getFullYear()}-${new Date().getMonth()}` : this.filter.sort_filter === 'Minggu Ini' ? z : JSON.stringify([this.filter.tanggal_mulai, this.filter.tanggal_selesai])
        }
        this.$axios.$get(`api/publisher/report/summary/export_xls`, { params })
        .then((response)=>{
          if(response.status === 200) {
            window.open(response.results.data.export_url, "_blank");
            this.process.export = false
          }else{
            this.process.export = false
          }
        })
      },
    }
  }
</script>
