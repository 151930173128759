<template>
  <div>
    <div class="pt-5">

      <v-dialog
        persistent
        scrollable
        v-if="dialog.insight"
        :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
        v-model="dialog.insight"
        width="1200">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
          <div class="d-flex align-center body-2 px-5 py-5" style="border-radius: 0px;">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              Detail Insight 
              <span>
                <span v-if="filter.sort_filter !== 'date'">
                  {{ filter.sort_filter }}
                </span>
                <span v-if="filter.sort_filter === 'date'">
                  {{ filter.tanggal_mulai | datetimemonth }} sd {{ filter.tanggal_selesai | datetimemonth }}
                </span>
              </span>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.insight = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text style="min-height: calc(100vh - 235px);">
            <v-row style="height: 100%;" class="py-5">
              <v-col cols="12" md="5" class="pr-0" style="border-right: 0.5px solid rgba(151, 151, 151, 0.10); height: 100%;">
                <!-- <div class="mb-3 font-weight-bold">
                  <span v-if="filter.sort_filter === 'date'">
                    {{ filter.tanggal_mulai | dateTanggalMonth }} - {{ filter.tanggal_selesai | dateTanggalMonth }}
                  </span>
                  <span v-if="filter.sort_filter !== 'date'">
                    {{ filter.sort_filter }}
                  </span>
                </div> -->
                <div class="pr-3 mb-5" style="height: calc(100%); overflow-y: auto; border-bottom: 0.5px solid rgba(151, 151, 151, 0.10)">
                  <div>
                    <div class="mb-6" v-for="(d, j) in summary.detail_insight" :key="j" style="background: #f6f6f6; min-height: 55px; padding: 10px 11px; border-radius: 6px;" :style="selected.data === j ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.data = j">
                      <div class="d-flex align-center" style="width: 100%;">
                        <div class="body-1 ml-0 font-weight-bold text-capitalize">
                          <div style="font-weight: 900;" v-if="filter.sort_filter !== 'Tahun Ini' && filter.sort_filter !== 'Tahun Kemarin'">
                            {{ d.label_raw | dateTanggalMonth }}
                          </div>
                          <div style="font-weight: 900;" v-if="filter.sort_filter === 'Tahun Ini' || filter.sort_filter === 'Tahun Kemarin'">
                            {{ d.label_raw | monthyear }}
                          </div>
                        </div>
                        <div class="ml-auto pl-4 text-right" style="color: #4ab762;">
                          <v-icon size="20" :color="selected.data !== j ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="7" style="height: calc(100vh - 190px); overflow-y: auto;">
                <div>
                  <div v-if="process.user">
                    <v-skeleton-loader
                      class="ma-auto"
                      v-for="r in 4"
                      :key="r"
                      :loading="process.user"
                      type="list-item-avatar-three-line">
                    </v-skeleton-loader>
                  </div>
                  <div v-if="!process.user">
                    <!-- <div class="mb-3 font-weight-bold">
                      Detail
                    </div> -->
                    <!-- <div class="d-flex align-center" style="padding: 10px 5px; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal;">
                        Nama Pembeli
                      </div>
                      <div class="ml-auto green--text" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right;">
                        Nominal
                      </div>
                    </div>
                    <v-divider class="pb-1"></v-divider> -->
                      <div class="d-flex align-center" v-for="(l, i) in summary.detail_insight[selected.data].data" :key="i" style="padding: 15px 18px; border: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.8;" class="mr-5">
                        {{ l.label }}
                      </div>
                      <div class="ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
                        {{ l.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <section>
        <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-bottom: 15px;">
          OVERVIEW
        </div>
        <v-row class="mb-5">
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <div>
                <v-card
                  class="box-shadow border-radius pa-1"
                  style="border-top: 5px solid #E67E22"
                  v-if="overview">
                  <v-card-text>
                    <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                      Produk Terjual
                    </div>
                    <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                      {{ overview.transaction_success }}
                    </div>
                    <div style="font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;" class="mt-3">
                      vs 
                      <span v-if="filter.sort_filter === 'date'">
                        {{ filter.tanggal_mulai | dateTanggalMonth }} - {{ filter.tanggal_selesai | dateTanggalMonth }}
                      </span>
                      <span v-if="filter.sort_filter !== 'date'">
                        {{ filter.sort_filter }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <div>
                <v-card
                  class="box-shadow border-radius pa-1"
                  style="border-top: 5px solid #F1C40F"
                  v-if="overview">
                  <v-card-text>
                    <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                      Penjualan
                    </div>
                    <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                      {{ overview.sale | price }}
                    </div>
                    <div style="font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;" class="mt-3">
                      vs 
                      <span v-if="filter.sort_filter === 'date'">
                        {{ filter.tanggal_mulai | dateTanggalMonth }} - {{ filter.tanggal_selesai | dateTanggalMonth }}
                      </span>
                      <span v-if="filter.sort_filter !== 'date'">
                        {{ filter.sort_filter }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-skeleton-loader>
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #1ABC9C">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Tingkat Konversi
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    0
                  </div>
                  <div style="font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal;" class="mt-3 d-flex align-center">
                    <div style="opacity: 0.5;">
                      vs Tahun Lalu
                    </div>
                    <div class="ml-auto d-flex align-center">
                      <div>
                        5,64%
                      </div>
                      <v-icon size="12" class="ml-1" color="#2ECC71">mdi-arrow-up-thick</v-icon>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #3498DB">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Penjualan
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    0
                  </div>
                  <div style="font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;" class="mt-3">
                    vs Tahun Lalu
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col> -->
        </v-row>


        <div class="mb-3">
          <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6;">
            Grafik setiap Kriteria
          </div>
        </div>

        <v-row class="mb-5">
          <v-col cols="12" md="12">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1">
                <div v-if="summary.detail">
                  <bar-chart
                    id="bar"
                    :data="summary.detail"
                    :xkey="summary.detail_xkeys"
                    :bar-colors="barColor"
                    :ykeys="ykeys"
                    :yLogScale="false"
                    :resize="true"
                    :labels="labelBar"
                    xLabelAngle="60"
                    :grid="true"
                    :pointSize="4"
                    :lineWidth="2"
                    yLabels="5min"
                    :parseTime="false"
                    :padding="80"
                    :xLabelMargin="50"
                    >
                  </bar-chart>
                </div>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>


        <v-row class="mb-5">
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-5">
                <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                  Berdarkan Harga Kelas
                </div>
                <div class="d-flex align-center">
                  <div style="width: 45%;" v-if="overview">
                    <donut-chart id="donut" :data="overview.donut_course_price" :colors="arrayColorsKelas" resize="true"></donut-chart>
                  </div>
                  <div v-if="overview" style="width: 55%;" class="ml-5">
                    <div v-for="(l, i) in overview.donut_course_price" :key="i">
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050;" class="d-flex py-3">
                        <div style="width: 10px; height: 10px; border-radius: 50px; margin-right: 11px;" class="mt-1" :style="`background: ${arrayColorsKelas[i]}`">
                        </div>
                        <div>
                          {{l.label }}
                          <div class="mt-2">
                            <span style="font-size: 24px;">{{ l.transaction }}</span> Transaksi
                          </div>
                        </div>
                        <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050;" class="ml-auto mt-1">
                          {{l.value }}%
                        </div>
                      </div>
                      <v-divider v-if="i === 0"></v-divider>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-5">
                <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                  Produk Terlaris
                </div>
                <div v-if="overview.donut_course_best && overview.donut_course_best.length < 1" style="height: 207px; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6;" class="d-flex align-center justify-center">
                  Belum Ada Produk Terlaris
                </div>
                <div class="d-flex align-center" v-if="overview.donut_course_best && overview.donut_course_best.length > 0">
                  <div style="width: 45%;">
                    <donut-chart id="donuts" :data="overview.donut_course_best" :colors="arrayColors" resize="true"></donut-chart>
                  </div>
                  <div style="width: 55%;" class="ml-5">
                    <div v-for="(l, i) in overview.donut_course_best" :key="i" class="mb-3 d-flex align-center">
                      <div style="width: 10px; height: 10px; border-radius: 50px;" :style="`background: ${arrayColors[i]}`">
                      </div>
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; margin-left: 11px;">
                        {{ l.label }}
                      </div>
                      <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050;" class="ml-auto">
                        {{ l.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>

        <div class="d-flex align-center mb_15">
          <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6;">
            INSIGHT
          </div>
          <div class="ml-auto">
            <span style="font-weight: normal; font-size: 14px; line-height: 16px; ext-align: right; color: #3498DB;">1 Bulan Terakhir</span>
            <span class="ml-3 cursor-pointer" style="font-size: 14px; line-height: 16px; text-align: right; color: #505050; mix-blend-mode: normal; opacity: 0.4;" @click="dialog.insight = true;">Perbulannya</span>
          </div>
        </div>

        <div v-if="process.run">
          <div v-for="p in 5" :key="p" class="mb-3">
            <v-skeleton-loader
              class="ma-auto"
              style="height: 50px;"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
            </v-skeleton-loader>
          </div>
        </div>


        <div v-if="!process.run && overview.insight">
          <div class="d-flex align-center" v-for="(l, i) in overview.insight.data" :key="i" style="padding: 15px 18px; border: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
              {{ l.label }}
            </div>
            <div class="ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
              {{ l.value }}
            </div>
          </div>

          <!-- <div class="d-flex align-center" style="padding: 15px 18px; border: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
              Rata-rata jumlah transaksi 1 bulan terahir
            </div>
            <div class="ml-auto">
              <span style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
                2
              </span>

              <span style="font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
                Transaksi
              </span>
            </div>
          </div>

          <div class="d-flex align-center" style="padding: 15px 18px; border: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
              Produk / Elearning yang sering dibeli dalam 1 bulan terakhir
            </div>
            <div class="ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
              React Native - The Practical Guide [2021 Edition]
            </div>
          </div>

          <div class="d-flex align-center" style="padding: 15px 18px; border: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
              Pembayaran yang sering digunakan dalam 1 bulan terakhir
            </div>
            <div class="ml-auto" style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
              BCA Virtual Account 
            </div>
          </div>

          <div class="d-flex align-center" style="padding: 15px 18px; border: 1px solid #EDEDED; box-sizing: border-box; border-radius: 5px; margin-bottom: 10px;">
            <div style="font-weight: normal; font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
              Perbandingan transaksi berhasil vs batal 1 bulan terakhir
            </div>
            <div class="ml-auto">
              <span style="font-weight: 900; font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
                2 dar 10
              </span>

              <span style="font-size: 14px; line-height: 16px; text-align: right; color: #505050;">
                Transaksi
              </span>
            </div>
          </div> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import { DonutChart } from 'vue-morris'
  import { BarChart } from 'vue-morris'
  import { LineChart } from 'vue-morris'
  import { AreaChart } from 'vue-morris'
  export default {
    middleware: false,
    data () {
      return {
        donutData: [
          { label: 'Car', value: 40 },
          { label: 'Motorcycle', value: 150 },
          { label: 'airplane', value: 100 },
          { label: 'airplane', value: 100 },
          { label: 'airplane', value: 100 }
        ],
        donutDataKelas: [
          { label: 'Car', value: 50 },
          { label: 'Motorcycle', value: 50 }
        ],
        selected: {
          data: 0
        },
        barData: [
          { y: '2014', a: 50, b: 90},
          { y: '2015', a: 65,  b: 75},
          { y: '2016', a: 50,  b: 50},
          { y: '2017', a: 75,  b: 60},
          { y: '2018', a: 80,  b: 65},
          { y: '2019', a: 90,  b: 70},
          { y: '2020', a: 100, b: 75},
          { y: '2021', a: 115, b: 75},
          { y: '2022', a: 120, b: 85},
          { y: '2023', a: 145, b: 85},
          { y: '2024', a: 160, b: 95}
        ],
        barColor: ['#E67E22', '#F1C40F'],
        labelBar: ['Produk Terjual', 'Penjualan'],
        ykeys: ['transaction_success', 'sale'],
        arrayColors:[ "#A6CEE3", "#1F78B4", "#B2DF8A", "#33A02C", "#FB9A99" ],
        arrayColorsKelas:[ "#1ABC9C", "#F1C40F"],
        dialog: {
          insight: false
        },
        overview: {},
        process: {
          detail: false,
          run: false,
          cancel: false,
          export: false
        },
        filter: {
          tanggal_mulai: "",
          tanggal_selesai: "",
          sort_filter: "Tahun Ini"
        },
        summary: {},
        success: {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/transaction',
          title: 'Transaksi Saya',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    props: {
      filterData: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'filterData': function(val) {
        this.filter = val
        this.fetch()
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      DonutChart, BarChart, LineChart, AreaChart
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.filter = this.$props.filterData
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.run = true
        let firstday = '';
        let lastday = '';
        if (this.filter.sort_filter === 'Minggu Ini') {
          let curr = new Date; // get current date
          let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          let last = first + 6; // last day is the first day + 6

          firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
          lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);
        }
        let params = {
          filter_type: this.filter.sort_filter === 'Tahun Ini' || this.filter.sort_filter === 'Tahun Kemarin' ? 'year' : this.filter.sort_filter === 'Bulan Ini' ? 'month' : 'date_range',
          filter_value: this.filter.sort_filter === 'Tahun Ini' ? new Date().getFullYear() : this.filter.sort_filter === 'Tahun Kemarin' ? new Date().getFullYear() - 1 : this.filter.sort_filter === 'Bulan Ini' ? `${new Date().getFullYear()}-${new Date().getMonth()}` : this.filter.sort_filter === 'Minggu Ini' ? JSON.stringify([firstday, lastday]) : JSON.stringify([this.filter.tanggal_mulai, this.filter.tanggal_selesai])
        }
        this.$axios.$get(`api/publisher/report/summary/detail_graphic`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.summary = response.results.data
            let array_data = []
            for (let index = 0; index < response.results.data.detail.length; index++) {
              array_data.push({
                date: response.results.data.detail[index].date,
                sale: response.results.data.detail[index].sale,
                transaction_success: response.results.data.detail[index].transaction_success
              })
            }
            // let array_data_donat = []
            // for (let index = 0; index < response.results.data.overview.donut_course_price.length; index++) {
            //   array_data_donat.push({
            //     label: response.results.data.overview.donut_course_price[index].label,
            //     value: parseInt(response.results.data.overview.donut_course_price[index].value_percent),
            //     transaction: response.results.data.overview.donut_course_price[index].value
            //   })
            // }
            this.summary.detail = array_data
            // this.overview.donut_course_price = array_data_donat
            this.fetchoverview()
          }else{
            this.process.run = false
          }
        })
      },

      async fetchoverview () {
        this.process.run = true
        let firstday = '';
        let lastday = '';
        if (this.filter.sort_filter === 'Minggu Ini') {
          let curr = new Date; // get current date
          let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          let last = first + 6; // last day is the first day + 6

          firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
          lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);
        }
        let params = {
          filter_type: this.filter.sort_filter === 'Tahun Ini' || this.filter.sort_filter === 'Tahun Kemarin' ? 'year' : this.filter.sort_filter === 'Bulan Ini' ? 'month' : 'date_range',
          filter_value: this.filter.sort_filter === 'Tahun Ini' ? new Date().getFullYear() : this.filter.sort_filter === 'Tahun Kemarin' ? new Date().getFullYear() - 1 : this.filter.sort_filter === 'Bulan Ini' ? `${new Date().getFullYear()}-${new Date().getMonth()}` : this.filter.sort_filter === 'Minggu Ini' ? JSON.stringify([firstday, lastday]) : JSON.stringify([this.filter.tanggal_mulai, this.filter.tanggal_selesai])
        }
        this.$axios.$get(`api/publisher/report/summary/overview`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.overview = response.results.data
            // let array_data = []
            // for (let index = 0; index < response.results.data.detail.length; index++) {
            //   array_data.push({
            //     date: response.results.data.detail[index].date,
            //     sale: response.results.data.detail[index].sale,
            //     transaction_success: response.results.data.detail[index].transaction_success
            //   })
            // }
            let array_data_donat = []
            for (let index = 0; index < response.results.data.donut_course_price.length; index++) {
              array_data_donat.push({
                label: response.results.data.donut_course_price[index].label,
                value: parseInt(response.results.data.donut_course_price[index].value_percent),
                transaction: response.results.data.donut_course_price[index].value
              })
            }
            // this.summary.detail = array_data
            this.overview.donut_course_price = array_data_donat
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
    }
  }
</script>

<style>
  #donut svg {
    height: 200px !important;
  }
  #donuts svg {
    height: 200px !important;
  }
  .morris-hover {
    position: absolute;
    z-index: 0 !important;
  }
</style>
