<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Tutup
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackbar_warning"
        :timeout="timeout"
        centered
        top
        style="margin-top: -40px;"
        color="warning">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar_warning = false"
          >
            Tutup
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.cancel"
        v-model="dialog.cancel"
        width="400">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="title text-second text--darken-2 font-weight-bold">
              Hapus Diskon
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.cancel"
                class="text-capitalize px-0"
                @click="dialog.cancel = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah Anda yakin untuk menghapus diskon <b>{{ deletes.name }}</b> ini?
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="red"
                  block
                  :disabled="process.cancel"
                  :loading="process.cancel"
                  @click="toDeletes()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, Hapus
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.cancel"
                  block
                  @click="dialog.cancel = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              @click="dialog.cancel = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Ya, Hapus
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              @click="dialog.cancel = false"
              class="text-capitalize white--text ml-2">
              Tidak
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.form"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.form"
        width="600">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <span v-if="form.id === ''">Tambah</span><span v-if="form.id !== ''">Ubah</span> Diskon
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form || process.avatar"
                class="text-capitalize px-0"
                @click="dialog.form = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <div class="text-second body-2 px-5">
            Nama diskon yang sesuai kategori Produk lebih mudah dicari pembeli
          </div> -->
          <v-divider></v-divider>
          <v-card-text style="min-height: calc(100vh - 300px);" class="mt-1">
            <ValidationObserver ref="observer">
              <div class="d-flex align-center text-first mt-5">
                <div class="body-2 mb-1 font-weight-bold">
                  Nama
                </div>
              </div>
              <ValidationProvider name="Nama" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  placeholder="Tuliskan disini"
                  :disabled="process.form"
                  v-model="form.name"
                  class="pt-0 mt-0"
                  :counter="50"
                  v-on:keyup.enter="save()"
                  :messages="'Wajib'"
                  :error-messages="errors"
                  require>
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>

              <div class="d-flex align-center text-first mt-7">
                <div class="body-2 mb-1 font-weight-bold">
                  Diskon<span class="fs-10 ml-1 font-weight-regular warning--text">(diskon dalam %)</span>
                </div>
              </div>
              <ValidationProvider name="Diskon" rules="required|min_0:0|max_100:100|max:3" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  placeholder="Tuliskan disini"
                  :disabled="process.form"
                  suffix="%"
                  :counter="3"
                  class="pt-0 mt-0"
                  type="number"
                  v-model="form.diskon"
                  v-on:keyup.enter="save()"
                  :error-messages="errors"
                  :messages="'Wajib'"
                  require>
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>

              <div class="d-flex align-center text-first mt-7">
                <div class="body-2 mb-1 font-weight-bold">
                  Digunakan pada platform
                </div>
              </div>
              <ValidationProvider name="Platform" rules="required" v-slot="{ errors }">
                <v-select
                  color="#4ab762"
                  :items="[
                    {
                      name: 'Semua Aplikasi',
                      value: 'semua'
                    },
                    {
                      name: 'Aplikasi Web',
                      value: 'web'
                    },
                    {
                      name: 'Aplikasi Mobile',
                      value: 'mobile'
                    }
                  ]"
                  placeholder="Tuliskan disini"
                  :disabled="process.form"
                  class="pt-0 mt-0"
                  item-text="name"
                  item-value="value"
                  item-color="green"
                  v-model="form.platform"
                  v-on:keyup.enter="save()"
                  :messages="'Wajib'"
                  :error-messages="errors"
                  require>
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                </v-select>
              </ValidationProvider>

              <v-row>
                <v-col cols="12" md="6">
                  <div class="d-flex align-center text-first mt-7">
                      <div class="body-2 mb-1 font-weight-bold">
                        Tanggal Mulai
                      </div>
                    </div>
                    <v-menu
                      v-model="date.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider name="Tanggal Mulai" rules="required" v-slot="{ errors }">
                          <v-text-field
                            :value="$dateInput(form.date_start)"
                            readonly
                            class="pt-0 mt-0"
                            color="#4ab762"
                            placeholder="Tuliskan disini"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                            :messages="'Wajib'"
                            require>
                            <template v-slot:append>
                              <v-icon>mdi-chevron-down</v-icon>
                            </template>
                            <template v-slot:message="{ message }">
                              <span class="red--text" v-html="message"></span>
                            </template>
                          </v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="form.date_start"
                        color="#4ab762"
                        @input="form.time_start = ''; form.date_end = ''; form.time_end = ''; date.start = false"
                        :min="new Date().toISOString().substr(0, 10)"
                      ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="d-flex align-center text-first mt-7">
                    <div class="body-2 mb-1 font-weight-bold">
                      Jam Mulai
                    </div>
                  </div>
                  <v-menu
                    v-model="time.start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="Jam Mulai" rules="required" v-slot="{ errors }">
                        <v-text-field
                          v-model="form.time_start"
                          readonly
                          color="#4ab762"
                          class="pt-0 mt-0"
                          placeholder="Pilih disini"
                          v-bind="form.date_start === '' ? '' : attrs"
                          v-on="form.date_start === '' ? '' : on"
                          @click="form.date_start === '' ? snackbar_warning = true : ''; success.message = 'Harap Masukan Tanggal Mulai Dahulu' "
                          :error-messages="errors"
                          :messages="'Wajib'"
                          require>
                          <template v-slot:append>
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                          <template v-slot:message="{ message }">
                            <span class="red--text" v-html="message"></span>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-time-picker
                      v-model="form.time_start"
                      format="24hr"
                      :min="form.date_start === new Date().toISOString().substr(0, 10) ? reminder_time : ''"
                      color="#4ab762"
                      @input="form.date_end = ''; form.time_end = ''; time.start = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="d-flex align-center text-first mt-7">
                    <div class="body-2 mb-1 font-weight-bold">
                      Tanggal Selesai
                    </div>
                  </div>
                  <v-menu
                    v-model="date.end"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="Tanggal Selesai" rules="required" v-slot="{ errors }">
                        <v-text-field
                          :value="$dateInput(form.date_end)"
                          readonly
                          v-bind="form.time_start === '' ? '' : attrs"
                          v-on="form.time_start === '' ? '' : on"
                          @click="form.time_start === '' ? snackbar_warning = true : ''; success.message = 'Harap Masukan Jam Mulai Dahulu' "
                          class="pt-0 mt-0"
                          color="#4ab762"
                          placeholder="Pilih disini"
                          :error-messages="errors"
                          :messages="'Wajib'"
                          require>
                          <template v-slot:append>
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                          <template v-slot:message="{ message }">
                            <span class="red--text" v-html="message"></span>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form.date_end"
                      color="#4ab762"
                      :min="form.date_start"
                      @input="form.time_end = ''; date.end = false;"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="d-flex align-center text-first mt-7">
                    <div class="body-2 mb-1 font-weight-bold">
                      Jam Selesai
                    </div>
                  </div>
                  <v-menu
                    v-model="time.end"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="Jam Selesai" rules="required" v-slot="{ errors }">
                        <v-text-field
                          v-model="form.time_end"
                          readonly
                          color="#4ab762"
                          class="pt-0 mt-0"
                          placeholder="Pilih disini"
                          v-bind="form.date_end === '' ? '' : attrs"
                          v-on="form.date_end === '' ? '' : on"
                          @click="form.date_end === '' ? snackbar_warning = true : ''; success.message = 'Harap Masukan Tanggal Selesai Dahulu' "
                          :error-messages="errors"
                          :messages="'Wajib'"
                          require>
                          <template v-slot:append>
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                          <template v-slot:message="{ message }">
                            <span class="red--text" v-html="message"></span>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-time-picker
                      v-model="form.time_end"
                      format="24hr"
                      color="#4ab762"
                      :min="form.date_start === form.date_end ? form.time_start : ''"
                      @input="time.end = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>


              <!-- <v-form
                class="mb-0 mt-5">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular">
                      <p class="fs-15 mb-3">
                        Nama
                      </p>
                    </div>
                    <ValidationProvider name="name" rules="required|max:100" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        placeholder="Tuliskan disini"
                        :disabled="process.form"
                        class="pt-0"
                        :counter="100"
                        v-model="form.name"
                        v-on:keyup.enter="save()"
                        :error-messages="errors"
                        required>
                        <template v-slot:message="{ message }">
                          <span class="red--text" v-html="message"></span>
                        </template>
                        <template v-slot:counter="{ props }">
                          <span style="font-size: 9px;" class="text-second">
                            {{ props.value }} dari {{ props.max }} karakter
                          </span>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular">
                      <p class="fs-15 mb-3">
                        Diskon
                      </p>
                    </div>
                    <ValidationProvider name="Diskon" rules="required|min_0:0|max_100:100" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        placeholder="Tuliskan disini"
                        :disabled="process.form"
                        suffix="%"
                        class="pt-0"
                        type="number"
                        v-model="form.diskon"
                        v-on:keyup.enter="save()"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular">
                      <p class="fs-15 mb-3">
                        Platform
                      </p>
                    </div>
                    <ValidationProvider name="Platform" rules="required" v-slot="{ errors }">
                      <v-select
                        solo
                        color="#4ab762"
                        :items="[
                          {
                            name: 'Semua Platform',
                            value: 'semua'
                          },
                          {
                            name: 'Web',
                            value: 'web'
                          },
                          {
                            name: 'mobile',
                            value: 'mobile'
                          }
                        ]"
                        placeholder="Tuliskan disini"
                        :disabled="process.form"
                        class="pt-0"
                        item-text="name"
                        item-value="value"
                        item-color="green"
                        v-model="form.platform"
                        v-on:keyup.enter="save()"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular mt_15">
                      <p class="fs-15 mb-3">
                        Tanggal Mulai
                      </p>
                    </div>
                    <v-menu
                      v-model="date.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider name="Tanggal Mulai" rules="required" v-slot="{ errors }">
                          <v-text-field
                            :value="$dateInput(form.date_start)"
                            readonly
                            solo
                            v-on:change="form.time_start = ''; form.date_end = ''; form.time_end = ''"
                            class="pt-0"
                            color="#4ab762"
                            placeholder="Tuliskan disini"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="form.date_start"
                        color="#4ab762"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="date.start = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular mt_15">
                      <p class="fs-15 mb-3">
                        Waktu Mulai
                      </p>
                    </div>
                    <v-menu
                      v-model="time.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider name="Waktu Mulai" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="form.time_start"
                            readonly
                            solo
                            v-on:change="form.date_end = ''; form.time_end = ''"
                            color="#4ab762"
                            class="pt-0"
                            placeholder="Tuliskan disini"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="form.date_start === ''"
                            :error-messages="errors"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-time-picker
                        v-model="form.time_start"
                        format="24hr"
                        :min="form.date_start === new Date().toISOString().substr(0, 10) ? reminder_time : ''"
                        color="#4ab762"
                        @input="time.start = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular mt_15">
                      <p class="fs-15 mb-3">
                        Tanggal Selesai
                      </p>
                    </div>
                    <v-menu
                      v-model="date.end"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider name="Tanggal Selesai" rules="required" v-slot="{ errors }">
                          <v-text-field
                            :value="$dateInput(form.date_end)"
                            readonly
                            solo
                            v-on:change="form.time_end = '';"
                            :disabled="form.time_start === ''"
                            class="pt-0"
                            color="#4ab762"
                            placeholder="Tuliskan disini"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="form.date_end"
                        color="#4ab762"
                        :min="form.date_start"
                        @input="date.end = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div class="d-flex align-center text-first body-2 font-weight-regular mt_15">
                      <p class="fs-15 mb-3">
                        Waktu Selesai
                      </p>
                    </div>
                    <v-menu
                      v-model="time.end"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider name="Waktu Selesai" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="form.time_end"
                            readonly
                            solo
                            color="#4ab762"
                            class="pt-0"
                            :disabled="form.date_end === ''"
                            placeholder="Tuliskan disini"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                            required
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-time-picker
                        v-model="form.time_end"
                        format="24hr"
                        color="#4ab762"
                        :min="form.date_start === form.date_end ? form.time_start : ''"
                        @input="time.end = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form> -->
            </ValidationObserver>
            <div class="mt_30">
              <!-- <v-divider></v-divider> -->
              <div class="d-flex my-3 align-center">
                <div class="text-first body-2 font-weight-bold">
                  List Produk Pada Diskon Ini <span v-if="form.name !== ''">({{ form.product.length }})</span>
                </div>
                <div class="ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '85%' : ''" v-if="form.id === ''">
                  <v-btn
                    text
                    color="#3498db"
                    :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                    v-if="form.product.length < 1"
                    @click="form.id === '' ? fetchCategory() : fetchClass(); dialog.product = true; selected.product = [];"
                    class="text-capitalize pr-0">
                    Pilih Produk
                  </v-btn>
                  <v-btn
                    text
                    color="#3498db"
                    :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                    v-if="form.product.length > 0"
                    @click="form.id === '' ? fetchCategory() : fetchClass(); selected.product = form.product; dialog.product = true;"
                    class="text-capitalize">
                    Tambah Produk
                  </v-btn>
                  <v-btn
                    text
                    color="red"
                    :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                    @click="form.product = []"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'pl-0' : ''"
                    v-if="form.product.length > 0"
                    class="text-capitalize pr-0">
                    Hapus Semua
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="form.product.length > 0">
              <div v-if="form.type === 'course'">
                <v-card flat
                  v-for="(item, index) in form.product"
                  :key="index">
                  <div>
                    <v-list flat three-line class="py-0" v-if="form.product.length > 0">
                      <v-list-item class="px-0">

                        <v-list-item-avatar tile size="80" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                            {{ item.name }} 
                          </v-list-item-subtitle>

                          <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                            <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.discount_percent) > 0">
                              {{ item.price | price }}
                            </span>
                            <span class="font-weight-bold red--text">
                              <span v-if="parseInt(item.discount_percent) > 0">
                              (-{{ parseInt(item.discount_percent) }}%)
                              </span>
                              <span class="text-first" v-if="parseInt(item.price) > 0">
                                {{ item.price_discount | price }}
                              </span>

                              <span class="green--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </p>
                        </v-list-item-content>

                        <v-list-item-content class="align-center text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 120px;' : 'max-width: 120px;'">
                          <v-list-item-subtitle class="mt-1">
                            <div v-if="form.id === ''">
                              <v-icon color="red" class="ml-2" @click="deleteProduct(item)">mdi-block-helper</v-icon>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-divider v-if="index < form.product.length - 1"/>
                </v-card>
              </div>
              <div v-if="form.type === 'category'">
                <v-card flat
                  v-for="(item, index) in form.product"
                  :key="index">
                  <div>
                    <v-list flat three-line class="py-0" v-if="form.product.length > 0">
                      <v-list-item class="px-0">

                        <v-list-item-avatar tile size="80" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            v-if="item.img_url !== ''"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <v-img 
                            style="border-radius: 10px;"
                            v-if="item.img_url === ''"
                            :src="require('@/assets/images/content_image_empty_square.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                            {{ item.name }} 
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content class="align-center text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 120px;' : 'max-width: 120px;'">
                          <v-list-item-subtitle class="mt-1">
                            <div v-if="form.id === ''">
                              <v-icon color="red" class="ml-2" @click="deleteProduct(item)">mdi-block-helper</v-icon>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-divider v-if="index < (form.product.length - 1)"/>
                </v-card>
              </div>
            </div>
            <v-alert
              text
              v-if="form.id === '' && form.product.length < 1"
              class="body-2 my-2"
              color="warning">
              Belum Ada Produk Yang Dipilih
            </v-alert>
            <!-- <div v-if="form.name !== ''">
              <div class="body-2 text-second" v-if="form.product.length < 1">
                Anda juga dapat menambahkan Produk pada diskon ini nanti.
              </div>
            </div> -->
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-alert 
              type="error" 
              text
              dense
              class="mb-0 mr-2 px-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="190"
              :disabled="process.form || process.avatar"
              :loading="process.form"
              @click="save()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  <span v-if="form.id === ''">Tambah</span><span v-if="form.id !== ''">Simpan</span> Diskon
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.form || process.avatar"
              width="110"
              @click="dialog.form = false"
              class="text-capitalize white--text mr-2">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.product"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.product"
        width="650">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 pt-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Pilih Kelas atau Kategori
              </div>
              <div class="body-2">
                Pilih Kelas atau Kategori dari list Kelas atau Kategori Anda untuk ditambahkan ke diskon.
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.product = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-5 pt-2 pb-7'">
            <div class="text-second" style="width: 60%">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.title"
                v-on:keyup.enter="fetchClass()"
                @click:clear="filter.title = ''; fetchClass();"
                :placeholder="`Cari Produk…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
              <v-select
                  :items="[
                    'Belum bisa di gunakan'
                  ]"
                  solo
                  disabled
                  color="#4ab762"
                  item-color="green"
                  hide-details
                  v-model="filter.category"
                  class=""
                  placeholder="Kategori(Belum Bisa)"
                ></v-select>
            </div>
          </div> -->
          <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4' : 'px-5'">
            <v-radio-group
              v-model="form.type"
              row>
              <v-radio
                value="category"
                @click="fetchCategory(); selected.product = [];"
                color="#4ab762">
                <template v-slot:label>
                  <div><strong class="fs-16 text-first">Kategori</strong></div>
                </template>
              </v-radio>
              <v-radio
                value="course"
                @click="fetchClass(); selected.product = [];"
                color="#4ab762">
                <template v-slot:label>
                  <div><strong class="fs-16 text-first">Kelas</strong></div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);">
            <div v-if="form.type === 'course'">
              <v-list flat dense class="" v-if="!process.kelas && kelas.length > 0">
                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <div>
                      <v-icon color="grey" @click="checkAll()" v-if="selected.product.length < 1 || selected.product.length !== kelas.length">
                        mdi-checkbox-blank-outline
                      </v-icon>
                      <v-icon color="#4ab762" @click="checkAll()" v-if="selected.product.length > 0 && selected.product.length === kelas.length">
                        mdi-checkbox-marked
                      </v-icon>
                    </div>
                    <!-- <div v-if="allDiskon">
                      <v-icon color="grey">
                        mdi-close-box
                      </v-icon>
                    </div> -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                      Pilih Semua
                    </v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-content v-if="allDiskon" style="min-width: 320px;">
                    <div class="red--text body-1">
                      Semua Kelas telah terdapat dalam diskon
                    </div>
                  </v-list-item-content> -->

                </v-list-item>
              </v-list>

              <v-divider/>
              <v-card flat
                v-for="(item, index) in process.kelas ? 8 : kelas"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.kelas"
                  type="list-item-avatar-three-line">
                  <div>
                    <v-list flat three-line class="py-0" v-if="!process.kelas && kelas.length > 0">
                      <v-list-item class="px-0">
                        <v-list-item-icon>
                          <v-checkbox
                            v-model="selected.product"
                            :color="'#4ab762'"
                            class="mt-0"
                            v-on:change="error.message = ''"
                            :value="item">
                          </v-checkbox>
                          <!-- <v-icon color="#4ab762" v-if="item.sys_kelas_cart_is_checked">mdi-checkbox-marked</v-icon>
                          <v-icon color="grey" v-if="!item.sys_kelas_cart_is_checked">mdi-checkbox-blank-outline</v-icon> -->
                        </v-list-item-icon>

                        <v-list-item-avatar tile size="80" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                            {{ item.name }} 
                          </v-list-item-subtitle>

                          <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                            <span class="text-decoration-line-through text-second mr-2" v-if="item.discount_bool">
                              {{ item.price | price }}
                            </span>
                            <span class="font-weight-bold red--text">
                              <span v-if="item.discount_bool">
                              (-{{ parseInt(item.discount_percent) }}%)
                              </span>
                              <span class="text-first" v-if="parseInt(item.price) > 0">
                                <span v-if="item.discount_bool">
                                  {{ item.price | price }}
                                </span>
                                <span v-if="!item.discount_bool">
                                  {{ item.price_discount | price }}
                                </span>
                              </span>

                              <span class="green--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </p>
                          <div class="text-first body-2">
                            <span v-if="!item.discount_bool">Kelas Belum Terdapat Diskon</span>
                            <span v-if="item.discount_bool" class="primary--text">Kelas telah terdapat diskon</span>
                          </div>
                          <!-- <div class="text-first body-2" v-if="form.id !== ''">
                            <span>Belum terdapat di diskon manapun</span>
                          </div> -->
                        </v-list-item-content>
                        <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                          <!-- <v-list-item-subtitle class="mt-1">
                            <div>
                              <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                            </div>
                          </v-list-item-subtitle> -->
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-divider v-if="index < kelas.length - 1"/>

                </v-skeleton-loader>
              </v-card>
              <div class="text-right mt-16" v-if="!process.kelas && kelas.length < 1">
                <Empty v-model="contentEmptyProduct"/>
              </div>
            </div>

            <div v-if="form.type === 'category'">
              <v-list flat dense class="" v-if="!process.category && category.length > 0">
                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <div>
                      <v-icon color="grey" @click="checkAllCategory()" v-if="selected.product.length < 1 || selected.product.length !== category.length">
                        mdi-checkbox-blank-outline
                      </v-icon>
                      <v-icon color="#4ab762" @click="checkAllCategory()" v-if="selected.product.length > 0 && selected.product.length === category.length">
                        mdi-checkbox-marked
                      </v-icon>
                    </div>
                    <!-- <div v-if="allDiskon">
                      <v-icon color="grey">
                        mdi-close-box
                      </v-icon>
                    </div> -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                      Pilih Semua
                    </v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-content v-if="allDiskon" style="min-width: 320px;">
                    <div class="red--text body-1">
                      Semua Kelas telah terdapat dalam diskon
                    </div>
                  </v-list-item-content> -->

                </v-list-item>
              </v-list>

              <v-divider/>
              <v-card flat
                v-for="(item, index) in process.category ? 8 : category"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.category"
                  type="list-item-avatar-three-line">
                  <div>
                    <v-list flat three-line class="py-0" v-if="!process.category && category.length > 0">
                      <v-list-item class="px-0">
                        <v-list-item-icon>
                          <v-checkbox
                            v-model="selected.product"
                            :color="'#4ab762'"
                            class="mt-0"
                            v-on:change="error.message = ''"
                            :value="item">
                          </v-checkbox>
                          <!-- <v-icon color="#4ab762" v-if="item.sys_kelas_cart_is_checked">mdi-checkbox-marked</v-icon>
                          <v-icon color="grey" v-if="!item.sys_kelas_cart_is_checked">mdi-checkbox-blank-outline</v-icon> -->
                        </v-list-item-icon>

                        <v-list-item-avatar tile size="80" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            v-if="item.img_url !== ''"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img 
                            style="border-radius: 10px;"
                            v-if="item.img_url === ''"
                            :src="require('@/assets/images/content_image_empty_square.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                            {{ item.name }} 
                          </v-list-item-subtitle>
                          <div class="text-first body-2">
                            <span class="warning--text">Jika kelas di kategori ini terdapat diskon, maka akan diganti dengan diskon ya baru</span>
                          </div>
                          <!-- <div class="text-first body-2" v-if="form.id !== ''">
                            <span>Belum terdapat di diskon manapun</span>
                          </div> -->
                        </v-list-item-content>
                        <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                          <!-- <v-list-item-subtitle class="mt-1">
                            <div>
                              <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                            </div>
                          </v-list-item-subtitle> -->
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-divider v-if="index < category.length - 1"/>

                </v-skeleton-loader>
              </v-card>
              <div class="text-right mt-16" v-if="!process.category && category.length < 1">
                <Empty v-model="contentEmptyProduct"/>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="200"
              :disabled="process.form || process.kelas || selected.product.length < 1"
              :loading="process.form"
              @click="addClassToDiskon()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <div class="ml-auto">
                  Tambahkan ke Diskon
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.form"
              width="120"
              @click="dialog.product = false"
              class="text-capitalize white--text mr-2">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.detail"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.detail"
        width="650">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="fs-18 text-second text--darken-2 font-weight-bold">
              <div>
                Detail Diskon
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.detail = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);" class="px-0">
            <div v-if="process.detail" class="mt-3">
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
            </div>
            <div v-if="!process.detail" class="my-4 px-5">
              <div class="fs-14 text-second mb-2">
                Nama
              </div>
              <div class="fs-16 font-weight-bold" style="line-height: 26px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
                {{ detail.name }}
              </div>
              <v-list-item class="py-0 px-0 my-2">
                <v-list-item-content class="py-0">
                  <div class="body-2 body-1 text-left color-blue-first">
                    <div class="fs-14 text-second mb-2">
                      Diskon
                    </div>
                    <div class="fs-16 font-weight-bold">
                      {{ detail.percent }}%
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list style="background: transparent;" class="py-0">
                <v-list-item class="py-0 px-0">
                  <v-list-item-content class="py-0">
                    <div class="body-2 body-1 text-second text-left text--darken-2 ">
                      <div class="fs-14 text-second mb-2">
                        Tanggal
                      </div>
                      <div class="fs-16 font-weight-bold text-first">
                        {{ detail.start_datetime | datetimemonth }} - {{ detail.end_datetime | datetimemonth }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <div class="fs-14 text-second mb-2 mt-5">
                Dapat digunakan pada platform
              </div>
              <div class="fs-16 font-weight-bold text-first">
                <span v-if="detail.platform === 'semua'">
                  Untuk semua aplikasi
                </span>
                <span v-if="detail.platform === 'web'">
                  Untuk aplikasi Web
                </span>
                <span v-if="detail.platform === 'mobile'">
                  Untuk aplikasi Mobile
                </span>
              </div>
            </div>

            <v-divider style="border-width: 5px;"></v-divider>

            <div class="my-4 px-5">
              <div class="fs-14 font-weight-bold text-first mb-2" v-if="!process.detail">
                <span v-if="detail.item_type === 'course'">Kelas</span>
                <span v-if="detail.item_type === 'category'">Kategori</span>
                yang terdapat dalam diskon ini :
              </div>

              <v-card flat
                v-for="(item, index) in process.detail ? 8 :  detail.items"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.detail"
                  type="list-item-avatar-three-line">
                  <div>
                    <div v-if="detail.item_type === 'course'">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0">
                          <v-list-item-avatar tile size="80" class="mr-3">
                            <v-img 
                              style="border-radius: 10px;"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>

                            <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                              <span class="text-decoration-line-through text-second mr-2" v-if="item.discount_bool">
                                {{ item.price | price }}
                              </span>
                              <span class="font-weight-bold red--text">
                                <span v-if="item.discount_bool">
                                (-{{ parseInt(item.discount_percent) }}%)
                                </span>
                                <span class="text-first" v-if="parseInt(item.price) > 0">
                                  <span v-if="item.discount_bool">
                                    {{ item.price | price }}
                                  </span>
                                  <span v-if="!item.discount_bool">
                                    {{ item.price_discount | price }}
                                  </span>
                                </span>

                                <span class="green--text" v-else>
                                  Gratis
                                </span>
                              </span>
                            </p>
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                            <!-- <v-list-item-subtitle class="mt-1">
                              <div>
                                <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                              </div>
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                    <div v-if="detail.item_type === 'category'">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0">

                          <v-list-item-avatar tile size="80" class="mr-3">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url === ''"
                              :src="require('@/assets/images/content_image_empty_square.png')">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                            <!-- <div class="text-first body-2">
                              <span class="warning--text">Jika kelas di kategori ini terdapat diskon, maka akan diganti dengan diskon ya baru</span>
                            </div> -->
                            <!-- <div class="text-first body-2" v-if="form.id !== ''">
                              <span>Belum terdapat di diskon manapun</span>
                            </div> -->
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                            <!-- <v-list-item-subtitle class="mt-1">
                              <div>
                                <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                              </div>
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>

                  <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                </v-skeleton-loader>
              </v-card>
            </div>


          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn
              elevation="0"
              color="red"
              width="120"
              :disabled="process.detail"
              @click="deletes = detail; dialog.cancel = true;"
              class="text-capitalize white--text mr-2">
              Hapus
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="#4ab762"
              height="40"
              v-if="detail.program_admin_user_name !== 'superuser'"
              :disabled="process.detail"
              @click="reset(detail); dialog.form = true;"
              class="text-capitalize white--text">
              Ubah Diskon
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.detail"
              width="120"
              @click="dialog.detail = false"
              class="text-capitalize white--text mr-2">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-7">
          <v-col cols="9" class="pt-0">
            <!-- <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div> -->
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb_30'">
          <div class="text-second d-flex align-center" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 60%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari diskon…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-btn
              color="#3498db"
              elevation="0"
              height="45"
              width="120"
              @click="reset(); dialog.form = true"
              class="text-capitalize white--text ml-6">
              Tambah
            </v-btn>
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data diskon
          </div>
        </div>
        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0 px-0">
                <v-list-item class="py-0 px-0">
                  <!-- <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar> -->
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-skeleton-loader
                style="width: 100px; height: 40px;"
                :loading="process.run"
                type="image">
              </v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="button">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list class="py-0">
              <v-list-item class="mb-4 hover_select" style="background: #f9f9f9; border-radius: 15px; padding: 20px 20px; border: 1px solid #f9f9f9;">
                <v-list-item-content class="py-0">
                  <v-row>
                    <!-- <v-col cols="2" class="pr-0">
                      <v-list style="background: transparent;">
                        <v-list-item class="py-0 px-0">
                          <v-list-item-avatar
                            size="120"
                            tile
                            class="mt-0">
                            <v-img 
                              :src="l.voucher_image_url" style="border-radius: 15px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-col> -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-list style="background: transparent;" class="py-0">
                            <v-list-item class="pr-0 pt-0">
                              <!-- <v-list-item-avatar
                                size="148"
                                tile
                                class="mt-0 mr-4">
                                <v-img 
                                  :src="l.voucher_image_url" style="border-radius: 15px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar> -->
                              <v-list-item-content class="pt-0">
                                <div class="fs-14 text-second text--darken-2 mb-1">
                                  Nama Diskon
                                </div>
                                <div class="font-weight-bold text-first text--darken-2" style="line-height: 26px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
                                  {{ l.name }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <div class="text-first d-flex align-center" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1 justify-start' : 'body-2'">
                            <!-- <v-list style="background: transparent;">
                              <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                                <v-list-item-content class="py-0" style="min-width: 100px;">
                                  <div class="body-2 body-1 text-second text-right text--darken-2 ">
                                    <div class="fs-14 text-second text--darken-2 mb-1">
                                      Min. Transaksi
                                    </div>
                                    <div class="font-weight-bold">
                                      {{ l.voucher_min_transaksi === '' ? '-' : l.voucher_min_transaksi}}
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list> -->
                            <!-- <v-list style="background: transparent;" class="py-0">
                              <v-list-item class="py-0 pr-0">
                                <v-list-item-content class="py-0">
                                  <div class="body-2 body-1 text-second text-left text--darken-2 ">
                                    <div class="fs-14 text-second text--darken-2 mb-1">
                                      Tanggal
                                    </div>
                                    <div class="text-first font-weight-bold fs-17">
                                      {{ l.start_datetime | datetimemonth }} - {{ l.end_datetime | datetimemonth }}
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list> -->
                            <v-list-item  style="border-right: 1px solid #ebebeb; max-width: 100px;" class="py-0">
                              <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-left color-blue-first">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Diskon
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ l.percent }}%
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0">
                              <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-left color-blue-first">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Kelas
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ l.item_array.length }}
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <div class="fs-14 text-second text--darken-2 mb-1">
                            Tanggal Diskon
                          </div>
                          <div class="text-first font-weight-bold fs-17">
                            {{ l.start_datetime | datetimemonth }} - {{ l.end_datetime | datetimemonth }}
                          </div>

                          <v-list style="background: transparent;" class="py-4">
                            <!-- <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0">
                              <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-right color-blue-first">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Diskon
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ l.percent }}%
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item> -->
                            <!-- <v-list-item class="py-0 pr-0">
                              <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-second text-right text--darken-2 ">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Tanggal
                                  </div>
                                  <div class="text-first font-weight-bold fs-17">
                                    {{ l.start_datetime | datetimemonth }} - {{ l.end_datetime | datetimemonth }}
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item> -->
                          </v-list>
                          
                          <div class="d-flex align-center justify-end" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
                            <v-icon @click="deletes = l; dialog.cancel = true;" size="20" color="red">mdi-block-helper</v-icon>
                            <v-btn
                              outlined
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#9e9e9e"
                              height="40"
                              v-if="l.program_admin_user_name !== 'superuser'"
                              @click="reset(l); dialog.form = true;"
                              class="text-capitalize ml-4">
                              Ubah Diskon
                            </v-btn>
                            <div v-if="l.program_admin_user_name === 'superuser'" class="ml-4" style="width: 180px;">
                              <div v-if="l.program_status === 'approved'" class="green--text">
                                <i>Diskon Dapat Digunakan</i>
                              </div>
                              <div v-if="l.program_status === ''" class="warning--text">
                                <i>Menunggu Persetujuan</i>
                              </div>
                              <div v-if="l.program_status === 'rejected'" class="red--text">
                                <i>Diskon di tolak</i>
                              </div>
                            </div>
                            <v-btn
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              height="40"
                              @click="fetchDetail(l.id); dialog.detail = true;"
                              class="text-capitalize white--text ml-4">
                              Detail Diskon
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import 'moment/locale/id'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          form: false,
          info: false,
          cancel: false,
          product: false,
          detail: false
        },
        date: {
          start: false,
          end: false
        },
        time: {
          start: false,
          end: false
        },
        filter: {
          title: "",
          search: ""
        },
        detail: {},
        avatar: null,
        form: {
          id: "",
          diskon: "",
          name: "",
          date_start: "",
          time_start: "",
          date_end: "",
          time_end: "",
          platform: "",
          product: [],
          type: 'category',
          active_bool: true
        },
        allDiskon: false,
        selected: {
          product: []
        },
        snackbar: false,
        snackbar_warning: false,
        timeout: 3000,
        deletes: {},
        kelas: [],
        category: [],
        contentEmptyProduct: "",
        contentEmpty: "",
        item_array: [],
        list: [],
        pagination: {},
        process: {
          run: false,
          avatar: false,
          form: false,
          category: false,
          cancel: false,
          detail: false
        },
        success: {
          message: ""
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/discount',
          title: 'Pengaturan Diskon',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      reminder () {
        let now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate())
        return next.toISOString().substr(0, 10)
      },
      reminder_time () {
        let today = new Date()
        return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      },
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    watch: {
      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.img_url = ''
          }
        },
        deep: true
      }
    },
    mounted () {
      this.fetchClass()
      this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search
        }
        this.$axios.$get(`api/publisher/course_discount`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            if (this.filter.search !== '' || this.filter.search !== null) {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail (item) {
        this.process.detail = true
        let params = {
          id: item
        }
        this.$axios.$get(`api/publisher/course_discount/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      fetchClass () {
        this.process.kelas = true
        let params = {
          limit: 10000000000000,
          page: 1,
          search: this.filter.title,
          // 'group_id[in]': `0,${this.form.id}`,
          // 'id[nin]':this.form.product.map(e => e.id).join(",")
        }
        this.$axios.$get(`api/publisher/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.kelas = false
            if (this.filter.title !== '' || this.filter.title !== null) {
              this.contentEmptyProduct = "Data tidak tersedia"
            } else {
              this.contentEmptyProduct = "Data belum tersedia"
            }
            this.kelas = response.results.data
            this.allDiskon = false
            // for (let i in response.results.data) {
            //   if (response.results.data[i].group_name === '') {
            //     let ArrayData = []
            //     ArrayData.push(response.results.data[i])
            //     // this.allDiskon = false
            //     console.log(ArrayData)
            //   }
            // }
          }else{
            this.process.kelas = false
          }
        })
      },
      fetchCategory () {
        this.process.category = true
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/publisher/course_category`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.category = false
            this.category = []
            for (let i in response.results.data) {
              this.category.push(
                {
                  id: response.results.data[i].id,
                  img_url: response.results.data[i].img_url,
                  name: response.results.data[i].name
                }
              ) 
              
            }
          }else{
            this.process.category = false
          }
        })
      },

      checkAllCategory() {
        if (this.selected.product.length < 1) {
          this.checkForCegory()
        } else {
          if (this.selected.product.length !== this.category.length) {
            this.checkForCegory()
          } else {
            this.selected.product = []
          }
        }

        this.selected.product = [...new Set(this.selected.product)]
      },
      checkForCegory() {
        for (let i = 0; i < this.category.length; i++) {
          this.selected.product.push(this.category[i])
        }
      },
      checkAll() {
        if (this.selected.product.length < 1) {
          this.checkFor()
        } else {
          if (this.selected.product.length !== this.kelas.length) {
            this.checkFor()
          } else {
            this.selected.product = []
          }
        }

        this.selected.product = [...new Set(this.selected.product)]
      },
      checkFor() {
        console.log('asd')
        for (let i = 0; i < this.kelas.length; i++) {
          this.selected.product.push(this.kelas[i])
        }
      },
      addClassToDiskon() {
        if (this.form.id === '') {
          this.form.product = this.selected.product
        } else if (this.form.id !== '') {
          for (let i = 0; i < this.selected.product.length; i++) {
            this.form.product .push(this.selected.product[i])
          }
          this.selected.product = []
        }
        this.dialog.product = false
      },
      deleteProduct(index) {
        this.form.product.splice(index, 1)
      },

      reset(item) {
        this.process.form = false
        this.error.message = false
        let array_item = []
        if (item !== undefined) {
          for (let i = 0; i < this.kelas.length; i++) {
            for (let j = 0; j < item.item_array.length; j++) {
              if (this.kelas[i].id === item.item_array[j]) {
                array_item.push(this.kelas[i])
              }
            }
          }
        }
        this.form = {
          id: item !== undefined ? item.id : "",
          diskon: item !== undefined ? item.percent : "",
          name: item !== undefined ? item.name : "",
          date_start: item !== undefined ? this.$callbackdate(item.start_datetime) : "",
          time_start: item !== undefined ? this.$callbacktime(item.start_datetime) : "",
          date_end: item !== undefined ? this.$callbackdate(item.end_datetime) : "",
          time_end: item !== undefined ? this.$callbacktime(item.end_datetime) : "",
          platform: item !== undefined ? item.platform : "",
          product: item !== undefined ? array_item : [],
          type: item !== undefined ? item.item_type : 'category',
          active_bool: item !== undefined ? item.active_bool : true
        }
      },

      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        this.item_array = []
        for (let i = 0; i < this.form.product.length; i++) {
          this.item_array.push(this.form.product[i].id)
        }
        if (isValid) {
          if (this.form.id === '') {
            if (this.form.product.length > 0) {
              this.creates()
            } else {
              this.error.message = "Harap pilih kelas atau kategori terlebih dahulu"
            }
          } else {
            this.updates()
          }
        } else {
          this.error.message = "Harap cek kembali inputan Anda"
        }
      },
      async creates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_discount`, 
        {
          item_type: this.form.type,
          item_array: this.item_array,
          name: this.form.name,
          platform: this.form.platform,
          percent: this.form.diskon,
          start_date: this.form.date_start,
          start_time: `${this.form.time_start}:00`,
          end_date: this.form.date_end,
          end_time:`${this.form.time_end}:00`,
          active_bool: true
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch()
            this.dialog.form = false
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_discount/update`, 
        {
          id: this.form.id,
          name: this.form.name,
          platform: this.form.platform,
          percent: this.form.diskon,
          start_date: this.form.date_start,
          start_time: `${this.form.time_start}:00`,
          end_date: this.form.date_end,
          end_time:`${this.form.time_end}:00`,
          active_bool:this.form.active_bool
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.fetch()
            this.fetchDetail(this.form.id)
            this.dialog.form = false
            this.reset()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async toDeletes() {
        this.process.cancel = true
        await this.$axios.$post(`api/publisher/course_discount/delete`, 
        {
          id: this.deletes.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cancel = false
            this.dialog.detail = false
            this.dialog.cancel = false
            this.fetch()
            this.success.message = "Berhasil menghapus diskon"
            this.snackbar = true
          } else {
            this.process.cancel = false
            this.error.message = response.message
          }
        })
      }
    }
  }
</script>

