<template>
  <div>
    <DialogAuth :dialogauth="toLogout" @close="toLogout = ''" />
    <div style="height: 570px;">

       <section style="padding: 0px 30px 23px 30px;">
        <div class="d-flex align-center">
          <div>
            <div class="text-second font-weight-bold" :class="fps !== 'md' && fps !== 'lg' && fps !== 'xl' ? 'body-1' : 'body-1'">
              Daftar Organisasi
            </div>
            <div class="text-second" :class="fps !== 'md' && fps !== 'lg' && fps !== 'xl' ? 'body-2' : 'caption'">
              Dartar akun organinsasi yang anda ikuti.
            </div>
          </div>
          <!-- <div class="ml-auto">
            <v-btn
              color="#3498db"
              elevation="0"
              :disabled="list.length >= 3"
              @click="reset()"
              class="text-capitalize white--text ml-6">
              Tambah Rekening
            </v-btn>
          </div> -->
        </div>
      </section>

      <section :class="fps !== 'md' && fps !== 'lg' && fps !== 'xl' ? '' : ''" style="height: 485px;  overflow-y: auto;">
        <div v-if="process.run" style="padding: 15px 30px 0px 30px;">
          <v-skeleton-loader
            type="list-item-avatar"
            v-for="l in 10"
            :key="l"
            class="mb-3"
            :loading="process.run">
          </v-skeleton-loader>
        </div>
        <div v-if="!process.run" style="padding: 15px 30px 0px 30px;">
          <!-- <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list style="background: transparent;">
              <v-list-item class="py-0 pl-0">
                <v-list-item-avatar
                  width="50"
                  color="#ebebeb"
                  height="50"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''"
                  class="align-self-start">
                  <v-img v-if="l.img_url !== ''" :src="l.img_url" contain></v-img>
                  <v-icon v-if="l.img_url === ''">mdi-office-building</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <div class="body-2 font-weight-bold body-1 text-second text--darken-2 ">
                    {{ l.name }}
                  </div>
                  <div class="d-flex align-center body-2 text-second">
                    <div class="text-first font-weight-bold">
                      {{ l.review_average }}
                    </div>
                    <div class="mx-2">
                      <v-rating
                        :value="l.review_average*1"
                        background-color="grey"
                        color="#e67e22"
                        dense
                        readonly
                        half-increments
                        hover
                        size="20">
                      </v-rating>
                    </div>
                  </div>
                </v-list-item-content>
                <v-list-item-content class="d-flex align-center justify-end" style="max-width: 280px;">
                  <v-btn
                    fab
                    max-width="40"
                    height="40"
                    v-if="l.url_web !== ''"
                    :href="`${l.url_web}`" 
                    target="_blank"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="primary">
                      mdi-link
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    max-width="40"
                    height="40"
                    v-if="l.url_youtube !== ''"
                    :href="`https://www.facebook.com/${l.url_youtube}`" 
                    target="_blank"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="red">
                      mdi-youtube
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    max-width="40"
                    height="40"
                    v-if="l.url_instagram !== ''"
                    :href="`https://www.instagram.com/${l.url_instagram}`" 
                    target="_blank"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="#bc2a8d">
                      mdi-instagram
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    max-width="40"
                    height="40"
                    v-if="l.url_twitter !== ''"
                    :href="`https://twitter.com/${l.url_twitter}`" 
                    target="_blank"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="#1DA1F2">
                      mdi-twitter
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    max-width="40"
                    height="40"
                    v-if="l.url_facebook !== ''"
                    :href="`https://www.facebook.com/${l.url_facebook}`" 
                    target="_blank"
                    elevation="0"
                    class="mx-1">
                    <v-icon color="#0077b5">
                      mdi-facebook
                    </v-icon>
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div> -->

          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : 'px-3'">
            <v-row class="px-4 mb-7 align-center" style="border: 1px solid #ebebeb; border-radius: 8px; background-color: #FFFFFF;">
              <v-col cols="12" md="6" class="pr-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
                <v-list-item class="py-0 pl-0">
                  <v-list-item-avatar
                    width="50"
                    color="#ebebeb"
                    height="50"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''"
                    class="align-self-start">
                    <v-img v-if="l.img_url !== ''" :src="l.img_url" contain></v-img>
                    <v-icon v-if="l.img_url === ''">mdi-office-building</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <div class="body-2 font-weight-bold body-1 text-second text--darken-2 ">
                      {{ l.name }}
                    </div>
                    <div class="d-flex align-center body-2 text-second">
                      <div class="text-first font-weight-bold">
                        {{ l.review_average }}
                      </div>
                      <div class="mx-2">
                        <v-rating
                          :value="l.review_average*1"
                          background-color="grey"
                          color="#e67e22"
                          dense
                          readonly
                          half-increments
                          hover
                          size="20">
                        </v-rating>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="text-right" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'pl-0'">
                <v-btn
                  fab
                  max-width="40"
                  height="40"
                  :disabled="l.url_web !== '' ? false : true"
                  :href="`${l.url_web}`" 
                  target="_blank"
                  elevation="0"
                  class="mx-1">
                  <v-icon color="primary">
                    mdi-link
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  max-width="40"
                  height="40"
                  :disabled="l.url_youtube !== '' ? false : true"
                  :href="`${l.url_youtube}`" 
                  target="_blank"
                  elevation="0"
                  class="mx-1">
                  <v-icon color="red">
                    mdi-youtube
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  max-width="40"
                  height="40"
                  :disabled="l.url_instagram !== '' ? false : true"
                  :href="`${l.url_instagram}`" 
                  target="_blank"
                  elevation="0"
                  class="mx-1">
                  <v-icon color="#bc2a8d">
                    mdi-instagram
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  max-width="40"
                  height="40"
                  :disabled="l.url_twitter !== '' ? false : true"
                  :href="`${l.url_twitter}`" 
                  target="_blank"
                  elevation="0"
                  class="mx-1">
                  <v-icon color="#1DA1F2">
                    mdi-twitter
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  max-width="40"
                  height="40"
                  :disabled="l.url_facebook !== '' ? false : true"
                  :href="`${l.url_facebook}`" 
                  target="_blank"
                  elevation="0"
                  class="mx-1">
                  <v-icon color="#0077b5">
                    mdi-facebook
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          style="padding: 0px 30px 0px 30px;"
          v-show="pagination.total_page > 1"/>
      </section>

    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          form: false,
          delete: false
        },
        toLogout: '',
        list: [],
        pagination: {},
        detail: {},
        contentEmpty: "Data belum tersedia",
        process: {
          run: false,
          form: false
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        },
        content: {
          url: '/account/setting',
          title: 'Oraganisasi',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      Empty: () => import('@/components/DataEmpty'),
      Pagination: () => import('@/components/Pagination'),
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      detailProfile: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'detailProfile': function(val) {
        this.form = val
      },
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p
        }
        this.$axios.$get(`api/publisher/organization`, { params })
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.process.run = false
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      getKarekter() {
        let name = this.$store.state.member.auth.user.name
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        // return initial.toUpperCase()
      },
    },
  };
</script>