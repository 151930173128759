<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
        <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.detail"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.detail"
        width="550">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Detail Ulasan
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.detail = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);" class="px-0">
            <div v-if="process.detail" class="mt-3">
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
            </div>
            <div v-if="!process.detail" class="my-2">
              <div class="px-5">
                <v-expansion-panels flat v-model="panel_class">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-0">
                      <div style="font-weight: 900; font-size: 16px; line-height: 19px; color: #505050;">
                        Informasi Produk / Kelas
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="non-padding-expansion">
                      <v-list three-line style="background: transparent;" class="py-0">
                        <v-list-item class="py-0 px-0">
                          <v-list-item-avatar
                            size="80"
                            class="my-0"
                            tile>
                            <v-img 
                              :src="detail.course_img_url" style="border-radius: 15px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                          <v-list-item-content class="pb-0 pt-0">
                            <v-list-item-subtitle class="text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'caption'">
                              {{ detail.course_name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-divider style="border-width: 5px;"></v-divider>
              <div class="px-5">
                <v-expansion-panels flat v-model="panel_ulasan">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-0">
                      <div style="font-weight: 900; font-size: 16px; line-height: 19px; color: #505050; opacity: 0.6;">
                        Ulasan Pembeli
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="non-padding-expansion">
                      <div style="font-size: 14px; line-height: 16px; color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                        Usename / Nama
                      </div>
                      <div style="font-weight: 900; font-size: 18px; line-height: 21px; color: #505050;" class="mt-1">
                        {{ detail.user_name }}
                      </div>
                      <div class="d-flex align-center body-2 text-second" style="margin-top: 29px; margin-bottom: 10px;">
                        <div class="text-first font-weight-bold">
                          {{ detail.rate }}
                        </div>
                        <div class="mx-2">
                          <v-rating
                            :value="detail.rate*1"
                            background-color="white"
                            color="#F39C12"
                            dense
                            half-increments
                            hover
                            size="20">
                          </v-rating>
                        </div>
                      </div>
                      <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.8;">
                        {{ detail.text }}
                      </div>
                      <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-top: 5px;">
                        {{ detail.last_update_datetime | datetimemonth }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-divider style="border-width: 5px;"></v-divider>
              <div class="px-5">
                <v-expansion-panels flat v-model="panel_balas">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-0">
                      <div style="font-weight: 900; font-size: 16px; line-height: 19px; color: #505050; opacity: 0.6;">
                        Balasan Anda
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="non-padding-expansion">
                      <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.8;" v-if="detail.reply_text !== ''">
                        {{ detail.reply_text }}
                      </div>
                      <span v-if="detail.reply_text === ''" style="text-align: right; color: #F39C12;">
                        Anda belum membalas ulasan ini
                      </span>
                      <div class="fs-12" v-if="detail.reply_text !== ''" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-top: 5px;">
                        {{ detail.reply_datetime | datetimemonth }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.replay"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.replay"
        width="550">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Balas Ulasan
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.replay = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="mt_25 mb-5">
            <div>
              <ValidationObserver ref="observer">
                <div class="d-flex align-center text-first">
                  <div class="fs-16 mb-2" style="color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                    Balasan Anda
                  </div>
                </div>
                <ValidationProvider name="Balasan" rules="required|max:1000" v-slot="{ errors }">
                  <v-textarea
                    color="#4ab762"
                    rows="2"
                    placeholder="Tuliskan disini"
                    :disabled="process.form"
                    v-model="form.desc"
                    class="pt-0 mt-0"
                    :counter="1000"
                    v-on:keyup.enter="save()"
                    :messages="'Wajib'"
                    :error-messages="errors"
                    require>
                    <template v-slot:message="{ message }">
                      <span class="red--text" v-html="message"></span>
                    </template>
                    <template v-slot:counter="{ props }">
                      <span style="font-size: 9px;" class="text-second">
                        {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                      </span>
                    </template>
                  </v-textarea>
                </ValidationProvider>
              </ValidationObserver>
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-alert 
              type="error" 
              text
              dense
              class="mb-0 mr-2 px-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="190"
              :disabled="process.form"
              :loading="process.form"
              @click="save()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Balas Ulasan
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              :disabled="process.form"
              @click="dialog.replay = false"
              class="text-capitalize white--text">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-2">
          <v-col :cols="9" class="pt-0">
            <div class="d-flex align-center" style="height: 100%;">
              <div class="mr-3" style="height: 98%;">
                <v-btn
                  outlined
                  small
                  min-width="30"
                  height="100%"
                  class="px-0"
                  style="background-color: #FFFFFF"
                  to="/account/rating"
                  color="red">
                  <v-icon color="red">mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
                  <span>Ulasan dan Penilaian kelas {{ !process.run && list && list.length > 0 ? list[0].course_name : '' }}</span>
                </div>
                <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
                  <span>Lihat ulasan dan penilaian kelas elaearning yang Anda jual.</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="43" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
      </section>

      <section>
        <v-card-text class="pa-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'mb_20 mt_25'">

          <v-row class="px-3 mb-5" v-if="!process.run && list && list.length > 0">
            <v-col cols="12" md="9" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0 py-0' : 'pt-0'">
              <v-list style="background: transparent;" class="py-0">
                <v-list-item class="py-0 pl-0">
                  <v-list-item-avatar
                    size="98"
                    class="my-0"
                    tile>
                    <v-img 
                      :src="list[0].course_img_url" style="border-radius: 15px;"
                      v-if="list[0].course_img_url !== ''">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#4ab762">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <div class="font-weight-bold body-1 text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      {{ list[0].course_name }}
                    </div>
                    <div class="text-first body-1 font-weight-bold mt-2 d-flex">
                      <v-list style="background: transparent; width: 120px;">
                        <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                          <v-list-item-content class="py-0">
                            <div class="font-weight-bold body-1 text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                Jumlah Rating
                              </div>
                              {{ all_data }}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list style="background: transparent; width: 180px;" class="ml-6">
                        <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                          <v-list-item-content class="py-0">
                            <div class="font-weight-bold body-1 text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                Perlu Ditanggapi
                              </div>
                              {{ data_review.not_replied_count }}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list style="background: transparent; width: 180px;" class="ml-6">
                        <v-list-item class="py-0 pl-0">
                          <v-list-item-content class="py-0">
                            <div class="font-weight-bold body-1 text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                              <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                Sudah Ditanggapi
                              </div>
                              {{ data_review.replied_count }}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="9" class="pt-0">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.search"
                v-on:keyup.enter="fetch()"
                @click:clear="filter.search = ''; fetch();"
                :placeholder="`Cari nama kelas…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <!-- <v-btn
                color="#3498db"
                elevation="0"
                height="45"
                block
                to="/account/product/form"
                class="text-capitalize white--text">
                Tambah
              </v-btn> -->
              <v-select
                :items="[
                  {
                    name:'Hari Ini',
                    id:'today'
                  },
                  {
                    name:'Kemarin',
                    id:'yesterday'
                  },
                  {
                    name:'Minggu Ini',
                    id:'last_week'
                  },
                  {
                    name:'Bulan Ini',
                    id:'last_month'
                  },
                  {
                    name:'Pilih Tanggal',
                    id:'date'
                  }
                ]"
                solo
                v-on:change="fetch()"
                color="#4ab762"
                placeholder="Pilih kategori disini"
                item-text="name"
                item-value="id"
                item-color="green"
                return-object
                v-model="filter.periode">
                <template v-slot:selection="data">
                  <div v-if="filter.periode.id !== 'date'">
                    {{ data.item.name }}
                  </div>
                  <div v-if="filter.periode.id === 'date'">
                    Tanggal
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div v-if="item.id === 'date'" style="display: contents;">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="filter.date"
                      persistent
                      width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs"
                          v-on="on" class="py-0" style="width: 100%;">
                          <div class="d-flex align-center" style="width: 100%;" v-if="filter.periode.id !== 'date'">
                            Pilih Tanggal
                          </div>
                          <div class="d-flex align-center" style="width: 100%;" v-if="filter.periode.id === 'date'">
                            Ubah Tanggal
                          </div>
                        </v-list-item-content>
                      </template>
                      <v-date-picker
                        v-model="filter.date"
                        range
                        color="#4ab762"
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="#4ab762"
                          @click="modal = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="#4ab762"
                          @click="$refs.dialog.save(filter.date); fetch(); filter.periode = {
                            name:'Pilih Tanggal',
                            id:'date'
                          }">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <div v-if="item.id !== 'date'" @click="filter.date = [$callbackdate(today)]">
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- <div class="text-second mt_20" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : ''" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data kelas
          </div> -->
        </v-card-text>

        <div v-if="!loader">
          <v-card-text class="py-3 px-0">
            <div style="font-weight: 900; font-size: 16px; line-height: 19px; color: #505050; mix-blend-mode: normal; opacity: 0.8;" class="mb-2">
              Rata-rata rating
            </div>
            <div class="d-flex align-center mb-5">
              <v-icon color="#F39C12" size="40">mdi-star</v-icon>
              <div style="font-weight: 900; font-size: 50px; line-height: 59px;color: #505050;" class="ml-2">
                {{ data_review.review_rate_avg }}<span style="font-weight: 900; font-size: 26px; line-height: 30px; color: #505050; mix-blend-mode: normal; opacity: 0.5;"> / 5</span>
              </div>
              <div class="ml-4">
                <div style="font-size: 14px; line-height: 19px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
                  {{ pagination.total_data }} Ulasan
                </div>
                <div style="font-size: 14px; line-height: 19px; color: #505050; mix-blend-mode: normal; opacity: 0.8;">
                  <span v-if="filter.periode.id === 'today'">
                    Hari Ini ({{ new Date() | dateTanggalMonth }})
                  </span>
                  <span v-if="filter.periode.id === 'yesterday'">
                    <!-- 7 Hari Terakhir (Periode 3 Agu 2021 - Hari ini) -->
                    Kemarin ({{ yesterday | dateTanggalMonth }})
                  </span>
                  <span v-if="filter.periode.id === 'last_week'">
                    Minggu ini ({{ week_start | dateTanggalMonth }} - {{ week_last | dateTanggalMonth }})
                  </span>
                  <span v-if="filter.periode.id === 'last_month'">
                    <!-- 7 Hari Terakhir (Periode 3 Agu 2021 - Hari ini) -->
                    Bulan ini ({{ mounth_start | dateTanggalMonth }} - {{ mounth_last | dateTanggalMonth }})
                  </span>
                  <span v-if="filter.periode.id === 'date'">
                    <span v-if="filter.date[1] !== undefined">
                      {{ filter.date[0] < filter.date[1] ? filter.date[0] : filter.date[1] | dateTanggalMonth }} - {{ filter.date[0] > filter.date[1] ? filter.date[0] : filter.date[1] | dateTanggalMonth }}
                    </span>
                    <span v-if="filter.date[1] === undefined">
                      {{ filter.date[0] | dateTanggalMonth }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <v-tabs
              background-color="transparent"
              color="grey darken-3"
              v-model="filter.menu"
              dense
              v-on:change="fetch()"
              grow>
              <v-tabs-slider color="#4ab762"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in menu"
                :key="index"
                class="px-0 mr-2"
                style="min-width: 55px; max-width: 140px;">
                <div>
                  <div class="body-1 text-capitalize" :class="filter.menu === index ? 'font-weight-bold' : 'text-third'">
                    {{ item === 'Semua' ? `Semua (${(all_data)})` :  item === 'Perlu Dibalas' ? `Perlu Dibalas (${(data_review.not_replied_count)})` :  item === 'Sudah Dibalas' ? `Sudah Dibalas (${(data_review.replied_count)})` : item }}
                  </div>
                </div>
              </v-tab>
            </v-tabs>

            <div class="mb_20 mt_25">
              <v-row>
                <v-col cols="12" md="2" v-for="(l, i) in rate" :key="i">
                  <v-btn
                    :color="filter.rate !== l.value ? '#E3E3E3':'#72CE86'"
                    outlined
                    block
                    @click="filter.rate = l.value; fetch()"
                    elevation="0"
                    class="text-capitalize">
                    {{ l.text }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </div>

        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 5" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0">
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      class="mx-auto"
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.detail"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <div class="text-right ml-auto mt-5">
                        <v-skeleton-loader
                          :loading="process.run"
                          type="paragraph">
                        </v-skeleton-loader>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="chip">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="chip">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="chip">
                </v-skeleton-loader>
              </div>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  style="width: 100px; height: 40px;"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
                <v-skeleton-loader
                  style="width: 100px; height: 40px;"
                  class="ml-5"
                  :loading="process.detail"
                  type="image">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-card-text class="py-3 px-0">

            <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : 'mb-7 px-3'">
              <v-row  class="border_radius_7 hover_select" style="padding: 17px 8px 23px 8px;" :style="'background: #f9f9f9; border: 1px solid #ebebeb;'">
                <v-col cols="12" class="pt-0 pb-2">
                  <div class="text-right fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                    No. Pesanan: {{ l.transaction_number }}
                  </div>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-list three-line style="background: transparent;" class="py-0">
                    <v-list-item class="py-0 px-0">
                      <v-list-item-avatar
                        size="80"
                        class="my-0"
                        tile>
                        <v-img 
                          :src="l.user_img_url" v-if="l.user_img_url !== ''" style="border-radius: 15px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <div v-if="l.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 80px; height: 80px;" class="rounded-lg">
                          <v-row
                            class="fill-height ma-0 fs-28"
                            align="center"
                            justify="center"
                          >
                            {{ getKarekter(l.user_name) }}
                          </v-row>

                        </div>
                      </v-list-item-avatar>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-subtitle class="text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'caption'">
                          {{ l.user_name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4" class="py-0" style="border-left: 1px solid #ebebeb;">
                  <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-bottom: 7px;">
                    Penilaian Pembeli
                  </div>
                  <div>
                    <div class="d-flex align-center body-2 text-second">
                      <div class="text-first font-weight-bold">
                        {{ l.rate }}
                      </div>
                      <div class="mx-2">
                        <v-rating
                          :value="l.rate*1"
                          background-color="grey"
                          color="#F39C12"
                          dense
                          half-increments
                          hover
                          size="20">
                        </v-rating>
                      </div>
                    </div>
                    <div>
                      <span v-if="l.text !== ''" style="color: #505050;" class="two-line">
                        {{ l.text }}
                      </span>
                    </div>
                    <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-top: 5px;">
                      {{ l.created_datetime_readable }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="5" class="text-right py-0" style="border-left: 1px solid #ebebeb;">
                  <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-bottom: 7px;">
                    Balasan Anda
                  </div>
                  <div>
                    <div class="fs-14">
                      <span v-if="l.reply_text !== ''" style="color: #505050;" class="two-line">
                        {{ l.reply_text }}
                      </span>
                      <span v-if="l.reply_text === ''" style="text-align: right; color: #F39C12;">
                        Anda belum membalas ulasan ini
                      </span>
                    </div>
                    <div class="fs-12" v-if="l.reply_text !== ''" style="color: #505050; mix-blend-mode: normal; opacity: 0.6; margin-top: 5px;">
                      {{ l.reply_datetime }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <!-- <div class="fs-14" style="color: #505050; mix-blend-mode: normal; opacity: 0.6;">
                    Username: {{ l.user_name }}
                  </div> -->
                </v-col>
                <v-col cols="6" class="pb-0 text-right">
                  <v-btn
                    outlined
                    elevation="0"
                    height="40"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    style="background: #fff;"
                    @click="fetchDetail(l); dialog.detail = true;"
                    color="#9e9e9e"
                    class="text-capitalize ml-3">
                    Lihat Detail
                  </v-btn>
                  <v-btn
                    outlined
                    elevation="0"
                    height="40"
                    v-if="l.reply_text === ''"
                    @click="detail = l; dialog.replay = true; form.desc = '';"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 150"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    style="background: #fff;"
                    color="#3498DB"
                    class="text-capitalize ml-3">
                    Balas
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>


          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  import moment from "moment"
  import 'moment/locale/id'
  export default {
    middleware: false,
    data () {
      return {
        page: 1,
        dialog: {
          detail: false,
          replay: false
        },
        modal: false,
        menu: ['Semua', 'Perlu Dibalas', 'Sudah Dibalas'],
        rate: [
          {
            text: "Semua",
            value: ""
          },
          {
            text: "5 Bintang",
            value: "5"
          },
          {
            text: "4 Bintang",
            value: "4"
          },
          {
            text: "3 Bintang",
            value: "3"
          },
          {
            text: "2 Bintang",
            value: "2"
          },
          {
            text: "1 Bintang",
            value: "1"
          },
        ],
        panel_class: 0,
        panel_balas: 0,
        panel_ulasan: 0,
        filter: {
          menu: 0,
          date: "",
          rate: "",
          search: "",
          periode: {
            name:'Bulan Ini',
            id:'last_month'
          },
          date:[]
        },
        form: {
          desc: ""
        },
        all_data: 0,
        loader: true,
        data_review:{},
        snackbar: false,
        timeout: 5000,
        contentEmpty: "",
        detail: {},
        list: [],
        pagination: {},
        process: {
          form: false,
          run: true,
          detail: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        // SEO
        content: {
          url: '/account/product',
          title: 'Produk Elearning',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      today () {
        var now = new Date()
        this.filter.date = [this.$callbackdate(now)]
        return now
      },
      yesterday () {
        var now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate()-1)
        return next.toISOString().substr(0, 10)
      },
      week_start () {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        return new Date(curr.setDate(first));
      },
      week_last () {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        return new Date(curr.setDate(last));
      },
      mounth_start () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
      },
      mounth_last () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    mounted () {
      var now = new Date()
      this.filter.date = [this.$callbackdate(now)]
      this.fetch()
    },
    methods: {
      async fetch (p) {
        let params = {}
        this.process.run = true
        if (this.filter.menu === 0) {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            rate: this.filter.rate,
            sort: "-id",
            periode: this.filter.periode.id !== 'date' ? this.filter.periode.id : '',
            'created_datetime[gte]': this.filter.date[1] !== undefined ? this.filter.date[0] < this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'created_datetime[lte]': this.filter.date[1] !== undefined ? this.filter.date[0] > this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            course_id: this.$route.params.slug
          }
        } else if (this.filter.menu === 1) {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            rate: this.filter.rate,
            replied_bool: false,
            sort: "-id",
            periode: this.filter.periode.id !== 'date' ? this.filter.periode.id : '',
            'created_datetime[gte]': this.filter.date[1] !== undefined ? this.filter.date[0] < this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'created_datetime[lte]': this.filter.date[1] !== undefined ? this.filter.date[0] > this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            course_id: this.$route.params.slug
          }
        } else if (this.filter.menu === 2) {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            rate: this.filter.rate,
            replied_bool: true,
            sort: "-id",
            periode: this.filter.periode.id !== 'date' ? this.filter.periode.id : '',
            'created_datetime[gte]': this.filter.date[1] !== undefined ? this.filter.date[0] < this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            'created_datetime[lte]': this.filter.date[1] !== undefined ? this.filter.date[0] > this.filter.date[1] ? this.filter.date[0] : this.filter.date[1] : this.filter.date[0],
            course_id: this.$route.params.slug
          }
        }
        this.$axios.$get(`api/publisher/course_review`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            this.data_review = response.results
            if (this.filter.search !== '' || this.filter.search !== null || this.filter.category !== '' || this.filter.etalase !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            if (this.filter.menu === 0) {
              this.all_data = response.results.pagination.total_data
            }
            this.loader = false
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
      async fetchDetail (item) {
        this.process.detail = true
        let params = {
          id: item.id
        }
        this.$axios.$get(`api/publisher/course_review/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.detail = response.results.data
            this.process.detail = false
          }else{
            this.process.detail = false
          }
        })
      },
      reset() {
        this.error.message = ''
        this.form.desc = ""
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.process.form = true
          await this.$axios.$post(`api/publisher/course_review`, 
          {
            id: this.detail.id,
            text: this.form.desc
          })
          .then((response) => {
            if (response.status === 200) {
              this.process.form = false
              this.reset()
              this.fetch()
              this.fetchDetail(this.detail)
              this.dialog.replay = false
            } else {
              if (response.status === 412) {
                this.error.message = response.message
              } else {
                this.error.message = response.message
              }
              this.error.code = response.results.data.code
              this.process.form = false
            }
          })
        }
      }
    },
  };
</script>