<template>
  <div>
    <v-container>
      <Loading :detailMessage="loading_message" v-if="process.form"/>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -20px;"
        :color="color">
        <div class="body-1 font-weight-bold">{{ color === 'warning' ? warning.message : success.message }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Tutup
          </v-btn>
        </template>
      </v-snackbar>

      <section>
        <v-row class="mb-2">
          <v-col :cols="9" class="pt-0">
            <div class="d-flex align-center" style="height: 100%;">
              <div>
                <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
                  <span>Notifikasi</span>
                </div>
                <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
                  <span>Lihat notifikasi Anda.</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3" class="text-right pt-0 d-flex align-center justify-end">
            <div class="">
              <v-btn
                elevation="0"
                @click="readAll()"
                color="#4ab762"
                :disabled="parseInt(count) < 1"
                class="white--text text-capitalize">
                Tandai semua dibaca
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </section>

      <section>

        <div>
          <v-card-text class="py-3 px-0">

            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              :tile="false"
              type="list-item-avatar"
              v-for="(item, index) in process.run ? 10:list" 
              :key="index">
              <div>
                <v-card flat @click="toRead(item)">
                  <div class="d-flex align-center mb-4">
                    <v-card flat>
                      <!-- <v-avatar 
                        :size="$vuetify.breakpoint.name !== 'sm' && $vuetify.breakpoint.name !== 'xs' ? 40 : 30"
                        style="border: 3px solid #C05394; border-radius: 50%;"
                        class="cursor-pointer"
                        v-if="item.notification_content_jsonobject">
                        <v-img
                          :src="item.notification_content_jsonobject.data.user_image_url"
                          :alt="item.notification_content_jsonobject.data.user_username">
                        </v-img>
                      </v-avatar> -->
                      <v-btn
                        fab
                        text
                        width="45"
                        height="45">
                        <v-icon size="40" :color="item.read_bool ? 'grey' : 'yellow darken-4'">mdi-bell</v-icon>
                      </v-btn>
                    </v-card>
                    <div class="ml-4 mr-3">
                      {{ item.title }}
                      <span class="font-weight-bold cursor-pointer"> 
                        {{ item.text }}
                      </span>
                      <div class="caption">
                        <span class="grey--text">{{ item.created_datetime | datetime }}</span>
                      </div>
                    </div>
                    <!-- <v-img
                      max-width="50px"
                      min-width="50px"
                      height="45"
                      class="rounded ml-auto cursor-pointer"
                      v-if="item.notification_content_jsonobject && item.notification_content_jsonobject.type !== 'follow'"
                      :src="item.notification_content_jsonobject.data.content_image"
                      :lazy-src="item.notification_content_jsonobject.data.content_image">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img> -->
                  </div>
                </v-card>
              </div>
            </v-skeleton-loader>
          </v-card-text>


          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        page: 1,
        dialog: {
          detail: false,
          replay: false,
          discus: false
        },
        loading_message: "Loading",
        filter: {
          menu: 0,
          date: "",
          comment: "",
          search: "",
          periode: {
            name:'Bulan Ini',
            id:'last_month'
          },
          date:[]
        },
        count: 0,
        color: "",
        loader: false,
        snackbar: false,
        timeout: 5000,
        contentEmpty: "",
        list: [],
        pagination: {},
        process: {
          form: false,
          run: true,
          detail: false
        },
        success: {
          message: ""
        },
        warning: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        // SEO
        content: {
          url: '/account/notification',
          title: 'Notifikasi',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      today () {
        var now = new Date()
        this.filter.date = [this.$callbackdate(now)]
        return now
      },
      yesterday () {
        var now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate()-1)
        return next.toISOString().substr(0, 10)
      },
      week_start () {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        return new Date(curr.setDate(first));
      },
      week_last () {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        return new Date(curr.setDate(last));
      },
      mounth_start () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
      },
      mounth_last () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      Loading: () => import('@/components/Loading'),
    },
    mounted () {
      var now = new Date()
      this.fetch()
      this.fetchCountNotification()
    },
    methods: {
      async fetch (p) {
        let params = {}
        this.process.run = this.loader ? false : true
        params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          sort: '-id'
        }
        this.$axios.$get(`api/notification`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            if (this.filter.search !== '' || this.filter.search !== null || this.filter.category !== '' || this.filter.etalase !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            if (this.filter.menu === 0) {
              this.all_data = response.results.pagination.total_data
              this.data_review = response.results
            }
            this.loader = false
            this.loader_tab = false
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },

      async fetchCountNotification() {
        if (this.token) {
          this.$axios.$get(`api/notification/count`)
          .then((response) => {
            let res = response
            if (res.status === 200) {
              this.count = res.results.data.count_notification_unread
              this.$store.commit('header/setNotif', parseInt(res.results.data.count_notification_unread))
            }
          })
        }
      },

      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },

      async toRead(item) {
        let id = parseInt(item.id)
        await this.$axios.$post(`api/notification/read_bulk`, 
        {
          notification_id_array: [id]
        })
        .then((response) => {
          if (response.status === 200) {
            this.toLink(item)
            this.fetch()
            this.fetchCountNotification()
          } else {
            this.error.message = response.message
          }
        })
      },

      toLink(item) {
        if (item.from === 'course') {
          window.location = `/elearning/${item.course_id}`
        } else if (item.from === 'course_announcement') {
          window.location = `/collection/${item.user_course_id}`
        } else if (item.from === 'course_announcement_comment') {
          window.location = `/collection/${item.user_course_id}`
        } else if (item.from === 'course_review') {
          window.location = `/collection/${item.user_course_id}`
        } else if (item.from === 'course_discussion_comment') {
          window.location = `/collection/${item.user_course_id}`
        } else if (item.from === 'transaction') {
          window.location = '/account/report'
        } else if (item.from === 'discount_program_publisher') {
          window.location = '/account/admin-discount'
        } else if (item.from === 'course_announcement_comment_publisher') {
          window.location = `/account/announcement/${item.course_id}`
        } else if (item.from === 'course_review_publisher') {
          window.location = `/account/rating/${item.course_id}`
        } else if (item.from === 'course_discussion_publisher') {
          window.location = `/account/discussion/${item.course_id}`
        }
      },

      async readAll() {
        this.loading_message = "Membaca Semua Notifikasi..."
        this.process.form = true
        await this.$axios.$post(`api/notification/read_all`)
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.fetch()
            this.fetchCountNotification()
            this.color="#27ae60"
            this.success.message="Membaca Semua Notifikasi"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>