<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
        <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.pembayaran"
        v-model="dialog.pembayaran"
        width="450">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
              Cara Pembayaran
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.pembayaran = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>

          <v-card-text class="px-0">
            <div class="px-5 pb-0">
              <div class="d-flex align-center py-4" v-if="detail.payment_va_number !== ''">
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                  <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                    Nomor Virtual Account
                  </div>
                  {{ detail.payment_va_number }}
                </div>
                <div class="ml-auto">
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.payment_va_number)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                </div>
              </div>

              <v-divider v-if="detail.payment_va_number !== ''"></v-divider>

              <div class="d-flex align-center py-4">
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                  <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                    Total Pembayaran
                  </div>
                  {{ detail.total_paid_nett | price }}
                </div>
                <div class="ml-auto">
                <v-btn 
                  class="text-capitalize"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                  color="#4ab762" 
                  text
                  @click="copyTestingCode(detail.total_paid_nett)"
                  elevation="0">
                  <span>
                    Salin
                  </span>
                </v-btn>
                </div>
              </div>
            </div>

            <v-divider style="border-width: 5px;"></v-divider>

            <div class="px-0">
              <div v-if="detail.payment_detail_object.qrcode_url !== undefined">
                <v-img width="300" class="mx-auto" :src="detail.payment_detail_object.qrcode_url"></v-img>
                <div v-if="detail.payment_detail_object.redirect_url !== undefined && detail.payment_detail_object.redirect_url !== ''" class="text-center">
                  <v-btn
                    elevation="0"
                    color="#4ab762"
                    class="text-capitalize white--text mb-5"
                    target="_blank"
                    :href="detail.payment_detail_object.redirect_url">
                    Buka aplikasi <span v-if="detail.payment_name === 'Gopay'" class="ml-1">Gojek</span><span v-else class="ml-1">{{ detail.payment_name }}</span>
                  </v-btn>
                </div>
                <v-divider class=""/>
              </div>
              <div>
                <div>
                  <v-expansion-panels accordion flat  v-model="panel">
                    <v-expansion-panel v-for="(n, t) in detail.payment_detail_object.instruction" :key="t" style="border-bottom: 1px solid #eee;">
                      <v-expansion-panel-header class=" body-1 font-weight-bold text-first">{{ n.label }}</v-expansion-panel-header>
                      <v-expansion-panel-content class="px-0">
                        <div class="d-flex mb-3" v-for="(s, p) in n.value" :key="p">
                          <div class="mr-2 body-2">
                            {{ p+1 }}. 
                          </div>
                          <div>
                            {{ s }}
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'py-10'">
        <div class="text-second body-1 text-center">
          Beranda |  Keranjang | Transaksi
        </div>
        <div class="py-10">
          <v-img
            :src="require('@/assets/payment/img_payment_success.png')" 
            v-if="detail.status !== 'rejected' && detail.status !== 'cancelled'"
            :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 200 : 300"
            class="mx-auto"
            height="fit-content">
          </v-img>
          <v-img
            :src="require('@/assets/payment/img_error.png')" 
            v-if="detail.status === 'rejected' || detail.status === 'cancelled'"
            :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 200 : 300"
            class="mx-auto"
            height="fit-content">
          </v-img>
        </div>

        <div v-if="process.run" class="d-flex align-center justify-center" style="height: 250px;">
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="grey"
            ></v-progress-circular>
            <div class="fs-16 ml-2 text-second text--darken-2 mt-2 font-weigth-bold">
              Loading….
            </div>
          </div>
        </div>
        <div v-if="!process.run">
          <div v-if="detail.status === 'waiting'">
            <div class="text-second text-center font-weight-bold mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-25'">
              Transaksi Berhasil
            </div>
            <div class="text-first font-weight-bold text-center mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-20'">
              Selesaikan pembayaran dalam
            </div>
            <div class="warning--text font-weight-bold text-center mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
              <p id="demo"></p>
            </div>
            <div class="text-second text-center mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-18'">
              Batas Akhir Pembayaran
            </div>
            <div class="text-first font-weight-bold text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-20'">
              {{ detail.paid_due_datetime | datetimeFull }}
            </div>
            
            <v-row
              justify="center"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-5' : 'mt-7 pb-16'">
              <v-col cols="12" md="7" sm="7">
                <div class="d-flex align-center" style="background: #F5F5F5; border-radius: 7px; padding: 18px 22px;">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-18'">
                    {{ detail.payment_name }}
                  </div>
                  <div class="ml-auto">
                    <v-img :src="detail.payment_image" contain width="100"></v-img>
                  </div>
                </div>

                <div class="d-flex align-center" v-if="detail.payment_va_number !== ''" style="padding: 18px 22px;">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-18'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Nomor Virtual Account
                    </div>
                    {{ detail.payment_va_number }}
                  </div>
                  <div class="ml-auto">
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-16'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.payment_va_number)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                  </div>
                </div>

                <div v-if="detail.payment_detail_object && detail.payment_detail_object.qrcode_url !== undefined">
                  <v-img width="300" class="mx-auto" :src="detail.payment_detail_object.qrcode_url"></v-img>
                  <!-- <v-divider class=""/> -->
                </div>

                <v-divider style="margin: 0px 22px;"></v-divider>

                <div class="d-flex align-center" style="padding: 18px 22px;">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-18'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Total Pembayaran
                    </div>
                    {{ detail.total_paid_nett | price }}
                  </div>
                  <div class="ml-auto">
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'fs-16'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.total_paid_nett)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                  </div>
                </div>

                <v-row class="mt-6" style="padding: 0px 22px;">
                  <v-col cols="6" class="pr-5">
                    <v-btn
                      block
                      :large="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? false : true"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      outlined
                      elevation="0"
                      color="#9e9e9e"
                      height="40"
                      @click="dialog.pembayaran = true"
                      class="text-capitalize mx-1">
                      Cara Pembayaran
                    </v-btn>
                  </v-col>
                  <v-col cols="6" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'pl-0' : 'pl-5'">
                    <v-btn
                      block
                      :large="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? false : true"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      elevation="0"
                      height="40"
                      :to="`/account/transaction?data=${detail.id}`"
                      color="#4ab762"
                      class="text-capitalize white--text">
                      Cek Status Pembayaran
                    </v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </div>
          <div v-if="detail.status === 'paid'">
            <div class="text-second text-center font-weight-bold mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
              Transaksi Berhasil
            </div>
            <div class="text-first font-weight-bold text-center mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
              Pembayaran telah berhasil
            </div>
            <v-row
              justify="center"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-5' : 'mt-7 pb-16'">
              <v-col cols="12" md="7" sm="7">
                <div class="d-flex align-center px-5 py-4" style="background: #efefefad;">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ detail.payment_name }}
                  </div>
                  <div class="ml-auto">
                    <v-img :src="detail.payment_image" contain width="100"></v-img>
                  </div>
                </div>

                <div class="d-flex align-center px-5 py-2" v-if="detail.payment_va_number !== ''">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Nomor Virtual Account
                    </div>
                    {{ detail.payment_va_number }}
                  </div>
                  <div class="ml-auto">
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.payment_va_number)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                  </div>
                </div>

                <div v-if="detail.payment_detail_object && detail.payment_detail_object.qrcode_url !== undefined">
                  <v-img width="300" class="mx-auto" :src="detail.payment_detail_object.qrcode_url"></v-img>
                  <v-divider class=""/>
                </div>

                <v-divider></v-divider>

                <div class="d-flex align-center px-5 py-2">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Total Pembayaran
                    </div>
                  </div>
                  <div class="ml-auto text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ detail.total_paid_nett | price }}
                  <!-- <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.total_paid_nett)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn> -->
                  </div>
                </div>
                <v-divider></v-divider>

                <div class="d-flex align-center px-5 py-2">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Kode Transaksi
                    </div>
                  </div>
                  <div class="ml-auto text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ detail.number }}
                  <!-- <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.total_paid_nett)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn> -->
                  </div>
                </div>

              </v-col>
            </v-row>
          </div>
          <div v-if="detail.status === 'rejected' || detail.status === 'cancelled'">
            <div class="text-second text-center font-weight-bold mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
              Transaksi Dibatalkan
            </div>
            <div class="text-first font-weight-bold text-center mb-3" v-if="detail.status === 'rejected'" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
              {{ detail.rejected_reason }}
            </div>
            <div class="text-first font-weight-bold text-center mb-3" v-if="detail.status === 'cancelled'" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
              Transaksi Dibatalkan Oleh Anda
            </div>

            <v-row
              justify="center"
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-5' : 'mt-7 pb-16'">
              <v-col cols="12" md="7" sm="7">
                <div class="d-flex align-center px-5 py-4" style="background: #efefefad;">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ detail.payment_name }}
                  </div>
                  <div class="ml-auto">
                    <v-img :src="detail.payment_image" contain width="100"></v-img>
                  </div>
                </div>

                <div class="d-flex align-center px-5 py-2" v-if="detail.payment_va_number !== ''">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Nomor Virtual Account
                    </div>
                    {{ detail.payment_va_number }}
                  </div>
                  <div class="ml-auto">
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.payment_va_number)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                  </div>
                </div>

                <div v-if="detail.payment_detail_object && detail.payment_detail_object.qrcode_url !== undefined">
                  <v-img width="300" class="mx-auto" :src="detail.payment_detail_object.qrcode_url"></v-img>
                  <v-divider class=""/>
                </div>

                <v-divider></v-divider>

                <div class="d-flex align-center px-5 py-2">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Total Pembayaran
                    </div>
                  </div>
                  <div class="ml-auto text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ detail.total_paid_nett | price }}
                  <!-- <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.total_paid_nett)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn> -->
                  </div>
                </div>
                <v-divider></v-divider>

                <div class="d-flex align-center px-5 py-2">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                      Kode Transaksi
                    </div>
                  </div>
                  <div class="ml-auto text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ detail.number }}
                  <!-- <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.total_paid_nett)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn> -->
                  </div>
                </div>

              </v-col>
            </v-row>
          </div>
          <div>
            
          </div>

          <div class="mt-16 pt-16 mb-12" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
              Recently Wishlisted
            </div>
            <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>

            <v-row class="mt-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
              <v-col cols="3" v-for="(c, k) in wishlist" :key="k" :style="fps === 'xl' ? 'flex: 0 0 20% !important;  max-width: 20% !important;' : ''">
                <v-hover v-slot="{ hover }">
                  <v-card flat>
                    <v-img :src="c.img_url" style="height: 250px; border-radius: 15px;" ></v-img> 
                    <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                      {{ c.name }}
                    </div> 
                    <v-list-item class="px-0">
                      <v-list-item-avatar size="25">
                        <v-img :src="c.organization_img_url" v-if="c.organization_img_url !== ''"></v-img>
                        <v-icon v-if="c.organization_img_url === ''">mdi-office-building</v-icon>
                      </v-list-item-avatar>
          
                      <v-list-item-content>
                        <v-list-item-title class="text-second">{{ c.organization_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <div class="d-flex align-center body-2 text-second">
                      <div class="text-first font-weight-bold">
                        5.0
                      </div>
                      <div class="mx-2">
                        <v-rating
                          :value="5"
                          background-color="white"
                          color="#e67e22"
                          dense
                          half-increments
                          hover
                          size="20">
                        </v-rating>
                      </div>
                      <div class="">
                        (1.024)
                      </div>
                    </div> -->
                    <div class="text-first text-h6 font-weight-bold py-2">
                      {{ c.price | price }}
                    </div> 
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                        style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                        <v-img :src="c.img_url" style="height:180px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                        <div class="px-3">
                          <div class="white--text text-h6 font-weight-bold mb-2 two-line mt-3" style="height: 64px;">
                            {{ c.name }}
                          </div> 
                          <div class="body-2 mb-2 white--text">
                            {{ c.description }}
                          </div>
                          <div class="white--text text--darken-3 text-h6 font-weight-bold py-0">
                            {{ c.price | price }}
                          </div> 
                          <div>
                            <v-btn
                              color="white"
                              :to="`/elearning/detail${c.id}`"
                              width="150"
                              class="text-capitalize">
                              Lihat Kelas
                            </v-btn>
                            <!-- <v-icon color="white" class="mx-2">mdi-cart-plus</v-icon>
                            <v-icon color="white">mdi-heart-outline</v-icon> -->
                          </div>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-hover>
              </v-col>

              <div v-if="wishlist.length < 1" style="width: 100%;">
                <v-img 
                  :src="require('@/assets/images/empty.png')"
                  :lazy-src="require('@/assets/images/empty.png')"
                  class="ml-auto mr-auto"
                  max-width="15%">
                </v-img>
                <p class="py-8 text-first title font-weight-bold text-center">Data wishlist belum tersedia</p>
              </div>
            </v-row>
          </div>
        </div>

      </section>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          pembayaran: false
        },
        panel: 0,
        model: '',
        detail: {},
        timeout: 2000,
        snackbar: false,
        wishlist: [],
        process: {
          run: false
        },
        success : {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/purchasing',
          title: 'pembayaran',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      // this.getDateTime()
      this.fetch()
    },
    methods: {
      fetch () {
        this.process.run = true
        let params = {
          trans_number: this.$route.params.slug
        }
        this.$axios.$get(`api/transaction/detail_by_trans_number`, { params })
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.fetchWishlist()
            this.detail = response.results.data
            if (this.detail.status === 'waiting') {
              this.getDateTime(response.results.data.paid_due_datetime)
            }
            // this.getLength = this.list.length
            // this.contentEmpty = 'Data tidak tersedia'
          }else{
            this.process.run = false
          }
        })
      },
      fetchWishlist () {
        let params = {
          limit: 4,
        }
        this.$axios.$get(`api/wishlist`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.wishlist = response.results.data
          }else{
            this.process.run = false
          }
        })
      },

      async copyTestingCode(text) {
        try {
          const toCopy = text
          await navigator.clipboard.writeText(toCopy);
          console.log('Text or Page URL copied');
          this.success.message = "Berhasil dicopy"
          this.snackbar = true
        }
        catch (err) {
          console.error('Failed to copy: ', err);
        }
      },
      
      changeStatus() {
        this.detail.status = 'rejected'
        this.detail.rejected_reason = 'Waktu Pembayaran Habis'
      },

      displayHello() {
        document.getElementById("demo").innerHTML += "Hello";
      },

      getDateTime(date) {
        // Set the date we're counting down to
        var countDownDate = new Date(date).getTime();

        // Update the count down every 1 second
        var self = this
        var x = setInterval(function() {

          // Get today's date and time
          var now = new Date().getTime();
            
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
            
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // console.log(minutes.toString())

          minutes = minutes.toString().length < 2 ? `0${minutes}` : minutes
          seconds = seconds.toString().length < 2 ? `0${seconds}` : seconds

          hours = days === 0 ? hours : hours === 0 ? 23+days : hours

          // Display the result in the element with id="demo"
          document.getElementById("demo").innerHTML = hours + ":"
          + minutes + ":" + seconds;
          // If the count down is over, write some text 
          if (distance < 0) {
            self.detail.status = 'rejected'
            self.detail.rejected_reason = 'Waktu Pembayaran Habis'
            clearInterval(x);
            document.getElementById("demo").innerHTML = "Waktu Pembayaran Habis";
          }
        }, 1000);
      }
    }
  }
</script>
