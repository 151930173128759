<template>
  <div>
    <v-container>
      <section :class="fps !== 'xs' && fps !== 'sm' ? 'pt-7' : ''">
        <!-- <div class="d-flex align-center">
          <div class="text-second" :class="fps !== 'xs' && fps !== 'sm' ? 'body-1' : 'body-2'">
            Beranda |  Akun Saya
          </div>

          <div class="ml-auto">
            <v-list-item class="px-0">
              <v-list-item-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" :class="fps !== 'xs' && fps !== 'sm' ? '' : 'mr-2'" tile>
                <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div> -->
        <v-row :class="fps !== 'xs' && fps !== 'sm' ? 'mt-0' : ''">
          <v-col cols="12" md="3">
            <div class="text-second" :class="fps !== 'xs' && fps !== 'sm' ? 'body-1 mb-12' : 'body-2 mb-2'">
              Beranda |  Akun Saya
            </div>
            <v-expansion-panels flat class="mt-n5" v-model="panel" :class="fps !== 'xs' && fps !== 'sm' ? '' : 'display-none'">
              <v-expansion-panel v-for="(item, index) in $store.state.member.auth.user.is_publisher_bool ? menu : menuUser" :key="index">
                <v-expansion-panel-header class="px-0">
                  <div class="text-first text-h6 font-weight-bold">
                    {{ item.title }}
                    <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="non-padding-expansion-bottom" v-for="(s, i) in item.sub_menu" :key="i">
                  <v-list class="py-0">
                    <v-list-item class="" style="border-radius: 6px;" :style="$route.path.split('/')[2] === 'etalase' ? s.text === 'Etalase' ? 'background: #f8f8f8; border-left: 8px solid #4ab762;' : '' : $route.fullPath === s.link ? 'background: #f8f8f8; border-left: 8px solid #4ab762;' : ''" @click="toPage(s.link)">
                      <v-list-item-content>
                        <v-list-item-subtitle class="body-1" :style="$route.path.split('/')[2] === 'etalase' ? s.text === 'Etalase' ? 'color: #3a3a3a' : 'color: #BDBDBD' : $route.fullPath === s.link ? 'color: #3a3a3a' : 'color: #BDBDBD'">
                          {{ s.text }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div :class="fps === 'xs' || fps === 'sm' ? '' : 'display-none'">
              <v-sheet
                class="mx-auto"
                elevation="0">
                <v-slide-group
                  v-model="mobilePanel"
                  class="pa-0"
                  mandatory
                  :show-arrows="false">
                  <v-slide-item
                    v-for="(item, index) in $store.state.member.auth.user.is_publisher_bool ? menu : menuUser" 
                    :key="index"
                    v-slot="{ active, toggle }">
                    <v-card
                      :color="active ? '#4ab762' : '#f2f2f6'"
                      :class="active ? 'white--text' : 'text-second'"
                      class="ma-2 text-center align-center d-flex justify-center"
                      height="40"
                      width="150"
                      flat
                      @click="toggle">
                      {{ item.title }}
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-sheet
                class="mx-auto"
                elevation="0">
                <v-slide-group
                  v-model="sub_menu"
                  class="pa-0"
                  mandatory
                  :show-arrows="false">
                  <v-slide-item
                    v-for="(sub, index) in menu[mobilePanel].sub_menu" 
                    :key="index">
                    <v-card
                      :color="$route.fullPath === sub.link ? '#3498db' : '#f2f2f6'"
                      :class="$route.fullPath === sub.link ? 'white--text' : 'text-second'"
                      class="ma-2 text-center align-center d-flex justify-center px-4"
                      height="40"
                      flat
                      @click="toPage(sub.link)">
                      {{ sub.text }}
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </div>
          </v-col>
          <v-col cols="12" md="9" :class="fps === 'xs' || fps === 'sm' ? 'px-2' : 'pl-5'">
            <router-view />
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        filter: {
          sort: 'Paling baru'
        },
        items: ['Paling baru'],
        format: 'row', 
        page: 1,
        dialog: {
          redeem: false,
          info: false
        },
        sub_menu: 0,
        panel: 0,
        mobilePanel: 0,
        model: null,
        menu: [
          {
            title: 'Pembelian',
            sub_menu: [
              {
                text: 'Semua',
                value: '',
                link: '/account/transaction'
              },
              {
                text: 'Menunggu Pembayaran',
                value: 'Menunggu Pembayaran',
                link: '/account/transaction?status=waiting'
              },
              // {
              //   text: 'Menunggu Konfirmasi',
              //   value: 'Menunggu Konfirmasi',
              //   link: '/account/transaction?status=konfirmasi'
              // },
              {
                text: 'Lunas',
                value: 'Lunas',
                link: '/account/transaction?status=paid'
              },
              {
                text: 'Dibatalkan',
                value: 'Dibatalkan',
                link: '/account/transaction?status=rejected'
              }
            ]
          },
          {
            title: 'Kelas Saya',
            sub_menu: [
              {
                text: 'Semua',
                value: '',
                link: '/account/collection'
              },
              {
                text: 'Progres',
                value: 'Progres',
                link: '/account/collection?status=progres'
              },
              {
                text: 'Selesai',
                value: 'Selesai',
                link: '/account/collection?status=selesai'
              },
              {
                text: 'Berakhir (Expired)',
                value: 'Berakhir (Expired)',
                link: '/account/collection?status=berakhir'
              },
            ]
          },
          {
            title: 'Favorit & Wishlist',
            sub_menu: [
              {
                text: 'Toko / Merchat Favorit',
                value: 'Toko / Merchat Favorit',
                link: '/account/wishlist/merchat'
              },
              {
                text: 'Wishlist',
                value: 'Wishlist',
                link: '/account/wishlist/elearning'
              }
            ]
          },
          {
            title: 'Voucher Saya',
            sub_menu: [
              {
                text: 'Semua',
                value: '',
                link: '/account/voucher-wallet'
              },
              // {
              //   text: 'Belum Terpakai',
              //   value: 'Belum Terpakai',
              //   link: '/account/voucher-wallet?status=belum-terpakai'
              // },
              // {
              //   text: 'Berakhir (Expired)',
              //   value: 'Berakhir (Expired)',
              //   link: '/account/voucher-wallet?status=berakhir'
              // }
            ]
          },
          {
            title: 'Akun',
            sub_menu: [
              {
                text: 'Pengaturan',
                value: 'Pengaturan',
                link: '/account/setting'
              },
              {
                text: 'Etalase',
                value: 'Etalase',
                link: '/account/etalase'
              },
              {
                text: 'Produk',
                value: 'Produk',
                link: '/account/product'
              },
              {
                text: 'Voucher',
                value: 'Voucher',
                link: '/account/voucher'
              },
              {
                text: 'Diskon',
                value: 'DIskon',
                link: '/account/discount'
              },
              {
                text: 'Program Diskon',
                value: 'Program Diskon',
                link: '/account/admin-discount'
              },
              {
                text: 'Diskusi',
                value: 'Diskusi',
                link: '/account/discussion'
              },
              {
                text: 'Ulasan',
                value: 'Ulasan',
                link: '/account/rating'
              },
              {
                text: 'Pengumuman',
                value: 'Pengumuman',
                link: '/account/announcement'
              },
              {
                text: 'Notifikasi',
                value: 'Notifikasi',
                link: '/account/notification'
              },
              {
                text: 'Laporan dan Statistik Penjualan',
                value: 'Laporan dan Statistik Penjualan',
                link: '/account/report'
              },
            ]
          },
        ],
        menuUser: [
          {
            title: 'Pembelian',
            sub_menu: [
              {
                text: 'Semua',
                value: '',
                link: '/account/transaction'
              },
              {
                text: 'Menunggu Pembayaran',
                value: 'Menunggu Pembayaran',
                link: '/account/transaction?status=waiting'
              },
              // {
              //   text: 'Menunggu Konfirmasi',
              //   value: 'Menunggu Konfirmasi',
              //   link: '/account/transaction?status=konfirmasi'
              // },
              {
                text: 'Lunas',
                value: 'Lunas',
                link: '/account/transaction?status=paid'
              },
              {
                text: 'Dibatalkan',
                value: 'Dibatalkan',
                link: '/account/transaction?status=rejected'
              }
            ]
          },
          {
            title: 'Kelas Saya',
            sub_menu: [
              {
                text: 'Semua',
                value: '',
                link: '/account/collection'
              },
              {
                text: 'Progres',
                value: 'Progres',
                link: '/account/collection?status=progres'
              },
              {
                text: 'Selesai',
                value: 'Selesai',
                link: '/account/collection?status=selesai'
              },
              {
                text: 'Berakhir (Expired)',
                value: 'Berakhir (Expired)',
                link: '/account/collection?status=berakhir'
              },
            ]
          },
          {
            title: 'Favorit & Wishlist',
            sub_menu: [
              {
                text: 'Toko / Merchat Favorit',
                value: 'Toko / Merchat Favorit',
                link: '/account/wishlist/merchat'
              },
              {
                text: 'Wishlist',
                value: 'Wishlist',
                link: '/account/wishlist/elearning'
              }
            ]
          },
          {
            title: 'Voucher Saya',
            sub_menu: [
              {
                text: 'Semua',
                value: '',
                link: '/account/voucher-wallet'
              },
              // {
              //   text: 'Belum Terpakai',
              //   value: 'Belum Terpakai',
              //   link: '/account/voucher-wallet?status=belum-terpakai'
              // },
              // {
              //   text: 'Berakhir (Expired)',
              //   value: 'Berakhir (Expired)',
              //   link: '/account/voucher-wallet?status=berakhir'
              // }
            ]
          },
          {
            title: 'Akun',
            sub_menu: [
              {
                text: 'Pengaturan',
                value: 'Pengaturan',
                link: '/account/setting'
              },
              {
                text: 'Notifikasi',
                value: 'Notifikasi',
                link: '/account/notification'
              },
            ]
          },
        ],
        selected: {
          transaction: '',
          merchat: ''
        },
        process: {
          redeem: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
    },
    watch: {
      // "panel": function(val) {
      //   let number = 0
      //   number = this.$route.path.split('/')[2] === 'transaction' ? 0 : this.$route.path.split('/')[2] === 'collection' ? 1 : this.$route.path.split('/')[2] === 'wishlist' ? 2 : this.$route.path.split('/')[2] === 'voucher' ? 3 : 4
      //   return number
      // },
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
    },
    mounted () {
      this.panel = this.$route.path.split('/')[2] === 'transaction' ? 0 : this.$route.path.split('/')[2] === 'collection' ? 1 : this.$route.path.split('/')[2] === 'wishlist' ? 2 : this.$route.path.split('/')[2] === 'voucher-wallet' ? 3 : 4
      this.mobilePanel = this.$route.path.split('/')[2] === 'transaction' ? 0 : this.$route.path.split('/')[2] === 'collection' ? 1 : this.$route.path.split('/')[2] === 'wishlist' ? 2 : this.$route.path.split('/')[2] === 'voucher-wallet' ? 3 : 4
    },
    methods: {
      toPage(link) {
        this.$router.push(`${link}`)
      }
    }
  }
</script>

