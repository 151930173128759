<template>
  <div class="my-10 text-center text--secondary">
    <v-img 
      :src="require('@/assets/images/empty.png')"
      :lazy-src="require('@/assets/images/empty.png')"
      class="ml-auto mr-auto"
       max-width="33%">
    </v-img>
    <p class="py-8 text-first title font-weight-bold">{{ contentEmpty }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      value: String
    },
    computed: {
      contentEmpty: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
  }
</script>