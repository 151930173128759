<template>
  <div>
    <div @click="launchFile()">
      <slot name="activator"></slot>
    </div>
    <input type="file"
       ref="file"
       :name="filename"
       v-on:change="changeFile($event.target.files)"
       style="display:none">
    <v-dialog 
      v-model="dialog"
      width="500">
      <v-card
        class="pa-0 ma-0"
        flat>
        <v-card
          color="red accent-2">
          <v-list-item>
            <v-list-item-avatar color="red accent-2" size="30">
              <v-icon color="white" large>mdi-alert</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card-text class="caption white--text pa-0">
                {{ response }}
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'file-input',
    data: () => ({
      dialog: false,
      filename: 'file',
      response: '',
      process: {
        run: false
      }
    }),
    methods: {
      launchFile () {
        this.$refs.file.click()
      },
      async changeFile (file) {
        let docs = file[0]
        console.log(file)
        if (file.length > 0) {
          if (docs.type != 'application/pdf') {
            this.dialog = true
            this.response = 'Silahkan pilih gambar dengan format .pdf'
          } else if (docs.size > 209715200) {
            this.dialog = true
            this.response = 'Maksimal file 200MB'
          } else {
            let formData = new FormData()
            formData.append('file', docs)

            this.process.run = true
            this.$emit("input", {
              process : this.process.run
            })

            fetch(`https://media.amila.id/file.php`, {
              method: "POST",
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              this.process.run = false
              this.$emit("input", {
                process : this.process.run,
                file: response.data,
                filetype: response.data.type
              })
              console.log(this.process.run)
            })
          }
        }
      }
    }
  } 
</script>