<template>
  <div>
    <Loading :detailMessage="loading_message" v-if="process.form || process.delete"/>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -20px;"
      :color="color">
      <div class="body-1 font-weight-bold">{{ color === 'warning' ? warning.message : success.message }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.detail"
      v-model="dialog.detail"
      width="570">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-7 pb-3 pt-5'">
          <div style="font-weight: 900; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'fs-18'">
            Pengumuman ({{ list.length }})
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.detail = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pt-2 pb-5'">
          <div class="text-second" style="width: 60%">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari pengumuman…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
            <v-select
              :items="[
                'Urutkan Terbaru',
                'Urutkan Terlama'
              ]"
              solo
              v-on:change="fetch()"
              color="#4ab762"
              hide-details
              placeholder="Urutkan disini"
              item-color="green"
              return-object
              v-model="filter.sort">
            </v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pb-16'" style="min-height: 400px">
          <div>
            <div v-if="process.run">
              <v-skeleton-loader
                class="ma-auto"
                v-for="r in 4"
                :key="r"
                :loading="process.run"
                type="list-item-avatar-three-line">
              </v-skeleton-loader>
            </div>
            <div v-if="!process.run">
              <div v-for="(item, index) in list" :key="index" >
                <div class="d-flex">
                  <div class="pr-0">
                    <v-img
                      :src="item.user_img_url"
                      :lazy-src="item.user_img_url"
                      v-if="item.user_img_url !== ''"
                      width="40"
                      height="40"
                      style="border-radius: 6px;"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <div v-if="item.user_img_url === undefined || item.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                      <v-row
                        class="fill-height ma-0 fs-28"
                        align="center"
                        justify="center"
                      >
                        {{ getKarekter(item.user_name) }}
                      </v-row>

                    </div>
                  </div>
                  <div style="width: 100%; margin-left: 15px;">
                    <div class="d-flex">
                      <div class="text-second text--darken-4" style="width: 100%;">
                        <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;">
                          {{ item.user_name }}
                        </div>
                        <!-- <div style="color: #3498db; font-size: 13px;">
                          [ {{ item.type }} ]
                        </div> -->
                      </div>
                    </div>
                    <!-- <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #3498DB;">
                      <span v-if="item.course_content_title_parent !== ''">
                        {{ item.course_content_title_parent }} - {{ item.course_content_title }}
                      </span>
                      <span v-if="item.course_content_title_parent === ''">
                        Semua Bab
                      </span>
                    </div> -->
                    <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.5;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                      Posted· {{ item.last_update_datetime | datetimemonth }}
                    </div>
                    <div class="fs-12mt-0 mb-3" style="text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                      {{ item.text }}
                    </div>

                    <div>
                      <div class="d-flex">
                        <div>
                          <v-img
                            :src="user.img_url"
                            :lazy-src="user.img_url"
                            width="38"
                            height="38"
                            style="border-radius: 6px;"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                        <div style="width: 100%; margin-left: 15px;">
                          <ValidationObserver ref="observerComment">
                            <div>
                              <ValidationProvider :name="`tanggapan`" rules="max:1000" v-slot="{ errors }">
                                <v-text-field
                                  color="#4ab762"
                                  rows="2"
                                  class="mt-0 pt-0"
                                  :counter="1000"
                                  @input="item.error = ''"
                                  :autofocus="false"
                                  v-on:keyup.enter="index_materi = index; detail = item; saveComment()"
                                  :messages="'Optional'"
                                  v-model="item.comment"
                                  placeholder="Tuliskan tanggapan anda disini"
                                  :error-messages="errors">
                                  <template v-slot:message="{ message }">
                                    <span :class="item.error !== '' ? 'red--text' : message !== 'Optional' ? 'red--text' : 'color-first'" v-html="item.error !== '' ? item.error : message"></span>
                                  </template>
                                  <template v-slot:counter="{ props }">
                                    <span style="font-size: 9px;" class="text-second">
                                      {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                                    </span>
                                  </template>
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </ValidationObserver>
                        </div>
                      </div>
                    </div>
                    <div v-if="!item.child_open_bool && parseInt(item.child_count) > 0" class="mt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                      <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline; color: #505050;" @click="error.message = ''; item.child_open_bool = true; detail = item, item.child !== undefined ? '' : fetchComment(undefined, index)">
                        Tampilkan komentar ({{ item.child_count }})
                      </span>
                    </div>
                    <div v-if="process.detail && detail.id === item.id" class="my-10 text-center">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </div>
                    <div v-if="item.child_open_bool" class="mt-2">
                      <div v-for="(l, i) in item.child" :key="i" class="d-flex mb-3">
                        <div>
                          <v-img
                            :src="l.user_img_url"
                            :lazy-src="l.user_img_url"
                            v-if="l.user_img_url !== ''"
                            width="40"
                            height="40"
                            style="border-radius: 6px;"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <div v-if="l.user_img_url === undefined || l.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                            <v-row
                              class="fill-height ma-0 fs-20"
                              align="center"
                              justify="center"
                            >
                              {{ getKarekter(l.user_name) }}
                            </v-row>

                          </div>
                        </div>
                        <div style="width: 100%; margin-left: 15px;">
                          <div class="d-flex align-center">
                            <div class="text-second text--darken-4" :style="l.is_user ? 'width: 55%;' : 'width: 100%;'">
                              <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                                {{ l.user_name }} <span style="font-weight: normal; font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;">- {{ l.last_update_datetime | datetimemonth }}</span>
                              </div>
                            </div>
                            <div class="ml-auto text-right" v-if="l.user_id === user.id" style="width: 45%;">
                              <v-btn
                                @click="detail = item; error.message = ''; form.id = l.id; item.comment = l.text; detail = item;"
                                text
                                x-small
                                :disabled="process.delete"
                                class="text-capitalize fs-12 primary--text pl-0 pr-1">
                                Ubah
                              </v-btn>
                              <v-btn
                                class="text-capitalize fs-12 red--text pr-0 pl-1"
                                text
                                x-small
                                :disabled="process.delete"
                                :loading="process.delete && index_child === i"
                                @click="detail = item; index_child = i; index_materi = index; form.id = l.id; deleteComment(i);">
                                Hapus
                              </v-btn>
                            </div>
                          </div>
                          <div class="mt-1" style="font-size: 12px; line-height: 18px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                            {{ l.text }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.child_open_bool && !process.detail && parseInt(item.child_count) > 0" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                      <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline; color: #505050;" @click="error.message = ''; item.child_open_bool = false">
                        Tutup komentar
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="list.length > 0 && index !== (list.length - 1)" class="my-3">
                  <v-divider style="opacity: 0.15; border-width: 1px; border-color: #979797;"></v-divider>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
          <div class="px-0 mb-2 mt-8 text-center color-blue-first" v-if="!process.run && pagination.current < pagination.total_page">
            <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline;" @click="loadet_list = true; page = page+1; fetch(page)">
              <div v-if="loadet_list" class="my-10 text-center">
                <v-skeleton-loader
                  class="ma-auto"
                  v-for="r in 4"
                  :key="r"
                  :loading="process.run"
                  type="list-item-avatar-three-line">
                </v-skeleton-loader>
              </div>
              <div v-if="!loadet_list" class="text-center">
                Tampilkan lebih banyak
              </div>
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    data () {
      return {
        detail: {},
        dialog: {
          detail: false,
          form: false
        },
        form:{
          id: "",
          content_id: {},
          materi_id: "",
          title: "",
          text: "",
          comment: ""
        },
        filter: {
          search: "",
          sort: "Urutkan Terbaru"
        },
        bab: [
          {
            title: "Semua Bab",
            id: "",
            sub: []
          }
        ],
        loading_message: "Loading...",
        detail: {},
        materi: [],
        materi_url: [],
        contentEmpty: "",
        list: [],
        pagination: {},
        page: 1,
        color: "",
        snackbar: false,
        timeout: 2000,
        nama_image: "",
        loader: false,
        index_materi: 0,
        index_child: 0,
        playlis_materi: [],
        process: {
          detail: false,
          run: false,
          read: false,
          form: false,
          delete: false
        },
        warning: {
          message: ""
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        }
      }
    },
    props: {
      dialogopen: {
        type: Boolean,
        default: false
      },
      detailCollection: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'dialogopen': function(val) {
        this.dialog.detail = val
        this.fetch()
      },
      'detailCollection': function(val) {
        this.bab = val.content
        this.detail = val
      },
      'form.content_id': function() {
        let t = this
        this.materi = []
        for (let index = 0; index < this.bab.length; index++) {
          for (let j = 0; j < this.materi_url.length; j++) {
            if (t.form.content_id.content_id === this.materi_url[j].parent_id || t.form.content_id.content_id === this.materi_url[j].content_id) {
              t.materi.push(this.materi_url[j])
            }
          }
        }
        this.form.materi_id = ""
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      Loading: () => import('@/components/Loading')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.detail = this.$props.detailCollection
      for (let index = 0; index < this.detail.content.length; index++) {
        this.bab.push(this.detail.content[index])
      }
      for (let i in this.detail.content) {
        if (this.detail.content[i].file_url !== '') {
          this.materi_url.push(this.detail.content[i])
        }
        for (let j in this.detail.content[i].sub) {
          if (this.detail.content[i].sub[j].file_url !== '') {
            this.materi_url.push(this.detail.content[i].sub[j])
          }
        }
      }
      this.fetch()
    },
    methods: {
      fetch (page) {
        this.process.run = this.loader || this.loadet_list ? false : true
        let params = {
          limit: page === undefined ? 10 : page * 10,
          'course_id[eq]': this.detail.course_id,
          sort: this.filter.sort === "Urutkan Terbaru" ? "-id" : "id", 
          // rate: this.filter.rating,
          search: this.filter.search
        }
        this.$axios.$get(`api/course_announcement`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = []
            for (let index = 0; index < response.results.data.length; index++) {
              this.list.push({
                child_count: response.results.data[index].child_count,
                child_open_bool: response.results.data[index].child_open_bool,
                course_category_id: response.results.data[index].course_category_id,
                course_category_name: response.results.data[index].course_category_name,
                course_content_id: response.results.data[index].course_content_id,
                course_content_title: response.results.data[index].course_content_title,
                course_content_title_parent: response.results.data[index].course_content_title_parent,
                course_group_id: response.results.data[index].course_group_id,
                course_group_name: response.results.data[index].course_group_name,
                course_id: response.results.data[index].course_id,
                course_img_url: response.results.data[index].course_img_url,
                course_level: response.results.data[index].course_level,
                course_name: response.results.data[index].course_name,
                course_organization_category: response.results.data[index].course_organization_category,
                course_organization_id: response.results.data[index].course_organization_id,
                course_organization_img_url: response.results.data[index].course_organization_img_url,
                course_organization_name: response.results.data[index].course_organization_name,
                created_datetime: response.results.data[index].created_datetime,
                created_datetime_readable: response.results.data[index].created_datetime_readable,
                downvote_count: response.results.data[index].downvote_count,
                id: response.results.data[index].id,
                img_url_array: response.results.data[index].img_url_array,
                last_update_datetime: response.results.data[index].last_update_datetime,
                last_update_datetime_readable: response.results.data[index].last_update_datetime_readable,
                text: response.results.data[index].text,
                title: response.results.data[index].title,
                upvote_count: response.results.data[index].upvote_count,
                user_as_publisher_bool: response.results.data[index].user_as_publisher_bool,
                user_author_bool: response.results.data[index].user_author_bool,
                user_id: response.results.data[index].user_id,
                user_img_url: response.results.data[index].user_img_url,
                user_name: response.results.data[index].user_name,
                comment: "",
                error: ""
              })
            }
            this.pagination = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.run = false
            this.loader = false
          }else{
            this.process.run = false
          }
        })
      },
      async fetchComment (p, index) {
        this.process.detail = this.loader ? false : true
        let params = {
          limit: 1000000,
          page: p === undefined ? 1 : p,
          parent_id: this.detail.id,
          // rate: this.filter.rating,
          // search: this.filter.search
        }
        this.$axios.$get(`api/course_announcement_comment`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.$refs.observerComment[0].reset()
            let list = []
            list = response.results.data
            if (this.loader) {
              this.process.form = false
              this.process.delete = false
              this.snackbar = true
            }
            if (index !== undefined) {
              this.list[index].child = list
              this.list[index].child_count = list.length
              if (this.list[this.index_materi].child < 1) {
                this.list[index].child_open_bool = false
              } else {
                this.list[index].child_open_bool = true
              }
            }
            this.loader = false
            // this.pagination = response.results.pagination
            // this.contentEmpty = "Data belum tersedia"
            this.process.detail = false
          }else{
            this.process.detail = false
          }
        })
      },
      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
      reset(item) {
        this.error.message=""
        let bab_id = ""
        let materi_id = ""
        if (item !== undefined) { 
          for (let i in this.detail.content) {
            if (item.content_id === this.detail.content[i].id) {
              bab_id = this.detail.content[i].id
            }
            for (let j in this.detail.content[i].sub) {
              if (item.content_id === this.detail.content[i].sub[j].id) {
                bab_id = this.detail.content[i].sub[j].parent_id
                materi_id = this.detail.content[i].sub[j].id
              }
            }
          }
        }
        this.form = {
          id: item !== undefined ? item.id : "",
          content_id: item !== undefined ? bab_id : {},
          materi_id: item !== undefined ? materi_id : "",
          text: item !== undefined ? item.text : "",
          title: item !== undefined ? item.title : "",
          comment: ""
        }
      },



      async saveComment() {
        this.error.message = ''
        const isValid = await this.$refs.observerComment[0].validate()
        if (isValid && this.list[this.index_materi].comment !== '') {
          this.loader = true
          if (this.form.id === '') {
            this.createsComment()
          } else {
            this.updatesComment()
          }
        } else {
          this.list[this.index_materi].error = "Tanggapan Tidak Boleh Kosong"
          this.warning.message = "Harap periksa kembali inputan Anda"
          this.color="warning"
          this.snackbar = true
        }
      },
      async createsComment() {
        this.loading_message = "Menanggapi pengumuman ..."
        this.process.form = true
        await this.$axios.$post(`api/course_announcement_comment`, 
        {
          id: this.detail.id,
          text: this.list[this.index_materi].comment
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.form = false
            this.reset()
            this.fetchComment(undefined, this.index_materi)
            console.log(this.$refs.observerComment[0].reset())
            this.$refs.observerComment[0].reset()
            this.list[this.index_materi].comment = ""
            this.color="#27ae60"
            this.success.message="Berhasil menanggapi pengumuman"
            // this.snackbar = true
          } else {
            this.process.form = false
            this.list[this.index_materi].error = response.message
          }
        })
      },
      async updatesComment() {
        this.loading_message = "Mengubah tanggapan Anda..."
        this.process.form = true
        await this.$axios.$post(`api/course_announcement_comment/update`, 
        {
          id: this.form.id,
          text: this.list[this.index_materi].comment
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.form = false
            this.reset()
            this.fetchComment(undefined, this.index_materi)
            this.color="#27ae60"
            this.$refs.observerComment[0].reset()
            this.list[this.index_materi].comment = ""
            this.success.message="Berhasil mengubah tanggapan Anda"
            // this.snackbar = true
          } else {
            this.process.form = false
            this.list[this.index_materi].error = response.message
          }
        })
      },

      async deleteComment(index) {
        this.loading_message = "Menghapus tanggapan Anda..."
        this.process.delete = true
        this.loader = true
        await this.$axios.$post(`api/course_announcement_comment/delete`, 
        {
          id: this.form.id
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.delete = false
            this.reset()
            this.fetchComment(undefined, this.index_materi)
            this.list[this.index_materi].child.splice(index, 1)
            if (this.list[this.index_materi].child < 1) {
              this.list[this.index_materi].child_open_bool = false
            }
            this.color="#27ae60"
            this.success.message="Berhasil menghapus tanggapan Anda"
            // this.snackbar = true
          } else {
            this.process.delete = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>