<template>
  <div>
    <v-footer 
      class="py-0 justify-center mt-10"
      v-if="$route.path !== '/login' && fps !== 'xs' && fps !== 'sm'"
      style="box-shadow: none!important;"
      color="white">
      <v-container class="mt-6 lebar_layar_header mx-auto">
        <v-row>
          <v-col cols="12" md="4" style="display: grid;">
            <v-img
              :src="require('@/assets/icon/logo.png')" 
              width="300"
              height="fit-content">
            </v-img>
            <div class="mt-7 text-second text--darken-2">
              JL. Ahmad Wahid 172b, Kalangan, Baturetno , Banguntapan, Bantul, DIY Yogyakarta 55197.
            </div>
            <div class="mt-16 text-second text--darken-2">
              @Copyright by Era Solusi Data @2021
            </div>
          </v-col>
          <v-col cols="6" md="2" sm="6">
            <div class="text-first font-weight-bold mb-2">
              About Elearning
            </div>  
            <div class="text-second mb-1">
              Aboout Us
            </div>
            <div class="text-second mb-1">
              Contact Us
            </div>
            <div class="text-second mb-1">
              Privacy & Policy
            </div>
            <div class="text-second mb-1">
              Term of Service
            </div>
            <div class="text-second mb-1">
              Become Partner
            </div>
          </v-col>
          <v-col cols="6" md="2" sm="6">
            <div class="text-first font-weight-bold mb-2">
              About Elearning
            </div>  
            <div class="text-second mb-1">
              Aboout Us
            </div>
            <div class="text-second mb-1">
              Contact Us
            </div>
            <div class="text-second mb-1">
              Privacy & Policy
            </div>
            <div class="text-second mb-1">
              Term of Service
            </div>
            <div class="text-second mb-1">
              Become Partner
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/publichome/header_image.png')" ></v-img>  
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <v-footer 
      elevation="0"
      color="white"
      v-if="fps === 'xs' || fps === 'sm'"
      max-height="65"
      fixed>
      <v-bottom-navigation
        v-model="selected"
        grow
        background-color="white"
        color="#4ab762"
        style="box-shadow: none !important;">
        <v-btn
          v-for="(l, text) in menu"
          :key="text"
          small
          :to="l.link === '/account/setting' ? token ? '/account/setting' : '/login' : l.link">
          <span :class="l.link === $route.path ? 'black--text font-weight-bold' : 'text-second'">
            {{ l.title }}
          </span>
          <v-icon :class="l.link === $route.path ? 'black--text font-weight-bold' : 'text-second'" class="mb-1">{{ l.icon }}</v-icon>
          <!-- <v-img contain :width="(index + 1) % 2 === 0 ? 24 : 20" :src="selected === index ? menu.icon_active : menu.icon_non_active"> </v-img> -->
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        selected: 0,
        menu: [
           {
             icon: "mdi-home",
             title: "Beranda",
             link: "/home"
           },
           {
             icon: "mdi-view-grid-outline",
             title: "E-learning",
             link: "/elearning"
           },
           {
             icon: "mdi-office-building",
             title: "Organisasi",
             link: "/organitation"
           },
           {
             icon: "mdi-account-circle-outline",
             title: "Profile Saya",
             link: "/account/setting"
           }
        ],
      }
    },
    computed: {
      token() {
         return this.$store.state.member.auth.token
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    watch: {
      // "selected": function(val) {
      //   // return this.$route.path === '/' || this.$route.path === '/home' ? 0 : this.$route.path === '/wishlist' ? 1 : this.$route.path === '/donasiku' ? 2 : this.$route.path === '/inbox' ? 3 : this.$route.path === '/profile' ? 4 : 0
      //   this.token ?  this.menu[0].link = '/home' : this.menu[0].link = '/'
      // }
    },
    mounted () {
      // this.selected = this.$route.path === '/' || this.$route.path === '/home' ? 0 : this.$route.path === '/wishlist' ? 1 : this.$route.path === '/donasiku' ? 2 : this.$route.path === '/profile' ? 3 : 0
    },
    methods: {
      
    }
  }
</script>