<template>
  <div>
    <DialogAuth :dialogauth="toLogout" @close="toLogout = ''" />
    <div>
      <v-dialog v-model="dialog.success"
        width="430"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        persistent>
        <v-card 
          height="530"
          color="#fff"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

            <v-col cols="12" class="pa-0">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
                <v-img
                  class="cursor-pointer"
                  contain
                  :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                  :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                  :src="require('@/assets/icon/logo.png')"
                  :lazy-src="require('@/assets/icon/logo.png')">
                </v-img>
                <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                  <v-btn
                    icon
                    @click="dialog.success = false; "
                    elevation="0"
                    class="color-green-second">
                    <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-card-text>
              </div>
              <!-- <v-img
                class="cursor-pointer"
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                style="position: absolute; left: 25px; top: 25px;"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
                <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; " color="#4ab762">mdi-close-circle-outline</v-icon>
              </v-card-text> -->
              <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
                <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                  Kode Verifikasi
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
                <div class="pt-10 pb-5">
                  <v-img
                    :src="require('@/assets/payment/img_payment_success.png')" 
                    width="250"
                    class="mx-auto"
                    height="fit-content">
                  </v-img>
                </div>
                <div class="text-second title text-center mb-5">
                  {{ success.message }}
                </div>
                <div class="text-center">
                  <v-btn
                    width="250"
                    color="#4ab762"
                    elevation="0"
                    @click="dialog.success = false"
                    class="caption font-weight-bold white--text">
                    Tutup
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.code"
        v-model="dialog.code"
        width="420">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-h6 text-second text--darken-2 font-weight-bold">
              Kirim Kode Verifikasi
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.email"
                class="text-capitalize px-0"
                @click="dialog.code = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-first title font-weight-reguler pt-8 pb-3 text-center">
            Kamu akan menerima kode verifikasi Email yang dikirimkan ke {{ munge_email_address }}
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.email"
                  block
                  @click="dialog.code = false"
                  class="text-capitalize white--text mr-2">
                  Tutup
                </v-btn>
              </v-col>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="#4ab762"
                  block
                  :disabled="process.email"
                  :loading="process.email"
                  @click="fetchEmail()"
                  class="text-capitalize white--text mx-1 px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Kirim Kode
                    </div>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="grey darken-4"
              outlined
              width="120"
              @click="dialog.metode = true"
              class="text-capitalize white--text mr-2">
              Cara Lain
            </v-btn>
            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.email"
              width="120"
              @click="dialog.code = false"
              class="text-capitalize white--text mr-2">
              Tutup
            </v-btn>

            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              :disabled="process.email"
              :loading="process.email"
              @click="fetchEmail()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Kirim Kode
                </div>
              </div>
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.metode"
        v-model="dialog.metode"
        width="500">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-h5 text-second text--darken-2 font-weight-bold">
              Pilih Metode Verifikasi
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.metode = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <div class="text-second text--darken-2 body-1 font-weight-reguler pt-5 pb-3 text-left">
              Pilih salah satu metode di bawah ini untuk mengirimkan kode verifikasi (OTP)
            </div>
            <v-list>
              <v-list-item class="px-0 mb-4" @click="dialog.metode = false" style="border: 1px solid; border-radius: 8px;">
                <v-list-item-avatar
                  width="50"
                  tile
                  height="50"
                  class="align-self-start mr-1 ml-2">
                  <v-icon size="30">mdi-whatsapp</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="pt-3">
                  <div class="body-1 font-weight-bold">
                    Kirim melalui WhatsApp
                  </div>
                </v-list-item-content>
                <v-list-item-content style="max-width: 50px;" class="text-right">
                  <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="px-0 mb-4" @click="dialog.metode = false" style="border: 1px solid; border-radius: 8px;">
                <v-list-item-avatar
                  width="50"
                  tile
                  height="50"
                  class="align-self-start mr-1 ml-2">
                  <v-icon size="30">mdi-message-processing</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="pt-3">
                  <div class="body-1 font-weight-bold">
                    Kirim SMS
                  </div>
                </v-list-item-content>
                <v-list-item-content style="max-width: 50px;" class="text-right">
                  <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="px-0 mb-4" @click="dialog.metode = false" style="border: 1px solid; border-radius: 8px;">
                <v-list-item-avatar
                  width="50"
                  tile
                  height="50"
                  class="align-self-start mr-1 ml-2">
                  <v-icon size="30">mdi-phone</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="pt-3">
                  <div class="body-1 font-weight-bold">
                    Buat Panggilan Telepon
                  </div>
                </v-list-item-content>
                <v-list-item-content style="max-width: 50px;" class="text-right">
                  <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn
              elevation="0"
              color="red"
              outlined
              block
              @click="dialog.metode = false"
              class="text-capitalize white--text">
              Nanti Saja
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
      </v-snackbar>

      <section>
        <v-row class="mb-4">
          <v-col cols="9" class="pt-0">
            <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Pengaturan profile & toko.
            </div>
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <v-expansion-panels flat v-model="panel" :readonly="process.form || process.avatar">
          <v-expansion-panel class="border_radius_7" style="border: 1px solid #ebebeb; margin-bottom: 20px;">
            <v-expansion-panel-header  style="background: #f9f9f994; padding: 25px 30px 30px 30px;" @click="error.message = ''; reset(); fetch();">
              <v-list dense class="py-0" color="transparent">
                <v-list-item class="py-0 pl-0">
                  <div style="width: 100%;">
                    <div class="body-1 font-weight-bold text-capitalize">
                      Profil Saya
                    </div>
                    <div class="text-first ml-auto" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      Kelola informasi profil Anda untuk mengontrol, melindungi dan mengamankan akun.
                    </div>
                  </div>
                </v-list-item>
              </v-list>
              <template v-slot:actions>
                <div>
                  <v-icon color="#7B7B7B">
                    $expand
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-0" style="background: #f9f9f994; padding: 0px 5px 10px 5px;">
              <div v-if="process.run" style="height: 300px;" class="d-flex align-center justify-center">
                <v-progress-circular
                  :size="50"
                  color="#4ab762"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-if="!process.run">
                <v-row>
                  <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pr-5 pb-0' : ''">
                    <ValidationObserver ref="observer">
                      <v-text-field
                        solo
                        readonly
                        v-on:keyup.enter="updateProfile()"
                        v-model="form.email"
                        hide-details="true"
                        color="#4ab762"
                        class="mb_15"
                        placeholder="Email"
                        prepend-inner-icon="mdi-email-outline"
                        require>
                      </v-text-field>
                      <ValidationProvider name="Nama" rules="required" v-slot="{ errors }">
                        <v-text-field
                          :outlined="form.name !== '' ? true : false"
                          :solo="form.name !== '' ? false : true"
                          :dense="form.name !== '' ? true : false"
                          color="#4ab762"
                          placeholder="Nama"
                          hide-details="true"
                          :class="errors.length > 0 ? 'error_input_border' : ''"
                          v-on:keyup.enter="updateProfile()"
                          v-model="form.name"
                          class="mb_15"
                          prepend-inner-icon="mdi-account-circle"
                          :error-messages="errors"
                          require>
                          <template v-slot:append>  
                            <v-tooltip bottom v-if="errors.length > 0">
                              <template v-slot:activator="{ on }">
                                <v-icon color="red" v-on="on">mdi-alert</v-icon> 
                              </template>
                              Kolom tidak boleh kosong
                            </v-tooltip> 
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                        <v-text-field
                          :outlined="form.username !== '' ? true : false"
                          :solo="form.username !== '' ? false : true"
                          :dense="form.username !== '' ? true : false"
                          color="#4ab762"
                          :class="errors.length > 0 ? 'error_input_border' : ''"
                          hide-details="true"
                          placeholder="Username"
                          :error-messages="errors"
                          v-on:keyup.enter="updateProfile()"
                          class="mb_15"
                          v-model="form.username"
                          prepend-inner-icon="mdi-account-key"
                          require>
                          <template v-slot:append>  
                            <v-tooltip bottom v-if="errors.length > 0">
                              <template v-slot:activator="{ on }">
                                <v-icon color="red" v-on="on">mdi-alert</v-icon> 
                              </template>
                              Kolom tidak boleh kosong
                            </v-tooltip> 
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider name="contact" rules="required" v-slot="{ errors }">
                        <v-text-field
                          color="#4ab762"
                          :outlined="form.contact !== '' ? true : false"
                          :solo="form.contact !== '' ? false : true"
                          :dense="form.contact !== '' ? true : false"
                          v-model="form.contact"
                          hide-details="true"
                          :class="errors.length > 0 ? 'error_input_border' : ''"
                          class="mb_15"
                          placeholder="No. Handphone"
                          v-on:keyup.enter="updateProfile()"
                          prepend-inner-icon="mdi-cellphone"
                          :error-messages="errors"
                          require>
                          <template v-slot:append>  
                            <v-tooltip bottom v-if="errors.length > 0">
                              <template v-slot:activator="{ on }">
                                <v-icon color="red" v-on="on">mdi-alert</v-icon> 
                              </template>
                              Kolom tidak boleh kosong
                            </v-tooltip> 
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                      <v-select
                        :items="[
                          {
                            label: 'Laki-Laki',
                            value: 'Laki-Laki'
                          },
                          {
                            label: 'Perempuan',
                            value: 'Perempuan'
                          }
                        ]"
                        item-text="label"
                        item-value="value"
                        hide-details="true"
                        class="mb_15"
                        :outlined="form.gender !== '' ? true : false"
                        :solo="form.gender !== '' ? false : true"
                        :dense="form.gender !== '' ? true : false"
                        v-model="form.gender"
                        prepend-inner-icon="mdi-gender-male"
                        placeholder="Jenis Kelamin"
                        color="#4ab762"
                        item-color="green">
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </ValidationObserver>
                  </v-col>
                  <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : ''">
                    <div class="d-flex">
                      <div style="width: 165px;">
                        <v-img
                          :src="form.img_url"
                          :lazy-src="form.img_url"
                          v-if="form.img_url !== ''"
                          :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 165 : 140"
                          :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 165 : 140"
                          aspect-ratio="1"
                          style="border-radius: 15px;"
                          class="grey lighten-2">
                          <v-progress-linear
                            color="#4ab762"
                            rounded
                            indeterminate
                            height="6"
                            v-if="process.avatar"
                            class="mx-auto"
                            style="width: 60%; margin-top: 50%;">
                          </v-progress-linear>
                        </v-img>
                        <div v-if="form.img_url === ''">
                          <div style="background-color: #a4a4a46e; color: #fff; width: 180px; height: 180px; border-radius: 15px;" class="ml-2" :style="process.avatar ? 'padding-top: 90px' : ''">
                            <v-row
                              class="fill-height ma-0"
                              v-if="!process.avatar"
                              :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h3' : ''"
                              align="center"
                              justify="center"
                            >
                              {{ nama_image }}
                            </v-row>
                            <v-progress-linear
                              color="#4ab762"
                              rounded
                              indeterminate
                              height="6"
                              v-if="process.avatar"
                              class="mx-auto"
                              style="width: 60%;">
                            </v-progress-linear>
                          </div>
                        </div>
                      </div>
                      <div class="ml-auto pl-1 pl-5">
                        <image-input v-model="avatar" style="height: 48px; width: 100%;">
                          <div slot="activator">
                            <v-btn
                              outlined
                              :disabled="process.avatar"
                              block
                              height="40"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#5050505e"
                              class="text-capitalize">
                              Pilih Foto
                            </v-btn>
                          </div>
                        </image-input>


                        <div class="mt-3 caption text-second text-darken-1">
                          Besar file: maksimum 10.000.000 bytes
                          (10 Megabytes). Ekstensi file yang
                          diperbolehkan: .JPG .JPEG .PNG.
                          Dengan dimensi terbaik square (contoh:
                          256px x 256px)
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pr-5 pt-0">
                    <p class="body-2 text-second text--darken-2 mb-2 mt-1 font-weight-regular">
                      <b>Tanggal lahir</b>
                    </p>
                    <DateInput 
                      v-model="form.birthdate" 
                      :clear="form.birthdate !== '' ? false : true"
                      @changeDate="form.birthdate = $event.date; date_birthdate = $event.valid"/>
                  </v-col>
                  <v-col cols="12" md="6" class="pl-0 text-right d-flex align-end justify-end">
                    <v-alert 
                      type="error" 
                      text
                      dense
                      prominent
                      v-show="error.message.length > 0"
                      v-html="error.message">
                    </v-alert>

                    <v-btn
                      elevation="0"
                      width="160"
                      :disabled="process.form || process.avatar"
                      :loading="process.form"
                      @click="updateProfile()"
                      height="40"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      color="#4ab762"
                      class="text-capitalize ml-3 white--text">
                      Simpan
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          
          <v-expansion-panel class="border_radius_7" style="border: 1px solid #ebebeb; margin-bottom: 20px;">
            <v-expansion-panel-header style="background: #f9f9f994; padding: 25px 30px 30px 30px;" @click="error.message = ''; reset();">
              <v-list dense class="py-0" color="transparent">
                <v-list-item class="py-0 pl-0">
                  <div style="width: 100%;">
                    <div class="body-1 font-weight-bold text-capitalize">
                      Atur Password
                    </div>
                    <div class="text-first ml-auto" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      Untuk keamanan akun Anda, mohon untuk tidak menyebarkan password Anda ke orang lain.
                    </div>
                  </div>
                </v-list-item>
              </v-list>
              <template v-slot:actions>
                <div>
                  <v-icon color="#7B7B7B">
                    $expand
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-0" style="background: #f9f9f994; padding: 0px 5px 25px 5px;">
              <div v-if="process.run" style="height: 300px;" class="d-flex align-center justify-center">
                <v-progress-circular
                  :size="50"
                  color="#4ab762"
                  indeterminate
                ></v-progress-circular>
              </div>
              <v-card-text class="px-3 py-0" v-if="!process.run">
                <ValidationObserver ref="observerPassword">
                  <ValidationProvider name="Password" vid="confirm" rules="required" v-slot="{ errors }">
                    <v-row>
                      <v-col cols="12" md="6" class="px-0 pb_15 pt-0">
                          <v-text-field
                            :outlined="password.new_password !== '' ? true : false"
                            :solo="password.new_password !== '' ? false : true"
                            :dense="password.new_password !== '' ? true : false"
                            color="#4ab762"
                            :class="errors.length > 0 ? 'error_input_border' : ''"
                            placeholder="Password"
                            hide-details="true"
                            v-on:keyup.enter="resetPassword()"
                            :disabled="process.form"
                            prepend-inner-icon="mdi-form-textbox-password"
                            v-model="password.new_password"
                            :type="show.new_password ? 'text' : 'password'"
                            :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.new_password = !show.new_password"
                            autocomplete="new-password"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0 pr-0 d-flex align-center" style="height: 45px;">
                        <v-icon v-if="errors.length > 0" color="red" class="mr-2">mdi-alert</v-icon>
                        <div v-if="errors.length > 0" class="red--text">
                          {{ errors[0] }}
                        </div>
                      </v-col>
                    </v-row>
                  </ValidationProvider>
                  <ValidationProvider name="Komfirmasi Password" rules="required|confirmed:confirm" v-slot="{ errors }">
                    <v-row>
                      <v-col cols="12" md="6" class="px-0 pb_15 pt_15">
                        <v-text-field
                          :outlined="password.comfirm_password !== '' ? true : false"
                          :solo="password.comfirm_password !== '' ? false : true"
                          :dense="password.comfirm_password !== '' ? true : false"
                          color="#4ab762"
                          :class="errors.length > 0 ? 'error_input_border' : ''"
                          hide-details="true"
                          placeholder="Komfirmasi Password"
                          v-on:keyup.enter="resetPassword()"
                          :disabled="process.form"
                          prepend-inner-icon="mdi-form-textbox-password"
                          v-model="password.comfirm_password"
                          :type="show.comfirm_password ? 'text' : 'password'"
                          :append-icon="show.comfirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show.comfirm_password = !show.comfirm_password"
                          autocomplete="new-password"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-5 pb-0 pr-0 d-flex align-center" style="height: 45px;">
                        <v-icon v-if="errors.length > 0" color="red" class="mr-2">mdi-alert</v-icon>
                        <div v-if="errors.length > 0" class="red--text">
                          {{ errors[0] }}
                        </div>
                      </v-col>
                    </v-row>
                  </ValidationProvider>
                  <ValidationProvider name="Kode Verifikasi" rules="required" v-slot="{ errors }">
                    <v-row>
                      <v-col cols="12" md="6" class="px-0 pb_15 pt_15">
                        <div class="d-flex align-start">
                          <div style="width: 70%">
                            <v-text-field
                              :outlined="password.verification !== '' ? true : false"
                              :solo="password.verification !== '' ? false : true"
                              :dense="password.verification !== '' ? true : false"
                              v-on:keyup.enter="resetPassword()"
                              v-model="password.verification"
                              :class="errors.length > 0 ? 'error_input_border' : ''"
                              class="input-border-right-none mb_15"
                              style="border-radius: 6px 0px 0px 6px !important;"
                              prepend-inner-icon="mdi-code-braces-box"
                              placeholder="Kode Verifikasi"
                              color="#4ab762"
                              hide-details="true"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </div>
                          <div style="width: 30%">
                            <v-btn
                              height="45"
                              block
                              color="#3498db"
                              @click="dialog.code = true;"
                              style="border-radius: 0px 6px 6px 0px !important"
                              elevation="0"
                              class="text-capitalize white--text">
                              Kirim Kode
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-5 pb-0 pr-0 d-flex align-center" style="height: 45px;">
                        <v-icon v-if="errors.length > 0" color="red" class="mr-2">mdi-alert</v-icon>
                        <div v-if="errors.length > 0" class="red--text">
                          {{ errors[0] }}
                        </div>
                      </v-col>
                    </v-row>
                  </ValidationProvider>
                  <v-row>
                    <v-col cols="12" md="6" class="pa-0">
                      <v-alert 
                        type="error" 
                        text
                        dense
                        prominent
                        v-show="error.message.length > 0"
                        v-html="error.message">
                      </v-alert>

                      <v-btn
                        elevation="0"
                        width="160"
                        :disabled="process.form || process.avatar"
                        :loading="process.form"
                        height="40"
                        @click="resetPassword()"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="#4ab762"
                        class="text-capitalize white--text mt-3">
                        Simpan
                      </v-btn>
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="border_radius_7" style="border: 1px solid #ebebeb; margin-bottom: 20px;" v-if="this.$store.state.member.auth.user.is_publisher_bool">
            <v-expansion-panel-header style="background: #f9f9f994; padding: 25px 30px 15px 30px;" @click="error.message = ''; reset(); fetch();">
              <v-list dense class="py-0" color="transparent">
                <v-list-item class="py-0 pl-0">
                  <div style="width: 100%;">
                    <div class="body-1 font-weight-bold text-capitalize">
                      Akun Publisher
                    </div>
                    <div class="text-first ml-auto" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      Kelola informasi profil Toko Anda seperti Etalase, Alamat, dan lain sebagainya.
                    </div>
                  </div>
                </v-list-item>
              </v-list>
              <template v-slot:actions>
                <div>
                  <v-icon color="#7B7B7B">
                    $expand
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-0 non-padding-expansion" style="background: #f9f9f994;">
              <div v-if="process.run" style="height: 300px;" class="d-flex align-center justify-center">
                <v-progress-circular
                  :size="50"
                  color="#4ab762"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-if="!process.run">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="grey darken-3"
                  class="mt-0 mb-5"
                  style="padding: 0px 30px 0px 23px; height: 45px;"
                  dense>
                  <v-tabs-slider color="#4ab762"></v-tabs-slider>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mx-2">
                    <div>
                      <div class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                        {{ item }}
                      </div>
                    </div>
                  </v-tab>
                </v-tabs>

                <div v-if="selected.menu === 0" style="padding: 0px 18px 0px 18px;">
                  <Information :detailProfile="form" @progress="process.form = $event" @fetch="success.message = 'Berhasil mengubah informasi toko Anda'; snackbar = true; fetch('update');"/>
                </div>
                <div v-if="selected.menu === 1" class="pt-3">
                  <Rekening :detailProfile="form" @progress="process.form = $event" />
                </div>

                <div v-if="selected.menu === 2" class="pt-3">
                  <Organitation :detailProfile="form" @progress="process.form = $event" />
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>

      <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'">
        <div class="d-flex align-center mt-5 mb-1" @click="toLogout = 'logout'">
          <div class="mr-3">
            <v-icon size="23" color="#E53935">mdi-lock-outline</v-icon>
          </div>
          <div class="body-1 font-weight-reguler red--text">
            Sign Out
          </div>
          <div class="ml-auto">
            <v-btn 
              class="px-0" 
              min-width="36" 
              elevation="0"
              icon>
            <v-icon color="#E53935">mdi-chevron-right</v-icon>
          </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
import Organitation from '~/components/toko/organitation.vue'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          code: false,
          metode: false,
          success: false
        },
        toLogout: '',
        panel: 0,
        menu: ['Informasi Publisher', 'Rekening Bank', 'Organisasi'],
        selected: {
          menu: 0
        },
        snackbar: false,
        timeout: 2000,
        date_birthdate: true,
        munge_email_address: "",
        avatar: null,
        form: {
          birthdate: ""
        },
        password: {
          comfirm_password: '',
          new_password: '',
          verification: ''
        },
        show: {
          comfirm_password: false,
          new_password: false
        },
        detail: {},
        nama_image: "",
        process: {
          run: false,
          avatar: false,
          form: false,
          email: false
        },
        formError: {
          username: ""
        },
        error: {
          message: ""
        },
        success: {
          message: ""
        },
        content: {
          url: '/account/setting',
          title: 'Pengaturan',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      ImageInput: () => import('@/components/ImageInput'),
      Information: () => import('@/components/toko/infomation'),
      Rekening: () => import('@/components/toko/rekening'),
      Organitation: () => import('@/components/toko/organitation'),
      DateInput: () => import('@/components/Date')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    watch: {
      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
          }
        },
        deep: true
      }
    },
    created() {
      let temp = []
      var maxYear = new Date().getFullYear() + 50;
      var minYear = 2000
      for (let i = minYear; i<=maxYear; i++) {
        temp.push(i)
      }
      this.years = temp

      let day = []
      for (let i = 1; i <= 31; i++) {
        day.push(i.toString().padStart(2,"0"))
      }
      this.days = day

    },
    mounted () {
      this.fetch()
      this.getKarekter()
    },
    methods: {
      fetch (update) {
        this.process.run = update === undefined ? true : false
        this.$axios.$get(`api/profile`)
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.process.run = false
            this.form = response.results
            this.detail = {}
            this.detail = response.results
            this.avatar = this.form.img_url === '' ? '' : {
              fileuri: this.form.img_url
            }
            this.mungeEmailAddress(this.form.email)
          }else{
            this.process.run = false
          }
        })
      },
      fetchEmail () {
        this.process.email = true
        this.$axios.$get(`api/profile/request_set_password_code`)
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.success.message = response.message
            this.dialog.success = true
            this.dialog.code = false
            this.process.email = false
          }else{
            this.process.email = false
          }
        })
      },
      mungeEmailAddress(s) {
        var i = s.indexOf('@');
        var startIndex = i * .2 | 0;
        var endIndex   = i * .9 | 0;
        this.munge_email_address = s.slice(0, startIndex) +
              s.slice(startIndex, endIndex).replace(/./g, '*') +
              s.slice(endIndex);
      },
      getKarekter() {
        let name = this.$store.state.member.auth.user.name
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        // return initial.toUpperCase()
      },
      async reset() {
        this.avatar = this.form.img_url === '' ? '' : {
          fileuri: this.form.img_url
        }

        this.password = {
          comfirm_password: '',
          new_password: '',
          verification: ''
        }

        this.process = {
          run: false,
          avatar: false,
          form: false,
          email: false
        }

        this.error.message = ''
        // this.$refs.observer.reset();
        // this.$refs.observerPassword.reset();
      },
      async updateProfile(){
        this.error.message = ''
        this.formError.username = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.process.form = true
          this.$axios.$post(`api/profile/update`, {
            username: this.form.username,
            name: this.form.name,
            img_url: this.form.img_url,
            gender:this.form.user_gender,
            contact: this.form.contact,
            gender: this.form.gender,
            birthdate: this.form.birthdate
          }).then(response => {
            let res = response
            if (res.status === 200) {
              this.$store.state.member.auth.user.img_url = this.form.img_url
              this.$store.state.member.auth.user.name = this.form.name
              this.$store.state.member.auth.user.username = this.form.username
              this.$store.state.member.auth.user.email = this.form.email

              Cookie.set('user', this.$store.state.member.auth.user)
              Cookie.set('token', this.token)

              // this.$store.state.member.auth.user = data

              this.success.message="Berhasil mengubah profile Anda"

              this.snackbar = true
              this.$refs.observer.reset();
              this.reset()
              this.fetch('update')
            } else {
              if (res.status === 412) {
                this.error.message = ''
              } else {
                this.error.message = res.message
              }
              this.error.message = res.results.data.username
              this.process.form = false
            }
          })
        }
      },
      async resetPassword(){
        this.error.message = ''
        const isValid = await this.$refs.observerPassword.validate()
        if (isValid) {
          this.process.form = true

          await this.$axios.$post(`api/profile/set_password`, 
          {
            code: this.password.verification,
            password: this.password.new_password,
            password_confirm: this.password.comfirm_password,
            device: 'web'
          })
          .then((response) => {
            if (response.status === 200) {
              this.process.form = false
              this.reset()
              this.success.message="Berhasil mengubah password Anda"

              this.snackbar = true
              this.$refs.observerPassword.reset();
            } else {
              this.process.form = false
              this.error.message = response.message
            }
          })
        }
      }
    },
  };
</script>