<template>
  <div>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.filter"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      v-model="dialog.filter"
      width="650">
      <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="body-1 text-second text--darken-2 font-weight-bold">
            <div>
              Filter Kelas
            </div>
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.form"
              class="text-capitalize px-0"
              @click="fetch(); dialog.filter = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 235px);">
          <div class="text-first fs-18 mt-5 mb-5 font-weight-bold">
            Kategori
          </div>
          <div class="d-flex align-center mt-4" style="flex-wrap: wrap;">
            <div  v-for="(c, n) in category" :key="n">
              <v-btn
                :color="filter.category === c ? '#4ab762' : '#ededed'"
                elevation="0"
                :class="filter.category === c ? 'white--text' : 'text-second'"
                @click="filter.category = c; fetch()"
                class="text-capitalize mr-2 mb-2 fs-14">
                {{c }}
              </v-btn>
            </div>
          </div>
          <div class="text-first fs-18 mt-5 mb-5 font-weight-bold">
            Urutkan
          </div>
          <div class="d-flex align-center mt-4" style="flex-wrap: wrap;">
            <div  v-for="(c, n) in sort" :key="n">
              <v-btn
                :color="filter.sort === c ? '#4ab762' : '#ededed'"
                elevation="0"
                :class="filter.sort === c ? 'white--text' : 'text-second'"
                @click="filter.sort = c; fetch()"
                class="text-capitalize mr-2 mb-2 fs-14">
                {{c }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>

          <v-btn
            elevation="0"
            color="#4ab762"
            :disabled="process.form"
            width="120"
            @click="fetch(); dialog.filter = false"
            class="text-capitalize white--text mr-2">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-container>
      <v-card-text class="pa-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-3' : 'py-10'">
        <v-row>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari organisasi…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="pt-0">
            <v-select
              :items="[
                'Semua Kategori',
                'Personal (Komunitas)',
                'Power Merchant',
                'Official Partner'
              ]"
              solo
              color="#4ab762"
              style="width: 100%;"
              item-color="green"
              hide-details
              v-on:change="fetch()"
              v-model="filter.category"
              placeholder="Kategori Oraganisasi">
            </v-select>
          </v-col>
          <v-col cols="12" md="3" class="pt-0">
            <v-select
              :items="[
                'Urutkan Terbaru',
                'Urutkan Terlama'
              ]"
              solo
              v-on:change="fetch()"
              color="#4ab762"
              hide-details
              placeholder="Urutkan disini"
              item-color="green"
              return-object
              v-model="filter.sort">
            </v-select>
          </v-col>
        </v-row>
        <div class="text-second mt_20" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : ''" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
          Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data kelas
        </div>
      </v-card-text>
      <div v-if="process.run">
        <v-row>
          <v-col cols="6" md="4" xl="4" lg="4" v-for="loader in 12" :key="loader">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                :loading="process.run"
                type="card"
              ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>

      <div v-if="!process.run">
        <v-row>
          <v-col cols="6" md="4" xl="4" lg="4" v-for="(f, r) in list" :key="r">
            <v-card
              flat
              :to="`/organitation/${f.id}`">
              <v-img :src="f.img_url" height="210" style="border-radius: 15px;" :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" contain v-if="f.img_url !== ''"></v-img> 
              <v-avatar style="border-radius: 10px;" width="100%" height="210" color="#4ab762" v-if="f.img_url === ''">
                <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
              </v-avatar>
              <div class="d-flex align-center mt-5 mb-1">
                <div>
                  <div class="text-first text-h6 font-weight-bold">
                    {{ f.name }}
                  </div>
                </div>
                <div class="text-second ml-auto">
                  ({{ f.total_course }})
                </div>
              </div>
              <div class="text-second body-1">
                {{ f.description_short }}
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-right mt-16" v-if="!process.run && list.length < 1">
          <Empty v-model="contentEmpty"/>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </div>
    </v-container> -->
  
    <v-container>
      <v-row>
        <v-col cols="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
          <div class="text-first fs-18 font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-3' : 'pt-10 pb-5'">
            Filter Organisasi
          </div>
          <v-expansion-panels flat v-model="category_panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-0">
                <div class="text-first fs-18 font-weight-bold">
                  Kategori
                  <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="non-padding-expansion">
                <div  v-for="(c, n) in category" :key="n" @click="filter.category = c; fetch()">
                  <div class="fs-14 mb_10 d-flex align-center" :class="filter.category === c ? 'color-first font-weight-bold' : 'text-second'">
                    <div class="cursor-pointer">
                      {{c }}
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="px-0">
                <div class="text-first fs-18 font-weight-bold">
                  Urutkan
                  <v-divider style="border: 2px solid #4ab762; width: 60px; border-radius: 6px;"></v-divider>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="non-padding-expansion">
                <div  v-for="(c, n) in sort" :key="n" @click="filter.sort = c; fetch()">
                  <div class="fs-14 mb_10 d-flex align-center" :class="filter.sort === c ? 'color-first font-weight-bold' : 'text-second'">
                    <div class="cursor-pointer">
                      {{c }}
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="9">
          <v-card-text class="pa-0" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-3' : 'py-10'">
            <v-row align="center">
              <v-col cols="10" md="6" lg="6" xl="6" class="pt-0">
                <v-text-field
                  solo
                  hide-details
                  clearable
                  color="#4ab762"
                  v-model="filter.search"
                  v-on:keyup.enter="fetch()"
                  @click:clear="filter.search = ''; fetch();"
                  :placeholder="`Cari organisasi…`">
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">mdi-magnify</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="2" class="pt-0 text-right" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                <v-icon size="25" @click="dialog.filter = true;">mdi-filter-variant</v-icon>
              </v-col>
              <v-col cols="12" md="6" class="pt-0"> 
                <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 text-left' : 'text-right'">
                  Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data Organisasi
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-if="process.run">
            <v-row>
              <v-col cols="6" md="4" xl="4" lg="4" v-for="loader in 12" :key="loader">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    :loading="process.run"
                    type="card"
                  ></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <div v-if="!process.run">
            <v-row>
              <v-col cols="6" md="4" xl="4" lg="4" v-for="(f, r) in list" :key="r">
                <v-card
                  flat
                  :to="`/organitation/${f.id}`">
                  <v-img :src="f.img_url" height="210" style="border-radius: 15px;" :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" contain v-if="f.img_url !== ''"></v-img> 
                  <v-avatar style="border-radius: 10px;" width="100%" height="210" color="#4ab762" v-if="f.img_url === ''">
                    <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                  </v-avatar>
                  <div class="d-flex align-center mt-5 mb-1">
                    <div>
                      <div class="text-first text-h6 font-weight-bold">
                        {{ f.name }}
                      </div>
                    </div>
                    <div class="text-second ml-auto">
                      ({{ f.total_course }})
                    </div>
                  </div>
                  <div class="text-second body-1 three-line" style="max-height: 72px;">
                    {{ f.description_short }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <div class="text-right mt-16" v-if="!process.run && list.length < 1">
              <Empty v-model="contentEmpty"/>
            </div>
            <Pagination 
              @changePage="fetch($event)" 
              :model="pagination"
              class="pt-6"
              v-show="pagination.total_page > 1"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        slide_list: 0,
        dialog: {
          filter: false
        },
        filter: {
          search: "",
          category: "Semua Kategori",
          sort: "Urutkan Terbaru"
        },
        category: [
          'Semua Kategori',
          'Personal (Komunitas)',
          'Power Merchant',
          'Official Partner'
        ],
        sort: [
          'Urutkan Terbaru',
          'Urutkan Terlama'
        ],
        category_panel: 0,
        page: 1,
        contentEmpty: "",
        pagination: {},
        list: [],
        process: {
          run: false
        },
        // SEO
        content: {
          url: '/organitation',
          title: 'Organitation',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      // call again the method if the route changes
      '$route': function(val) {
        if (this.$route.query.category !== undefined) {
          console.log('asdsad')
          this.filter.category = this.$route.query.category === 'Personal' ? 'Personal (Komunitas)' : this.$route.query.category === 'Merchant' ? 'Power Merchant' : 'Official Partner'
        } else {
          this.filter.category = ''
        }
        this.fetch()
      },
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return th/course/detailis.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      DialogAuth: () => import('@/components/dialog/auth'),
    },
    mounted () {
      if (this.$route.query.category !== undefined) {
        console.log('asdsad')
        this.filter.category = this.$route.query.category === 'Personal' ? 'Personal (Komunitas)' : this.$route.query.category === 'Merchant' ? 'Power Merchant' : 'Official Partner'
      } else {
        this.filter.category = ''
      }
      this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 12,
          page: p === undefined ? 1 : p,
          search: this.filter.search,
          category: this.filter.category === 'Semua Kategori' ? "" : this.filter.category,
          sort: this.filter.sort === 'Urutkan Terbaru' ? '-id' : 'id'
        }
        this.$axios.$get(`api/organization`, { params })
        .then((response)=>{
          if(response.status === 200) {
            if (this.filter.search !== '' || this.filter.search !== null || this.filter.category !== '') {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      getKarekter(item) {
        if (item !== undefined) {
          let name = item
          let splitName = name.split(" ")
          let initial = name.charAt(0);
          // let second = "";
          if(splitName[1] !== undefined) {
            initial += splitName[1].charAt(0)
          }
  
          // let result = first+second
          // this.nama_image = initial.toUpperCase()
          // console.log(initial.toUpperCase())
          return initial.toUpperCase()
        }
      },
    },
  };
</script>