<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -80px;"
      color="#27ae60">
      <div class="body-1 font-weight-bold">{{ success.message }}</div>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarCoundown"
      :timeout="5000"
      centered
      top
      style="margin-top: -80px;"
      color="#27ae60">
      <div class="body-1 font-weight-bold">{{ coundown_message }}</div>
      <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Tutup
      </v-btn>
      </template>
    </v-snackbar>
    <v-container :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'px-0'">
      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.checkout"
        v-model="dialog.checkout"
        width="500">
        <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
            Checkout
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.checkout = false; error.message = '';">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-0">
            <div class="px-5 pt-5">
              <v-card flat
                v-for="(item, index) in process.cart ? 8 : selected.cart"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.cart"
                  type="list-item-avatar-three-line">
                  <div>
                    <v-list flat three-line class="py-0">
                      <v-list-item class="px-0">

                        <v-list-item-avatar tile size="75" class="mr-3 my-0">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <!-- <div class="badge-overlay-premium" v-if="item.sys_kelas_cart_kelas_is_discount">
                              <span class="top-left-premium badge-premium premium font-weight-bold">
                                {{ parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent) }}%
                              </span>
                            </div> -->
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="py-0">
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-n2" style="line-height: 22px;"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'fs-16'">
                            {{ item.name }}
                          </v-list-item-subtitle>
                          
                          <v-list-item-subtitle class="body-2 mt-n4 mb-0">
                            <v-list-item class="px-0" style="min-height: 20px;">
                              <v-list-item-avatar :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 15 : 15" class="mt-1 mb-0 " style="margin-right: 8px;">
                                <v-img :src="item.organization_img_url" v-if="item.organization_img_url !== ''"></v-img>
                                <v-icon v-if="item.organization_img_url === ''" size="15">mdi-office-building</v-icon>
                              </v-list-item-avatar>
                  
                              <v-list-item-content class="pt-1 pb-0">
                                <v-list-item-title class="text-second fs-14" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">{{ item.organization_name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content class="align-start text-right py-0" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
                          <v-list-item-title>

                            <!-- <div class="text-first body-1 font-weight-bold text-capitalize mt-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''" style="min-height: 48px; margin-bottom: 6px;"> -->
                              <div class="text-decoration-line-through text-second fs-16" v-if="item.discount_bool">
                                {{ item.price | price }}
                              </div>
                              <span v-if="item.discount_bool" class="red--text">
                                ({{ item.discount_percent }}%)
                              </span>
                              <span class="font-weight-bold">
                                <span class="" v-if="parseInt(item.price) > 0">
                                  <span v-if="!item.discount_bool">
                                    {{ item.price | price }}
                                  </span>
                                  <span v-if="item.discount_bool">
                                    {{ item.price_discount | price }}
                                  </span>
                                </span>

                                <span class="green--text" v-else>
                                  Gratis
                                </span>
                              </span>
                            <!-- </div> -->
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-divider v-if="index < list.cart.length - 1" style="margin: 7px 0px 19px 0px"/>
                </v-skeleton-loader>
              </v-card>
            </div>
            <div class="px-5 pb-0">
              <div class="d-flex align-center justify-space-between fs-16 mb-4 mt-5">
                <span class="fs-16 font-weight-bold">Ringkasan Belanja</span>
              </div>

              <div class="d-flex align-center justify-space-between my-3 fs-16">
                <span class="fs-16">Total Harga ({{ selected.cart.length }} Kelas)</span>
                <span class="fs-16 font-weight-medium">{{ parseInt(detail.price) | price}}</span>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center justify-space-between my-3">
                <span class="fs-16">Voucher Diskon <span v-if="Object.keys(voucher).length > 0">({{ voucher.discount_percent }}%)</span></span>
                <span class="fs-16 warning--text" style="font-weight: 900;">
                  <span v-if="Object.keys(voucher).length > 0">-{{ parseInt(voucher.discount) | price }}</span>
                  <span v-if="Object.keys(voucher).length < 1">Rp 0</span>
                </span>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center justify-space-between my-3">
                <span class="fs-16">Biaya Admin</span>
                <span class="fs-16 text-second" style="font-weight: 900;">
                  <span v-if="Object.keys(selected.bank).length > 0">{{ parseInt(selected.bank.admin_fee) | price }}</span>
                  <span v-if="Object.keys(selected.bank).length < 1">Rp 0</span>
                </span>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center justify-space-between mt-3 mb-5">
                <span class="fs-18" style="font-weight: 900;">Total Bayar</span>
                <span class="fs-18" style="font-weight: 900;">
                  <span v-if="Object.keys(voucher).length > 0">
                    {{ selected.bank.admin_fee === undefined ? parseInt(detail.total) - parseInt(voucher.discount) : parseInt(detail.total) - parseInt(voucher.discount) + parseInt(selected.bank.admin_fee) | price }}
                  </span>
                  <span v-if="Object.keys(voucher).length < 1">
                    {{ selected.bank.admin_fee === undefined ? parseInt(detail.total) : parseInt(detail.total) + parseInt(selected.bank.admin_fee) | price }}
                  </span>
                </span>
              </div>

              <!-- <div v-if="Object.keys(voucher).length > 0 ? detail.diskon + parseInt(voucher.discount) > 0 : detail.diskon > 0">
                <v-alert
                  dense
                  text
                  type="info">
                  Anda Menghemat {{ Object.keys(voucher).length > 0 ? detail.diskon + parseInt(voucher.discount) : detail.diskon | price }}
                </v-alert>
              </div> -->
            </div>

            <v-divider style="border-width: 5px; background: #D8D8D8; mix-blend-mode: normal; opacity: 0.2;"></v-divider>

            <div class="px-5">
              <div class="fs-16 taxt-first font-weight-bold text-left" style="margin: 25px 0px 20px 0px">
                Pilih Metode Pembayaran
              </div>
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.bank"
                v-for="(bank, i) in process.bank? 6 : bank" :key="i"
                type="list-item-avatar-two-line">
                <v-list class="py-0 mb_15">
                  <v-list-item class="" style="background: #F9F9F9; border-radius: 6px; padding: 15px 20px;" :style="selected.bank.id === bank.id ? 'border: 1px solid #4ab762' : ''" @click="selected.bank = bank">
                    <v-list-item-content style="max-width: 30px;" class="text-right">
                      <v-icon :color="selected.bank.id === bank.id ? '#4ab762' : 'grey lighten-2'">mdi-check-circle</v-icon>
                    </v-list-item-content>
                    <v-list-item-content class="pt-3 ml-5">
                      <div class="fs-16 font-weight-bold" :style="selected.bank.id === bank.id ? 'color: #4ab762' : 'color: #757575'">
                        {{ bank.label }}
                      </div>
                      <v-list-item-subtitle class="caption mt-1" :style="selected.bank.id === bank.id ? 'color: #757575' : 'color: #BDBDBD'">
                        <div>
                          {{ bank.desc_web }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar
                      width="70"
                      tile
                      style="margin-top: 17px;"
                      height="100%"
                      class="align-self-start">
                      <v-img :src="bank.image" height="30px" class="my-auto" contain></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </v-skeleton-loader>
              <div>
                <v-alert 
                  type="error" 
                  text
                  dense
                  class="mb-2"
                  prominent
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
                  v-show="error.message.length > 0"
                  v-html="error.message">
                </v-alert>
              </div>
            </div>
          </v-card-text>
          <v-card-actions style="padding: 15px 20px; display: grid;">
            <!-- <v-spacer></v-spacer> -->
            <v-btn
              block
              elevation="0"
              color="#4ab762"
              height="50"
              :loading="process.form"
              :disabled="process.form || process.bank || process.checkout || Object.keys(selected.bank).length < 1"
              @click="checkout()"
              class="text-capitalize white--text">
              Checkout
            </v-btn>
            <!-- <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.voucher"
        v-model="dialog.voucher"
        width="450">
        <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
            Pilih Voucher
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.voucher = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-5" style="min-height: 400px;">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.listVoucher"
              v-for="(item, i) in process.listVoucher? 6 : listVoucher" :key="i"
              type="list-item-avatar-two-line">
              <v-list>
                <v-list-item class="px-0" @click="detail_voucher = item; dialog.detail_voucher = true;">
                  <v-list-item-content class="pt-3">
                    <div class="body-2 font-weight-bold">
                      {{ item.name }}
                    </div>
                    <v-list-item-subtitle class="caption mt-1">
                      {{ item.code }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption mt-1">
                      <span class="fs-12 green--text" v-if="item.applicable_bool">
                        Dapat Digunakan
                      </span>
                      <span class="fs-12 red--text" v-if="!item.applicable_bool">
                        Tidak Dapat Digunakan
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pt-3 text-right">
                    <div class="fs-12 text-second">
                      Diskon
                    </div>
                    <v-list-item-subtitle class="fs-12 mt-1 font-weight-bold primary--text">
                      {{ item.discount_percent }}%
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="fs-12 mt-1">
                      <span v-if="parseInt(item.discount_max) > 0">
                        (s.d {{ item.discount_max | price }})
                      </span>
                      <span v-if="parseInt(item.discount_max) === 0">
                        (s.d -)
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content style="max-width: 30px;" class="text-right">
                    <v-icon color="#4ab762">mdi-chevron-right</v-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider v-if="i < listVoucher.length - 1"/>
            </v-skeleton-loader>
            <div v-if="!process.listVoucher && listVoucher.length < 1" class="text-center my-10">
              <v-img 
                :src="require('@/assets/images/empty.png')"
                :lazy-src="require('@/assets/images/empty.png')"
                class="ml-auto mr-auto"
                max-width="33%">
              </v-img>
              <p class="py-8 text-first body-2 font-weight-bold">Voucher Tidak Tersedia</p>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.detail_voucher"
        v-model="dialog.detail_voucher"
        width="450">
        <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5" @click="dialog.detail_voucher = false; dialog.voucher = true;">
            <div class="text-second text--darken-2 font-weight-bold d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
              <v-icon>mdi-arrow-left</v-icon> 
              <div class="ml-2">
                Detail Voucher
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-0" style="min-height: 400px;">
            <div class="px-5 mt-4">
              <div class="body-2">
                Nama
              </div>
              <div class="body-2 font-weight-bold">
                {{ detail_voucher.name }}
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <div class="body-2">
                Kode
              </div>
              <div class="body-2 font-weight-bold">
                {{ detail_voucher.code }}
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Minimal Pembelian
                  </div>
                  <div class="body-2 font-weight-bold warning--text">
                    {{ detail_voucher.min_order | price }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Diskon
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    <span class="color-blue-first">{{ detail_voucher.discount_percent }}%</span> s.d. {{ detail_voucher.discount_max | price }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Kuota Terpakai
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.quota_used }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Kuota Tersedia
                  </div>
                  <div class="body-2 font-weight-bold color-first">
                    {{ detail_voucher.quota_balance }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Dari Total Kuota
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.quota }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Limit Pengguna
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    <span v-if="parseInt(detail_voucher.user_limit) > 0">
                      <span class="warning--text">{{ detail_voucher.user_limit }}</span>x Penggunaan
                    </span>
                    <span v-if="parseInt(detail_voucher.user_limit) === 0">
                      Tidak Ada limit
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Tanggal Mulai
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.start_datetime |datetimemonth }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Tanggal Akhir
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.end_datetime |datetimemonth }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5" v-if="!detail_voucher.applicable_bool">
              <!-- <div class="body-2">
                Kode
              </div>
              <div class="body-2 font-weight-bold">
                {{ detail_voucher.code }}
              </div> -->
              <div class="body-2 red--text" v-html="detail_voucher.applicable_bool_reason">
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn
              block
              elevation="0"
              height="48"
              v-if="detail_voucher.applicable_bool"
              color="#4ab762"
              :loading="process.voucher"
              :disabled="process.voucher"
              @click="addVoucher()"
              class="text-capitalize white--text">
              Pakai Promo
            </v-btn>
            <v-btn
              block
              elevation="0"
              height="48"
              @click="dialog.detail_voucher = false; dialog.voucher = true;"
              v-if="!detail_voucher.applicable_bool"
              color="red"
              class="text-capitalize white--text">
              Promo Tidak dapat Digunakan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>



      <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-1' : 'py-10'">
        <div class="text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
          Elearning | Keranjang
        </div>
        <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title mt-1 mb-3' : 'fs-32 mt-4 mb_25'">
          Keranjang Belanja
        </div>
        <div class="text-second line-text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1 mb-1' : 'fs-18'">
          <b>{{ list.cart.length }}</b> Kelas di Keranjang Belanja Anda
        </div>
        <v-divider style="border: 1.6px solid #4ab762; width: 67px; border-radius: 6px; margin-top: 9px; background: #4ab762;"></v-divider>
        
        <v-row
          justify="center"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-2' : 'mt-7'">
          <v-col cols="12" md="8" sm="8">

            <v-list flat dense class="py-0" v-if="list.cart.length > 0">
              <v-list-item class="px-0">
                <v-list-item-icon style="margin-top: 3px;">
                  <v-icon color="grey" @click="checkAll" v-if="selected.cart.length < 1 || selected.cart.length !== list.cart.length">
                    mdi-checkbox-blank-outline
                  </v-icon>
                  <v-icon color="#4ab762" @click="checkAll" v-if="selected.cart.length > 0 && selected.cart.length === list.cart.length">
                    mdi-checkbox-marked
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pt-0">
                  <v-list-item-title class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                    Pilih Semua
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="pt-0">
                  <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'text-right d-flex align-center justify-end' : 'display-none'">
                    <!-- <v-icon size="20" :color="''">mdi-heart</v-icon> -->
                    <span class="caption text-second">Pindahkan ke wishlist</span>
                    <v-icon color="red" class="ml-2" @click="deleteCartAll()">mdi-delete</v-icon>
                  </div>
                  <v-list-item-title class="fs-14 text-first text-right font-weight-regular" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
                    Pindahkan ke wishlist
                    <span class="ml-3 red--text font-weight-bold" @click="deleteCartAll()">
                      Hapus
                    </span>
                  </v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </v-list>

            <v-divider style="margin-bottom: 26px;"/>

            <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'height: calc(100vh - 520px); overflow-y: auto;'">
              <v-card flat
                v-for="(item, index) in process.cart ? 8 : list.cart"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.cart"
                  type="list-item-avatar-three-line">
                  <div>
                    <v-list flat three-line class="py-0" v-if="!process.cart && list.cart.length > 0">
                      <v-list-item class="px-0">
                        <v-list-item-icon style="margin-right: 21px;" class="my-0">
                          <v-checkbox
                            v-model="selected.cart"
                            color="#4ab762"
                            @click="item.cart_checked_bool = item.cart_checked_bool === true ? false : true; beforeUpdate('one', item);"
                            v-on:change="error.message = ''"
                            :value="item">
                          </v-checkbox>
                          <!-- <v-icon color="#4ab762" v-if="item.sys_kelas_cart_is_checked">mdi-checkbox-marked</v-icon>
                          <v-icon color="grey" v-if="!item.sys_kelas_cart_is_checked">mdi-checkbox-blank-outline</v-icon> -->
                        </v-list-item-icon>

                        <v-list-item-avatar tile size="75" class="mr-3 my-0">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <!-- <div class="badge-overlay-premium" v-if="item.sys_kelas_cart_kelas_is_discount">
                              <span class="top-left-premium badge-premium premium font-weight-bold">
                                {{ parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent) }}%
                              </span>
                            </div> -->
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="py-0">
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-n2" style="line-height: 22px;"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'fs-16'">
                            {{ item.name }}
                          </v-list-item-subtitle>
                          
                          <v-list-item-subtitle class="body-2 mt-n10 mb-0">
                            <v-list-item class="px-0" style="min-height: 20px;">
                              <v-list-item-avatar :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 15 : 15" class="mt-1 mb-0 " style="margin-right: 8px;">
                                <v-img :src="item.organization_img_url" v-if="item.organization_img_url !== ''"></v-img>
                                <v-icon v-if="item.organization_img_url === ''" size="15">mdi-office-building</v-icon>
                              </v-list-item-avatar>
                  
                              <v-list-item-content class="pt-1 pb-0">
                                <v-list-item-title class="text-second fs-14" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">{{ item.organization_name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-subtitle>

                          <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2 mt-1' : 'display-none'">
                            <!-- <span class="text-decoration-line-through text-second mr-2" v-if="item.sys_kelas_cart_kelas_is_discount">
                              {{ detail.price | price }}
                            </span> -->
                            <span class="font-weight-bold fs-16">
                              <span class="color-first" v-if="parseInt(item.price) > 0">
                                {{ item.price | price }}
                              </span>

                              <span class="green--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </p>
                        </v-list-item-content>
                        <v-list-item-content class="align-start text-right py-0" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 40px;' : ''">
                          <v-list-item-title>

                            <div class="text-first body-1 font-weight-bold text-capitalize mt-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''" style="min-height: 48px; margin-bottom: 6px;">
                              <div class="text-decoration-line-through text-second fs-16" v-if="item.discount_bool">
                                {{ item.price | price }}
                              </div>
                              <span v-if="item.discount_bool" class="red--text">
                                (<span v-if="item.discount_percent_admin > 0">{{ item.discount_percent_admin }}%</span><span v-if="item.discount_percent_admin > 0 && item.discount_percent_publisher > 0">+</span><span v-if="item.discount_percent_publisher > 0">{{ item.discount_percent_publisher }}%</span>)
                              </span>
                              <span class="font-weight-bold">
                                <span class="" v-if="parseInt(item.price) > 0">
                                  <span v-if="!item.discount_bool">
                                    {{ item.price | price }}
                                  </span>
                                  <span v-if="item.discount_bool">
                                    {{ item.price_discount | price }}
                                  </span>
                                </span>

                                <span class="green--text" v-else>
                                  Gratis
                                </span>
                              </span>
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle class="mt-n3">
                            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'">
                              <!-- <v-icon size="20" :color="item.wishlisted_bool ? 'pink darken-2' : ''">mdi-heart</v-icon> -->
                              <v-icon color="red" class="ml-2" @click="deleteCart(index, item)">mdi-delete</v-icon>
                            </div>
                            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
                              <span class="mr-2 body-2">
                                <span class="text-second cursor-pointer fs-13" @click="addWishlist(item, index)" v-if="!item.wishlisted_bool">Pindahkan ke wishlist</span>
                                <span class="text-second cursor-pointer fs-13" style="opacity: 0.4;" @click="removeWishlist(item, index)" v-if="item.wishlisted_bool">Sudah ada di wishlist</span>
                              </span>
                              <span class="fs-13 red--text cursor-pointer" @click="deleteCart(index, item)">
                                Hapus 
                              </span>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mb-3' : 'display-none'">
                        <span class="mr-2 caption text-second cursor-pointer" @click="addWishlist(item, index)" v-if="!item.wishlisted_bool">Pindahkan ke wishlist</span>
                        <span class="mr-2 caption text-second cursor-pointer" style="opacity: 0.4;" @click="removeWishlist(item, index)" v-if="item.wishlisted_bool">Sudah ada di wishlist</span>
                      </div>
                    </v-list>
                  </div>

                  <v-divider v-if="index < list.cart.length - 1" style="margin: 7px 0px 19px 0px"/>
                </v-skeleton-loader>
              </v-card>

              <!-- KETIKA KOSONG -->
              <div class="" v-if="!process.cart && list.cart.length < 1">
                <v-img 
                  class="mx-auto"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/images/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#4ab762">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Keranjang mu masih kosong!</p>

                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk lihat kelas belajar yang menarik.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#4ab762"
                    class="text-capitalize white--text"
                    to="/elearning">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Cari Kelas Belajar
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- KELAS LAINNYA  -->
          </v-col>

          <v-col cols="12" md="4" sm="4" class="text-first">
            <v-card flat :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mb-16 pb-5' : 'pl-8'" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: sticky; top: 90px;'">
              <v-skeleton-loader
                v-for="index in process.cart ? 3 : 1"
                :key="index"
                class="ma-auto"
                :loading="process.cart"
                type="article">
                <div>
                  <v-card-text class="pa-0" v-if="!process.cart && list.cart.length > 0">
                    <div class="fs-20 text-second pt-2 d-flex align-center" style="margin-bottom: 10px;" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
                      <div>
                        Total Harga ({{ selected.cart.length }} Kelas)
                      </div>

                      <div class="ml-auto font-weight-bold">
                        {{ parseInt(detail.price) | price}}
                      </div>
                    </div>

                    <v-divider class="mt-2" style="margin-bottom: 19px;"/>

                    <p class="mb-3 text-second fs-18">
                      Diskon / Voucher / Promo
                    </p>

                    <div class="d-flex">
                      <div style="width: 100%;">
                        <v-text-field
                          hide-details
                          clearable
                          color="#4ab762"
                          solo
                          :disabled="selected.cart.length < 1"
                          v-model="filter.voucher"
                          v-on:keyup.enter="addVoucher()"
                          @click:clear="filter.voucher = ''; error.voucher='';"
                          :placeholder="`Enter discount voucher code…`">
                        </v-text-field>
                      </div>
                      <div class="ml_15 text-right">
                        <v-btn 
                          class="body-2 text-capitalize"
                          color="#e67e22" 
                          height="45"
                          width="70"
                          :disabled="selected.cart.length < 1"
                          @click="fetchVoucher(); dialog.voucher = true;"
                          elevation="0">
                          <span class="white--text">
                            Pilih
                          </span>
                        </v-btn>
                      </div>
                    </div>
                    <div v-if="process.voucher">
                      <div class="d-flex align-center justify-center" style="height: 130px">
                        <div class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="grey"
                          ></v-progress-circular>
                          <div class="fs-16 ml-2 text-second text--darken-2 mt-2 font-weigth-bold">
                            Loading….
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!process.voucher">
                      <div v-if="Object.keys(voucher).length > 0" class="mt-5">
                        <v-alert
                          outlined
                          text
                          class="py-2 mb-10"
                          color="warning"
                        >
                          <div class="d-flex align-center">
                            <div class="text-first font-weight-bold">
                              {{ voucher.code }}
                            </div>
                            <div class="ml-auto">
                              <v-icon color="red" @click="voucher = {}; filter.voucher = '';">mdi-close-circle-outline</v-icon>
                            </div>
                          </div>
                          <div class="text-second text--darken-2 mt-1">
                            Discount {{ voucher.discount_percent }}% (-{{ voucher.discount | price }})
                          </div>
                        </v-alert>
                      </div>
                      <div v-if="Object.keys(voucher).length < 1" class="text-second">
                        <div class="d-flex align-center justify-center" style="height: 130px">
                          <div class="text-center" v-if="error.voucher.length > 0">
                            {{ error.voucher }}
                          </div>
                          <div class="text-center" v-if="error.voucher.length < 1">
                            <div v-if="selected.cart.length > 0">
                              Silahkan Pilih Voucher
                            </div>
                            <div v-if="selected.cart.length < 1">
                              Silahkan pilih kelas yang akan di beli
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="mt_15" v-if="voucher">
                      <v-alert
                        outlined
                        text
                        class="py-2"
                        color="warning"
                      >
                        <div class="d-flex align-center">
                          <div class="text-second fs-16" style="font-weight: 900 !important;">
                            X123XCCVF
                          </div>
                          <div class="ml-auto">
                            <v-icon color="red" @click="voucher = false;">mdi-close-circle-outline</v-icon>
                          </div>
                        </div>
                        <div class="text-second text--darken-2 fs-14" style="margin-top: 0px;">
                          Discount 50% (-Rp337.500)
                        </div>
                      </v-alert>
                    </div> -->

                    <v-divider class="mb-4" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''" style="border-width: 1px; margin-top: 60px;"/>

                    <div class="mt-3 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : 'd-flex align-center'" v-if="Object.keys(voucher).length > 0">
                      <div class="body-1 text-first">
                      Voucher Diskon  ({{ voucher.discount_percent }}%)
                      </div>
                      <v-spacer/>
                      <div class="body-1">
                        -{{ parseInt(voucher.discount) | price }}
                      </div>
                    </div>

                    <div class="mt-3 mb-6" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : 'd-flex align-center'">
                      <div class="body-1 text-first font-weight-bold">
                      Total Bayar
                      </div>
                      <v-spacer/>
                      <div class="body-1 font-weight-bold" v-if="Object.keys(voucher).length > 0">
                        {{ parseInt(detail.total) - parseInt(voucher.discount) | price }}
                      </div>
                      <div class="body-1 font-weight-bold" v-if="Object.keys(voucher).length < 1">
                        {{ parseInt(detail.total) | price }}
                      </div>
                    </div>

                    <v-alert 
                      type="error" 
                      text
                      dense
                      :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''"
                      prominent
                      v-show="error.message.length > 0"
                      v-html="error.message">
                    </v-alert>
                    
                    <v-btn
                      elevation="0"
                      block
                      height="60"
                      color="green"
                      :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''"
                      class="fs-22 font-weight-bold text-capitalize white--text"
                      :disabled="process.form || selected.cart.length < 1"
                      :loading="process.form"
                      @click="parseInt(detail.total) === 0 ? checkout() : toCheckout()">
                      {{ `Beli (${selected.cart.length}) Kelas` }}
                    </v-btn>
                  </v-card-text>
                </div>
              </v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>


        <div class="mb-12" style="margin-top: 20px;" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
          <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
            Recently Wishlisted
          </div>
          <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>

          <v-row class="mt-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="3" v-for="(c, k) in list.classes" :key="k" :style="fps === 'xl' ? 'flex: 0 0 20% !important;  max-width: 20% !important;' : ''">
              <v-hover v-slot="{ hover }">
                <v-card flat>
                  <v-img :src="c.img_url" style="height: 250px; border-radius: 15px;" ></v-img> 
                  <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                    {{ c.name }}
                  </div> 
                  <v-list-item class="px-0">
                    <v-list-item-avatar size="25">
                      <v-img :src="c.organization_img_url" v-if="c.organization_img_url !== ''"></v-img>
                      <v-icon v-if="c.organization_img_url === ''">mdi-office-building</v-icon>
                    </v-list-item-avatar>
        
                    <v-list-item-content>
                      <v-list-item-title class="text-second">{{ c.organization_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <div class="d-flex align-center body-2 text-second">
                    <div class="text-first font-weight-bold">
                      5.0
                    </div>
                    <div class="mx-2">
                      <v-rating
                        :value="5"
                        background-color="white"
                        color="#e67e22"
                        dense
                        half-increments
                        hover
                        size="20">
                      </v-rating>
                    </div>
                    <div class="">
                      (1.024)
                    </div>
                  </div> -->
                  <div class="text-first text-h6 font-weight-bold py-2">
                    {{ c.price | price }}
                  </div> 
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                      style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px;">
                      <v-img :src="c.img_url" style="height:180px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                      <div class="px-3">
                        <div class="white--text text-h6 font-weight-bold mb-2 two-line mt-3" style="height: 64px;">
                          {{ c.name }}
                        </div> 
                        <div class="body-2 mb-2 white--text">
                          {{ c.description }}
                        </div>
                        <div class="white--text text--darken-3 text-h6 font-weight-bold py-0">
                          {{ c.price | price }}
                        </div> 
                        <div>
                          <v-btn
                            color="white"
                            :to="`/elearning/detail${c.id}`"
                            width="150"
                            class="text-capitalize">
                            Lihat Kelas
                          </v-btn>
                          <!-- <v-icon color="white" class="mx-2">mdi-cart-plus</v-icon>
                          <v-icon color="white">mdi-heart-outline</v-icon> -->
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-hover>
            </v-col>

            <div v-if="list.classes.length < 1" style="width: 100%;">
              <v-img 
                :src="require('@/assets/images/empty.png')"
                :lazy-src="require('@/assets/images/empty.png')"
                class="ml-auto mr-auto"
                max-width="15%">
              </v-img>
              <p class="py-8 text-first title font-weight-bold text-center">Data wishlist belum tersedia</p>
            </div>
          </v-row>

          <!-- <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-5' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(p, r) in popular"
                :key="r">
                <v-card
                  class="ma-2"
                  height="290"
                  to="/elearning/detail"
                  width="180"
                  flat>
                <v-img :src="p.image" style="width: 180px;" class="mx-auto">
                  <div class="badge-overlay-circle" v-if="p.is_diskon">
                    <span class="top-right-circle badge-circle">
                      <div
                        class="font-weight-bold"
                        style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1);">
                        -50%
                      </div>
                    </span>
                  </div>
                </v-img> 
                  <div class="text-first body-2 font-weight-bold mt-4 mb-1 two-line">
                    {{ p.title }}
                  </div> 
                  <div class="text-first body-2 font-weight-bold py-2" v-if="p.is_diskon">
                    <span class="text-decoration-line-through text-second mr-2">
                      Rp. 150.000
                    </span>
                    <span>
                      Rp 75.000
                    </span>
                  </div>  
                  <div class="text-first body-2 font-weight-bold py-2 color-green-second" v-if="!p.is_diskon">
                    Rp. 150.000
                  </div> 
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet> -->

        </div>
        

        <div class="total-checkout justify-space-between align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex' : 'display-none'">
          <div>
            <div class="body-2 font-weight-bold">TOTAL HARGA</div>
            <span class="body-1 font-weight-bold text-first">
              <span class="body-1 font-weight-bold" v-if="Object.keys(voucher).length > 0">
                {{ parseInt(detail.total) - parseInt(voucher.discount) | price }}
              </span>
              <span class="body-1 font-weight-bold" v-if="Object.keys(voucher).length < 1">
                {{ parseInt(detail.total) | price }}
              </span>
            </span>
          </div>
          <v-btn
            small
            elevation="0"
            color="#4ab762"
            :disabled="process.form || selected.cart.length < 1"
            :loading="process.form"
            @click="parseInt(detail.total) === 0 ? checkout() : toCheckout()"
            class="py-5 white--text text-capitalize">
            <div class="px-4">
              Beli({{ selected.cart.length }})
            </div>
          </v-btn>          
        </div>

      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        items: [
          {
            text: 'Beranda',
            disabled: false,
            href: '/home'
          },
          {
            text: 'Keranjang Saya',
            disabled: true,
            href: '#'
          },
        ],
        coundown_message: "",
        snackbarCoundown: false,
        voucher: {},
        dialog: {
          checkout: false,
          voucher: false,
          detail_voucher: false
        },
        filter: {
          voucher: ""
        },
        listVoucher: [],
        detail_voucher: {},
        model: "",
        selected: {
          cart: [],
          delete: '',
          pagination: 1,
          bank: {}
        },
        list: {
          cart: [],
          classes: []
        },
        nearest_discount_end_datetime: "",
        bank: [],
        snackbar: false,
        timeout: 2000,
        loader: false,
        detail: {
          price: 0,
          total: 0,
          diskon: 0
        },
        process: {
          cart: false,
          classes: false,
          checkout: false,
          update: false,
          bank: false,
          voucher: false,
          listVoucher: false,
          bank: false,
          form: false,
          add: false
        },
        success: {
          message: ''
        },
        error: {
          message: '',
          voucher: ""
        },
        // SEO
        content: {
          url: '/cart',
          title: 'Keranjang',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
    },
    watch: {
      'selected.cart': function(val) {
        let data = this.list.cart.filter(v => val.some(id => id.id === v.id))

        this.detail = {
          price: 0,
          total: 0,
          diskon: 0
        }
        // console.log(data)

        this.cartDetail(data)
      },
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.process.cart = !this.loader ? true : false
        this.$axios.$get(`api/cart`)
        .then((response)=>{
          if(response.status === 200) {
            this.contentEmpty = "Data belum tersedia"
            this.list.cart = response.results.data
            this.nearest_discount_end_datetime = response.results.nearest_discount_end_datetime
            // this.pagination.cart = response.results.pagination
            if (!this.loader) {
              this.fetchWishlist()
            }
            this.loader = false
            // this.process.cart = false
            this.selected.cart = []
            for (let i = 0; i < this.list.cart.length; i++) {
              if (this.list.cart[i].cart_checked_bool === true) {
                this.selected.cart.push(this.list.cart[i])
              }
            }

            this.selected.cart = [...new Set(this.selected.cart)]
            
          }else{
            this.process.cart = false
          }
        })
      },
      fetchBank () {
        this.process.bank = true
        this.$axios.$get(`api/midtrans/payment_methods`)
        .then((response)=>{
          if(response.status === 200) {
            this.bank = response.results.data
            this.process.bank = false
          }else{
            this.process.bank = false
          }
        })
      },
      fetchVoucher () {
        this.process.listVoucher = true
        let id_array = []
        for (let i = 0; i < this.list.cart.length; i++) {
          for (let j = 0; j < this.selected.cart.length; j++) {
            if (this.list.cart[i].id === this.selected.cart[j].id) {
              id_array.push(this.list.cart[i].course_id)
            }
          }
        }
        this.$axios.$post(`api/cart/list_applicable_coupon`,
        {
          course_id_array: id_array
        })
        .then((response)=>{
          if(response.status === 200) {
            this.contentEmpty = "Data belum tersedia"
            this.listVoucher = response.results.data
            this.process.listVoucher = false
          }else{
            this.process.listVoucher = false
          }
        })
      },
      fetchWishlist () {
        let params = {
          limit: 4,
        }
        this.$axios.$get(`api/wishlist`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.cart = false
            this.list.classes = response.results.data
          }else{
            this.process.cart = false
          }
        })
      },

      getDateTime(date) {
        // Set the date we're counting down to
        var countDownDate = new Date(date).getTime();
        countDownDate = countDownDate + 15000

        // Update the count down every 1 second
        var self = this
        var x = setInterval(function() {

          // Get today's date and time
          var now = new Date().getTime();
            
          // Find the distance between now and the count down date
          var distance = countDownDate - now;

          console.log(distance)
          if (distance < 0) {
            self.loader = true
            self.dialog.checkout = false;
            self.fetch()
            self.voucher = {} 
            self.filter.voucher = ''
            self.snackbarCoundown = true
            clearInterval(x);
          }

          if (self.dialog.checkout === false) {
            clearInterval(x);
          }
        }, 1000);
      },

      async deleteCart(index, item) {
        await this.$axios.$post(`api/cart/delete`, 
        {
          id: item.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.list.cart.splice(index, 1)
            this.success.message = "Berhasil dihapus kelas dari keranjang"
            this.snackbar = true
            this.$store.commit('header/setCart', this.headers.cart - 1)
            this.loader = true
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      async deleteCartAll() {
        let idCart = []
        for (let i in this.list.cart) {
          idCart.push(this.list.cart[i].id)
        }
        await this.$axios.$post(`api/cart/delete_bulk`, 
        {
          id_array: idCart
        })
        .then((response) => {
          if (response.status === 200) {
            this.list.cart = []
            this.success.message = "Berhasil dihapus semua kelas dari keranjang"
            this.snackbar = true
            this.$store.commit('header/setCart', 0)
            this.loader = true
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      checkAll() {
        if (this.selected.cart.length < 1) {
          this.checkFor()
        } else {
          if (this.selected.cart.length !== this.list.cart.length) {
            this.checkFor()
          } else {
            this.selected.cart = []
          }
        }

        this.selected.cart = [...new Set(this.selected.cart)]

        this.beforeUpdate('all', undefined)
      },
      checkFor() {
        for (let i = 0; i < this.list.cart.length; i++) {
          this.selected.cart.push(this.list.cart[i])
        }
      },
      cartDetail(data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let item = data[i]

            // JIKA SCHEDULE ID TIDAK SAMA DENGAN "0"
            // if (item.sys_kelas_cart_schedule_id !== '0') {
            //   item.sys_kelas_cart_kelas_diskon_jsonobject.value = (parseInt(item.price) * parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent)) / 100
            // }

            this.detail = {
              price: this.detail.price += parseInt(item.price_discount),
              total: this.detail.total += parseInt(item.price_discount),
              diskon: this.detail.diskon += (parseInt(item.price) - parseInt(item.price_discount))
            }
          }
        } else {
          this.detail = {
            price: 0,
            total: 0,
            diskon: 0
          }
        }
      },

      beforeUpdate(check, item) {
        let data = {
          id: [],
          status: false
        }

        if (check === 'all') {
          for (let i = 0; i < this.list.cart.length; i++) {
            if (check === 'all') {
              data.status = true
              this.list.cart[i].cart_checked_bool = true
            }
  
            if (check === 'all' && this.selected.cart.length < 1) {
              data.status = false
              this.list.cart[i].cart_checked_bool = false
            }
            // this.toSumTotal()
  
            data.id.push(this.list.cart[i].id)
          }
        } else {
          data.id = [item.id],
          data.status = item.cart_checked_bool
        }


        this.updates(data) 
      },

      async updates(data) {
        // console.log(data)
        this.process.add = true
        
        await this.$axios.$post(`api/cart/toggle_bulk`, {
          id_array: data.id,
          check_bool: data.status
        })
        .then((response) => {
          if (response.status === 200) {
            this.loader = true
            this.fetch()
          }
          this.process.add = false
        })
      },


      async addVoucher() {
        this.error.voucher = ""
        this.voucher = {}
        this.process.voucher = true
        let id_array = []
        for (let i = 0; i < this.list.cart.length; i++) {
          for (let j = 0; j < this.selected.cart.length; j++) {
            if (this.list.cart[i].id === this.selected.cart[j].id) {
              id_array.push(this.list.cart[i].course_id)
            }
          }
        }
        await this.$axios.$post(`api/cart/apply_coupon`, 
        {
          code: this.dialog.detail_voucher === true ? this.detail_voucher.code : this.filter.voucher,
          course_id_array: id_array
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.voucher = false
            this.voucher = response.results.data
            this.dialog.detail_voucher = false
            this.dialog.voucher = false
          } else {
            this.process.voucher = false
            if (response.message === 'Voucher tidak valid.') {
              this.error.voucher = "Voucher Belum Tersedia"
            } else {
              this.error.voucher = response.message
            }
          }
        })
      },

      toCheckout() {
        this.selected.bank = {}; 
        this.fetchBank(); 
        this.dialog.checkout = true; 
        this.error.message = '';
        if (this.nearest_discount_end_datetime !== '') {
          if (this.voucher.end_datetime !== undefined) {
            if (new Date(this.nearest_discount_end_datetime) < new Date(this.voucher.end_datetime)) {
              this.getDateTime(this.nearest_discount_end_datetime)
              this.coundown_message = "Diskon kelas yang Anda pilih telah berakhir"
            } else if (new Date(this.nearest_discount_end_datetime) > new Date(this.voucher.end_datetime)) {
              this.getDateTime(this.voucher.end_datetime)
              this.coundown_message = "Voucher yang Anda pilih telah berakhir"
            }
          } else {
            this.getDateTime(this.nearest_discount_end_datetime)
            this.coundown_message = "Diskon kelas yang Anda pilih telah berakhir"
          }
        } else if (this.voucher.end_datetime !== undefined) {
          this.getDateTime(this.voucher.end_datetime)
          this.coundown_message = "Voucher yang Anda pilih telah berakhir"
        }
      },

      async checkout() {
        this.process.form = true
        let id_array = []
        for (let i = 0; i < this.list.cart.length; i++) {
          for (let j = 0; j < this.selected.cart.length; j++) {
            if (this.list.cart[i].id === this.selected.cart[j].id) {
              id_array.push(this.list.cart[i].course_id)
            }
          }
        }
        this.error.message = ''
        await this.$axios.$post(`api//midtrans/checkout`, {
          course_id_array: id_array,
          payment_method_object: parseInt(this.detail.total) === 0 ? {} : this.selected.bank,
          total_paid_nett: parseInt(this.detail.total) === 0 ? parseInt(this.detail.total) : Object.keys(this.voucher).length > 0 ? parseInt(this.detail.total) - parseInt(this.voucher.discount) + parseInt(this.selected.bank.admin_fee) : parseInt(this.detail.total) + parseInt(this.selected.bank.admin_fee),
          coupon_code: Object.keys(this.voucher).length > 0 ? this.voucher.code : ''
        })
        .then((response) => {
          if (response.status === 200) {
            if (parseInt(this.detail.total) === '0' || parseInt(this.detail.total) === 0) {
              this.$router.push(`/account/transaction`)
            } else {
              // this.$router.push(`/purchasing/detail/${response.results.data.transaction_code}`)
              window.location= `/purchasing/${response.results.data.transaction_number}`
            }
            this.process.form = false
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },

      async addWishlist(item, index) {
        await this.$axios.$post(`api/wishlist`, 
        {
          course_id: item.course_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.list.cart[index].wishlisted_bool = true
            this.success.message = "Berhasil ditambahkan ke wishlist"
            this.snackbar = true
            this.loader = true
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
            
          }
        })
      },
      async removeWishlist(item, index) {
        await this.$axios.$post(`api/wishlist/delete`, 
        {
          id: item.user_course_wish_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.list.cart[index].wishlisted_bool = false
            this.success.message = "Berhasil dihapus dari wishlist"
            this.snackbar = true
            this.loader = true
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    }
  }
</script>

<style>
  .total-checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 50px;
    background-color: white;
    padding: 15px;
    box-shadow: 0px -2px 11px -6px #7d7d7d;
  }
</style>
