<template>
  <div>
    <DialogAuth :dialogauth="authDialog" @close="authDialog = ''" />

    <v-dialog v-model="dialog.share"
      width="400"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <div class="d-flex align-center body-2 mb-3">
          <div class="title text-second text--darken-2 font-weight-bold">
            Bagikan Kelas
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.share = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="subtitle-2 font-weight-bold text-second">
              Share Konten
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third" style="font-size: 10px;">
              Klik icon di bawah untuk share Konten ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                v-if="token"
                :network="item.network"
                :url="`${host}elearning/${detail_kelas.id}`"
                :title="detail_kelas.name"
                :description="detail_kelas.description"
                hashtags="otodidak">
                <v-img
                  alt="otodidak"
                  width="45"
                  height="45"
                  contain
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
              <nuxt-link v-else to="/login">
                <v-img
                  class="cursor-pointer"
                  width="45"
                  height="45"
                  contain
                  :src="item.icon">
                </v-img>
              </nuxt-link>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#4ab762"
            elevation="0"
            class="caption white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -20px;"
      :color="'warning'">
      <div class="body-1 font-weight-bold">Maaf Fiture Belum Tersedia</div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <div v-if="process.run">
        <section class="py-14">
          <v-row class="align-center">
            <v-col cols="12" md="6">
              <v-skeleton-loader
                style="width: 100%;"
                type="image"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <div class="pb-5">
                <v-skeleton-loader
                style="width: 100%;"
                type="text"
              ></v-skeleton-loader>
              </div>
              <v-skeleton-loader
                style="width: 100%;"
                type="paragraph"
              ></v-skeleton-loader>
              <v-skeleton-loader
                style="width: 100%;"
                type="paragraph"
              ></v-skeleton-loader>
              <v-skeleton-loader
                style="width: 100%;"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

        <section class="py-5">
          <!-- <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Mau Belajar Apa Hari ini?
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div> -->
          <v-row class="align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="3" v-for="c in 4" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

        <section class="py-14">
          <v-row class="align-center">
            <v-col cols="12" md="6">
              <div class="pb-5">
                <v-skeleton-loader
                style="width: 100%;"
                type="text"
              ></v-skeleton-loader>
              </div>
              <v-skeleton-loader
                style="width: 100%;"
                type="paragraph"
              ></v-skeleton-loader>
              <v-skeleton-loader
                style="width: 100%;"
                type="paragraph"
              ></v-skeleton-loader>
              <v-skeleton-loader
                style="width: 100%;"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                style="width: 100%;"
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

        <section class="py-14">
          <!-- <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Mau Belajar Apa Hari ini?
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div> -->
          <v-row class="align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="3" v-for="c in 4" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>


        <section class="py-14">
          <!-- <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Mau Belajar Apa Hari ini?
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div> -->
          <v-row class="align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="3" v-for="c in 4" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

        <section class="py-14">
          <!-- <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Mau Belajar Apa Hari ini?
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div> -->
          <v-row class="align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="c in 3" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="c in 3" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="c in 3" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>


        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="c in 3" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>


        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="c in 3" :key="c">
              <v-skeleton-loader
                style="width: 100%;"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>

      </div>
      <div v-if="!process.run">
        <section class="py-14">
          <v-row class="align-center">
            <v-col cols="12" md="6">
              <v-img :src="require('@/assets/publichome/header_image.png')" ></v-img>  
            </v-col>
            <v-col cols="12" md="6">
              <v-carousel v-model="model" hide-delimiters :show-arrows="false" height="auto">
                <v-carousel-item
                  v-for="(elearning, i) in detail.course_new"
                  :key="i">
                  <div class="text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                    Elearning | {{ elearning.category_name }}
                  </div>
                  <div class="text-first font-weight-bold my-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
                    {{ elearning.name }}
                  </div>
                  <div class="text-second line-text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ elearning.description }}
                  </div>
                  <div class="d-flex align-center text-second my-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                    <div class="text-first font-weight-bold">
                      {{ elearning.review_average }}
                    </div>
                    <div class="mx-2">
                      <v-rating
                        :value="elearning.review_average*1"
                        readonly
                        background-color="grey"
                        color="#e67e22"
                        dense
                        half-increments
                        hover
                        size="20">
                      </v-rating>
                    </div>
                    <div class="">
                      ({{ elearning.review_count }})
                    </div>
                  </div>
                  <div class="mb-5">
                    <v-btn 
                      elevation="0"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      color="#4ab762"
                      :to="`/elearning/${elearning.id}`"
                      class="mr-3 mt-2 white--text text-capitalize font-weight-bold">
                      Detail kelas
                    </v-btn>
                    <v-btn 
                      elevation="0"
                      outlined
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      color="#000000"
                      @click="!token ? authDialog = 'login' : dialog.share = true; detail_kelas = elearning;"
                      class="mr-3 mt-2 text-capitalize">
                      <v-icon size="15" class="mr-2">mdi-share-variant</v-icon>
                      Bagikan
                    </v-btn>
                    <v-btn 
                      elevation="0"
                      outlined
                      @click="!token ? authDialog = 'login' : snackbar = true"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      color="#d35400"
                      class="mr-3 mt-2 text-capitalize">
                      <v-icon size="15" class="mr-2">mdi-heart-outline</v-icon>
                      Wishlist
                    </v-btn>
                  </div>
                </v-carousel-item>
              </v-carousel>
              <div class="text-left ml-n2"> 
                <v-btn 
                  v-for="(item, i) in detail.course_new"
                  :key="i"
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                  elevation="0"
                  x-small
                  fab
                  :color="model === i ? '#4ab762' : '#5baf6036'"
                  class="ma-2"
                  @click="model = i">
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </section>

        <section class="py-5">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Mau Belajar Apa Hari ini?
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <router-link to="/category" class="text-second">
                <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              </router-link>
                <v-btn 
                  class="px-0" 
                  min-width="36" 
                  elevation="0"
                  to="/category"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                  color="#ececec78">
                  <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
                </v-btn>
            </div>
          </div>
          <v-row class="align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="3" v-for="(c, l) in detail.category" :key="l">
              <v-card flat :to="`/elearning?category=${c.id}`">
                <v-img :src="c.img_url" style="border-radius: 15px;" height="270"></v-img> 
                <div class="text-first text-h5 text-center font-weight-bold mt-4 mb-1">
                  {{ c.name }}
                </div> 
                <div class="text-center text-second">
                  ({{ c.count }})
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model1"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(c, l) in detail.category" :key="l">
                <v-card
                  class="ma-2"
                  height="210"
                  width="150"
                  :to="`/elearning?category=${c.id}`"
                  flat>
                <v-img :src="c.img_url" style="width: 150px;" height="150" class="mx-auto"></v-img> 
                  <div class="text-first body-2 text-center font-weight-bold mt-4 mb-1">
                    {{ c.name }}
                  </div> 
                  <div class="text-center text-second caption">
                    ({{ c.count }})
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <div v-if="detail.category && detail.category.length < 1">
            <div style="height: 100px" class="d-flex align-center justify-center text-second">
              Kategori Belum Tersedia
            </div>
          </div>
        </section>
      
        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-8' : 'py-14'">
          <v-row class="align-center">
            <v-col cols="12" md="6">
              <v-carousel v-model="model2" hide-delimiters :show-arrows="false" height="auto">
                <v-carousel-item
                  v-for="(elearning, i) in detail.organization_official_partner"
                  :key="i">
                  <div class="text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'body-2'">
                    Official Partner
                  </div>
                  <div class="text-first font-weight-bold my-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
                    Start Learning Online Courses with {{ elearning.name }}
                  </div>
                  <div class="text-second line-text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                    {{ elearning.description_short }}
                  </div>
                  <div class="my-5">
                    <v-btn 
                      elevation="0"
                      outlined
                      :to="`/organitation/${elearning.id}`"
                      color="#4ab762"
                      class="mr-3 text-capitalize font-weight-bold">
                      View All Course
                    </v-btn>
                  </div>
                </v-carousel-item>
              </v-carousel>
              <div class="text-left ml-n2"> 
                <v-btn 
                  v-for="(item, i) in detail.organization_official_partner"
                  :key="i"
                  :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                  :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 10 : 15"
                  elevation="0"
                  x-small
                  fab
                  :color="model2 === i ? '#4ab762' : '#5baf6036'"
                  class="ma-2"
                  @click="model2 = i">
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="6" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
              <v-card flat>
                <v-img :src="require('@/assets/publichome/content_image_2.png')" style="width: 70%;" class="ml-auto"></v-img>  
                <div style="position: absolute; bottom: -70px; left: 70px;">
                  <v-img :src="require('@/assets/publichome/content_image_5.png')" style="width: 55%;"></v-img>  
                </div>
              </v-card>
            </v-col>
          </v-row>
        </section>
      
        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-5 pt-10'">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Kelas Populer & Terlaris
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
                <span class="cursor-pointer">
                  <router-link to="/elearning" class="text-second">
                    Lihat Semua
                  </router-link>
                </span>
              </span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                to="/elearning"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>

          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="3" v-for="(l, index) in detail.course_popular" :key="index" style="margin-bottom: 45px;">
              <v-hover v-slot="{ hover }">
                <v-card flat>
                  <v-img :src="l.img_url" style="border-radius: 15px;" height="270">
                    <div class="badge-overlay-circle" v-if="l.discount_bool">
                      <span class="top-right-circle badge-circle">
                        <div
                          class="font-weight-bold "
                          style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                          <span v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</span> 
                          <span v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</span>
                          <span v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</span>
                        </div>
                      </span>
                    </div>
                  </v-img> 
                  <div class="text-first fs-18 font-weight-bold mt-4 two-line" style="height: 48px;">
                    {{ l.name }}
                  </div> 
                  <v-list-item class="px-0">
                    <v-list-item-avatar size="25" color="#ebebeb">
                      <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                      <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                    </v-list-item-avatar>
        
                    <v-list-item-content>
                      <v-list-item-title class="text-second fs-14">{{ l.organization_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="d-flex align-center body-2 text-second">
                    <div class="text-first font-weight-bold">
                      {{ l.review_average }}
                    </div>
                    <div class="mx-2">
                      <v-rating
                        :value="l.review_average*1"
                        background-color="grey"
                        color="#e67e22"
                        dense
                        readonly
                        half-increments
                        hover
                        size="20">
                      </v-rating>
                    </div>
                    <div class="">
                      ({{ l.review_count }})
                    </div>
                  </div>
                  <div class="text-first fs-16 font-weight-bold pt-2">
                    <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                      {{ l.price | price }}
                    </span>
                    <span class="font-weight-bold red--text">
                      <span class="text-first" v-if="parseInt(l.price) > 0">
                        <span v-if="!l.discount_bool">
                          {{ l.price | price }}
                        </span>
                        <span v-if="l.discount_bool">
                          {{ l.price_discount | price }}
                        </span>
                      </span>

                      <span class="green--text" v-else>
                        Gratis
                      </span>
                    </span>
                  </div> 
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                      style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px; line-height: unset !important;">
                      <v-img :src="l.img_url" style="height:200px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                      <div class="px-3">
                        <div class="white--text fs-18 font-weight-bold mb-2 one-line mt-3" style="line-height: 1.5;">
                          {{ l.name }}
                        </div> 
                        <div class="body-2 mb-2 white--text mt-2 three-line" style="height: 60px;">
                          {{ l.description }}
                        </div>
                        <div class="white--text text--darken-3 fs-16 font-weight-bold py-1">
                          <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                            {{ l.price | price }}
                          </span>
                          <span class="font-weight-bold">
                            <span class="white--text" v-if="parseInt(l.price) > 0">
                              <span v-if="!l.discount_bool">
                                {{ l.price | price }}
                              </span>
                              <span v-if="l.discount_bool">
                                {{ l.price_discount | price }}
                              </span>
                            </span>

                            <span class="white--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div> 
                        <div class="d-flex align-center">
                          <div>
                            <v-btn
                              color="white"
                              width="150"
                              height="40"
                              elevation="0"
                              :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                              class="text-capitalize">
                              Lihat Kelas
                            </v-btn>
                          </div>
                          <div class="ml-auto">
                            <v-icon color="white" size="25" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" class="ml-3" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                            <span v-if="token">
                              <v-icon size="25" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                              <v-icon size="25" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                            </span>
                            <span v-if="!token">
                              <v-icon size="25" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model3"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(p, r) in detail.course_popular"
                :key="r">
                <v-card
                  class="ma-2"
                  height="290"
                  to="/elearning/detail"
                  width="180"
                  flat>
                  <v-img :src="p.img_url" style="width: 180px; border-radius: 15px;" height="180" class="mx-auto">
                    <div class="badge-overlay-circle" v-if="p.discount_bool">
                      <span class="top-right-circle-small badge-circle-small">
                        <div
                          class="font-weight-bold"
                          style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                          <div v-if="p.discount_percent_admin > 0">{{ p.discount_percent_admin }}%</div> 
                          <div v-if="p.discount_percent_admin > 0 && p.discount_percent_publisher > 0">+</div>
                          <div v-if="p.discount_percent_publisher > 0">{{ p.discount_percent_publisher }}%</div>
                        </div>
                      </span>
                      </div>
                  </v-img> 
                  <div class="text-first body-2 font-weight-bold mt-4 mb-1 two-line">
                    {{ p.name }}
                  </div> 
                  <div class="text-first body-2 font-weight-bold py-2" >
                    <span class="text-decoration-line-through text-second mr-2">
                      Rp. 150.000
                    </span>
                    <span>
                      Rp 75.000
                    </span>
                  </div>  
                  <div class="text-first body-2 font-weight-bold py-2 color-green-second" v-if="!p.is_diskon">
                    Rp. 150.000
                  </div> 
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <div v-if="detail.course_popular && detail.course_popular.length < 1">
            <div style="height: 100px" class="d-flex align-center justify-center text-second">
              Kelas Belum Tersedia
            </div>
          </div>

        </section>
      
        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Berdasarkan Pencarianmu
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
                <span class="cursor-pointer">
                  <router-link to="/elearning" class="text-second">
                    Lihat Semua
                  </router-link>
                </span>
              </span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                to="/elearning"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="3" v-for="(l, index) in detail.course_searched" :key="index" style="margin-bottom: 45px;">
              <v-hover v-slot="{ hover }">
                <v-card flat>
                  <v-img :src="l.img_url" style="border-radius: 15px;" height="270">
                    <div class="badge-overlay-circle" v-if="l.discount_bool">
                      <span class="top-right-circle badge-circle">
                        <div
                          class="font-weight-bold "
                          style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                          <span v-if="l.discount_percent_admin > 0">{{ l.discount_percent_admin }}%</span> 
                          <span v-if="l.discount_percent_admin > 0 && l.discount_percent_publisher > 0">+</span>
                          <span v-if="l.discount_percent_publisher > 0">{{ l.discount_percent_publisher }}%</span>
                        </div>
                      </span>
                    </div>
                  </v-img> 
                  <div class="text-first fs-18 font-weight-bold mt-4 two-line" style="height: 48px;">
                    {{ l.name }}
                  </div> 
                  <v-list-item class="px-0">
                    <v-list-item-avatar size="25" color="#ebebeb">
                      <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                      <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                    </v-list-item-avatar>
        
                    <v-list-item-content>
                      <v-list-item-title class="text-second fs-14">{{ l.organization_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="d-flex align-center body-2 text-second">
                    <div class="text-first font-weight-bold">
                      {{ l.review_average }}
                    </div>
                    <div class="mx-2">
                      <v-rating
                        :value="l.review_average*1"
                        background-color="grey"
                        color="#e67e22"
                        dense
                        readonly
                        half-increments
                        hover
                        size="20">
                      </v-rating>
                    </div>
                    <div class="">
                      ({{ l.review_count }})
                    </div>
                  </div>
                  <div class="text-first fs-16 font-weight-bold pt-2">
                    <span class="text-decoration-line-through text-second mr-2" v-if="l.discount_bool">
                      {{ l.price | price }}
                    </span>
                    <span class="font-weight-bold red--text">
                      <span class="text-first" v-if="parseInt(l.price) > 0">
                        <span v-if="!l.discount_bool">
                          {{ l.price | price }}
                        </span>
                        <span v-if="l.discount_bool">
                          {{ l.price_discount | price }}
                        </span>
                      </span>

                      <span class="green--text" v-else>
                        Gratis
                      </span>
                    </span>
                  </div> 
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="transition-fast-in-fast-out v-card--reveal text-h2 white--text align-start"
                      style="height: 100%; background-color: #4ab762; opacity: 100%; border-radius: 15px; line-height: unset !important;">
                      <v-img :src="l.img_url" style="height:200px; border-radius: 15px 15px 0px 0px;" ></v-img> 
                      <div class="px-3">
                        <div class="white--text fs-18 font-weight-bold mb-2 one-line mt-3" style="line-height: 1.5;">
                          {{ l.name }}
                        </div> 
                        <div class="body-2 mb-2 white--text mt-2 three-line" style="height: 60px;">
                          {{ l.description }}
                        </div>
                        <div class="white--text text--darken-3 fs-16 font-weight-bold py-1">
                          <span class="text-decoration-line-through white--text mr-2" v-if="l.discount_bool">
                            {{ l.price | price }}
                          </span>
                          <span class="font-weight-bold">
                            <span class="white--text" v-if="parseInt(l.price) > 0">
                              <span v-if="!l.discount_bool">
                                {{ l.price | price }}
                              </span>
                              <span v-if="l.discount_bool">
                                {{ l.price_discount | price }}
                              </span>
                            </span>

                            <span class="white--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div> 
                        <div class="d-flex align-center">
                          <div>
                            <v-btn
                              color="white"
                              width="150"
                              height="40"
                              elevation="0"
                              :to="token && l.subscribed_bool && !l.subscribtion_expired_bool ? `/collection/${l.user_course_id}` : `/elearning/${l.id}`"
                              class="text-capitalize">
                              Lihat Kelas
                            </v-btn>
                          </div>
                          <div class="ml-auto">
                            <v-icon color="white" size="25" v-if="(!l.subscribed_bool && !l.subscribtion_expired_bool) || (l.subscribed_bool && l.subscribtion_expired_bool)" class="ml-3" @click="detail = l; !token ? authDialog = 'login' : dialog.cart = true; error.message = '';">mdi-cart-plus</v-icon>

                            <span v-if="token">
                              <v-icon size="25" color="white" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                              <v-icon size="25" :color="'pink'" @click="!token ? authDialog = 'login' : l.course_wish_id.length < 1 ? addWishlist(l, index) : removeWishlist(l, index)" v-if="l.course_wish_id !== ''">mdi-heart</v-icon>
                            </span>
                            <span v-if="!token">
                              <v-icon size="25" color="white" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model3"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(p, r) in detail.course_searched"
                :key="r">
                <v-card
                  class="ma-2"
                  height="290"
                  to="/elearning/detail"
                  width="180"
                  flat>
                  <v-img :src="p.img_url" style="width: 180px; border-radius: 15px;" height="180" class="mx-auto">
                    <div class="badge-overlay-circle" v-if="p.discount_bool">
                      <span class="top-right-circle-small badge-circle-small">
                        <div
                          class="font-weight-bold"
                          style="transform: translateX(0%) translateY(15%) rotate(45deg) scaleX(-1); margin-left: 95px;">
                          <div v-if="p.discount_percent_admin > 0">{{ p.discount_percent_admin }}%</div> 
                          <div v-if="p.discount_percent_admin > 0 && p.discount_percent_publisher > 0">+</div>
                          <div v-if="p.discount_percent_publisher > 0">{{ p.discount_percent_publisher }}%</div>
                        </div>
                      </span>
                      </div>
                  </v-img> 
                  <div class="text-first body-2 font-weight-bold mt-4 mb-1 two-line">
                    {{ p.name }}
                  </div> 
                  <div class="text-first body-2 font-weight-bold py-2" >
                    <span class="text-decoration-line-through text-second mr-2">
                      Rp. 150.000
                    </span>
                    <span>
                      Rp 75.000
                    </span>
                  </div>  
                  <div class="text-first body-2 font-weight-bold py-2 color-green-second" v-if="!p.is_diskon">
                    Rp. 150.000
                  </div> 
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <div v-if="detail.course_searched && detail.course_searched.length < 1">
            <div style="height: 100px" class="d-flex align-center justify-center text-second">
              Kelas Belum Tersedia
            </div>
          </div>

        </section>
      
        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-7' : 'py-16 my-10'">
          <div class="text-first text-center font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
            Belajar Bisa Dapat Poin Lo, Mau?
          </div>
          <div class="text-second text-center mt-4 mb-10" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h5'">
            Dapatkan 10% Diskon untuk temanmu + 1 Kelas Gratis & Akun Premium untukmu
          </div>
          <v-row>
            <v-col cols="12" md="4">
              <v-img :src="require('@/assets/publichome/content_image_9.png')" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 35%;' : 'width:75%;'" class="mx-auto"></v-img> 
              <div class="text-first text-center font-weight-bold mt-11 mb-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                Ajak Temanmu untuk Belajar
              </div> 
              <div class="text-center text-second body-1" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : ''">
                Salin dan bagikan link referensimu di media sosial atau kirim ajakan ke temanmu melalui email
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-img :src="require('@/assets/publichome/content_image_10.png')" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 35%;' : 'width:75%;'" class="mx-auto"></v-img> 
              <div class="text-first text-center font-weight-bold mt-4 mb-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                Ajak Temanmu untuk Belajar
              </div> 
              <div class="text-center text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                Salin dan bagikan link referensimu di media sosial atau kirim ajakan ke temanmu melalui email
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-img :src="require('@/assets/publichome/content_image_11.png')" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 35%;' : 'width:75%;'" class="mx-auto"></v-img> 
              <div class="text-first text-center font-weight-bold mt-4 mb-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                Ajak Temanmu untuk Belajar
              </div> 
              <div class="text-center text-second" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                Salin dan bagikan link referensimu di media sosial atau kirim ajakan ke temanmu melalui email
              </div>
            </v-col>
          </v-row>
          <div class="my-10 text-center">
            <v-btn 
              elevation="0"
              outlined
              :large="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? false : true"
              :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
              color="grey darken-1"
              class="text-capitalize font-weight-bold px-6">
              Baca Sarat & Ketentuan
            </v-btn>
          </div>
        </section>
      
        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
            <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 5%;' : 'width: 2%'">
              <v-img :src="require('@/assets/icon/icon_power_merchant.svg')" ></v-img> 
            </div>
            <div class="d-flex align-center ml-2" style="width: 98%">
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h6'">
                  Official Partner
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
              </div>
              <div class="text-second ml-auto">
                <router-link to="/organitation?category=Partner" class="text-second">
                  <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
                </router-link>
                <v-btn 
                  class="px-0" 
                  min-width="36" 
                  to="/organitation?category=Partner"
                  elevation="0"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                  color="#ececec78">
                  <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="(f, r) in detail.organization_official_partner" :key="r">
              <v-card flat :to="`/organitation/${f.id}`">
                <v-img :src="f.img_url" height="210" style="border-radius: 15px;" :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" contain v-if="f.img_url !== ''"></v-img> 
                <v-avatar style="border-radius: 10px;" width="100%" height="210" color="#4ab762" v-if="f.img_url === ''">
                  <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                </v-avatar>
                <div class="d-flex align-center mt-5 mb-1">
                  <div>
                    <div class="text-first text-h6 font-weight-bold">
                      {{ f.name }}
                    </div>
                  </div>
                  <div class="text-second ml-auto">
                    ({{ f.total_course }})
                  </div>
                </div>
                <div class="text-second body-1">
                  {{ f.description_short }}
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model5"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(f, r) in detail.organization_official_partner" :key="r">
                <v-card
                  class="ma-2"
                  height="200"
                  :to="`/organitation/${f.id}`"
                  width="180"
                  flat>
                  <v-img :src="f.img_url" contain width="100%" height="180"  v-if="f.img_url !== ''"></v-img> 
                  <v-avatar style="border-radius: 10px;" width="100%" height="180" color="#4ab762" v-if="f.img_url === ''">
                    <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                  </v-avatar>
                  <div class="d-flex align-center mt-3 mb-1">
                    <div>
                      <div class="text-first body-2 font-weight-bold">
                        {{ f.name }}
                      </div>
                    </div>
                    <div class="text-second ml-auto caption">
                      ({{ f.total_course }})
                    </div>
                  </div>
                  <div class="text-second caption">
                    {{ f.description_short }}
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <div v-if="detail.organization_official_partner && detail.organization_official_partner.length < 1">
            <div style="height: 100px" class="d-flex align-center justify-center text-second">
              Official Partner Belum Tersedia
            </div>
          </div>
          
        </section>

        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
            <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 5%;' : 'width: 2%'">
              <v-img :src="require('@/assets/icon/icon_official_merchant.svg')" ></v-img> 
            </div>
            <div class="d-flex align-center ml-2" style="width: 98%">
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h6'">
                  Power Merchant
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
              </div>
              <div class="text-second ml-auto">
                <router-link to="/organitation?category=Merchant" class="text-second">
                  <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
                </router-link>
                <v-btn 
                  class="px-0" 
                  min-width="36" 
                  to="/organitation?category=Merchant"
                  elevation="0"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                  color="#ececec78">
                  <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="(f, r) in detail.organization_power_merchant" :key="r">
              <v-card flat :to="`/organitation/${f.id}`">
                <v-img :src="f.img_url" height="210" style="border-radius: 15px;" :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" contain v-if="f.img_url !== ''"></v-img> 
                <v-avatar style="border-radius: 10px;" width="100%" height="210" color="#4ab762" v-if="f.img_url === ''">
                  <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                </v-avatar>
                <div class="d-flex align-center mt-5 mb-1">
                  <div>
                    <div class="text-first text-h6 font-weight-bold">
                      {{ f.name }}
                    </div>
                  </div>
                  <div class="text-second ml-auto">
                    ({{ f.total_course }})
                  </div>
                </div>
                <div class="text-second body-1">
                  {{ f.description_short }}
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model5"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(f, r) in detail.organization_power_merchant" :key="r">
                <v-card
                  class="ma-2"
                  height="200"
                  :to="`/organitation/${f.id}`"
                  width="180"
                  flat>
                  <v-img :src="f.img_url" contain width="100%" height="180"  v-if="f.img_url !== ''"></v-img> 
                  <v-avatar style="border-radius: 10px;" width="100%" height="180" color="#4ab762" v-if="f.img_url === ''">
                    <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                  </v-avatar>
                  <div class="d-flex align-center mt-3 mb-1">
                    <div>
                      <div class="text-first body-2 font-weight-bold">
                        {{ f.name }}
                      </div>
                    </div>
                    <div class="text-second ml-auto caption">
                      ({{ f.total_course }})
                    </div>
                  </div>
                  <div class="text-second caption">
                    {{ f.description_short }}
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <div v-if="detail.organization_power_merchant && detail.organization_power_merchant.length < 1">
            <div style="height: 100px" class="d-flex align-center justify-center text-second">
              Power Merchant Belum Tersedia
            </div>
          </div>

        </section>


        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
            <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 5%;' : 'width: 2%'">
              <v-img :src="require('@/assets/icon/icon_official_merchant.svg')" ></v-img> 
            </div>
            <div class="d-flex align-center ml-2" style="width: 98%">
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'text-h6'">
                  Personal
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
              </div>
              <div class="text-second ml-auto">
                <router-link to="/organitation?category=Personal" class="text-second">
                  <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
                </router-link>
                <v-btn 
                  class="px-0" 
                  min-width="36" 
                  to="/organitation?category=Personal"
                  elevation="0"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                  color="#ececec78">
                  <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="(f, r) in detail.organization_personal" :key="r">
              <v-card flat :to="`/organitation/${f.id}`">
                <v-img :src="f.img_url" height="210" style="border-radius: 15px;" :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" contain v-if="f.img_url !== ''"></v-img> 
                <v-avatar style="border-radius: 10px;" width="100%" height="210" color="#4ab762" v-if="f.img_url === ''">
                  <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                </v-avatar>
                <div class="d-flex align-center mt-5 mb-1">
                  <div>
                    <div class="text-first text-h6 font-weight-bold">
                      {{ f.name }}
                    </div>
                  </div>
                  <div class="text-second ml-auto">
                    ({{ f.total_course }})
                  </div>
                </div>
                <div class="text-second body-1">
                  {{ f.description_short }}
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model5"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(f, r) in detail.organization_personal" :key="r">
                <v-card
                  class="ma-2"
                  height="200"
                  width="180"
                  :to="`/organitation/${f.id}`"
                  flat>
                  <v-img :src="f.img_url" contain width="100%" height="180"  v-if="f.img_url !== ''"></v-img> 
                  <v-avatar style="border-radius: 10px;" width="100%" height="180" color="#4ab762" v-if="f.img_url === ''">
                    <v-icon size="100" color="white" v-if="f.img_url === ''">mdi-office-building</v-icon>
                  </v-avatar>
                  <div class="d-flex align-center mt-3 mb-1">
                    <div>
                      <div class="text-first body-2 font-weight-bold">
                        {{ f.name }}
                      </div>
                    </div>
                    <div class="text-second ml-auto caption">
                      ({{ f.total_course }})
                    </div>
                  </div>
                  <div class="text-second caption">
                    {{ f.description_short }}
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <div v-if="detail.organization_personal && detail.organization_personal.length < 1">
            <div style="height: 100px" class="d-flex align-center justify-center text-second">
              Power Merchant Belum Tersedia
            </div>
          </div>

        </section>

        <section :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'py-5' : 'pb-16 pt-10'">
          <div class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? ' mt-0 pb-5' : 'mt-5 pb-10'">
            <div>
              <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h6'">
                Apa Kata Mereka
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
            </div>
            <!-- <div class="text-second ml-auto">
              <span :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">Lihat Semua</span>
              <v-btn 
                class="px-0" 
                min-width="36" 
                elevation="0"
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                color="#ececec78">
                <v-icon color="#a0c7b0">mdi-chevron-right</v-icon>
              </v-btn>
            </div> -->
          </div>
          <v-row :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
            <v-col cols="12" md="4" v-for="(r, l) in detail.course_review" :key="l">
              <v-card
                flat
                height="390"
                class="border-radius box-shadow">
                <v-card-title>
                  <v-avatar
                    tile
                    size="40">
                    <v-img
                      contain
                      :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                    </v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-text class="body-2 line-text-second text-second d-flex align-center" style="height: 350px;">
                  {{ r.text }}
                </v-card-text>
                <div class="list-bottom">
                  <v-card-title class="pt-0">
                    <v-avatar
                      size="50"
                      color="#4ab762">
                      <v-img :src="r.user_img_url" v-if="r.user_img_url !== ''"></v-img>
                      <v-icon v-if="r.user_img_url === ''" color="white">mdi-account</v-icon>
                    </v-avatar>
                  </v-card-title>
                  <v-card-title class="subtitle-2 text-second pt-0 pb-1">
                    {{ r.user_name }}
                  </v-card-title>
                  <!-- <v-card-title class="subtitle-2 pt-0 pb-5 text-second">
                    Los Angeles
                  </v-card-title> -->
                </div>
              </v-card>
            </v-col>
    
          </v-row>

          <v-sheet
            class="mx-auto"
            elevation="0"
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
            max-width="800">
            <v-slide-group
              v-model="model7"
              class="pa-0"
              :show-arrows="false">
              <v-slide-item
                v-for="(r, a) in detail.course_review"
                :key="a">
                <v-card
                  height="350"
                  class="border-radius box-shadow ma-2"
                  :style="$vuetify.breakpoint.name === 'sm' ? 'width: 350px;' : 'width: calc(100vw - 40px);'"
                  flat>
                  <v-card-title>
                    <v-avatar
                      tile
                      size="20">
                      <v-img
                        contain
                        :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                      </v-img>
                    </v-avatar>
                  </v-card-title>
                  <v-card-text class="caption line-text-second text-second">
                    {{ r.text }}
                  </v-card-text>
                  <div class="list-bottom">
                    <v-card-title class="pt-0">
                      <v-avatar
                        size="50">
                        <v-img :src="r.user_img_url" v-if="r.user_img_url !== ''"></v-img>
                        <v-icon v-if="r.user_img_url === ''" color="white">mdi-account</v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-title class="caption text-second pt-0 pb-1">
                      {{ r.name }}
                    </v-card-title>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

        </section>
      </div>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        authDialog: '',
        year: {
          selected: "2021",
          items: ["2021", "2020", "2019", "2018"]
        },
        dialog: {
          share: false
        },
        snackbar: false,
        timeout: 3000,
        model: 0,
        model1: 0,
        model2: 0,
        model3: 0,
        model4: 0,
        model5: 0,
        model6: 0,
        model7: 0,
        detail: {},
        detail_kelas: {},
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          // { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        category: [
          {
            title: "Programming",
            image: require('@/assets/publichome/content_image_1.png'),
            count: "1.024"
          },
          {
            title: "Research & Ideas",
            image: require('@/assets/publichome/content_image_2.png'),
            count: "1.024"
          },
          {
            title: "Art & Designs",
            image: require('@/assets/publichome/content_image_3.png'),
            count: "1.024"
          },
          {
            title: "Science",
            image: require('@/assets/publichome/content_image_4.png'),
            count: "1.024"
          }
        ],
        popular: [
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "React Native: Advanced Concepts",
            image: require('@/assets/publichome/content_image_7.png'),
            price: "Rp. 150.000",
            is_diskon: true,
            diskon_value: 50,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_7.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          }
        ],
        searching: [
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "React Native: Advanced Concepts",
            image: require('@/assets/publichome/content_image_7.png'),
            price: "Rp. 150.000",
            is_diskon: true,
            diskon_value: 50,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_6.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_power_merchant.svg'),
            rating: "5"
          },
          {
            title: "Socket.IO Chat App in React Native for iOS/Android",
            image: require('@/assets/publichome/content_image_4.png'),
            price: "Rp. 150.000",
            is_diskon: false,
            diskon_value: 0,
            institution_name: "Era Solusi Data",
            institution_image: require('@/assets/icon/icon_official_merchant.svg'),
            rating: "5"
          }
        ],
        partner: [
          {
            title: "ESODA",
            image: require('@/assets/publichome/content_image_12.png'),
            count: "1.024",
            desc: "Lorem Ipsum is simply dummy text of the printing."
          },
          {
            title: "Era Solusi Data",
            image: require('@/assets/publichome/content_image_13.png'),
            count: "150",
            desc: "Lorem Ipsum is simply dummy text of the printing."
          },
          {
            title: "Amila Community",
            image: require('@/assets/publichome/content_image_14.png'),
            count: "4",
            desc: "Lorem Ipsum is simply dummy text of the printing."
          }
        ],
        review: [
          {
            desc: "I believe that everything happens for a reason. People change so that you can learn to let go, things go wrong so that you appreciate them when they're right, you believe lies so you eventually learn to trust no one but yourself, and sometimes good things fall apart so better things can fall together.",
            name: "Marilyn Monroe",
            image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            address: "Los Angeles"
          },
          {
            desc: "I believe that everything happens for a reason. People change so that you can learn to let go, things go wrong so that you appreciate them when they're right, you believe lies so you eventually learn to trust no one but yourself, and sometimes good things fall apart so better things can fall together.",
            name: "Marilyn Monroe",
            image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            address: "Los Angeles"
          },
          {
            desc: "I believe that everything happens for a reason. People change so that you can learn to let go, things go wrong so that you appreciate them when they're right, you believe lies so you eventually learn to trust no one but yourself, and sometimes good things fall apart so better things can fall together.",
            name: "Marilyn Monroe",
            image: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            address: "Los Angeles"
          }
        ],
        process: {
          run: true
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      DialogAuth: () => import('@/components/dialog/auth'),
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      host () {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.process.run = true
        // let params = {
        //   content_type: 'donasi',
        //   sort: 'donation_end_datetime',
        //   dir: 'ASC'
        // }
        this.$axios.$get(`api/frontpage`)
        .then((response)=>{
          // console.log('data response', response)
          if(response.status === 200) {
            this.process.run = false
            this.detail = response.results.data
            this.contentEmpty = 'Data tidak tersedia'
          }else{
            this.process.run = false
          }
        })
      },
    }
  }
</script>
