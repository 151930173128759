<template>
  <div>
    <DialogAuth :dialogauth="dialog" :email="form.email" @close="dialog = ''" />

    <div :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'height: calc(100vh - 65px);' : ''" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'pa-0' : ''">
      <v-card 
        :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '100vh' : '100vh'"
        :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '100vw' : ''"
        color="#fff">
        <v-row class="ma-0" justify="center" style="height: 100%;" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center'">

          <v-col cols="12" md="7" class="pa-0" :class="fps === 'xs' || fps === 'sm' ? 'px-0' : 'px-16 mt-3'">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-3' : ''">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 85 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  text
                  to="/home"
                  elevation="0"
                  class="color-green-second"
                  :disabled="process.form || process.register || process.login_sso">
                  <u class="color-green-second">Home</u>
                </v-btn>
              </v-card-text>
            </div>
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? error.message !== '' ? 'mt-2' : '' : 'display-none'"></v-divider>
            <div :class="fps === 'xs' || fps === 'sm' ? 'px-5 pt-9' : 'px-10'" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'height: calc(100vh - 145px); overflow-y: auto;' : ''">
              <div :class="fps === 'xs' || fps === 'sm' ? '' : 'px-16'">
                <div class="text-center text-first" :class="fps === 'xs' || fps === 'sm' ? 'body-1 font-weight-bold' : 'title'">
                  Login ke Akun Anda
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto" :class="fps === 'xs' || fps === 'sm' ? 'mt-2' : 'mt-5'"></v-divider>
                <div class="text-center" :class="fps === 'xs' || fps === 'sm' ? 'my-5' : 'my-6'">
                  <!-- <v-btn
                    outlined
                    :width="fps === 'xs' || fps === 'sm' ? 40 : 55"
                    :height="fps === 'xs' || fps === 'sm' ? 40 : 55"
                    fab
                    color="grey darken-2"
                    elevation="0"
                    class="px-3 mx-2">
                    <v-icon color="grey darken-4" :size="fps === 'xs' || fps === 'sm' ? 29 : 37">mdi-facebook</v-icon>
                  </v-btn> -->
                  <v-btn
                    outlined
                    :width="fps === 'xs' || fps === 'sm' ? 40 : 55"
                    :height="fps === 'xs' || fps === 'sm' ? 40 : 55"
                    fab
                    :disabled="process.form || process.register || process.login_sso"
                    :loading="process.login_sso"
                    color="grey darken-2"
                    elevation="0"
                    @click="google('login')"
                    class="px-3 mx-2">
                    <v-icon color="grey darken-4" :size="fps === 'xs' || fps === 'sm' ? 24 : ''">mdi-google</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    outlined
                    :width="fps === 'xs' || fps === 'sm' ? 40 : 55"
                    :height="fps === 'xs' || fps === 'sm' ? 40 : 55"
                    fab
                    color="grey darken-2"
                    elevation="0"
                    class="px-3 mx-2">
                    <v-icon color="grey darken-4" :size="fps === 'xs' || fps === 'sm' ? 24 : ''">mdi-linkedin</v-icon>
                  </v-btn> -->
                </div>
                <div class="text-second text--darken-2 mb-5 text-center" :class="fps === 'xs' || fps === 'sm' ? 'body-2' : 'body-1'">
                  Atau gunakan akun email Anda
                </div>
                <v-form
                  class="mb-1">
                  <ValidationObserver ref="observer">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        placeholder="Email"
                        :disabled="process.form || process.register || process.login_sso"
                        prepend-inner-icon="mdi-email-outline"
                        v-model="form.email"
                        v-on:keyup.enter="save('login')"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Password" rules="required|min:5|max:20" v-slot="{ errors }">
                      <v-text-field
                        solo
                        color="#4ab762"
                        placeholder="Password"
                        v-on:keyup.enter="save('login')"
                        :disabled="process.form || process.register || process.login_sso"
                        prepend-inner-icon="mdi-form-textbox-password"
                        v-model="form.password"
                        :type="show.password ? 'text' : 'password'"
                        :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password = !show.password"
                        :counter="20"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </ValidationObserver>
                </v-form>
                <div class="d-flex align-center">
                  <div>
                    <v-checkbox class="mt-0 pt-0" :dense="fps === 'xs' || fps === 'sm' ? true : false" hide-details color="#4ab762">
                      <template v-slot:label>
                        <div class="font-weight-bold text--darken-4 text-first" :class="fps === 'xs' || fps === 'sm' ? 'body-2' : 'body-1'">
                          Ingat Saya
                        </div>
                      </template>
                    </v-checkbox>

                  </div>
                  <div class="font-weight-bold text--darken-4 ml-auto" :class="fps === 'xs' || fps === 'sm' ? 'body-2' : 'body-1'">
                    <span class="cursor-pointer text-first" @click="dialog = 'reset'; reset(); $refs.observer.reset();">Lupa Password?</span>
                  </div>
                </div>
                <div class="text-center mt-7">
                  <v-alert 
                    type="error" 
                    text
                    dense
                    prominent
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>
                  <v-btn
                    width="200"
                    color="#4ab762"
                    elevation="0"
                    :disabled="process.form || process.register || process.login_sso"
                    :loading="process.form"
                    @click="save('login')"
                    class="caption font-weight-bold white--text">
                    Login
                  </v-btn>
                  <div v-if="fps !== 'lg' && fps !== 'md' && fps !== 'xl'">
                    <div class="d-flex align-center mt-3 mb-4">
                      <v-divider/>
                      <span class="caption font-weight-bold text-first mx-3">
                        Atau
                      </span>
                      <v-divider/>
                    </div>

                    <v-btn
                      width="250"
                      outlined
                      color="#4ab762"
                      elevation="0"
                      @click="google('register')"
                      :disabled="process.form || process.register || process.login_sso"
                      :loading="process.register"
                      class="caption font-weight-bold">
                      <v-avatar size="20">
                        <v-img
                          :src="require('@/assets/icon/login_google.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height mt-0"
                              align="end"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :size="20"
                                :width="2"
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="primary--text ml-3">Buat Akun Dengan google</span>
                    </v-btn>
                  </div>

                </div>

                <div class="text-second body-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'margin-top: 20px; text-align: center;' : 'position: fixed; bottom: 5px; width: calc(100vw - 940px); text-align: center;'">
                  Privacy Policy |  Term & Conditions
                </div>
              </div>
            </div>
          </v-col>

          <v-col :cols="5" v-if="fps !== 'xs' && fps !== 'sm'" class="pa-0 d-flex align-center justify-center" style="background: #4ab762; height: 100%;">
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 15px; left: -15px;">
              <v-btn
                text
                to="/home"
                elevation="0"
                class="white--text"
                :disabled="process.form || process.register || process.login_sso">
                <u>Home</u>
              </v-btn>
            </v-card-text>
            <div class="px-7">
              <div class="text-center title white--text">
                Hallo, kawan
              </div>
              <v-divider style="border: 2px solid #FFF; width: 67px; border-radius: 6px;" class="mx-auto my-5"></v-divider>
              <div class="white--text body-1 text-center mb-5">
                Mau belajar tapi belum punya Akun atau bingung mau daftar, yuk daftar sekarang disini
              </div>
              <div class="text-center">
                <v-btn
                  width="250"
                  color="#FFF"
                  elevation="0"
                  @click="google('register')"
                  :disabled="process.form || process.register || process.login_sso"
                  :loading="process.register"
                  class="caption font-weight-bold">
                  <v-avatar size="20">
                    <v-img
                      :src="require('@/assets/icon/login_google.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height mt-0"
                          align="end"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :size="20"
                            :width="2"
                            color="#4ab762">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                  <span class="primary--text ml-3">Buat Akun Dengan google</span>
                </v-btn>
                <!-- <v-btn
                  width="250"
                  color="#4ab762"
                  elevation="0"
                  @click="google()"
                  class="caption font-weight-bold white--text">
                  <v-icon color="white" :size="fps === 'xs' || fps === 'sm' ? 20 : '22'" class="mr-2">mdi-google</v-icon>
                  Buat Akun Dengan google
                </v-btn> -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        dialog: '',
        show: {
          password: false,
          password_confirm: false
        },
        form: {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          token_email: '',
          role: 'user',
          image: ''
        },
        form_google: {
          name: '',
          email: '',
          device: 'web',
          image: ''
        },
        process: {
          run: false,
          form: false,
          register: false,
          login_sso: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/login',
          title: 'Masuk',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      // this.fetch()
    },
    methods: {
      reset() {
        this.form = {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          token_email: '',
          role: 'user',
          image: ''
        }
        
        this.form_google = {
          name: '',
          email: '',
          device: 'web',
          image: ''
        }

        this.process.run = false

        this.error.message = ''
      },
      async google(type) {
        this.process.register = type === 'register' ? true : false
        this.process.login_sso = type === 'login' ? true : false
        this.error.message = ''
        let provider = new this.$firebase.auth.GoogleAuthProvider()
        await this.$firebase.auth().signInWithPopup(provider)
        .then(response => {
          // let data = response.additionalUserInfo.profile
          // this.form_google.name = data.name
          // this.form_google.email = data.email
          // this.form_google.image = data.picture
          // this.form_google.device = 'web'

          // let datas = {
          //   email: this.form_google.email,
          //   name: this.form_google.name,
          // }

          let data = response
          this.form.name = data.additionalUserInfo.profile.name
          this.form.email = data.additionalUserInfo.profile.email
          this.form.image = data.additionalUserInfo.profile.picture
          this.form.token_email = data.credential.idToken
          this.form.device = 'web'

          let datas = {
            email: this.form.email,
            name: this.form.name,
          }


          console.log(data)

          // let hashData = sha256(JSON.stringify(datas)).toString().toLowerCase()
          // let signatureData = `L06INS0O:WEB:${hashData}:${process.env.API_SIGNATURE}`
          // this.form_google.signature = hmacSHA256(signatureData, 'K0l0niaW3bPl4tform_google').toString()
          
          this.loginSSO(type)
        }).catch(error => {
          this.$snotify.error(error.message)
          console.log(error)
        })
      },
      async loginSSO() {
        this.process.run = true

        await this.$axios.$post(`api/login/sso`, 
        {
          email: this.form.email,
          name: this.form.name,
          img_url: this.form.image,
          token: this.form.token_email,
          device : "web"
        }).then((response) => {
          if (response.status === 200) {
            this.process.register = false
            this.process.login_sso = false
            console.log(response.results.data)
            Cookie.set('user', response.results.data)
            Cookie.set('token', response.results.token)

            window.location = '/home'
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      async save(type) {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          if (type === 'register') {
            this.register()
          } else if (type === 'verification') {
            this.verification()
          } else if (type === 'login') {
            this.login()
          } else {
            this.resetPassword()
          }
        }
      },
      async login(){
        this.process.form = true

        // let data = {
        //   username: this.form.username,
        //   password: this.form.password
        // }
        
        // let hashData = sha256(JSON.stringify(data)).toString().toLowerCase()
        // let signatureData = `L0G1N:WEB:${hashData}:${process.env.API_SIGNATURE}`
        // this.form.signature = hmacSHA256(signatureData, 'K0l0niaW3bPl4tform').toString()

        await this.$axios.$post(`api/login`,{
          credential: this.form.email,
          password: this.form.password,
          device : "web"
        }).then((response)=>{
          console.log('ini data respon',response)
          if (response.status === 200) {
            Cookie.set('user', response.results.data)
            Cookie.set('token', response.results.token)
            window.location = '/home'
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    },
  }
</script>
