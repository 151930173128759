<template>
  <div>
    <v-container>
      
      <section>
        <v-row class="mb-4">
          <v-col :cols="9" class="pt-0">
            <div class="d-flex align-center" style="height: 100%;">
              <div class="mr-3" style="height: 98%;">
                <v-btn
                  outlined
                  small
                  min-width="30"
                  height="100%"
                  class="px-0"
                  style="background-color: #FFFFFF"
                  to="/account/product"
                  color="red">
                  <v-icon color="red">mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
                  <span>Tambah Kelase</span>
                </div>
                <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
                  <span>Lengkapi data untuk menembah produk baru dibawah ini</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
      </section>

      <section>
        <ValidationObserver ref="observer">
          <v-expansion-panels flat v-model="panel" multiple :readonly="process.form || process.avatar">
            <v-expansion-panel class="border_radius_7" style="border: 1px solid #ebebeb; margin-bottom: 20px; padding: 25px 30px" v-if="this.$store.state.member.auth.user.is_publisher_bool">
              <v-expansion-panel-header style="background: #f9f9f994; height: auto; min-height: auto;" class="pa-0">
                <v-list dense class="py-0" color="transparent">
                  <v-list-item class="py-0 pl-0">
                    <div style="width: 100%;">
                      <div class="body-1 font-weight-bold text-capitalize">
                        Informasi Kelas
                      </div>
                      <div class="text-second ml-auto" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                        Lengkapi informasi kelas Anda.
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
                <template v-slot:actions>
                  <div>
                    <v-icon color="#7B7B7B">
                      $expand
                    </v-icon>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="non-padding-expansion-bottom" style="background: #f9f9f994; padding-top: 32px; padding-bottom: 14px;">
                <div v-if="process.run" style="height: 300px;" class="d-flex align-center justify-center">
                  <v-progress-circular
                    :size="50"
                    color="#4ab762"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div v-if="!process.run">
                  <div style="padding: 0px;">
                    <General :detailClass="detail" :detailCategory="category" :detailOrganisasi="organisasi" :detailEtalase="etalase" :detailLevel="level" @fetch="detail"/>
                  </div>
                  <!-- <div class="pt-3">
                    <Rekening :detailProfile="form" @progress="process.form = $event" />
                  </div>

                  <di class="pt-3">
                    <Organitation :detailProfile="form" @progress="process.form = $event" />
                  </di> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="border_radius_7" style="border: 1px solid #ebebeb; margin-bottom: 20px; padding: 25px 30px" v-if="this.$store.state.member.auth.user.is_publisher_bool">
              <v-expansion-panel-header style="background: #f9f9f994; height: auto; min-height: auto;" class="pa-0">
                <v-list dense class="py-0" color="transparent">
                  <v-list-item class="py-0 pl-0">
                    <div style="width: 100%;">
                      <div class="body-1 font-weight-bold text-capitalize">
                        Harga Kelas
                      </div>
                      <div class="text-second ml-auto" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                        Lengkapi harga kelas Anda.
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
                <template v-slot:actions>
                  <div>
                    <v-icon color="#7B7B7B">
                      $expand
                    </v-icon>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="non-padding-expansion-bottom" style="background: #f9f9f994; padding-top: 35px; padding-bottom: 14px;">
                <div v-if="process.run" style="height: 300px;" class="d-flex align-center justify-center">
                  <v-progress-circular
                    :size="50"
                    color="#4ab762"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div v-if="!process.run">
                  <div style="padding: 0px;">
                    <Price :detailClass="detail" @addData="detail.access_month = $event"/>
                  </div>
                  <!-- <div class="pt-3">
                    <Rekening :detailProfile="form" @progress="process.form = $event" />
                  </div>

                  <di class="pt-3">
                    <Organitation :detailProfile="form" @progress="process.form = $event" />
                  </di> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="border_radius_7" style="border: 1px solid #ebebeb; margin-bottom: 20px; padding: 25px 30px" v-if="this.$store.state.member.auth.user.is_publisher_bool">
              <v-expansion-panel-header style="background: #f9f9f994; height: auto; min-height: auto;" class="pa-0">
                <v-list dense class="py-0" color="transparent">
                  <v-list-item class="py-0 pl-0">
                    <div style="width: 100%;">
                      <div class="body-1 font-weight-bold text-capitalize">
                        Materi Kelas
                      </div>
                      <div class="text-second ml-auto" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                        Lengkapi Materi Kelas Anda.
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
                <template v-slot:actions>
                  <div>
                    <v-icon color="#7B7B7B">
                      $expand
                    </v-icon>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="non-padding-expansion-bottom" style="background: #f9f9f994; padding-top: 25px; padding-bottom: 14px;">
                <div v-if="process.run" style="height: 300px;" class="d-flex align-center justify-center">
                  <v-progress-circular
                    :size="50"
                    color="#4ab762"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div v-if="!process.run">
                  <div style="padding: 0px;">
                    <Materi :detailClass="detail.content" @fetch="detail.content"/>
                  </div>
                  <!-- <div class="pt-3">
                    <Rekening :detailProfile="form" @progress="process.form = $event" />
                  </div>

                  <di class="pt-3">
                    <Organitation :detailProfile="form" @progress="process.form = $event" />
                  </di> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </ValidationObserver>
        <v-checkbox color="#4ab762" v-model="detail.active_bool">
          <template v-slot:label>
            <div class="text-second">
              Kelas publish
            </div>
          </template>
        </v-checkbox>
        <v-alert
          text
          v-if="error.info.length > 0"
          class="fs-14"
          style="margin-top: 17px;"
          color="warning">
          {{ error.info }}
        </v-alert>
        <v-alert
          text
          v-if="error.materi.length > 0"
          class="fs-14"
          style="margin-top: 17px;"
          color="warning">
          {{ error.materi }}
        </v-alert>
        <v-alert
          text
          v-if="error.message.length > 0"
          class="fs-14"
          style="margin-top: 17px;"
          color="error">
          {{ error.message }}
        </v-alert>
        <v-row>
          <v-col cols="12" md="3" class="pb-0 pt_28">
            <v-btn
              elevation="0"
              color="#4ab762"
              block
              @click="save()"
              :disabled="process.form"
              :loading="process.form"
              class="text-capitalize white--text px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  <span v-if="detail.id === ''">Buat</span><span v-if="detail.id !== ''">Simpan</span> Kelas Ini
                </div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" class="pb-0 pt_30">
            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.form"
              block
              class="text-capitalize white--text">
              Hapus
            </v-btn>
          </v-col>
          <v-col cols="12" md="7" class="pb-0 pt_30 text-right">
            <v-btn
              elevation="0"
              color="primary"
              outlined
              :block="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
              @click="toPreview"
              :disabled="process.form"
              width="110"
              class="text-capitalize">
              Preview
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        panel: [0],
        category: [],
        etalase: [],
        level: [],
        organisasi: [],
        detail: {
          id: "",
          level: "",
          category_id: {},
          group_id: {},
          name: "",
          img_url: "",
          active_bool: false,
          subject: "",
          description: "",
          description_long: "",
          price: "",
          access_month: 0,
          organization_id: "",
          content: [
            // {
            //   "title":"",
            //   "img_url":"",
            //   "file_url":"",
            //   "type":"",
            //   "description":"",
            //   "active_bool":true,
            //   "process_img": false,
            //   "process_file": false,
            //   "sub":[]
            // },
          ]
        },
        process: {
          form: false,
          run: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          info: "",
          materi: ""
        },
        // SEO
        content: {
          url: '/account/product',
          title: 'Produk Elearning',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      General: () => import('@/components/elearning/general'),
      Price: () => import('@/components/elearning/price'),
      Materi: () => import('@/components/elearning/materi/index'),
    },
    mounted () {
      if (this.$route.query.data !== undefined) {
        this.fetchDetail()
      } else {
        this.fetchCategory()
      }
    },
    methods: {
      fetchDetail () {
        this.process.run = true
        let params = {
          id: this.$route.query.data
        }
        this.$axios.$get(`api/publisher/course/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.fetchCategory()
            this.detail = response.results.data
            this.detail.category_id = {
              id: this.detail.category_id.toString(),
              name: this.detail.category_name
            }
            this.detail.group_id = {
              id: this.detail.group_id.toString(),
              name: this.detail.group_name
            }
            this.detail.organization_id = this.detail.organization_id.toString()
          }else{
            this.process.run = false
          }
        })
      },
      fetchCategory () {
        this.process.run = true
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/course_category`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.category = []
            for (let i in response.results.data) {
              this.category.push(
                {
                  id: response.results.data[i].id,
                  img_url: response.results.data[i].img_url,
                  name: response.results.data[i].name
                }
              ) 
              
            }
            this.category.push(
              {
                id: null,
                name: 'Tulis Lainnya'
              }
            ) 
            this.fetchEtalase()
          }else{
            this.process.run = false
          }
        })
      },
      fetchEtalase () {
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/publisher/course_group`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.etalase = []
            for (let i in response.results.data) {
              this.etalase.push(
                {
                  id: response.results.data[i].id,
                  name: response.results.data[i].name
                }
              ) 
            }
            this.etalase.push(
              {
                id: null,
                name: 'Tulis Lainnya'
              }
            ) 
            this.fetchLevel()
          }else{
            this.process.run = false
          }
        })
      },
      fetchLevel () {
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/course_level`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.fetchOrganisasi()
            this.level = response.results.data
          }else{
            this.process.run = false
          }
        })
      },
      fetchOrganisasi () {
        let params = {
          limit: 1000000,
        }
        this.$axios.$get(`api/publisher/organization`, { params })
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.process.run = false
            this.organisasi = response.results.data
          }else{
            this.process.run = false
          }
        })
      },
      async save(){
        this.error.message = ''
        this.error.info = ''
        this.error.materi = ''
        let data_submateri = false
        let data_materi = false
        for (let i in this.detail.content) {
          if (this.detail.content[i].title === '') {
            data_materi = true
          }
          for (let j in this.detail.content[i].sub) {
            if (this.detail.content[i].sub[j].title === '' || this.detail.content[i].sub[j].file_url === '') {
              data_submateri = true
            }
          }
          
        }
        if (
          this.detail.name === "" ||
          this.detail.img_url === "" ||
          this.detail.subject === "" ||
          this.detail.description === "" ||
          this.detail.description_long === "" ||
          this.detail.price === "" ||
          this.detail.access_month === "" 
        ) {
          this.error.info = "Lengkapi data atau informasi harga kelas"
        }
        if (this.detail.content.length < 1 || data_materi || data_submateri) {
          this.error.materi = "Lengkapi data materi yang akan dipelajari padaa kelas ini"
        }
        const isValid = await this.$refs.observer.validate()
        // const valids = this.validation()
        if (this.error.info === '' && this.error.materi === '') {
          if (this.detail.category_id.id === null ) {
            this.addCategory()
          } else if (this.detail.group_id.id === null) {
            this.addEtalase()
          } else if (this.detail.group_id.id !== null && this.detail.category_id.id !== null) {
            if (this.detail.id !== '') {
              this.updates()
            } else {
              this.creates()
            }
          }
        }
      },
      async toPreview() {
        this.error.message = ''
        this.error.info = ''
        this.error.materi = ''
        let data_submateri = false
        let data_materi = false
        for (let i in this.detail.content) {
          if (this.detail.content[i].title === '') {
            data_materi = true
          }
          for (let j in this.detail.content[i].sub) {
            if (this.detail.content[i].sub[j].title === '' || this.detail.content[i].sub[j].file_url === '') {
              data_submateri = true
            }
          }
          
        }
        if (
          this.detail.name === "" ||
          this.detail.img_url === "" ||
          this.detail.subject === "" ||
          this.detail.description === "" ||
          this.detail.description_long === "" ||
          this.detail.price === "" ||
          this.detail.access_month === "" 
        ) {
          this.error.info = "Lengkapi data atau informasi harga kelas"
        }
        if (this.detail.content.length < 1 || data_materi || data_submateri) {
          this.error.materi = "Lengkapi data materi yang akan dipelajari padaa kelas ini"
        }
        const isValid = await this.$refs.observer.validate()
        // const valids = this.validation()
        if (this.error.info === '' && this.error.materi === '') {
          // this.$store.commit('preview/setContent', this.detail)
          // TokenService.setContent(
          //   JSON.stringify(this.detail)
          // );

          // storeToken(token){
            localStorage.setItem("setContent", JSON.stringify(this.detail));
          // }
          window.open(`/preview`)
        }
      },
      async addCategory() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_category`, 
        {
          name: this.detail.category_id.name
        })
        .then((response) => {
          if (response.status === 200) {
            this.detail.category_id.id = response.results.data.id
            if (this.detail.group_id.id === null) {
              this.addEtalase()
            } else {
              // if (this.detail.id !== '') {
              //   this.updates()
              // } else {
              //   this.creates()
              // }
            }
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async addEtalase() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_group`, 
        {
          name: this.detail.group_id.name,
          course_id_array: [],
          img_url: ""
        })
        .then((response) => {
          if (response.status === 200) {
            this.detail.group_id.id = response.results.data.id
            if (this.detail.id !== '') {
              this.updates()
            } else {
              this.creates()
            }
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async creates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course`, 
        {
          level: this.detail.level,
          category_id: this.detail.category_id.id,
          group_id: this.detail.group_id.id,
          name: this.detail.name,
          organization_id: this.detail.organization_id,
          img_url: this.detail.img_url,
          subject: this.detail.subject,
          description: this.detail.description,
          description_long: this.detail.description_long,
          price: this.detail.price,
          access_month: this.detail.access_month,
          content_array: this.detail.content,
          active_bool: this.detail.active_bool
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.$router.push('/account/product')
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        let toArrayMateri = []
        for (let i in this.detail.content) {
          toArrayMateri.push({
            "title":this.detail.content[i].title,
            "img_url":this.detail.content[i].img_url,
            "file_url":this.detail.content[i].file_url,
            "type":this.detail.content[i].type,
            "id":this.detail.content[i].id,
            "description":this.detail.content[i].description,
            "active_bool":true,
            "process_img": false,
            "process_file": false,
            "preview": this.detail.content[i].preview_bool === undefined ? this.detail.content[i].preview : this.detail.content[i].preview_bool,
            "sub": []
          })
          for (let j in this.detail.content[i].sub) {
            toArrayMateri[i].sub.push({
              "title":this.detail.content[i].sub[j].title,
              "img_url":this.detail.content[i].sub[j].img_url,
              "file_url":this.detail.content[i].sub[j].file_url,
              "type":this.detail.content[i].sub[j].type,
              "id":this.detail.content[i].sub[j].id,
              "description":this.detail.content[i].sub[j].description,
              "active_bool":true,
              "process_img": false,
              "process_file": false,
              "preview": this.detail.content[i].sub[j].preview_bool === undefined ? this.detail.content[i].sub[j].preview : this.detail.content[i].sub[j].preview_bool
            })
          }
          
        }
        console.log(toArrayMateri)
        this.process.form = true
        await this.$axios.$post(`api/publisher/course/update`, 
        {
          id: this.detail.id,
          level: this.detail.level,
          category_id: this.detail.category_id.id,
          group_id: this.detail.group_id.id,
          name: this.detail.name,
          organization_id: this.detail.organization_id,
          img_url: this.detail.img_url,
          subject: this.detail.subject,
          description: this.detail.description,
          description_long: this.detail.description_long,
          price: this.detail.price,
          access_month: this.detail.access_month,
          content_array: toArrayMateri,
          active_bool: this.detail.active_bool
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.$router.push('/account/product')
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>