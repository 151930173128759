<template>
  <div>

    <DialogAuth :dialogauth="reset" :email="form.email" @close="reset = ''" />

     <v-dialog v-model="dialog.success"
      width="500"
      persistent>
      <v-card 
        height="650"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0 align-center" justify="center" style="height: 100%;">

          <v-col cols="12" class="pa-0">
            <div class="px-10">
              <div class="text-center font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
                Ubah Password
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="300"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second text-center font-weight-bold mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'text-h4'">
                Ubah Password Sukses
              </div>
              <div class="text-second body-1 text-center mb-5">
                Silahkan Klik tomboh dibawah ini untuk login
              </div>
              <div class="text-center">
                <v-btn
                  width="250"
                  color="#4ab762"
                  elevation="0"
                  to="/login"
                  class="caption font-weight-bold white--text">
                  Login
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-container :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'height: calc(100vh - 65px);' : 'height: 100vh; width: 100%;'" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'pa-0' : 'd-flex align-center'">
      <v-card 
        :height="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '100vh' : '600'"
        :width="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '100vw' : ''"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center'">

          <v-col cols="12" md="7" class="pa-0" :class="fps === 'xs' || fps === 'sm' ? 'px-3' : 'px-16 mt-3'">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3' : ''">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  text
                  to="/home"
                  elevation="0"
                  class="color-green-second"
                  :disabled="process.form">
                  <u class="color-green-second">Home</u>
                </v-btn>
              </v-card-text>
            </div>
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? error.message !== '' ? 'mt-2' : '' : 'display-none'"></v-divider>
            <div :class="fps === 'xs' || fps === 'sm' ? 'px-2 mt-9' : 'px-10'">
              <div class="text-center" :class="fps === 'xs' || fps === 'sm' ? 'body-1 font-weight-bold' : 'title'">
                Ubah Passwod Anda
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto" :class="fps === 'xs' || fps === 'sm' ? 'mt-2' : 'mt-5'"></v-divider>
              <div class="text-second text--darken-2 mb-5 text-center" :class="fps === 'xs' || fps === 'sm' ? 'body-2 mt-5' : 'body-1 mt-8'">
                Passwod berupa kombinasi haruf dan angka
              </div>
              <v-form
                class="mb-1"
                :class="fps === 'xs' || fps === 'sm' ? 'mt-5' : 'mt-5'">
                <ValidationObserver ref="observer">
                  <ValidationProvider name="Password" vid="confirm" rules="required|min:5|max:20" v-slot="{ errors }">
                    <v-text-field
                      solo
                      color="#4ab762"
                      placeholder="Password"
                      v-on:keyup.enter="resetPassword()"
                      :disabled="process.form"
                      prepend-inner-icon="mdi-form-textbox-password"
                      v-model="form.password"
                      :type="show.password ? 'text' : 'password'"
                      :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show.password = !show.password"
                      :counter="20"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Komfirmasi Password" rules="required|min:5|max:20|confirmed:confirm" v-slot="{ errors }">
                    <v-text-field
                      solo
                      color="#4ab762"
                      placeholder="Komfirmasi Password"
                      v-on:keyup.enter="resetPassword()"
                      :disabled="process.form"
                      prepend-inner-icon="mdi-form-textbox-password"
                      v-model="form.password_confirm"
                      :type="show.password_confirm ? 'text' : 'password'"
                      :append-icon="show.password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show.password_confirm = !show.password_confirm"
                      :counter="20"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </ValidationObserver>
              </v-form>
              <!-- <div class="d-flex align-center">
                <div>
                  <v-checkbox class="mt-0 pt-0" :dense="fps === 'xs' || fps === 'sm' ? true : false" hide-details color="#4ab762">
                    <template v-slot:label>
                      <div class="font-weight-bold text-second text--darken-4" :class="fps === 'xs' || fps === 'sm' ? 'body-2' : 'body-1'">
                        Ingat Saya
                      </div>
                    </template>
                  </v-checkbox>

                </div>
                <div class="font-weight-bold text-second text--darken-4 ml-auto" :class="fps === 'xs' || fps === 'sm' ? 'body-2' : 'body-1'">
                  <span class="cursor-pointer" @click="dialog = 'reset'; reset(); $refs.observer.reset();">Lupa Password?</span>
                </div>
              </div> -->
              <div class="text-center mt-7">
                <v-alert 
                  type="error" 
                  text
                  dense
                  prominent
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
                  v-show="error.message.length > 0"
                  v-html="error.message">
                </v-alert>
                <v-btn
                  width="250"
                  color="#4ab762"
                  elevation="0"
                  :disabled="process.form"
                  :loading="process.form"
                  @click="resetPassword()"
                  class="caption font-weight-bold white--text">
                  Ubah Password
                </v-btn>
                <div>
                  <div class="d-flex align-center mt-3 mb-4">
                    <v-divider/>
                    <span class="caption font-weight-bold mx-3">
                      Atau
                    </span>
                    <v-divider/>
                  </div>

                  <v-btn
                    width="250"
                    outlined
                    color="#4ab762"
                    elevation="0"
                    @click="reset = 'reset'; error.message = ''"
                    :disabled="process.form"
                    class="caption font-weight-bold">
                    <span class="color-green-second ml-3">Reset Password</span>
                  </v-btn>
                </div>

              </div>
            </div>
          </v-col>

          <v-col :cols="5" v-if="fps !== 'xs' && fps !== 'sm'" class="pa-0 d-flex align-center justify-center" style="background: #4ab762; height: 100%; border-radius: 0px 20px 20px 0px !important;">
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 15px; left: -15px;">
              <v-btn
                text
                to="/home"
                elevation="0"
                class="white--text"
                :disabled="process.form">
                <u>Home</u>
              </v-btn>
            </v-card-text>
            <div class="px-7">
              <div class="text-center title white--text">
                Hallo, kawan
              </div>
              <v-divider style="border: 2px solid #FFF; width: 67px; border-radius: 6px;" class="mx-auto my-5"></v-divider>
              <div class="white--text body-1 text-center mb-5">
                Yuk belajar sekarang biar semakin banyak ilmu dan pengetahuan buatmu
              </div>
              <div class="text-center">
                <v-btn
                  width="200"
                  outlined
                  color="#FFF"
                  elevation="0"
                  @click="error.message = ''"
                  :disabled="process.form"
                  to="/login"
                  class="caption font-weight-bold">
                  LOGIN
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        dialog: {
           success: false
        },
        reset: '',
        show: {
          password: false,
          password_confirm: false
        },
        form: {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          token_email: '',
          role: 'user',
          image: ''
        },
        form_google: {
          name: '',
          email: '',
          device: 'web',
          image: ''
        },
        process: {
          run: false,
          form: false,
          register: false,
          login_sso: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/reset-password',
          title: 'Ubah Password',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      // this.fetch()
    },
    methods: {
      reset() {
        this.form = {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          token_email: '',
          role: 'user',
          image: ''
        }
        
        this.form_google = {
          name: '',
          email: '',
          device: 'web',
          image: ''
        }

        this.process.run = false

        this.error.message = ''
      },
      async resetPassword(){
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.process.form = true

          await this.$axios.$post(`api/forget_password/reset`,{
            token: this.$route.query.data,
            password : this.form.password,
            password_confirm : this.form.password_confirm
          }).then((response)=>{
            if (response.status === 200) {
              this.dialog.success = true
            } else {
              this.process.form = false
              this.error.message = response.message
            }
          })
        }
      }
    },
  }
</script>
