<template>
  <div>
    <DialogAuth :dialogauth="toLogout" @close="toLogout = ''" />
    <div style="height: 570px;">

      <v-dialog
        persistent
        scrollable
        v-if="dialog.form"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.form"
        width="649">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <span v-if="form.id === ''">Tambah</span><span v-if="form.id !== ''">Ubah</span> Rekening Bank
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.form = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text class="pb-0">
            <div class="text-second body-2">
              Rekening bank yang telah ditambahkan bisa kamu gunakan untuk penarikan Saldo eLearning
            </div>
            <ValidationObserver ref="observer">
              <div
                class="mb-0 mt-5">
                <div class="d-flex align-center text-first body-2 font-weight-bold">
                  <p>
                    Nama Bank
                  </p>
                </div>
                <ValidationProvider name="Bank" rules="required" v-slot="{ errors }">
                  <v-text-field
                    solo
                    color="#4ab762"
                    placeholder="Pilih bank disini"
                    v-if="form.id !== ''"
                    disabled
                    class="border-input-none"
                    :readonly="form.id === '' ? false : true"
                    prepend-inner-icon="mdi-bank"
                    v-model="detail.name"
                    :error-messages="errors"
                    required>
                  </v-text-field>
                  <v-autocomplete
                    v-model="form.ref_bank_id"
                    :items="bank"
                    solo
                    v-if="form.id === ''"
                    :readonly="form.id === '' ? false : true"
                    color="#4ab762"
                    prepend-inner-icon="mdi-bank"
                    placeholder="Pilih bank disini"
                    item-text="id"
                    item-value="id"
                    :error-messages="errors"
                    require>
                    <template v-slot:selection="data">
                      <div class="d-flex align-center">
                        <!-- <div class="mr-2">
                          <v-img contain style="width: 45px;" :src="data.item.img_url"></v-img>
                        </div> -->
                        <div class="body-1" v-html="data.item.name"></div>
                      </div>
                      <!-- <v-list-item-avatar tile size="25">
                      </v-list-item-avatar>
                      <v-list-item-content class="py-0">
                        <v-list-item-title class="caption" v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content> -->
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar tile>
                        <v-img contain :src="data.item.img_url"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
                <div class="d-flex align-center text-first body-2 font-weight-bold">
                  <p>
                    Nomor Rekening
                  </p>
                </div>
                <ValidationProvider name="Nomor" rules="required" v-slot="{ errors }">
                  <v-text-field
                    solo
                    color="#4ab762"
                    placeholder="Tuliskan disini"
                    :disabled="process.form"
                    prepend-inner-icon="mdi-account-cash"
                    v-model="form.number"
                    type="number"
                    v-on:keyup.enter="save()"
                    :error-messages="errors"
                    required>
                  </v-text-field>
                </ValidationProvider>
                <div class="d-flex align-center text-first body-2 font-weight-bold">
                  <p>
                    Nama Rekening
                  </p>
                </div>
                <ValidationProvider name="Nama" rules="required" v-slot="{ errors }">
                  <v-text-field
                    solo
                    color="#4ab762"
                    placeholder="Tuliskan disini"
                    :disabled="process.form"
                    prepend-inner-icon="mdi-account"
                    v-model="form.name"
                    v-on:keyup.enter="save()"
                    :error-messages="errors"
                    required>
                  </v-text-field>
                </ValidationProvider>
              </div>
            </ValidationObserver>
            <v-alert 
              type="error" 
              text
              dense
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <!-- <v-btn
              elevation="0"
              color="grey darken-4"
              outlined
              width="120"
              @click="dialog.metode = true"
              class="text-capitalize white--text mr-2">
              Cara Lain
            </v-btn> -->
            <v-btn
              elevation="0"
              color="#4ab762"
              width="204"
              height="40"
              :disabled="process.form"
              :loading="process.form"
              @click="save()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  <span v-if="form.id === ''">Tambah</span><span v-if="form.id !== ''">Ubah</span> Bank
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              height="40"
              :disabled="process.form"
              width="120"
              @click="dialog.form = false"
              class="text-capitalize white--text mr-2">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.delete"
        v-model="dialog.delete"
        width="420">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="title text-second text--darken-2 font-weight-bold">
              Hapus Rekening
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.delete = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-first body-1 pt-8 pb-3 text-center">
              Apakah anda yakin ingin menghapus rekening bank <span class="font-weight-bold">{{ detail.name }}</span> dengan nomer rekening <span class="font-weight-bold">{{ detail.number }}</span> ini dari daftar rekening bank Anda?
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="#4ab762"
                  block
                  :disabled="process.form"
                  :loading="process.form"
                  @click="deletes()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, Hapus
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.form"
                  block
                  @click="dialog.delete = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              @click="dialog.delete = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Ya, Hapus
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              @click="dialog.delete = false"
              class="text-capitalize white--text ml-2">
              Tidak
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section style="padding: 0px 30px 23px 30px;">
        <div class="d-flex align-center">
          <div>
            <div class="text-second font-weight-bold" :class="fps !== 'md' && fps !== 'lg' && fps !== 'xl' ? 'body-1' : 'body-1'">
              Daftar Rekening Bank
            </div>
            <div class="text-second" :class="fps !== 'md' && fps !== 'lg' && fps !== 'xl' ? 'body-2' : 'caption'">
              Akun Rekening Bank yang aktif
            </div>
          </div>
          <div class="ml-auto">
            <v-btn
              color="#3498db"
              elevation="0"
              @click="reset()"
              :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
              class="text-capitalize white--text ml-6">
              Tambah Rekening
            </v-btn>
          </div>
        </div>
      </section>

      <section :class="fps !== 'md' && fps !== 'lg' && fps !== 'xl' ? '' : ''" style="height: 485px;  overflow-y: auto;">
        <div v-if="process.run" style="padding: 15px 30px 0px 30px;">
          <v-skeleton-loader
            type="list-item-avatar"
            v-for="l in 2"
            :key="l"
            class="mb-3"
            :loading="process.run">
          </v-skeleton-loader>
        </div>
        <div v-if="!process.run" style="padding: 15px 30px 0px 30px;">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : 'px-3'">
            <v-row class="px-4 mb-7 align-center" style="border: 1px solid #ebebeb; border-radius: 8px; background-color: #FFFFFF;">
              <v-col cols="12" md="2" class="pr-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''">
                <v-list style="background: transparent;">
                  <v-list-item class="py-0 px-0">
                    <v-list-item-avatar
                      width="90"
                      tile
                      height="40"
                      class="align-self-start mt-0 mr-0">
                      <v-img :src="l.img_url" contain></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'pl-0'">
                <v-list style="background: transparent;">
                  <v-list-item class="py-0 pl-0">
                    <v-list-item-avatar
                      width="80"
                      tile
                      height="40"
                      :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'"
                      class="align-self-start">
                      <v-img :src="l.img_url" contain></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <div class="body-2 font-weight-bold body-1 text-second text--darken-2 ">
                        <div class="body-2 text-second text--darken-2">
                          Bank
                        </div>
                        {{ l.name }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'px-0'">
                <div class="text-first body-1 font-weight-bold">
                  <v-list style="background: transparent;">
                    <v-list-item style="border-left: 1px solid #979797;" class="py-0 pr-0">
                      <v-list-item-content class="py-0">
                        <div class="body-2 font-weight-bold body-1 text-second text--darken-2 ">
                          <div class="body-2 text-second text--darken-2">
                            No Rekening
                          </div>
                          {{ l.number }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
              <v-col cols="12" md="5" class="d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'pr-0'">
                <div class="text-first body-1 font-weight-bold">
                  <v-list style="background: transparent;">
                    <v-list-item style="border-left: 1px solid #979797;" class="py-0 pr-0">
                      <v-list-item-content class="py-0">
                        <div class="body-2 font-weight-bold body-1 text-second text--darken-" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
                          <div class="body-2 text-second text--darken-2">
                            Nama Rekening
                          </div>
                          {{ l.account_name }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div class="text-first body-1 font-weight-bold ml-auto">
                  <v-btn
                    fab
                    small
                    @click="detail = l; reset(l)"
                    icon>
                    <v-icon size="20" color="primary">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    @click="detail = l; dialog.delete = true;"
                    icon>
                    <v-icon size="20" color="red">mdi-block-helper</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>

          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          style="padding: 0px 30px 0px 30px;"
          v-show="pagination.total_page > 1"/>
      </section>

    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          form: false,
          delete: false
        },
        toLogout: '',
        list: [],
        pagination: {},
        bank: [],
        form: {
          id: "",
          ref_bank_id: "",
          name: "",
          number: ""
        },
        detail: {},
        contentEmpty: "Data belum tersedia",
        process: {
          run: false,
          form: false,
          bank: false
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        },
        content: {
          url: '/account/setting',
          title: 'Pengaturan',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      ImageInput: () => import('@/components/ImageInput'),
      Empty: () => import('@/components/DataEmpty'),
      Pagination: () => import('@/components/Pagination'),
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      detailProfile: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'detailProfile': function(val) {
        this.form = val
      },
    },
    mounted () {
      this.fetch()
      this.fetchBank()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p
        }
        this.$axios.$get(`api/publisher/bank`, { params })
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.process.run = false
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      fetchBank () {
        this.process.bank = true
        this.$axios.$get(`api/ref_bank`)
        .then((response)=>{
          console.log('data response', response)
          if(response.status === 200) {
            this.process.bank = false
            this.bank = response.results.data
          }else{
            this.process.bank = false
          }
        })
      },
      reset(item) {
        this.form = {
          id: item !== undefined ? item.id : "",
          ref_bank_id: item !== undefined ? item.bank_id : "",
          name: item !== undefined ? item.account_name : "",
          number: item !== undefined ? item.number : ""
        }

        this.process.form = false
        this.error.message = ""
        this.dialog.form = this.dialog.form === true ? false : true
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          if (this.form.id === '') {
            this.creates()
          } else {
            this.updates()
          }
        }
      },
      async creates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/bank`, 
        {
          ref_bank_id: this.form.ref_bank_id,
          name: this.form.name,
          number: this.form.number
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/bank/update`, 
        {
          id: this.form.id,
          // ref_bank_id: this.form.ref_bank_id,
          account_name: this.form.name,
          number: this.form.number
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async deletes() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/bank/delete`, 
        {
          id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.dialog.delete = false
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      }
    },
  };
</script>