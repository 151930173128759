<template>
  <div>
    <DialogAuth :dialogauth="toAuth" :email="form.email" @close="toAuth = ''" />

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -80px;"
      color="#27ae60">
      <div class="body-1 font-weight-bold">{{ success.message }}</div>
    </v-snackbar>

    <v-dialog v-model="dialog.message"
      width="500"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      persistent>
      <v-card 
        height="650"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

          <v-col cols="12" class="pa-0">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  icon
                  @click="toCloseDialog()"
                  elevation="0"
                  class="color-green-second">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; $emit('close')" color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                <span v-if="!user.new_user_bool">Berhasil Login</span>
                <span v-if="user.new_user_bool">Berhasil Mendaftar</span>
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="300"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <!-- <div class="text-second text-center font-weight-bold mb-3" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'title' : 'text-h4'">
                Reset Password Sukses
              </div> -->
              <div class="text-first text-center mb-5" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
                {{ user.message }}
              </div>
              <div class="text-center">
                <v-btn
                  width="250"
                  color="#4ab762"
                  elevation="0"
                  @click="toCloseDialog()"
                  class="caption font-weight-bold white--text">
                  Tutup
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-app-bar 
      app
      class="box-shadow"
      :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'height: 64px;' : !view.topOfPage ? 'height: 75px;' : 'height: 170px;'"
      color="white"
      :prominent="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
      fixed>
      <v-container class="mx-auto">
        <div class="lebar_layar_header mx-auto" v-if="view.topOfPage && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
          <v-row justify="start">
            <v-col :cols="3" class="d-flex align-center" :class="token ? 'pb-4' : 'pb-8'">
              <v-btn class="ma-2 hidden-md-and-up" 
                tile 
                large 
                color="#393939" 
                dark
                @click.stop="drawer = !drawer"
                icon>
                <v-icon left>mdi-menu</v-icon>
              </v-btn>
              <router-link to="/home" style="width: 130px">
                <v-img
                  class="cursor-pointer"
                  max-width="130px"
                  :src="require('@/assets/icon/logo.png')"
                  :lazy-src="require('@/assets/icon/logo.png')">
                </v-img>
              </router-link>
            </v-col>
            <v-col cols="6" class="d-flex align-center justify-center" :class="token ? 'pb-4' : 'pb-8'">
              <v-btn
                v-for="(l, text) in menu"
                :key="text"
                rounded
                :to="l.link"
                text
                color="white"
                class="body-2 menu text-capitalize">
                <span :class="l.link === $route.path ? 'black--text font-weight-bold' : 'text-second'">
                  {{ l.title }}
                </span>
              </v-btn>

              <!-- <v-divider
                class="mx-2"
                vertical
                color="white"
                style="min-height: 15px !important; height: 25px; margin-top: auto; margin-bottom: auto; opacity: 50%;">
              </v-divider> -->
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end" :class="token ? 'pb-4' : 'pb-8'">
              <div v-if="!token">
                <v-btn 
                  rounded 
                  text
                  small 
                  elevation="0"
                  :disabled="process.register"
                  :loading="process.register"
                  @click="google"
                  class="body-2 text-capitalize white--text ml-5 mr-3" 
                  color="grey">
                  Registrasi
                </v-btn>
                
                <v-btn 
                  rounded 
                  small 
                  text
                  @click="toAuth = 'login'"
                  class="body-2 text-capitalize color-first" 
                  color="#16a085">
                  <span class="color-first">
                    <!-- <v-icon small class="mr-1">mdi-account-outline</v-icon> -->
                    Login
                  </span>
                </v-btn>
              </div>
              <div v-if="token" class="d-flex align-center">
                <v-list-item class="px-2" to="/account/setting" style="color: transparent;">
                  <v-list-item-content class="justify-end text-right">
                    <v-list-item-subtitle class="body-2 font-weight-bold text-first">
                      {{ user.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ user.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar class="rounded-lg my-0" rounded :size="40" v-if="user.img_url !== undefined && user.img_url !== ''">
                    <v-img
                      :src="user.img_url"
                      :lazy-src="user.img_url"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                  <div v-if="user.img_url === undefined || user.img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px;" class="rounded-lg ml-2">
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      {{ nama_image }}
                    </v-row>

                  </div>
                </v-list-item>
                <v-btn
                  fab
                  small
                  class="ml-2"
                  @click="toAuth = 'logout'"
                  icon>
                  <v-icon color="red">mdi-power-standby</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider v-if="view.topOfPage && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'" style="opacity: 0.1; border-color: rgb(151, 151, 151); margin-bottom: 18px; margin-top: 10px;"></v-divider>
        <div class="lebar_layar_header mx-auto">
          <v-row v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
            <v-col :cols="3" class="d-flex align-center">
              <v-select
                :items="category"
                color="#4ab762"
                item-color="green"
                hide-details
                item-text="name"
                item-value="id"
                v-model="filter.category"
                v-on:change="toList()"
                placeholder="Kategori"
                solo
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.search"
                v-on:keyup.enter="toSearch()"
                @click:clear="filter.search = ''; toSearch();"
                :placeholder="`Cari produk…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
              <!-- <v-text-field
                dense
                hide-details
                clearable
                outlined
                :placeholder="`Cari kelas elearning semua kategori`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field> -->
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <div v-if="token" class="mr-2">
                <router-link to="/account/notification">
                  <v-badge 
                    v-if="headers.notif > 0"
                    class="ml-4 cursor-pointer"
                    color="orange darken-3"
                    overlap>
                    <span slot="badge" v-if="headers.notif >= 10">
                      9+
                    </span>
                    <span slot="badge" v-else>
                      {{ headers.notif }}
                    </span>
                    <span slot="default">
                      <v-icon>mdi-bell</v-icon>
                    </span>
                  </v-badge>
                  <v-icon v-else>mdi-bell</v-icon>
                </router-link>
              </div>
              <!-- <v-menu
                close-on-content-click nudge-width="250" offset-x
                v-if="token">
                <template v-slot:activator="{ on }"> -->
                  <div v-if="token">
                    <router-link to="/cart">
                      <v-badge 
                        v-if="headers.cart > 0"
                        class="ml-4 cursor-pointer"
                        color="orange darken-3"
                        overlap>
                        <span slot="badge">
                          {{ headers.cart }}
                        </span>
                        <span slot="default">
                          <v-icon>mdi-cart</v-icon>
                        </span>
                      </v-badge>
                      <v-icon v-else>mdi-cart</v-icon>
                    </router-link>
                  </div>
                <!-- </template>
              </v-menu> -->
              <v-btn
                small
                icon
                @click="toAuth = 'login'"
                v-if="!token">
                <v-icon color="grey darken-1">mdi-cart</v-icon>
              </v-btn>
              <div class="mx-6">
                <v-btn
                  small
                  icon
                  to="/account/wishlist/elearning"
                  v-if="token">
                  <v-icon color="grey darken-1">mdi-cards-heart</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  @click="toAuth = 'login'"
                  v-if="!token">
                  <v-icon color="grey darken-1">mdi-cards-heart</v-icon>
                </v-btn>
              </div>
              <div class="body-1 text-first">
                <span class="cursor-pointer">
                  <router-link to="/account/collection" v-if="token">
                    <span class="text-first">
                      Kelas Saya
                    </span>
                  </router-link>
                  <span class="text-first" v-if="!token" @click="toAuth = 'login'">
                    Kelas Saya
                  </span>
                </span>
              </div>
            </v-col>
          </v-row>


          <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
            <v-row justify="start" v-if="$route.path === '/elearning' || $route.path === '/home' || $route.path === '/organitation' || $route.path === '/account/setting' || $route.path === '/login'" style="height:64px; margin-top: 0.5px;">
              <v-col :cols="4" class="d-flex align-center">
                <router-link to="/home" style="width: 90px">
                  <v-img
                    class="cursor-pointer"
                    max-width="90px"
                    :src="require('@/assets/icon/logo.png')"
                    :lazy-src="require('@/assets/icon/logo.png')">
                  </v-img>
                </router-link>
              </v-col>
              <v-col cols="8" class="d-flex align-center justify-end">
                <div v-if="!token">
                  <v-btn 
                    rounded 
                    text
                    small 
                    elevation="0"
                    :disabled="process.register"
                    :loading="process.register"
                    @click="google"
                    class="body-2 text-capitalize white--text mr-3" 
                    color="grey">
                    Registrasi
                  </v-btn>
                  
                  <v-btn 
                    rounded 
                    small 
                    text
                    @click="toAuth = 'login'"
                    class="body-2 text-capitalize color-first" 
                    color="#16a085">
                    <span class="color-first">
                      <!-- <v-icon small class="mr-1">mdi-account-outline</v-icon> -->
                      Login
                    </span>
                  </v-btn>
                </div>
                <div v-if="token" class="d-flex align-center justify-end">
                  <div v-if="token" class="mr-0">
                    <router-link to="/account/notification">
                      <v-badge 
                        v-if="headers.notif > 0"
                        class="ml-4 cursor-pointer"
                        color="orange darken-3"
                        overlap>
                        <span slot="badge" v-if="headers.notif >= 10">
                          9+
                        </span>
                        <span slot="badge" v-else>
                          {{ headers.notif }}
                        </span>
                        <span slot="default">
                          <v-icon>mdi-bell</v-icon>
                        </span>
                      </v-badge>
                      <v-icon v-else>mdi-bell</v-icon>
                    </router-link>
                  </div>
                  <v-menu
                    close-on-content-click nudge-width="250" offset-x>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <router-link to="/cart">
                          <v-badge 
                            v-if="headers.cart > 0"
                            class="ml-4 cursor-pointer"
                            color="orange darken-3"
                            overlap>
                            <span slot="badge">
                              {{ headers.cart }}
                            </span>
                            <span slot="default">
                              <v-icon>mdi-cart</v-icon>
                            </span>
                          </v-badge>
                          <v-icon v-else>mdi-cart</v-icon>
                        </router-link>
                      </div>
                    </template>
                  </v-menu>
                  <div class="ml-4">
                    <router-link to="/account/wishlist/elearning">
                      <v-icon>mdi-cards-heart</v-icon>
                    </router-link>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row justify="start" v-if="$route.path !== '/elearning' && $route.path !== '/home' && $route.path !== '/organitation' && $route.path !== '/account/setting' && $route.path !== '/login'" style="height:64px; margin-top: 0.5px;">
              <v-col :cols="4" class="d-flex align-center">
                <span @click="$router.back()" style="width: 90px; color: #16a085;">
                  <v-icon class="cursor-pointer" color="#16a085">mdi-arrow-left</v-icon>
                  Kembali
                </span>
              </v-col>
              <v-col cols="8" class="d-flex align-center justify-end">
                <div v-if="!token">
                  <v-btn 
                    rounded 
                    text
                    small 
                    elevation="0"
                    :disabled="process.register"
                    :loading="process.register"
                    @click="google"
                    class="body-2 text-capitalize white--text mr-3" 
                    color="grey">
                    Registrasi
                  </v-btn>
                  
                  <v-btn 
                    rounded 
                    small 
                    text
                    @click="toAuth = 'login'"
                    class="body-2 text-capitalize color-first" 
                    color="#16a085">
                    <span class="color-first">
                      <!-- <v-icon small class="mr-1">mdi-account-outline</v-icon> -->
                      Login
                    </span>
                  </v-btn>
                </div>
                <div v-if="token" class="d-flex align-center justify-end">
                  <div v-if="token" class="mr-0">
                    <router-link to="/account/notification">
                      <v-badge 
                        v-if="headers.notif > 0"
                        class="ml-4 cursor-pointer"
                        color="orange darken-3"
                        overlap>
                        <span slot="badge" v-if="headers.notif >= 10">
                          9+
                        </span>
                        <span slot="badge" v-else>
                          {{ headers.notif }}
                        </span>
                        <span slot="default">
                          <v-icon>mdi-bell</v-icon>
                        </span>
                      </v-badge>
                      <v-icon v-else>mdi-bell</v-icon>
                    </router-link>
                  </div>
                  <v-menu
                    close-on-content-click nudge-width="250" offset-x>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <router-link to="/cart">
                          <v-badge 
                            v-if="headers.cart > 0"
                            class="ml-4 cursor-pointer"
                            color="orange darken-3"
                            overlap>
                            <span slot="badge">
                              {{ headers.cart }}
                            </span>
                            <span slot="default">
                              <v-icon>mdi-cart</v-icon>
                            </span>
                          </v-badge>
                          <v-icon v-else>mdi-cart</v-icon>
                        </router-link>
                      </div>
                    </template>
                  </v-menu>
                  <div class="ml-4">
                    <router-link to="/account/wishlist/elearning">
                      <v-icon>mdi-cards-heart</v-icon>
                    </router-link>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

        </div>
      </v-container>
    </v-app-bar>


  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    data () {
      return {
        // notification: this.$store.state.notification,
        menu: [
           {
             icon: "mdi-home",
             title: "Beranda",
             link: "/home"
           },
           {
             icon: "mdi-view-grid-outline",
             title: "E-learning",
             link: "/elearning"
           },
           {
             icon: "mdi-account-tie",
             title: "Oraganisasi",
             link: "/organitation"
           },
          //  {
          //    icon: "mdi-home",
          //    title: "Kontak Kami",
          //    link: "/homef"
          //  }
        ],
        snackbar: false,
        timeout: 2000,
        count:0,
        dialog: {
          message: false
        },
        toAuth: '',
        show: {
          password: false,
          password_confirm: false
        },
        items: ['Semua Kategori', 'Kategori A'],
        filter: {
          category: 'Semua Kategori',
          search: ""
        },
        nama_image: '',
        selected: false,
        link: "",
        form: {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          role: 'user',
          image: ''
        },
        view: {
          topOfPage: true
        },
        notification:[],
        routefrom: '',
        category: [],
        searching:'',
        list:[],
        back: true,
        process: {
          run: false,
          register: false
        },
        success: {
          message: ""
        },
        error: {
          message: false
        }
      }
    },
    watch: { 
      // searching:{
      //   handler:function(){
      //     if(this.searching !== ''){
      //       this.dialog.donasi = true
      //       this.fetch()
      //     }else{
      //       this.dialog.donasi = false
      //     }
      //   }
      // },
      '$route' (to, from) {
        this.routefrom = from.name
        // console.log(to)
        this.hideBack(to)
        this.fetchCountNotification()
      } 
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
    },
    mounted () {
      this.fetchCategory()
      if ( Object.keys(this.$store.state.member.auth.user).length > 0 && this.$store.state.member.auth.user.new_user_bool !== '') {
        if (this.$store.state.member.auth.user.new_user_bool) {
          this.dialog.message = true
        } else {
          this.snackbar = true
          this.success.message = `Selamat Datang Kembali ${this.$store.state.member.auth.user.name} di Otodidak`
          this.toCloseDialog()
        }
      }

      if (Object.keys(this.$store.state.member.auth.user).length > 0) {
        this.getKarekter()
      }
      if (this.$route.query.search !== undefined) {
          this.filter.search = this.$route.query.search
        }
      if (this.token) {
        this.fetchCountNotification()
      }
    },
    beforeMount() {
      window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll(){
        if(window.pageYOffset>200){
          if(this.view.topOfPage) this.view.topOfPage = false
        } else {
          if(!this.view.topOfPage) this.view.topOfPage = true
        }
      },
      getKarekter() {
        let name = this.$store.state.member.auth.user.name
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        // return initial.toUpperCase()
      },
      hideBack (val) {
        // console.log(this.$route.path == '/profile')
        // this.back = true
        // if( val.path == '/wishlist' || val.path == '/donasiku' ||  val.path == '/profile') {
        //   this.back = false 
        // }
      },
      fetchCategory () {
        if (this.$route.query.category !== undefined) {
          this.filter.category = this.$route.query.category
        }
        this.process.run = true
        let params = {
          limit: 10000000000
        }
        this.$axios.$get(`api/course_category`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.category = []
            this.category.push(
              {
                id: "",
                name: 'Semua Kategori',
                count: ''
              }
            ) 
            for (let i in response.results.data) {
              this.category.push(
                {
                  id: response.results.data[i].id,
                  count: response.results.data[i].count,
                  name: response.results.data[i].name
                }
              ) 
              
            }
          }else{
            this.process.run = false
          }
        })
      },
      async fetchNotification() {
        if (this.token) {
          this.process.run = true
          let params = {
            sort: '-notification_id'
          }
          this.$axios.$get(`member/notifications`, { params })
          .then((response) => {
            let res = response
            if (res.status === 200) {
              this.notification = res.results.data
              this.process.run = false
            }
          })
        }
      },
      async fetchCountNotification() {
        if (this.token) {
          this.$axios.$get(`api/notification/count`)
          .then((response) => {
            let res = response
            if (res.status === 200) {
              // this.count = res.results.data.count_notification_unread
              this.$store.commit('header/setNotif', parseInt(res.results.data.count_notification_unread))
            }
          })
        }
      },
      toRead(value) {
        this.detail = value
        this.$axios.$post(`api/notification/read_bulk`, 
          {
            id: value.notification_id
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.fetchCountNotification()
            this.dialog = true
          }
        })
      },
      toSearch(){
        if (this.filter.search !== '' && this.filter.search !== null) {
          this.$router.push(`/elearning?search=${this.filter.search}`)
        } else {
          this.$router.push(`/elearning`)
        }
      },
      toList() {
        if (this.filter.category !== '') {
          this.$router.push(`/elearning?category=${this.filter.category}`)
        } else {
          this.$router.push(`/elearning`)
        }
      },
      async google(type) {
        this.error.message = ''
        let provider = new this.$firebase.auth.GoogleAuthProvider()
        await this.$firebase.auth().signInWithPopup(provider)
        .then(response => {
          // let data = response.additionalUserInfo.profile
          // this.form_google.name = data.name
          // this.form_google.email = data.email
          // this.form_google.image = data.picture
          // this.form_google.device = 'web'

          // let datas = {
          //   email: this.form_google.email,
          //   name: this.form_google.name,
          // }

          let data = response
          this.form.name = data.additionalUserInfo.profile.name
          this.form.email = data.additionalUserInfo.profile.email
          this.form.image = data.additionalUserInfo.profile.picture
          this.form.token_email = data.credential.idToken
          this.form.device = 'web'

          let datas = {
            email: this.form.email,
            name: this.form.name,
          }


          console.log(data)

          // let hashData = sha256(JSON.stringify(datas)).toString().toLowerCase()
          // let signatureData = `L06INS0O:WEB:${hashData}:${process.env.API_SIGNATURE}`
          // this.form_google.signature = hmacSHA256(signatureData, 'K0l0niaW3bPl4tform_google').toString()
          
          this.loginSSO(type)
        }).catch(error => {
          this.$snotify.error(error.message)
          console.log(error)
        })
      },
      async loginSSO() {
        this.process.register = true
        this.process.run = true

        await this.$axios.$post(`api/login/sso`, 
        {
          email: this.form.email,
          name: this.form.name,
          img_url: this.form.image,
          token: this.form.token_email,
          device : "web"
        }).then((response) => {
          if (response.status === 200) {
            this.process.register = false
            Cookie.set('user', response.results.data)
            Cookie.set('token', response.results.token)

            window.location = this.$route.path
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },

      toCloseDialog() {
        this.user.new_user_bool = ""
        Cookie.set('user', this.user)
        this.dialog.message = false
      }
      
    },
    created () {
      if (this.token) {
        this.$axios.$get(`api/cart`)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('header/setCart', response.results.data.length)
          }
        })
        if (process.browser) {
          if ('Notification' in window && 'serviceWorker' in navigator) {
            console.log(navigator.serviceWorker)
            navigator.serviceWorker.addEventListener('message', event => {
              this.fetchCountNotification() 
            })
          }
        }
      }
    }
  }
</script>

<style scoped>
  .header {
    background-color: white !important;
    /* box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2) !important; */
  }
  html:not([data-scroll="0"]) .header {
    /* background: url(../../assets/background/bg_header.png); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  .text-cut{
    line-height: 24px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
  }
</style>