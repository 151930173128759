<template>
  <div>
    <Loading :detailMessage="loading_message" v-if="process.form || process.delete"/>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      centered
      top
      style="margin-top: -20px;"
      :color="color">
      <div class="body-1 font-weight-bold">{{ color === 'warning' ? warning.message : success.message }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.form"
      v-model="dialog.form"
      width="500">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="text-h5 text-second text--darken-2 font-weight-bold">
            Buat Diskusi Baru
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="reset(); dialog.form = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-card-text class="pt-5 pb-0">
          <div class="d-flex align-center body-2">
            <div class="text-second pr-2" style="width: 50%">
              <v-select
                :items="bab"
                solo
                item-text="title"
                item-value="content_id"
                color="#4ab762"
                item-color="green"
                hide-details
                return-object
                v-model="form.content_id"
                class="mr-3"
                placeholder="Bab"
              ></v-select>
            </div>
            <div class="ml-auto text-right pl-2" style="width: 50%">
              <v-select
                :items="materi"
                solo
                :disabled="form.content_id === '' || Object.keys(form.content_id).length < 1 || form.content_id.id === ''"
                color="#4ab762"
                clearable
                @click:clear="form.materi_id = '';"
                item-value="content_id"
                item-text="title"
                item-color="green"
                hide-details
                v-model="form.materi_id"
                :placeholder="Object.keys(form.content_id).length < 1 ? 'Pilih bab terlebih dulu' : form.content_id.id === '' ? 'Semua Materi' : 'Materi'"
              ></v-select>
            </div>
          </div>
          <div class="mt-8">
            <ValidationObserver ref="observer">
              <v-row>
                <v-col cols="12">
                  <div class="body-1 text-first">
                    Judul Diskusi
                  </div>
                  <ValidationProvider name="Judul Diskusi" rules="required|max:1000" v-slot="{ errors }">
                    <v-text-field
                      color="#4ab762"
                      class="mt-0 pt-3"
                      :counter="1000"
                      v-on:keyup.enter="save()"
                      :messages="'Wajib'"
                      v-model="form.title"
                      placeholder="Tuliskan disini"
                      :error-messages="errors"
                      require>
                      <template v-slot:message="{ message }">
                        <span class="red--text" v-html="message"></span>
                      </template>
                      <template v-slot:counter="{ props }">
                        <span style="font-size: 9px;" class="text-second">
                          {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                        </span>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <div class="body-1 text-first">
                    Pertanyaan
                  </div>
                  <ValidationProvider name="Pertanyaan" rules="required|max:1000" v-slot="{ errors }">
                    <v-text-field
                      color="#4ab762"
                      class="mt-0 pt-3"
                      :counter="1000"
                      v-on:keyup.enter="save()"
                      :messages="'Wajib'"
                      v-model="form.text"
                      placeholder="Tuliskan disini"
                      :error-messages="errors"
                      require>
                      <template v-slot:message="{ message }">
                        <span class="red--text" v-html="message"></span>
                      </template>
                      <template v-slot:counter="{ props }">
                        <span style="font-size: 9px;" class="text-second">
                          {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                        </span>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
            <v-alert 
              type="error" 
              text
              dense
              class="mb-0 mr-2 px-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#4ab762"
            width="190"
            @click="save()"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                Tambah Diskusi
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            @click="reset(); dialog.form = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.detail"
      v-model="dialog.detail"
      width="1200">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-7 pb-3 pt-5'">
          <div style="font-weight: 900; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'fs-18'">
            Diskusi ({{ pagination.total_data }})
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.detail = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pt-2 pb-5'">
          <div class="text-second" style="width: 60%">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari diskusi…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
            <v-select
              :items="materi_filter"
              solo
              v-on:change="fetch()"
              color="#4ab762"
              item-value="content_id"
              item-text="title"
              item-color="green"
              hide-details
              v-model="filter.materi"
              :placeholder="'Materi'">
            </v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-7 pb-10'" style="min-height: 400px; height: 100vh;">
          <div style="height: 100%;">
            <v-row style="height: 100%;">
              <v-col cols="12" md="5" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'" class="pr-0" style="border-right: 0.5px solid rgba(151, 151, 151, 0.10); height: 100%;">
                <div class="pr-3 mb-5" style="height: calc(100% * 0.75); overflow-y: auto; border-bottom: 0.5px solid rgba(151, 151, 151, 0.10)">
                  <!-- <div flat class="mb-6">
                    <div style="background: #f6f6f6; min-height: 55px; padding: 17px 21px; border: 0.5px solid rgba(151, 151, 151, 0.10)">
                      <v-list dense class="py-0" color="transparent">
                        <v-list-item class="py-0 px-0">
                          <div class="d-flex align-center" style="width: 100%;">
                            <div class="body-1 ml-0 font-weight-bold text-capitalize">
                              Semua Materi
                              <div class="caption text-second">
                                {{ materi_filter.length }} Materi
                              </div>
                            </div>
                            <div class="body-2 text-first ml-auto">
                            </div>
                          </div>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div> -->
                  <div class="mb-6" style="background: #f6f6f6; min-height: 55px; padding: 10px 11px; border-radius: 6px;" :style="filter.materi === '' ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="filter.materi = ''; fetch(); form.materi_id = ''; ">
                    <div class="d-flex align-center" style="width: 100%;">
                      <div class="body-1 ml-0 font-weight-bold text-capitalize">
                        Semua Materi
                        <div class="caption text-second">
                          {{ materi_filter.length }} Materi
                        </div>
                      </div>
                      <div class="ml-auto pl-4 text-right" style="color: #4ab762;">
                        <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                        <v-icon size="20" :color="filter.materi !== '' ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                      </div>
                    </div>
                  </div>
                  <div flat class="mb-6" v-for="(materi, i) in detail.content" :key="i">
                    <div style="background: #f6f6f6; min-height: 55px; padding: 10px 11px; border-radius: 6px;" :style="selected.type === 'bab' && `${filter.materi}bab` === `${materi.content_id}bab` ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.type = 'bab'; filter.materi = materi.content_id; fetch(); form.content_id = materi;">
                      <div class="d-flex align-center" style="width: 100%;">
                        <div class="body-1 ml-0 font-weight-bold text-capitalize">
                          {{ materi.title }}
                          <div class="caption text-second">
                            {{ materi.sub_count }} Materi
                          </div>
                        </div>
                        <div class="ml-auto pl-4 text-right" style="color: #4ab762;">
                          <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                          <v-icon size="20" :color="selected.type === 'bab' && `${filter.materi}bab` === `${materi.content_id}bab` ? '#5baf60' : '#50905336'">mdi-check-circle</v-icon>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 20px 10px 0px 10px;">
                      <!-- <div v-if="materi.file_url === ''">
                        <div class="d-flex mb_15" @click="filter.materi = materi.content_id">
                          <div>
                            <div class="d-flex">
                              <div class="mr-4">
                                <v-img
                                  width="54"
                                  height="54"
                                  class="d-flex align-center justify-center"
                                  gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                  :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                                  style="border-radius: 5px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>

                              <div class="body-1 font-weight-light text-second body-2">
                                <div v-if="materi.description !== ''">
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length > 50">
                                    <div v-if="!materi.read_more">
                                      <span class="caption" style="white-space: pre-line;" v-html="materi.description.substring(0, 50) + '...'">
                                      </span>
                                      <span class="text-second cursor-pointer mt-5" @click="materi.read_more = true"><u>Selengkapnya</u></span>
                                    </div>
                                    <div v-if="materi.read_more">
                                      <span class="caption mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                      <span class="text-second cursor-pointer mt-0" @click="materi.read_more = false"><u>Tutup</u></span>
                                    </div>
                                  </div>
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length <= 50">
                                    <div class="caption" style="white-space: pre-line;" v-html="materi.description"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div v-if="materi.file_url !== ''" class="cursor-pointer" style="background: #f6f6f6; border-radius: 6px; margin-bottom: 10px;" :style="selected.type === 'materi' && `${filter.materi}materi` === `${materi.content_id}materi` ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.type = 'materi'; filter.materi = materi.content_id; fetch(); form.content_id = materi; form.materi_id = materi.content_id;">
                        <div class="d-flex" style="padding: 11px 15px;">
                          <div>
                            <div
                              style="min-width: 35px; width: 35px; padding-top: 15.5px">
                              <v-icon 
                                size="18"
                                :color="selected.type === 'materi' && `${filter.materi}materi` === `${materi.content_id}materi` ? '#4ab762' : 'grey'">
                                {{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                              </v-icon>
                            </div>
                          </div>
                          <div class="mr-3">
                            <v-img
                              width="54"
                              height="54"
                              class="d-flex align-center justify-center"
                              gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                              :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                              style="border-radius: 5px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div class="body-1 font-weight-light text-second">
                            <span
                              class="text-second body-2">
                              <div class="body-2 font-weight-bold mb-1 text-first">
                                {{ materi.title }}
                              </div>
                              <div v-if="materi.description !== ''">
                                <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length > 30">
                                  <div v-if="!materi.read_more">
                                    <span class="caption" style="white-space: pre-line;" v-html="materi.description.substring(0, 30) + '...'">
                                    </span>
                                    <span class="text-second cursor-pointer mt-5" @click="materi.read_more = true"><u>Selengkapnya</u></span>
                                  </div>
                                  <div v-if="materi.read_more">
                                    <span class="caption mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                    <span class="text-second cursor-pointer mt-0" @click="materi.read_more = false"><u>Tutup</u></span>
                                  </div>
                                </div>
                                <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length <= 30">
                                  <div class="caption" style="white-space: pre-line;" v-html="materi.description"></div>
                                </div>
                              </div>
                            </span>
                          </div>

                          <div class="ml-auto pl-4 text-right" style="color: #4ab762; padding-top: 15.5px">
                            <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                            <v-icon size="20" :color="selected.type === 'materi' && `${filter.materi}materi` === `${materi.content_id}materi` ? '#5baf60' : '#50905336'">mdi-check-circle</v-icon>
                          </div>
                        </div>
                      </div>
                      <div v-for="(sub_materi, index) in materi.sub" :key="index" style="margin-bottom: 10px;">
                        <div class="cursor-pointer" style="background: #f6f6f6; border-radius: 6px;" :style="filter.materi === sub_materi.content_id ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="filter.materi = sub_materi.content_id; fetch(); form.content_id = materi; form.materi_id = sub_materi.content_id;">
                          <div class="d-flex" style="padding: 11px 15px;">
                            <div>
                              <div
                                style="min-width: 35px; width: 35px; padding-top: 15.5px">
                                <v-icon 
                                  size="18"
                                  :color="filter.materi === sub_materi.content_id ? '#4ab762' : 'grey'">
                                  {{ sub_materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                </v-icon>
                              </div>
                            </div>
                            <div class="mr-3">
                              <v-img
                                width="54"
                                height="54"
                                class="d-flex align-center justify-center"
                                gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                :src="sub_materi.img_url === '' ? detail.img_url : sub_materi.img_url"
                                style="border-radius: 5px;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#4ab762">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div class="body-1 font-weight-light text-second">
                              <span
                                class="text-second body-2">
                                <div class="body-2 font-weight-bold mb-1 text-first">
                                  {{ sub_materi.title }}
                                </div>
                                <div v-if="sub_materi.description !== ''">
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="sub_materi.description.length > 30">
                                    <div v-if="!sub_materi.read_more">
                                      <span class="caption" style="white-space: pre-line;" v-html="sub_materi.description.substring(0, 30) + '...'">
                                      </span>
                                      <span class="text-second cursor-pointer mt-5" @click="sub_materi.read_more = true"><u>Selengkapnya</u></span>
                                    </div>
                                    <div v-if="sub_materi.read_more">
                                      <span class="caption mr-3" style="white-space: pre-line;" v-html="sub_materi.description"></span>
                                      <span class="text-second cursor-pointer mt-0" @click="sub_materi.read_more = false"><u>Tutup</u></span>
                                    </div>
                                  </div>
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="sub_materi.description.length <= 30">
                                    <div class="caption" style="white-space: pre-line;" v-html="sub_materi.description"></div>
                                  </div>
                                </div>
                              </span>
                            </div>

                            <div class="ml-auto pl-4 text-right" style="color: #4ab762; padding-top: 15.5px">
                              <!-- {{ sub_materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                              <v-icon size="20" :color="filter.materi !== sub_materi.content_id ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pr-3" style="height: calc(100% * 0.25); overflow-y: auto; border-top: 0.5px solid rgba(151, 151, 151, 0.10)">
                  <div class="mb-6 d-flex align-center" style="background: #f6f6f6; min-height: 66px; padding: 10px 11px; border-radius: 6px;" :style="filter.id_comment === '' ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="filter.id_comment = ''; fetch();">
                    <div class="d-flex align-center" style="width: 100%;">
                      <div class="body-1 ml-0 font-weight-bold text-capitalize">
                        Semua Diskusi
                      </div>
                      <div class="ml-auto pl-4 text-right" style="color: #4ab762;">
                        <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                        <v-icon size="20" :color="filter.id_comment !== '' ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                      </div>
                    </div>
                  </div>

                  <div class="mb-6 d-flex align-center" @click="filter.id_comment = user.id; fetch()" style="background: #f6f6f6; min-height: 66px; padding: 10px 11px; border-radius: 6px;" :style="filter.id_comment === user.id ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #FFF'">
                    <div class="d-flex align-center" style="width: 100%;">
                      <div class="body-1 ml-0 font-weight-bold text-capitalize">
                        Diskusi Yang Anda Buat
                      </div>
                      <div class="ml-auto pl-4 text-right" style="color: #4ab762;">
                        <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                        <v-icon size="20" :color="filter.id_comment === '' ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <div v-if="process.user">
                      <v-skeleton-loader
                        class="ma-auto"
                        v-for="r in 4"
                        :key="r"
                        :loading="process.user"
                        type="list-item-avatar-three-line">
                      </v-skeleton-loader>
                    </div>
                    <div v-if="!process.user">
                      <div class="fs-16 mb-3 mt-2 font-weight-bold">
                        Diskusi Yang Anda Buat
                      </div>
                      
                      <div v-for="(item, index) in list_user" :key="index" >
                        <div class="d-flex pa-2" @click="filter.id_comment = item; fetch()" style="border-radius: 6px;" :style="filter.id_comment === item ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #FFF'">
                          <div class="pr-0">
                            <v-img
                              :src="item.user_img_url"
                              :lazy-src="item.user_img_url"
                              v-if="item.user_img_url !== ''"
                              width="40"
                              height="40"
                              style="border-radius: 6px;"
                              class="grey lighten-2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <div v-if="item.user_img_url === undefined || item.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                              <v-row
                                class="fill-height ma-0 fs-28"
                                align="center"
                                justify="center"
                              >
                                {{ getKarekter(item.user_name) }}
                              </v-row>

                            </div>
                          </div>
                          <div style="width: 100%; margin-left: 15px;">
                            <div class="d-flex">
                              <div>
                                <div class="d-flex">
                                  <div class="text-second text--darken-4" style="width: 100%;">
                                    <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;">
                                      {{ item.user_name }}
                                    </div>
                                  </div>
                                </div>
                                <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #3498DB;">
                                  <span v-if="item.course_content_title_parent !== '' && item.course_content_title !== ''">
                                    {{ item.course_content_title_parent }} - {{ item.course_content_title }}
                                  </span>
                                  <span v-if="item.course_content_title_parent === '' && item.course_content_title === ''">
                                    Semua Bab
                                  </span>
                                  <span v-if="item.course_content_title_parent === '' && item.course_content_title !== ''">
                                    {{ item.course_content_title }} - {{ item.course_content_title }}
                                  </span>
                                </div>
                                <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.5;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                                  Posted· {{ item.last_update_datetime | datetimemonth }}
                                </div>
                              </div>
                              <div class="ml-auto text-right" v-if="item.user_id === user.id" style="width: 45%;">
                                <v-btn
                                  @click="reset(item); dialog.form = true;"
                                  text
                                  x-small
                                  :disabled="process.delete"
                                  class="text-capitalize fs-12 primary--text pl-0 pr-1">
                                  Ubah
                                </v-btn>
                                <v-btn
                                  class="text-capitalize fs-12 red--text pr-0 pl-1"
                                  text
                                  x-small
                                  :disabled="process.delete"
                                  @click="detailDiskusi = item; form.id = item.id; deletes(i);">
                                  Hapus
                                </v-btn>
                              </div>

                            </div>

                            <div class="fs-13 mt-0 mb-0" style="font-weight: 900; text-align: justify; color: #4ab762; mix-blend-mode: normal; opacity: 0.7;">
                              {{ item.title }}
                            </div>
                            <div class="fs-13 mt-0 mb-3" style="text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                              {{ item.text }}
                            </div>
                          </div>
                        </div>
                        <div v-if="list.length > 0 && index !== (list_user.length - 1)" class="my-3">
                          <v-divider style="opacity: 0.15; border-width: 1px; border-color: #979797;"></v-divider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-right mt-16" v-if="!process.user && list_user.length < 1">
                    <Empty v-model="contentEmpty"/>
                  </div>

                  <div class="px-0 mb-2 mt-8 text-center color-blue-first" v-if="!process.user && pagination_user.current < pagination_user.total_page">
                    <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline;" @click="loadet_list = true; page_user = page_user+1; fetchUser(page_user)">
                      <div v-if="loadet_list" class="my-10 text-center">
                        <v-skeleton-loader
                          class="ma-auto"
                          v-for="r in 4"
                          :key="r"
                          :loading="process.user"
                          type="list-item-avatar-three-line">
                        </v-skeleton-loader>
                      </div>
                      <div v-if="!loadet_list" class="text-center">
                        Tampilkan lebih banyak
                      </div>
                    </span>
                  </div>
                </div> -->
                </div>
              </v-col>
              <v-col cols="12" md="7" style="height: calc(100vh - 190px); overflow-y: auto;">
                <div>
                  <div v-if="process.run">
                    <v-skeleton-loader
                      class="ma-auto"
                      v-for="r in 4"
                      :key="r"
                      :loading="process.run"
                      type="list-item-avatar-three-line">
                    </v-skeleton-loader>
                  </div>
                  <div v-if="!process.run">
                    <v-text-field
                      solo
                      hide-details
                      clearable
                      v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
                      color="#4ab762"
                      v-model="filter.search"
                      class="mb-4"
                      v-on:keyup.enter="fetch()"
                      @click:clear="filter.search = ''; fetch();"
                      :placeholder="`Cari diskusi…`">
                      <template v-slot:prepend-inner>
                        <v-icon class="mr-2">mdi-magnify</v-icon>
                      </template>
                    </v-text-field>
                    <div v-if="filter.id_comment !== '' || filter.materi !== ''" class="mb-2">
                      <div class="font-weight-bold">
                        Filter: 
                      </div>
                      <div class="d-flex align-center" style="flex-wrap: wrap;">
                        <div v-if="filter.id_comment !== ''">
                          <v-chip
                            class="ma-2 white--text"
                            close
                            color="info"
                            @click:close="filter.id_comment = ''; fetch();">
                            Diskusi Saya
                          </v-chip>
                        </div>
                        <div v-if="filter.materi !== ''">
                          <v-chip
                            class="ma-2 white--text"
                            close
                            color="info"
                            @click:close="filter.materi = ''; fetch();">
                            Bab atau Materi : {{ filter.materi_title }}
                          </v-chip>
                        </div>
                      </div>
                    </div>
                    <div v-for="(item, index) in list" :key="index" >
                      <div class="d-flex">
                        <div class="pr-0">
                          <v-img
                            :src="item.user_img_url"
                            :lazy-src="item.user_img_url"
                            v-if="item.user_img_url !== ''"
                            width="40"
                            height="40"
                            style="border-radius: 6px;"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <div v-if="item.user_img_url === undefined || item.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                            <v-row
                              class="fill-height ma-0 fs-28"
                              align="center"
                              justify="center"
                            >
                              {{ getKarekter(item.user_name) }}
                            </v-row>

                          </div>
                        </div>
                        <div style="width: 100%; margin-left: 15px;">
                          <div class="d-flex">
                            <div>
                              <div class="d-flex">
                                <div class="text-second text--darken-4" style="width: 100%;">
                                  <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;">
                                    {{ item.user_name }}
                                  </div>
                                  <!-- <div style="color: #3498db; font-size: 13px;">
                                    [ {{ item.type }} ]
                                  </div> -->
                                </div>
                              </div>
                              <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #3498DB;">
                                <span v-if="item.course_content_title_parent !== '' && item.course_content_title !== ''">
                                  {{ item.course_content_title_parent }} - {{ item.course_content_title }}
                                </span>
                                <span v-if="item.course_content_title_parent === '' && item.course_content_title === ''">
                                  Semua Bab
                                </span>
                                <span v-if="item.course_content_title_parent === '' && item.course_content_title !== ''">
                                  {{ item.course_content_title }} - {{ item.course_content_title }}
                                </span>
                              </div>
                              <div class="fs-12" style="color: #505050; mix-blend-mode: normal; opacity: 0.5;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                                Posted· {{ item.last_update_datetime | datetimemonth }}
                              </div>
                            </div>
                            <div class="ml-auto text-right" v-if="item.user_id === user.id" style="width: 45%;">
                              <v-btn
                                @click="reset(item); dialog.form = true;"
                                text
                                x-small
                                :disabled="process.delete"
                                class="text-capitalize fs-12 primary--text pl-0 pr-1">
                                Ubah
                              </v-btn>
                              <v-btn
                                class="text-capitalize fs-12 red--text pr-0 pl-1"
                                text
                                x-small
                                :disabled="process.delete"
                                @click="detailDiskusi = item; form.id = item.id; deletes(i);">
                                Hapus
                              </v-btn>
                            </div>

                          </div>

                          <div class="fs-13 mt-0 mb-0" style="font-weight: 900; text-align: justify; color: #4ab762; mix-blend-mode: normal; opacity: 0.7;">
                            {{ item.title }}
                          </div>
                          <div class="fs-13 mt-0 mb-3" style="text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                            {{ item.text }}
                          </div>

                          <div>
                            <div class="d-flex">
                              <div>
                                <v-img
                                  :src="user.img_url"
                                  :lazy-src="user.img_url"
                                  width="38"
                                  v-if="user.img_url !== ''"
                                  height="38"
                                  style="border-radius: 6px;"
                                  class="grey lighten-2"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <div v-if="user.img_url === undefined || user.img_url === ''" style="background-color: #4ab762; color: #fff; width: 38px; height: 38px;" class="rounded-lg">
                                  <v-row
                                    class="fill-height ma-0 fs-28"
                                    align="center"
                                    justify="center"
                                  >
                                    {{ getKarekter(user.user_name) }}
                                  </v-row>

                                </div>
                              </div>
                              <div style="width: 100%; margin-left: 15px;">
                                <ValidationObserver ref="observerComment">
                                  <div>
                                    <ValidationProvider :name="`tanggapan`" rules="max:1000" v-slot="{ errors }">
                                      <v-text-field
                                        color="#4ab762"
                                        rows="2"
                                        class="mt-0 pt-0"
                                        :counter="1000"
                                        :autofocus="false"
                                        @input="item.error = ''"
                                        v-on:keyup.enter="index_materi = index; detailDiskusi = item; saveComment()"
                                        :messages="'Optional'"
                                        v-model="item.comment"
                                        placeholder="Tuliskan tanggapan anda disini"
                                        :error-messages="errors">
                                        <template v-slot:message="{ message }">
                                          <span :class="item.error !== '' ? 'red--text' : message !== 'Optional' ? 'red--text' : 'color-first'" v-html="item.error !== '' ? item.error : message"></span>
                                        </template>
                                        <template v-slot:counter="{ props }">
                                          <span style="font-size: 9px;" class="text-second">
                                            {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </ValidationProvider>
                                  </div>
                                </ValidationObserver>
                              </div>
                            </div>
                          </div>
                          <div v-if="!item.child_open_bool && parseInt(item.child_count) > 0" class="mt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                            <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline; color: #505050;" @click="error.message = ''; item.child_open_bool = true; detailDiskusi = item, item.child !== undefined ? '' : fetchComment(undefined, index)">
                              Tampilkan komentar ({{ item.child_count }})
                            </span>
                          </div>
                          <div v-if="process.detail && detailDiskusi.id === item.id" class="my-10 text-center">
                            <v-progress-circular
                              indeterminate
                              color="success"
                            ></v-progress-circular>
                          </div>
                          <div v-if="item.child_open_bool" class="mt-2">
                            <div v-for="(l, i) in item.child" :key="i" class="d-flex mb-3">
                              <div>
                                <v-img
                                  :src="l.user_img_url"
                                  :lazy-src="l.user_img_url"
                                  v-if="l.user_img_url !== ''"
                                  width="40"
                                  height="40"
                                  style="border-radius: 6px;"
                                  class="grey lighten-2"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <div v-if="l.user_img_url === undefined || l.user_img_url === ''" style="background-color: #4ab762; color: #fff; width: 40px; height: 40px;" class="rounded-lg">
                                  <v-row
                                    class="fill-height ma-0 fs-28"
                                    align="center"
                                    justify="center"
                                  >
                                    {{ getKarekter(l.user_name) }}
                                  </v-row>

                                </div>
                              </div>
                              <div style="width: 100%; margin-left: 15px;">
                                <div class="d-flex align-center">
                                  <div class="text-second text--darken-4" :style="l.is_user ? 'width: 55%;' : 'width: 100%;'">
                                    <div style="font-weight: 900; font-size: 14px; line-height: 16px; color: #505050;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                                      {{ l.user_name }} <span style="font-weight: normal; font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;">- {{ l.last_update_datetime | datetimemonth }}</span>
                                    </div>
                                  </div>
                                  <div class="ml-auto text-right" v-if="l.user_id === user.id" style="width: 45%;">
                                    <v-btn
                                      @click="detailDiskusi = item; error.message = ''; form.id = l.id; item.comment = l.text; detailDiskusi = item;"
                                      text
                                      x-small
                                      :disabled="process.delete"
                                      class="text-capitalize fs-12 primary--text pl-0 pr-1">
                                      Ubah
                                    </v-btn>
                                    <v-btn
                                      class="text-capitalize fs-12 red--text pr-0 pl-1"
                                      text
                                      x-small
                                      :disabled="process.delete"
                                      :loading="process.delete && index_child === i"
                                      @click="detailDiskusi = item; index_child = i; index_materi = index; form.id = l.id; deleteComment(i);">
                                      Hapus
                                    </v-btn>
                                  </div>
                                </div>
                                <div class="mt-1" style="font-size: 12px; line-height: 18px; text-align: justify; color: #505050; mix-blend-mode: normal; opacity: 0.7;">
                                  {{ l.text }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="item.child_open_bool && !process.detail && parseInt(item.child_count) > 0" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                            <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline; color: #505050;" @click="error.message = ''; item.child_open_bool = false">
                              Tutup komentar
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-if="list.length > 0 && index !== (list.length - 1)" class="my-3">
                        <v-divider style="opacity: 0.15; border-width: 1px; border-color: #979797;"></v-divider>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right mt-16" v-if="!process.run && list.length < 1">
                  <Empty v-model="contentEmpty"/>
                </div>

                <div class="px-0 mb-2 mt-8 text-center color-blue-first" v-if="!process.run && pagination.current < pagination.total_page">
                  <span class="cursor-pointer" style="font-size: 12px; line-height: 18px; text-align: justify; text-decoration-line: underline;" @click="loadet_list = true; page = page+1; fetch(page)">
                    <div v-if="loadet_list" class="my-10 text-center">
                      <v-skeleton-loader
                        class="ma-auto"
                        v-for="r in 4"
                        :key="r"
                        :loading="process.run"
                        type="list-item-avatar-three-line">
                      </v-skeleton-loader>
                    </div>
                    <div v-if="!loadet_list" class="text-center">
                      Tampilkan lebih banyak
                    </div>
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-fab-transition v-if="!process.run">
          <v-btn
            elevation="3"
            fab
            fixed
            bottom
            right
            style="position: absolute;"
            class="white--text"
            @click="error.message = ''; dialog.form = true"
            color="#3498db">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    data () {
      return {
        detail: {},
        dialog: {
          detail: false,
          form: false
        },
        form:{
          id: "",
          content_id: {},
          materi_id: "",
          title: "",
          text: "",
          comment: ""
        },
        selected: {
          sub: "",
          type: "",
          index: 0
        },
        bab: [
          {
            title: "Semua Bab",
            id: "",
            sub: []
          }
        ],
        filter: {
          search: "",
          materi :"",
          id_comment: "",
          materi_title: ""
        },
        loadet_list: false,
        loading_message: "Loading...",
        detail: {},
        materi: [],
        materi_filter: [
          {
            title: "Semua Materi",
            content_id: ""
          }
        ],
        materi_web_filter: [
          {
            title: "Semua Materi",
            content_id: ""
          }
        ],
        detailDiskusi: {},
        page: 1,
        materi_url: [],
        contentEmpty: "",
        list: [],
        pagination: {},
        list_user: [],
        pagination_user: {},
        page_user: 1,
        color: "",
        snackbar: false,
        timeout: 2000,
        nama_image: "",
        loader: false,
        index_materi: 0,
        index_child: 0,
        playlis_materi: [],
        process: {
          detail: false,
          run: false,
          read: false,
          form: false,
          delete: false,
          user: false
        },
        warning: {
          message: ""
        },
        success: {
          message: ""
        },
        error: {
          message: ""
        }
      }
    },
    props: {
      dialogopen: {
        type: Boolean,
        default: false
      },
      detailCollection: {
        type: Object,
        default: () => {}
      },
      detailMateri: {
        type: Array,
        default: () => []
      },
    },
    watch: {
      'dialogopen': function(val) {
        this.dialog.detail = val
        this.fetch()
      },
      'detailCollection': function(val) {
        this.bab = val.content
        this.detail = val
      },
      'detailMateri': function(val) {
        this.materi_web_filter = [
          {
            title: "Semua Materi",
            content_id: ""
          }
        ]
        for (let index = 0; index < val.length; index++) {
          this.materi_web_filter.push(val[index])
        }
      },
      'form.content_id': function() {
        let t = this
        if (this.materi.length > 1) {
          this.form.materi_id = ""
        }
        this.materi = []
        for (let index = 0; index < this.bab.length; index++) {
          for (let j = 0; j < this.materi_url.length; j++) {
            if (t.form.content_id.content_id === this.materi_url[j].parent_id || t.form.content_id.content_id === this.materi_url[j].content_id) {
              t.materi.push(this.materi_url[j])
            }
          }
        }
        // for (let j = 0; j < t.materi.length; j++) {
        //   if (this.form.materi_id !== t.materi[j].content_id ) {
        //     this.form.materi_id = ""
        //   }
        // }
        // if (this.form.id === '' && this.form.materi_id !== '') {
          
        //   this.form.materi_id = ""
        // }
      },
      'filter.materi': function() {
        if (this.filter.materi !== '') {
          for (let index = 0; index < this.detail.content.length; index++) {
            if (this.filter.materi === this.detail.content[index].content_id) {
              this.filter.materi_title = this.detail.content[index].title
            }
            for (let j = 0; j < this.detail.content[index].sub.length; j++) {
              if (this.filter.materi === this.detail.content[index].sub[j].content_id) {
                this.filter.materi_title = this.detail.content[index].sub[j].title
              }
            }
          }
        }
        // if (this.form.id === '' && this.form.materi_id !== '') {
          
        //   this.form.materi_id = ""
        // }
      },
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      Loading: () => import('@/components/Loading')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.detail = this.$props.detailCollection
      this.materi_web_filter = [
        {
          title: "Semua Materi",
          content_id: ""
        }
      ]
      for (let index = 0; index < this.$props.detailMateri.length; index++) {
        this.materi_web_filter.push(this.$props.detailMateri[index])
      }
      for (let index = 0; index < this.detail.content.length; index++) {
        this.bab.push(this.detail.content[index])
        // this.materi_filter.push({
        //   content_id: this.detail.content[index].content_id,
        //   id: this.detail.content[index].id,
        //   parent_id: this.detail.content[index].parent_id,
        //   children: this.detail.content[index].sub,
        //   name: this.detail.content[index].title,
        // })
      }
      for (let i in this.detail.content) {
        if (this.detail.content[i].file_url !== '') {
          this.materi_url.push(this.detail.content[i])
          this.materi_filter.push(this.detail.content[i])
        }
        for (let j in this.detail.content[i].sub) {
          if (this.detail.content[i].sub[j].file_url !== '') {
            this.materi_url.push(this.detail.content[i].sub[j])
            this.materi_filter.push(this.detail.content[i])
          }
        }
      }
      this.fetch()
      this.fetchUser()
    },
    methods: {
      fetch (page) {
        this.process.run = this.loader || this.loadet_list ? false : true
        let params = {
          limit: page === undefined ? 10 : page * 10,
          course_id: this.detail.course_id,
          sort: "-id",
          course_content_id: this.filter.materi,
          search: this.filter.search,
          id: this.filter.id_comment !== '' ? this.user.id : ""
        }
        this.$axios.$get(`api/course_discussion`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = []
            for (let index = 0; index < response.results.data.length; index++) {
              this.list.push({
                child_count: response.results.data[index].child_count,
                child_open_bool: response.results.data[index].child_open_bool,
                course_category_id: response.results.data[index].course_category_id,
                course_category_name: response.results.data[index].course_category_name,
                course_content_id: response.results.data[index].course_content_id,
                course_content_title: response.results.data[index].course_content_title,
                course_content_title_parent: response.results.data[index].course_content_title_parent,
                course_group_id: response.results.data[index].course_group_id,
                course_group_name: response.results.data[index].course_group_name,
                course_id: response.results.data[index].course_id,
                course_img_url: response.results.data[index].course_img_url,
                course_level: response.results.data[index].course_level,
                course_name: response.results.data[index].course_name,
                course_organization_category: response.results.data[index].course_organization_category,
                course_organization_id: response.results.data[index].course_organization_id,
                course_organization_img_url: response.results.data[index].course_organization_img_url,
                course_organization_name: response.results.data[index].course_organization_name,
                created_datetime: response.results.data[index].created_datetime,
                created_datetime_readable: response.results.data[index].created_datetime_readable,
                downvote_count: response.results.data[index].downvote_count,
                id: response.results.data[index].id,
                img_url_array: response.results.data[index].img_url_array,
                last_update_datetime: response.results.data[index].last_update_datetime,
                last_update_datetime_readable: response.results.data[index].last_update_datetime_readable,
                text: response.results.data[index].text,
                title: response.results.data[index].title,
                upvote_count: response.results.data[index].upvote_count,
                user_as_publisher_bool: response.results.data[index].user_as_publisher_bool,
                user_author_bool: response.results.data[index].user_author_bool,
                user_id: response.results.data[index].user_id,
                user_img_url: response.results.data[index].user_img_url,
                user_name: response.results.data[index].user_name,
                comment: "",
                error: ""
              })
            }
            this.pagination = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.run = false
            this.loadet_list = false
            this.process.form = false
            this.loader = false
          }else{
            this.process.run = false
          }
        })
      },
      fetchUser (page) {
        this.process.user = this.loader || this.loadet_list ? false : true
        let params = {
          limit: page === undefined ? 10 : page * 10,
          course_id: this.detail.course_id,
          sort: "-id",
          user_id: this.user.id
        }
        this.$axios.$get(`api/course_discussion`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list_user = []
            for (let index = 0; index < response.results.data.length; index++) {
              this.list_user.push({
                child_count: response.results.data[index].child_count,
                child_open_bool: response.results.data[index].child_open_bool,
                course_category_id: response.results.data[index].course_category_id,
                course_category_name: response.results.data[index].course_category_name,
                course_content_id: response.results.data[index].course_content_id,
                course_content_title: response.results.data[index].course_content_title,
                course_content_title_parent: response.results.data[index].course_content_title_parent,
                course_group_id: response.results.data[index].course_group_id,
                course_group_name: response.results.data[index].course_group_name,
                course_id: response.results.data[index].course_id,
                course_img_url: response.results.data[index].course_img_url,
                course_level: response.results.data[index].course_level,
                course_name: response.results.data[index].course_name,
                course_organization_category: response.results.data[index].course_organization_category,
                course_organization_id: response.results.data[index].course_organization_id,
                course_organization_img_url: response.results.data[index].course_organization_img_url,
                course_organization_name: response.results.data[index].course_organization_name,
                created_datetime: response.results.data[index].created_datetime,
                created_datetime_readable: response.results.data[index].created_datetime_readable,
                downvote_count: response.results.data[index].downvote_count,
                id: response.results.data[index].id,
                img_url_array: response.results.data[index].img_url_array,
                last_update_datetime: response.results.data[index].last_update_datetime,
                last_update_datetime_readable: response.results.data[index].last_update_datetime_readable,
                text: response.results.data[index].text,
                title: response.results.data[index].title,
                upvote_count: response.results.data[index].upvote_count,
                user_as_publisher_bool: response.results.data[index].user_as_publisher_bool,
                user_author_bool: response.results.data[index].user_author_bool,
                user_id: response.results.data[index].user_id,
                user_img_url: response.results.data[index].user_img_url,
                user_name: response.results.data[index].user_name,
                comment: "",
                error: ""
              })
            }
            this.pagination_user = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.user = false
            this.loadet_list = false
          }else{
            this.process.user = false
          }
        })
      },
      async fetchComment (p, index) {
        this.process.detail = this.loader ? false : true
        let params = {
          limit: 100000000,
          page: p === undefined ? 1 : p,
          sort: "-id",
          parent_id: this.detailDiskusi.id,
          // rate: this.filter.rating,
          // search: this.filter.search
        }
        this.$axios.$get(`api/course_discussion_comment`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.$refs.observerComment[0].reset()
            let list = []
            list = response.results.data
            if (this.loader) {
              this.process.form = false
              this.process.delete = false
              this.snackbar = true
            }
            if (index !== undefined) {
              this.list[index].child = list
              this.list[index].child_count = list.length
              if (this.list[this.index_materi].child < 1) {
                this.list[index].child_open_bool = false
              } else {
                this.list[index].child_open_bool = true
              }
            }
            this.loader = false
            // this.pagination = response.results.pagination
            // this.contentEmpty = "Data belum tersedia"
            this.process.detail = false
          }else{
            this.process.detail = false
          }
        })
      },
      getKarekter(item) {
        let name = item
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        // this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        return initial.toUpperCase()
      },
      reset(item) {
        this.error.message=""
        let bab_id = ""
        let parent_id = ""
        let materi_id = ""
        if (item !== undefined) { 
          for (let i in this.detail.content) {
            if (item.course_content_id === this.detail.content[i].content_id) {
              bab_id = this.detail.content[i]
            }
            for (let j in this.detail.content[i].sub) {
              if (item.course_content_id === this.detail.content[i].sub[j].content_id) {
                console.log(this.detail.content[i].sub[j])
                parent_id = this.detail.content[i].sub[j].parent_id
                materi_id = this.detail.content[i].sub[j].content_id
              }
            }
            if (materi_id !== "") {
              if (parent_id === this.detail.content[i].content_id) {
                bab_id = this.detail.content[i]
              }
            }
            if (materi_id === "" && bab_id !== '') {
              materi_id = bab_id.content_id
            }
          }
        }
        if (item !== undefined && bab_id === "") {
          bab_id = {
            title: "Semua Bab",
            id: "",
            sub: []
          }
        }
        this.form = {
          id: item !== undefined ? item.id : "",
          content_id: item !== undefined ? bab_id : {},
          materi_id: item !== undefined ? materi_id : "",
          text: item !== undefined ? item.text : "",
          title: item !== undefined ? item.title : "",
          comment: ""
        }
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (Object.keys(this.form.content_id).length > 0) {
          if (isValid) {
            if (this.form.materi_id === null) {
              this.form.materi_id = ''
            }
            if (this.form.id === '') {
              this.creates()
            } else {
              this.updates()
            }
          } else {
            this.warning.message = "Harap periksa kembali inputan Anda"
            this.color="warning"
            this.snackbar = true
          }
        } else {
          this.warning.message = "Harap pilih bab terlebih dahulu"
          this.color="warning"
          this.snackbar = true
        }
      },
      async creates() {
        this.loading_message = "Membuat diskusi Anda..."
        this.process.form = true
        await this.$axios.$post(`api/course_discussion`, 
        {
          course_id: this.detail.course_id,
          content_id: this.form.content_id.id === '' ? 0 : this.form.materi_id !== '' ? this.form.materi_id : this.form.content_id.content_id,
          title: this.form.title,
          text: this.form.text
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.$emit('add')
            this.fetch(this.page)
            this.fetchUser()
            this.dialog.form = false
            this.snackbar = true
            this.color="#27ae60"
            this.success.message="Berhasil membuat diskusi"
            
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        this.loading_message = "Mengubah diskusi Anda..."
        this.process.form = true
        await this.$axios.$post(`api/course_discussion/update`, 
        {
          id: this.form.id,
          course_id: this.detail.course_id,
          content_id: this.form.content_id.id === '' ? 0 : this.form.materi_id !== '' ? this.form.materi_id : this.form.content_id.content_id,
          title: this.form.title,
          text: this.form.text
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.fetch(this.page)
            this.fetchUser()
            this.dialog.form = false
            this.snackbar = true
            this.color="#27ae60"
            this.success.message="Berhasil mengubah diskusi"
            // this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async deletes() {
        this.loading_message = "Menghapus diskusi Anda..."
        this.process.delete = true
        await this.$axios.$post(`api/course_discussion/delete`, 
        {
          id: this.form.id
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.delete = false
            this.reset()
            this.fetch(this.page)
            this.fetchUser()
            this.$emit('deletes')
            this.color="#27ae60"
            this.success.message="Berhasil menghapus diskusi"
            this.snackbar = true
            this.process.delete = false
          } else {
            this.process.delete = false
            this.error.message = response.message
          }
        })
      },



      async saveComment() {
        this.error.message = ''
        const isValid = await this.$refs.observerComment[0].validate()
        if (isValid && this.list[this.index_materi].comment.length > 0 ) {
          this.loader = true
          if (this.form.id === '') {
            this.createsComment()
          } else {
            this.updatesComment()
          }
        } else {
          this.list[this.index_materi].error = "Tanggapan Tidak Boleh Kosong"
          this.warning.message = "Harap periksa kembali inputan Anda"
          this.color="warning"
          this.snackbar = true
        }
      },
      async createsComment() {
        this.loading_message = "Membuat diskusi Anda..."
        this.process.form = true
        await this.$axios.$post(`api/course_discussion_comment`, 
        {
          id: this.detailDiskusi.id,
          text: this.list[this.index_materi].comment
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.form = false
            this.reset()
            this.fetchComment(undefined, this.index_materi)
            console.log(this.$refs.observerComment[0].reset())
            this.$refs.observerComment[0].reset()
            this.list[this.index_materi].comment = ""
            this.color="#27ae60"
            this.success.message="Berhasil membuat diskusi"
            // this.snackbar = true
          } else {
            this.process.form = false
            this.list[this.index_materi].error = response.message
          }
        })
      },
      async updatesComment() {
        this.loading_message = "Mengubah diskusi Anda..."
        this.process.form = true
        await this.$axios.$post(`api/course_discussion_comment/update`, 
        {
          id: this.form.id,
          text: this.list[this.index_materi].comment
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.form = false
            this.reset()
            this.fetchComment(undefined, this.index_materi)
            this.color="#27ae60"
            this.$refs.observerComment[0].reset()
            this.list[this.index_materi].comment = ""
            this.success.message="Berhasil mengubah diskusi"
            // this.snackbar = true
          } else {
            this.process.form = false
            this.list[this.index_materi].error = response.message
          }
        })
      },

      async deleteComment(index) {
        this.loading_message = "Menghapus diskusi Anda..."
        this.process.delete = true
        this.loader = true
        await this.$axios.$post(`api/course_discussion_comment/delete`, 
        {
          id: this.form.id
        })
        .then((response) => {
          if (response.status === 200) {
            // this.process.delete = false
            this.reset()
            this.fetchComment(undefined, this.index_materi)
            this.list[this.index_materi].child.splice(index, 1)
            if (this.list[this.index_materi].child < 1) {
              this.list[this.index_materi].child_open_bool = false
            }
            this.color="#27ae60"
            this.success.message="Berhasil menghapus diskusi"
            // this.snackbar = true
          } else {
            this.process.delete = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>