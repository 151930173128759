<template>
  <div>
    <section>
      <v-card-text class="pa-0">
        <v-row>
          <v-col cols="12" md="6" class="py-0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <div class="d-flex align-center text-second mb_6">
              <div>
                Harga Kelas
              </div>
              <div class="red--text ml-auto caption">
                Wajib
              </div>
            </div>
            <ValidationProvider name="Harga Kelas" rules="required|min_0:0" v-slot="{ errors }">
              <v-text-field
                color="#4ab762"
                class="pt-0"
                outlined
                dense
                type="number"
                @keypress="parseInt(form.price) === 0 ? 
                price = {
                  akses: 'Selamanya',
                  number: 0,
                  mounth: 'Bulan'
                }
                 : ''; parseInt(form.price) === 0 ?  $emit('addData', (0 * 1)) : '' "

                @blur="parseInt(form.price) === 0 ? 
                price = {
                  akses: 'Selamanya',
                  number: 0,
                  mounth: 'Bulan'
                }
                 : ''; parseInt(form.price) === 0 ?  $emit('addData', (0 * 1)) : '' "
                 
                @change="parseInt(form.price) === 0 ? 
                price = {
                  akses: 'Selamanya',
                  number: 0,
                  mounth: 'Bulan'
                }
                 : ''; parseInt(form.price) === 0 ?  $emit('addData', (0 * 1)) : '' "
                placeholder="Tuliskan Harga disini"
                v-model="form.price"
                :error-messages="errors"
                require>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" class="py-0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <div class="d-flex align-center text-second mb_6">
              <div>
                Akses Kelas
              </div>
              <div class="red--text ml-auto caption">
                Wajib
              </div>
            </div>
            <v-row>
              <v-col cols="5">
                <v-select
                  color="#4ab762"
                  hide-details="true"
                  class="pt-0"
                  outlined
                  :disabled="parseInt(form.price) === 0"
                  dense
                  :items="[
                    {
                      text: 'Tahunan',
                      value: 'Tahunan'
                    },
                    {
                      text: 'Bulanan',
                      value: 'Bulanan'
                    },
                    {
                      text: 'Selamanya',
                      value: 'Selamanya'
                    }
                  ]"
                  @keypress="price.akses === 'Selamanya' ? $emit('addData', (0 * 1)) : price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12)); price.akses === 'Selamanya' || price.akses === 'Bulanan' ? price.mounth = 'Bulan' : ''; price.akses === 'Selamanya' ? price.number = 0 : price.number === 0 ? price.number = 1 : ''"
                  @blur="price.akses === 'Selamanya' ? $emit('addData', (0 * 1)) : price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12)); price.akses === 'Selamanya' || price.akses === 'Bulanan' ? price.mounth = 'Bulan' : ''; price.akses === 'Selamanya' ? price.number = 0 : price.number === 0 ? price.number = 1 : ''"
                  @change="price.akses === 'Selamanya' ? $emit('addData', (0 * 1)) : price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12)); price.akses === 'Selamanya' || price.akses === 'Bulanan' ? price.mounth = 'Bulan' : ''; price.akses === 'Selamanya' ? price.number = 0 : price.number === 0 ? price.number = 1 : ''"
                  item-text="text"
                  item-value="value"
                  placeholder="Tuliskan disini"

                  v-model="price.akses"
                  require>
                </v-select>
              </v-col>
              <v-col cols="3" class="pl-0">
                <v-select
                  color="#4ab762"
                  hide-details="true"
                  class="pt-0"
                  @keypress="price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12))"
                  @blur="price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12))"
                  @change="price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12))"
                  :dense="price.akses !== '' && price.akses !== 'Selamanya'"
                  :solo="price.akses === '' || price.akses === 'Selamanya'"
                  :outlined="price.akses !== '' && price.akses !== 'Selamanya'"
                  :disabled="price.akses === '' || price.akses === 'Selamanya'"
                  :items="price.akses === 'Selamanya' ? number_without_0 : number"
                  placeholder="Tuliskan disini"

                  v-model="price.number"
                  require>
                </v-select>
              </v-col>
              <v-col cols="4" class="pl-0">
                <v-select
                  color="#4ab762"
                  hide-details="true"
                  class="pt-0"
                  @keypress="price.number !== '' ? price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12)): ''"
                  @blur="price.number !== '' ? price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12)): ''"
                  @change="price.number !== '' ? price.mounth === 'Bulan' ? $emit('addData', (price.number * 1)) : $emit('addData', (price.number * 12)): ''"
                  :dense="price.number !== '' && price.akses !== '' && price.akses !== 'Selamanya' && price.akses !== 'Bulanan'"
                  :solo="price.number === '' || price.akses === '' || price.akses === 'Selamanya' || price.akses === 'Bulanan'"
                  :outlined="price.number !== '' && price.akses !== '' && price.akses !== 'Selamanya' && price.akses !== 'Bulanan'"
                  :disabled="price.number === '' || price.akses === '' || price.akses === 'Selamanya' || price.akses === 'Bulanan'"
                  :items="[
                    {
                      text: 'Tahun',
                      value: 'Tahun'
                    },
                    {
                      text: 'Bulan',
                      value: 'Bulan'
                    }
                  ]"
                  placeholder="Tuliskan disini"
                  item-text="text"
                  item-value="value"

                  v-model="price.mounth"
                  require>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </section>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          code: false,
          metode: false
        },
        toLogout: '',
        panel: 0,
        menu: ['Informasi Toko', 'Rekening Bank', 'Organisasi'],
        selected: {
          menu: 0
        },
        price: {
          akses: "",
          number: "",
          mounth: ""
        },
        number: [],
        number_without_0:[],
        snackbar: true,
        timeout: 2000,
        avatar: null,
        form: {},
        nama_image: "",
        process: {
          run: false,
          avatar: false,
          form: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        content: {
          url: '/account/setting',
          title: 'Pengaturan',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      ImageInput: () => import('@/components/ImageInput')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      detailClass: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'detailClass': function(val) {
        this.form = val
        if (val.access_month !== '') {
          if (val.access_month === 0) {
            this.price = {
              akses: "Selamanya",
              number: 0,
              mounth: "Bulan"
            }
          } else if (val.access_month % 6 === 0 ) {
            this.price = {
              akses: "Tahunan",
              number: (val.access_month / 12),
              mounth: "Tahun"
            }
          } else if (val.access_month % 6 !== 0) {
            this.price = {
              akses: "Bulanan",
              number: val.access_month,
              mounth: "Bulan"
            }
          }
        } else {
          this.price = {
            akses: "Selamanya",
            number: 0,
            mounth: "Bulan"
          }
        }
      },

      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.img_url = ''
          }
        },
        deep: true
      }
    },
    created() {

      let day = []
      let day_0 =[]
      for (let i = 1; i <= 24; i++) {
        day.push(i)
      }
      for (let i = 0; i <= 24; i++) {
        day_0.push(i)
      }
      this.number = day
      this.number_without_0 = day_0

    },
    mounted () {
      this.form = this.$props.detailClass
      if (this.$props.detailClass.access_month !== '') {
        if (this.$props.detailClass.access_month === 0) {
          this.price = {
            akses: "Selamanya",
            number: 0,
            mounth: "Bulan"
          }
        } else if (this.$props.detailClass.access_month % 6 === 0 ) {
          this.price = {
            akses: "Tahunan",
            number: (this.$props.detailClass.access_month / 12),
            mounth: "Tahun"
          }
        } else if (this.$props.detailClass.access_month % 6 !== 0) {
          this.price = {
            akses: "Bulanan",
            number: this.$props.detailClass.access_month,
            mounth: "Bulan"
          }
        }
      } else if (this.$props.detailClass.access_month === '') {
          this.price = {
            akses: "Selamanya",
            number: 0,
            mounth: "Bulan"
          }
        }
      this.getKarekter()
    },
    methods: {
      getKarekter() {
        let name = this.$store.state.member.auth.user.name
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        // return initial.toUpperCase()
      },
      validation() {
        var error = this.error,
          valid = true;

        if (this.form.img_url.length < 1) {
          error.img_url = "Gambar tidak boleh kosong";
          valid = false;
        } else {
          error.img_url = "";
        }

        return valid;
      }
    },
  };
</script>