<template>
  <div>
    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.form"
      v-model="dialog.form"
      width="600">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div class="text-second text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'title'">
            Preview Materi
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.form = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
				<div>
					<v-img v-if="player.type === ''" contain :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" :src="detail_kelas.img_url" :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: 400px;' : 'height: 250px'"></v-img>
					<section v-if="player.type === 'docs'"
						style="background: #333638;">
						<iframe
							frameborder="0" 
							width="100%"
							:style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: 400px;' : 'height: 250px'"
							:src="player.data.file_url">
						</iframe>

					</section>

					<section v-if="player.type === 'audio'"
						style="background: #f0f3f4;">
						<audio
							style="width: 100%; position: relative; bottom: -8px; background: #f0f3f4;"
							autoplay
							controls
							preload="none">
							<source :src="player.data.file_url">
						</audio>
					</section>

					<section v-if="player.type === 'video'"
						style="background: black;">
						<video
							autoplay
							controls 
							controlsList="nodownload"
							width="100%" 
							:style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: 400px;' : 'height: 250px'"
							style="`object-fit: contain;`"
							:poster="detail_kelas.img_url">
							<source :src="player.data.file_url">
						</video>
					</section>
				</div>
        <v-divider></v-divider>
        <v-card-text class="px-0 mt-2" style="overflow-y: auto; height: calc(100vh - 546px);">
					<div>
						<div v-for="(materi, i) in detail.content" :key="i" style="padding: 10px 20px;" @click="playing(materi)">
							<div class="d-flex" style="padding: 10px 13px;" :style="selected === materi.id ? 'background: #f7f7f7cf; border: 1px solid #4ab762; border-radius: 6px;' : ''">
								<div v-if="materi.file_url !== ''">
									<div
										style="min-width: 45px; width: 45px; padding-top: 15.5px">
										<v-icon 
											size="25"
											:color="selected === materi.id ? '#4ab762' : 'grey'">
											{{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
										</v-icon>
									</div>
								</div>
								<div>
									<div class="d-flex">
										<div class="mr-4">
											<v-img
												width="54"
												height="54"
												class="d-flex align-center justify-center"
												gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
												:src="materi.img_url === '' ? detail_kelas.img_url : materi.img_url"
												style="border-radius: 5px;">
												<template v-slot:placeholder>
													<v-row
														class="fill-height"
														align="center"
														justify="center">
														<v-progress-circular 
															indeterminate 
															color="#FF3177">
														</v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</div>

										<div class="body-1 font-weight-light text-second body-2">
											<div class="body-2 font-weight-bold mb-1">
												{{ materi.title }}
											</div>
											<div v-if="materi.description !== ''">
												<div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="materi.description.length > 100">
													<div v-if="materi.preview_img_url === ''">
														<span class="body-2" style="white-space: pre-line;" v-html="materi.description.substring(0, 100) + '...'">
														</span>
														<span class="text-second cursor-pointer mt-5" @click="materi.preview_img_url = 'oke'"><u>Selengkapnya</u></span>
													</div>
													<div v-if="materi.preview_img_url !== ''">
														<span class="body-2 mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
														<span class="text-second cursor-pointer mt-0" @click="materi.preview_img_url = ''"><u>Tutup</u></span>
													</div>
												</div>
												<div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="materi.description.length <= 100">
													<div class="body-2" style="white-space: pre-line;" v-html="materi.description"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
        </v-card-text>
			</v-card>
		</v-dialog>

    
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
					form: false
				},
        selected: '',
        player: {
          type: "",
          data: {},
          id: null
        },
				detail_kelas: {},
				detail:{
					content: [],
				},
        process: {
          run: false
        },
        // SEO
        // content: {
        //   url: '/home',
        //   title: 'Beranda',
        //   description: 'otodidak.sodapos.com',
        //   image: require('@/assets/logo/logo.png'),
        // }
      }
    },
    // head () {
    //   return {
    //     title: this.content.title,
    //     meta: createSEOMeta(this.content)
    //   }
    // },
		props: {
      detailMateri: {
        type: Array,
        default: () => []
      },
      dialogopen: {
        type: Boolean,
        default: false
      },
			detailKelas: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'detailMateri': function(val) {
        this.detail.content = val
      },
      'dialogopen': function(val) {
        this.dialog.form = val
        this.player = {
          type: "",
          data: {},
          id: null
        }
        this.selected = ''
      },
			'detailKelas': function(val) {
        this.detail_kelas = val
      },
		},
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      detail() {
        return this.$store.state.preview.content
      },
    },
    mounted () {
			this.detail.content = this.$props.detailMateri
			this.dialog.form = this.$props.dialogopen
			this.detail_kelas = this.$props.detailKelas
      this.player = {
        type: "",
        data: {},
        id: null
      }
    },
    methods: {
			playing(item) {

        // let status = false
        // if (item.id === this.player.data.id) {
        //   if(!this.player.media.is_play) {
        //     status = true
        //   } else {
        //     status = false
        //   }
        // } else {
        //   status = true
        // }

        this.player.type = item.type
        this.player.data = item
        this.player.id = item.id
        this.selected = item.id
      },
    },
  };
</script>