<template>
  <div>
    <v-container>
      <v-dialog
        persistent
        scrollable
        v-if="dialog.detail"
        v-model="dialog.detail"
        width="600">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 pb-5" style="padding: 27px 25px;">
            <div class="fs-18 text-second text--darken-2 font-weight-bold">
              Detail Voucher
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.detail = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text class="px-0 pb-0">
            <div v-if="process.detail" class="px-5">
              <v-skeleton-loader
                style="width: 100%; height: 200px;"
                :loading="process.run"
                type="image">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="paragraph">
              </v-skeleton-loader>
            </div>
            <div v-if="!process.detail">
              <div class="pb-5" style="padding-left: 25px; padding-right: 25px;">
                <v-img
                  :src="detail.image_landscape_url"
                  :lazy-src="detail.image_landscape_url"
                  width="100%"
                  :height="175"
                  contain
                  gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.4)"
                  class="grey lighten-2"
                  style="border-radius: 7px;"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div class="fs-18 font-weight-bold text-first text--darken-2 mt-7" style="margin-bottom: 19px;">
                  {{ detail.name }}
                </div>
                <div class="fs-14 font-weight-bold text-first mb-2">
                  Masa Berlaku
                </div>
                <div class="fs-16 text-second">
                  Berlaku dari {{ detail.start_datetime | datetimemonth }}, maksimal {{ detail.end_datetime | datetimemonth }}
                </div>
                <div class="fs-14 font-weight-bold text-first mb-2 mt-5">
                  Platform
                </div>
                <div class="fs-16 text-second">
                  <span v-if="detail.platform === 'semua'">
                    Untuk semua platform
                  </span>
                  <span v-if="detail.platform === 'web'">
                    Untuk platform Web
                  </span>
                  <span v-if="detail.platform === 'mobile'">
                    Untuk platform Mobile
                  </span>
                </div>
                <div class="fs-14 font-weight-bold text-first mb-2 mt-5">
                  Produk
                </div>
                <div class="fs-16 text-second">
                  <span v-if="detail.item_type === 'all category'">
                    Untuk semua kategori kelas
                  </span>
                  <span v-if="detail.item_type === 'all course'">
                    Untuk semua kelas
                  </span>
                  <div v-if="detail.item_type === 'course'">
                    <v-card flat
                      v-for="(item, index) in detail.items"
                      :key="index">
                      <div>
                        <v-list flat three-line class="py-0" v-if="detail.items.length > 0" >
                          <v-list-item class="px-0" :to="`/elearning/${item.id}`">

                            <v-list-item-avatar tile size="80" class="mr-3">
                              <v-img 
                                style="border-radius: 10px;"
                                :src="item.img_url">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#4ab762">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                                {{ item.name }} 
                              </v-list-item-subtitle>

                              <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                                <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.discount_percent) > 0">
                                  {{ item.price | price }}
                                </span>
                                <span class="font-weight-bold red--text">
                                  <span v-if="parseInt(item.discount_percent) > 0">
                                  (-{{ parseInt(item.discount_percent) }}%)
                                  </span>
                                  <span class="text-first" v-if="parseInt(item.price) > 0">
                                    {{ item.price_discount | price }}
                                  </span>

                                  <span class="green--text" v-else>
                                    Gratis
                                  </span>
                                </span>
                              </p>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>

                      <v-divider v-if="index < detail.items.length - 1"/>
                    </v-card>
                  </div>
                  <div v-if="detail.item_type === 'category'">
                    <v-card flat
                      v-for="(item, index) in detail.items"
                      :key="index">
                      <div>
                        <v-list flat three-line class="py-0" v-if="detail.items.length > 0">
                          <v-list-item class="px-0">

                            <v-list-item-avatar tile size="80" class="mr-3">
                              <v-img 
                                style="border-radius: 10px;"
                                v-if="item.img_url !== ''"
                                :src="item.img_url">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#4ab762">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <v-img 
                                style="border-radius: 10px;"
                                v-if="item.img_url === ''"
                                :src="require('@/assets/images/content_image_empty_square.png')">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#4ab762">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                                {{ item.name }} 
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>

                      <v-divider v-if="index < (detail.items.length - 1)"/>
                    </v-card>
                  </div>
                </div>
              </div>
              <v-divider style="border-width: 5px;"></v-divider>
              <div class="px-6 py-5">
                <div class="fs-14 font-weight-bold text-first mb-2">
                  Syarat & Ketentuan
                </div>
                <div class="fs-16 text-second">
                  Nikmati potongan sebesar {{ detail.discount_percent }}%  
                  <span v-if="parseInt(detail.discount_max) > 0">
                    dengan maksimum potongan sebesar {{ detail.discount_max | price }}
                  </span>
                  <span v-if="parseInt(detail.discount_max) === 0">
                    tanpa maksimum potongan
                  </span>
                  untuk pesananmu. *Berlaku hingga {{ detail.end_datetime | datetimemonth }}. Dengan <span v-if="parseInt(detail.min_order) > 0">min. belanja {{ detail.min_order | price }}</span> <span v-if="parseInt(detail.min_order) === 0">tidak ada minumal belanja</span>. 
                  <span v-if="parseInt(detail.user_limit) > 0">
                    Berlaku untuk  {{ detail.user_limit}} kali pembelian.
                  </span>

                  <span v-if="parseInt(detail.user_limit) === 0">
                    Tidak ada batas pembelian.
                  </span>
                    
                  <!-- Hanya berlaku untuk transaksi dengan eLearning. -->
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn
              elevation="0"
              color="#4ab762"
              block
              large
              :disabled="process.detail"
              height="40"
              @click="dialog.detail = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center justify-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-3">
                  GUNAKAN VOUCHER
                </div>
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.redeem"
        v-model="dialog.redeem"
        width="710">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl_30 pr_30 py-4' : 'pa-4'">
            <div class="text-second text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
              Redeem Voucher
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.redeem = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text style="min-height: 400px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'py-5 px-8' : 'pa-4'">
            <div class="text-first text--darken-2 fs-16 font-weight-bold">
              Tuliskan kode voucher disini
            </div>
            <div class="text-second d-flex align-center mt-3">
              <v-text-field
                solo
                hide-details
                clearable
                v-model="redeem"
                @click:clear="is_redeem = false; listRedeem = [];"
                color="#4ab762"
                style="width: 100%"
                :placeholder="`Cari voucher…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
              <v-btn
                color="#3498db"
                width="120"
                height="45"
                elevation="0"
                @click="toRedeem()"
                class="text-capitalize white--text ml-6">
                Redeem
              </v-btn>
            </div>

            <div v-if="process.redeem && is_redeem" class="d-flex align-center justify-center" style="height: 250px;">
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="grey"
                ></v-progress-circular>
                <div class="fs-16 ml-2 text-second text--darken-2 mt-2 font-weigth-bold">
                  Loading….
                </div>
              </div>
            </div>

            <div v-if="!process.redeem && is_redeem" class="mt-5">

              <div class="text-first text--darken-2 fs-16 font-weight-bold mb-4">
                Voucher untuk Anda
              </div>

              <div v-if="listRedeem.length > 0">
                <div v-for="(l, s) in listRedeem" :key="s">
                  <v-list class="py-0">
                    <v-list-item class="mb-4" style="background: #f7f7f7cf; border-radius: 15px; padding: 25px 20px 22px 20px;">
                      <v-list-item-content class="py-0">
                        <v-row>
                          <!-- <v-col cols="2" class="pr-0">
                            <v-list style="background: transparent;">
                              <v-list-item class="py-0 px-0">
                                </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col cols="1" class="pl-0">
                              </v-col> -->
                          <v-col cols="12" md="8" class="pl-0 pt-0 pb-0">
                            <v-list style="background: transparent;">
                              <v-list-item class=" pl-3">
                                <v-list-item-avatar
                                  :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 85 : 65"
                                  tile
                                  class="mt-0">
                                  <v-img 
                                    :src="l.image_portrait_url" style="border-radius: 15px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#4ab762">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content class="">
                                  <div class="text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-2'" style="font-weight: 900; line-height: 26px;">
                                    {{ l.name }}
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          <v-col cols="12" md="4" class="d-flex align-end py-0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'justify-end' : ''">
                            <div class="text-second text--darken-2 mb-5" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-16 text-right' : 'body-2'" style="line-height: 19px;">
                              <div>
                                Berlaku hingga 
                                <div class="green--text font-weight-bold fs-16" style="line-height: 19px;">{{ l.voucher_end_datetime }}</div>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <div class="text-first body-1 d-flex align-center">
                              <v-list style="background: transparent;" :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'width: 25%;' : 'width: 35%;'">
                                <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                                  <v-list-item-content class="py-0" style="min-width: 100px;">
                                    <div class="text-second text-right " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-13'">
                                      <div class="text-second" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'caption'" style="line-height: 16px;">
                                        Min. Transaksi
                                      </div>
                                      <div class="fs-18 font-weight-bold" style="line-height: 21px;">
                                        {{ l.voucher_min_transaksi === '' ? '-' : l.voucher_min_transaksi}}
                                      </div>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                              <v-list style="background: transparent;" class="ml-4" :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'width: 20%;' : 'width: 30%;'">
                                <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0">
                                  <v-list-item-content class="py-0">
                                    <div class="text-right color-blue-first" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-13'">
                                      <div class="text-second" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-13' : 'caption'">
                                        Diskon
                                      </div>
                                      <div class="fs-18 font-weight-bold" style="line-height: 21px;">
                                        {{ l.voucher_diskon }}%
                                      </div>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                              <v-list style="background: transparent;" class="ml-4" :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'width: 23%;' : 'width: 35%;'">
                                <v-list-item class="py-0">
                                  <v-list-item-content class="py-0">
                                    <div class="body-1 text-second text-right " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-13' : 'caption'">
                                      <div class="text-second" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-13' : 'caption'" style="line-height: 16px;">
                                        Hingga
                                      </div>
                                      <div class="font-weight-bold" style="line-height: 21px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'fs-13'">
                                        {{ l.discount_max }}
                                      </div>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                              <div class="ml-auto text-right" style="width: 34%;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'display-none'">
                                <v-btn
                                  elevation="0"
                                  width="160"
                                  height="40"
                                  color="#4ab762"
                                  v-if="l.voucher_is_active"
                                  class="text-capitalize ml-3 white--text">
                                  Ambil Voucher
                                </v-btn>
                              </div>
                            </div>
                            <div :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'display-none' : 'mt-3'">
                              <v-btn
                                elevation="0"
                                width="160"
                                small
                                color="#4ab762"
                                v-if="l.voucher_is_active"
                                class="text-capitalize ml-3 white--text">
                                Ambil Voucher
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                        <!-- <div>
                          <div class="text-right">
                            <v-icon size="20" color="red" @click="dialog.cancel = true">mdi-block-helper</v-icon>
                            <v-btn
                              outlined
                              elevation="0"
                              width="180"
                              color="#9e9e9e"
                              class="text-capitalize ml-3">
                              Lihat Detail
                            </v-btn>
                          </div>
                        </div> -->
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>

              <div v-if="listRedeem.length < 1">
                <v-alert
                  text
                  style="padding: 17px 25px; font-weight: 900;"
                  color="warning">
                  <div :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
                    Voucher yang Anda cari tidak tersedia atau tidak ditemukan.
                  </div>
                </v-alert>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-7">
          <v-col cols="9" class="pt-0">
            <!-- <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div> -->
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb_30'">
          <div class="text-second d-flex align-center" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 60%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari voucher…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <!-- <v-btn
              color="#3498db"
              elevation="0"
              height="45"
              width="120"
              @click="is_redeem = false; dialog.redeem = true"
              class="text-capitalize white--text ml-6">
              Redeem
            </v-btn> -->
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data voucher
          </div>
        </div>
        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0 px-0">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-skeleton-loader
                style="width: 100px; height: 40px;"
                :loading="process.run"
                type="image">
              </v-skeleton-loader> -->
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="button">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list class="py-0">
              <v-list-item class="mb-4 hover_select" style="background: #f9f9f9; border-radius: 15px; padding: 20px 20px; border: 1px solid #f9f9f9;">
                <v-list-item-content class="py-0">
                  <v-row>
                    <!-- <v-col cols="2" class="pr-0">
                      <v-list style="background: transparent;">
                        <v-list-item class="py-0 px-0">
                          <v-list-item-avatar
                            size="120"
                            tile
                            class="mt-0">
                            <v-img 
                              :src="l.image_portrait_url" style="border-radius: 15px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-col> -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6" class="pl-0">
                          <v-list style="background: transparent;">
                            <v-list-item class="pr-0">
                              <v-list-item-avatar
                                size="148"
                                tile
                                class="mt-0 mr-4">
                                <v-img 
                                  :src="l.image_portrait_url" style="border-radius: 15px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div class="font-weight-bold text-first text--darken-2" style="line-height: 26px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
                                  {{ l.name }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col cols="12" md="6" class="">
                          <div class="text-second text--darken-2 mb-5" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-16 text-right' : 'body-2'">
                            <div v-if="new Date() >= new Date(l.start_datetime) && new Date() <= new Date(l.end_datetime)">
                              Berlaku hingga <span class="green--text font-weight-bold">{{ l.end_datetime | datetimemonth }}</span>
                            </div>
                            <div v-if="new Date() < new Date(l.start_datetime)">
                              Dapat digunakan pada <span class="warning--text font-weight-bold">{{ l.start_datetime | datetimemonth }}</span>
                            </div>
                            <div v-if="new Date() > new Date(l.end_datetime)">
                              Berakhir (Telah Expired)
                            </div>
                          </div>
                          <div class="text-first d-flex align-center" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1 justify-end' : 'body-2'">
                            <v-list style="background: transparent;">
                              <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0 pl-0">
                                <v-list-item-content class="py-0" style="min-width: 100px;">
                                  <div class="body-2 body-1 text-second text-right text--darken-2 ">
                                    <div class="fs-14 text-second text--darken-2 mb-1">
                                      Min. Transaksi
                                    </div>
                                    <div class="font-weight-bold">
                                      <span v-if="parseInt(l.min_order) !== 0">
                                        {{ l.min_order | price }}
                                      </span>
                                      <span v-if="parseInt(l.min_order) === 0">
                                        -
                                      </span>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-list style="background: transparent;" class="ml-4">
                              <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0">
                                <v-list-item-content class="py-0">
                                  <div class="body-2 body-1 text-right color-blue-first">
                                    <div class="fs-14 text-second text--darken-2 mb-1">
                                      Diskon
                                    </div>
                                    <div class="font-weight-bold">
                                      {{ l.discount_percent }}%
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-list style="background: transparent;" class="ml-4">
                              <v-list-item class="py-0 pr-0">
                                <v-list-item-content class="py-0">
                                  <div class="body-2 body-1 text-second text-right text--darken-2 ">
                                    <div class="fs-14 text-second text--darken-2 mb-1">
                                      Hingga
                                    </div>
                                    <div class="text-first font-weight-bold fs-17">
                                      <span v-if="!l.no_max_discount_bool">
                                        {{ l.discount_max | price }}
                                      </span>
                                      <span v-if="l.no_max_discount_bool">
                                        -
                                      </span>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                          
                          <div class="d-flex align-center justify-end mt_20" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
                            <v-btn
                              outlined
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#9e9e9e"
                              height="40"
                              :loading="selected.detail === s && process.detail"
                              @click="selected.detail = s; fetchDetail(l.id); dialog.detail = true;"
                              class="text-capitalize">
                              Informasi Voucher
                            </v-btn>
                            <v-btn
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              height="40"
                              to="/elearning"
                              :disabled="process.detail"
                              v-if="new Date() >= new Date(l.start_datetime) && new Date() <= new Date(l.end_datetime)"
                              class="text-capitalize white--text ml-4">
                              Gunakan Voucher
                            </v-btn>
                            <div :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-16' : 'body-2'">
                              <div class="warning--text ml-8" v-if="new Date() < new Date(l.start_datetime)">
                                <i>Belum dapat digunakan</i>
                              </div>
                              <div class="red--text ml-8" v-if="new Date() > new Date(l.end_datetime)">
                                <i>Tidak dapat digunakan</i>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- <div>
                    <div class="text-right">
                      <v-icon size="20" color="red" @click="dialog.cancel = true">mdi-block-helper</v-icon>
                      <v-btn
                        outlined
                        elevation="0"
                        width="180"
                        color="#9e9e9e"
                        class="text-capitalize ml-3">
                        Lihat Detail
                      </v-btn>
                    </div>
                  </div> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          sort: 'Paling baru'
        },
        dialog: {
          redeem: false,
          info: false,
          detail: false
        },
        filter: {
          title: "",
          search: ""
        },
        selected: {
          detail: ""
        },
        contentEmpty: "",
        detail: {},
        list: [],
        pagination: {},
        listRedeem: [],
        redeem: "",
        is_redeem: false,
        process: {
          redeem: false,
          detail: false,
          run: true
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/voucher',
          title: 'Voucher Saya',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    watch: {
    },
    computed: {
      reminder () {
        let now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate())
        return next.toISOString().substr(0, 10)
      },
      reminder_time () {
        let today = new Date()
        return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      },
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search
        }
        this.$axios.$get(`api/coupon`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            if (this.filter.search !== '' || this.filter.search !== null) {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail(item) {
        this.process.detail = true
        let params = {
          id: item
        }
        this.$axios.$get(`api/coupon/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      // toRedeem () {
      //   this.process.redeem = true
      //   this.is_redeem = true
      //   setTimeout(() => {
      //     this.process.redeem = false
      //     if (this.redeem === '001') {
      //       this.listRedeem = []
      //     } else {
      //       this.listRedeem = [
      //         {
      //           name: "Potongan diskon eLearning hingga Rp25.000 tanpa minimal transaksi",
      //           voucher_id: "1",
      //           image_portrait_url: "https://picsum.photos/500/300?image=25",
      //           voucher_harga: "Rp150.000",
      //           voucher_harga_after_diskon: "Rp75.000",
      //           voucher_harga_diskon: "50",
      //           voucher_diskon_value: "50",
      //           voucher_count_rating: "5,0",
      //           voucher_desc: "Use React Native and your React knowledge to build native iOS and Android Apps - incl.",
      //           voucher_create_datetime: "7 nov 2021, 23:38",
      //           voucher_end_datetime: '10 Nov 2022, 23:38',
      //           voucher_is_collection: '0',
      //           voucher_min_transaksi: 'Rp250.000',
      //           voucher_diskon: 10,
      //           discount_max: 'Rp5.000',
      //           voucher_is_active: true,
      //           voucher_start_datetime: 0,
      //           voucher_is_expired: '0',
      //           voucher_is_publish: '1'
      //         }
      //       ]
      //     }
      //   }, 2000);
      // },
    }
  }
</script>

