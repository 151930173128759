<template>
  <div>
    <v-card-text class="pa-0">
      <v-row justify="center" class="align-center">
        <v-col cols="3">
          <v-btn
            :rounded="fps === 'lg' || fps === 'md' || fps === 'xl' ? false : true"
            :small="fps === 'lg' || fps === 'md' || fps === 'xl' ? false : true"
            :fab="fps === 'lg' || fps === 'md' || fps === 'xl' ? false : true"
            :width="fps === 'lg' || fps === 'md' || fps === 'xl' ? 170 : 'auto'"
            elevation="0"
            color="grey darken-3"
            class="text-capitalize rounded-xl px-5 white--text"
            :disabled="pagination.current < 2"
            @click="selected.pagination = selected.pagination-1; changePage(selected.pagination)">
            <div class="d-flex align-center" style="width: 100%">
              <v-icon>mdi-arrow-left</v-icon>
              <div class="ml-auto" v-if="fps === 'lg' || fps === 'md' || fps === 'xl'">
                Sebelumnya
              </div>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-container class="max-width">
            <v-pagination
              v-model="selected.pagination"
              @input="changePage(selected.pagination)"
              circle
              color="#505050"
              class="non-arrow-pagination elevation-0"
              :length="pagination.total_page"
              :total-visible="6"
            ></v-pagination>
          </v-container>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            :rounded="fps === 'lg' || fps === 'md' || fps === 'xl' ? false : true"
            :small="fps === 'lg' || fps === 'md' || fps === 'xl' ? false : true"
            :fab="fps === 'lg' || fps === 'md' || fps === 'xl' ? false : true"
            :width="fps === 'lg' || fps === 'md' || fps === 'xl' ? 170 : 'auto'"
            elevation="0"
            color="grey darken-3"
            class="text-capitalize rounded-xl white--text px-5"
            :disabled="pagination.current >= pagination.total_page"
            @click="selected.pagination = selected.pagination+1; changePage(selected.pagination)">
            <div class="d-flex align-center" style="width: 100%">
              <div v-if="fps === 'lg' || fps === 'md' || fps === 'xl'">
                Selanjutnya
              </div>
              <v-icon class="ml-auto">mdi-arrow-right</v-icon>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
  // import { get, post, put, destroy } from "../service/Axios";
  export default {
    name: 'pagination-data',
    data: () => ({
      selected: {
        pagination: 1
      },
    }),
    computed: {
      pagination () {
        this.selected.pagination = this.$attrs.model.current
        let total = this.$attrs.model.total_page
        for(let i = 1; i <= total; i++) {
          this.$attrs.model.detail.push(i)
        }
        return this.$attrs.model
      },
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    methods: {
      changePage(numberPage) {
        this.selected.pagination = numberPage
        this.$emit('changePage', numberPage)
      }
    }
  }
</script>

<style>
</style>
<style>
   .pagi-height .v-input__control .v-input__slot {
     min-height: 34px !important;
   }
   .pagi-height .v-input__control .v-input__slot  .v-input__append-inner {
     margin-top: 5px !important;
   }

</style>