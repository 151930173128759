<template>
  <div>
    <DialogAuth :dialogauth="toLogout" @close="toLogout = ''" />
    <v-container>

      <section>
        <ValidationObserver ref="observer">
          <v-row>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
              <div class="d-flex align-center text-second">
                <div>
                  Nama Toko
                </div>
              </div>
              <ValidationProvider name="Nama" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :counter="50"
                  placeholder="Tuliskan disini"
                  v-on:keyup.enter="updateProfile()"
                  v-model="form.publisher_name"
                  :messages="'Wajib'"
                  :error-messages="errors"
                  require>
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>
              <div class="mt-6 mb-7">
                <div class="d-flex">
                  <div style="width: 165px;">
                    <v-img
                      :src="form.publisher_img_url"
                      :lazy-src="form.publisher_img_url"
                      v-if="form.publisher_img_url !== ''"
                      :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 165 : 140"
                      :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 165 : 140"
                      aspect-ratio="1"
                      style="border-radius: 15px"
                      class="grey lighten-2">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template> -->
                      <v-progress-linear
                        color="#4ab762"
                        rounded
                        indeterminate
                        height="6"
                        v-if="process.avatar"
                        class="mx-auto"
                        style="width: 60%; margin-top: 50%;">
                      </v-progress-linear>
                    </v-img>
                    <div v-if="form.publisher_img_url === ''">
                      <div style="background-color: #a4a4a46e; color: #fff; width: 160px; height: 160px; border-radius: 15px;" class="ml-2" :style="process.avatar ? 'padding-top: 90px' : ''">
                        <v-row
                          class="fill-height ma-0"
                          v-if="!process.avatar"
                          :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h3' : ''"
                          align="center"
                          justify="center"
                        >
                          {{ nama_image }}
                        </v-row>
                        <v-progress-linear
                          color="#4ab762"
                          rounded
                          indeterminate
                          height="6"
                          v-if="process.avatar"
                          class="mx-auto"
                          style="width: 60%;">
                        </v-progress-linear>
                      </div>
                    </div>
                  </div>
                  <div class="ml-auto pl-5">
                    <image-input v-model="avatar" style="height: 48px; width: 100%;">
                      <div slot="activator">
                        <v-btn
                          outlined
                          :disabled="process.avatar"
                          block
                          :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 40 : 40"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          color="#5050505e"
                          class="text-capitalize">
                          Pilih Foto
                        </v-btn>
                      </div>
                    </image-input>


                    <div class="mt-1 text-second text-darken-1" style="font-size: 11px;">
                      Besar file: maksimum 10.000.000 bytes
                      (10 Megabytes). Ekstensi file yang
                      diperbolehkan: .JPG .JPEG .PNG.
                      Dengan dimensi terbaik square (contoh:
                      256px x 256px)
                    </div>
                    <p
                      style="font-size: 9px;"
                      class="mt-0 mb-0 red--text pr-2"
                      v-show="error.publisher_img_url !== ''">
                      {{ error.publisher_img_url }}
                    </p>
                    <p
                      style="font-size: 9px;"
                      class="mt-0 mb-0 red--text pr-2"
                      v-show="error.publisher_img_url === ''">
                      Wajib
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex align-center text-second">
                <div>
                  Deskripsi Singkat
                </div>
              </div>
              <ValidationProvider name="Deskripsi Singkat" rules="required|max:500|min:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :counter="500"
                  :messages="'Wajib'"
                  v-model="form.description"
                  v-on:keyup.enter="updateProfile()"
                  :error-messages="errors"
                  require
                  placeholder="Tuliskan disini">
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>
              <div class="d-flex align-center text-second mt-3">
                <div>
                  Tentang Toko Saya
                </div>
              </div>
              <ValidationProvider name="Tentang Toko Saya" rules="required|max:1000|min:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :messages="'Wajib'"
                  :counter="1000"
                  v-model="form.about"
                  v-on:keyup.enter="updateProfile()"
                  :error-messages="errors"
                  require
                  placeholder="Tuliskan disini">
                  <template v-slot:message="{ message }">
                    <span class="red--text" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
              <div class="d-flex align-center text-second">
                <div>
                  Alamat Website
                </div>
              </div>
              <ValidationProvider name="Alamat Website" rules="url|max:100" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :messages="'Optional'"
                  :counter="100"
                  placeholder="Tuliskan disini"
                  v-on:keyup.enter="updateProfile()"
                  v-model="form.url_web"
                  :error-messages="errors"
                  require>
                  <template v-slot:message="{ message }">
                    <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
              </ValidationProvider>
              <div class="d-flex align-center text-second mt-5">
                <div>
                  Youtube
                </div>
              </div>
              <ValidationProvider name="Youtube" rules="url|max:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :messages="'Optional'"
                  :counter="50"
                  placeholder="Tuliskan disini"
                  :error-messages="errors"
                  v-on:keyup.enter="updateProfile()"
                  v-model="form.url_youtube"
                  require>
                  <template v-slot:message="{ message }">
                    <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
                  <!-- prefix="https://www.youtube.com/" -->
              </ValidationProvider>
              <div class="d-flex align-center text-second mt-5">
                <div>
                  Facebook
                </div>
              </div>
              <ValidationProvider name="Facebook" rules="url|max:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :messages="'Optional'"
                  :counter="50"
                  placeholder="Tuliskan disini"
                  :error-messages="errors"
                  v-on:keyup.enter="updateProfile()"
                  v-model="form.url_facebook"
                  require>
                  <template v-slot:message="{ message }">
                    <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
                  <!-- prefix="https://www.facebook.com/" -->
              </ValidationProvider>
              <div class="d-flex align-center text-second mt-5">
                <div>
                  Instagram
                </div>
              </div>
              <ValidationProvider name="Instagram" rules="url|max:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :messages="'Optional'"
                  :counter="50"
                  placeholder="Tuliskan disini"
                  :error-messages="errors"
                  v-on:keyup.enter="updateProfile()"
                  v-model="form.url_instagram"
                  require>
                  <template v-slot:message="{ message }">
                    <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
                  <!-- prefix="https://www.instagram.com/" -->
              </ValidationProvider>
              <div class="d-flex align-center text-second mt-3">
                <div>
                  Twitter
                </div>
              </div>
              <ValidationProvider name="Twitter" rules="url|max:50" v-slot="{ errors }">
                <v-text-field
                  color="#4ab762"
                  class="pt-0"
                  :messages="'Optional'"
                  :counter="50"
                  placeholder="Tuliskan disini"
                  :error-messages="errors"
                  v-on:keyup.enter="updateProfile()"
                  v-model="form.url_twitter"
                  require>
                  <template v-slot:message="{ message }">
                    <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                  </template>
                  <template v-slot:counter="{ props }">
                    <span style="font-size: 9px;" class="text-second">
                      {{ props.value }} dari {{ props.max }} karakter
                    </span>
                  </template>
                </v-text-field>
                  <!-- prefix="https://twitter.com/" -->
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6" class="pl-0 text-right d-flex align-end">
              <v-btn
                elevation="0"
                width="160"
                :disabled="process.form || process.avatar"
                :loading="process.form"
                @click="updateProfile()"
                height="40"
                :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                color="#4ab762"
                class="text-capitalize ml-3 white--text">
                <span v-if="form.publisher_name === ''">Simpan</span>
                <span v-if="form.publisher_name !== ''">Ubah</span>
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </section>

    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          code: false,
          metode: false
        },
        toLogout: '',
        panel: 0,
        menu: ['Informasi Toko', 'Rekening Bank', 'Organisasi'],
        selected: {
          menu: 0
        },
        snackbar: true,
        timeout: 2000,
        avatar: null,
        form: {},
        nama_image: "",
        process: {
          run: false,
          avatar: false,
          form: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          publisher_img_url: ""
        },
        content: {
          url: '/account/setting',
          title: 'Pengaturan',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      ImageInput: () => import('@/components/ImageInput')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      detailProfile: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'detailProfile': function(val) {
        this.form = val
      },

      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.publisher_img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.publisher_img_url = ''
          }
        },
        deep: true
      }
    },
    mounted () {
      this.form = this.$props.detailProfile
      this.getKarekter()
    },
    methods: {
      getKarekter() {
        let name = this.$store.state.member.auth.user.name
        let splitName = name.split(" ")
        let initial = name.charAt(0);
        // let second = "";
        if(splitName[1] !== undefined) {
          initial += splitName[1].charAt(0)
        }

        // let result = first+second
        this.nama_image = initial.toUpperCase()
        // console.log(initial.toUpperCase())
        // return initial.toUpperCase()
      },
      validation() {
        var error = this.error,
          valid = true;

        if (this.form.publisher_img_url.length < 1) {
          error.publisher_img_url = "Gambar tidak boleh kosong";
          valid = false;
        } else {
          error.publisher_img_url = "";
        }

        return valid;
      },
      async updateProfile(){
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        const valids = this.validation()
        if (isValid && valids) {
          this.process.form = true
          this.$emit('process', this.process.form )
          this.$axios.$post(`api/publisher/profile/update`, {
            publisher_name: this.form.publisher_name,
            publisher_img_url: this.form.publisher_img_url,
            description: this.form.description,
            about:this.form.about,
            url_web: this.form.url_web,
            url_youtube: this.form.url_youtube,
            url_twitter: this.form.url_twitter,
            url_facebook: this.form.url_facebook,
            url_instagram: this.form.url_instagram
          }).then(response => {
            let res = response
            if (res.status === 200) {
              this.process.form = false
              this.$emit('process', this.process.form )
              this.$refs.observer.reset();
              this.$emit('fetch')
            } else {
              this.error.message = res.message
              this.process.form = false
            }
          })
        }
      },
    },
  };
</script>