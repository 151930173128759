<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.cancel"
        v-model="dialog.cancel"
        width="400">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="title text-second text--darken-2 font-weight-bold">
              Hapus {{ deletes.title }}
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.cancel"
                class="text-capitalize px-0"
                @click="dialog.cancel = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            {{ deletes.text }}
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="#4ab762"
                  block
                  :disabled="process.cancel"
                  :loading="process.cancel"
                  @click="toDeletes()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, Hapus
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  :disabled="process.cancel"
                  block
                  @click="dialog.cancel = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="#4ab762"
              width="220"
              @click="dialog.cancel = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Ya, Hapus
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              width="120"
              @click="dialog.cancel = false"
              class="text-capitalize white--text ml-2">
              Tidak
            </v-btn>
            <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.form"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.form"
        width="650">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <span v-if="form.id === ''">Tambah</span><span v-if="form.id !== ''">Ubah</span> Etalase
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form || process.avatar"
                class="text-capitalize px-0"
                @click="dialog.form = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <div class="text-second body-2 px-5">
            Nama etalase yang sesuai kategori Elearning lebih mudah dicari pembeli
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text style="min-height: calc(100vh - 300px);" class="mt-1">
            <ValidationObserver ref="observer">
              <v-form
                class="mb-0 mt-5">
                <div class="d-flex align-center text-first body-2 font-weight-bold">
                  <p>
                    Nama Etalase
                  </p>
                </div>
                <ValidationProvider name="Nama" rules="required" v-slot="{ errors }">
                  <v-text-field
                    solo
                    color="#4ab762"
                    placeholder="Tuliskan disini"
                    :disabled="process.form"
                    prepend-inner-icon="mdi-view-grid-plus"
                    v-model="form.name"
                    v-on:keyup.enter="save()"
                    :error-messages="errors"
                    required>
                  </v-text-field>
                </ValidationProvider>
                <div class="mt-1 mb-7">
                  <div class="d-flex">
                    <div>
                      <v-img
                        :src="form.img_url"
                        :lazy-src="form.img_url"
                        v-if="form.img_url !== ''"
                        :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                        :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                        aspect-ratio="1"
                        style="border-radius: 15px;"
                        class="grey lighten-2">
                        <!-- <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template> -->
                        <v-progress-linear
                          color="#4ab762"
                          rounded
                          indeterminate
                          height="6"
                          v-if="process.avatar"
                          class="mx-auto"
                          style="width: 60%; margin-top: 50%;">
                        </v-progress-linear>
                      </v-img>
                      <div v-if="form.img_url === ''">
                        <div style="background-color: #ededed; color: #fff; width: 160px; height: 160px;" class="rounded-lg ml-2" :style="process.avatar ? 'padding-top: 90px' : ''">
                          <v-row
                            class="fill-height ma-0"
                            v-if="!process.avatar"
                            :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h3' : ''"
                            align="center"
                            justify="center"
                          >
                            <v-icon large>mdi-camera</v-icon>
                          </v-row>
                          <v-progress-linear
                            color="#4ab762"
                            rounded
                            indeterminate
                            height="6"
                            v-if="process.avatar"
                            class="mx-auto"
                            style="width: 60%;">
                          </v-progress-linear>
                        </div>
                      </div>
                    </div>
                    <div class="pl-8" :style=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 100%;' : 'width: 100%;'">
                      <image-input v-model="avatar" style="height: 48px; width: 100%;">
                        <div slot="activator">
                          <v-btn
                            outlined
                            :disabled="process.avatar"
                            height="45"
                            width="200"
                            :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                            color="#5050505e"
                            class="text-capitalize">
                            Pilih Foto
                          </v-btn>
                        </div>
                      </image-input>


                      <div class="mt-3 text-second text-darken-1" style="font-size: 11px;">
                        Besar file: maksimum 10.000.000 bytes
                        (10 Megabytes). Ekstensi file yang
                        diperbolehkan: .JPG .JPEG .PNG.
                        Dengan dimensi terbaik square (contoh:
                        256px x 256px)
                      </div>
                      <p
                        style="font-size: 9px;"
                        class="mt-1 mb-0 red--text pr-2"
                        v-show="error.img_url !== ''">
                        {{ error.img_url }}
                      </p>
                      <p
                        style="font-size: 9px;"
                        class="mt-1 mb-0 red--text pr-2"
                        v-show="error.img_url === ''">
                        Wajib
                      </p>
                    </div>
                  </div>
                </div>
              </v-form>
            </ValidationObserver>
            <div>
              <v-divider></v-divider>
              <div class="d-flex my-3 align-center">
                <div class="text-first body-2 font-weight-bold">
                  List Elearning Pada Etalase Ini <span v-if="form.name !== ''">({{ form.product.length }})</span>
                </div>
                <div class="ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '85%' : ''">
                  <div v-if="form.name === ''">
                    <v-btn
                      text
                      color="#3498db"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      @click="form.id !== '' ? fetchClassNoneGroup() : fetchClass(); dialog.product = true;"
                      :disabled="form.name === ''"
                      v-if="form.product.length < 1"
                      class="text-capitalize pr-0">
                      Pilih Elearning
                    </v-btn>
                  </div>
                  <div v-if="form.name !== ''">
                    <v-btn
                      text
                      color="#3498db"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      @click="form.id !== '' ? fetchClassNoneGroup() : fetchClass(); dialog.product = true;"
                      :disabled="form.name === ''"
                      v-if="form.product.length < 1"
                      class="text-capitalize pr-0">
                      Pilih Elearning
                    </v-btn>
                    <v-btn
                      text
                      color="#3498db"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      @click="form.id !== '' ? fetchClassNoneGroup() : fetchClass(); dialog.product = true;"
                      :disabled="form.name === ''"
                      v-if="form.product.length > 0"
                      class="text-capitalize">
                      Tambah Elearning
                    </v-btn>
                    <v-btn
                      text
                      color="red"
                      :small="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
                      :disabled="form.name === ''"
                      @click="form.product = []"
                      :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'pl-0' : ''"
                      v-if="form.product.length > 0"
                      class="text-capitalize pr-0">
                      Hapus Semua
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="form.name !== ''">
              <v-card flat
                v-for="(item, index) in form.product"
                :key="index">
                <div>
                  <v-list flat three-line class="py-0" v-if="form.product.length > 0">
                    <v-list-item class="px-0">

                      <v-list-item-avatar tile size="80" class="mr-3">
                        <v-img 
                          style="border-radius: 10px;"
                          :src="item.img_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                          {{ item.name }} 
                        </v-list-item-subtitle>

                        <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.discount_percent) > 0">
                            {{ item.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span v-if="parseInt(item.discount_percent) > 0">
                            (-{{ parseInt(item.discount_percent) }}%)
                            </span>
                            <span class="text-first" v-if="parseInt(item.price) > 0">
                              {{ item.price_discount | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </p>
                      </v-list-item-content>

                      <v-list-item-content class="align-center text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 120px;' : 'max-width: 120px;'">
                        <v-list-item-subtitle class="mt-1">
                          <div>
                            <v-icon color="red" class="ml-2" @click="deleteProduct(item)">mdi-block-helper</v-icon>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <v-divider v-if="index < form.product.length - 1"/>
              </v-card>
            </div>
            <v-alert
              text
              v-if="form.name === ''"
              class="body-2 my-2"
              color="warning">
              Anda harus menuliskan nama etalase terlebih dahulu untuk menambahkan Elearning.
            </v-alert>
            <div v-if="form.name !== ''">
              <div class="body-2 text-second" v-if="form.product.length < 1">
                Anda juga dapat menambahkan Elearning pada etalase ini nanti.
              </div>
            </div>
            <v-alert 
              type="error" 
              text
              dense
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <!-- <v-btn
              elevation="0"
              color="grey darken-4"
              outlined
              width="120"
              @click="dialog.metode = true"
              class="text-capitalize white--text mr-2">
              Cara Lain
            </v-btn> -->
            <v-btn
              elevation="0"
              color="#4ab762"
              width="200"
              :disabled="process.form || process.avatar"
              :loading="process.form"
              @click="save()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  <span v-if="form.id === ''">Tambah</span><span v-if="form.id !== ''">Simpan</span> Etalase
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.form || process.avatar"
              width="120"
              @click="dialog.form = false"
              class="text-capitalize white--text mr-2">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.product"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.product"
        width="650">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Pilih Elearning
              </div>
              <div class="body-2">
                Pilih Elearning dari list Elearning Anda untuk ditambahkan ke etalase.
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.product = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-5 pt-2 pb-7'">
            <div class="text-second" style="width: 60%">
              <v-text-field
                solo
                hide-details
                clearable
                color="#4ab762"
                v-model="filter.title"
                v-on:keyup.enter="fetchClass()"
                @click:clear="filter.title = ''; fetchClass();"
                :placeholder="`Cari Elearning…`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
              <v-select
                  :items="[
                    'Belum bisa di gunakan'
                  ]"
                  solo
                  disabled
                  color="#4ab762"
                  item-color="green"
                  hide-details
                  v-model="filter.category"
                  class=""
                  placeholder="Kategori(Belum Bisa)"
                ></v-select>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);">

            <div></div>
            <v-list flat dense class="" v-if="!process.kelas && kelas.length > 0">
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <div v-if="!allEtalase">
                    <v-icon color="grey" @click="form.id !== '' ? checkAll() : checkAllCreate()" v-if="form.id === '' ? selected.product.length !== allEtalaseLength : selected.product.length < 1 || selected.product.length !== kelas.length">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon color="#4ab762" @click="form.id !== '' ? checkAll() : checkAllCreate()" v-if="form.id === '' ? selected.product.length === allEtalaseLength : selected.product.length > 0 && selected.product.length === kelas.length">
                      mdi-checkbox-marked
                    </v-icon>
                  </div>
                  <div v-if="allEtalase">
                    <v-icon color="grey">
                      mdi-close-box
                    </v-icon>
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                    Pilih Semua
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="allEtalase" style="min-width: 320px;">
                  <div class="red--text body-1">
                    Semua Kelas telah terdapat dalam etalase
                  </div>
                </v-list-item-content>

              </v-list-item>
            </v-list>

            <v-divider/>
            <v-card flat
              v-for="(item, index) in process.kelas ? 8 : kelas"
              :key="index">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.kelas"
                type="list-item-avatar-three-line">
                <div>
                  <v-list flat three-line class="py-0" v-if="!process.kelas && kelas.length > 0">
                    <v-list-item class="px-0">
                      <v-list-item-icon @click="item.sys_kelas_cart_is_checked = item.sys_kelas_cart_is_checked ? false : true;">
                         <v-checkbox
                          v-model="selected.product"
                          :off-icon="form.id === '' && item.group_name !== '' ? 'mdi-close-box' : 'mdi-checkbox-blank-outline'"
                          :color="form.id === '' && item.group_name !== '' ? 'grey' : '#4ab762'"
                          class="mt-0"
                          :disabled="form.id === '' && item.group_name !== ''"
                          v-on:change="error.message = ''"
                          :value="item">
                        </v-checkbox>
                        <!-- <v-icon color="#4ab762" v-if="item.sys_kelas_cart_is_checked">mdi-checkbox-marked</v-icon>
                        <v-icon color="grey" v-if="!item.sys_kelas_cart_is_checked">mdi-checkbox-blank-outline</v-icon> -->
                      </v-list-item-icon>

                      <v-list-item-avatar tile size="80" class="mr-3" :style="form.id === '' && item.group_name !== '' ? 'opacity: 0.3;' : ''">
                        <v-img 
                          style="border-radius: 10px;"
                          :src="item.img_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content :style="form.id === '' && item.group_name !== '' ? 'opacity: 0.3;' : ''">
                        <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                          {{ item.name }} 
                        </v-list-item-subtitle>

                        <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.discount_percent) > 0">
                            {{ item.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span v-if="parseInt(item.discount_percent) > 0">
                             (-{{ parseInt(item.discount_percent) }}%)
                            </span>
                            <span class="text-first" v-if="parseInt(item.price) > 0">
                              {{ item.price_discount | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </p>
                        <div class="text-first body-2" v-if="form.id === ''">
                          <span v-if="item.group_name !== ''" class="primary--text">Terdapat di Etalase {{ item.group_name }}</span>
                          <span v-if="item.group_name === ''">Belum terdapat di etalase manapun</span>
                        </div>
                        <div class="text-first body-2" v-if="form.id !== ''">
                          <span>Belum terdapat di etalase manapun</span>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                        <!-- <v-list-item-subtitle class="mt-1">
                          <div>
                            <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                          </div>
                        </v-list-item-subtitle> -->
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <v-divider v-if="index < kelas.length - 1"/>

              </v-skeleton-loader>
            </v-card>
            <div class="text-right mt-16" v-if="!process.kelas && kelas.length < 1">
              <Empty v-model="contentEmptyProduct"/>
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <!-- <v-btn
              elevation="0"
              color="grey darken-4"
              outlined
              width="120"
              @click="dialog.metode = true"
              class="text-capitalize white--text mr-2">
              Cara Lain
            </v-btn> -->
            <v-btn
              elevation="0"
              color="#4ab762"
              width="200"
              :disabled="process.form || process.kelas || selected.product.length < 1"
              :loading="process.form"
              @click="addClassToEtalase()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <div class="ml-auto">
                  Tambahkan ke Etalase
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.form"
              width="120"
              @click="dialog.product = false"
              class="text-capitalize white--text mr-2">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-2">
          <v-col :cols="9" class="pt-0">
            <div class="d-flex align-center" style="height: 100%;">
              <div>
                <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
                  <span>Etalase Elearning</span>
                </div>
                <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
                  <span>Kelompokkan elearningmu ke dalam etalase agar semakin mudah ditemukan pembeli</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="43" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
      </section>

      <section>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb_30'">
          <div class="text-second d-flex align-center" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 60%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari etalase…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-btn
              color="#3498db"
              elevation="0"
              height="45"
              width="120"
              @click="reset(); dialog.form = true"
              class="text-capitalize white--text ml-6">
              Tambah
            </v-btn>
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data etalase
          </div>
        </div>

        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0">
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      class="mx-auto"
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        class="mx-auto"
                        :loading="process.run"
                        type="list-item">
                      </v-skeleton-loader>
                      <v-skeleton-loader
                        style="width: 100px; height: 40px;"
                        :loading="process.run"
                        type="image">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <v-skeleton-loader
                class="mx-auto"
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="button">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-card-text class="pa-3">
            <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
              <v-row class="mb-7 border_radius_7 hover_select" style="background: #f9f9f9; border: 1px solid #ebebeb" :style="fps === 'xs' || fps === 'sm' ? 'padding: 19px 20px 18px 23px' : 'padding: 19px 20px 18px 23px'">
                <v-col cols="12" md="5" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0 py-0' : 'pt-0'">
                  <v-list three-line style="background: transparent;" class="py-0">
                    <v-list-item class="py-0 pl-0">
                      <v-list-item-avatar
                        size="98"
                        tile
                        class="my-0">
                        <v-img 
                          :src="l.img_url" style="border-radius: 15px;"
                          v-if="l.img_url !== ''">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-icon v-if="l.img_url === ''" size="100">mdi-storefront</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pb-0 pt-1">
                        <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                          {{ l.name }}
                        </v-list-item-subtitle>
                        <div class="text-first body-1 font-weight-bold mt-n8">
                          <v-list style="background: transparent; width: 140px; height: 55px;">
                            <v-list-item  style="border-right: 1px solid #ebebeb; min-height: 50px;" class="py-0 pl-0">
                              <v-list-item-content class="py-0">
                                <div class="font-weight-bold body-1 text-second text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                  <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                    Jumlah Elearning
                                  </div>
                                  {{ l.item_count }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'my-0 pr-0 py-0' : 'pt-0'">
                  <div class="text-right text-second">
                    Ditambahkan pada <span class="font-weight-bold text-first">{{ l.created_datetime | datetimemonth }}</span>
                  </div>
                  <div class="text-right ml-auto mt-5">
                    <v-btn
                      icon
                      :disabled="process.detail"
                      @click="deletes.title = 'Etalase'; deletes.text = `Apakah anda yakin ingin menghapus etalase ${detail.name} ini?`; detail = l;  dialog.cancel = true"
                      small>
                      <v-icon size="20" color="red">mdi-block-helper</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      elevation="0"
                      height="40"
                      :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 150 : 130"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      style="background: #fff;"
                      color="#9e9e9e"
                      :disabled="process.detail"
                      :loading="selected.etalase === l.id && process.detail"
                      @click="fetchDetail(l);"
                      class="text-capitalize ml-3">
                      Ubah Etalase
                    </v-btn>
                    <v-btn
                      elevation="0"
                      outlined
                      height="40"
                      :disabled="process.detail"
                      style="background: #ffffff;"
                      :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 150 : 130"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      color="#4ab762"
                      :to="`/account/etalase/${l.id}`"
                      class="text-capitalize ml-4">
                      Atur Etalase
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>


          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        page: 1,
        dialog: {
          cancel: false,
          form: false,
          product: false
        },
        allEtalase: false,
        avatar: null,
        panel: 0,
        allEtalaseLength: 0,
        detail_items: [],
        selected: {
          etalase: '',
          product: [],
          page: 'list',
          detail: '',
          item: ""
        },
        filter: {
          title: "",
          category: "Disemua kategori",
          search: ""
        },
        deletes: {
          title: "",
          text: ""
        },
        form: {
          id: "",
          name: "",
          product: [],
          img_url: ""
        },
        snackbar: false,
        timeout: 2000,
        contentEmpty: "",
        contentEmptyProduct: "",
        detail: {},
        list: [],
        pagination: [],
        kelas: [],
        process: {
          form: false,
          run: true,
          kelas: false,
          detail: false,
          avatar: false,
          product: false,
          cancel: false
        },
        success: {
          message: ""
        },
        error: {
          message: "",
          img_url: ""
        },
        // SEO
        content: {
          url: '/account/etalase',
          title: 'Etalase',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.img_url = ''
          }
        },
        deep: true
      }
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search
        }
        this.$axios.$get(`api/publisher/course_group`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            if (this.filter.search !== '' || this.filter.search !== null) {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail (item, type) {
        this.process.detail = true
        let params = {
          id: item.id
        }
        this.$axios.$get(`api/publisher/course_group/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
            this.reset(response.results.data)
            if (this.selected.page === 'list') {
              this.dialog.form = true
            } else {
              this.fetchDetailItem(this.detail)
            }
          }else{
            this.process.detail = false
          }
        })
      },
      fetchDetailItem (item) {
        this.process.product = true
        let params = {
          group_id: item.id,
          search: this.filter.title
        }
        this.$axios.$get(`api/publisher/course_group/course_by_group`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.product = false
            this.detail_items = response.results.data
            if (this.filter.title !== '' || this.filter.title !== null) {
              this.contentEmptyProduct = "Data tidak tersedia"
            } else {
              this.contentEmptyProduct = "Data belum tersedia"
            }
          }else{
            this.process.detail = false
          }
        })
      },
      fetchClass () {
        this.process.kelas = true
        let params = {
          limit: 10000000000000,
          page: 1,
          search: this.filter.title
        }
        this.$axios.$get(`api/publisher/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.kelas = false
            if (this.filter.title !== '' || this.filter.title !== null) {
              this.contentEmptyProduct = "Data tidak tersedia"
            } else {
              this.contentEmptyProduct = "Data belum tersedia"
            }
            this.kelas = response.results.data
            this.allEtalase = true
            let ArrayData = []
            for (let i in response.results.data) {
              if (response.results.data[i].group_name === '') {
                ArrayData.push(response.results.data[i])
                this.allEtalase = false
                this.allEtalaseLength = ArrayData.length
              }
            }
          }else{
            this.process.kelas = false
          }
        })
      },
      fetchClassNoneGroup () {
        let dataId = ''
        for (let i = 0; i < this.form.product.length; i++) {
          dataId+=this.form.product[i].id+','
          // if (this.kelas[i].group_name === '') {
          //   this.selected.product.push(this.kelas[i])
          // }
        }
        this.process.kelas = true
        let params = {
          limit: 10000000000000,
          page: 1,
          search: this.filter.title,
          'group_id[in]': `0,${this.form.id}`,
          'id[nin]':this.form.product.map(e => e.id).join(",")
        }
        this.$axios.$get(`api/publisher/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.kelas = false
            if (this.filter.title !== '' || this.filter.title !== null) {
              this.contentEmptyProduct = "Data tidak tersedia"
            } else {
              this.contentEmptyProduct = "Data belum tersedia"
            }
            this.kelas = response.results.data
            this.allEtalase = false
            for (let i in response.results.data) {
              if (response.results.data[i].group_name === '') {
                let ArrayData = []
                ArrayData.push(response.results.data[i])
                // this.allEtalase = false
                console.log(ArrayData)
              }
            }
          }else{
            this.process.kelas = false
          }
        })
      },
      reset(item) {
        console.log(item)
        this.form = {
          id: item !== undefined ? item.id : "",
          name: item !== undefined ? item.name : "",
          product: item !== undefined ? item.items : [],
          img_url:  item !== undefined ? item.img_url : ""
        }

        this.avatar = this.form.img_url === '' ? '' : {
          fileuri: this.form.img_url
        }

        this.process = {
          form: false,
          run: false,
          kelas: false,
          detail: false,
          avatar: false,
          product: false,
          cancel: false
        }

        this.error = {
          message: "",
          img_url: ""
        }

        this.contentEmpty = ''

        this.selected.product = []
        this.filter = {
          title: "",
          category: "Disemua kategori",
          search: ""
        }
      },
      checkAllCreate() {
        if (this.selected.product.length < 1) {
          this.checkForCreate()
        } else {
          if (this.selected.product.length !== this.allEtalaseLength) {
            this.checkForCreate()
          } else {
            this.selected.product = []
          }
        }

        this.selected.product = [...new Set(this.selected.product)]
      },
      checkForCreate() {
        for (let i = 0; i < this.kelas.length; i++) {
          if (this.kelas[i].group_name === '') {
            this.selected.product.push(this.kelas[i])
          }
        }
      },
      checkAll() {
        if (this.selected.product.length < 1) {
          this.checkFor()
        } else {
          if (this.selected.product.length !== this.kelas.length) {
            this.checkFor()
          } else {
            this.selected.product = []
          }
        }

        this.selected.product = [...new Set(this.selected.product)]
      },
      checkFor() {
        console.log('asd')
        for (let i = 0; i < this.kelas.length; i++) {
          this.selected.product.push(this.kelas[i])
        }
      },
      addClassToEtalase() {
        if (this.form.id === '') {
          this.form.product = this.selected.product
        } else if (this.form.id !== '') {
          for (let i = 0; i < this.selected.product.length; i++) {
            this.form.product .push(this.selected.product[i])
          }
          this.selected.product = []
        }
        this.dialog.product = false
      },
      deleteProduct(index) {
        this.form.product.splice(index, 1)
      },
      validation() {
        var error = this.error,
          valid = true;

        if (this.form.img_url.length < 1) {
          error.img_url = "Gambar tidak boleh kosong";
          valid = false;
        } else {
          error.img_url = "";
        }

        return valid;
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        const valids = this.validation()
        this.course_id_array = []
        for (let i = 0; i < this.form.product.length; i++) {
          this.course_id_array.push(this.form.product[i].id)
        }
        // console.log(this.course_id_array)
        if (isValid && valids) {
          if (this.form.id === '') {
            this.creates()
          } else {
            this.updates()
          }
        }
      },
      async creates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_group`, 
        {
          name: this.form.name,
          course_id_array: this.course_id_array,
          img_url: this.form.img_url
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch()
            this.dialog.form = false
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async updates() {
        this.process.form = true
        await this.$axios.$post(`api/publisher/course_group/update`, 
        {
          id: this.form.id,
          name: this.form.name,
          course_id_array: this.course_id_array,
          img_url: this.form.img_url
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.form = false
            this.reset()
            this.fetch()
            if (this.selected.page === 'detail') {
              this.fetchDetail(this.detail, 'product')
              this.dialog.product = false
            }
            this.dialog.form = false
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async toDeletes() {
        this.process.cancel = true
        await this.$axios.$post(`api/publisher/course_group/delete`, 
        {
          id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cancel = false
            this.dialog.cancel = false
            this.selected.page = 'list'
            this.fetch()
            this.success.message = "Berhasil menghapus Etalase"
            this.snackbar = true
          } else {
            this.process.cancel = false
            this.error.message = response.message
          }
        })
      }
    },
  };
</script>