<template>
  <div>
    <v-container>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Tutup
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.pembayaran"
        v-model="dialog.pembayaran"
        width="450">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
              Cara Pembayaran
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.pembayaran = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>

          <v-card-text class="px-0">
            <div class="px-5 pb-0">
              <div class="d-flex align-center py-4" v-if="detail.payment_va_number !== ''">
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                  <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                    Nomor Virtual Account
                  </div>
                  {{ detail.payment_va_number }}
                </div>
                <div class="ml-auto">
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    @click="copyTestingCode(detail.payment_va_number)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                </div>
              </div>

              <v-divider v-if="detail.payment_va_number !== ''"></v-divider>

              <div class="d-flex align-center py-4">
                <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'text-h5'">
                  <div class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-2'">
                    Total Pembayaran
                  </div>
                  {{ detail.total_paid_nett | price }}
                </div>
                <div class="ml-auto">
                <v-btn 
                  class="text-capitalize"
                  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                  color="#4ab762" 
                  text
                  @click="copyTestingCode(detail.total_paid_nett)"
                  elevation="0">
                  <span>
                    Salin
                  </span>
                </v-btn>
                </div>
              </div>
            </div>

            <v-divider style="border-width: 5px;"></v-divider>

            <div class="px-0">
              <div v-if="detail.payment_detail_object.qrcode_url !== undefined">
                <v-img width="300" class="mx-auto" :src="detail.payment_detail_object.qrcode_url"></v-img>
                <div v-if="detail.redirect_url !== ''" class="text-center">
                  <v-btn
                    elevation="0"
                    color="#4ab762"
                    class="text-capitalize white--text mb-5"
                    target="_blank"
                    :href="detail.redirect_url">
                    Buka aplikasi <span v-if="detail.payment_name === 'Gopay'" class="ml-1">Gojek</span><span v-else class="ml-1">{{ detail.payment_name }}</span>
                  </v-btn>
                </div>
                <v-divider class=""/>
              </div>
              <div>
                <div>
                  <v-expansion-panels accordion flat  v-model="panel">
                    <v-expansion-panel v-for="(n, t) in detail.payment_detail_object.instruction" :key="t" style="border-bottom: 1px solid #eee;">
                      <v-expansion-panel-header class=" body-1 font-weight-bold text-first">{{ n.label }}</v-expansion-panel-header>
                      <v-expansion-panel-content class="px-0">
                        <div class="d-flex mb-3" v-for="(s, p) in n.value" :key="p">
                          <div class="mr-2 body-2">
                            {{ p+1 }}. 
                          </div>
                          <div>
                            {{ s }}
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.cancel"
        v-model="dialog.cancel"
        class="border-radius"
        width="400">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-h6 text-second text--darken-2 font-weight-bold">
              Batalkan Transaksi
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.cancel"
                class="text-capitalize px-0"
                @click="dialog.cancel = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <div class="text-second body-1 font-weight-reguler pt-6 pb-3 text-center">
              Apakah Anda yakin ingin membatalkan transaksi ini ?
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="7">
                <v-btn
                  elevation="0"
                  color="red"
                  block
                  :loading="process.cancel"
                  :disabled="process.cancel"
                  @click="toCancel()"
                  class="text-capitalize white--text px-4">
                  <div class="d-flex align-center" style="width: 100%;">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-auto">
                      Ya, Batalkan
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  elevation="0"
                  color="red"
                  outlined
                  block
                  :disabled="process.cancel"
                  @click="dialog.cancel = false"
                  class="text-capitalize white--text">
                  Tidak
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer> -->

            <!-- <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.detail"
        v-model="dialog.detail"
        width="500">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
              Detail Transaksi
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.detail = false; changeRouter()">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-0">
            <div v-if="process.detail" class="mt-6">
              <v-skeleton-loader
                :loading="process.detail"
                style="padding-left: 0px;"
                type="list-item">
              </v-skeleton-loader>
              <div class="py-3">
                <v-skeleton-loader
                  :loading="process.detail"
                  style="padding-left: 0px;"
                  type="list-item">
                </v-skeleton-loader>
              </div>
              <v-skeleton-loader
                :loading="process.detail"
                style="padding-left: 0px;"
                type="list-item">
              </v-skeleton-loader>
              <div class="py-3">
                <v-skeleton-loader
                  :loading="process.detail"
                  style="padding-left: 0px;"
                  type="list-item">
                </v-skeleton-loader>
              </div>
            </div>
            <div class="px-5 pb-0" v-if="!process.detail">
              <p class="body-1 text-first font-weight-bold mt-4 mb-2">
                Pembayaran
              </p>
              <div class="d-flex align-center py-3">
                <div class="text-second text--darken-2 body-1">
                  Total Harga ({{ detail.detail.length }} Kelas)
                </div>
                <div class="ml-auto">
                  <div class="body-1 text-second text--darken-2 font-weight-bold">
                  {{ detail.total_paid | price }}
                  </div>
                </div>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center py-3">
                <div class="text-second text--darken-2 body-1">
                  Diskon <span v-if="parseInt(detail.total_discount_value) > 0">({{ detail.total_discount_percent }}%)</span>
                </div>
                <div class="ml-auto">
                  <div class="body-1 warning--text font-weight-bold">
                    -{{ detail.total_discount_value | price }}
                  </div>
                </div>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center py-3">
                <div class="text-second text--darken-2 body-1">
                  Biaya Admin
                </div>
                <div class="ml-auto">
                  <div class="body-1 text-first font-weight-bold">
                    {{ detail.admin_fee | price }}
                  </div>
                </div>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center py-4">
                <div class="text-first text-h5 font-weight-bold">
                  <div class="text-first" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
                    Total Bayar
                  </div>
                </div>
                <div class="ml-auto d-flex align-center">
                  <div class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
                    {{ detail.total_paid_nett | price }}
                  </div>
                  <v-btn 
                    class="text-capitalize"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                    color="#4ab762" 
                    text
                    v-if="detail.status === 'waiting'"
                    @click="copyTestingCode(detail.total_paid_nett)"
                    elevation="0">
                    <span>
                      Salin
                    </span>
                  </v-btn>
                </div>
              </div>

            </div>

            <v-divider style="border-width: 5px;"></v-divider>

            <div v-if="process.detail" class="my-4">
              <v-skeleton-loader
                :loading="process.detail"
                class="mx-auto"
                style="padding-left: 0px;"
                type="list-item">
              </v-skeleton-loader>
              <div class="py-3">
                <v-skeleton-loader
                  :loading="process.detail"
                  class="mx-auto"
                  style="padding-left: 0px;"
                  type="list-item">
                </v-skeleton-loader>
              </div>
            </div>

            <div class="px-5 py-4" v-if="!process.detail">
              <div class="d-flex align-center mb-2">
                <div class="text-second text--darken-2 body-1">
                  Tanggal Beli
                </div>
                <v-spacer/>
                <div class="body-1 primary--text">
                  {{ detail.created_datetime | datetimemonth }}
                </div>
              </div>
              <v-divider class="my-4"/>
              <div class="d-flex align-center mb-2">
                <div class="text-second text--darken-2 body-1">
                  Status
                </div>
                <v-spacer/>
                <div class="body-1 font-weight-bold" :class="detail.status === 'paid' ? 'success--text' : detail.status === 'rejected' || detail.status === 'cancelled' ? 'red--text' : 'warning--text'">
                  {{ detail.status === 'rejected' ? 'Dibatalkan' : detail.status === 'paid' ? 'Lunas' : detail.status === 'waiting' ? 'Belum Bayar' : 'Dibatalkan' }}
                </div>
              </div>
              <v-divider class="my-4"/>
              <!-- <div class="d-flex align-center" v-if="detail.transaction_detail_jsonarray && detail.transaction_detail_jsonarray.length < 2 && detail.transaction_payment_status === 'Menunggu Pembayaran'"> -->
              <div class="d-flex align-center" v-if="detail.status === 'waiting'">
                <div class="text-second text--darken-2 body-1">
                  Batas Pembayaran
                </div>
                <v-spacer/>
                <div class="body-1 warning--text">
                  {{ detail.paid_due_datetime | datetimemonth }}
                </div>
              </div>
              <!-- <v-divider class="my-4"/> -->
              <div class="d-flex align-center" v-if="detail.status === 'paid'">
                <div class="text-second text--darken-2 body-1">
                  Tanggal Bayar
                </div>
                <v-spacer/>
                <div class="body-1 success--text">
                  {{ detail.paid_datetime | datetimemonth }}
                </div>
              </div>

              <div class="d-flex align-center" v-if="detail.status === 'rejected' || detail.status === 'cancelled'">
                <div class="text-second text--darken-2 body-1">
                  Tanggal Dibatalkan
                </div>
                <v-spacer/>
                <div class="body-1 red--text">
                  <div v-if="detail.status === 'rejected'" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">
                    <span class="red--text">{{ detail.rejected_datetime | datetimemonth }}</span>
                  </div>
                  <div v-if="detail.status === 'cancelled'" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">
                    <span class="red--text">{{ detail.cancelled_datetime | datetimemonth }}</span>
                  </div>
                </div>
              </div>
            </div>

            <v-divider style="border-width: 5px;" v-if="detail.total_paid_nett !== undefined && detail.total_paid_nett > 0"></v-divider>

            <div v-if="process.detail" class="my-4">
              <v-skeleton-loader
                :loading="process.detail"
                style="padding-left: 0px;"
                class="mx-auto"
                type="list-item">
              </v-skeleton-loader>
            </div>

            <v-list class="pt-0 px-5" v-if="!process.detail && detail.total_paid_nett > 0">
              <div class="d-flex">
                <div>
                  <p class="body-1 text-first font-weight-bold mt-4 mb-2">
                    Metode Pembayaran
                  </p>
                </div>
              </div>
              <v-list-item class="px-0">
                <v-list-item-avatar
                  color="#FFF"
                  width="80"
                  class="mt-0"
                  style="border-radius: 6px !important;">
                  <v-img contain :src="detail.payment_image"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="pt-0">
                  <div class="fs-16 font-weight-bold">
                    {{ detail.payment_name }}
                  </div>
                  <!-- <v-list-item-subtitle class="caption mt-2" v-if="detail.payment_va_number !== ''">
                    Nomor Rekening
                  </v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-content class="pt-0">
                  <v-list-item-subtitle class="title black--text font-weight-medium" v-if="detail.payment_va_number !== ''">

                    <div 
                      class="d-flex align-center justify-end">
                      <div class="fs-17">
                        {{ detail.payment_va_number }}
                      </div>
                      <!-- <v-icon 
                        class="ml-5 cursor-pointer"
                        size="20"
                        @click="copyTestingCode(detail.payment_va_number)">
                        mdi-content-copy
                      </v-icon> -->
                      <v-btn 
                        class="text-capitalize"
                        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'"
                        color="#4ab762" 
                        text
                        v-if="detail.status === 'waiting'"
                        @click="copyTestingCode(detail.payment_va_number)"
                        elevation="0">
                        <span>
                          Salin
                        </span>
                      </v-btn>
                    </div>
                    
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider style="border-width: 5px;"></v-divider>
            
            <div class="pa-5">
              <div v-if="process.detail" class="mt-6">
                <v-skeleton-loader
                  :loading="process.detail"
                  type="list-item-avatar-three-line">
                </v-skeleton-loader>
                <div class="py-3">
                  <v-skeleton-loader
                    :loading="process.detail"
                    type="list-item-avatar-three-line">
                  </v-skeleton-loader>
                </div>
                <v-skeleton-loader
                  :loading="process.detail"
                  type="list-item-avatar-three-line">
                </v-skeleton-loader>
                <div class="py-3">
                  <v-skeleton-loader
                    :loading="process.detail"
                    type="list-item-avatar-three-line">
                  </v-skeleton-loader>
                </div>
              </div>
              <div v-if="!process.detail">
                <div class="d-flex align-center mb-5">
                  <p class="body-1 text-first font-weight-bold mb-0">
                    Kelas Yang Dibeli
                  </p>
                  <div class="ml-auto text-second text--darken-2 body-1">
                    {{ detail.detail.length }} items
                  </div>
                </div>

                <v-list three-line class="py-0">
                  <v-list-item class="px-0 mb-3"
                    v-for="(item, index) in detail.detail"
                    :key="index"
                    target="_blank"
                    :href="detail.status === 'paid' ? `/collection/${item.user_course_id}` : `/elearning/${item.course_id}`">
                      <v-list-item-avatar tile width="100" height="100" class="ma-0">
                        <v-img 
                          style="border-radius: 10px;"
                          :src="item.course_img_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#3D4178">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="align-self-start ml-4 py-0">
                      <v-list-item-subtitle class="body-1 font-weight-bold">
                        <div class="text-capitalize mt-1 mb-1">
                          {{ item.course_name }}
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="body-2 mt-1">
                        <v-list-item class="px-0 pb-2" style="min-height: 20px;">
                          <v-list-item-avatar size="15" class="mt-1 mb-0 mr-2">
                            <v-img :src="item.course_organization_img_url" v-if="item.course_organization_img_url !== ''"></v-img>
                            <v-icon v-if="item.course_organization_img_url === ''" size="18">mdi-office-building</v-icon>
                          </v-list-item-avatar>
              
                          <v-list-item-content class="pt-1 pb-0">
                            <v-list-item-title class="text-second body-2">{{ item.course_organization_name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <div class="mt-1">
                          <!-- <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.discount_percent) > 0">
                            {{ item.price | price }}
                          </span> -->
                          <span class="font-weight-bold red--text">
                            <!-- <span v-if="parseInt(item.discount_percent) > 0">
                             (-{{ parseInt(item.discount_percent) }}%)
                            </span> -->
                            <span class="text-first" v-if="parseInt(item.course_price) > 0">
                              {{ item.course_price | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-7">
          <v-col cols="9" class="pt-0">
            <!-- <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div> -->
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb_30'">
          <div class="text-second d-flex align-center" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 60%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari transaksi…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <!-- <v-btn
              color="#3498db"
              elevation="0"
              height="45"
              width="120"
              @click="is_redeem = false; dialog.redeem = true"
              class="text-capitalize white--text ml-6">
              Redeem
            </v-btn> -->
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data transaksi
          </div>
        </div>

        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k" justify="end">
            <v-col cols="12" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0 px-0">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="10" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <div class="d-flex align-center">
                <div style="width: 200px;">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="list-item">
                  </v-skeleton-loader>
                </div>
                <div class="text-right ml-auto mt-2 d-flex justify-end">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="button">
                  </v-skeleton-loader>
                  <v-skeleton-loader
                    class="ml-5"
                    :loading="process.run"
                    type="button">
                  </v-skeleton-loader>
                  <v-skeleton-loader
                    :loading="process.run"
                    class="ml-5"
                    type="button">
                  </v-skeleton-loader>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list class="py-0">
              <v-list-item class="hover_select mb-4" style="background: #f9f9f9; border-radius: 7px; padding: 15px 20px; border: 1px solid #f9f9f9;">
                <v-list-item-content class="py-0">
                  <div :class="'d-flex align-center'">
                    <div class="d-flex">
                      <div>
                        <v-icon size="35" color="#4ab762">mdi-shopping</v-icon>
                      </div>
                      <div class="text-first text--darken-2 ml-4" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                        <!-- <b class="pr-2">Belanja</b>  -->
                        <div>
                          {{ l.number }}
                        </div>
                        <div class="caption">
                          {{ l.created_datetime | datetimemonth }}
                        </div>
                      </div>
                    </div>
                    <div class="text-second text--darken-2 ml-auto justify-end" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2 mt-3' : 'body-1'">
                      <!-- <div v-if="l.status === 'waiting'">
                        Bayar sebelum <span class="warning--text font-weight-bold">{{ l.paid_due_datetime | datetimemonth }}</span>
                      </div> -->
                      <div class="font-weight-bold">
                        <v-alert
                          dense
                          :color="l.status === 'paid' ? 'success' : l.status === 'rejected' || l.status === 'cancelled' ? 'red' : 'warning'"
                          text
                          class="fs-16"
                          style="font-weight: 500;">
                          {{ l.status === 'rejected' ? 'Dibatalkan' : l.status === 'paid' ? 'Lunas' : l.status === 'waiting' ? 'Belum Bayar' : 'Dibatalkan' }}
                        </v-alert>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <v-row class="pt-5 pb-4">
                    <v-col :cols="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 12 : 8">
                      <v-list three-line class="py-0" style="background: transparent;">
                        <v-list-item class="px-0 mb-3"
                          style="min-height: auto;"
                          v-for="(item, index) in l.detail"
                          :key="index"
                          v-show="index === 0">
                          <v-list-item-avatar tile width="65" height="65" class="ma-0">
                            <v-img 
                              style="border-radius: 10px;"
                              :src="item.course_img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#3D4178">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                          <v-list-item-content class="align-self-start ml-4 py-0">
                            <v-list-item-subtitle class="body-1 font-weight-bold">
                              <div class="text-capitalize mt-1 mb-1">
                                {{ item.course_name }}
                              </div>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="body-2 mt-1">
                              <v-list-item class="px-0 pb-2" style="min-height: 20px;">
                                <v-list-item-avatar size="15" class="mt-1 mb-0 mr-2">
                                  <v-img :src="item.course_organization_img_url" v-if="item.course_organization_img_url !== ''"></v-img>
                                  <v-icon v-if="item.course_organization_img_url === ''" size="18">mdi-office-building</v-icon>
                                </v-list-item-avatar>
                    
                                <v-list-item-content class="pt-1 pb-0">
                                  <v-list-item-title class="text-second body-2">{{ item.course_organization_name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <div class="mt-1">
                                <span class="font-weight-bold red--text">
                                  <span class="text-first" v-if="parseInt(item.course_price) > 0">
                                    {{ item.course_price | price }}
                                  </span>

                                  <span class="green--text" v-else>
                                    Gratis
                                  </span>
                                </span>
                              </div> -->
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <div v-if="l.detail.length > 1">
                        <span @click="detail = l; dialog.detail = true" class="color-first text-decoration-underline">
                          <b>+{{ l.detail.length - 1 }}</b> Lainnya 
                        </span>
                      </div>
                    </v-col>
                    <v-col :cols="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 12 : 4" class="d-flex align-end justify-end">
                      <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'text-right'">
                        <div class="fs-14 text-second text--darken-2 mb-1">
                          Total Pembayaran
                        </div>
                        <div class="text-first fs-18 font-weight-bold">
                          <span v-if="parseInt(l.total_paid_nett) > 0">
                            {{ l.total_paid_nett | price }}
                          </span>
                          <span class="green--text" v-else>
                            Gratis
                          </span>
                        </div>
                      </div>
                    </v-col>
                    <!-- <v-col :cols="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 12 : 4" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'px-0' : 'pl-0'" v-if="l.status === 'rejected' || l.status === 'cancelled'">
                      <div v-if="l.status === 'rejected'" class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">
                        Dibatalkan pada <span class="red--text font-weight-bold pl-2">{{ l.rejected_datetime | datetimemonth }}</span>
                      </div>
                      <div v-if="l.status === 'cancelled'" class="text-second text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">
                        Dibatalkan pada <span class="red--text font-weight-bold pl-2">{{ l.cancelled_datetime | datetimemonth }}</span>
                      </div>
                    </v-col> -->
                    <v-col :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''" :cols="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 12 : 6" v-if="l.status === 'waiting' || l.status === 'rejected' || l.status === 'cancelled'" class="d-flex align-center justify-start">
                      <div v-if="l.status === 'rejected'" class="red--text">
                        Dibatalkan oleh sistem secara otomatis
                      </div>
                      <div v-if="l.status === 'cancelled'" class="red--text">
                        Pembelian telah Anda batalkan
                      </div>
                      <div v-if="l.status === 'waiting'" class="text-second">
                        Silahkan melakukan pembayaran sebelum <span class="warning--text font-weight-bold">{{ l.paid_due_datetime | datetimemonth }}</span>
                      </div>
                    </v-col>
                    <v-col :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'pl-0' : ''" :cols="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 12 : 6">
                      <div class="text-right ml-auto">
                        <v-icon v-if="l.status === 'waiting'" @click="detail = l; dialog.cancel = true;" size="20" color="red">mdi-block-helper</v-icon>
                        <v-btn
                          outlined
                          elevation="0"
                          :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          style="background: #fff;"
                          color="#9e9e9e"
                          height="40"
                          @click="detail = l; dialog.detail = true"
                          class="text-capitalize ml-5">
                          Lihat Detail
                        </v-btn>
                        <v-btn
                          elevation="0"
                          :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          color="#4ab762"
                          height="40"
                          @click="detail = l; dialog.pembayaran = true"
                          v-if="l.status === 'waiting'"
                          class="text-capitalize white--text ml-4">
                          Cara Pembayaran
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <div class="d-flex align-center">
                    <div v-if="l.status === 'rejected'" class="text-second text--darken-2">
                      rejected pada <span class="red--text font-weight-bold">{{ l.transaction_rejected_datetime }}</span>
                    </div>
                    <div class="text-right ml-auto">
                      <v-icon v-if="l.status === 'waiting'" size="20" color="red">mdi-block-helper</v-icon>
                      <v-btn
                        outlined
                        elevation="0"
                        width="180"
                        color="#9e9e9e"
                        @click="dialog.detail = true"
                        class="text-capitalize ml-3">
                        Lihat Detail
                      </v-btn>
                      <v-btn
                        elevation="0"
                        width="180"
                        color="#4ab762"
                        v-if="l.status === 'waiting'"
                        class="text-capitalize white--text ml-4">
                        Cara Pembayaran
                      </v-btn>
                    </div>
                  </div> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>

        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          search: ""
        },
        dialog: {
          detail: false,
          pembayaran: false,
          cancel: false
        },
        timeout: 2000,
        snackbar: false,
        contentEmpty: "",
        pagination: "",
        list: [],
        panel: 0,
        detail: {},
        process: {
          detail: false,
          run: true,
          cancel: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/transaction',
          title: 'Transaksi Saya',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetch()
    },
    watch: {
      // call again the method if the route changes
      '$route': function(val) {
        this.fetch()
      },
    },
    mounted () {
      // this.fetch()
      // this.fetchDetail()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search,
          sort: '-created_datetime',
          status: this.$route.query.status !== undefined ? this.$route.query.status : ''
        }
        this.$axios.$get(`api/transaction`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            if (this.filter.search !== '' || this.filter.search !== null) {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
            if (this.$route.query.data !== undefined) {
              this.dialog.detail = true
              this.fetchDetail(this.$route.query.data)
            }
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail(item) {
        this.process.detail = true
        let params = {
          id: item
        }
        this.$axios.$get(`api/transaction/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      async copyTestingCode(text) {
        try {
          const toCopy = text
          await navigator.clipboard.writeText(toCopy);
          console.log('Text or Page URL copied');
          this.success.message = "Berhasil dicopy"
          this.snackbar = true
        }
        catch (err) {
          console.error('Failed to copy: ', err);
        }
      },
      changeRouter() {
        if (this.$route.query.data !== undefined) {
          this.$router.replace({ path: '/account/transaction' })
        }
      },
      async toCancel() {
        this.process.cancel = true
        await this.$axios.$post(`api/transaction/cancel`, 
        {
          id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cancel = false
            this.dialog.cancel = false
            this.fetch()
            this.success.message = "Berhasil membatalkan pembelian kelas"
            this.snackbar = true
          } else {
            this.process.cancel = false
            this.error.message = response.message
          }
        })
      }
    }
  }
</script>
