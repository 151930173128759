<template>
  <div>
    <div class="pt-5">
      <DetailPenjualan v-if="list.length > 0" :dialogopen="dialog.detail" :detailData="detail" :filterData="filter" @close="dialog.detail = false"/>
      <section>
        <!-- <v-row class="mb-5">
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #E67E22">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Kelas Terjual
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ summary.sum_total_item }}
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #F1C40F">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Transaksi Terjadi
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ summary.sum_total_item }}
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #1ABC9C">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Transaksi Berhasil
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ summary.sum_total_item }}
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #3498DB">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Penjualan
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ summary.sum_total_paid_nett | price}}
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row> -->

        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k" justify="end">
            <v-col cols="12" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0 px-0">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="10" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <div class="d-flex align-center">
                <div style="width: 200px;">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="list-item">
                  </v-skeleton-loader>
                </div>
                <div class="text-right ml-auto mt-2 d-flex justify-end">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="button">
                  </v-skeleton-loader>
                  <v-skeleton-loader
                    class="ml-5"
                    :loading="process.run"
                    type="button">
                  </v-skeleton-loader>
                  <v-skeleton-loader
                    :loading="process.run"
                    class="ml-5"
                    type="button">
                  </v-skeleton-loader>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-row class="hover_select mb-7" style="background: #f9f9f9; border-radius: 7px; padding: 10px 15px; border: 1px solid #f9f9f9;">
              <v-col cols="5">
                <div class="text-first text--darken-2" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                  <!-- <b class="pr-2">Belanja</b>  -->
                  <div class="caption">
                    <div v-if="filter.sort_filter !== 'Tahun Ini' && filter.sort_filter !== 'Tahun Kemarin'">
                      Tanggal
                    </div>
                    <div v-if="filter.sort_filter === 'Tahun Ini' || filter.sort_filter === 'Tahun Kemarin'">
                      Bulan
                    </div>
                  </div>
                  <di>
                    <!-- <div v-if="filter.sort_filter === 'Bulan Ini'">
                      {{ l.date_raw | dateTanggalMonth }}
                    </div> -->
                    <div v-if="filter.sort_filter !== 'Tahun Ini' && filter.sort_filter !== 'Tahun Kemarin'">
                      {{ l.date_raw | dateTanggalMonth }}
                    </div>
                    <div v-if="filter.sort_filter === 'Tahun Ini' || filter.sort_filter === 'Tahun Kemarin'">
                      {{ l.date_raw | monthyear }}
                    </div>
                  </di>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="text-second text--darken-2 ml-auto justify-end" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2 mt-3' : 'body-1'">
                  <div class="text-first text--darken-2 ml-4" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                    <!-- <b class="pr-2">Belanja</b>  -->
                    <div class="caption">
                      Transaksi
                    </div>
                    <div>
                      {{ l.transaction_success }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="text-second text--darken-2 ml-auto justify-end" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2 mt-3' : 'body-1'">
                  <div class="text-first text--darken-2 ml-4 text-right" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                    <!-- <b class="pr-2">Belanja</b>  -->
                    <div class="caption">
                      Nominal
                    </div>
                    <div>
                      {{ l.sale | price }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="text-right">
                  <v-btn
                    outlined
                    elevation="0"
                    :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 150 : 130"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    color="#4ab762"
                    @click="detail = l; dialog.detail = true;"
                    class="text-capitalize ml-3">
                    Lihat Detail
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          search: '',
          status: '',
          user: '',
          tanggal_mulai: "",
          tanggal_selesai: "",
          transaksi: "",
          publisher: ""
        },
        form_filter: {
          search: '',
          status: '',
          user: '',
          tanggal_mulai: "",
          tanggal_selesai: "",
          transaksi: "",
          publisher: ""
        },
        dialog: {
          detail: false,
          pembayaran: false,
          cancel: false,
          filter: false
        },
        summary: {},
        timeout: 2000,
        snackbar: false,
        contentEmpty: "",
        pagination: "",
        list: [],
        filter: {
          tanggal_mulai: "",
          tanggal_selesai: "",
          sort_filter: "Tahun Ini"
        },
        panel: 0,
        detail: {},
        process: {
          detail: false,
          run: true,
          cancel: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/transaction',
          title: 'Transaksi Saya',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
      DetailPenjualan: () => import('@/components/report/transaction/Detail.vue'),
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      filterData: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'filterData': function(val) {
        this.filter = val
        this.fetch()
      },
    },
    mounted () {
      this.filter = this.$props.filterData
      this.fetch()
      // this.fetchDetail()
    },
    methods: {
      fetch () {
        this.process.run = true
        let firstday = '';
        let lastday = '';
        if (this.filter.sort_filter === 'Minggu Ini') {
          let curr = new Date; // get current date
          let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          let last = first + 6; // last day is the first day + 6

          firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
          lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);
        }
        let params = {
          filter_type: this.filter.sort_filter === 'Tahun Ini' || this.filter.sort_filter === 'Tahun Kemarin' ? 'year' : this.filter.sort_filter === 'Bulan Ini' ? 'month' : 'date_range',
          filter_value: this.filter.sort_filter === 'Tahun Ini' ? new Date().getFullYear() : this.filter.sort_filter === 'Tahun Kemarin' ? new Date().getFullYear() - 1 : this.filter.sort_filter === 'Bulan Ini' ? `${new Date().getFullYear()}-${new Date().getMonth()}` : this.filter.sort_filter === 'Minggu Ini' ? JSON.stringify([firstday, lastday]) : JSON.stringify([this.filter.tanggal_mulai, this.filter.tanggal_selesai])
        }
        this.$axios.$get(`api/publisher/report/summary/detail_graphic`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            this.contentEmpty = "Data belum tersedia"
            this.list = response.results.data.detail
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail(item) {
        this.process.detail = true
        let params = {
          id: item
        }
        this.$axios.$get(`api/transaction/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      async copyTestingCode(text) {
        try {
          const toCopy = text
          await navigator.clipboard.writeText(toCopy);
          console.log('Text or Page URL copied');
          this.success.message = "Berhasil dicopy"
          this.snackbar = true
        }
        catch (err) {
          console.error('Failed to copy: ', err);
        }
      },
      toFilter(item) {
        this.filter = {
          search: this.form_filter.search === null ? '' : this.form_filter.search,
          status: this.form_filter.status,
          user: this.form_filter.user,
          tanggal_mulai: this.form_filter.tanggal_mulai,
          tanggal_selesai: this.form_filter.tanggal_selesai,
          transaksi: this.form_filter.transaksi,
          publisher: this.form_filter.publisher
        },
        this.filter.status = this.form_filter.status === "Selesai" ? "Diterima" : this.form_filter.status === "Ditolak" ? "Ditolak" : this.filter.status === "Menunggu Konfirmasi" ? "Menunggu Pembayaran" : this.filter.status
        this.fetch()
        this.dialog.filter = false
      },

      inputFilter(item) {
        this.form_filter = {
          search: this.filter.search,
          status: this.filter.status === "Diterima" ? "Selesai" : this.filter.status === "Ditolak" ? "Ditolak" : this.filter.status === "Menunggu Pembayaran" ? "Menunggu Konfirmasi" : this.filter.status,
          user: this.filter.user,
          tanggal_mulai: this.filter.tanggal_mulai,
          tanggal_selesai: this.filter.tanggal_selesai,
          transaksi: this.filter.transaksi,
          publisher: this.filter.publisher
        },
        this.dialog.filter = true
      },
      changeRouter() {
        if (this.$route.query.data !== undefined) {
          this.$router.replace({ path: '/account/transaction' })
        }
      },
    }
  }
</script>