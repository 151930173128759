<template>
  <div>
    <div style="z-index: 9999; top: 0; left: 0; position: fixed; width: 100%; height: 100vh; background-color: rgba(0,0,0,0.8); text-align: center; padding: 48vh 0">
      <v-card
        flat
        style="background: transparent;">
        <div style="background-color: #4AB762; color: #fff; font-size: 16px; padding: 10px 25px; border-radius: 50px; width: fit-content;" class="d-flex align-center mx-auto">
          <img 
            :src="require('@/assets/icon/gif_loading_default.gif')"
            style="height: 24px; margin-right: 10px;" />
          <div>
            {{ message }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      process: {
        run: false
      },
      message: "",
      error: {
        message: ''
      }
    }),
    props: {
      dialogopen: {
        type: Boolean,
        default: false
      },
      detailMessage: {
        type: String,
        default: ""
      },
    },
    watch: {
      'dialogopen': function(val) {
        this.dialog = val
      },
      'detailMessage': function(val) {
        this.message = val
      },
    },
    mounted() {
      this.dialog = this.$props.dialogopen
      this.message = this.$props.detailMessage
    },
    methods: {
      
    }
  }
</script>