<template>
  <div>
    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
      </v-snackbar>

      <v-snackbar
        v-model="snackbar_warning"
        :timeout="timeout"
        centered
        top
        style="margin-top: -40px;"
        color="warning">
        <div class="body-1 font-weight-bold">
          <span v-if="!detail.usable_bool">
            Anda tidak dapat mengikuti program diskon
          </span>
          <span v-if="detail.usable_bool">
            Periode pengajuan telah berakhir
          </span>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar_warning = false"
          >
            Tutup
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.product"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.product"
        width="550">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Pilih Kelas
              </div>
              <div class="body-2">
                Pilih Kelas untuk mengikuti program diskon.
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.product = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);">

            <div></div>
            <v-list flat dense class="" v-if="!process.kelas && kelas.length > 0">
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <div>
                    <v-icon color="grey" @click="checkAll()" v-if="selected.product.length < 1 || selected.product.length !== kelas.length">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon color="#4ab762" @click="checkAll()" v-if="selected.product.length > 0 && selected.product.length === kelas.length">
                      mdi-checkbox-marked
                    </v-icon>
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-first font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                    Pilih Semua
                  </v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </v-list>

            <v-divider/>
            <v-card flat
              v-for="(item, index) in process.kelas ? 8 : kelas"
              :key="index">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.kelas"
                type="list-item-avatar-three-line">
                <div>
                  <v-list flat three-line class="py-0" v-if="!process.kelas && kelas.length > 0">
                    <v-list-item class="px-0">
                      <v-list-item-icon @click="item.sys_kelas_cart_is_checked = item.sys_kelas_cart_is_checked ? false : true;" class="mr-6">
                         <v-checkbox
                          v-model="selected.product"
                          :off-icon="'mdi-checkbox-blank-outline'"
                          :color="'#4ab762'"
                          class="mt-0"
                          v-on:change="error.message = ''"
                          :value="item">
                        </v-checkbox>
                        <!-- <v-icon color="#4ab762" v-if="item.sys_kelas_cart_is_checked">mdi-checkbox-marked</v-icon>
                        <v-icon color="grey" v-if="!item.sys_kelas_cart_is_checked">mdi-checkbox-blank-outline</v-icon> -->
                      </v-list-item-icon>

                      <v-list-item-avatar tile size="80" class="mr-3">
                        <v-img 
                          style="border-radius: 10px;"
                          :src="item.img_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                          {{ item.name }} 
                        </v-list-item-subtitle>
                        <v-list-item class="px-0" style="min-height: 20px;">
                          <v-list-item-avatar :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 15 : 15" class="mt-1 mb-0 " style="margin-right: 8px;">
                            <v-img :src="item.organization_img_url" v-if="item.organization_img_url !== ''"></v-img>
                            <v-icon v-if="item.organization_img_url === ''" size="15">mdi-office-building</v-icon>
                          </v-list-item-avatar>
              
                          <v-list-item-content class="pt-1 pb-0">
                            <v-list-item-title class="text-second fs-14" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">{{ item.organization_name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.price) > 0 && parseInt(item.discount_percent_publisher) > 0">
                            {{ item.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span v-if="parseInt(item.price) > 0 && parseInt(item.discount_percent_publisher) > 0">
                            (<span v-if="parseInt(item.discount_percent_publisher) > 0">{{ parseInt(item.discount_percent_publisher) }}%</span>)
                            </span>
                            <span class="text-first" v-if="parseInt(item.price) > 0">
                              {{ parseInt(item.price_discount) | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </p>
                        <div class="red--text caption">
                          Harga Kelas Anda
                        </div>
                      </v-list-item-content>
                      <!-- <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                      </v-list-item-content> -->
                    </v-list-item>
                  </v-list>
                  <div style="margin-left: 58px;">
                    <v-alert
                      dense
                      text
                      color="success">
                      <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                        <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.price) > 0 && parseInt(detail.percent) > 0">
                          {{ item.price | price }}
                        </span>
                        <span class="font-weight-bold red--text">
                          <span v-if="parseInt(item.price) > 0 && parseInt(detail.percent) > 0">
                          (<span v-if="parseInt(item.discount_percent_publisher) > 0">{{ parseInt(item.discount_percent_publisher) }}%+</span>{{ parseInt(detail.percent) }}%)
                          </span>
                          <span class="text-first" v-if="parseInt(item.price) > 0">
                            {{ parseInt(item.price_discount) - (parseInt(item.price_discount)*parseInt(detail.percent))/100 | price }}
                          </span>

                          <span class="green--text" v-else>
                            Gratis
                          </span>
                        </span>
                      </p>
                      <div class="caption">
                        Harga Setelah Mengikuti Potongan
                      </div>
                    </v-alert>

                  </div>

                </div>

                <v-divider v-if="index < kelas.length - 1"/>

              </v-skeleton-loader>
            </v-card>
            <div class="text-right mt-16" v-if="!process.kelas && kelas.length < 1">
              <Empty v-model="contentEmptyProduct"/>
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <!-- <v-btn
              elevation="0"
              color="grey darken-4"
              outlined
              width="120"
              @click="dialog.metode = true"
              class="text-capitalize white--text mr-2">
              Cara Lain
            </v-btn> -->
            <v-btn
              elevation="0"
              color="#4ab762"
              width="200"
              :disabled="process.form || process.kelas || selected.product.length < 1"
              :loading="process.form"
              @click="addClassToDiskon()"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <div class="ml-auto">
                  Tambahkan ke diskon
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.form"
              width="120"
              @click="dialog.product = false"
              class="text-capitalize white--text mr-2">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.detail"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.detail"
        width="550">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Detail Diskon
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.detail = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);" class="px-0 pb-0">
            <div v-if="process.detail" class="mt-3 px-5">
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.detail"
                type="list-item">
              </v-skeleton-loader>
            </div>
            <div v-if="!process.detail" class="pa-5">
              <v-img
                :src="detail.image_landscape_url"
                :lazy-src="detail.image_landscape_url"
                height="175"
                contain
                gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.4)"
                style="border-radius: 7px;"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div class="fs-18 font-weight-bold text-first text--darken-2 mt-7" style="margin-bottom: 19px;">
                {{ detail.name }}
              </div>
              <div class="fs-14 font-weight-bold text-first mb-2">
                Masa Berlaku
              </div>
              <div class="fs-16 text-second">
                <span v-if="detail.start_datetime_array.day_int === detail.end_datetime_array.day_int && detail.start_datetime_array.month_int === detail.end_datetime_array.month_int && detail.start_datetime_array.year_int === detail.end_datetime_array.year_int">
                  Berlaku pada {{ detail.start_datetime | dateTanggalMonth }} Pukul {{ detail.start_datetime | time }} - {{ detail.end_datetime | time }}
                </span>
                <span v-else>
                  Berlaku dari {{ detail.start_datetime | datetimemonth }}, maksimal {{ detail.end_datetime | datetimemonth }}
                </span>
              </div>
              <div class="fs-14 font-weight-bold text-first mb-2 mt-5">
                Periode Pendaftaran
              </div>
              <div class="fs-16 text-second">
                <span v-if="detail.start_apply_datetime_array.day_int === detail.end_apply_datetime_array.day_int && detail.start_apply_datetime_array.month_int === detail.end_apply_datetime_array.month_int && detail.start_apply_datetime_array.year_int === detail.end_apply_datetime_array.year_int">
                  Pendaftaran pada {{ detail.start_apply_datetime | dateTanggalMonth }} Pukul {{ detail.start_apply_datetime | time }} - {{ detail.end_apply_datetime | time }}
                </span>
                <span v-else>
                  Pendaftaran dimulai dari {{ detail.start_apply_datetime | datetimemonth }}, sampai {{ detail.end_apply_datetime | datetimemonth }}
                </span>
              </div>
              <div class="fs-14 font-weight-bold text-first mb-2 mt-5">
                Platform
              </div>
              <div class="fs-16 text-second">
                <span v-if="detail.platform === 'semua'">
                  Untuk semua platform
                </span>
                <span v-if="detail.platform === 'web'">
                  Untuk platform Web
                </span>
                <span v-if="detail.platform === 'mobile'">
                  Untuk platform Mobile
                </span>
              </div>
              <!-- <div class="fs-14 text-second font-weight-bold mb-1">
                Nama
              </div>
              <div class="font-weight-bold text-first text--darken-2" style="line-height: 26px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
                {{ detail.name }}
              </div>
              <v-list-item class="py-0 px-0 my-2">
                <v-list-item-content class="py-0">
                  <div class="body-2 body-1 text-left color-blue-first">
                    <div class="fs-14 text-second font-weight-bold mb-1">
                      Diskon
                    </div>
                    <div class="font-weight-bold">
                      {{ detail.percent }}%
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item> -->

              <!-- <v-list style="background: transparent;" class="py-0">
                <v-list-item class="py-0 px-0">
                  <v-list-item-content class="py-0">
                    <div class="body-2 body-1 text-second text-left text--darken-2 ">
                      <div class="fs-14 text-second font-weight-bold mb-1">
                        Tanggal Diskon
                      </div>
                      <div class="text-first font-weight-bold fs-17">
                        <span v-if="detail.start_datetime_array.day_int === detail.end_datetime_array.day_int && detail.start_datetime_array.month_int === detail.end_datetime_array.month_int && detail.start_datetime_array.year_int === detail.end_datetime_array.year_int">
                          {{ detail.start_datetime | dateTanggalMonth }} Pukul {{ detail.start_datetime | time }} - {{ detail.end_datetime | time }}
                        </span>
                        <span v-else>
                          {{ detail.start_datetime | datetimemonth }} - {{ detail.end_datetime | datetimemonth }}
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->

              <!-- <v-list style="background: transparent;" class="py-0 mt-3">
                <v-list-item class="py-0 px-0">
                  <v-list-item-content class="py-0">
                    <div class="body-2 body-1 text-second text-left text--darken-2 ">
                      <div class="fs-14 text-second font-weight-bold mb-1">
                        Tanggal Pengajuan
                      </div>
                      <div class="text-first font-weight-bold fs-17">
                        <span v-if="detail.start_apply_datetime_array.day_int === detail.end_apply_datetime_array.day_int && detail.start_apply_datetime_array.month_int === detail.end_apply_datetime_array.month_int && detail.start_apply_datetime_array.year_int === detail.end_apply_datetime_array.year_int">
                          {{ detail.start_apply_datetime | dateTanggalMonth }} Pukul {{ detail.start_apply_datetime | time }} - {{ detail.end_apply_datetime | time }}
                        </span>
                        <span v-else>
                          {{ detail.start_apply_datetime | datetimemonth }} - {{ detail.end_apply_datetime | datetimemonth }}
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->

              <!-- <div class="fs-14 text-second font-weight-bold mb-1 mt-4">
                Platform
              </div>
              <div class="text-first font-weight-bold fs-17">
                <span v-if="detail.platform === 'semua'">
                  Untuk semua platform
                </span>
                <span v-if="detail.platform === 'web'">
                  Untuk platform Web
                </span>
                <span v-if="detail.platform === 'mobile'">
                  Untuk platform Mobile
                </span>
              </div> -->

              <!-- <div class="fs-14 text-second font-weight-bold mb-1 mt-4">
                Syarat Diskon
              </div>
              <div class="text-first font-weight-bold fs-17" v-if="detail.item_array.length < 1">
                <span v-if="detail.item_type === 'organization'">
                  Untuk semua Organisasi
                </span>
                <span v-if="detail.item_type === 'publisher'">
                  Untuk semua publisher
                </span>
                <span v-if="detail.item_type === 'category'">
                  Untuk semua kategori
                </span>
              </div>

              <div class="text-first font-weight-bold fs-15" v-if="detail.item_array.length > 0">
                <span v-if="detail.item_type === 'organization'">
                  Untuk organisasi tertentu, meliputi:
                  <v-card flat
                    v-for="(item, index) in detail.items"
                    :key="index">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.detail"
                      type="list-item-avatar-three-line">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0" style="min-height: auto;">

                          <v-list-item-avatar style="border-radius: 10px;" size="50" color="#4ab762" class="mr-3 my-1">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-icon size="30" color="white" v-if="item.img_url === ''">mdi-office-building</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                    </v-skeleton-loader>
                  </v-card>
                </span>
                <span v-if="detail.item_type === 'publisher'">
                  Untuk publisher tertentu, meliputi:
                  <v-card flat
                    v-for="(item, index) in detail.items"
                    :key="index">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.detail"
                      type="list-item-avatar-three-line">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0" style="min-height: auto;">

                          <v-list-item-avatar style="border-radius: 10px;" size="50" color="#4ab762" class="mr-3 my-1">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-icon size="30" color="white" v-if="item.img_url === ''">mdi-office-building</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                    </v-skeleton-loader>
                  </v-card>
                </span>
                <span v-if="detail.item_type === 'category'">
                  Untuk kategori tertentu, meliputi:
                  <v-card flat
                    v-for="(item, index) in detail.items"
                    :key="index">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.detail"
                      type="list-item-avatar-three-line">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0" style="min-height: auto;">

                          <v-list-item-avatar style="border-radius: 10px;" size="50" color="#4ab762" class="mr-3 my-1">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url === ''"
                              :src="require('@/assets/images/content_image_empty_square.png')">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                    </v-skeleton-loader>
                  </v-card>
                </span>
              </div> -->
            </div>

            <v-divider style="border-width: 5px;"></v-divider>

            <div v-if="!process.detail" class="pa-5">
              <div class="fs-14 font-weight-bold text-first mb-2">
                Syarat & Ketentuan
              </div>

              <div class="fs-16 text-second" v-if="detail.item_array.length < 1">
                <span v-if="detail.item_type === 'organization'">
                  Diskon dapat dipakai untuk semua Organisasi
                </span>
                <span v-if="detail.item_type === 'publisher'">
                  Diskon dapat dipakai untuk semua publisher
                </span>
                <span v-if="detail.item_type === 'category'">
                  Diskon dapat dipakai untuk semua kategori
                </span>
              </div>

              <div class="fs-16 text-second" v-if="detail.item_array.length > 0">
                <span v-if="detail.item_type === 'organization'">
                  Diskon hanya dapat dipakai untuk organisasi tertentu, meliputi:
                  <v-card flat
                    v-for="(item, index) in detail.items"
                    :key="index"
                    class="mt-1">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.detail"
                      type="list-item-avatar-three-line">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0" style="min-height: auto;">

                          <v-list-item-avatar style="border-radius: 10px;" size="50" color="#4ab762" class="mr-3 my-1">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-icon size="30" color="white" v-if="item.img_url === ''">mdi-office-building</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                            <!-- <div class="text-first body-2">
                              <span class="warning--text">Jika kelas di kategori ini terdapat diskon, maka akan diganti dengan diskon ya baru</span>
                            </div> -->
                            <!-- <div class="text-first body-2" v-if="form.id !== ''">
                              <span>Belum terdapat di diskon manapun</span>
                            </div> -->
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                            <!-- <v-list-item-subtitle class="mt-1">
                              <div>
                                <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                              </div>
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                    </v-skeleton-loader>
                  </v-card>
                </span>
                <span v-if="detail.item_type === 'publisher'">
                  Diskon hanya dapat dipakai untuk publisher tertentu, meliputi:
                  <v-card flat
                    v-for="(item, index) in detail.items"
                    :key="index"
                    class="mt-1">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.detail"
                      type="list-item-avatar-three-line">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0" style="min-height: auto;">

                          <v-list-item-avatar style="border-radius: 10px;" size="50" color="#4ab762" class="mr-3 my-1">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-icon size="30" color="white" v-if="item.img_url === ''">mdi-office-building</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                            <!-- <div class="text-first body-2">
                              <span class="warning--text">Jika kelas di kategori ini terdapat diskon, maka akan diganti dengan diskon ya baru</span>
                            </div> -->
                            <!-- <div class="text-first body-2" v-if="form.id !== ''">
                              <span>Belum terdapat di diskon manapun</span>
                            </div> -->
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                            <!-- <v-list-item-subtitle class="mt-1">
                              <div>
                                <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                              </div>
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                    </v-skeleton-loader>
                  </v-card>
                </span>
                <span v-if="detail.item_type === 'category'">
                  Diskon hanya dapat dipakai untuk kategori tertentu, meliputi:
                  <v-card flat
                    v-for="(item, index) in detail.items"
                    :key="index"
                    class="mt-1">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.detail"
                      type="list-item-avatar-three-line">
                      <v-list flat three-line class="py-0">
                        <v-list-item class="px-0" style="min-height: auto;">

                          <v-list-item-avatar style="border-radius: 10px;" size="50" color="#4ab762" class="mr-3 my-1">
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url !== ''"
                              :src="item.img_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-img 
                              style="border-radius: 10px;"
                              v-if="item.img_url === ''"
                              :src="require('@/assets/images/content_image_empty_square.png')">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                              {{ item.name }} 
                            </v-list-item-subtitle>
                            <!-- <div class="text-first body-2">
                              <span class="warning--text">Jika kelas di kategori ini terdapat diskon, maka akan diganti dengan diskon ya baru</span>
                            </div> -->
                            <!-- <div class="text-first body-2" v-if="form.id !== ''">
                              <span>Belum terdapat di diskon manapun</span>
                            </div> -->
                          </v-list-item-content>
                          <v-list-item-content class="align-start text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 90px;'">
                            <!-- <v-list-item-subtitle class="mt-1">
                              <div>
                                <v-icon color="red" class="ml-2" @click="deleteProduct(index)">mdi-block-helper</v-icon>
                              </div>
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider v-if="!process.detail && index < detail.items.length - 1"/>

                    </v-skeleton-loader>
                  </v-card>
                </span>
              </div>
            </div>

            <!-- <v-card flat
              v-for="(item, index) in process.detail ? 8 :  detail.program"
              :key="index">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.detail"
                type="list-item-avatar-three-line">
                <div>
                  <div>
                    <v-list flat three-line class="py-0">
                      <v-list-item class="px-0">
                        <v-list-item-avatar tile size="100" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                            {{ item.name }}  
                          </v-list-item-subtitle>

                          <v-list-item class="px-0" style="min-height: 20px;">
                            <v-list-item-avatar :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 15 : 15" class="mt-1 mb-0 " style="margin-right: 8px;">
                              <v-img :src="item.organization_img_url" v-if="item.organization_img_url !== ''"></v-img>
                              <v-icon v-if="item.organization_img_url === ''" size="15">mdi-office-building</v-icon>
                            </v-list-item-avatar>
                
                            <v-list-item-content class="pt-1 pb-0">
                              <v-list-item-title class="text-second fs-14" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">{{ item.organization_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <p class="text-first font-weight-bold text-capitalize my-1" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-15'">
                            <span class="text-decoration-line-through text-second mr-2" >
                              Rp 200.000
                            </span>
                            <span class="font-weight-bold red--text">
                              <span >
                              (-{{ parseInt(detail.percent) }}%)
                              </span>
                              <span class="text-first">
                                Rp 100.000
                              </span>
                            </span>
                          </p>

                          <div class="warning--text caption">
                            Harga Setelah Mengikuti Potongan
                          </div>

                        </v-list-item-content>
                        <v-list-item-content class="align-start text-right d-flex justify-end" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 145px;'">
                          <div :class="item.status === 'approved' ? 'success--text' : item.status === 'rejected' || item.status === 'cancelled' ? 'red--text' : 'warning--text'">
                            {{ item.status === 'rejected' ? 'Dibatalkan' : item.status === 'approved' ? 'Di Setujui' : item.status === 'waiting' ? 'Menunggu Approval' : 'Dibatalkan' }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>

                <v-divider v-if="!process.detail && index < detail.program.length - 1"/>

              </v-skeleton-loader>
            </v-card> -->

          </v-card-text>
          <v-card-actions class="pa-5">
            <!-- <v-btn
              elevation="0"
              color="red"
              width="120"
              :disabled="process.detail"
              @click="deletes = detail; dialog.cancel = true;"
              class="text-capitalize white--text mr-2">
              Hapus
            </v-btn> -->
            <v-alert 
              color="error" 
              text
              v-show="!detail.usable_bool || new Date(detail.end_apply_datetime) < new Date()"
              dense
              class="mb-0 mr-2 px-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'">
              <span v-if="!detail.usable_bool">
                Anda tidak dapat mengikuti program diskon
              </span>
              <span v-if="detail.usable_bool">
                Periode pengajuan telah berakhir
              </span>
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 140"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="#4ab762"
              height="40"
              v-if="detail.program.length < 1"
              :disabled="process.detail || !detail.usable_bool || new Date(detail.end_apply_datetime) < new Date()"
              @click="!detail.usable_bool || new Date(detail.end_apply_datetime) < new Date() ? snackbar_warning = true : dialog.product = true; new Date() > detail.end_apply_datetime ? '' : fetchClass(); form.product = []; selected.product = [];"
              class="text-capitalize white--text">
              Ikuti
            </v-btn>

            <v-btn
              elevation="0"
              outlined
              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 140"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="#3498db"
              height="40"
              v-if="detail.program.length > 0"
              :disabled="process.detail"
              @click="dialog.class = true; dialog.detail = false;"
              class="text-capitalize">
              Lihat Kelas ({{ detail.program.length }})
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.detail"
              width="120"
              @click="dialog.detail = false"
              class="text-capitalize white--text mr-2">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.class"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-model="dialog.class"
        width="550">
        <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
          <div class="d-flex align-center body-2 px-5 py-4">
            <div class="body-1 text-second text--darken-2 font-weight-bold">
              <div>
                Data Kelas
              </div>
              <div class="body-2">
                Kelas Anda Yang Mengikuti Program
              </div>
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.form"
                class="text-capitalize px-0"
                @click="dialog.class = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 235px);">
            <v-card flat
              v-for="(item, index) in process.detail ? 8 :  detail.program"
              :key="index">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.detail"
                type="list-item-avatar-three-line">
                <div>
                  <div>
                    <v-list flat three-line class="py-0">
                      <v-list-item class="px-0">
                        <v-list-item-avatar tile size="85" class="mr-3">
                          <v-img 
                            style="border-radius: 10px;"
                            :src="item.img_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-0"
                            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'body-1'">
                            {{ item.name }}  
                          </v-list-item-subtitle>

                          <v-list-item class="px-0" style="min-height: 20px;">
                            <v-list-item-avatar :size="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 15 : 15" class="mt-1 mb-0 " style="margin-right: 8px;">
                              <v-img :src="item.organization_img_url" v-if="item.organization_img_url !== ''"></v-img>
                              <v-icon v-if="item.organization_img_url === ''" size="15">mdi-office-building</v-icon>
                            </v-list-item-avatar>
                
                            <v-list-item-content class="pt-1 pb-0">
                              <v-list-item-title class="text-second fs-14" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''">{{ item.organization_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <p class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                            <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.price) > 0 && parseInt(item.discount_percent_publisher) > 0">
                              {{ item.price | price }}
                            </span>
                            <span class="font-weight-bold red--text">
                              <span v-if="parseInt(item.price) > 0 && parseInt(item.discount_percent_publisher) > 0">
                              (<span v-if="parseInt(item.discount_percent_publisher) > 0">{{ parseInt(item.discount_percent_publisher) }}%</span>)
                              </span>
                              <span class="text-first" v-if="parseInt(item.price) > 0">
                                {{ parseInt(item.price_discount) | price }}
                              </span>

                              <span class="green--text" v-else>
                                Gratis
                              </span>
                            </span>
                          </p>
                          <div class="red--text caption">
                            Harga Kelas Anda
                          </div>

                        </v-list-item-content>
                        <v-list-item-content class="align-start text-right d-flex justify-end" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'max-width: 0px;' : 'max-width: 145px;'">
                          <div :class="item.status === 'approved' ? 'success--text' : item.status === 'rejected' || item.status === 'cancelled' ? 'red--text' : 'warning--text'">
                            {{ item.status === 'rejected' ? 'Dibatalkan' : item.status === 'approved' ? 'Di Setujui' : item.status === 'waiting' ? 'Menunggu Approval' : 'Dibatalkan' }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <div>
                      <v-alert
                        dense
                        text
                        color="success">
                        <div class="text-first font-weight-bold text-capitalize mt-0 mb-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'body-1'">
                          <div class="fs-13 text-second text--darken-2">
                            Tanggal Pengajuan
                          </div>
                          <div class="color-first font-weight-bold fs-14 mb-2">
                            {{ item.program_input_datetime | datetimemonth }}
                          </div>
                          <span class="text-decoration-line-through text-second mr-2" v-if="parseInt(item.price) > 0 && parseInt(detail.percent) > 0">
                            {{ item.price | price }}
                          </span>
                          <span class="font-weight-bold red--text">
                            <span v-if="parseInt(item.price) > 0 && parseInt(detail.percent) > 0">
                            (<span v-if="parseInt(item.discount_percent_publisher) > 0">{{ parseInt(item.discount_percent_publisher) }}%+</span>{{ parseInt(detail.percent) }}%)
                            </span>
                            <span class="text-first" v-if="parseInt(item.price) > 0">
                              {{ parseInt(item.price_discount) - (parseInt(item.price_discount)*parseInt(detail.percent))/100 | price }}
                            </span>

                            <span class="green--text" v-else>
                              Gratis
                            </span>
                          </span>
                        </div>
                        <div class="caption">
                          Harga Setelah Mengikuti Potongan
                        </div>
                      </v-alert>

                    </div>

                  </div>
                </div>

                <v-divider v-if="!process.detail && index < detail.program.length - 1"/>

              </v-skeleton-loader>
            </v-card>

          </v-card-text>
          <v-card-actions class="pa-5">
            <!-- <v-btn
              elevation="0"
              color="red"
              width="120"
              :disabled="process.detail"
              @click="deletes = detail; dialog.cancel = true;"
              class="text-capitalize white--text mr-2">
              Hapus
            </v-btn> -->
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="#4ab762"
              height="40"
              :disabled="process.detail || new Date() > detail.end_apply_datetime"
              @click="new Date() > detail.end_apply_datetime ? '' : fetchClass(); dialog.product = true; form.product = []; selected.product = []; dialog.class = false;"
              class="text-capitalize white--text">
              Tambah Kelas
            </v-btn>

            <v-btn
              elevation="0"
              color="red"
              outlined
              :disabled="process.detail"
              width="120"
              @click="dialog.class = false"
              class="text-capitalize white--text mr-2">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-7">
          <v-col cols="9" class="pt-0">
            <!-- <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div> -->
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb_30'">
          <div class="text-second d-flex align-center" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 60%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari program diskon…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-select
              :items="status"
              solo
              style="width: 200px;"
              v-on:change="fetch()"
              color="#4ab762"
              hide-details
              class="ml-6"
              placeholder="Pilih status disini"
              item-text="name"
              item-value="id"
              item-color="green"
              v-model="filter.status">
            </v-select>
            <!-- <v-btn
              color="#3498db"
              elevation="0"
              height="45"
              width="120"
              @click="reset(); dialog.form = true"
              class="text-capitalize white--text ml-6">
              Tambah
            </v-btn> -->
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data diskon
          </div>
        </div>
        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0 px-0">
                <v-list-item class="py-0 px-0">
                  <!-- <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar> -->
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-skeleton-loader
                style="width: 100px; height: 40px;"
                :loading="process.run"
                type="image">
              </v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="button">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list class="py-0">
              <v-list-item class="mb-4 hover_select" style="background: #f9f9f9; border-radius: 15px; padding: 20px 20px; border: 1px solid #f9f9f9;">
                <v-list-item-content class="py-0">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="5" class="pl-0" style="display: grid;">
                          <v-list style="background: transparent;" class="py-0">
                            <v-list-item class="pr-0 pt-0">
                              <v-list-item-avatar
                                size="148"
                                style="border-radius: 15px;"
                                color="#4ab762"
                                class="mt-0 mr-4">
                                <v-img 
                                  :src="l.image_portrait_url" style="border-radius: 15px;"
                                  v-if="l.image_portrait_url !== ''">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <v-icon size="40" color="white" v-if="l.image_portrait_url === ''">mdi-sack-percent</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content class="pt-0">
                                <div class="fs-14 text-second text--darken-2 mb-1">
                                  Nama Diskon
                                </div>
                                <div class="font-weight-bold text-first text--darken-2" style="line-height: 26px;" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'body-1'">
                                  {{ l.name }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col cols="12" md="7" class="pl-0" style="display: grid;">
                          <div class="text-second text--darken-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-16 text-right' : 'body-2'">
                            <div class="fs-14 text-second text--darken-2 mb-1">
                              Periode Pengajuan
                            </div>
                            <div class="color-first font-weight-bold fs-17">
                              <span v-if="l.start_apply_datetime_array.day_int === l.end_apply_datetime_array.day_int && l.start_apply_datetime_array.month_int === l.end_apply_datetime_array.month_int && l.start_apply_datetime_array.year_int === l.end_apply_datetime_array.year_int">
                                {{ l.start_apply_datetime | dateTanggalMonth }} Pukul {{ l.start_apply_datetime | time }} - {{ l.end_apply_datetime | time }}
                              </span>
                              <span v-else>
                                {{ l.start_apply_datetime | datetimemonth }} - {{ l.end_apply_datetime | datetimemonth }}
                              </span>
                            </div>
                            <!-- <div v-if="new Date() >= new Date(l.start_datetime) && new Date() <= new Date(l.end_datetime)">
                              Berlaku hingga <span class="green--text font-weight-bold">{{ l.end_datetime | datetimemonth }}</span>
                            </div>
                            <div v-if="new Date() < new Date(l.start_datetime)">
                              Dapat digunakan pada <span class="warning--text font-weight-bold">{{ l.start_datetime | datetimemonth }}</span>
                            </div>
                            <div v-if="new Date() > new Date(l.end_datetime)" class="red--text">
                              Berakhir (Telah Expired)
                            </div> -->
                          </div>

                          <v-list style="background: transparent;" class="py-4 d-flex align-center justify-end">
                            <v-list-item  style="border-right: 1px solid #ebebeb; max-width: 100px;" class="py-0 pl-0">
                              <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-right color-blue-first">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Diskon
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ l.percent }}%
                                  </div>
                                </div>
                              </v-list-item-content>

                              <!-- <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-second text-right text--darken-2 ">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Tanggal Diskon
                                  </div>
                                  <div class="text-first font-weight-bold fs-17">
                                    {{ l.start_datetime | datetimemonth }} - {{ l.end_datetime | datetimemonth }}
                                  </div>
                                </div>
                              </v-list-item-content> -->
                            </v-list-item>

                            
                            <v-list-item class="py-0 pr-0">
                              <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-second text-right text--darken-2 ">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Periode Diskon
                                  </div>
                                  <div class="text-first font-weight-bold fs-17">
                                    <span v-if="l.start_datetime_array.day_int === l.end_datetime_array.day_int && l.start_datetime_array.month_int === l.end_datetime_array.month_int && l.start_datetime_array.year_int === l.end_datetime_array.year_int">
                                      {{ l.start_datetime | dateTanggalMonth }} Pukul {{ l.start_datetime | time }} - {{ l.end_datetime | time }}
                                    </span>
                                    <span v-else>
                                      {{ l.start_datetime | datetimemonth }} - {{ l.end_datetime | datetimemonth }}
                                    </span>
                                  </div>
                                </div>
                              </v-list-item-content>

                              <!-- <v-list-item-content class="py-0">
                                <div class="body-2 body-1 text-right color-blue-first">
                                  <div class="fs-14 text-second text--darken-2 mb-1">
                                    Diskon
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ l.percent }}%
                                  </div>
                                </div>
                              </v-list-item-content> -->
                            </v-list-item>
                          </v-list>
                          
                          <div class="d-flex align-center justify-end" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
                            <v-btn
                              outlined
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 140"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#3498db"
                              height="40"
                              v-if="l.program.length > 0"
                              @click="detail = l; dialog.class = true;"
                              class="text-capitalize ml-4">
                              Lihat Kelas ({{ l.program.length }})
                            </v-btn>
                            <v-btn
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 160 : 140"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              height="40"
                              @click="detail = l; dialog.detail = true;"
                              class="text-capitalize white--text ml-4">
                              Info Program
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>
        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import 'moment/locale/id'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          form: false,
          info: false,
          cancel: false,
          product: false,
          detail: false,
          follow: false,
          class: false
        },
        snackbar_warning: false,
        date: {
          start: false,
          end: false
        },
        time: {
          start: false,
          end: false
        },
        status: [
          {
            id: "",
            name: "Semua"
          },
          {
            id: "1",
            name: "Bisa Diikuti"
          },
          {
            id: "2",
            name: "Menunngu Komfirmasi"
          },
          {
            id: "3",
            name: "Sudah Berkhir"
          }
        ],
        filter: {
          title: "",
          search: "",
          status: ""
        },
        detail: {},
        avatar: null,
        form: {
          id: "",
          diskon: "",
          name: "",
          date_start: "",
          time_start: "",
          date_end: "",
          time_end: "",
          platform: "",
          product: [],
          type: 'category',
          active_bool: true
        },
        allDiskon: false,
        selected: {
          product: []
        },
        snackbar: false,
        timeout: 3000,
        deletes: {},
        kelas: [],
        category: [],
        contentEmptyProduct: "",
        contentEmpty: "",
        item_array: [],
        list: [],
        pagination: {},
        process: {
          run: false,
          avatar: false,
          form: false,
          category: false,
          cancel: false,
          detail: false
        },
        success: {
          message: ""
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/discount',
          title: 'Pengaturan Diskon',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      reminder () {
        let now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate())
        return next.toISOString().substr(0, 10)
      },
      reminder_time () {
        let today = new Date()
        return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      },
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    watch: {
      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            this.error.img_url = ''
          }
        },
        deep: true
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {    
      fetch (p) {
        this.process.run = true
        let params = {}

        if (this.filter.status === '1') {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            usable_bool: true,
            apply_ended_bool: false,
            sort: "-1"
          }
        } else if (this.filter.status === '2') {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            'count_program_status_waiting[neq]': 0,
            sort: "-1"
          }
        } else if (this.filter.status === '3') {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            apply_ended_bool: true,
            sort: "-1"
          }
        } else if (this.filter.status === '') {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            sort: "-1"
          }
        }
        this.$axios.$get(`api/publisher/discount_program`, { params })
        .then((response)=>{
          if(response.status === 200) {
            if (this.filter.search !== '' || this.filter.search !== null) {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.process.form = false
            this.pagination = response.results.pagination
            this.process.run = false
          }else{
            this.process.run = false
          }
        })
      },
      fetchClass () {
        this.process.kelas = true
        let params = {
          limit: 10000000000000,
          page: 1,
          id: this.detail.id,
          'price[neq]': '0'
        }
        this.$axios.$get(`api/publisher/discount_program/list_applicable_course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.kelas = false
            this.contentEmptyProduct = "Data belum tersedia"
            this.kelas = response.results.data
          }else{
            this.process.kelas = false
          }
        })
      },
      fetchDetail(item) {
        this.process.detail = true
        let params = {
          id: item
        }
        this.$axios.$get(`api/publisher/discount_program/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      checkAll() {
        if (this.selected.product.length < 1) {
          this.checkFor()
        } else {
          if (this.selected.product.length !== this.kelas.length) {
            this.checkFor()
          } else {
            this.selected.product = []
          }
        }

        this.selected.product = [...new Set(this.selected.product)]
      },
      checkFor() {
        console.log('asd')
        for (let i = 0; i < this.kelas.length; i++) {
          this.selected.product.push(this.kelas[i])
        }
      },
      addClassToDiskon() {
        for (let i = 0; i < this.selected.product.length; i++) {
          this.form.product .push(this.selected.product[i])
        }
        this.addDiskon()
      },
      deleteProduct(index) {
        this.form.product.splice(index, 1)
      },
      async addDiskon() {
        this.error.message = ''
        this.process.form = true
        let item_array = []
        for (let i = 0; i < this.form.product.length; i++) {
          item_array.push(this.form.product[i].id)
        }
        await this.$axios.$post(`api/publisher/discount_program`, 
        {
          id: this.detail.id,
          course_id_array: item_array
        })
        .then((response) => {
          if (response.status === 200) {
            this.selected.product = []
            this.dialog.product = false
            this.detail = response.results.data
            this.dialog.class = true
            this.fetch()
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
    }
  }
</script>

