<template>
  <div>
    <v-container>
      <v-dialog
        persistent
        scrollable
        v-if="dialog.sertifikat"
        v-model="dialog.sertifikat"
        width="850">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="rey--text text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
              Sertifikat - Data Exploration in Data Science using R
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.sertifikat = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-0 pb-0">
            <v-img
              :src="`https://picsum.photos/500/300?image=25`"
              :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: 500px;' : 'height: 250px;'"
              :lazy-src="`https://picsum.photos/500/300?image=25`"
              class="grey lighten-2">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="#4ab762"
              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 220 : 180"
              @click="dialog.sertifikat = false"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-download</v-icon>
                <div class="ml-auto">
                  Unduh Sertifikat
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="red"
              outlined
              width="120"
              @click="dialog.sertifikat = false"
              class="text-capitalize white--text ml-2">
              Tutup
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.checkout"
        v-model="dialog.checkout"
        width="500">
        <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
            Checkout
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                :disabled="process.cart"
                class="text-capitalize px-0"
                @click="dialog.checkout = false; error.message = '';">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-0">
            <div class="px-5 mt-5">
              <v-list flat three-line class="py-0">
                <v-list-item class="px-0">

                  <v-list-item-avatar tile size="75" class="mr-3 my-0">
                    <v-img 
                      style="border-radius: 10px;"
                      :src="detail.img_url">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#4ab762">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <!-- <div class="badge-overlay-premium" v-if="item.sys_kelas_cart_kelas_is_discount">
                        <span class="top-left-premium badge-premium premium font-weight-bold">
                          {{ parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent) }}%
                        </span>
                      </div> -->
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="py-0">
                    <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-n2"
                      :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'fs-16'">
                      {{ detail.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
                    <v-list-item-title>

                      <!-- <div class="text-first body-1 font-weight-bold text-capitalize mt-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''" style="min-height: 48px; margin-bottom: 6px;"> -->
                        <div class="text-decoration-line-through text-second fs-16" v-if="detail.discount_bool">
                          {{ detail.price | price }}
                        </div>
                        <span v-if="detail.discount_bool" class="red--text">
                          (-{{ detail.discount_percent }}%)
                        </span>
                        <span class="font-weight-bold">
                          <span class="" v-if="parseInt(detail.price) > 0">
                            <span v-if="!detail.discount_bool">
                              {{ detail.price | price }}
                            </span>
                            <span v-if="detail.discount_bool">
                              {{ detail.price_discount | price }}
                            </span>
                          </span>

                          <span class="green--text" v-else>
                            Gratis
                          </span>
                        </span>
                      <!-- </div> -->
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <div class="px-5 pb-0">
              <div class="d-flex align-center justify-space-between fs-16 mb-4 mt-5">
                <span class="fs-16 font-weight-bold">Ringkasan Belanja</span>
              </div>

              <div class="d-flex align-center justify-space-between my-3 fs-16">
                <span class="fs-16">Total Harga ({{ selected.cart.length }} Kelas)</span>
                <span class="fs-16 font-weight-medium">{{ parseInt(detail.price) | price}}</span>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center justify-space-between my-3">
                <span class="fs-16">Voucher Diskon <span v-if="Object.keys(voucher).length > 0">({{ voucher.discount_percent }}%)</span></span>
                <span class="fs-16 warning--text">
                  <span v-if="Object.keys(voucher).length > 0" style="font-weight: 900;">-{{ parseInt(voucher.discount) | price }}</span>
                  <span v-if="Object.keys(voucher).length < 1" class="color-blue-first cursor-pointer" @click="dialog.voucher = true; fetchVoucher();">Tambahkan</span>
                </span>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center justify-space-between my-3">
                <span class="fs-16">Biaya Admin</span>
                <span class="fs-16 text-second" style="font-weight: 900;">
                  <span v-if="Object.keys(selected.bank).length > 0">+{{ parseInt(selected.bank.admin_fee) | price }}</span>
                  <span v-if="Object.keys(selected.bank).length < 1">Rp 0</span>
                </span>
              </div>

              <v-divider></v-divider>

              <div class="d-flex align-center justify-space-between mt-3 mb-5">
                <span class="fs-18" style="font-weight: 900;">Total Bayar</span>
                <span class="fs-18" style="font-weight: 900;">
                  <span v-if="Object.keys(voucher).length > 0">
                    {{ selected.bank.admin_fee === undefined ? parseInt(detail.price_discount) - parseInt(voucher.discount) : parseInt(detail.price_discount) - parseInt(voucher.discount) + parseInt(selected.bank.admin_fee) | price }}
                  </span>
                  <span v-if="Object.keys(voucher).length < 1">
                    {{ selected.bank.admin_fee === undefined ? parseInt(detail.price_discount) : parseInt(detail.price_discount) + parseInt(selected.bank.admin_fee) | price }}
                  </span>
                </span>
              </div>
            </div>

            <v-divider style="border-width: 5px; background: #D8D8D8; mix-blend-mode: normal; opacity: 0.2;"></v-divider>

            <div class="px-5">
              <div class="fs-16 taxt-first font-weight-bold text-left" style="margin: 25px 0px 20px 0px">
                Pilih Metode Pembayaran
              </div>
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.bank"
                v-for="(bank, i) in process.bank? 6 : bank" :key="i"
                type="list-item-avatar-two-line">
                <v-list class="py-0 mb_15">
                  <v-list-item class="" style="background: #F9F9F9; border-radius: 6px; padding: 15px 20px;" :style="selected.bank.id === bank.id ? 'border: 1px solid #4ab762' : ''" @click="selected.bank = bank">
                    <v-list-item-content style="max-width: 30px;" class="text-right">
                      <v-icon :color="selected.bank.id === bank.id ? '#4ab762' : 'grey lighten-2'">mdi-check-circle</v-icon>
                    </v-list-item-content>
                    <v-list-item-content class="pt-3 ml-5">
                      <div class="fs-16 font-weight-bold" :style="selected.bank.id === bank.id ? 'color: #4ab762' : 'color: #757575'">
                        {{ bank.label }}
                      </div>
                      <v-list-item-subtitle class="caption mt-1" :style="selected.bank.id === bank.id ? 'color: #757575' : 'color: #BDBDBD'">
                        <div>
                          {{ bank.desc_web }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar
                      width="70"
                      tile
                      style="margin-top: 17px;"
                      height="100%"
                      class="align-self-start">
                      <v-img :src="bank.image" height="30px" class="my-auto" contain></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </v-skeleton-loader>
            </div>
          </v-card-text>
          <v-card-actions style="padding: 15px 20px; display: grid;">
            <!-- <v-spacer></v-spacer> -->
            <div>
              <v-alert 
                type="error" 
                text
                dense
                class="mb-2"
                prominent
                :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
                v-show="error.message.length > 0"
                v-html="error.message">
              </v-alert>
            </div>
            <v-btn
              block
              elevation="0"
              color="#4ab762"
              height="50"
              :loading="process.cart"
              :disabled="process.cart || process.bank || process.checkout || Object.keys(selected.bank).length < 1"
              @click="checkout()"
              class="text-capitalize white--text">
              Checkout
            </v-btn>
            <!-- <v-spacer></v-spacer> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.voucher"
        v-model="dialog.voucher"
        width="450">
        <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
            Pilih Voucher
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.voucher = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-5" style="min-height: 400px;">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.listVoucher"
              v-for="(item, i) in process.listVoucher? 6 : listVoucher" :key="i"
              type="list-item-avatar-two-line">
              <v-list>
                <v-list-item class="px-0" @click="detail_voucher = item; dialog.detail_voucher = true;">
                  <v-list-item-content class="pt-3">
                    <div class="body-2 font-weight-bold">
                      {{ item.name }}
                    </div>
                    <v-list-item-subtitle class="caption mt-1">
                      {{ item.code }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption mt-1">
                      <span class="fs-12 green--text" v-if="detail.price >= item.min_order">
                        Dapat Digunakan
                      </span>
                      <span class="fs-12 red--text" v-if="detail.price < item.min_order">
                        Tidak Dapat Digunakan
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pt-3 text-right">
                    <div class="fs-12 text-second">
                      Diskon
                    </div>
                    <v-list-item-subtitle class="fs-12 mt-1 font-weight-bold primary--text">
                      {{ item.discount_percent }}%
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="fs-12 mt-1">
                      <span v-if="parseInt(item.discount_max) > 0">
                        (s.d {{ item.discount_max | price }})
                      </span>
                      <span v-if="parseInt(item.discount_max) === 0">
                        (s.d -)
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content style="max-width: 30px;" class="text-right">
                    <v-icon color="#4ab762">mdi-chevron-right</v-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider v-if="i < listVoucher.length - 1"/>
            </v-skeleton-loader>
            <div v-if="!process.listVoucher && listVoucher.length < 1" class="text-center my-10">
              <v-img 
                :src="require('@/assets/images/empty.png')"
                :lazy-src="require('@/assets/images/empty.png')"
                class="ml-auto mr-auto"
                max-width="33%">
              </v-img>
              <p class="py-8 text-first body-2 font-weight-bold">Voucher Tidak Tersedia</p>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        v-if="dialog.detail_voucher"
        v-model="dialog.detail_voucher"
        width="450">
        <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <div class="d-flex align-center body-2 px-5 py-5" @click="dialog.detail_voucher = false; dialog.voucher = true;">
            <div class="text-second text--darken-2 font-weight-bold d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
              <v-icon>mdi-arrow-left</v-icon> 
              <div class="ml-2">
                Detail Voucher
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text class="px-0" style="min-height: 400px;">
            <div class="px-5 mt-4">
              <div class="body-2">
                Nama
              </div>
              <div class="body-2 font-weight-bold">
                {{ detail_voucher.name }}
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <div class="body-2">
                Kode
              </div>
              <div class="body-2 font-weight-bold">
                {{ detail_voucher.code }}
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Minimal Pembelian
                  </div>
                  <div class="body-2 font-weight-bold warning--text">
                    {{ detail_voucher.min_order | price }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Diskon
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    <span class="color-blue-first">{{ detail_voucher.discount_percent }}%</span> s.d. {{ detail_voucher.discount_max | price }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Kuota Terpakai
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.quota_used }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Kuota Tersedia
                  </div>
                  <div class="body-2 font-weight-bold color-default">
                    {{ detail_voucher.quota_balance }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Dari Total Kuota
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.quota }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Limit Pengguna
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    <span v-if="parseInt(detail_voucher.user_limit) > 0">
                      <span class="warning--text">{{ detail_voucher.user_limit }}</span>x Penggunaan
                    </span>
                    <span v-if="parseInt(detail_voucher.user_limit) === 0">
                      Tidak Ada limit
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="px-5">
              <v-row>
                <v-col>
                  <div class="body-2">
                    Tanggal Mulai
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.start_datetime |datetimemonth }}
                  </div>
                </v-col>
                <v-col>
                  <div class="body-2">
                    Tanggal Akhir
                  </div>
                  <div class="body-2 font-weight-bold text-first">
                    {{ detail_voucher.end_datetime |datetimemonth }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn
              block
              elevation="0"
              height="48"
              v-if="detail.price >= detail_voucher.min_order"
              color="#4ab762"
              :loading="process.voucher"
              :disabled="process.voucher"
              @click="addVoucher()"
              class="text-capitalize white--text">
              Pakai Promo
            </v-btn>
            <v-btn
              block
              elevation="0"
              height="48"
              @click="dialog.detail_voucher = false; dialog.voucher = true;"
              v-if="detail.price < detail_voucher.min_order"
              color="red"
              class="text-capitalize white--text">
              Promo Tidak dapat Digunakan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.success"
        width="430"
        :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
        persistent>
        <v-card 
          height="530"
          color="#fff"
          :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
          <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

            <v-col cols="12" class="pa-0">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
                <v-img
                  class="cursor-pointer"
                  contain
                  :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                  :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                  :src="require('@/assets/icon/logo.png')"
                  :lazy-src="require('@/assets/icon/logo.png')">
                </v-img>
                <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                  <v-btn
                    icon
                    @click="dialog.success = false; "
                    elevation="0"
                    class="color-green-second">
                    <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-card-text>
              </div>
              <!-- <v-img
                class="cursor-pointer"
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                style="position: absolute; left: 25px; top: 25px;"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
                <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; " color="#4ab762">mdi-close-circle-outline</v-icon>
              </v-card-text> -->
              <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
                <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                  Tambah Keranjang
                </div>
                <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
                <div class="pt-10 pb-5">
                  <v-img
                    :src="require('@/assets/payment/img_payment_success.png')" 
                    width="250"
                    class="mx-auto"
                    height="fit-content">
                  </v-img>
                </div>
                <div class="text-second title text-center mb-5">
                  {{ success.message }}
                </div>
                <div class="text-center d-flex alogn-center justify-center">
                  <v-btn
                    width="175"
                    color="#4ab762"
                    elevation="0"
                    to="/cart"
                    class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                    Lihat Keranjang
                  </v-btn>
                  <v-btn
                    width="100"
                    color="red"
                    outlined
                    elevation="0"
                    @click="dialog.success = false"
                    class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                    Tutup
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        scrollable
        v-if="dialog.buy"
        v-model="dialog.buy"
        width="420">
        <v-card class="box-shadow border-radius">
          <div class="d-flex align-center body-2 px-5 py-5">
            <div class="rey--text text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
              Beli Lagi Kelas Ini
            </div>
            <div class="ml-auto">
              <v-btn
                width="25"
                height="25"
                color="white"
                elevation="0"
                fab
                class="text-capitalize px-0"
                @click="dialog.buy = false;">
                <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> 
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-second text--darken-2 font-weight-reguler pt-8 pb-3 text-center" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'title' : 'body-2'">
              Apakah anda yakin ingin membeli kembali kelas ini, atau tambahkan ke keranjang?
            </div>
            <v-alert 
              type="error" 
              text
              dense
              class="mb-0"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="#4ab762"
              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 220 : 180"
              @click="dialog.checkout = true; fetchBank(); dialog.buy = false;"
              :disabled="process.cart"
              class="text-capitalize white--text mx-1 px-4">
              <div class="d-flex align-center" style="width: 100%;">
                <v-icon>mdi-check-circle-outline</v-icon>
                <div class="ml-auto">
                  Beli Langsung
                </div>
              </div>
            </v-btn>

            <v-btn
              elevation="0"
              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
              color="green"
              outlined
              :disabled="process.cart"
              :loading="process.cart"
              width="120"
              @click="addCart()"
              class="text-capitalize white--text ml-2">
              <v-icon>mdi-plus</v-icon>
              Keranjang
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <section>
        <v-row class="mb-7">
          <v-col cols="9" class="pt-0">
            <!-- <div class="text-first font-weight-bold" :class="fps === 'xs' || fps === 'sm' ? 'body-1' : 'title'">
              Pengaturan Profil & Toko
            </div>
            <div class="text-second" :class="fps === 'xs' || fps === 'sm' ? 'caption' : 'body-2'">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div> -->
          </v-col>
          <v-col cols="3" class="text-right pt-0">
            <!-- <div class="d-flex align-center justify-end">
              <div>
                <v-avatar :size="fps !== 'xs' && fps !== 'sm' ? 25 : 18" tile>
                  <v-img :src="require('@/assets/icon/icon_poin.svg')" contain></v-img>
                </v-avatar>
              </div>
              <div>
                <div class="text-first font-weight-bold" :class="fps !== 'xs' && fps !== 'sm' ? 'text-h5' : 'body-1'">1.515</div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <div :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-5' : 'd-flex align-center mb_30'">
          <div class="text-second d-flex align-center" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 60%'">
            <v-text-field
              solo
              hide-details
              clearable
              color="#4ab762"
              v-model="filter.search"
              v-on:keyup.enter="fetch()"
              @click:clear="filter.search = ''; fetch();"
              :placeholder="`Cari kelas…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <!-- <v-btn
              color="#3498db"
              elevation="0"
              height="45"
              width="120"
              @click="is_redeem = false; dialog.redeem = true"
              class="text-capitalize white--text ml-6">
              Redeem
            </v-btn> -->
          </div>
          <div class="text-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-2 caption mb-6 display-none' : 'ml-auto text-right'" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'width: 100%' : 'width: 40%'">
            Menampilkan <span class="font-weight-bold mx-1">{{ pagination.start }} - {{ pagination.end }} dari {{ pagination.total_data }}</span> data kelas
          </div>
        </div>
        <div v-if="process.run" class="mt-6">
          <v-row class="mb-8 px-4" v-for="k in 10" :key="k">
            <v-col cols="12" md="7" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pl-0' : 'pt-0'">
              <v-list three-line style="background: transparent;" class="py-0 px-0">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    size="100"
                    tile>
                    <v-skeleton-loader
                      style="width: 100px; height: 100px;"
                      type="image"
                      :loading="process.run"
                    ></v-skeleton-loader>
                  </v-list-item-avatar>
                  <v-list-item-content class="pb-0 pt-1">
                    <v-list-item-subtitle class="font-weight-bold body-1 text-first text--darken-2 mt-n8" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                      <v-skeleton-loader
                        :loading="process.run"
                        style="padding-left: 0px;"
                        type="list-item">
                      </v-skeleton-loader>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-skeleton-loader
                style="width: 100px; height: 40px;"
                :loading="process.run"
                type="image">
              </v-skeleton-loader> -->
            </v-col>
            <v-col cols="12" md="5" class="mt-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="process.run"
                type="list-item">
              </v-skeleton-loader>
              <div class="text-right ml-auto mt-2 d-flex justify-end">
                <v-skeleton-loader
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="ml-5"
                  :loading="process.run"
                  type="button">
                </v-skeleton-loader>
                <v-skeleton-loader
                  :loading="process.run"
                  class="ml-5"
                  type="button">
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!process.run">
          <div v-for="(l, s) in list" :key="s" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'mt-6' : ''">
            <v-list class="py-0">
              <v-list-item class="mb-4 hover_select" style="background: #f9f9f9; border-radius: 15px; padding: 12px 20px 22px 20px; border: 1px solid #f9f9f9;">
                <v-list-item-content class="py-0">
                  <div class="d-flex align-center" style="flex-wrap: inherit;">
                    <div class="d-flex align-center">
                      <v-list-item class="px-0 pb-2" style="min-height: 20px;">
                      <v-list-item-avatar size="25" color="#ebebeb">
                        <v-img :src="l.organization_img_url" v-if="l.organization_img_url !== ''"></v-img>
                        <v-icon v-if="l.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                      </v-list-item-avatar>
          
                      <v-list-item-content class="pt-1 pb-0">
                        <v-list-item-title class="text-first font-weight-bold" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'">{{ l.organization_name }} </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    </div>
                    <div class="text-second text--darken-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1 ml-auto' : 'caption pr-2'">
                      <div v-if="!l.access_expired_bool">
                        <span class="font-weight-bold color-first">Akses Selamanya</span>
                      </div>
                      <div v-if="l.access_expired_bool">
                        <span class="" v-if="l.accessable_bool">
                          Akses berakhir pada <span class="font-weight-bold warning--text"> {{ l.expired_datetime | datetimemonth}} </span>
                        </span>
                        <span class="font-weight-bold red--text" v-if="!l.accessable_bool">
                          Akses Berakhir (Telah Expired)
                        </span>
                      </div>
                    </div>
                  </div>
                  <v-row class="pt-5 pb-2">
                    <!-- <v-col cols="2" class="pr-0">
                      <v-list style="background: transparent;">
                        <v-list-item class="py-0 px-0">
                          <v-list-item-avatar
                            size="100"
                            tile
                            class="mt-0">
                            <v-img 
                              :src="l.img_url" style="border-radius: 15px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-col> -->
                    <v-col cols="12" class="py-0">
                      <v-row>
                        <v-col cols="12" md="5" class="pl-0 pt-0">
                          <v-list style="background: transparent;">
                            <v-list-item class="py-0">
                              <v-list-item-avatar
                                size="100"
                                tile>
                                <v-img 
                                  :src="l.img_url" style="border-radius: 15px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>
                              <v-list-item-content class="py-0">
                                <div class="font-weight-bold two-line body-1 text-first text--darken-2" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-18' : 'caption'" :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: 64px;' : 'height: 52px;'">
                                  {{ l.name }}
                                </div>
                                <div class="d-flex align-center text-second" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'mt-3'">
                                  <div class="text-first font-weight-bold" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                    {{ l.review_average }}
                                  </div>
                                  <div class="ml-2">
                                    <v-rating
                                      :value="parseInt(l.review_average)"
                                      background-color="grey"
                                      color="#e67e22"
                                      dense
                                      readonly
                                      half-increments
                                      hover
                                      :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 20 : 15">
                                    </v-rating>
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col cols="12" md="7" class="pt-0">
                          <div class="text-first body-1 font-weight-bold d-flex align-center" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'justify-end' : 'mt-n2'">
                            <v-list style="background: transparent;">
                              <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0">
                                <v-list-item-content class="py-0">
                                  <div class="font-weight-bold body-1 text-second text-right text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                    <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                      Bab
                                    </div>
                                    {{ l.content_count }}
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-list style="background: transparent;" class="ml-4">
                              <v-list-item  style="border-right: 1px solid #ebebeb;" class="py-0">
                                <v-list-item-content class="py-0">
                                  <div class="font-weight-bold body-1 text-second text-right text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                    <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                      Materi
                                    </div>
                                    {{ l.content_sub_count }}
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-list style="background: transparent;" class="ml-4">
                              <v-list-item class="py-0">
                                <v-list-item-content class="py-0">
                                  <div class="font-weight-bold body-1 text-second text-right text--darken-2 " :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                    <div class="text-second text--darken-2 mb-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2' : 'caption'">
                                      Progress
                                    </div>
                                    <div :class="parseInt(l.proggress) <= 25 ? 'red--text' : parseInt(l.proggress) > 25 && parseInt(l.proggress) <= 50 ? 'warning--text' : parseInt(l.proggress) > 50 && parseInt(l.proggress) <= 75  ? 'primary--text' : 'green--text'">
                                      {{ parseInt(l.proggress) }}%
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                          <div class="d-flex align-center justify-end" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'mt-5' : 'mt-3 body-2'">
                            <div v-if="!l.accessable_bool && l.available_bool">
                              <span class="text-decoration-line-through font-weight-bold text-second mr-1" v-if="parseInt(l.discount_percent) > 0">
                                {{ l.price | price }}
                              </span>
                              <span class="red--text mr-1 font-weight-bold" v-if="parseInt(l.discount_percent) > 0">
                                ({{ parseInt(l.discount_percent) }}%)
                              </span>
                              <span class="font-weight-bold text-first text--darken-2">
                                {{ l.price_discount | price }}
                              </span>
                            </div>
                            <!-- <v-btn
                              outlined
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#9e9e9e"
                              height="40"
                              v-if="parseInt(l.proggress) == 100 && (!l.access_expired_bool || !(!l.accessable_bool && l.available_bool) || (!l.access_expired_bool || !l.available_bool))"
                              @click="dialog.sertifikat = true"
                              class="text-capitalize">
                              Lihat Sertifikat
                            </v-btn> -->
                            <v-btn
                              outlined
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#9e9e9e"
                              height="40"
                              v-if="l.certificate_available_bool"
                              @click="dialog.sertifikat = true"
                              class="text-capitalize">
                              Lihat Sertifikat
                            </v-btn>
                            <v-btn
                              outlined
                              elevation="0"
                              height="40"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              v-if="!l.accessable_bool && l.available_bool"
                              @click="detail = l; dialog.buy = true;"
                              class="text-capitalize ml-3">
                              Beli Lagi Kelas Ini
                            </v-btn>
                            <v-btn
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              height="40"
                              :to="`/collection/${l.id}`"
                              v-if="l.accessable_bool && parseInt(l.proggress) > 0 && parseInt(l.proggress) < 100"
                              class="text-capitalize white--text ml-4">
                              Lanjutkan Belajar
                            </v-btn>
                            <v-btn
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              height="40"
                              :to="`/collection/${l.id}`"
                              v-if="l.accessable_bool && parseInt(l.proggress) === 0"
                              class="text-capitalize white--text ml-4">
                              Mulai Belajar
                            </v-btn>
                            <v-btn
                              elevation="0"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 130"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#4ab762"
                              height="40"
                              :to="`/collection/${l.id}`"
                              v-if="l.accessable_bool && parseInt(l.proggress) == 100"
                              class="text-capitalize white--text ml-4">
                              Belajar Kembali
                            </v-btn>
                            <div class="warning--text ml-8 fs-16" style="height: 40px; align-items: center; display: flex;" v-if="l.access_expired_bool && (!l.access_expired_bool || !l.available_bool)">
                              <i>Kelas tidak lagi tersedia</i>
                            </div>
                          </div>
                        </v-col>
                        <!-- <v-col cols="4" class="pt-0 pl-0">
                          <div class="d-flex align-center text-second">
                            <div class="text-first font-weight-bold body-2">
                              {{ l.review_average }}
                            </div>
                            <div class="ml-2">
                              <v-rating
                                :value="5"
                                background-color="white"
                                color="#e67e22"
                                dense
                                half-increments
                                hover
                                size="20">
                              </v-rating>
                            </div>
                          </div>
                        </v-col> -->
                        <!-- <v-col cols="8" class="pt-0">
                        </v-col> -->
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- <div>
                    <div class="text-right">
                      <v-icon size="20" color="red" @click="dialog.cancel = true">mdi-block-helper</v-icon>
                      <v-btn
                        outlined
                        elevation="0"
                        width="180"
                        color="#9e9e9e"
                        class="text-capitalize ml-3">
                        Lihat Detail
                      </v-btn>
                    </div>
                  </div> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="text-right mt-16" v-if="!process.run && list.length < 1">
            <Empty v-model="contentEmpty"/>
          </div>
        </div>

        <Pagination 
          @changePage="fetch($event)" 
          :model="pagination"
          class="pt-6"
          v-show="pagination.total_page > 1"/>
      </section>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          search: ""
        },
        dialog: {
          buy: false,
          cancel: false,
          sertifikat: false,
          success: false,
          player: false,
          success_pay: false,
          pay: false,
          voucher: false,
          detail_voucher: false,
          checkout: false
        },
        listVoucher: [],
        detail_voucher: {},
        selected: {
          cart: [],
          delete: '',
          pagination: 1,
          bank: {}
        },
        bank: {},
        voucher: {},
        panel: 0,
        timeout: 2000,
        snackbar: false,
        contentEmpty: "",
        pagination: "",
        list: [],
        panel: 0,
        detail: {},
        process: {
          detail: false,
          run: true,
          cancel: false,
          cart: false,
          listVoucher: false,
          bank: false,
          voucher: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/account/collection',
          title: 'Koleksi Kelas',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import('@/components/DataEmpty'),
      ImageInput: () => import('@/components/ImageInput'),
      Pagination: () => import('@/components/Pagination'),
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetch()
    },
    watch: {
      // call again the method if the route changes
      '$route': function(val) {
        this.fetch()
      },
    },
    mounted () {
      // this.fetch()
      // this.fetchDetail()
    },
    methods: {
      fetch (p) {
        this.process.run = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          search: this.filter.search,
          // 'proggress[lt]': 100,
          // accessable_bool: false
          sort: '-id',
          // status: this.$route.query.status !== undefined ? this.$route.query.status : ''
        }

        if (this.$route.query.status !== undefined && this.$route.query.status === 'selesai') {
          params.proggress = 100,
          params.accessable_bool = true
        }

        if (this.$route.query.status !== undefined && this.$route.query.status === 'progres') {
          params = {
            limit: 10,
            page: p === undefined ? 1 : p,
            search: this.filter.search,
            'proggress[lt]': 100,
            accessable_bool: true
            // sort: '-created_datetime',
            // status: this.$route.query.status !== undefined ? this.$route.query.status : ''
          }
        }

        if (this.$route.query.status !== undefined && this.$route.query.status === 'berakhir') {
          params.accessable_bool = false
        }

        this.$axios.$get(`api/my_course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.run = false
            if (this.filter.search !== '' || this.filter.search !== null) {
              this.contentEmpty = "Data tidak tersedia"
            } else {
              this.contentEmpty = "Data belum tersedia"
            }
            this.list = response.results.data
            this.pagination = response.results.pagination
          }else{
            this.process.run = false
          }
        })
      },
      fetchDetail(item) {
        this.process.detail = true
        let params = {
          id: item
        }
        this.$axios.$get(`api/transaction/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
          }else{
            this.process.detail = false
          }
        })
      },
      async copyTestingCode(text) {
        try {
          const toCopy = text
          await navigator.clipboard.writeText(toCopy);
          console.log('Text or Page URL copied');
          this.success.message = "Berhasil dicopy"
          this.snackbar = true
        }
        catch (err) {
          console.error('Failed to copy: ', err);
        }
      },
      
      async addCart () {
        this.process.cart = true
        await this.$axios.$post(`api/cart`, 
        {
          course_id: this.detail.course_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.dialog.buy = false
            this.success.message = "Berhasil menambahkab kelas ini ke keranjang"
            this.dialog.success = true
            this.process.cart = false
            this.$store.commit('header/setCart', this.headers.cart + 1)
          } else {
            this.process.cart = false
            this.error.message = response.message
          }
        })
      },


      fetchBank () {
        this.process.bank = true
        this.$axios.$get(`api/midtrans/payment_methods`)
        .then((response)=>{
          if(response.status === 200) {
            this.bank = response.results.data
            this.process.bank = false
          }else{
            this.process.bank = false
          }
        })
      },

      fetchVoucher () {
        this.process.listVoucher = true
        this.$axios.$post(`api/cart/list_applicable_coupon`,
        {
          course_id_array: this.detail.course_id
        })
        .then((response)=>{
          if(response.status === 200) {
            this.contentEmpty = "Data belum tersedia"
            this.listVoucher = response.results.data
            this.process.listVoucher = false
          }else{
            this.process.listVoucher = false
          }
        })
      },

      async addVoucher() {
        this.error.voucher = ""
        this.process.voucher = true
        await this.$axios.$post(`api/cart/apply_coupon`, 
        {
          code: this.detail_voucher.code,
          course_id_array: [this.detail.course_id]
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.voucher = false
            this.voucher = response.results.data
            this.dialog.detail_voucher = false
            this.dialog.voucher = false
          } else {
            this.process.voucher = false
            if (response.message === 'Voucher tidak valid.') {
              this.error.voucher = "Voucher Belum Tersedia"
            } else {
              this.error.voucher = response.message
            }
          }
        })
      },
      async checkout() {
        this.process.cart = true
        await this.$axios.$post(`api/cart`, 
        {
          course_id: this.detail.course_id
        })
        .then((response) => {
        })
        this.error.message = ''
        await this.$axios.$post(`api//midtrans/checkout`, {
          course_id_array: [this.detail.course_id],
          payment_method_object: parseInt(this.detail.price_discount) === 0 ? {} : this.selected.bank,
          total_paid_nett: parseInt(this.detail.price_discount) === 0 ? parseInt(this.detail.price_discount) : Object.keys(this.voucher).length > 0 ? parseInt(this.detail.price_discount) - parseInt(this.voucher.discount) + parseInt(this.selected.bank.admin_fee) : parseInt(this.detail.price_discount) + parseInt(this.selected.bank.admin_fee),
          coupon_code: Object.keys(this.voucher).length > 0 ? this.voucher.code : ''
        })
        .then((response) => {
          if (response.status === 200) {
            let class_in_cart = false
            if (this.cart.length > 0) {
              for (let i = 0; i < this.cart.length; i++) {
                if (this.cart[i].course_id === this.detail.course_id) {
                  this.toDeleteCart(this.cart[i].id)
                  class_in_cart = true
                }
              }

              if (class_in_cart === false) {
                this.process.cart = false
                if (parseInt(this.detail.price_discount) === 0) {
                  this.dialog.success_pay = true
                  this.dialog.pay = false
                  this.dialog.checkout = false
                  this.success.message = "Berhasil membeli kelas ini"
                } else {
                  window.location= `/purchasing`
                }
              }
            } else {
              if (parseInt(this.detail.price_discount) === 0) {
                this.dialog.success_pay = true
                this.dialog.pay = false
                this.dialog.checkout = false
                this.success.message = "Berhasil membeli kelas ini"
              } else {
              window.location= `/purchasing`
            }
              this.process.cart = false
            }
          } else {
            // this.dialog.success_pay = true
            // this.dialog.pay = false
            // this.dialog.checkout = false
            this.process.cart = false
            this.error.message = response.message
          }
        })
      },
      async toDeleteCart(item) {
        await this.$axios.$post(`api/cart/delete`, 
        {
          id: item
        })
        .then((response) => {
          if (response.status === 200) {
            if (parseInt(this.detail.price_discount) === 0) {
              this.dialog.success_pay = true
              this.dialog.pay = false
              this.dialog.checkout = false
              this.success.message = "Berhasil membeli kelas ini"
            } else {
              window.location= `/purchasing`
            }
            this.process.cart = false
          } else {
            this.process.cart = false
            this.error.message = response.message
          }
        })
      },
    }
  }
</script>
