<template>
  <div>
    <v-dialog
      persistent
      scrollable
      v-if="dialog.form"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      v-model="dialog.form"
      width="650">
      <v-card :class=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="body-1 text-second text--darken-2 font-weight-bold">
            {{ contentdialog.title }}
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.form || process.avatar"
              class="text-capitalize px-0"
              @click="$emit('close'); dialog.form = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text style="min-height: calc(100vh - 300px);" class="mt_25">
          <ValidationObserver ref="observer">
            <div class="d-flex">
              <div>
                <v-img
                  :src="form.img_url"
                  :lazy-src="form.img_url"
                  v-if="form.img_url !== ''"
                  :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                  :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 180 : 140"
                  aspect-ratio="1"
                  style="border-radius: 15px;"
                  class="grey lighten-2">
                  <!-- <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template> -->
                  <v-progress-linear
                    color="#4ab762"
                    rounded
                    indeterminate
                    height="6"
                    v-if="process.avatar"
                    class="mx-auto"
                    style="width: 60%; margin-top: 50%;">
                  </v-progress-linear>
                </v-img>
                <div v-if="form.img_url === ''">
                  <div style="background-color: #ededed; color: #fff; width: 160px; height: 160px;" class="rounded-lg" :style="process.avatar ? 'padding-top: 90px' : ''">
                    <v-row
                      class="fill-height ma-0"
                      v-if="!process.avatar"
                      :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h3' : ''"
                      align="center"
                      justify="center"
                    >
                      <v-icon large>mdi-camera</v-icon>
                    </v-row>
                    <v-progress-linear
                      color="#4ab762"
                      rounded
                      indeterminate
                      height="6"
                      v-if="process.avatar"
                      class="mx-auto"
                      style="width: 60%;">
                    </v-progress-linear>
                  </div>
                </div>
              </div>
              <div class="pl-8" :style=" fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'width: 100%;' : 'width: 100%;'">
                <div class="d-flex align-center">
                  <image-input v-model="avatar" style="height: 48px; width: 100%;">
                    <div slot="activator">
                      <v-btn
                        outlined
                        :disabled="process.avatar"
                        height="45"
                        width="217"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="#5050505e"
                        class="text-capitalize">
                        Pilih Sampul Materi
                      </v-btn>
                    </div>
                  </image-input>
                  <v-btn
                    :disabled="process.avatar"
                    height="45"
                    icon
                    v-if="form.img_url !== ''"
                    @click="form.img_url = ''"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                    color="red"
                    class="text-capitalize">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>


                <div class="mt-3 text-second text-darken-1" style="font-size: 11px;">
                  Besar file: maksimum 10.000.000 bytes
                  (10 Megabytes). Ekstensi file yang
                  diperbolehkan: .JPG .JPEG .PNG.
                  Dengan dimensi terbaik square (contoh:
                  256px x 256px)
                </div>
                <p
                  style="font-size: 9px;"
                  class="mt-1 mb-0 color-first pr-2">
                  Optional
                </p>
              </div>
            </div>
            <div class="d-flex align-center text-second mt_33">
              <div class="fs-16">
                Nama
              </div>
            </div>
            <ValidationProvider :name="`Nama Materi`" rules="required|max:50" v-slot="{ errors }">
              <v-text-field
                color="#4ab762"
                class="pt-0"
                :counter="50"
                placeholder="Tuliskan disini"
                v-on:keyup.enter="addMateri()"
                v-model="form.title"
                :messages="'Wajib'"
                :error-messages="errors"
                require>
                <template v-slot:message="{ message }">
                  <span class="red--text" v-html="message"></span>
                </template>
                <template v-slot:counter="{ props }">
                  <span style="font-size: 9px;" class="text-second">
                    {{ props.value }} dari {{ props.max }} karakter
                  </span>
                </template>
              </v-text-field>
            </ValidationProvider>
            <div class="d-flex align-center text-second mt_30">
              <div class="fs-16">
                Deskripsi
              </div>
            </div>
            <ValidationProvider :name="`Deskripsi Materi`" rules="max:1000" v-slot="{ errors }">
              <v-textarea
                color="#4ab762"
                class="pt-0"
                rows="2"
                :messages="'Optional'"
                :counter="1000"
                v-model="form.description"
                :error-messages="errors"
                require
                placeholder="Tuliskan disini">
                <template v-slot:message="{ message }">
                  <span :class="message !== 'Optional' ? 'red--text' : 'color-first'" v-html="message"></span>
                </template>
                <template v-slot:counter="{ props }">
                  <span style="font-size: 9px;" class="text-second">
                    {{ props.value | price_not_rp }} dari {{ props.max | price_not_rp }} karakter
                  </span>
                </template>
              </v-textarea>
            </ValidationProvider>
            <v-checkbox color="#4ab762" v-model="form.preview" class="mt_15" :disabled="form.file_url === ''">
              <template v-slot:label>
                <div class="text-second fs-14">
                  Bab merupakan bab preview (untuk mengubah pastikan Anda sudah memasukan file)
                </div>
              </template>
            </v-checkbox>
            <div style="font-size: 16.5px; width: 100%;" class="mt_15 mb_15 d-flex align-center">
              <div>
                Isi (file, video)
              </div>
              <div class="ml-auto">
                <v-btn
                  :disabled="process.file"
                  height="45"
                  icon
                  v-if="form.file_url !== ''"
                  @click="form.file_url = ''; form.preview = false;"
                  :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                  color="red"
                  class="text-capitalize">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex">
              <div style="width: 165px;">
                <div v-if="form.file_url === ''">
                  <div style="background-color: #ededed; color: #fff; width: 165px; height: 165px; border-radius: 7px;" class="" :style="process.file ? 'padding-top: 80px' : ''">
                    <v-row
                      class="ma-0"
                      v-if="!process.file"
                      align="center"
                      justify="center"
                    >
                      <div class="warning--text text-center fs-10 d-flex align-center justify-center" style="padding: 12px 19px; height: 165px;">
                        Anda belum menambahkan isi form. Pilih video atau file (.pdf)
                      </div>
                    </v-row>
                    <v-progress-linear
                      color="#4ab762"
                      rounded
                      indeterminate
                      height="6"
                      v-if="process.file"
                      class="mx-auto"
                      style="width: 60%;">
                    </v-progress-linear>
                  </div>
                </div>
                <div v-if="form.file_url !== ''">
                  <video width="165" height="165" controls v-if="form.type === 'video'">
                    <source :src="form.file_url">
                    Your browser does not support the video tag.
                  </video>
                  <audio class="my_audio" controls preload="none" v-if="form.type === 'audio'">
                    <source :src="form.file_url">
                  </audio>
                  <div v-if="form.type === 'docs'">
                    <iframe 
                      width="165" 
                      height="165"
                      :src="form.file_url">
                    </iframe>
                  </div>
                </div>
              </div>
              <div class="ml-auto pl-5">
                <div class="d-flex align-center">
                  <video-input v-model="video" style="height: 30px; width: 179px;">
                    <div slot="activator">
                      <v-btn
                        elevation="0"
                        color="#C3C3C3"
                        height="30"
                        width="179"
                        :disabled="process.file"
                        outlined
                        class="text-capitalize caption">
                        <div class="d-flex align-center justify-center" style="width: 100%;">
                          <v-icon small>mdi-plus</v-icon>
                          <div class="ml-1">
                            <span v-if="form.file_url === ''">Tambah</span> 
                            <span v-if="form.file_url !== ''">Ganti</span> Video
                          </div>
                        </div>
                      </v-btn>
                    </div>
                  </video-input>
                  <div class="caption text-second text-center" style="padding: 2px 15px;">
                    atau
                  </div>
                  <pdf-input v-model="pdf" style="height: 30px; width: 179px;">
                    <div slot="activator">
                      <v-btn
                        elevation="0"
                        color="#C3C3C3"
                        height="30"
                        width="179"
                        :disabled="process.file"
                        outlined
                        class="text-capitalize caption">
                        <div class="d-flex align-center justify-center" style="width: 100%;">
                          <v-icon small>mdi-plus</v-icon>
                          <div class="ml-1">
                            <span v-if="form.file_url === ''">Tambah</span> 
                            <span v-if="form.file_url !== ''">Ganti</span> File (.pdf)
                          </div>
                        </div>
                      </v-btn>
                    </div>
                  </pdf-input>
                </div>


                <div class="mt-1 text-second text-darken-1" style="font-size: 11px;">
                  Pilih tambah video jika isi materi adalah video dan pilih tambah file jika isi materi adalah file pdf.
                </div>

                <div style="margin-top: 10px;">
                  Isi materi hanya bisa satu file, video saja atau file saja.
                </div>
                <p
                  style="font-size: 9px;"
                  class="mt-0 mb-0 color-first pr-2">
                  Optional
                </p>
              </div>
            </div>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <!-- <v-btn
            elevation="0"
            color="grey darken-4"
            outlined
            width="120"
            @click="dialog.metode = true"
            class="text-capitalize white--text mr-2">
            Cara Lain
          </v-btn> -->
          <v-btn
            elevation="0"
            color="#4ab762"
            width="200"
            :disabled="process.form || process.avatar || process.file"
            :loading="process.form"
            @click="addMateri()"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                <span v-if="contentdialog.type === 'add'">Tambahkan</span><span v-if="contentdialog.type === 'edit'">Simpan</span>
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            :disabled="process.form || process.avatar || process.file"
            width="120"
            @click="dialog.form = false"
            class="text-capitalize white--text mr-2">
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          form: false
        },
        avatar: null,
        video: null,
        pdf: null,
        contentdialog: {},
        form:[],
        process: {
          run: false,
          form: false,
          file: false,
          avatar: false
        }
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      ImageInput: () => import('@/components/ImageInput'),
      VideoInput: () => import('@/components/VideoInput'),
      PdfInput: () => import('@/components/PdfInput')
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      }
    },
    props: {
      formInput: {
        type: Object,
        default: () => {}
      },
      dialogopen: {
        type: Boolean,
        default: false
      },
      detailCOntenDialog: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      'formInput': function(val) {
        this.form = val
      },
      'dialogopen': function(val) {
        this.dialog.form = val
      },
      'detailCOntenDialog': function(val) {
        this.contentdialog = val
      },


      avatar: {
        handler: function () {
          this.process.avatar = this.avatar.process 
          if (!process.avatar){
            this.form.img_url = this.avatar ? this.avatar.fileuri : ''
            // this.error.img_url = ''
          }
        },
        deep: true
      },

      video: {
        handler: function() {
          this.form.file_url = this.video.file !== undefined ? this.video.file.fileuri : ''
          this.form.type = this.video.filetype !== undefined ? this.video.filetype : ''
          this.process.file = this.video.process
        },
        deep: true,
      },

      pdf: {
        handler: function() {
          console.log(this.pdf.file)
          this.form.file_url = this.pdf.file !== undefined ? this.pdf.file.fileuri : ''
          this.form.type = this.pdf.filetype !== undefined ? this.pdf.filetype : ''
          this.process.file = this.pdf.process
        },
        deep: true,
      },
    },
    mounted () {
      this.process.form = false
      this.form = this.$props.formInput
      this.contentdialog = this.$props.detailCOntenDialog
    },
    methods: {
      async addMateri () {
        const isValid = await this.$refs.observer.validate()
        // const valids = this.validation()
        if (isValid) {
          this.$emit('close')
          this.$emit('addData', this.form)
        }
      }
    },
  };
</script>