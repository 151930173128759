<template>
  <div>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="4">
          <v-autocomplete
            :items="days"
            attac
            label="Tangal"
            solo
            hide-details="true"
            single-line
            color="#4ab762"
            v-model="day"
            return-object>
            <template v-slot:selection="{ item }">
              {{ item }}
            </template>
            <template v-slot:append>
              <v-icon color="#7B7B7B">mdi-chevron-down</v-icon>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" class="px-0">
          <v-autocomplete
            :items="months"
            attac
            label="Bulan"
            solo
            hide-details="true"
            single-line
            item-text="name"
            item-value="id"
            color="#4ab762"
            v-model="mounth">
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:append>
              <v-icon color="#7B7B7B">mdi-chevron-down</v-icon>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            :items="years"
            attac
            single-line
            label="Tahun"
            solo
            hide-details="true"
            color="#4ab762"
            v-model="year"
            return-object>
            <template v-slot:selection="{ item }">
              {{ item }}
            </template>
            <template v-slot:append>
              <v-icon color="#7B7B7B">mdi-chevron-down</v-icon>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- <div class="d-flex align-center">
        <span style="width: 125px;">
        </span>
        <span style="width: 130px;" class="mx-3">
        </span>
        <span style="width: 120px;">
        </span>
      </div> -->
    </v-card-text>
    <!-- <v-card-text class="pa-0">
      <div class="d-flex align-center">
        <span style="width: 75px;">
          <v-autocomplete
            :items="days"
            attach
            outlined
            label="Tgl"
            dense
            hide-details="true"
            single-line
            :readonly="lock"
            :disabled="lock"
            color="#FF3177"
            v-model="day"
            return-object>
            <template v-slot:selection="{ item }">
              {{ item }}
            </template>
          </v-autocomplete>
        </span>
        <span style="width: 120px;" class="mx-1">
          <v-autocomplete
            :items="months"
            attach
            outlined
            label="Bulan"
            dense
            hide-details="true"
            single-line
            :readonly="lock"
            :disabled="lock"
            item-text="name"
            item-value="id"
            color="#FF3177"
            v-model="mounth">
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </span>
        <span style="width: 85px;">
          <v-autocomplete
            :items="years"
            attach
            outlined
            single-line
            label="Tahun"
            dense
            hide-details="true"
            :readonly="lock"
            :disabled="lock"
            color="#FF3177"
            v-model="year"
            return-object>
            <template v-slot:selection="{ item }">
              {{ item }}
            </template>
          </v-autocomplete>
        </span>
      </div>
    </v-card-text> -->
  </div>
</template>

<script>
  import moment from "moment";
  export default {
    data: () => ({
      year: "",
      day: "",
      mounth: "",
      mounth_id: "",
      months: [
        {
          id: 1,
          name: 'Januari'
        },
        {
          id: 2,
          name: 'Februari'
        },
        {
          id: 3,
          name: 'Maret'
        },
        {
          id: 4,
          name: 'April'
        },
        {
          id: 5,
          name: 'Mei'
        },
        {
          id: 6,
          name: 'Juni'
        },
        {
          id: 7,
          name: 'Juli'
        },
        {
          id: 8,
          name: 'Agustus'
        },
        {
          id: 9,
          name: 'September'
        },
        {
          id: 10,
          name: 'Oktober'
        },
        {
          id: 11,
          name: 'November'
        },
        {
          id: 12,
          name: 'Desember'
        }
      ],
      clear: false,
      years: [],
      days:[]
    }),
    props: {
      value: String,
    },
    computed: {
      lock () {
        return this.$attrs.lock
      }
    },
    created() {
      let temp = []
      var maxYear = new Date().getFullYear() + 50;
      var minYear = 1800
      for (let i = minYear; i<=maxYear; i++) {
        temp.push(i)
      }
      this.years = temp

      let day = []
      for (let i = 1; i <= 31; i++) {
        day.push(i.toString().padStart(2,"0"))
      }
      this.days = day

    },
    watch: {
      value: function (val) {
        this.toDate(val);
      },
      day: function (val) {
        this.changeDate();
      },
      mounth: function (val) {
        this.changeDate();
      },
      year: function (val) {
        this.changeDate();
      },
    },
    mounted() {
      if (this.$props.value !== '') {
        this.toDate()
      }
    },
    methods: {
      toDate() {
        this.clear = this.$attrs.clear

        if (this.clear) {
          this.year = ''
        } else {
          if (this.$props.value === '') {
            this.year = ''
          } else {
            if (isNaN(this.year)) {
              this.year = ''
            }
            this.year = this.year === '' ? new Date(this.$props.value).getFullYear() : this.year
          }
        }

        if (this.clear) {
          this.day = ''
        } else {
          if (this.$props.value === '') {
            this.day = ''
          } else {
            if (isNaN(this.day)) {
              this.day = ''
            }
            this.day = this.day === '' ? new Date(this.$props.value).getDate().toString().padStart(2,"0") : this.day
          }
        }

        if (this.clear) {
          this.mounth = ''
        } else {
          if (this.$props.value === '') {
            this.mounth =  ''
          } else {
            if (isNaN(this.mounth)) {
              this.mounth = ''
            }
            this.mounth = this.mounth === '' ? new Date(this.$props.value).getMonth() + 1 : this.mounth 
            // if (Object.keys(this.mounth).length < 1) {
            //   let n = new Date(this.$props.value).getMonth() + 1
            //   for (let i in this.months) {
            //     if (this.months[i].id === n) {
            //       this.mounth =  this.months[i].id
            //     }
            //   }
            // } else {
            //   this.mounth = this.mounth 
            // }
          }
        }
      },

      changeDate() {
        
        // this.mounth === null ? this.mounth = {} : this.mounth

        if (this.day !== "" && this.mounth === "" && this.year === "") {
          this.mounth = null
          this.year = null
        }

        if (this.day === "" && this.mounth !== "" && this.year === "") {
          this.day = null
          this.year = null
        }

        if (this.day === "" && this.mounth === "" && this.year !== "") {
          this.day = null
          this.mounth = null
        }

        // this.mounth === null ? this.mounth = {} : this.mounth

        let dateForm = {
          day: this.day,
          mounth: this.mounth,
          valid: true,
          year: this.year,
          date: `${this.year}-${this.mounth !== null ? this.mounth < 10 ? `0${this.mounth}` : this.mounth : null}-${this.day}`
        }

        if (this.year === null) {
          dateForm.valid = false
        } else if (this.day === null) {
          dateForm.valid = false
        } else if (this.mounth === null) {
          dateForm.valid = false
        }
        if (dateForm.date === 'null-null-null') {
          dateForm.date = ''
          dateForm.valid = true
        }

        this.$emit('changeDate', dateForm)
      }
    }
  }
</script>