<template>
  <div>
    <!-- <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.announcement"
      v-model="dialog.announcement"
      width="700">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div class="text-second text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'text-h5'">
            Pengumuman (2)
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.announcement = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pt-2 pb-7'">
          <v-text-field
            solo
            color="#4ab762"
            hide-details
            clearable
            :placeholder="`Cari pengumuman…`">
            <template v-slot:prepend-inner>
              <v-icon class="mr-2">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pb-12'">
          <div>
            <v-row v-for="(item, index) in announcement" :key="index">
              <v-col cols="2">
                <v-img
                  :src="item.image"
                  :lazy-src="item.image"
                  aspect-ratio="1"
                  style="border-radius: 6px;"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="10">
                <div class="d-flex">
                  <div class="text-second text--darken-4" style="width: 50%;">
                    <div class="font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="ml-auto text-right" style="width: 50%;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                    Posted· {{ item.date }}
                  </div>
                </div>
                <div class="text-second text--draken-2 mt-3 mb-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                  {{ item.desc }}
                </div>

                <div v-if="!item.is_replay" class="my-5">
                  <v-row>
                    <v-col cols="2">
                      <v-img
                        :src="'https://picsum.photos/500/300?image=35'"
                        :lazy-src="'https://picsum.photos/500/300?image=35'"
                        aspect-ratio="1"
                        style="border-radius: 6px;"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col cols="10">
                      <v-textarea
                        rows="1"
                        :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pt-0 mt-0' : ''"
                        color="#4ab762"
                        hide-details
                        placeholder="Tuliskan komentar anda disini"
                      ></v-textarea>
                      <div class="d-flex align-center mt-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                        <div class="color-green-second">
                          Optional
                        </div>
                        <div class="text-second text-draken-2 ml-auto">
                          0 dari 1.000 karakter
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="!item.is_readmore" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                  <span class="cursor-pointer" @click="item.is_readmore = true">
                    <u>Tampilkan komentar ({{ item.replay.length }})</u>
                  </span>
                </div>
                <div v-if="item.is_readmore">
                  <v-row v-for="(l, i) in item.replay" :key="i">
                    <v-col cols="2">
                      <v-img
                        :src="l.image"
                        :lazy-src="l.image"
                        aspect-ratio="1"
                        style="border-radius: 6px;"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex">
                        <div class="text-second text--darken-4" :style="l.is_user ? 'width: 65%;' : 'width: 100%;'">
                          <div class="font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                            {{ l.name }} <span class="font-weight-regular text-second">- {{ l.date }}</span>
                          </div>
                        </div>
                        <div class="ml-auto" v-if="l.is_user" style="width: 35%;">
                          <v-btn
                            :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                            :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                            text
                            class="text-capitalize primary--text pl-0">
                            Ubah
                          </v-btn>
                          <v-btn
                            :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                            :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                            text
                            class="text-capitalize red--text pr-0">
                            Hapus
                          </v-btn>
                        </div>
                      </div>
                      <div class="text-second text--draken-2 mt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                        {{ l.desc }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.is_readmore" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                  <span class="cursor-pointer" @click="item.is_readmore = false">
                    <u>Tutup komentar</u>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" v-if="index !== (announcement.length - 1)">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.catatan"
      v-model="dialog.catatan"
      width="600">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div class="text-second text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'text-h5'">
            Catatan Saya (3)
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              small
              class="text-capitalize px-0"
              @click="dialog.catatan = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pt-2 pb-7'">
          <div class="text-second" style="width: 60%">
            <v-text-field
              solo
              color="#4ab762"
              hide-details
              clearable
              :placeholder="`Cari catatan…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
            <v-select
                :items="[
                  'Disemua Materi'
                ]"
                solo
                color="#4ab762"
                item-color="green"
                hide-details
                class=""
                placeholder="Materi"
              ></v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pb-12'">
          <div>
            <v-row v-for="(item, index) in catatan" :key="index">
              <v-col cols="2">
                <div class="color-green-second pa-2 text-center font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'caption' : 'body-1'" style="background-color: #4ab76230;">
                  {{ item.time }}
                </div>
              </v-col>
              <v-col cols="10">
                <div class="d-flex">
                  <div class="font-weight-bold text-first" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'title'" style="width: 68%;">
                    {{ item.title }}
                    <div class="color-green-second" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'caption' : 'body-2'">
                      {{ item.materi }}
                    </div>
                  </div>
                  <div class="ml-auto text-right justify-end" style="width: 32%;">
                    <v-btn
                      :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                      :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                      text
                      class="text-capitalize primary--text pl-0">
                      Ubah
                    </v-btn>
                    <v-btn
                      :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                      :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                      text
                      class="text-capitalize red--text pr-0">
                      Hapus
                    </v-btn>
                  </div>
                </div>
                <div class="text-second text--draken-2 mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-2' : 'body-1'">
                  {{ item.catatan }}
                </div>
              </v-col>
              <v-col cols="12" v-if="index !== (catatan.length - 1)">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <Rating v-if="Object.keys(detail).length > 0" :dialogopen="dialog.review" :detailCollection="detail" @close="dialog.review = false"  @add="detail.review_count = parseInt(detail.review_count)+1"/>

    <Discussion v-if="Object.keys(detail).length > 0" :detailMateri="playlis_materi" :dialogopen="dialog.discus" :detailCollection="detail" @close="dialog.discus = false" @add="detail.discussion_count = parseInt(detail.discussion_count)+1" @deletes="detail.discussion_count = parseInt(detail.discussion_count)-1"/>

    <Announcement v-if="Object.keys(detail).length > 0" :dialogopen="dialog.announcement" :detailCollection="detail" @close="dialog.announcement = false"/>

    <Catatan v-if="Object.keys(detail).length > 0" :timeDialog="getTime" :dialogopen="dialog.catatan" :detailPlaylist="player" :dialogopenform="dialog.addCatatan" :detailCollection="detail" @close="dialog.catatan = false; dialog.addCatatan = false; player.type !== 'docs' && player.type !== '' ? play() : ''" @add="detail.note_count = parseInt(detail.note_count)+1" @deletes="detail.note_count = parseInt(detail.note_count)-1"/>

    <!-- <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.discus"
      v-model="dialog.discus"
      width="700">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
      <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div class="text-second text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'text-h5'">
            Diskusi (2)
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.discus = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pt-2 pb-7'">
          <div class="text-second" style="width: 60%">
            <v-text-field
              solo
              color="#4ab762"
              hide-details
              clearable
              :placeholder="`Cari ulasan…`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="ml-auto text-right pl-5 text-right justify-end" style="width: 40%">
            <v-select
                :items="[
                  'Semua Materi'
                ]"
                solo
                color="#4ab762"
                item-color="green"
                hide-details
                class=""
                placeholder="Materi"
              ></v-select>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'px-4 pb-4' : 'px-8 pb-16'">
          <div>
            <v-row v-for="(item, index) in discus" :key="index">
              <v-col cols="2">
                <v-img
                  :src="item.image"
                  :lazy-src="item.image"
                  aspect-ratio="1"
                  style="border-radius: 6px;"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="10">
                <div class="d-flex">
                  <div class="text-second text--darken-4" style="width: 50%;">
                    <div class="font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="ml-auto text-right" style="width: 50%;" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                    Posted· {{ item.date }}
                  </div>
                </div>
                <div class="text-second text--draken-2 mt-3 mb-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                  {{ item.desc }}
                </div>

                <div class="my-5">
                  <v-row>
                    <v-col cols="2">
                      <v-img
                        :src="'https://picsum.photos/500/300?image=35'"
                        :lazy-src="'https://picsum.photos/500/300?image=35'"
                        aspect-ratio="1"
                        style="border-radius: 6px;"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col cols="10">
                      <v-textarea
                        rows="1"
                        color="#4ab762"
                        :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pt-0 mt-0' : ''"
                        hide-details
                        placeholder="Tuliskan komentar anda disini"
                      ></v-textarea>
                      <div class="d-flex align-center mt-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                        <div class="color-green-second">
                          Optional
                        </div>
                        <div class="text-second text-draken-2 ml-auto">
                          0 dari 1.000 karakter
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="!item.is_readmore" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                  <span class="cursor-pointer" @click="item.is_readmore = true">
                    <u>Tampilkan komentar ({{ item.replay.length }})</u>
                  </span>
                </div>
                <div v-if="item.is_readmore">
                  <v-row v-for="(l, i) in item.replay" :key="i">
                    <v-col cols="2">
                      <v-img
                        :src="l.image"
                        :lazy-src="l.image"
                        aspect-ratio="1"
                        style="border-radius: 6px;"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex">
                        <div class="text-second text--darken-4" :style="l.is_user ? 'width: 65%;' : 'width: 100%;'">
                          <div class="font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                            {{ l.name }} <span class="font-weight-regular text-second">- {{ l.date }}</span>
                          </div>
                        </div>
                        <div class="ml-auto" v-if="l.is_user" style="width: 35%;">
                          <v-btn
                            :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                            :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                            text
                            class="text-capitalize primary--text pl-0">
                            Ubah
                          </v-btn>
                          <v-btn
                            :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? false : true"
                            :x-small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
                            text
                            class="text-capitalize red--text pr-0">
                            Hapus
                          </v-btn>
                        </div>
                      </div>
                      <div class="text-second text--draken-2 mt-1" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">
                        {{ l.desc }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.is_readmore" class="mt-3" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'caption' : ''">
                  <span class="cursor-pointer" @click="item.is_readmore = false">
                    <u>Tutup komentar</u>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" v-if="index !== (discus.length - 1)">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-fab-transition>
          <v-btn
            elevation="3"
            fab
            fixed
            bottom
            right
            style="position: absolute;"
            class="white--text"
            @click="dialog.addDiscus = true"
            color="#3498db">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card>
    </v-dialog> -->

    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-if="dialog.about"
      v-model="dialog.about"
      width="500">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow border-radius'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div class="text-second text--darken-2 font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'fs-18'">
            Tentang Kelas
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.about = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <div class="text-second fs-14" style="padding: 20px 30px;">
            {{ detail.description}}
          </div>
          <v-divider style="border-width: 5px; border-color: #D8D8D8;"></v-divider>
          <div class="text-second fs-14" style="padding: 20px 30px;">
            <div class="font-weight-bold text-first mb-2 fs-16">
              Tentang Kelas Ini
            </div>
            {{ detail.description_long }}
          </div>
          <v-divider style="border-width: 5px; border-color: #D8D8D8;"></v-divider>
          <div class="text-second fs-14" style="padding: 20px 30px;">
            <div class="font-weight-bold text-first mb-2 fs-16">
              Yang Anda Pelajari Di Kelas Ini
            </div>
            {{ detail.subject }}
          </div>
          <v-divider style="border-width: 5px; border-color: #D8D8D8;"></v-divider>
          <div class="text-second fs-14" style="padding: 20px 30px 40px 30px;">
            <div class="font-weight-bold text-first mb-3 fs-16">
              Instruktur / Author
            </div>
            <v-row>
              <v-col cols="2">
                <v-img
                  :src="detail.publisher_user_img_url"
                  :lazy-src="detail.publisher_user_img_url"
                  aspect-ratio="1"
                  style="border-radius: 6px;"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="10">
                <div class="d-flex mb-2">
                  <div class="text-first text--darken-4" :style="'width: 65%;'">
                    <div class="font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'fs-18'">
                      {{ detail.publisher_user_name }}
                    </div>
                  </div>
                  <div class="ml-auto text-right justify-end" style="width: 35%;">
                    <v-list-item class="px-0 pb-2" style="min-height: 20px;">
                      <v-list-item-avatar :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25" class="mt-0 mb-0 mr-3" color="#ededed">
                        <v-img :src="detail.organization_img_url" style="width: 25px;" v-if="detail.organization_img_url !== ''"></v-img>
                          <v-icon size="18"  v-if="detail.organization_img_url === ''">mdi-office-building</v-icon>
                      </v-list-item-avatar>
          
                      <v-list-item-content class="pt-0 pb-0">
                        <v-list-item-title class="text-first font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'body-2' : 'body-1'">{{ detail.organization_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <div v-if="detail.url_facebook !== undefined">
                  <v-btn
                    outlined
                    :min-width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 35"
                    :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    color="grey darken-2"
                    elevation="0"
                    :href="detail.url_web"
                    target="_blank"
                    class="px-3 mr-3"
                    style="border-radius: 8px;">
                    <v-icon color="grey darken-4" :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-link</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    :min-width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 35"
                    :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    color="grey darken-2"
                    elevation="0"
                    :href="`${detail.url_youtube}`"
                    target="_blank"
                    class="px-3 mr-3"
                    style="border-radius: 8px;">
                    <v-icon color="grey darken-4" :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-youtube</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    :min-width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 35"
                    :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    color="grey darken-2"
                    elevation="0"
                    class="px-3 mr-3"
                    :href="`${detail.url_instagram}`"
                    target="_blank"
                    style="border-radius: 8px;">
                    <v-icon color="grey darken-4" :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-instagram</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    :min-width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 35"
                    :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    color="grey darken-2"
                    elevation="0"
                    :href="`${detail.url_facebook}`"
                    target="_blank"
                    class="px-3 mr-3"
                    style="border-radius: 8px;">
                    <v-icon color="grey darken-4" :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    :min-width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 35"
                    :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 30 : 40"
                    color="grey darken-2"
                    elevation="0"
                    :href="`${detail.url_twitter}`"
                    target="_blank"
                    class="px-3 mr-3"
                    style="border-radius: 8px;">
                    <v-icon color="grey darken-4" :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-twitter</v-icon>
                  </v-btn>
                </div>
                <div class="text-second text--draken-2 mt-5" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'fs-14' : 'fs-14'" v-if="detail.publisher_about !== undefined">
                  {{ detail.publisher_about }}
                </div>
                <!-- <router-link to="/account/setting">
                  <div class="caption color-blue-first mt-1">
                    <u>Lihat Detail</u>
                  </div>
                </router-link> -->
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div v-if="!process.detail">
      <section>
        <v-app-bar 
          app
          fixed
          v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
          class="header app_detail_collection">
          <v-row align="center" justify="center" class="ma-0" style="height: 100%">
            <v-col cols="8" class="d-flex align-center pl-5" style="background-color: #4ab762; height: 100%">
              <div class="mr-6">
                <v-btn
                  small
                  icon
                  to="/account/collection"
                  color="#FFFFFF">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </div>
  
              <div>
                <span class="title one-line white--text">
                {{ detail.name }}
                </span>
              </div>
            </v-col>
  
            <v-col cols="4" class="title text-right pr-5 d-flex align-center justify-end" style="background-color: #42A558; height: 100%">
              <div class="white--text">
                Konten Pembelajaran
              </div>
              <div class="ml-auto">
                <div>
                  <span class="font-weight-bold" :class="'white--text'" >
                    {{ detail.proggress }}%
                  </span>
                  
                  <span
                    class="font-weight-bold white--text">
                    Selesai
                  </span>
                </div>
  
                <!-- <div>
                  <span class="font-weight-bold"
                    :class="'white--text'">
                    {{ detail.content.length }}
                  </span>
  
                  <span
                    class="font-weight-bold white--text">
                    Bagian .
                  </span>
                  <span class="font-weight-bold"
                    :class="'white--text'">
                    {{ totalSub }}
                  </span>
  
                  <span
                    class="font-weight-bold white--text">
                    Materi
                  </span>
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-app-bar>
      </section>
  
      <section>
        <v-card-text :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'pa-0 mt-n5' : 'pa-0 mt-13'">
          <v-row class="ma-0">
            <v-col cols="12" md="8" class="px-0 pb-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <div>
                    <v-hover v-slot="{ hover }" v-if="player.type === ''">
                      <v-card flat>
                        <v-img :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'" contain :src="detail.img_url" :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: calc(100vh - 128px);' : 'height: 250px'"></v-img>
                        <div v-if="hover" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'position: absolute; top: 95px; width: 100%;' : 'position: absolute; top: calc(calc(100vh - 200px) / 2; width: 100%;'">
                          <v-row align="center">
                            <v-col cols="3">
                              <v-btn
                                icon
                                fab
                                @click="playing_previous()"
                                :disabled="parseInt(selected.index) === 0">
                                <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 40" color="white">mdi-skip-previous</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="6" class="text-center">
                              <v-btn
                                icon
                                fab
                                @click="playing_now()">
                                <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 40 : 90" color="white">mdi-play-circle</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="3" class="text-right">
                              <v-btn
                                icon
                                fab
                                @click="playing_next()"
                                :disabled="playlis_materi.length === parseInt(selected.index)+1">
                                <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 40" color="white">mdi-skip-next</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-hover>
                    <section v-if="player.type === 'docs'"
                      style="background: #333638;">
                      <v-hover v-slot="{ hover }">
                        <v-card flat>
                          <iframe
                            frameborder="0" 
                            width="100%"
                            :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'height: calc(100vh - 135px);' : 'height: 250px'"
                            :src="player.data.file_url">
                          </iframe>
                          <div v-if="hover" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'position: absolute; top: calc(250px / 2); width: 100%;' : 'position: absolute; top: calc(calc(100vh - 200px) / 2; width: 100%;'">
                            <v-row align="center">
                              <v-col cols="3">
                                <v-btn
                                  icon
                                  fab
                                  @click="playing_previous()"
                                  :disabled="parseInt(selected.index) === 0">
                                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 40" color="white">mdi-skip-previous</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="6" class="text-center">
                                <!-- <v-icon size="90" color="white">mdi-play-circle</v-icon> -->
                              </v-col>
                              <v-col cols="3" class="text-right">
                                <v-btn
                                  icon
                                  fab
                                  @click="playing_next()"
                                  :disabled="playlis_materi.length === parseInt(selected.index)+1">
                                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 40" color="white">mdi-skip-next</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-hover>

                    </section>

                    <section v-if="player.type === 'audio'"
                      style="background: #f0f3f4;">
                      <audio
                        style="width: 100%; position: relative; bottom: -8px; background: #f0f3f4;"
                        autoplay
                        controls
                        preload="none">
                        <source :src="player.data.file_url">
                      </audio>
                    </section>

                    <section v-if="player.type === 'video'"
                      style="background: black;">
                      <v-hover v-slot="{ hover }">
                        <v-card flat>
                          <video
                            autoplay
                            controls 
                            controlsList="nodownload"
                            width="100%" 
                            id="myVideo"
                            ref="videoRef"
                            style="`object-fit: contain;`"
                            :poster="detail.img_url">
                            <source :src="player.data.file_url">
                          </video>
                          <div v-if="hover" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'position: absolute; top: calc(250px / 2); width: 100%;' : 'position: absolute; top: calc(calc(100vh - 200px) / 2; width: 100%;'">
                            <v-row align="center">
                              <v-col cols="3">
                                <v-btn
                                  icon
                                  fab
                                  @click="playing_previous()"
                                  :disabled="parseInt(selected.index) === 0">
                                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 40" color="white">mdi-skip-previous</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="6" class="text-center">
                                <v-btn
                                icon
                                fab
                                @click="play()">
                                <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 40 : 90" color="white">{{ !paused_video ? 'mdi-pause-circle' : 'mdi-play-circle' }}</v-icon>
                              </v-btn>
                              </v-col>
                              <v-col cols="3" class="text-right">
                                <v-btn
                                  icon
                                  fab
                                  @click="playing_next()"
                                  :disabled="playlis_materi.length === parseInt(selected.index)+1">
                                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 40" color="white">mdi-skip-next</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-hover>
                    </section>
                  </div>
      
                  <div class="d-flex align-center px-5 text-second" style="height: 64px; background: #f7f7f7;  margin-top: 0px;"  v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                    <div class="mx-5">
                      <span class="cursor-pointer" @click="dialog.about = true">Tentang Kelas</span>
                    </div>
                    <div class="mx-5">
                      <span class="cursor-pointer" @click="dialog.catatan = true;">
                        Catatan Saya ({{ detail.note_count }})
                      </span>
                    </div>
                    <div class="mx-5">
                      <span class="cursor-pointer" @click="dialog.discus = true">Diskusi ({{ detail.discussion_count }})</span>
                    </div>
                    <div class="mx-5">
                      <span class="cursor-pointer" @click="dialog.announcement = true;">Pengumuman ({{ detail.announcement_count }})</span>
                    </div>
                    <div class="mx-5">
                      <span class="cursor-pointer" @click="dialog.review = true">Rating & Ulasan ({{ detail.review_count }})</span>
                    </div>
                  </div>
      
                  <div style="position: absolute; right: 35px; bottom: 95px;"  v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && player.type !== ''">
                    <v-btn
                      elevation="0"
                      @click="toGetTime();"
                      class="text-capitalize">
                      <div class="d-flex align-center" style="width: 100%;">
                        <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
                        <div class="ml-auto">
                          Catatan
                        </div>
                      </div>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4" class="px-0" style="height: calc(100vh - 55px); overflow-y: auto;"  v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
              <v-expansion-panels flat class="mb-n2" v-for="(materi, i) in detail.content" :key="i">
                <v-expansion-panel class="mb-3">
                  <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 21px; height: 65px; border-bottom: 0.5px solid rgba(151, 151, 151, 0.10)">
                    <template v-slot:actions>
                      <div>
                        <v-icon class="px-0" color="#7B7B7B">
                          $expand
                        </v-icon>
                      </div>
                    </template>
                    <v-list dense class="py-0" color="transparent">
                      <v-list-item class="py-0 px-0">
                        <div class="d-flex align-center" style="width: 100%;">
                          <div class="body-1 ml-0 font-weight-bold text-capitalize">
                            {{ materi.title }}
                            <div class="caption text-second">
                              {{ materi.sub_count }} Materi
                            </div>
                          </div>
                          <div class="body-2 text-first ml-auto">
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="non-padding-expansion">
                    <div style="padding: 20px 20px 0px 20px;">
                      <div v-if="materi.file_url === ''">
                        <div class="d-flex mb_15">
                          <div>
                            <div class="d-flex">
                              <div class="mr-4">
                                <v-img
                                  width="54"
                                  height="54"
                                  class="d-flex align-center justify-center"
                                  gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                  :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                                  style="border-radius: 5px;">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#4ab762">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>

                              <div class="body-1 font-weight-light text-second body-2">
                                <div v-if="materi.description !== ''">
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length > 50">
                                    <div v-if="!materi.read_more">
                                      <span class="caption" style="white-space: pre-line;" v-html="materi.description.substring(0, 50) + '...'">
                                      </span>
                                      <span class="text-second cursor-pointer mt-5" @click="materi.read_more = true"><u>Selengkapnya</u></span>
                                    </div>
                                    <div v-if="materi.read_more">
                                      <span class="caption mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                      <span class="text-second cursor-pointer mt-0" @click="materi.read_more = false"><u>Tutup</u></span>
                                    </div>
                                  </div>
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length <= 50">
                                    <div class="caption" style="white-space: pre-line;" v-html="materi.description"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="materi.file_url !== ''" class="cursor-pointer" style="background: #f6f6f6; border-radius: 6px; margin-bottom: 10px;" :style="selected.sub === materi.id ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.sub = materi.id">
                        <div class="d-flex" style="padding: 11px 15px;" @click="playing(materi)">
                          <div>
                            <div
                              style="min-width: 35px; width: 35px; padding-top: 15.5px">
                              <v-icon 
                                size="18"
                                :color="selected.sub === materi.id ? '#4ab762' : 'grey'">
                                {{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                              </v-icon>
                            </div>
                          </div>
                          <div class="mr-3">
                            <v-img
                              width="54"
                              height="54"
                              class="d-flex align-center justify-center"
                              gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                              :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                              style="border-radius: 5px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#4ab762">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div class="body-1 font-weight-light text-second">
                            <span
                              class="text-second body-2">
                              <div class="body-2 font-weight-bold mb-1 text-first">
                                {{ materi.title }}
                              </div>
                              <div v-if="materi.description !== ''">
                                <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length > 30">
                                  <div v-if="!materi.read_more">
                                    <span class="caption" style="white-space: pre-line;" v-html="materi.description.substring(0, 30) + '...'">
                                    </span>
                                    <span class="text-second cursor-pointer mt-5" @click="materi.read_more = true"><u>Selengkapnya</u></span>
                                  </div>
                                  <div v-if="materi.read_more">
                                    <span class="caption mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                    <span class="text-second cursor-pointer mt-0" @click="materi.read_more = false"><u>Tutup</u></span>
                                  </div>
                                </div>
                                <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length <= 30">
                                  <div class="caption" style="white-space: pre-line;" v-html="materi.description"></div>
                                </div>
                              </div>
                            </span>
                          </div>

                          <div class="ml-auto pl-4 text-right" style="color: #4ab762; padding-top: 15.5px">
                            <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                            <v-icon size="20" :color="!materi.read_bool ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                          </div>
                        </div>
                      </div>
                      <div v-for="(sub_materi, index) in materi.sub" :key="index" style="margin-bottom: 10px;">
                        <div class="cursor-pointer" style="background: #f6f6f6; border-radius: 6px;" :style="selected.sub === sub_materi.id ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.sub = sub_materi.id">
                          <div class="d-flex" style="padding: 11px 15px;" @click="playing(sub_materi)">
                            <div>
                              <div
                                style="min-width: 35px; width: 35px; padding-top: 15.5px">
                                <v-icon 
                                  size="18"
                                  :color="selected.sub === sub_materi.id ? '#4ab762' : 'grey'">
                                  {{ sub_materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                </v-icon>
                              </div>
                            </div>
                            <div class="mr-3">
                              <v-img
                                width="54"
                                height="54"
                                class="d-flex align-center justify-center"
                                gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                :src="sub_materi.img_url === '' ? detail.img_url : sub_materi.img_url"
                                style="border-radius: 5px;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#4ab762">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div class="body-1 font-weight-light text-second">
                              <span
                                class="text-second body-2">
                                <div class="body-2 font-weight-bold mb-1 text-first">
                                  {{ sub_materi.title }}
                                </div>
                                <div v-if="sub_materi.description !== ''">
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="sub_materi.description.length > 30">
                                    <div v-if="!sub_materi.read_more">
                                      <span class="caption" style="white-space: pre-line;" v-html="sub_materi.description.substring(0, 30) + '...'">
                                      </span>
                                      <span class="text-second cursor-pointer mt-5" @click="sub_materi.read_more = true"><u>Selengkapnya</u></span>
                                    </div>
                                    <div v-if="sub_materi.read_more">
                                      <span class="caption mr-3" style="white-space: pre-line;" v-html="sub_materi.description"></span>
                                      <span class="text-second cursor-pointer mt-0" @click="sub_materi.read_more = false"><u>Tutup</u></span>
                                    </div>
                                  </div>
                                  <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="sub_materi.description.length <= 30">
                                    <div class="caption" style="white-space: pre-line;" v-html="sub_materi.description"></div>
                                  </div>
                                </div>
                              </span>
                            </div>

                            <div class="ml-auto pl-4 text-right" style="color: #4ab762; padding-top: 15.5px">
                              <!-- {{ sub_materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                              <v-icon size="20" :color="!sub_materi.read_bool ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row> 
        </v-card-text>
      </section>
    </div>

    <div v-if="!process.detail">
      <section>
        <v-app-bar 
          app
          fixed
          v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
          class="header app_detail_collection"
          style="background-color: #4ab762; box-shadow: none; height: 64px;">
          <div class="d-flex align-center pt-2" style="height: 100%;">
            <div class="mr-3 pl-1">
              <v-btn
                small
                icon
                to="/account/collection"
                color="#FFFFFF">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </div>
            
            <div class="">
              <span class="title one-line white--text">
                {{ detail.name }}
              </span>
            </div>
          </div>
        </v-app-bar>
      </section>

      <section v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
        <div class="px-2 py-2 body-1">
          <h3>{{ detail.name }}</h3>
        </div>

        <div class="d-flex align-center">
          <div>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#4ab762"
              slider-size="4"
              class="mx-5">
              <v-tabs-slider color="#4ab762"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in menu"
                :key="index"
                class="text-capitalize">
                <span class="body-2" :class="tab === index ? 'font-weight-bold color-default' : 'text-third'">
                  {{ item }}
                </span>
              </v-tab>
            </v-tabs>
          </div>
          <div class="ml-auto text-second body-2 mr-5">
            <span class="text-second text--darken-4 mr-2 font-weight-bold">100%</span>selesai
          </div>
        </div>
      </section>

      <section class="mt-5 px-3" style="overflow-y: auto; height: calc(100vh - 455px);" v-if="tab === 0 && ($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm')">
        <v-expansion-panels flat class="mb-n2" v-for="(materi, i) in detail.content" :key="i">
          <v-expansion-panel class="mb-3">
            <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 21px; height: 65px; border-bottom: 0.5px solid rgba(151, 151, 151, 0.10)">
              <template v-slot:actions>
                <div>
                  <v-icon class="px-0" color="#7B7B7B">
                    $expand
                  </v-icon>
                </div>
              </template>
              <v-list dense class="py-0" color="transparent">
                <v-list-item class="py-0 px-0">
                  <div class="d-flex align-center" style="width: 100%;">
                    <div class="body-1 ml-0 font-weight-bold text-capitalize">
                      {{ materi.title }}
                      <div class="caption text-second">
                        {{ materi.sub.length }} Materi
                      </div>
                    </div>
                    <div class="body-2 text-first ml-auto">
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="non-padding-expansion">
              <div style="padding: 20px 20px 0px 20px;">
                <div v-if="materi.file_url === ''">
                  <div class="d-flex mb_15">
                    <div>
                      <div class="d-flex">
                        <div class="mr-4">
                          <v-img
                            width="54"
                            height="54"
                            class="d-flex align-center justify-center"
                            gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                            :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                            style="border-radius: 5px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#4ab762">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div class="body-1 font-weight-light text-second body-2">
                          <div v-if="materi.description !== ''">
                            <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length > 50">
                              <div v-if="!materi.read_more">
                                <span class="caption" style="white-space: pre-line;" v-html="materi.description.substring(0, 50) + '...'">
                                </span>
                                <span class="text-second cursor-pointer mt-5" @click="materi.read_more = true"><u>Selengkapnya</u></span>
                              </div>
                              <div v-if="materi.read_more">
                                <span class="caption mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                <span class="text-second cursor-pointer mt-0" @click="materi.read_more = false"><u>Tutup</u></span>
                              </div>
                            </div>
                            <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length <= 50">
                              <div class="caption" style="white-space: pre-line;" v-html="materi.description"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="materi.file_url !== ''" class="cursor-pointer" style="background: #f6f6f6; border-radius: 6px; margin-bottom: 10px;" :style="selected.sub === materi.id ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.sub = materi.id">
                  <div class="d-flex" style="padding: 11px 15px;" @click="playing(materi)">
                    <div>
                      <div
                        style="min-width: 35px; width: 35px; padding-top: 15.5px">
                        <v-icon 
                          size="18"
                          :color="selected.sub === materi.id ? '#4ab762' : 'grey'">
                          {{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                        </v-icon>
                      </div>
                    </div>
                    <div class="mr-3">
                      <v-img
                        width="54"
                        height="54"
                        class="d-flex align-center justify-center"
                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                        :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                        style="border-radius: 5px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#4ab762">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div class="body-1 font-weight-light text-second">
                      <span
                        class="text-second body-2">
                        <div class="body-2 font-weight-bold mb-1 text-first">
                          {{ materi.title }}
                        </div>
                        <div v-if="materi.description !== ''">
                          <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length > 30">
                            <div v-if="!materi.read_more">
                              <span class="caption" style="white-space: pre-line;" v-html="materi.description.substring(0, 30) + '...'">
                              </span>
                              <span class="text-second cursor-pointer mt-5" @click="materi.read_more = true"><u>Selengkapnya</u></span>
                            </div>
                            <div v-if="materi.read_more">
                              <span class="caption mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                              <span class="text-second cursor-pointer mt-0" @click="materi.read_more = false"><u>Tutup</u></span>
                            </div>
                          </div>
                          <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="materi.description.length <= 30">
                            <div class="caption" style="white-space: pre-line;" v-html="materi.description"></div>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div class="ml-auto pl-4 text-right" style="color: #4ab762; padding-top: 15.5px">
                      <!-- {{ materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                      <v-icon size="20" :color="!materi.read_bool ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                    </div>
                  </div>
                </div>
                <div v-for="(sub_materi, index) in materi.sub" :key="index" style="margin-bottom: 10px;">
                  <div class="cursor-pointer" style="background: #f6f6f6; border-radius: 6px;" :style="selected.sub === sub_materi.id ? 'background: #f7f7f7cf; border: 1px solid #4ab762;' : 'border: 1px solid #F2F2F2'" @click="selected.sub = sub_materi.id">
                    <div class="d-flex" style="padding: 11px 15px;" @click="playing(sub_materi)">
                      <div>
                        <div
                          style="min-width: 35px; width: 35px; padding-top: 15.5px">
                          <v-icon 
                            size="18"
                            :color="selected.sub === sub_materi.id ? '#4ab762' : 'grey'">
                            {{ sub_materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                          </v-icon>
                        </div>
                      </div>
                      <div class="mr-3">
                        <v-img
                          width="54"
                          height="54"
                          class="d-flex align-center justify-center"
                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                          :src="sub_materi.img_url === '' ? detail.img_url : sub_materi.img_url"
                          style="border-radius: 5px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#4ab762">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div class="body-1 font-weight-light text-second">
                        <span
                          class="text-second body-2">
                          <div class="body-2 font-weight-bold mb-1 text-first">
                            {{ sub_materi.title }}
                          </div>
                          <div v-if="sub_materi.description !== ''">
                            <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="sub_materi.description.length > 30">
                              <div v-if="!sub_materi.read_more">
                                <span class="caption" style="white-space: pre-line;" v-html="sub_materi.description.substring(0, 30) + '...'">
                                </span>
                                <span class="text-second cursor-pointer mt-5" @click="sub_materi.read_more = true"><u>Selengkapnya</u></span>
                              </div>
                              <div v-if="sub_materi.read_more">
                                <span class="caption mr-3" style="white-space: pre-line;" v-html="sub_materi.description"></span>
                                <span class="text-second cursor-pointer mt-0" @click="sub_materi.read_more = false"><u>Tutup</u></span>
                              </div>
                            </div>
                            <div class="font-weight-light caption text-second text--darken-2 mb-0" v-if="sub_materi.description.length <= 30">
                              <div class="caption" style="white-space: pre-line;" v-html="sub_materi.description"></div>
                            </div>
                          </div>
                        </span>
                      </div>

                      <div class="ml-auto pl-4 text-right" style="color: #4ab762; padding-top: 15.5px">
                        <!-- {{ sub_materi.type === 'docs' ? 'Pdf' : 'Video'}} -->
                        <v-icon size="20" :color="!sub_materi.read_bool ? '#50905336' : '#5baf60'">mdi-check-circle</v-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>
      <section class="mt-5 px-3" v-if="tab === 1 && ($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm')">
        <v-list>
          <v-list-item class="px-0" @click="dialog.about = true">
            <v-list-item-content class="align-self-start pt-3">
              <div class="body-2 font-weight-bold d-flex align-center">
                <v-icon size="20" class="mr-2">mdi-information-outline</v-icon>
                <div>Tentang Kelas Ini</div>
              </div>
            </v-list-item-content>
            <v-list-item-content style="max-width: 30px;" class="text-right">
              <v-icon color="#4ab762">mdi-chevron-right</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item class="px-0" @click="dialog.discus = true">
            <v-list-item-content class="align-self-start pt-3">
              <div class="body-2 font-weight-bold d-flex align-center">
                <v-icon size="20" class="mr-2">mdi-forum-outline</v-icon>
                <div>Diskusi</div>
                <div class="ml-auto">
                  ( 20 )
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content style="max-width: 30px;" class="text-right">
              <v-icon color="#4ab762">mdi-chevron-right</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item class="px-0" @click="dialog.announcement = true;">
            <v-list-item-content class="align-self-start pt-3">
              <div class="body-2 font-weight-bold d-flex align-center">
                <v-icon size="20" class="mr-2">mdi-bell-outline</v-icon>
                <div>Pengumuman</div>
                <div class="ml-auto">
                  ( 20 )
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content style="max-width: 30px;" class="text-right">
              <v-icon color="#4ab762">mdi-chevron-right</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item class="px-0" @click="dialog.review = true">
            <v-list-item-content class="align-self-start pt-3">
              <div class="d-flex align-center">
                <div class="body-2 font-weight-bold d-flex align-center">
                  <v-icon size="20" class="mr-2">mdi-star</v-icon>
                  <div>Ulasan</div>
                </div>
                <div class="ml-auto d-flex align-center">
                  <!-- <v-rating
                    v-model="detail.content_rating_value"
                    :length="length"
                    color="warning"
                    half-increments
                    background-color="warning lighten-1"
                    dense
                    size="20"
                    class="mr-2"
                    readonly>
                  </v-rating> -->
                  <h4>( 20 )</h4>
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content style="max-width: 30px;" class="text-right">
              <v-icon color="#4ab762">mdi-chevron-right</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item class="px-0" @click="dialog.catatan = true">
            <v-list-item-content class="align-self-start pt-3">
              <div class="body-2 font-weight-bold d-flex align-center">
                <div class="d-flex align-center">
                  <v-icon size="20" class="mr-2">mdi-text</v-icon>
                  <div>Catatan</div>
                </div>
                <div class="ml-auto">
                  ( 2 )
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content style="max-width: 30px;" class="text-right">
              <v-icon color="#4ab762">mdi-chevron-right</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </section>
    </div>
    
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        filter: {
          sort: 'Paling baru'
        },
        selected: {
          sub: "",
          index: 0
        },
        items: ['Paling baru'],
        format: 'row', 
        page: 1,
        dialog: {
          catatan: false,
          announcement: false,
          review: false,
          discus: false,
          addDiscus: false,
          about: false,
          addCatatan: false
        },
        menu: [
          'Materi', 'Lainnya'
        ],
        player: {
          type: "",
          data: {}
        },
        getTime: "",
        paused_video: false,
        tab: 0,
        panel: 0,
        detail: {},
        catatan: [
          {
            time: '1:15',
            title: 'Animation with React Native',
            materi: 'The Animation Module',
            catatan: 'React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.'
          },
          {
            time: '0:36',
            title: 'Animation with React Native',
            materi: 'The Animation Module',
            catatan: 'React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.'
          },
          {
            time: '0:36',
            title: 'Animation with React Native',
            materi: 'The Animation Module',
            catatan: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
          }
        ],
        announcement: [
          {
            name: "PT ESODA",
            image: "https://picsum.photos/500/300?image=25",
            type: "AUTHOR",
            desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
            date: "6 months ago",
            is_replay: false,
            is_readmore: false,
            replay: [
              {
                name: "Anne Marie",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Marie Anne",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Laode Panoma",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Bambang Setyawan",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              }
            ]
          },
          {
            name: "PT ESODA",
            image: "https://picsum.photos/500/300?image=25",
            type: "AUTHOR",
            desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
            date: "6 months ago",
            is_replay: true,
            is_readmore: true,
            replay: [
              {
                name: "Anda",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: true,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Anne Marie",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Marie Anne",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Laode Panoma",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Bambang Setyawan",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              }
            ]
          }
        ],
        review: [
          {
            date: 'Kemarin, pukul 12:00',
            name: 'Anne Marie',
            image: "https://picsum.photos/500/300?image=35",
            rate: 5,
            is_user: false,
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
          }
        ],
        review_is_user: false,
        discus: [
          {
            name: "PT ESODA",
            image: "https://picsum.photos/500/300?image=25",
            type: "AUTHOR",
            desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
            date: "6 months ago",
            is_replay: false,
            is_readmore: false,
            replay: [
              {
                name: "Anne Marie",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Marie Anne",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Laode Panoma",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Bambang Setyawan",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              }
            ]
          },
          {
            name: "PT ESODA",
            image: "https://picsum.photos/500/300?image=25",
            type: "AUTHOR",
            desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
            date: "6 months ago",
            is_replay: true,
            is_readmore: true,
            replay: [
              {
                name: "Anda",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: true,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Anne Marie",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Marie Anne",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Laode Panoma",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              },
              {
                name: "Bambang Setyawan",
                image: "https://picsum.photos/500/300?image=35",
                type: "member",
                is_user: false,
                desc: "React Native is an excellent solution for developing apps on mobile in a fraction of the time it takes to make an equivalent.",
                date: "6 months ago",
              }
            ]
          }
        ],
        loader: false,
        playlis_materi: [],
        process: {
          detail: true,
          read: false
        },
        // SEO
        content: {
          url: '/home',
          title: 'Detail Koleksi Kelas',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Rating: () => import('@/components/collection/rating'),
      Discussion: () => import('@/components/collection/discussion'),
      Announcement: () => import('@/components/collection/announcement'),
      Catatan: () => import('@/components/collection/catatan')
    },
    mounted () {
      this.fetchDetail()
    },
    methods: {
      fetchDetail() {
        this.process.detail = this.loader ? true : false
        // let params = {
        //   id: this.$route.params.slug
        // }
        this.$axios.$post(`api/my_course/read_course`, { 
          id: this.$route.params.slug
         })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            this.detail = response.results.data
            let array = []
            for (let i in response.results.data.content) {
              array.push (
                {
                  content_id: response.results.data.content[i].content_id,
                  description: response.results.data.content[i].description,
                  file_url: response.results.data.content[i].file_url,
                  id: response.results.data.content[i].id,
                  img_url: response.results.data.content[i].img_url,
                  last_watch_time: response.results.data.content[i].last_watch_time,
                  parent_id: response.results.data.content[i].parent_id,
                  read_bool: response.results.data.content[i].read_bool,
                  sub: response.results.data.content[i].sub,
                  sub_count: response.results.data.content[i].sub_count,
                  sub_proggress: response.results.data.content[i].sub_proggress,
                  sub_proggress_count: response.results.data.content[i].sub_proggress_count,
                  title: response.results.data.content[i].title,
                  total_time: response.results.data.content[i].total_time,
                  type: response.results.data.content[i].type,
                  read_more: false
                }
              )
              if (response.results.data.content[i].sub.length > 0) {
                array[i].sub = []
                for (let j in response.results.data.content[i].sub) {
                  array[i].sub.push (
                    {
                      content_id: response.results.data.content[i].sub[j].content_id,
                      description: response.results.data.content[i].sub[j].description,
                      file_url: response.results.data.content[i].sub[j].file_url,
                      id: response.results.data.content[i].sub[j].id,
                      img_url: response.results.data.content[i].sub[j].img_url,
                      last_watch_time: response.results.data.content[i].sub[j].last_watch_time,
                      parent_id: response.results.data.content[i].sub[j].parent_id,
                      read_bool: response.results.data.content[i].sub[j].read_bool,
                      sub_count: response.results.data.content[i].sub[j].sub_count,
                      title: response.results.data.content[i].sub[j].title,
                      total_time: response.results.data.content[i].sub[j].total_time,
                      type: response.results.data.content[i].sub[j].type,
                      read_more: false
                    }
                  )
                }
              }
            }
            this.detail.content = array
            this.playlis_materi = []
            for (let i in this.detail.content) {
              if (this.detail.content[i].file_url !== '') {
                this.playlis_materi.push(this.detail.content[i])
              }
              for (let j in this.detail.content[i].sub) {
                if (this.detail.content[i].sub[j].file_url !== '') {
                  this.playlis_materi.push(this.detail.content[i].sub[j])
                }
              }
            }
          } else{
            this.process.detail = false
          }
        })
      },
      play: function(event) {
        var a = this.$refs.videoRef;
        // this.paused_video = false
        if (a.paused) {
          a.play();
          this.paused_video = false
        } else {
          a.pause();
          this.paused_video = true
        }
      },
      playing(item) {
        for (let i in this.playlis_materi) {
          if (this.playlis_materi[i].id === item.id) {
            this.selected.index = i
          }
        }
        this.player.type = item.type
        this.player.data = item
        this.paused_video = false
        this.toRead(item.id)
      },
      playing_now() {
        for (let i in this.playlis_materi) {
          if (this.playlis_materi[i].id === this.detail.last_watch_id) {
            this.selected.index = parseInt(i)
            this.selected.sub = this.playlis_materi[parseInt(i)].id
          }
        }
        this.paused_video = false
        this.player.type = this.playlis_materi[this.selected.index].type
        this.player.data = this.playlis_materi[this.selected.index]
        this.toRead(this.playlis_materi[this.selected.index].id)
      },
      playing_next() {
        if (this.selected.sub === '') {
          this.selected.index = 1
          this.selected.sub = this.playlis_materi[1].id
        } else {
          // this.selected.index = parseInt(1)+1
          // this.selected.sub = this.playlis_materi[this.selected.index].id
          for (let i in this.playlis_materi) {
            if (this.playlis_materi[i].id === this.player.data.id) {
              this.selected.index = parseInt(i)+1
              this.selected.sub = this.playlis_materi[parseInt(i)+1].id
            }
          }
        }
        this.paused_video = false
        this.player.type = this.playlis_materi[this.selected.index].type
        this.player.data = this.playlis_materi[this.selected.index]
        this.toRead(this.playlis_materi[this.selected.index].id)
      },
      playing_previous() {
        if (this.selected.sub === '') {
          // this.selected.index = 1
          // this.selected.sub = this.playlis_materi[1].id
        } else {
          // this.selected.index = parseInt(1)+1
          // this.selected.sub = this.playlis_materi[this.selected.index].id
          for (let i in this.playlis_materi) {
            if (this.playlis_materi[i].id === this.player.data.id) {
              this.selected.index = parseInt(i)-1
              this.selected.sub = this.playlis_materi[parseInt(i)-1].id
            }
          }
        }
        this.paused_video = false
        this.player.type = this.playlis_materi[this.selected.index].type
        this.player.data = this.playlis_materi[this.selected.index]
        this.toRead(this.playlis_materi[this.selected.index].id)
      },
      toSaveReview() {
        this.review.unshift(
          {
            date: 'Kemarin, pukul 12:00',
            name: 'Anda',
            is_user: true,
            image: "https://picsum.photos/500/300?image=35",
            rate: 5,
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
          }
        )

        this.review_is_user = true
      },
      toRead(id) {
        this.process.read = true
        // let params = {
        //   id: this.$route.params.slug
        // }
        this.$axios.$post(`api/my_course/read_next_content`, { 
          id: id
         })
        .then((response)=>{
          if(response.status === 200) {
            this.process.detail = false
            for (let i in this.detail.content) {
              if (this.detail.content[i].id  === id) {
                this.detail.content[i].read_bool = true
              }
              if (this.detail.content[i].sub.length > 0) {
                for (let j in this.detail.content[i].sub) {
                  if (this.detail.content[i].sub[j].id === id) {
                    this.detail.content[i].sub[j].read_bool = true
                  }
                }
              }
            }
            this.fetchDetail()
          } else{
            this.process.read = false
          }
        })
      },
      toGetTime() {
        this.getTime = ""
        if (this.player.data.type !== 'docs') {
          var a = this.$refs.videoRef;
          a.pause();
          this.paused_video = true
          var vid = document.getElementById("myVideo");
          let timenow = vid.currentTime
          if (parseInt(timenow)/60>=1) {
            var h = Math.floor(timenow / 3600);
            timenow = timenow - h * 3600;               
            var m = Math.floor(timenow / 60);
            var s = Math.floor(timenow % 60);
            if(h.toString().length<2){h='0'+h;}
            if(m.toString().length<2){m='0'+m;}
            if(s.toString().length<2){s='0'+s;}
            this.getTime = h+':'+m+':'+s          
          } else {
            var m = Math.floor(timenow / 60);
            var s = Math.floor(timenow % 60);
            if(m.toString().length<2){m='0'+m;}
            if(s.toString().length<2){s='0'+s;}
            this.getTime = '00:'+m+':'+s
          }
        }
        this.dialog.addCatatan = true;
      }
    },
  };
</script>

<style>
  @media only screen and (min-width: 960px) {
    video {
      object-fit: fill;
      height: calc(100vh - 135px) !important;
    }
  }
  video {
      object-fit: fill;
      height: 250px;
    }
</style>