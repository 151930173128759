<template>
  <div>
    <DialogAuth :dialogauth="authDialog" @close="authDialog = ''" />
    <Player :dialogopen="dialog.player" :detailMateri="materi_preview" :detailKelas="detail" @close="dialog.player = false"/>

    <v-dialog v-model="dialog.share"
      width="400"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <div class="d-flex align-center body-2 mb-3">
          <div class="title text-second text--darken-2 font-weight-bold">
            Bagikan Kelas
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.share = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="subtitle-2 font-weight-bold text-second">
              Share Konten
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third" style="font-size: 10px;">
              Klik icon di bawah untuk share Konten ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                v-if="token"
                :network="item.network"
                :url="`${host}elearning/${detail.id}`"
                :title="detail.name"
                :description="detail.description"
                hashtags="otodidak">
                <v-img
                  alt="otodidak"
                  width="45"
                  height="45"
                  contain
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
              <nuxt-link v-else to="/login">
                <v-img
                  class="cursor-pointer"
                  width="45"
                  height="45"
                  contain
                  :src="item.icon">
                </v-img>
              </nuxt-link>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#4ab762"
            elevation="0"
            class="caption white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      v-if="dialog.checkout"
      v-model="dialog.checkout"
      width="500">
      <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
          Checkout
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.cart"
              class="text-capitalize px-0"
              @click="dialog.checkout = false; error.message = '';">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <div class="px-5 mt-5">
            <v-list flat three-line class="py-0">
              <v-list-item class="px-0">

                <v-list-item-avatar tile size="75" class="mr-3 my-0">
                  <v-img 
                    style="border-radius: 10px;"
                    :src="detail.img_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#4ab762">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <!-- <div class="badge-overlay-premium" v-if="item.sys_kelas_cart_kelas_is_discount">
                      <span class="top-left-premium badge-premium premium font-weight-bold">
                        {{ parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent) }}%
                      </span>
                    </div> -->
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content class="py-0">
                  <v-list-item-subtitle class="font-weight-bold text-first text-capitalize mt-n2"
                    :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-2' : 'fs-16'">
                    {{ detail.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="text-right" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : ''">
                  <v-list-item-title>

                    <!-- <div class="text-first body-1 font-weight-bold text-capitalize mt-0" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'display-none' : ''" style="min-height: 48px; margin-bottom: 6px;"> -->
                      <div class="text-decoration-line-through text-second fs-16" v-if="detail.discount_bool">
                        {{ detail.price | price }}
                      </div>
                      <span v-if="detail.discount_bool" class="red--text">
                        (-{{ detail.discount_percent }}%)
                      </span>
                      <span class="font-weight-bold">
                        <span class="" v-if="parseInt(detail.price) > 0">
                          <span v-if="!detail.discount_bool">
                            {{ detail.price | price }}
                          </span>
                          <span v-if="detail.discount_bool">
                            {{ detail.price_discount | price }}
                          </span>
                        </span>

                        <span class="green--text" v-else>
                          Gratis
                        </span>
                      </span>
                    <!-- </div> -->
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="px-5 pb-0">
            <div class="d-flex align-center justify-space-between fs-16 mb-4 mt-5">
              <span class="fs-16 font-weight-bold">Ringkasan Belanja</span>
            </div>

            <div class="d-flex align-center justify-space-between my-3 fs-16">
              <span class="fs-16">Total Harga ({{ selected.cart.length }} Kelas)</span>
              <span class="fs-16 font-weight-medium">{{ parseInt(detail.price) | price}}</span>
            </div>

            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between my-3">
              <span class="fs-16">Voucher Diskon <span v-if="Object.keys(voucher).length > 0">({{ voucher.discount_percent }}%)</span></span>
              <span class="fs-16 warning--text">
                <span v-if="Object.keys(voucher).length > 0" style="font-weight: 900;">-{{ parseInt(voucher.discount) | price }}</span>
                <span v-if="Object.keys(voucher).length < 1" class="color-blue-first cursor-pointer" @click="dialog.voucher = true;">Tambahkan</span>
              </span>
            </div>

            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between my-3">
              <span class="fs-16">Biaya Admin</span>
              <span class="fs-16 text-second" style="font-weight: 900;">
                <span v-if="Object.keys(selected.bank).length > 0">+{{ parseInt(selected.bank.admin_fee) | price }}</span>
                <span v-if="Object.keys(selected.bank).length < 1">Rp 0</span>
              </span>
            </div>

            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between mt-3 mb-5">
              <span class="fs-18" style="font-weight: 900;">Total Bayar</span>
              <span class="fs-18" style="font-weight: 900;">
                <span v-if="Object.keys(voucher).length > 0">
                  {{ selected.bank.admin_fee === undefined ? parseInt(detail.price_discount) - parseInt(voucher.discount) : parseInt(detail.price_discount) - parseInt(voucher.discount) + parseInt(selected.bank.admin_fee) | price }}
                </span>
                <span v-if="Object.keys(voucher).length < 1">
                  {{ selected.bank.admin_fee === undefined ? parseInt(detail.price_discount) : parseInt(detail.price_discount) + parseInt(selected.bank.admin_fee) | price }}
                </span>
              </span>
            </div>
          </div>

          <v-divider style="border-width: 5px; background: #D8D8D8; mix-blend-mode: normal; opacity: 0.2;"></v-divider>

          <div class="px-5">
            <div class="fs-16 taxt-first font-weight-bold text-left" style="margin: 25px 0px 20px 0px">
              Pilih Metode Pembayaran
            </div>
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.bank"
              v-for="(bank, i) in process.bank? 6 : bank" :key="i"
              type="list-item-avatar-two-line">
              <v-list class="py-0 mb_15">
                <v-list-item class="" style="background: #F9F9F9; border-radius: 6px; padding: 15px 20px;" :style="selected.bank.id === bank.id ? 'border: 1px solid #4ab762' : ''" @click="selected.bank = bank">
                  <v-list-item-content style="max-width: 30px;" class="text-right">
                    <v-icon :color="selected.bank.id === bank.id ? '#4ab762' : 'grey lighten-2'">mdi-check-circle</v-icon>
                  </v-list-item-content>
                  <v-list-item-content class="pt-3 ml-5">
                    <div class="fs-16 font-weight-bold" :style="selected.bank.id === bank.id ? 'color: #4ab762' : 'color: #757575'">
                      {{ bank.label }}
                    </div>
                    <v-list-item-subtitle class="caption mt-1" :style="selected.bank.id === bank.id ? 'color: #757575' : 'color: #BDBDBD'">
                      <div>
                        {{ bank.desc_web }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar
                    width="70"
                    tile
                    style="margin-top: 17px;"
                    height="100%"
                    class="align-self-start">
                    <v-img :src="bank.image" height="30px" class="my-auto" contain></v-img>
                  </v-list-item-avatar>
                </v-list-item>
              </v-list>
            </v-skeleton-loader>
          </div>
        </v-card-text>
        <v-card-actions style="padding: 15px 20px; display: grid;">
          <!-- <v-spacer></v-spacer> -->
          <div>
            <v-alert 
              type="error" 
              text
              dense
              class="mb-2"
              prominent
              :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : 'fs-14'"
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
          </div>
          <v-btn
            block
            elevation="0"
            color="#4ab762"
            height="50"
            :loading="process.cart"
            :disabled="process.cart || process.bank || process.checkout || Object.keys(selected.bank).length < 1"
            @click="checkout()"
            class="text-capitalize white--text">
            Checkout
          </v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      v-if="dialog.voucher"
      v-model="dialog.voucher"
      width="450">
      <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="text-second text--darken-2 font-weight-bold" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
          Pilih Voucher
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.voucher = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-divider></v-divider>
        <v-card-text class="px-5" style="min-height: 400px;">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.listVoucher"
            v-for="(item, i) in process.listVoucher? 6 : listVoucher" :key="i"
            type="list-item-avatar-two-line">
            <v-list>
              <v-list-item class="px-0" @click="detail_voucher = item; dialog.detail_voucher = true;">
                <v-list-item-content class="pt-3">
                  <div class="body-2 font-weight-bold">
                    {{ item.name }}
                  </div>
                  <v-list-item-subtitle class="caption mt-1">
                    {{ item.code }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="caption mt-1">
                    <span class="fs-12 green--text" v-if="detail.price >= item.min_order">
                      Dapat Digunakan
                    </span>
                    <span class="fs-12 red--text" v-if="detail.price < item.min_order">
                      Tidak Dapat Digunakan
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pt-3 text-right">
                  <div class="fs-12 text-second">
                    Diskon
                  </div>
                  <v-list-item-subtitle class="fs-12 mt-1 font-weight-bold primary--text">
                    {{ item.discount_percent }}%
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="fs-12 mt-1">
                    <span v-if="parseInt(item.discount_max) > 0">
                      (s.d {{ item.discount_max | price }})
                    </span>
                    <span v-if="parseInt(item.discount_max) === 0">
                      (s.d -)
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content style="max-width: 30px;" class="text-right">
                  <v-icon color="#4ab762">mdi-chevron-right</v-icon>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-if="i < listVoucher.length - 1"/>
          </v-skeleton-loader>
          <div v-if="!process.listVoucher && listVoucher.length < 1" class="text-center my-10">
            <v-img 
              :src="require('@/assets/images/empty.png')"
              :lazy-src="require('@/assets/images/empty.png')"
              class="ml-auto mr-auto"
              max-width="33%">
            </v-img>
            <p class="py-8 text-first body-2 font-weight-bold">Voucher Tidak Tersedia</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      v-if="dialog.detail_voucher"
      v-model="dialog.detail_voucher"
      width="450">
      <v-card class="box-shadow" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <div class="d-flex align-center body-2 px-5 py-5" @click="dialog.detail_voucher = false; dialog.voucher = true;">
          <div class="text-second text--darken-2 font-weight-bold d-flex align-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'title' : 'fs-18'">
            <v-icon>mdi-arrow-left</v-icon> 
            <div class="ml-2">
              Detail Voucher
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="px-0" style="min-height: 400px;">
          <div class="px-5 mt-4">
            <div class="body-2">
              Nama
            </div>
            <div class="body-2 font-weight-bold">
              {{ detail_voucher.name }}
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="px-5">
            <div class="body-2">
              Kode
            </div>
            <div class="body-2 font-weight-bold">
              {{ detail_voucher.code }}
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="px-5">
            <v-row>
              <v-col>
                <div class="body-2">
                  Minimal Pembelian
                </div>
                <div class="body-2 font-weight-bold warning--text">
                  {{ detail_voucher.min_order | price }}
                </div>
              </v-col>
              <v-col>
                <div class="body-2">
                  Diskon
                </div>
                <div class="body-2 font-weight-bold text-first">
                  <span class="color-blue-first">{{ detail_voucher.discount_percent }}%</span> s.d. {{ detail_voucher.discount_max | price }}
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="px-5">
            <v-row>
              <v-col>
                <div class="body-2">
                  Kuota Terpakai
                </div>
                <div class="body-2 font-weight-bold text-first">
                  {{ detail_voucher.quota_used }}
                </div>
              </v-col>
              <v-col>
                <div class="body-2">
                  Kuota Tersedia
                </div>
                <div class="body-2 font-weight-bold color-default">
                  {{ detail_voucher.quota_balance }}
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="px-5">
            <v-row>
              <v-col>
                <div class="body-2">
                  Dari Total Kuota
                </div>
                <div class="body-2 font-weight-bold text-first">
                  {{ detail_voucher.quota }}
                </div>
              </v-col>
              <v-col>
                <div class="body-2">
                  Limit Pengguna
                </div>
                <div class="body-2 font-weight-bold text-first">
                  <span v-if="parseInt(detail_voucher.user_limit) > 0">
                    <span class="warning--text">{{ detail_voucher.user_limit }}</span>x Penggunaan
                  </span>
                  <span v-if="parseInt(detail_voucher.user_limit) === 0">
                    Tidak Ada limit
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="px-5">
            <v-row>
              <v-col>
                <div class="body-2">
                  Tanggal Mulai
                </div>
                <div class="body-2 font-weight-bold text-first">
                  {{ detail_voucher.start_datetime |datetimemonth }}
                </div>
              </v-col>
              <v-col>
                <div class="body-2">
                  Tanggal Akhir
                </div>
                <div class="body-2 font-weight-bold text-first">
                  {{ detail_voucher.end_datetime |datetimemonth }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
            block
            elevation="0"
            height="48"
            v-if="detail.price >= detail_voucher.min_order"
            color="#4ab762"
            :loading="process.voucher"
            :disabled="process.voucher"
            @click="addVoucher()"
            class="text-capitalize white--text">
            Pakai Promo
          </v-btn>
          <v-btn
            block
            elevation="0"
            height="48"
            @click="dialog.detail_voucher = false; dialog.voucher = true;"
            v-if="detail.price < detail_voucher.min_order"
            color="red"
            class="text-capitalize white--text">
            Promo Tidak dapat Digunakan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.success"
      width="430"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      persistent>
      <v-card 
        height="530"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

          <v-col cols="12" class="pa-0">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  icon
                  @click="dialog.success = false; "
                  elevation="0"
                  class="color-green-second">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; " color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                Tambah Keranjang
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="250"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second title text-center mb-5">
                {{ success.message }}
              </div>
              <div class="text-center d-flex alogn-center justify-center">
                <v-btn
                  width="175"
                  color="#4ab762"
                  elevation="0"
                  to="/cart"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Lihat Keranjang
                </v-btn>
                <v-btn
                  width="100"
                  color="red"
                  outlined
                  elevation="0"
                  @click="dialog.success = false"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Tutup
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.cart"
      v-model="dialog.cart"
      width="400">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="title text-second text--darken-2 font-weight-bold">
            Tambah ke keranjang
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.cart"
              class="text-capitalize px-0"
              @click="dialog.cart = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah anda yakin ingin menambahkan kelas <b>{{ detail.name }}</b> ini ke keranjang?
          </div>
          <v-alert 
            type="error" 
            text
            dense
            class="mb-0"
            prominent
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-row>
            <v-col cols="7">
              <v-btn
                elevation="0"
                color="#4ab762"
                block
                :disabled="process.cart"
                :loading="process.cart"
                @click="addCart()"
                class="text-capitalize white--text px-4">
                <div class="d-flex align-center" style="width: 100%;">
                  <v-icon>mdi-check-circle-outline</v-icon>
                  <div class="ml-auto">
                    Ya, Tambahkan
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                elevation="0"
                color="red"
                outlined
                :disabled="process.cart"
                block
                @click="dialog.cart = false"
                class="text-capitalize white--text">
                Tidak
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#4ab762"
            width="220"
            @click="dialog.cart = false"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                Ya, Hapus
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            @click="dialog.cart = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn>
          <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-if="dialog.pay"
      v-model="dialog.pay"
      width="400">
      <v-card class="box-shadow border-radius">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div class="title text-second text--darken-2 font-weight-bold">
            Beli Kelas
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              :disabled="process.cart"
              class="text-capitalize px-0"
              @click="dialog.pay = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <div class="text-second font-weight-regular pt-8 pb-3 text-center" :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'body-1' : 'title'">
            Apakah anda yakin ingin membeli kelas <b>{{ detail.name }}</b> ini?
          </div>
          <v-alert 
            type="error" 
            text
            dense
            class="mb-0"
            prominent
            :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'caption' : ''"
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-row>
            <v-col cols="7">
              <v-btn
                elevation="0"
                color="#4ab762"
                block
                :disabled="process.cart"
                :loading="process.cart"
                @click="checkout()"
                class="text-capitalize white--text px-4">
                <div class="d-flex align-center" style="width: 100%;">
                  <v-icon>mdi-check-circle-outline</v-icon>
                  <div class="ml-auto">
                    Ya, Beli Kelas
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                elevation="0"
                color="red"
                outlined
                :disabled="process.cart"
                block
                @click="dialog.pay = false"
                class="text-capitalize white--text">
                Tidak
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#4ab762"
            width="220"
            @click="dialog.cart = false"
            class="text-capitalize white--text mx-1 px-4">
            <div class="d-flex align-center" style="width: 100%;">
              <v-icon>mdi-check-circle-outline</v-icon>
              <div class="ml-auto">
                Ya, Hapus
              </div>
            </div>
          </v-btn>

          <v-btn
            elevation="0"
            color="red"
            outlined
            width="120"
            @click="dialog.cart = false"
            class="text-capitalize white--text ml-2">
            Tidak
          </v-btn>
          <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.success_pay"
      width="430"
      :fullscreen="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? true : false"
      persistent>
      <v-card 
        height="530"
        color="#fff"
        :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'border-radius'">
        <v-row class="ma-0" justify="center" style="height: 100%;"  :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'align-center border-radius'">

          <v-col cols="12" class="pa-0">
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? 'd-flex align-cemter py-3 px-5' : 'display-none'">
              <v-img
                class="cursor-pointer"
                contain
                :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
                :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; left: 25px; top: 25px;'"
                :src="require('@/assets/icon/logo.png')"
                :lazy-src="require('@/assets/icon/logo.png')">
              </v-img>
              <v-card-text class="pa-0 text-right ml-auto" :style="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'position: absolute; top: 20px; left: -20px;'" v-if="fps === 'xs' || fps === 'sm'">
                <v-btn
                  icon
                  @click="dialog.success_pay = false; "
                  elevation="0"
                  class="color-green-second">
                  <v-icon size="25" class="cursop-pointer" color="#4ab762">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
            </div>
            <!-- <v-img
              class="cursor-pointer"
              :max-width="fps === 'xs' || fps === 'sm' ? 90 : 130"
              style="position: absolute; left: 25px; top: 25px;"
              :src="require('@/assets/icon/logo.png')"
              :lazy-src="require('@/assets/icon/logo.png')">
            </v-img>
            <v-card-text class="pa-0 text-right" style="position: absolute; top: 25px; left: -20px;" v-if="fps === 'xs' || fps === 'sm'">
              <v-icon size="25" class="cursop-pointer" @click="dialog.reset = false; " color="#4ab762">mdi-close-circle-outline</v-icon>
            </v-card-text> -->
            <v-divider :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl' ? '' : 'display-none'" ></v-divider>
            <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'px-5 mt-10' : 'px-10'">
              <div :class="fps !== 'lg' && fps !== 'md' && fps !== 'xl'  ? 'text-center font-weight-bold body-1' : 'text-center title'">
                Berhasil Beli Kelas
              </div>
              <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;" class="mx-auto mt-5"></v-divider>
              <div class="pt-10 pb-5">
                <v-img
                  :src="require('@/assets/payment/img_payment_success.png')" 
                  width="250"
                  class="mx-auto"
                  height="fit-content">
                </v-img>
              </div>
              <div class="text-second title text-center mb-5">
                {{ success.message }}
              </div>
              <div class="text-center d-flex alogn-center justify-center">
                <v-btn
                  width="175"
                  color="#4ab762"
                  elevation="0"
                  to="/account/collection"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Lihat Koleksi Kelas
                </v-btn>
                <v-btn
                  width="100"
                  color="red"
                  outlined
                  elevation="0"
                  @click="dialog.success_pay = false"
                  class="fs-14 font-weight-bold white--text mx-2 text-capitalize">
                  Tutup
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-container>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        centered
        top
        style="margin-top: -80px;"
        color="#27ae60">
        <div class="body-1 font-weight-bold">{{ success.message }}</div>
      </v-snackbar>
      <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
        <section class="py-10">
          <div v-if="process.detail">
            <v-row>
              <v-col cols="8">
                <v-skeleton-loader
                  max-width="300"
                  :loading="process.run"
                  type="text"
                ></v-skeleton-loader>
                <div class="mt-5 mb_30">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="text"
                  ></v-skeleton-loader>
                </div>
                <div>
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="mb-5 pt-5 d-flex align-center">
                  <div class="d-flex align-center">
                    <v-skeleton-loader
                      :loading="process.run"
                      type="button"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      :loading="process.run"
                      type="button"
                      class="ml-3"
                    ></v-skeleton-loader>
                  </div>
                  <div class="ml-auto">
                    <v-skeleton-loader
                      :loading="process.run"
                      type="button"
                    ></v-skeleton-loader>
                  </div>
                </div>
                <v-skeleton-loader
                  :loading="process.run"
                  type="paragraph"
                ></v-skeleton-loader>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
              </v-col>
              <v-col cols="4">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="300"
                  :loading="process.run"
                  type="image, article"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
          <div v-if="!process.detail">
            <v-row>
              <v-col cols="8">
                <div class="text-second body-1">
                  Elearning |  {{ detail.category_name }}
                </div>
                <div class="text-first fs-28 font-weight-bold mt-5">
                  {{ detail.name }}
                </div>
                <div class="text-second fs-22 line-text-first" style="margin-top: 48px;">
                  {{ detail.description }}
                </div>
                <div class="d-flex align-center body-2 text-second my-4">
                  <div class="text-second fs-14 text--darken-3 font-weight-bold">
                    {{ detail.review_average }}
                  </div>
                  <div class="mx-2">
                    <v-rating
                      :value="detail.review_average*1"
                      background-color="grey"
                      color="#e67e22"
                      dense
                      readonly
                      half-increments
                      hover
                      size="20">
                    </v-rating>
                  </div>
                  <div class="fs-14">
                    ({{ detail.review_count }})
                  </div>
                </div>
                <div class="mb-5 pt-3 d-flex align-center">
                  <div>
                    <v-btn 
                      elevation="0"
                      outlined
                      height="40"
                      width="120"
                      color="#000000"
                      @click="!token ? authDialog = 'login' : dialog.share = true"
                      class="mr-3 text-capitalize rounded-md">
                      <v-icon size="15" class="mr-2">mdi-share-variant</v-icon>
                      Bagikan
                    </v-btn>
                    <v-btn 
                      elevation="0"
                      outlined
                      height="40"
                      width="120"
                      color="#d35400"
                      @click="!token ? authDialog = 'login' : detail.course_wish_id.length < 1 ? addWishlist() : removeWishlist()"
                      class="mr-3 text-capitalize rounded-md">
                      <v-icon size="20" class="mr-2" v-if="detail.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                      <v-icon size="20" class="mr-2" :color="'pink'" v-if="detail.course_wish_id !== ''">mdi-heart</v-icon>
                      Wishlist
                    </v-btn>
                  </div>
                  <div class="ml-auto">
                    <v-btn 
                      elevation="0"
                      outlined
                      height="40"
                      color="#00C09A"
                      :href="`/organitation/${detail.organization_id}`"
                      target="_blank"
                      width="200"
                      class="mr-3 text-capitalize font-weight-bold rounded-md">
                      <div class="d-flex align-center" style="width: 100%;">
                        <div>
                          <v-img :src="detail.organization_img_url" style="width: 25px;" v-if="detail.organization_img_url !== ''"></v-img>
                          <v-avatar color="#ededed" size="30" v-if="detail.organization_img_url === ''">
                            <v-icon size="18">mdi-office-building</v-icon>
                          </v-avatar>
                        </div>
                        <div class="ml-auto">
                          {{ detail.organization_name }}
                        </div>
                      </div>
                    </v-btn>
                  </div>
                </div>
      
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="grey darken-3"
                  style="margin-top: 78px;"
                  dense
                  grow>
                  <v-tabs-slider color="#4ab762"></v-tabs-slider>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    style="min-width: 55px; max-width: 135px;">
                    <div>
                      <div class="body-1 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                        {{ item === 'Materi Kelas' ? `Materi Kelas (${(detail.content_count*1 + detail.content_sub_count*1)})` :  item === 'Review' ? `Review (${(detail.review_count*1)})` :  item }}
                      </div>
                      <!-- <v-divider v-if="selected.menu === index" class="mt-1" style="border: 2px solid #4ab762; border-radius: 6px;"></v-divider>
                      <v-divider v-if="selected.menu !== index" class="mt-1" style="border: 2px solid white; border-radius: 6px;"></v-divider> -->
                    </div>
                  </v-tab>
                </v-tabs>
      
                <v-tabs-items v-model="selected.menu">
                  <v-tab-item
                    v-for="(item, index) in menu"
                    :key="index">
      
                    <v-card flat v-if="selected.menu === 0" class="mt_25">
                      <v-row>
                        <v-col cols="12" class="px-0">
                          <v-card flat>
                            <v-card-text class="line-text-first text-second py-2 body-1">
                              {{ detail.description_long }}
                            </v-card-text>
                            <v-card-text class="line-text-first text-first text-h6 font-weight-bold py-3">
                              Apa Yang Akan dipelajari?
                            </v-card-text>
                            <v-card-text class="line-text-first text-second py-2 body-1">
                              {{ detail.subject }}
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
      
                    <v-card flat v-if="selected.menu === 1" class="mt-5">
                      <div class="d-flex align-center mt_23">
                        <div class="text-first fs-16 font-weight-bold">
                          {{ detail.content_count }} Materi • {{ detail.content_sub_count }} Sub materi 
                          <!-- • Total durasi 38h 28m -->
                        </div>
                        <div class="ml-auto">
                          <v-btn
                            text
                            color="#505050"
                            @click="!openAll ? all() : panel = []; !openAll ? openAll = true :  openAll = false;"
                            class="text-capitalize">
                            <u v-if="!openAll">Expand all</u>
                            <u v-if="openAll">Close all</u>
                          </v-btn>
                        </div>
                      </div>

                      <div class="mt-4">
                        <v-expansion-panels flat class="mb-3" multiple v-model="panel">
                          <v-expansion-panel class="mb-3" v-for="(materi, i) in detail.content" :key="i">
                            <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: 55px;">
                              <template v-slot:actions>
                                <div>
                                  <v-icon class="px-0" style="order: 0;" color="#7B7B7B">
                                    $expand
                                  </v-icon>
                                </div>
                              </template>
                              <v-list dense style="order: 1;" class="py-0 pl-6" color="transparent">
                                <v-list-item class="py-0 px-0">
                                  <div class="d-flex align-center" style="width: 100%;">
                                    <div class="body-1 ml-0 font-weight-bold text-capitalize">
                                      {{ materi.title }}
                                    </div>
                                    <div class="body-2 text-first ml-auto">
                                      <!-- 11 lectures - 26min -->
                                    </div>
                                  </div>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="non-padding-expansion">
                              <div style="padding: 17px 20px;">
                                <div class="d-flex">
                                  <div v-if="materi.file_url !== ''">
                                    <div
                                      style="min-width: 50px; width: 50px; padding-top: 23.5px">
                                      <v-icon 
                                        size="25"
                                        :color="'grey'">
                                        {{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                      </v-icon>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="d-flex">
                                      <div class="mr-6">
                                        <v-img
                                          width="75"
                                          height="75"
                                          class="d-flex align-center justify-center"
                                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                          :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                                          style="border-radius: 5px;">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </div>

                                      <div class="body-1 font-weight-light text-second"
                                        style="line-height: 1.75;">
                                        <div v-if="materi.description !== ''">
                                          <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="materi.description.length > 200">
                                            <div v-if="materi.preview_img_url === ''">
                                              <span class="body-2" style="white-space: pre-line;" v-html="materi.description.substring(0, 200) + '...'">
                                              </span>
                                              <span class="text-second cursor-pointer mt-5" @click="materi.preview_img_url = 'oke'"><u>Selengkapnya</u></span>
                                            </div>
                                            <div v-if="materi.preview_img_url !== ''">
                                              <span class="body-2 mr-3" style="white-space: pre-line;" v-html="materi.description"></span>
                                              <span class="text-second cursor-pointer mt-0" @click="materi.preview_img_url = ''"><u>Tutup</u></span>
                                            </div>
                                          </div>
                                          <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="materi.description.length <= 200">
                                            <div class="body-2" style="white-space: pre-line;" v-html="materi.description"></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="ml-auto pl-6" v-if="materi.preview_bool" style="color: #4ab762; padding-top: 23.5px;">
                                        Preview
                                      </div>
                                    </div>
                                    <div v-for="(sub_materi, index) in materi.sub" :key="index">
                                      <div style="padding: 17px 0 17px 20px;">
                                        <div class="d-flex">
                                          <div>
                                            <div
                                              style="min-width: 75px; width: 75px; padding-top: 23.5px">
                                              <v-icon 
                                                size="25"
                                                :color="'grey'">
                                                {{ sub_materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                              </v-icon>
                                            </div>
                                          </div>
                                          <div class="mr-6">
                                            <v-img
                                              width="75"
                                              height="75"
                                              class="d-flex align-center justify-center"
                                              gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                              :src="sub_materi.img_url === '' ? detail.img_url : sub_materi.img_url"
                                              style="border-radius: 5px;">
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height"
                                                  align="center"
                                                  justify="center">
                                                  <v-progress-circular 
                                                    indeterminate 
                                                    color="#FF3177">
                                                  </v-progress-circular>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </div>

                                          <div class="body-1 font-weight-light text-second">
                                            <span
                                              class="text-second">
                                              <div class="body-2 font-weight-bold mb-1">
                                                {{ sub_materi.title }}
                                              </div>
                                              <div v-if="sub_materi.description !== ''">
                                                <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="sub_materi.description.length > 150">
                                                  <div v-if="sub_materi.preview_img_url === ''">
                                                    <span class="body-2" style="white-space: pre-line;" v-html="sub_materi.description.substring(0, 150) + '...'">
                                                    </span>
                                                    <span class="text-second cursor-pointer mt-5" @click="sub_materi.preview_img_url = 'oke'"><u>Selengkapnya</u></span>
                                                  </div>
                                                  <div v-if="sub_materi.preview_img_url !== ''">
                                                    <span class="body-2 mr-3" style="white-space: pre-line;" v-html="sub_materi.description"></span>
                                                    <span class="text-second cursor-pointer mt-0" @click="sub_materi.preview_img_url = ''"><u>Tutup</u></span>
                                                  </div>
                                                </div>
                                                <div class="font-weight-light body-2 text-second text--darken-2 mb-0" v-if="sub_materi.description.length <= 150">
                                                  <div class="body-2" style="white-space: pre-line;" v-html="sub_materi.description"></div>
                                                </div>
                                              </div>
                                            </span>
                                          </div>

                                          <div class="ml-auto pl-6" v-if="sub_materi.preview_bool" style="color: #4ab762; padding-top: 23.5px;">
                                            Preview
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <!-- <v-expansion-panel>
                            <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: 55px;">
                              <template v-slot:actions>
                                <div>
                                  <v-icon class="px-0" style="order: 0;" color="#7B7B7B">
                                    $expand
                                  </v-icon>
                                </div>
                              </template>
                              <v-list dense style="order: 1;" class="py-0 pl-6" color="transparent">
                                <v-list-item class="py-0 px-0">
                                  <div class="d-flex align-center" style="width: 100%;">
                                    <div class="body-1 ml-0 font-weight-bold text-capitalize">
                                      Working with Contents
                                    </div>
                                    <div class="body-2 text-first ml-auto">
                                      11 lectures - 26min
                                    </div>
                                  </div>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="non-padding-expansion">
                              <div style="padding: 17px 20px;">
                                <div class="d-flex align-center mb-3">
                                  
                                  <div class="">
                                    <v-img
                                      width="75"
                                      height="75"
                                      class="d-flex align-center justify-center"
                                      gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                      :src="require('@/assets/publichome/content_image_6.png')"
                                      style="border-radius: 5px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </div>

                                  <div class="body-1 ml_18 font-weight-light text-second"
                                    style="line-height: 1.75;">
                                    <span
                                      class="three-line text-second">
                                      React Native is an excellent solution for developing apps on mobile in a fraction of the time it
                                      takes to make an equivalent iOS or Swift app.
                                      You'll love seeing your changes instantly
                                      appear on your own device, rather than waiting for Swift/Java code to
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <div class="d-flex align-center mb-3">
                                    <div
                                      class="text-center"
                                      style="min-width: 80px; width: 80px;">
                                      <v-icon 
                                        size="25"
                                        :color="'grey'">
                                        mdi-television-play
                                      </v-icon>
                                    </div>
                                    
                                    <div class="ml_18">
                                      <v-img
                                        width="75"
                                        height="75"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="require('@/assets/publichome/content_image_6.png')"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </div>

                                    <div class="body-1 ml_18 font-weight-light text-second"
                                      style="line-height: 1.75;">
                                      <span
                                        class="three-line text-second">
                                        React Native is an excellent solution for developing apps on mobile in a fraction of the time it
                                        takes to make an equivalent iOS or Swift app.
                                        You'll love seeing your changes instantly
                                        appear on your own device, rather than waiting for Swift/Java code to
                                      </span>
                                    </div>

                                    <div class="ml-auto pl-6" style="color: #4ab762;">
                                      Preview
                                    </div>
                                  </div>
                                  <div class="d-flex align-center">
                                    <div
                                      class="text-center"
                                      style="min-width: 80px; width: 80px;">
                                      <v-icon 
                                        size="25"
                                        :color="'grey'">
                                        mdi-television-play
                                      </v-icon>
                                    </div>
                                    
                                    <div class="ml_18">
                                      <v-img
                                        width="75"
                                        height="75"
                                        class="d-flex align-center justify-center"
                                        :src="require('@/assets/publichome/content_image_6.png')"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </div>

                                    <div class="body-1 ml_18 font-weight-light text-second"
                                      style="line-height: 1.75;">
                                      <span
                                        class="three-line text-second">
                                        React Native is an excellent solution for developing apps on mobile in a fraction of the time it
                                        takes to make an equivalent iOS or Swift app.
                                        You'll love seeing your changes instantly
                                        appear on your own device, rather than waiting for Swift/Java code to
                                      </span>
                                    </div>
                                    <div class="ml-auto pl-6" style="color: #4ab762;">
                                      Preview
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel> -->
                        </v-expansion-panels>
                      </div>
                    </v-card>
      
                    <v-card flat v-if="selected.menu === 2">
                      <v-row class="align-center mt-5">
                        <v-col cols="12" md="3" class="text-center">
                          <div class="text-h2 text-second font-weight-bold">
                            {{ detail.review_average }}
                          </div>
                          <v-rating dense
                            :value="detail.review_average*1"
                            readonly
                            color="#F0932B"
                            background-color="grey darken-1"
                            half-increments>
                          </v-rating>
                          <div class="text-second mt-2 body-1">
                            ({{ detail.review_count }})
                          </div>
                        </v-col>
                        <v-col cols="12" md="9" class="pl-0">
                          <div 
                            v-for="(item, index) in detail.review_rate"
                            :key="index">
                            <div class="d-flex align-center" style="100%" v-if="item.value !== 0">
                              <!-- <div class="pt-1"> -->
                                <!-- <v-progress-linear value="15"></v-progress-linear> -->
                              <v-progress-linear
                                :color="'#F0932B'"
                                height="10"
                                rounded
                                :value="item.percent === '' ? 0 : item.percent"
                                style="width: 77%;"
                                class="cursor-pointer">
                              </v-progress-linear>
                              <!-- </div> -->
                              <div 
                                class="mr-auto pl-3">
                                <v-icon 
                                  small
                                  color="#F0932B" 
                                  v-for="(s, index) in item.value" 
                                  :key="index">
                                  mdi-star
                                </v-icon>
                              </div>
                              <div 
                                class="ml-auto caption text-second body-1 pr-2 py-0 pt-2">
                                <span>
                                  {{ item.percent === '' ? 0 : item.percent }}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="text-first text-h6 my-8 px-5 font-weight-bold">
                        Reviews atau Ulasan
                      </div>
                      <v-row class="px-5">
                        <v-col cols="8">
                          <v-text-field
                            solo
                            hide-details
                            clearable
                            color="#4ab762"
                            v-model="filter.search"
                            v-on:keyup.enter="fetchReview()"
                            @click:clear="filter.search = ''; fetchReview();"
                            :placeholder="`Cari ulasan`">
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-2">mdi-magnify</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col :cols="4" class="d-flex align-center">
                          <v-select
                            :items="[
                              {
                                text: 'Semua Rating',
                                value: ''
                              },
                              {
                                text: '1',
                                value: 1
                              },
                              {
                                text: '2',
                                value: 2
                              },
                              {
                                text: '3',
                                value: 3
                              },
                              {
                                text: '4',
                                value: 4
                              },
                              {
                                text: '5',
                                value: 5
                              }
                            ]"
                            solo
                            @keypress="fetchReview()"
                            @blur="fetchReview()"
                            @change="fetchReview()"
                            item-text="text"
                            item-value="value"
                            color="#4ab762"
                            item-color="green"
                            hide-details
                            v-model="filter.rating"
                            placeholder="Filter">
                            <template v-slot:selection="data">
                              <div v-if="data.item.value !== ''">
                                <v-icon v-for="l in data.item.value" :key="l">mdi-star</v-icon>
                              </div>
                              <div v-if="data.item.value === ''">
                                {{ data.item.text }}
                              </div>
                            </template>
                            <template v-slot:item="data">
                              <div v-if="data.item.value !== ''">
                                <v-icon v-for="l in data.item.value" :key="l">mdi-star</v-icon>
                              </div>
                              <div v-if="data.item.value === ''">
                                {{ data.item.text }}
                              </div>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <div>
                        <div v-if="process.review">
                          <v-skeleton-loader
                            class="ma-auto"
                            v-for="r in 4"
                            :key="r"
                            :loading="process.review"
                            type="list-item-avatar-three-line">
                          </v-skeleton-loader>
                        </div>
                        <div v-if="!process.review">
                          <v-list dense class="my-3" color="transparent">
                            <v-list-item
                              v-for="(l, index) in review"
                              :key="index">
                              <v-list-item-avatar size="60" tile class="align-self-start">
                                <v-img
                                  :src="l.user_img_url"
                                  v-if="l.user_img_url !== ''"
                                  style="border-radius: 6px;">
                                </v-img>
                                <v-icon size="80" v-if="l.user_img_url === ''">mdi-account-box</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content class="cursor-pointer">
                                <div class="d-flex align-start">
                                  <div>
                                    <v-list-item-title class="body-1 font-weight-bold">
                                      {{ l.user_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="mt-2">
                                      <v-rating dense
                                        :value="l.rate*1"
                                        readonly
                                        color="#F0932B"
                                        background-color="grey darken-1">
                                      </v-rating>
                                    </v-list-item-subtitle>
                                  </div>
                                  <div class="text-second body-2 ml-auto">
                                    {{ l.last_update_datetime | datetimemonth }}
                                  </div>
                                </div>
                                <v-list-item-content class="text-second line-text-second body-2">
                                  {{ l.text }}
                                </v-list-item-content>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </div>
                      <div class="text-right mt-16" v-if="!process.review && review.length < 1">
                        <Empty v-model="contentEmpty"/>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                <Pagination 
                  @changePage="fetchReview($event)" 
                  :model="pagination"
                  class="pt-6"
                  v-show="pagination.total_page > 1"/>
              </v-col>
              <v-col cols="4">
                <v-img
                  width="100%"
                  height="440"
                  contain
                  @click="class_is_preview ? dialog.player = true : ''"
                  class="d-flex align-center justify-center"
                  :gradient="'to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)'"
                  :src="detail.img_url"
                  style="border-radius: 15px;">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
      
                  <div class="d-flex align-center justify-center font-weight-black" v-if="class_is_preview">
                    <div class="text-center">
                      <v-icon size="95" color="#FFFFFF">mdi-play-circle</v-icon> 
                      <div class="white--text text-h5">
                        Preview Kelas
                      </div>
                    </div>
                  </div>
                </v-img>
                <div class="d-flex align-center text-h6 my-6" style="background-color: #ecf0f1; border-radius: 8px;">
                  <div class="pa-3 font-weight-bold white--text text-center fs-16" style="background-color: #d35400; border-radius: 12px; width: 30%;" v-if="detail.discount_bool">
                    <span v-if="detail.discount_percent_admin > 0">{{ detail.discount_percent_admin }}%</span> 
                    <span v-if="detail.discount_percent_admin > 0 && detail.discount_percent_publisher > 0">+</span>
                    <span v-if="detail.discount_percent_publisher > 0">{{ detail.discount_percent_publisher }}%</span>
                  </div>
                  <div class="text-first text-h6 font-weight-bold pa-3" style="background-color: #ecf0f1; border-radius: 8px; width: 70%;">
                    <!-- <span class="text-decoration-line-through text-second mr-2">
                      Rp. 150.000
                    </span>
                    <span>
                      {{ detail.price | price }}
                    </span> -->
                    <span class="text-decoration-line-through text-second mr-2" v-if="detail.discount_bool">
                      {{ detail.price | price }}
                    </span>
                    <span class="font-weight-bold red--text">
                      <span class="text-first" v-if="parseInt(detail.price) > 0">
                        <span v-if="!detail.discount_bool">
                          {{ detail.price | price }}
                        </span>
                        <span v-if="detail.discount_bool">
                          {{ detail.price_discount | price }}
                        </span>
                      </span>

                      <span class="green--text" v-else>
                        Gratis
                      </span>
                    </span>
                  </div> 
                </div>
                <div class="d-flex align-center" v-if="detail.publisher_user_id !== parseInt(this.user.id)">
                  <v-btn
                    color="#4ab762"
                    width="70%"
                    x-large
                    v-if="(!detail.subscribed_bool && !detail.subscribtion_expired_bool) || (detail.subscribed_bool && detail.subscribtion_expired_bool)"
                    :disabled="detail.subscribed_bool && !detail.subscribtion_expired_bool"
                    elevation="0"
                    @click="!token ? authDialog = 'login' : detail.subscribed_bool && !detail.subscribtion_expired_bool ? '' : parseInt(detail.price) < 1 ? dialog.pay = true : toCheckout(); error.message = '';"
                    style="border-radius: 8px;"
                    class="text-capitalize white--text">
                    Beli Sekarang
                  </v-btn>
                  <v-btn
                    color="#4ab762"
                    width="70%"
                    x-large
                    v-if="token && detail.subscribed_bool && !detail.subscribtion_expired_bool"
                    elevation="0"
                    :to="`/collection/${detail.user_course_id}`"
                    style="border-radius: 8px;"
                    class="text-capitalize white--text">
                    Lihat Kelas
                  </v-btn>
                  <div class="ml-auto">
                    <v-btn
                      icon
                      fab
                      small
                      :disabled="detail.subscribed_bool && !detail.subscribtion_expired_bool"
                      elevation="0"
                      @click="!token ? authDialog = 'login' : detail.subscribed_bool && !detail.subscribtion_expired_bool ? '' : dialog.cart = true; error.message = '';"
                      color="#FFFFFF"> 
                      <div class="px-3">
                        <v-icon color="#3498db">mdi-cart-plus</v-icon>
                      </div>
                    </v-btn>
                    <!-- <v-icon size="30" color="#3498db" class="mx-2">mdi-cart-plus</v-icon> -->
                    <!-- <v-icon size="30" color="#d35400">mdi-heart-outline</v-icon> -->
                    <span v-if="token">
                      <v-icon size="30" color="#d35400" @click="!token ? authDialog = 'login' : detail.course_wish_id.length < 1 ? addWishlist() : removeWishlist()" v-if="detail.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                      <v-icon size="30" :color="'pink'" @click="!token ? authDialog = 'login' : detail.course_wish_id.length < 1 ? addWishlist() : removeWishlist()" v-if="detail.course_wish_id !== ''">mdi-heart</v-icon>
                    </span>
                    <span v-if="!token">
                      <v-icon size="30" color="#d35400" @click="authDialog = 'login'">mdi-heart-plus-outline</v-icon>
                    </span>
                  </div>
                </div>
                <div class="text-second mt-3">
                  30-Day Money-Back Guarantee
                </div>
              </v-col>
            </v-row>
            <v-card flat v-if="selected.menu === 0" style="margin-top: 60px;">
              <div class="d-flex align-center mt-5 pb-10">
                <div>
                  <div class="text-first text-h6 font-weight-bold">
                    Kelas Terkait
                  </div>
                  <v-divider style="border: 2px solid #4ab762; width: 67px; border-radius: 6px;"></v-divider>
                </div>
                <div class="text-second ml-auto">
                  <span class="cursor-pointer">
                    <router-link to="/elearning" class="text-second">
                      Lihat Semua
                    </router-link>
                  </span>
                </div>
              </div>
              <v-row v-if="list.length > 0">
                <v-col cols="3" v-for="(t, k) in list" :key="k">
                  <v-card :to="`/elearning/${t.id}`" flat>
                    <v-img :src="t.img_url" style="border-radius: 15px;" height="300"></v-img> 
                    <div class="text-first text-h6 font-weight-bold mt-4 two-line" style="height: 64px;">
                      {{ t.name }}
                    </div> 
                    <v-list-item class="px-0">
                      <v-list-item-avatar size="25" color="#ebebeb">
                        <v-img :src="t.organization_img_url" v-if="t.organization_img_url !== ''"></v-img>
                        <v-icon v-if="t.organization_img_url === ''" size="18">mdi-office-building</v-icon>
                      </v-list-item-avatar>
          
                      <v-list-item-content>
                        <v-list-item-title class="text-second fs-14">{{ t.organization_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <div class="d-flex align-center body-2 text-second">
                      <div class="text-first font-weight-bold">
                        {{ t.review_average }}
                      </div>
                      <div class="mx-2">
                        <v-rating
                          :value="t.review_average*1"
                          background-color="grey"
                          color="#e67e22"
                          dense
                          half-increments
                          hover
                          size="20">
                        </v-rating>
                      </div>
                      <div class="">
                        ({{ t.review_count }})
                      </div>
                    </div>
                    <div class="text-first text-h6 font-weight-bold py-2">
                      {{ t.price | price }}
                    </div> 
                  </v-card>
                </v-col>
              </v-row>
              <div class="text-right mt-0" v-if="list.length < 1">
                <Empty v-model="contentEmpty"/>
              </div>
            </v-card>
          </div>
        </section>
      </div>


      <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
        <section class="mt-n5">
          <div v-if="process.detail">
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="100%"
                  :loading="process.run"
                  type="image"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12">
                <v-skeleton-loader
                  max-width="300"
                  :loading="process.run"
                  type="text"
                ></v-skeleton-loader>
                <div class="mt-5 mb_30">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="text"
                  ></v-skeleton-loader>
                </div>
                <div>
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="mb-5 pt-5 d-flex align-center">
                  <div class="d-flex align-center">
                    <v-skeleton-loader
                      :loading="process.run"
                      type="button"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      :loading="process.run"
                      type="button"
                      class="ml-3"
                    ></v-skeleton-loader>
                  </div>
                  <div class="ml-auto">
                    <v-skeleton-loader
                      :loading="process.run"
                      type="button"
                    ></v-skeleton-loader>
                  </div>
                </div>
                <v-skeleton-loader
                  :loading="process.run"
                  type="paragraph"
                ></v-skeleton-loader>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
                <div class="my-8">
                  <v-skeleton-loader
                    :loading="process.run"
                    type="paragraph"
                  ></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
          </div>

          <div v-if="!process.detail">
            <v-img
              width="100%"
              height="250"
              class="d-flex align-center justify-center"
              gradient="to top right, rgba(0,0,0,.6), rgba(0,0,0,.4)"
              :src="detail.img_url"
              @click="class_is_preview ? dialog.player = true : ''"
              style="border-radius: 0px;">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>

              <div class="d-flex align-center justify-center font-weight-black" v-if="class_is_preview">
                <div class="text-center">
                  <v-icon size="95" color="#FFFFFF">mdi-play-circle</v-icon> 
                  <div class="white--text text-h5">
                    Preview Kelas
                  </div>
                </div>
              </div>
            </v-img>
            <v-row>
              <v-col cols="12" md="8" class="px-4">
                <div class="text-second caption mt-3">
                  Elearning |  {{ detail.category_name }}
                </div>
                <div class="text-first body-1 font-weight-bold my-5">
                  {{ detail.name }}
                </div>
                <div class="text-second body-2 line-text-first">
                  {{ detail.description }}
                </div>
                <div class="d-flex align-center caption text-second my-5">
                  <div class="text-second caption text--darken-3 font-weight-bold">
                    {{ detail.review_average }}
                  </div>
                  <div class="mx-2">
                    <v-rating
                      :value="detail.review_average*1"
                      background-color="grey"
                      color="#e67e22"
                      dense
                      half-increments
                      hover
                      size="20">
                    </v-rating>
                  </div>
                  <div class="caption">
                    ({{ detail.review_count }})
                  </div>
                </div>
                <div class="mb-2 pt-1">
                  <div class="ml-auto">
                    <v-btn 
                      elevation="0"
                      outlined
                      color="#5fc29a"
                      width="150"
                      :href="`/organitation/${detail.organization_id}`"
                      target="_blank"
                      class="mr-3 text-capitalize font-weight-bold rounded-md">
                      <div class="d-flex align-center" style="width: 100%;">
                        <div>
                          <v-img :src="detail.organization_img_url" style="width: 15px;" v-if="detail.organization_img_url !== ''"></v-img>
                            <v-avatar color="#ededed" size="20" v-if="detail.organization_img_url === ''">
                              <v-icon size="15">mdi-office-building</v-icon>
                            </v-avatar>
                        </div>
                        <div class="ml-auto">
                          {{ detail.organization_name }}
                        </div>
                      </div>
                    </v-btn>
                  </div>
                </div>
      
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="grey darken-3"
                  class="mt-10"
                  dense
                  grow>
                  <v-tabs-slider color="#4ab762"></v-tabs-slider>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    style="min-width: 55px; max-width: 135px;">
                    <div>
                      <div class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                        {{ item === 'Materi Kelas' ? `Materi Kelas (${(detail.content_count*1 + detail.content_sub_count*1)})` : item === 'Review' ? `Review (${(detail.review_count*1)})` : item }}
                      </div>
                      <!-- <v-divider v-if="selected.menu === index" class="mt-1" style="border: 2px solid #4ab762; border-radius: 6px;"></v-divider>
                      <v-divider v-if="selected.menu !== index" class="mt-1" style="border: 2px solid white; border-radius: 6px;"></v-divider> -->
                    </div>
                  </v-tab>
                </v-tabs>
      
                <v-tabs-items v-model="selected.menu">
                  <v-tab-item
                    v-for="(item, index) in menu"
                    :key="index">
      
                    <v-card flat v-if="selected.menu === 0">
                      <v-row>
                        <v-col cols="12" class="px-0">
                          <v-card flat>
                            <v-card-text class="line-text-first text-second py-2 body-2">
                              {{ detail.description_long }}
                            </v-card-text>
                            <v-card-text class="line-text-first text-first body-1 font-weight-bold py-3">
                              Apa Yang akan Dipelajari?
                            </v-card-text>
                            <v-card-text class="line-text-first text-second py-2 body-2">
                              {{ detail.subject }}
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
      
                    <v-card flat v-if="selected.menu === 1" class="mt-5">
                      <div class="d-flex align-center mt_23">
                        <div class="text-first caption font-weight-bold ml-1">
                          {{ detail.content_count }} Materi • {{ detail.content_sub_count }} Sub materi
                        </div>
                        <div class="ml-auto">
                          <v-btn
                            text
                            small
                            color="#505050"
                            @click="!openAll ? all() : panel = []; !openAll ? openAll = true :  openAll = false;"
                            class="text-capitalize">
                            <u v-if="!openAll">Expand all</u>
                            <u v-if="openAll">Close all</u>
                          </v-btn>
                        </div>
                      </div>

                      <div class="mt-4">
                        <v-expansion-panels flat class="mb-3" multiple v-model="panel">
                          <v-expansion-panel class="mb-3" v-for="(materi, i) in detail.content" :key="i">
                            <v-expansion-panel-header style="background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: 55px;">
                              <template v-slot:actions>
                                <div>
                                  <v-icon class="px-0" style="order: 0;" color="#7B7B7B">
                                    $expand
                                  </v-icon>
                                </div>
                              </template>
                              <v-list dense style="order: 1;" class="py-0 pl-4" color="transparent">
                                <v-list-item class="py-0 px-0">
                                  <div class="d-flex align-center" style="width: 100%;">
                                    <div class="body-2 ml-0 font-weight-bold text-capitalize">
                                      {{ materi.title }}
                                    </div>
                                    <div class="caption text-first ml-auto">
                                      <!-- 11 lectures - 26min -->
                                    </div>
                                  </div>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="non-padding-expansion">
                              <div style="padding: 17px 20px;">
                                <div class="d-flex">
                                  <div v-if="materi.file_url !== ''">
                                    <div
                                      style="min-width: 40px; width: 40px; padding-top: 13.5px">
                                      <v-icon 
                                        size="20"
                                        :color="'grey'">
                                        {{ materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                      </v-icon>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="d-flex">
                                      <div class="mr-4">
                                        <v-img
                                          width="55"
                                          height="55"
                                          class="d-flex align-center justify-center"
                                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                          :src="materi.img_url === '' ? detail.img_url : materi.img_url"
                                          style="border-radius: 5px;">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </div>

                                      <div class="body-1 font-weight-light text-second"
                                        style="line-height: 1.75;">
                                        <span
                                          class="three-line text-second caption">
                                          {{ materi.description }}
                                        </span>
                                      </div>

                                      <div class="ml-auto pl-2 caption" v-if="materi.preview_bool" style="color: #4ab762;">
                                        Preview
                                      </div>
                                    </div>
                                    <div v-for="(sub_materi, index) in materi.sub" :key="index">
                                      <div style="padding: 17px 0 17px 20px;">
                                        <div class="d-flex align-center">
                                          <div>
                                            <div
                                              style="min-width: 55px; width: 55px;">
                                              <v-icon 
                                                size="20"
                                                :color="'grey'">
                                                {{ sub_materi.type === 'docs' ? 'mdi-text' : 'mdi-television-play'}}
                                              </v-icon>
                                            </div>
                                          </div>
                                          <div class="mr-3">
                                            <v-img
                                              width="55"
                                              height="55"
                                              class="d-flex align-center justify-center"
                                              gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                              :src="sub_materi.img_url === '' ? detail.img_url : sub_materi.img_url"
                                              style="border-radius: 5px;">
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height"
                                                  align="center"
                                                  justify="center">
                                                  <v-progress-circular 
                                                    indeterminate 
                                                    color="#FF3177">
                                                  </v-progress-circular>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </div>

                                          <div class="body-1 font-weight-light text-second"
                                            style="line-height: 1.75;">
                                            <span
                                              class="three-line caption text-second">
                                              {{ sub_materi.description }}
                                            </span>
                                          </div>

                                          <div class="ml-auto pl-6 caption" v-if="sub_materi.preview_bool" style="color: #4ab762;">
                                            Preview
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                    </v-card>
      
                    <v-card flat v-if="selected.menu === 2">
                      <v-row class="align-center mt-5">
                        <v-col cols="12" md="3" class="text-center">
                          <div class="text-h3 text-second font-weight-bold">
                            {{ detail.review_average }}
                          </div>
                          <v-rating dense
                            :value="detail.review_average*1"
                            readonly
                            color="#F0932B"
                            size="20"
                            background-color="grey darken-1"
                            half-increments>
                          </v-rating>
                          <div class="text-second mt-2 body-2">
                            ({{ detail.review_count }})
                          </div>
                        </v-col>
                        <v-col cols="12" md="9">
                          <div 
                            v-for="(item, index) in detail.review_rate"
                            :key="index">
                            <div class="d-flex align-center" style="100%" v-if="item.value !== 0">
                              <!-- <div class="pt-1"> -->
                                <!-- <v-progress-linear value="15"></v-progress-linear> -->
                              <v-progress-linear
                                :color="'#F0932B'"
                                height="10"
                                rounded
                                :value="item.percent === '' ? 0 : item.percent"
                                style="width: 60%;"
                                class="cursor-pointer">
                              </v-progress-linear>
                              <!-- </div> -->
                              <div 
                                class="mr-auto pl-3">
                                <v-icon 
                                  small
                                  color="#F0932B" 
                                  v-for="(s, index) in item.value" 
                                  :key="index">
                                  mdi-star
                                </v-icon>
                              </div>
                              <div 
                                class="ml-auto caption text-second pr-2 py-0 pt-2">
                                <span>
                                  {{ item.percent === '' ? 0 : item.percent }}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="text-first body-1 my-8 px-0 font-weight-bold">
                        Reviews atau Ulasan
                      </div>
                      <v-row class="px-0">
                        <v-col cols="10">
                          <v-text-field
                            solo
                            hide-details
                            clearable
                            color="#4ab762"
                            v-model="filter.search"
                            v-on:keyup.enter="fetchReview()"
                            @click:clear="filter.search = ''; fetchReview();"
                            :placeholder="`Cari ulasan`">
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-2">mdi-magnify</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col :cols="2" class="d-flex align-center justify-end">
                          <v-icon size="25" class="mr-1">mdi-filter-variant</v-icon>
                        </v-col>
                      </v-row>
                      <div>
                        <div v-if="process.review">
                          <v-skeleton-loader
                            class="ma-auto"
                            :loading="process.review"
                            type="list-item-avatar-three-line">
                          </v-skeleton-loader>
                        </div>
                        <div v-if="!process.review">
                          <v-list dense class="my-3" color="transparent">
                            <v-list-item
                              v-for="(l, index) in review"
                              :key="index">
                              <v-list-item-avatar size="60" tile class="align-self-start">
                                <v-img
                                  :src="l.user_img_url"
                                  v-if="l.user_img_url !== ''"
                                  style="border-radius: 6px;">
                                </v-img>
                                <v-icon size="65" v-if="l.user_img_url === ''">mdi-account-box</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content class="cursor-pointer">
                                <div class="d-flex align-start">
                                  <div>
                                    <v-list-item-title class="body-2 font-weight-bold">
                                      {{ l.user_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="mt-2">
                                      <v-rating dense
                                        :value="l.rate*1"
                                        readonly
                                        size="15"
                                        color="#F0932B"
                                        background-color="grey darken-1">
                                      </v-rating>
                                    </v-list-item-subtitle>
                                  </div>
                                  <div class="text-second caption ml-auto">
                                    {{ l.last_update_datetime | datetimemonth }}
                                  </div>
                                </div>
                                <v-list-item-content class="text-second line-text-second caption">
                                  {{ l.text }}
                                </v-list-item-content>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
            <div class="bottom-price d-flex align-center" style="position: fixed; bottom: 0; z-index: 1; width: calc(100vw - 25px);">
              <div class="py-2 px-4">
                <div class="text-left red--text fs-14 font-weight-bold">
                  <span v-if="detail.discount_percent_admin > 0">{{ detail.discount_percent_admin }}%</span> 
                  <span v-if="detail.discount_percent_admin > 0 && detail.discount_percent_publisher > 0">+</span>
                  <span v-if="detail.discount_percent_publisher > 0">{{ detail.discount_percent_publisher }}%</span>
                </div>
                <div class="text-left">

                  <span class="body-2 font-weight-bold">
                    <span class="text-decoration-line-through text-second mr-2" v-if="detail.discount_bool">
                      {{ detail.price | price }}
                    </span>
                    <span class="font-weight-bold red--text">
                      <span class="text-first" v-if="parseInt(detail.price) > 0">
                        <span v-if="!detail.discount_bool">
                          {{ detail.price | price }}
                        </span>
                        <span v-if="detail.discount_bool">
                          {{ detail.price_discount | price }}
                        </span>
                      </span>

                      <span class="green--text" v-else>
                        Gratis
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="ml-auto" v-if="detail.publisher_user_id !== parseInt(this.user.id)">
                <v-btn
                  icon
                  fab
                  small
                  v-if="(!detail.subscribed_bool && !detail.subscribtion_expired_bool) || (detail.subscribed_bool && detail.subscribtion_expired_bool)"
                  elevation="0"
                  @click="!token ? authDialog = 'login' : dialog.cart = true; error.message = '';"
                  color="#FFFFFF"> 
                  <div class="px-3">
                    <v-icon class="color-first">mdi-cart</v-icon>
                  </div>
                </v-btn>
                <v-btn
                  small
                  v-if="token && detail.subscribed_bool && !detail.subscribtion_expired_bool"
                  elevation="0"
                  class="text-capitalize white--text"
                  :to="`/collection/${detail.user_course_id}`"
                  color="#4ab762"> 
                  lihat kelas
                </v-btn>
                <v-btn
                  icon
                  elevation="0"
                  fab
                  small
                  @click="!token ? authDialog = 'login' : detail.course_wish_id.length < 1 ? addWishlist() : removeWishlist()"
                  class=""> 
                  <div class="px-3" v-if="token">
                    <v-icon color="#d35400" v-if="detail.course_wish_id === ''">mdi-heart-plus-outline</v-icon>
                    <v-icon :color="'pink'" v-if="detail.course_wish_id !== ''">mdi-heart</v-icon>
                  </div>
                  <div class="px-3" v-if="!token">
                    <v-icon color="#d35400">mdi-heart-plus-outline</v-icon>
                  </div>
                </v-btn>
                <v-btn
                  icon
                  fab
                  small
                  elevation="0"
                  class=""
                  @click="dialog.share = true"
                  color="#FFFFFF"> 
                  <div class="px-3">
                    <v-icon :color="'grey'">mdi-share-variant</v-icon>
                  </div>
                </v-btn>
              </div>
            </div>
          </div>
        </section>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        menu: ['Tentang Kelas', 'Materi Kelas', 'Review'],
        selected: {
          menu: 0
        },
        panel: 0,
        model: '',
        dialog: {
          cart: false,
          success: false,
          player: false,
          success_pay: false,
          pay: false,
          voucher: false,
          detail_voucher: false,
          checkout: false,
          share: false
        },
        authDialog: '',
        filter: {
          sort: 'Semua Rating'
        },
        cart: [],
        filter: {
          search: "",
          rating: ""
        },
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          // { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        listVoucher: [],
        detail_voucher: {},
        selected: {
          cart: [],
          delete: '',
          pagination: 1,
          bank: {}
        },
        voucher: {},
        pagination: {},
        review: [],
        materi_preview: [],
        class_is_preview: false,
        openAll: false,
        snackbar: false,
        timeout: 2000,
        is_like: false,
        detail: {},
        list: [],
        items: ['Semua Rating'],
        success: {
          message: ""
        },
        error: {
          message: ""
        },
        process: {
          detail: true,
          cart: false,
          review: false,
          listVoucher: false,
          bank: false,
          voucher: false
        },
        // SEO
        content: {
          url: '/elearning',
          title: 'Detail Kelas',
          description: 'otodidak.sodapos.com',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },

    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      Empty: () => import('@/components/DataEmpty'),
      Player: () => import('@/components/player'),
      Pagination: () => import('@/components/Pagination'),
    },

    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fps() {
        return this.$vuetify.breakpoint.name
      },
      headers() {
        return this.$store.state.header
      },
      host () {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      this.fetchDetail()
    },
    methods: {
      fetchDetail () {
        this.process.detail = true
        let params = {
          id: this.$route.params.slug
        }
        this.$axios.$get(`api/course/detail`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.detail = response.results.data
            this.materi_preview = []
            for (let i in this.detail.content) {
              if (this.detail.content[i].preview_bool === true) {
                this.class_is_preview = true
                this.materi_preview.push(this.detail.content[i])
              }
              for (let j in this.detail.content[i].sub) {
                if (this.detail.content[i].sub[j].preview_bool === true) {
                  this.class_is_preview = true
                  this.materi_preview.push(this.detail.content[i].sub[j])
                }
              }
            }
            this.fetchTerkait()
            this.fetchReview()
            if (this.token) {
              this.fetchCart()
            }
          }else{
            this.process.detail = false
          }
        })
      },
      fetchCart () {
        let params = {
          limit: 1000000000
        }
        this.$axios.$get(`api/cart`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.cart = response.results.data
            // this.process.detail = false
          }else{
            this.process.detail = false
          }
        })
      },
      fetchTerkait () {
        let params = {
          limit: 4,
          category_id: this.detail.category_id,
          'id[neq]': this.detail.id
        }
        this.$axios.$get(`api/course`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.list = response.results.data
            this.contentEmpty = "Data belum tersedia"
            this.process.detail = false
          }else{
            this.process.detail = false
          }
        })
      },
      fetchReview (p) {
        this.process.review = true
        let params = {
          limit: 10,
          page: p === undefined ? 1 : p,
          course_id: this.detail.id,
          rate: this.filter.rating,
          search: this.filter.search
        }
        this.$axios.$get(`api/course_review`, { params })
        .then((response)=>{
          if(response.status === 200) {
            this.review = response.results.data
            this.pagination = response.results.pagination
            this.contentEmpty = "Data belum tersedia"
            this.process.review = false
          }else{
            this.process.review = false
          }
        })
      },
      fetchBank () {
        this.process.bank = true
        this.$axios.$get(`api/midtrans/payment_methods`)
        .then((response)=>{
          if(response.status === 200) {
            this.bank = response.results.data
            this.process.bank = false
          }else{
            this.process.bank = false
          }
        })
      },
      toCheckout() {
        this.selected.bank = {}; 
        this.fetchBank(); 
        this.dialog.checkout = true; 
        this.error.message = '';
      },
      all () {
        this.panel = [...Array(this.detail.content).keys()].map((k, i) => i)
      },
      async addWishlist() {
        await this.$axios.$post(`api/wishlist`, 
        {
          course_id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.detail.course_wish_id = response.results.data.id
            this.success.message = "Berhasil ditambahkan ke wishlist"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async removeWishlist() {
        await this.$axios.$post(`api/wishlist/delete`, 
        {
          id: this.detail.course_wish_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.detail.course_wish_id = ""
            this.success.message = "Berhasil dihapus dari wishlist"
            this.snackbar = true
          } else {
            this.process.form = false
            this.error.message = response.message
          }
        })
      },
      async addCart () {
        this.process.cart = true
        await this.$axios.$post(`api/cart`, 
        {
          course_id: this.detail.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.dialog.cart = false
            this.success.message = "Berhasil menambahkab kelas ini ke keranjang"
            this.dialog.success = true
            this.process.cart = false
            this.$store.commit('header/setCart', this.headers.cart + 1)
          } else {
            this.process.cart = false
            this.error.message = response.message
          }
        })
      },
      async addVoucher() {
        this.error.voucher = ""
        this.process.voucher = true
        await this.$axios.$post(`api/cart/apply_coupon`, 
        {
          code: this.detail_voucher.code,
          course_id_array: [this.detail.id]
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.voucher = false
            this.voucher = response.results.data
            this.dialog.detail_voucher = false
            this.dialog.voucher = false
          } else {
            this.process.voucher = false
            if (response.message === 'Voucher tidak valid.') {
              this.error.voucher = "Voucher Belum Tersedia"
            } else {
              this.error.voucher = response.message
            }
          }
        })
      },
      async checkout() {
        this.process.cart = true
        await this.$axios.$post(`api/cart`, 
        {
          course_id: this.detail.id
        })
        .then((response) => {
        })
        this.error.message = ''
        await this.$axios.$post(`api//midtrans/checkout`, {
          course_id_array: [this.detail.id],
          payment_method_object: parseInt(this.detail.price_discount) === 0 ? {} : this.selected.bank,
          total_paid_nett: parseInt(this.detail.price_discount) === 0 ? parseInt(this.detail.price_discount) : Object.keys(this.voucher).length > 0 ? parseInt(this.detail.price_discount) - parseInt(this.voucher.discount) + parseInt(this.selected.bank.admin_fee) : parseInt(this.detail.price_discount) + parseInt(this.selected.bank.admin_fee),
          coupon_code: Object.keys(this.voucher).length > 0 ? this.voucher.code : ''
        })
        .then((response) => {
          if (response.status === 200) {
            let class_in_cart = false
            if (this.cart.length > 0) {
              for (let i = 0; i < this.cart.length; i++) {
                if (this.cart[i].course_id === this.detail.id) {
                  this.toDeleteCart(this.cart[i].id)
                  class_in_cart = true
                }
              }

              if (class_in_cart === false) {
                this.process.cart = false
                if (parseInt(this.detail.price_discount) === 0) {
                  this.dialog.success_pay = true
                  this.dialog.pay = false
                  this.dialog.checkout = false
                  this.success.message = "Berhasil membeli kelas ini"
                } else {
                  window.location= `/purchasing`
                }
              }
            } else {
              if (parseInt(this.detail.price_discount) === 0) {
                this.dialog.success_pay = true
                this.dialog.pay = false
                this.dialog.checkout = false
                this.success.message = "Berhasil membeli kelas ini"
              } else {
              window.location= `/purchasing`
            }
              this.process.cart = false
            }
          } else {
            // this.dialog.success_pay = true
            // this.dialog.pay = false
            // this.dialog.checkout = false
            this.process.cart = false
            this.error.message = response.message
          }
        })
      },
      async toDeleteCart(item) {
        await this.$axios.$post(`api/cart/delete`, 
        {
          id: item
        })
        .then((response) => {
          if (response.status === 200) {
            if (parseInt(this.detail.price_discount) === 0) {
              this.dialog.success_pay = true
              this.dialog.pay = false
              this.dialog.checkout = false
              this.success.message = "Berhasil membeli kelas ini"
            } else {
              window.location= `/purchasing`
            }
            this.process.cart = false
          } else {
            this.process.cart = false
            this.error.message = response.message
          }
        })
      },
    },
  };
</script>